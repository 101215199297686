import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveryNotesRoutes } from './delivery-notes.routing';
import { DeliveryNotesComponent } from './delivery-notes/delivery-notes.component';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DemoMaterialModule } from '../demo-material-module';
import { DeliveryNotesInfoComponent } from './delivery-notes-info/delivery-notes-info.component';
import { DlApproveComponent } from './dl-approve/dl-approve.component';
import { EditDeliveryNoteComponent } from './edit-delivery-note/edit-delivery-note.component';
import { AddDeliveryNotesComponent } from './add-delivery-notes/add-delivery-notes.component';
// import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { PoInfoComponent } from './po-info/po-info.component';
import { DeliveryNotesHistoryComponent } from './delivery-notes-history/delivery-notes-history.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {DatePipe} from '@angular/common';
import { VendorInfoComponent } from './vendro-info/vendor-info.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { SpecialCharacterDirective } from '../shared/specialIsAlphaNumeric';
import { TwoDigitDecimaNumberDirective } from '../twodigitdecimalnumber.directive';
import { CommonPipesModule } from '../common-pipes.module';

@NgModule({
  declarations: [
    DeliveryNotesComponent,
    DeliveryNotesInfoComponent,
    EditDeliveryNoteComponent,
    AddDeliveryNotesComponent,
    PoInfoComponent,
    DeliveryNotesHistoryComponent,
    VendorInfoComponent,
    SpecialCharacterDirective,
    TwoDigitDecimaNumberDirective
  ],
  imports: [
    CommonModule,
    DemoMaterialModule,
    FlexLayoutModule,
    AngularMultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    RouterModule.forChild(DeliveryNotesRoutes),
    NgMultiSelectDropDownModule.forRoot(),
    CommonPipesModule
    
  ],
  providers:[DatePipe]
})
export class DeliveryNotesModule { }