// my-modal.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router'
import { InvoiceListComponent } from '../invoice-list/invoice-list.component';
// import { UserManagementComponent } from '../user-management/user-management.component';

@Component({
  selector: 'app-invoice-upload',
  templateUrl: './invoice-upload.component.html',
  styleUrls: ['./invoice-upload.component.css']
})

export class InvoiceUploadComponent {
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  food: string | undefined;
  local_data: any;
  action: string;
  status: boolean = true;
  constructor(private fb: FormBuilder, private router: Router,
    public dialogRef: MatDialogRef<InvoiceUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InvoiceListComponent) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
    if (this.action == 'Add') {
      this.status = false;
    } else {
      this.status = true;
    }
  }

  get f() { return this.changePwd.controls; }
  changePwd: FormGroup= this.fb.group({
    typeOfInvoice : ['',[Validators.required]],
    inoviceNumber : ['',[Validators.required]],
    // username : ['',[Validators.required]],
    invoiceFile:['',[Validators.required]]
  });
  ngOnInit(): void {
    this.changePwd = this.fb.group({
      typeOfInvoice : ['',[Validators.required]],
      inoviceNumber : ['',[Validators.required]],
      // username : ['',[Validators.required]],
      invoiceFile:['',[Validators.required]]
    });
  }
  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  onNoClick(): void {
    
    this.dialogRef.close({event:this.action, data:this.changePwd.value});
    // this.dialogRef.close({ event: this.action, data: this.local_data });
    

  }
  taxType:any="0";
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  goTo(){
    this.router.navigate(["invoice-list/view-invoice"]);
    
  }

}