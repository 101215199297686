<div fxLayout="row" fxLayoutWrap="wrap">
    <div fxFlex.gt-sm="100%" fxFlex="100">
        
            <mat-card class="example-card full-width">
                <mat-card-content>

                    <div class="row" style="display: flex">


                        <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                            <!-- <h3 style="text-align: center;">Add Delivery Note</h3> -->
                            <mat-card-title style="text-align: center;">Add Delivery Note</mat-card-title>
                        </div>

                        <div class="addr" fxFlex.gt-lg="80" fxFlex.gt-md="80" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-icon (click)="goTo()" style="float: right;" class="close">close</mat-icon>
                        </div>
                    </div>
                    <hr>
                   
                    <div class="row">
                        <div class="col-md-12">
                          
                            <div class="card-box">
                                <!-- <mat-card>
                                    <mat-card-content> -->
                                        <!-- <form  [formGroup]="addDeliveryNotes" (submit)="createNewTask()"> -->
                                        <div fxLayout="row" fxLayoutWrap="wrap">
                                            <!-- <div fxFlex.gt-sm="50%" fxFlex="100"> -->

                                                <!-- <form class="basic-form"> -->
                                                    <div fxLayout="row wrap">
                                                        <div fxFlex.gt-sm="50" class="fs-box" fxFlex="50">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>PO Number</mat-label>
                                                                <input matInput formControlName="poNumber" type="text">
                                                            </mat-form-field>
                                                        </div>
                                                        <div fxFlex.gt-sm="50" fxFlex="50">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Delivery Note Number</mat-label>
                                                                <input matInput  formControlName="dlNumber" type="text">
                                                            </mat-form-field>
                                                        </div>

                                                        <div fxFlex.gt-sm="50" class="fs-box" fxFlex="50">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Delivery Note Date</mat-label>
                                                                <input matInput  formControlName="dlDate" type="date">
                                                            </mat-form-field>
                                                        </div>
                                                        <div fxFlex.gt-sm="50" fxFlex="50">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Shipment Number</mat-label>
                                                                <input matInput  formControlName="shipmentNo" type="text">
                                                            </mat-form-field>
                                                        </div>
                                                        <div fxFlex.gt-sm="50" class="fs-box" fxFlex="50">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Posting Date</mat-label>
                                                                <input matInput  formControlName="postingDate" type="date">
                                                            </mat-form-field>
                                                        </div>

                                                        <div fxFlex.gt-sm="50" fxFlex="50">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Bill Of Lading</mat-label>
                                                                <input matInput  formControlName="billOfLanding" type="text">
                                                            </mat-form-field>
                                                        </div>

                                                        <div fxFlex.gt-sm="50" class="fs-box" fxFlex="50">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Total Quantity</mat-label>
                                                                <input matInput  formControlName="totalQty" type="text">
                                                            </mat-form-field>
                                                        </div>

                                                        <div fxFlex.gt-sm="50" fxFlex="50">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Total Amount</mat-label>
                                                                <input matInput  formControlName="totalAmt" type="text">
                                                            </mat-form-field>
                                                        </div>

                                                        <div fxFlex.gt-sm="50" class="fs-box" fxFlex="50">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Header</mat-label>
                                                                <input formControlName="header" matInput type="text">
                                                            </mat-form-field>
                                                        </div>
                                                        <div fxFlex.gt-sm="50" fxFlex="50">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Category</mat-label>
                                                                <input matInput formControlName="category" type="text">
                                                            </mat-form-field>
                                                        </div>

                                                        <div fxFlex.gt-sm="50" class="fs-box" fxFlex="50">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Delivery Person Name</mat-label>
                                                                <input matInput  formControlName="dlPerson" type="text">
                                                            </mat-form-field>
                                                        </div>

                                                        
                                                        <div fxFlex.gt-sm="50" fxFlex="50">
                                                            <mat-form-field appearance="outline">
                                                                <mat-label>Delivery Person Email</mat-label>
                                                                <input matInput  formControlName="dlPersonEmail" type="text">
                                                            </mat-form-field>
                                                        </div>

                                                    </div>
                                                <!-- </form> -->

                                            <!-- </div>
                                            <div fxFlex.gt-sm="50%" fxFlex="100"> -->


                                                <!-- <form class="basic-form"> -->
                                                    <!-- <div fxLayout="row wrap" style="padding-left: 10%;">

                                                        
                                                        
                                                        
                                                        
                                                    </div> -->
                                                <!-- </form> -->

                                            <!-- </div> -->
                                        </div>
                                        <div class="row" style="display: flex;">
                                            <div fxFlex.gt-lg="90" fxFlex.gt-md="90" fxFlex.gt-xs="100" fxFlex="100">
        
                                            </div>
        
                                            <div fxFlex.gt-lg="90" fxFlex.gt-md="90" fxFlex.gt-xs="100" fxFlex="100"
                                                style="text-align: center;">
                                                <div class="text-md-right">
        
                                                </div>
        
                                            </div>
                                        </div>
        
        
                                        <div class="row mt-2">
                                            <div class="col-md-12">
                                                <h4>Delivery Note Details</h4>
                                                <div class="table-responsive addr">
                                                    <table class="table mt-4 table-centered">
                                                        <thead>
                                                            <tr>
        
                                                                <th class="tableCls">Item Number</th>
                                                                <th class="tableCls">GR Quantity</th>
                                                                <th class="tableCls">Storage Location</th>
                                                                <th class="tableCls">Unit Price</th>
                                                                <th class="tableCls">Quantity Delivering</th>
                                                                <th class="text-right tableCls">Total Price
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <!-- <tbody formArrayName="productInformationMasters" *ngFor="let pdct of productInformationMasters.controls; let pointIndex=index">
                                                                <tr [formGroupName]="pointIndex">
                                                                    <td>{{productInformationMasters[pointIndex].itemNumber}}</td>
                                                                    <td>{{productInformationMasters[pointIndex].itemNumber}}</td>
                                                                    <td>{{productInformationMasters[pointIndex].itemNumber}}</td>
                                                                    <td>{{productInformationMasters[pointIndex].itemNumber}}</td>
                                                                    <td>{{productInformationMasters[pointIndex].itemNumber}}</td>
                                                                    <td>{{productInformationMasters[pointIndex].itemNumber}}</td>
                                                                    </tr> -->
                                                            <tr>
                                                                <td>10</td>
                                                                <td>100</td>
                                                                <td>Bangalore</td>
                                                                <td>200</td>
                                                                <td>50</td>
                                                                <td class="text-right" >10000</td>
                                                            </tr>
        
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" style="display: flex;">
                                            <div fxFlex.gt-lg="10" fxFlex.gt-md="90" fxFlex.gt-xs="100" fxFlex="100">
                                                <div fxFlex.gt-sm="30%" fxFlex="100">
                                                    Attachments:
                                                    <input type="file">
                                                    <!-- <mat-form-field appearance="outline">
                                                        <input matInput type="text">
                                                    </mat-form-field> -->
                                                </div>
                                            </div>
                                        </div>
        
                                        <div class="row mt-2 addr ">
                                            <div fxFlex.gt-lg="90" fxFlex.gt-md="90" fxFlex.gt-xs="100" fxFlex="100">
                                            </div>
                                            
                                            <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
                                                <button mat-raised-button class="submitButton">Submit</button>
                                            </div>
                                            <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
                                                <button mat-raised-button class="rejectButton">Cancel</button>
                                            </div>
                                        </div>
                                        <!-- </form> -->
                                    <!-- </mat-card-content>
                                </mat-card> -->

                            </div>
                            <!-- </form> -->
                        </div>

                    </div>
                </mat-card-content>
            </mat-card>
        
    </div>
</div>