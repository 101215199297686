<mat-card>
    
            <mat-card-title>
    
                <div fxLayout="row" fxFlex.gt-xs="100" fxFlex="100">
                    {{language.responseMessages}}
                </div>
                <!-- <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                   <mat-form-field appearance="outline" class="selectLang" style="padding:0px;margin: 0px;">
                       <mat-label>Select Language</mat-label>
                        <mat-select class="langSelect width-80" style="font-size: 14px;padding: 0.5em 0 0.5em 0 !important;" [(ngModel)]="langSelect" (selectionChange)="langChange()">
                            <mat-option value="en">English</mat-option>
                            <mat-option value="thai">Thai</mat-option>
                        </mat-select>
                    </mat-form-field>
    
                </div> -->
    
            </mat-card-title>
            <hr>
            <mat-card-content>
                <div fxLayout="row" fxLayoutWrap="wrap">
                    <!-- Card column -->
                    <div fxFlex.gt-sm="100%" fxFlex="100">
                        <!-- <mat-card> -->
                            <mat-tab-group (selectedTabChange)="tabClick($event)">
                                <mat-tab label="English">
                                    <div  fxLayout="row"fxLayout.xs="column" >
                                        <div class="search" fxFlex="30" fxFlex.sm="50" fxFlex.xs="100">
                                            <mat-form-field class="mx-3 max-width">
                                             <!-- <mat-label>{{headerToDisplay.search}}</mat-label> -->
                                              <input matInput placeholder="Search" (keyup)="applyFilter1('en')" #input [(ngModel)]="searchString">
                                           </mat-form-field>
                                           </div>
                                            
                                           <div fxFlex="20" fxFlex.sm="50" fxFlex.xs="100" style="margin-top:15px">
                                                <button (click)="applyFilter('en')" mat-raised-button class="submitButton btn-primary">Search</button>
                                           </div>
                                    </div>
                            <!-- <div class="row mx-2 mt-2 my-2 summaryTab mat-elevation-z8  mx-3 mt-3 full-width"> -->
                        <div class="overflow">
                            <mat-card>
                                        <mat-table *ngIf="lang == 'en'" [dataSource]="dataSource" matSort multiTemplateDataRows class="mat-elevation-z5 main-table responseTable">
                                            <ng-container matColumnDef="respCode">
                                                <mat-cell *matCellDef="let element" class="response-w" data-label ="Message Code"> {{element.respCode}} </mat-cell>
                                                <mat-header-cell *matHeaderCellDef mat-sort-header class="response-w"> Message Code </mat-header-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="message">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header class="message-w"> Message</mat-header-cell>
                                                <mat-cell *matCellDef="let element" data-label="Message" class="message-w"> {{element.message}} </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="actions">
                                                <mat-header-cell *matHeaderCellDef class="action-w"> Actions </mat-header-cell>
                                                <mat-cell *matCellDef="let element" class="action-w" data-label="Actions">
                                                    <!-- <button (click)="openDialog('Update',element)" mat-flat-button class="submitButton"> -->
                                                        <!-- <mat-icon aria-hidden="false" class="visibility" aria-label="visibility">visibility</mat-icon> -->
                                                        <!-- Update
                                                    </button> -->
                                                    <!-- <button (click)="openDialog('Update',element)" mat-flat-button class="button-actions mx-2">
                                                        <mat-icon aria-hidden="false" class="visibility" aria-label="visibility">visibility</mat-icon>
                                                    </button> -->
                                                    <mat-icon class="edit" aria-label="edit" (click)="openDialog('Update',element)">edit</mat-icon>
                                                </mat-cell>
                                            </ng-container>
                                            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                                            <mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
                                            </mat-row>
                                        </mat-table>                                   
                            </mat-card>
                            <mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" [pageSize]="pageSize" [length]="listLength"
                                [pageSizeOptions]="[10, 25, 50, 100]" (page)="getResponseMessage($event,'','en')" style="width:100%">
                            </mat-paginator>
                        </div>
                            
                        
                            <!-- </div> -->
                        <!-- </mat-card> -->
                        </mat-tab>
                        <mat-tab label=" ไทย" >
                            <div  fxLayout="row"fxLayout.xs="column" >
                                <div class="search" fxFlex="30" fxFlex.sm="50" fxFlex.xs="100">
                                    <mat-form-field class="mx-3 max-width">
                                     <!-- <mat-label>{{headerToDisplay.search}}</mat-label> -->
                                      <input matInput placeholder="ค้นหา" (keyup)="applyFilter1('th')" #input [(ngModel)]="searchString">
                                   </mat-form-field>
                                   </div>
                                    
                                   <div fxFlex="20" fxFlex.sm="50" fxFlex.xs="100" style="margin-top:15px">
                                        <button (click)="applyFilter('th')" mat-raised-button class="submitButton btn-primary">ค้นหา</button>
                                   </div>
                            </div>
                            <!-- <div class="row mx-2 mt-2 my-2 summaryTab mat-elevation-z8  mx-3 mt-3 full-width"> -->
                            <div class="overflow">
                                <mat-card>
                                    <mat-table *ngIf="lang == 'en'" [dataSource]="dataSource" matSort multiTemplateDataRows class="mat-elevation-z5 main-table"
                                        style="width: 100%;">
                                        <ng-container matColumnDef="respCode">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header  class="response-w"> รหัสข้อความ </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="response-w" data-label="Message Code"> {{element.respCode}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="message">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header class="message-w" >ข้อความ</mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Message" class="message-w"> {{element.message}} </mat-cell>
                                        </ng-container>
                                
                                        <ng-container matColumnDef="actions">
                                            <mat-header-cell *matHeaderCellDef class="action-w"> การดำเนินการ </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="action-w" data-label="Actions">
                                                <!-- <button (click)="openDialog('Update',element)" mat-flat-button class="submitButton">
                                                    Update
                                                </button> -->
                                                <mat-icon class="edit" aria-label="edit" (click)="openDialog('Update',element)">edit</mat-icon>
                                            </mat-cell>
                                        </ng-container>
                                        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                                        <mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row">
                                        </mat-row>
                                    </mat-table>
                                </mat-card>
                            </div>
                            <mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" [pageSize]="pageSize" [length]="listLength"
                        [pageSizeOptions]="[10, 25, 50, 100]" (page)="getResponseMessage($event,'','th')" style="width:100%">
                        </mat-paginator>                        
                    </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </mat-card-content>
            <div class="overlay"  *ngIf="mySpinner">
                <div class="center">
                    <mat-spinner></mat-spinner> 
                </div>
                </div>
</mat-card>