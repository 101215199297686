import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from '../interceptors/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../interceptors/user/user.interface';
import swal from "sweetalert2";
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  auth:any;
  constructor(private router:Router, private authService: AuthService 
    ,private idle: Idle, private keepalive: Keepalive) {
    this.auth = JSON.parse(localStorage.getItem('Auth')); 
   }
  loginForm: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required]),//
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
  });

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  
  showOverlay:boolean=false;
  error:boolean=false;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  ngOnInit(): void {
    this.loginForm  = new FormGroup({
        username: new FormControl('', [Validators.required]),//
        password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      });
    this.getRoles();
  }
  // submit(){
  //   
  //  if(this.loginForm.value.username == "vnd123"){
  //   localStorage.setItem("currentUser","vnd123");
  //  }else if(this.loginForm.value.username == "war123"){
  //   localStorage.setItem("currentUser","war123");
  //  }else if(this.loginForm.value.username == "inv123"){
  //   localStorage.setItem("currentUser","inv123");
  //  }
  //  else if(this.loginForm.value.username == "pjo123"){
  //   localStorage.setItem("currentUser","pjo123");
  //  }
  //  else{
  //   localStorage.setItem("currentUser","ADM123");
  //  }
   
   
  //  this.router.navigate(['/dashboard']);
  // }
  mySpinner:boolean=false;
  getRoles(){
    this.authService.getRoles().pipe(first()).subscribe(user => {
      // this.currentUserSubject.next(null);
      if(user.responseStatus.toUpperCase() == 'SUCCESS'){
        this.mySpinner = false;

        if (user && user.token) {
          //
          let userDetailsData = {
            pagination: user["pagination"],
            response: user["response"].userDetails,
            responseCode: user["responseCode"],
            responseMessage: user["responseMessage"],
            responseStatus: user["responseStatus"],
            token: user.token,
  
          }
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(userDetailsData));
          localStorage.setItem('menuList', JSON.stringify(user["response"].menuList));
          localStorage.setItem('menuAccess', JSON.stringify(user["response"].menuAccess));
          localStorage.setItem("Auth",JSON.stringify(user));
          localStorage.setItem("refreshToken",user["refreshToken"]);
          localStorage.setItem("token",user.token);
          localStorage.setItem("idleTime",user["idleTime"]);
          localStorage.setItem("refreshInterval",user["refreshInterval"]);
          // this.currentUserSubject.next(user);
        }

          this.router.navigate(["dashboard"]);

          let Crent = localStorage.getItem("currentUser");
          if(Crent){
          this.idle.setIdle(Number(2));
          this.idle.setTimeout(Number(user["idleTime"]));

          this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      
          this.idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
          this.idle.onTimeout.subscribe(() => {
            this.idleState = 'Timed out!';
          this.timedOut = true;
          swal.fire("Login Expired. Please login again", "", "error").then(function(){;
            window.location.href = "signin";
            localStorage.clear();
          });
          });
          this.idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
          this.idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');
          this.keepalive.interval(15);
      
          this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
      
          this.reset();
        
        }
        
          let varr = setInterval(async ()=>{
           let refToken =  localStorage.getItem("refreshToken");
            let obj = 
            {
                "refreshToken": refToken
          }
          if(this.timedOut == true){
            clearInterval(varr)
          }
          let Val = localStorage.getItem("currentUser");
          if(Val){
           await  this.authService.getRefreshToken(obj).pipe(first()).subscribe(res => {
              user["refreshToken"] = res.refreshToken;
              user["accessToken"] = res.accessToken;
              let vVal = JSON.parse(localStorage.getItem("currentUser"));
              let refVal = localStorage.getItem("refreshToken");
              vVal.token = res.accessToken;
              refVal = res.refreshToken;
              localStorage.setItem("currentUser",JSON.stringify(vVal));
              localStorage.setItem("refreshToken",refVal);
            })
          }                     
          },Number(user["refreshInterval"]) * 1000) 

      
 

      }else {
        this.mySpinner = false;
        // this.listLength = 0;
        swal.fire(user.responseMessage,"","error");
        swal.fire(user.message,"","error");
        this.router.navigate(["/signin"]);
      }
      
    })
    // var w = window.open("https://www.google.com", "MsgWindow", "width=500,height=500"); //has a function on `window` called "test"
    //w.test();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
    }

}
