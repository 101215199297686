<div class="row" style="display: flex;">
    <div fxFlex.gt-lg="90" fxFlex.gt-md="90" fxFlex.gt-xs="100" fxFlex="100">
        <strong>Update Audit Record</strong>
    </div>
    <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
        <mat-icon class="close pull-right" (click)="closeDialog()">close</mat-icon>
    </div>


</div>
<div mat-dialog-content class="mb-2">
    <!-- <div class="row" *ngIf="action != 'delete'; else elseTemplate"> -->
    <form [formGroup]="roleForm">
        <div class="row  mt-3">
            <div class="col-lg-6">
                <mat-form-field class="width-80" appearance="outline">
                    <mat-label>User Name</mat-label>
                    <input matInput placeholder="User Name" readonly formControlName="respCode"
                        [(ngModel)]="local_data.respCode">

                </mat-form-field>
            </div>
            <div class="col-lg-6">
                <mat-form-field class="width-80" appearance="outline">
                    <mat-label>Description</mat-label>
                    <input matInput placeholder="Description" required formControlName="message"
                        [(ngModel)]="local_data.message">

                </mat-form-field>
            </div>
        </div>
        <button mat-button mat-flat-button class="submitButton" (click)="onNoClick()"
            style="margin-right:10px">{{action}}</button>
    </form>
</div>
