import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { PurchaseOrderInterface } from 'src/app/purchase-order/purchase-order';

@Component({
  selector: 'app-debit-note',
  templateUrl: './debit-note.component.html',
  styleUrls: ['./debit-note.component.css']
})
export class DebitNoteComponent implements OnInit {

  expandedElement: PurchaseOrderInterface | null | undefined;
  constructor(private router:Router,private dialog:MatDialog) { }
  userType:any;
  @ViewChild(MatSort) sort: MatSort;
  ngOnInit(): void {
    this.userType = localStorage.getItem("user");
    this.dataSource.sort=this.sort;
    
  }
  dataSource:any=[
    {
      'invoiceNo':"1010062_IV12993",'documentNo':"87149", 'debitNoteRefNo':"12993",
    },
    {
      'invoiceNo':"1010062_IV12994",'documentNo':"87148", 'debitNoteRefNo':"12994",
    }
  ];
  columnsToDisplay = ['invoiceNo','documentNo', 'debitNoteRefNo','actions'];
  


  keyword:any;
  applyFilter(event:any){
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
