import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// import { MyModalComponent } from './my-modal/my-modal.component';
import swal from 'sweetalert2';
import { VendorModalComponent } from '../vendor-management/vendor-modal/vendor-modal.component';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { first } from 'rxjs/operators';
import { AdminServiceService } from '../services/admin-service.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import {browserRefresh} from '../app.component';
import { TableUtil } from 'src/app/reports/TableUtil';
import { Location } from '@angular/common';

@Component({
  selector: 'app-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.css']
})
export class VendorListComponent implements OnInit {
  showOverlay: boolean;
  mySpinner:boolean = false;
  @ViewChild('myInput')
  myInputVariable: ElementRef
  constructor(private dialog: MatDialog, public _snackBar: MatSnackBar, private router: Router, private cService: AdminServiceService,
  private route:ActivatedRoute,private location: Location) { }
  dataSource: MatTableDataSource<any>;
  columnsToDisplay = ["isBlocked", "actions","vendorCode","vendorName","vendorGroup", "city", "country"];
  // columnsToDisplay1 = ["vendorCode", "firstName", "lastName", "email", "mobileNo", "isBlocked", "actions"];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  currentUser: any;
  status: boolean = false;
  supportDocs: any = [];
  listValue: any;
  datacache: any = [];
  newDocuments: any = [];
  headerToDisplay:any;
  browserRefresh:boolean = false;
  listData:any = [];
  search:any;
  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    let currentUsers = this.currentUser.response;
		let lang = currentUsers.langPreference;
		

    let states;
    states = this.location.getState();
    

		if(lang == 'en'){
      this.headerToDisplay={
        vendorCode:"Vendor Code",
        companyCode:"Company Code",
        vendorGroup:"Vendor Group",
        currency:"Currency",
        city:"City",
        country:"Country",
        isActive:"Access",
        isBlocked:"Status",
        actions:"Actions",
        userManagement:"Vendor List",
        search:"Search",
        vendorName:"vendor Name"
      }
		  }else if(lang == 'th'){
      this.headerToDisplay={
        vendorCode:"รหัสผู้ขาย",
        companyCode:"รหัส บริษัท",
        vendorGroup:"กลุ่มผู้จำหน่าย",
        currency:"สกุลเงิน",
        city:"เมือง",
        country:"ประเทศ",
        isActive:"เข้าถึง",
        isBlocked:"สถานะ",
        actions:"การกระทำ",
        userManagement:"รายชื่อผู้ขาย",
        search:"ค้นหา",
        vendorName:"ชื่อผู้ขาย"
      }
      }
      
      if(localStorage.getItem("searchData") != null){
        this.searchString = localStorage.getItem("searchData")
      }
      
      if(browserRefresh == true){
        this.searchString = '';
        localStorage.setItem("searchData","")
        localStorage.setItem("tableData",JSON.stringify([]))
      }
      let tableData:any = [];
      if(localStorage.getItem("tableData")){
        tableData = JSON.parse(localStorage.getItem("tableData"));
        this.listData = tableData;
      
      }
      
      if(tableData.length == 0){
        this.searchString = states.search?states.search:"";
        localStorage.setItem("searchData",states.search?states.search:"")
        this.getVendors(null, this.searchString);
      }else{
        this.dataSource = tableData.response;
        this.listLength = tableData.pagination.count;
        localStorage.setItem("tableData",JSON.stringify([]))
      }
  }
  pageIndex = 0;
  pageSize = 10;
  listLength: any;
  searchString:any='';
  getVendors(event: PageEvent, searchString:any) {
    // this.showOverlay = true;
    this.mySpinner = true;
    if (event == null) {
      this.pageIndex = 0;
      this.pageSize = 10;
    } else {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
    }

    this.cService.getVendorsList(this.pageIndex, this.pageSize, this.searchString).pipe(first()).subscribe(res => {
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        // this.showOverlay=false;
        
        this.dataSource = new MatTableDataSource(res.response);
        this.listData = res;
        this.listLength = res.pagination.count;
        this.dataSource.sort=this.sort;
        this.mySpinner = false;
        //

      } else {
        this.mySpinner = false;
        this.dataSource = new MatTableDataSource([]);
        this.listData = [];
        this.listLength = 0;
        
        swal.fire(res.responseMessage,"", "error");
      }
    }, error => {
      this.mySpinner = false;
      // this.showOverlay=false;
    })
  }
  openDialog(_add: any, obj: any) {
    obj.action = _add;
    let vendorid = obj.id;
    //

    const dialogRef = this.dialog.open(VendorModalComponent, {
      width: '500px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      
      if (result != undefined) {

        // let roleId;
        // if (result.data.usertype == "Administrator") {
        //   roleId = 1;
        // }

        let vendordata;
        if (result.event == "Add") {
          vendordata = {
            "vendorCode": result.data.vendorCode,
            "companyCode": result.data.companyCode,
            "vendorGroup": result.data.vendorGroup,
            "currency": result.data.currency,
            "city": result.data.city,
            "country": result.data.country
          };
          this.addVendor(vendordata);
        }
        else if (result.event == "Update") {
          vendordata = {
            "id": vendorid,
            "vendorCode": result.data.vendorCode,
            "companyCode": result.data.companyCode,
            "vendorGroup": result.data.vendorGroup,
            "currency": result.data.currency,
            "city": result.data.city,
            "country": result.data.country
          };
          this.updateVendor(vendordata);
        }
      }

    })
  }

  addVendor(row_obj) {
    this.showOverlay = true;
    this.mySpinner = true;
    this.cService.addVendor(row_obj).pipe(first()).subscribe(data => {
      if (data != null) {
        if (data.responseStatus.toUpperCase() == "SUCCESS") {
          this._snackBar.open('Vendor Added Succesfully', 'Ok', {
            duration: 5000,
          });
          // this.dataSource = data;
          this.mySpinner = false;
          this.showOverlay = false;
          this.getVendors(null, this.searchString);
        } else {
          this.showOverlay = false;
          this.mySpinner = false;
          swal.fire(data.responseMessage,"", "error")
        }
      }
      // this.showOverlay=false;
    }, () => {
      this.showOverlay = false;
    })

  }


  updateVendor(row_obj) {
    this.showOverlay = true;
    this.mySpinner = true;
    this.cService.updateVendor(row_obj).pipe(first()).subscribe(data => {
      if (data != null) {
        if (data.responseStatus.toUpperCase() == "SUCCESS") {
          this._snackBar.open('Vendor Updated Succesfully', 'Ok', {
            duration: 5000,
          });
          // this.dataSource = data;
          this.mySpinner = false;
          this.showOverlay = false;
          this.getVendors(null, this.searchString);
        } else {
          this.mySpinner = false;
          this.showOverlay = false;
          swal.fire(data.responseMessage,"", "error")
        }
      }
      // this.showOverlay=false;
    }, () => {
      this.showOverlay = false;
    })

  }
  openView(element: any) {
    localStorage.setItem("tableData",JSON.stringify(this.listData));
    this.router.navigate(["vendor-management/vendor-info", element.id,'venderList']);
  }

  applyFilter1(){
    const filterValue = this.searchString;
    localStorage.setItem("searchData", this.searchString);
    if(this.searchString == "" ){
      this.getVendors(null, this.searchString);
    }
  }
  applyFilter() {
    const filterValue = this.searchString;
    if(filterValue.length >= 2 || this.searchString == "" ){
      this.getVendors(null, this.searchString);
    }
}

  calculateClass(status:any) {
    let val = false;
    if (status == "0") {
      val = true;
    }
    return {
      "color-green": val,
      "color-red": !val
    }
  }

  changeClass(status:any) {
    let val = false;
    if (status == "Y") {
      val = true;
    }
    return {
      "color-green": val,
      "color-red": !val
    }
  }
  //     openConfirm(element: any) {
  //       const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
  //         data: {
  //           message: 'Are you sure you want to block/unblock?',
  //           buttonText: {
  //             ok: 'Submit',
  //             cancel: 'Cancel',
  //           },
  //           buttonColor: {
  //             ok: 'primary',
  //             cancel: '',
  //           },
  //         },
  //       });


  //       //
  //       let act = element["isBlocked"];
  //       if (act == '0') {
  //         act = 1;
  //       } else {
  //         act = 0;
  //       }
  //       dialogRef.afterClosed().subscribe((confirmed: boolean) => {
  //         if (confirmed) {
  //           var updateVendorData = {
  //             "isBlocked": act,
  //             "id": element["id"],

  //           }
  //           this.updateRowData(updateVendorData);
  //         }
  //       })
  //     }
  // updateRowData(row_obj) {
  //       this.showOverlay = true;

  //       this.cService.updateVendor(row_obj).pipe(first()).subscribe(data => {
  //         if (data != null) {
  //           if (data.responseStatus != "ERROR") {
  //             this._snackBar.open('Vendor Updated Succesfully', 'Ok', {
  //               duration: 5000,
  //             });
  //             // this.dataSource = data;
  //             this.showOverlay = false;
  //             this.getVendors(null);
  //           } else {
  //             this.showOverlay = false;
  //             swal.fire(data.responseMessage)
  //           }
  //         }
  //         // this.showOverlay=false;
  //       }, () => {
  //         this.showOverlay = false;
  //       })

  //     }

  async upload(event: any) {
    let formData = new FormData();
    formData.append("file", event.target.files[0]);
    
    this.myInputVariable.nativeElement.value = "";
    

    

    if (this.supportDocs != null && this.supportDocs != undefined) {
      for (let i = 0; i <= this.supportDocs.length - 1; i++) {
        if (this.supportDocs[i].documentName == event.target.files[0].name) {

          swal.fire("File is already uploaded, please upload defferent file","", "warning");
          return
        }
      }
    }
    (await this.cService.vendorBulkUpload(formData)).pipe(first()).subscribe(res => {
      
      if (res != null) {
        if (res.responseStatus.toUpperCase() == "SUCCESS") {
          swal.fire(res.responseMessage,"", "success");
          this.getVendors(null, this.searchString);
        } else {
          swal.fire(res.responseMessage,"", "error")
        }
      }else {
        swal.fire("File is not Uploaded","", "error");
      }
    })
  }

  openConfirm(element: any) {
    var dialogRef;
    // if(element["isBlocked"] == 1)
    if (element["isBlocked"] == 1) {

      dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: 'Vendor will get access to portal after confirmation',
          buttonText: {
            ok: 'Yes',
            cancel: 'Cancel',
          },
          buttonColor: {
            ok: 'primary',
            cancel: 'primary',
          },
        },
      });
    } else {
      dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: 'Vendor will not be able to access portal after confirmation',
          buttonText: {
            ok: 'Yes',
            cancel: 'Cancel',
          },
          buttonColor: {
            ok: 'primary',
            cancel: 'primary',
          },
        },
      });
    }


    
    let act = element["isBlocked"];
    if (act == '0') {
      act = 1;
    } else {
      act = 0;
    }
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      
      
      if (confirmed) {
        // var updateUserData = {
        //   "isBlocked": act,
        //   "id": element["id"],

        // }
        if(act == 1){
          this.updateRowData(element["id"]);
        }else{
          this.updateRowData1(element["id"]);
        }
        
      }
    })
  }
  updateRowData(row_obj) {
    this.showOverlay = true;

    this.cService.updateVendorUser(row_obj).pipe(first()).subscribe(data => {
      
      if (data != null) {
        if (data.responseStatus != "ERROR") {          
            this._snackBar.open('Vendor Blocked Succesfully', 'Ok', {
              duration: 5000,
            });
          
          // this.dataSource = data;
          this.showOverlay = false;
          this.getVendors(null, this.searchString);
        } else {
          this.showOverlay = false;
          swal.fire(data.responseMessage,"", "error")
        }
      }
      // this.showOverlay=false;
    }, () => {
      this.showOverlay = false;
    })

  }
  updateRowData1(row_obj) {
    this.showOverlay = true;

    this.cService.updateVendorUser1(row_obj).pipe(first()).subscribe(data => {
      
      if (data != null) {
        if (data.responseStatus != "ERROR") {
          // this._snackBar.open('User Updated Succesfully', 'Ok', {
          this._snackBar.open('Vendor Unblocked Succesfully', 'Ok', {
            duration: 5000,
          });
          // this.dataSource = data;
          this.showOverlay = false;
          this.getVendors(null, this.searchString);
        } else {
          this.showOverlay = false;
          swal.fire(data.responseMessage,"", "error")
        }
      }
      // this.showOverlay=false;
    }, () => {
      this.showOverlay = false;
    })

  }

  async exportexcel(searchString:any)
  {
    
    this.mySpinner = true;
  
    // if (event == null) {
    //   this.pageIndex = 0;
    //   this.pageSize = 10;
    // } else {
     
    //     this.pageIndex = "";
    //     this.pageSize = "";
     
    // }
      (await this.cService.getVendorsListExport(this.searchString)).pipe(first()).subscribe(res => {
        if(res.response != null){
          if (res.responseStatus.toUpperCase() == "SUCCESS") {
            
            // this.table = true;
            this.mySpinner = false;
          } else {
            this.mySpinner = false;
            swal.fire(res.responseMessage,"","error");
          }
        }else{
          this.mySpinner = false;
          swal.fire(res.responseMessage,"","error");
        }
  
        let onlyNameAndSymbolArr: Partial<PeriodicElement>[];
  
         onlyNameAndSymbolArr = res.response.map(
          x => ({
            "STATUS":x.isBlocked == 0?'Active':'Blocked',
            "VENDOR CODE":x.vendorCode,
            "VENDOR NAME":(x.nameOne + x.nameTwo),
            "VENDOR GROUP":x.vendorGroup,
            "CITY":x.city,
            "COUNTRY":x.country,
          })
        )
  
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "Vendor List-Report");
  })
  }
}

export interface PeriodicElement {
  status:any,
  vendorCode:any,
  vendorName:any,
  vendorGroup:any,
  city:any
  country:any,
}