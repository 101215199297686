import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ErrorPageService {

    errorNumber : number = 0;
    errorContents : string = '';

    constructor() {
    }

}
