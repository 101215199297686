<mat-card>   
    <mat-card-title>
        <div class="margin-bottotm" fxLayout="row" fxFlex="100">
            <div class="pull-left title" fxFlex="80">
                {{language.orderDetails}}
                <!-- Purchase Order Details -->
            </div>
            <div class="pull-right" fxFlex="20">
                <mat-icon (click)="goTo()" class="close1">
                    close
                </mat-icon>
            </div>
        </div>
    </mat-card-title>

<div id="printPage">
  <mat-card-content>
        <form fxFlex="100" fxLayout="column">
           <div class="mt-2"  fxLayout="row" fxLayout.xs="column" fxFlex="100">
                                            <div class="pODetails-logo f-start" fxFlex="33" fxFlex.xs="100"  fxLayout.xs="row">
                                                <img src="assets/images/background/insee_logo_en.png">
                                            </div>
                                            <div  class="f-middle addr"  fxFlex="33" fxFlex.xs="100" fxLayout="column" fxLayout.xs="column">
                                                <!-- <address> -->
                                                    <!-- 795 Folsom Ave, Suite 600<br>
                                                    San Francisco, CA 94107<br>
                                             <abbr title="Phone">P:<span>(123) 456-7890</span></abbr> -->
                                                        <!-- abctraders@gmail.com -->
  
                                                <!-- </address> -->
                                            </div>
                                            <div class="f-end addr" fxFlex="33" fxFlex.xs="100"  fxLayout="column" fxLayout.xs="column">
                                                <!-- PO.No: 12992 -->
                                                <span><strong>{{language.PONo}}&nbsp; &nbsp;: &nbsp;</strong>{{purchaseOrder.poNumber}}</span>
                                                <!-- <img  src="../../../assets/images/barcode.PNG" width="100" height="50"> -->
                                                <span><strong>{{language.Date}}&nbsp; &nbsp;: &nbsp;</strong>{{purchaseOrder.poCreatedDate | date:"dd/MM/yyyy"}}</span>
                                                <!-- <img style="display: none;" src="../../../assets/images/barcodepo.PNG" width="135" height="50"> -->
                                            </div>
  
                                          
           </div>
           <div fxFlex="100" fxLayout="row">
               <div class="orderDetails" fxFlex="30" >
                    <p>This order details form is just an initial information only. You may find offical PO document with the following<span><a href="https://service.ariba.com/Supplier.aw" target="_blank">&nbsp;Ariba&nbsp;</a></span>link.</p>
               </div>
           </div>
           <div class="mt-2 addr padding-left padding-right"  fxLayout="row" fxLayout.xs="column" fxFlex="100">
                                             <!-- <div class="f-start" fxLayout.xs="column" fxFlex="50" fxFlex.xs="100" >
                                               <img  src="../../../assets/images/barcode.PNG">
                                             </div> -->
  
           </div>
           <div class="mt-2 addr padding-left padding-right poInfoDetail" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxFlex="100">
                                             <div class="poinfovendcode" fxLayout="column" fxFlex="40" fxFlex.xs="100">
  
                                                      <span><strong >{{language.VendorCode}}&nbsp; &nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;</strong><a (click)="onPoInfo(purchaseOrder.vendorId)" class="poInfoCls">{{purchaseOrder.vendorCode}}</a></span>
                                                      <span><span fxFlex="auto"><strong>{{language.vendorName}}&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;</strong></span><span fxFlex="auto" style="max-width: 260px;word-break: break-word;"> <span *ngIf="vendorInfo1.busNameOne != undefined"> {{vendorInfo1.busNameOne}}&nbsp;&nbsp;{{vendorInfo1.busNameTwo}}<br></span></span></span>
                                                      <span>
                                                          <span>
                                                              <strong  fxFlex="auto">{{language.Address}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                                                            </span>
                                                            <span fxFlex="auto">
                                                                        
                                                                
                                                                <span *ngIf="vendorInfo1.busStreet != undefined || vendorInfo1.busStreet != null">{{vendorInfo1.busStreet}}<br></span>
                                                                <span *ngIf="vendorInfo1.busStreetFour != undefined || vendorInfo1.busStreetFour != null">{{vendorInfo1.busStreetFour}}<br></span>
                                                                <span *ngIf="vendorInfo1.busStreetFive != undefined || vendorInfo1.busStreetFive != null">{{vendorInfo1.busStreetFive}}<br></span>
                                                                <span >{{vendorAdd.district}}<br></span>
                                                                <span>{{vendorInfo1.busCity +" - "+vendorInfo1.busPostalCode}}<br> </span>
                                                                <!-- {{vendorDetails.nameOne == null ? '' :vendorDetails.nameOne}}<br>
                                                                 {{vendorDetails.street == null ? '' :vendorDetails.street+", "+vendorDetails.city == null ? '': vendorDetails.city}}<br>
                                                                 {{vendorDetails.district == null ? '' :vendorDetails.district+", "+vendorDetails.postalCode == null ? '' :vendorDetails.postalCode}}<br>
                                                                  <abbr title="Phone">{{vendorDetails.comTelephone == null ? '' :'P:'+vendorDetails.comTelephone}}</abbr> -->
                                                              </span>
                                                      </span>
  
                                            </div>
                                            <div fxFlex="20" fxFlex.xs="0" class="blankDiv"></div>
                                       
                                                 <div fxFlex="40" fxFlex.xs="100" class="page-group" >
  
                                                        <ul class="listStyle">
                                                            <li>
                                                                <strong>{{language.SalePerson}}&nbsp; &nbsp;:&nbsp;&nbsp;&nbsp;</strong>{{vendorDetails.salesPerson}}
                                                            </li>
                                                            <!-- <li>
                                                                <strong>Your Ref&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:&nbsp;&nbsp;&nbsp;</strong>
                                                            </li>
                                                            <li>
                                                                <strong>Our Ref&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:&nbsp;&nbsp;&nbsp;</strong>
                                                            </li> -->
                                                            <li>
                                                                <span>
                                                                    <strong fxFlex="auto">{{language.DeliveryTo}}&nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;</strong>
                                                                </span>
                                                                <span fxFlex="auto" *ngIf="languagePref == 'th'">
                                                                    &nbsp;{{purchaseOrder.addressTh}}
                                                                </span>
                                                                <span fxFlex="auto" *ngIf="languagePref == 'en'">
                                                                    &nbsp;{{purchaseOrder.addressEn}}
                                                                </span>
                                                            </li>
                                                        </ul>
  
                                                 </div>
                                                
                                            
  
           </div>
           <div class="mt-3 addr pull-left padding-left padding-right" fxFlex="100" fxLayout="row" fxLayout.xs="row">
              <ul>
                  <li><strong>{{language.taxIdentificationNo}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</strong>{{taxId == null ? '-' : taxId}}&nbsp;&nbsp;</li>
                  <li *ngIf="branchC == true"><strong>{{language.headOfficeorBranchNo}}&nbsp;&nbsp;:&nbsp;&nbsp;</strong>{{(branchMaster?.branchCode == null || branchMaster?.branchCode == undefined) ? '-' : branchMaster.branchCode}} {{(branchMaster?.branchDescription == null || branchMaster?.branchDescription == undefined) ? '-' : branchMaster.branchDescription}}&nbsp;&nbsp;</li>
                  <li *ngIf="branchC == false"><strong>{{language.headOfficeorBranchNo}}&nbsp;&nbsp;:</strong>&nbsp;&nbsp;{{(vendorOrg?.branchCode == null || vendorOrg?.branchCode == undefined) ? '-' : vendorOrg.branchCode}}  {{(vendorOrg?.branchDescription == null || vendorOrg?.branchDescription == undefined) ? '-' : vendorOrg.branchDescription}}&nbsp;&nbsp;</li>
              </ul>
           </div>
           <div class="mt-3 addr pull-left padding-left padding-right" fxFlex="100" fxLayout="row" fxLayout.xs="row">
            {{language.lineBeforeTable}}:
         </div>
           <!-- <div fxLayout="row"  class="addr padding-left padding-right">
  
                <table class="table table-centered">
                    <thead>
                        <tr>
                            <th class="tableClsx">PO Item</th>
                            <th class="tableClsx">Material Group</th>
                            <th class="tableClsx">Short Text</th>
                            <th class="tableClsx">Item Quantity</th>
                            <th class="tableClsx">Received Quantity</th>
                            <th class="tableClsx">Pending Quantity</th>
                            <th class="tableClsx">Unit Price</th>
                            <th class="tableClsx">Item Amount</th>
                            <th class="tableClsx">Invoiced Amount</th>
                            <th class="tableClsx">Pending Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="tableRow" *ngFor="let item of purchaseOrder.purchaseOrderDetails">
                            <td data-name="PO Item">{{item.poItem}}</td>
                            <td data-name="Material Group">{{item.materialGroup}}</td>
                            <td data-name="Short Test">{{item.shortText}}</td>
                            <td data-name="Item Quantity">{{item.scheduledQuantity}}</td>
                            <td data-name="Received Quantity">{{item.grQuantity}}</td>
                            <td data-name="Pending Quantity">{{item.outStandingQuantity}}</td>
                            <td data-name="Unit Price">{{item.netPrice}}</td>
                            <td data-name="Item Amount">{{item.scheduledQuantity * item.netPrice }}</td>
                            <td data-name="Invoiced Amount">{{item.grQuantity * item.netPrice }}</td>
                            <td data-name="Pending Amount">{{item.outStandingQuantity * item.netPrice}}</td>
                        </tr>
                    </tbody>
                </table>
           </div>  -->
           <div>
  
  
  
                    <mat-table [dataSource]="purchaseOrderInfo" matSort  multiTemplateDataRows
                class=" full-width mat-elevation-z5 main-table poInfoTable font-info">
  
                <ng-container matColumnDef="poItem" >
                <mat-header-cell *matHeaderCellDef class="po-item-po" mat-sort-header >
                    {{language.poItem}}
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="po-item-po" data-label="PO Item :">
                {{element.poItem}}
                </mat-cell>
                </ng-container>
                <ng-container matColumnDef="materialGroup">
                <mat-header-cell *matHeaderCellDef class="material-po" mat-sort-header >
                    {{language.materialGroup}}
                </mat-header-cell>>
                <mat-cell *matCellDef="let element" class="material-po" data-label="Material Group :">
                {{element.materialGroup}}
                </mat-cell>>
                </ng-container>
                <ng-container matColumnDef="shortText">
                <mat-header-cell *matHeaderCellDef class="shortText-po"  mat-sort-header >
                    {{language.shortText}}
                </mat-header-cell>>
                <mat-cell *matCellDef="let element" class="shortText-po" data-label="Short Text :">
                {{element.shortText}}
                </mat-cell>
                </ng-container>
                <ng-container matColumnDef="scheduledQuantity">
                <mat-header-cell *matHeaderCellDef class="ItemQty-po" mat-sort-header >
                    {{language.itemQuantity}}
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="ItemQty-po" data-label="Item Qty :">
                {{element.scheduledQuantity | number : '1.3-3'}}
                </mat-cell>
                </ng-container>
                <ng-container matColumnDef="grQuantity">
                <mat-header-cell *matHeaderCellDef class="recQty-po" mat-sort-header > Received Qty
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="recQty-po" data-label="Received Qty : ">
                {{element.grQuantity | number : '1.3-3'}}
                </mat-cell>
                </ng-container>
                <ng-container matColumnDef="outStandingQuantity">
                <mat-header-cell *matHeaderCellDef class="penQty-po" mat-sort-header >
                Pending Qty
                </mat-header-cell>>
                <mat-cell *matCellDef="let element" class="penQty-po" data-label="Pending Qty : ">
                {{element.outStandingQuantity | number : '1.3-3'}}
                </mat-cell>
                </ng-container>
                <ng-container matColumnDef="netPrice">
                <mat-header-cell *matHeaderCellDef class="unitPrice-po"  mat-sort-header >
                    {{language.netPrice}}
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="unitPrice-po" data-label="Unit Price : ">
                {{element.netPrice | number : '1.2-2'}}
                </mat-cell>
                </ng-container>
                <ng-container matColumnDef="grossValue">
                <mat-header-cell *matHeaderCellDef class="itemAmt-po" mat-sort-header >
                    {{language.itemAmount}}
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="itemAmt-po" data-label="Item Amount : ">
                {{element.grossValue | number : '1.2-2'}}
                </mat-cell>
                </ng-container>
                <ng-container matColumnDef="invoicedAmt">
                <mat-header-cell *matHeaderCellDef class="invoicedAmt-po" mat-sort-header > Invoiced Amount
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="invoicedAmt-po"  data-label="Invoiced Amount : ">
                {{element.grQuantity * element.netPrice | number : '1.2-2'}}
                </mat-cell>
                </ng-container>
                <ng-container matColumnDef="totalAmount">
                <mat-header-cell *matHeaderCellDef class="penAmt-po" mat-sort-header > Pending Amount
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="penAmt-po" data-label="Pending Amount : ">
                {{element.outStandingQuantity * element.netPrice | number : '1.2-2' }}
                </mat-cell>
                </ng-container>
  
                <mat-header-row *matHeaderRowDef="columnsToDisplay">
                </mat-header-row>
                <mat-row *matRowDef="let element; columns: columnsToDisplay;"></mat-row>
                <!-- <mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element">
                </mat-row>> -->
                </mat-table>
  
  
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxFlex="100" fxFlex.xs="100"
        class="addr mt-2">
        <div fxFlex="100" fxFlex.xs="100" class="pull-left">
            <address>
                <strong>{{language.totalinwords}}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;: &nbsp;
                    &nbsp;</strong>{{subTotal | AmountToWordPipe }}
            </address>
        </div>
      <!-- <div fxFlex="50" fxFlex.xs="0"></div> -->
    </div>
    <div fxFlex="100" fxLayout="row" fxLayout.xs="column" class="addr printRow">
        <div fxFlex="50" fxFlex.xs="100">
            <ul class="listStyle">
                <li>
                    <strong>{{language.Currency}}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;: &nbsp; &nbsp;</strong>{{currency==null ? '-': currency}}
                </li>
                <!-- <li>
                    <strong>{{language.AdditionalConditions}}&nbsp;&nbsp;&nbsp;:&nbsp; &nbsp;</strong><span
                        fxFlex="auto">The price is exclusive of VAT.</span>
                </li> -->
                <li>
                    <strong>{{language.PaymentTerm}}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;:
                        &nbsp; &nbsp;</strong>{{purchaseOrder.paymentTermsDescription == null ? '-' : purchaseOrder.paymentTermsDescription}}
                </li>
                <li>
                    <strong>{{language.DownPayment}}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;:
                        &nbsp; &nbsp;</strong>{{dpytAmount == null ? '-' : dpytAmount}}&nbsp;{{currency}}
                </li>
            </ul>
        </div>
        <div class=" page-group" fxFlex="50" fxFlex.xs="100" >
          
                <ul class="listStyle">
                    <!-- <li>
                        <strong>Total Quantity &nbsp;: &nbsp;
                            &nbsp;</strong>{{scheduledQuantity}}
                    </li> -->
                    <li>
                        <strong>{{language.totalAmount}}&nbsp; &nbsp;: &nbsp; &nbsp;</strong>{{newsub}}&nbsp;{{currency}}
                    </li>
                </ul>
            
        </div>
    </div>
        </form>
  </mat-card-content>
</div>  
<div class="printButton" >  
    <button class="printBtn" (click)="printPage('printPage')">
        <mat-icon>print</mat-icon> {{language.print}}              
    </button>           
</div>         
</mat-card>
<div class="overlay" *ngIf="mySpinner">
<div class="center">
    <mat-spinner></mat-spinner>
</div>
</div>
