import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { CommonServicesService } from '../services/common-services.service';
import { PasswordStrengthValidator } from '../shared/password-strength.validators';

@Component({
  selector: 'app-change-pwd',
  templateUrl: './change-pwd.component.html',
  styleUrls: ['./change-pwd.component.css']
})
export class ChangePwdComponent implements OnInit {
  showOverlay: boolean;
  language:any;
  currentUser :any =[];
  constructor(private fb: FormBuilder,private router: Router,public http: HttpClient,public cService:CommonServicesService) { }
  changePwd: FormGroup;
  mySpinner = false;
  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
    if(lang == 'en'){
        this.language = {
        "ChangePassword":	"Change Password",
        "CurrentPassword": 	'Current Password',
        "NewPassword" :	"New Password",
        "ConfirmPassword": 	"Confirm Password",
        "Submit" :	"Submit",
        "swalCurrentP":"Current Password is empty",
        "swalnewP":"New password is empty",
        "swalNotCurP":"Can not use current password",
        "swalWarning":"New passwords must contain at least eight characters, including uppercase,special character,lowercase letters and numbers",
        "swalEmptyP":"Confirm password is empty",
        "swalConfirmWarning":"Confirm passwords must contain at least eight characters, including uppercase,special character,lowercase letters and numbers"



    }}
    else if(lang == 'th'){
      this.language = {
       "ChangePassword":	"เปลี่ยนรหัสผ่าน",
        "CurrentPassword": 	'รหัสผ่านปัจจุบัน',
        "NewPassword" :	"รหัสผ่านใหม่",
        "ConfirmPassword": 	"ยืนยันรหัสผ่าน",
        "Submit" :	"ส่ง",
        "swalCurrentP":"รหัสผ่านปัจจุบันว่างเปล่า",
        "swalnewP":"รหัสผ่านใหม่ว่างเปล่า",
        "swalNotCurP":"ไม่สามารถใช้รหัสผ่านปัจจุบันได้",
        "swalWarning":"รหัสผ่านใหม่ต้องมีอักขระอย่างน้อยแปดตัว รวมทั้งตัวพิมพ์ใหญ่ อักขระพิเศษ ตัวพิมพ์เล็กและตัวเลข",
        "swalEmptyP":"ยืนยันรหัสผ่านว่างเปล่า",
        "swalConfirmWarning":"ยืนยันรหัสผ่านต้องมีอักขระอย่างน้อยแปดตัว รวมทั้งตัวพิมพ์ใหญ่ อักขระพิเศษ ตัวพิมพ์เล็กและตัวเลข"

  }
}

    this.changePwd = this.fb.group({
      oldPassword : ['',[Validators.required, Validators.minLength(5), Validators.maxLength(15)]],
      newPassword : ['',[Validators.required, Validators.minLength(8),Validators.maxLength(15),PasswordStrengthValidator]],
      confirmPassword : ['',[Validators.required, Validators.minLength(8),Validators.maxLength(15),PasswordStrengthValidator]]
    })
  }

  onSubmit(){
    let passregex=/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    
    if (this.changePwd.value.oldPassword == "" || this.changePwd.value.oldPassword == undefined){
      Swal.fire(`${this.language.swalCurrentP}`,"","warning");
      return;
    }
    else if (this.changePwd.value.newPassword == "" || this.changePwd.value.newPassword == undefined){
      Swal.fire(`${this.language.swalnewP}`,"","warning");
      return;
    }
    else if (!passregex.test(this.changePwd.value.newPassword)) {
      Swal.fire(`${this.language.swalWarning}`,"","warning");
      return;
    }
    else if(this.changePwd.value.oldPassword==this.changePwd.value.newPassword){
      Swal.fire(`${this.language.swalNotCurP}`,"","error");
      this.changePwd.setValue({ 
        newPassword : [''],
        confirmPassword : ['']
      })
      return;
    }
    else if (this.changePwd.value.confirmPassword == "" || this.changePwd.value.confirmPassword == undefined){
      Swal.fire(`${this.language.swalEmptyP}`,"","warning");
      return;
    }  
    else if (!passregex.test(this.changePwd.value.confirmPassword)) {
      Swal.fire(`${this.language.swalConfirmWarning}`,"","warning");
      return;
    }
    
    else{
    if(this.changePwd.get("oldPassword").value,this.changePwd.get("newPassword").value){
      
      if (this.changePwd.invalid) {
        return;
      }
      // api/v1/users/changepwd/24
      this.mySpinner = true;  
      this.cService.changePWD(this.changePwd.value).pipe(first()).subscribe(data =>{
        this.mySpinner = false;
        if(data.responseStatus != "ERROR"){
          // Swal.fire("Password changed successfully");
          Swal.fire(
            { 
              icon: 'success',
              title: '',
              text: data.responseMessage,
              footer: ''
          })
          // this.changePwd.get("oldPassword");
          // this.changePwd.reset();
          this.changePwd.setValue({ 
          
            oldPassword : [''],
            newPassword : [''],
            confirmPassword : ['']
          })
          if(data.response.firstTimeLogin == 1)
          {
            localStorage.clear();
            localStorage.removeItem("currentUser");
            this.router.navigate(['/signin'])  
          }else{
            
            localStorage.setItem("firstTimeLogin", "1");
            this.router.navigate(['/dashboard'])
            
          }
          
        }else{
          Swal.fire(
            { 
              icon: 'error',
              title: '',
              text: data.responseMessage,
              footer: ''
          })
        }        
      },error =>{
        this.showOverlay=false;
      });
    }else{

    }
    }
}
  disabled=true;
  get f() { return this.changePwd.controls; }
  changePwdValid(){
    if(this.changePwd.get("oldPassword").value != "" && this.changePwd.get("newPassword").value !=""){
      if(this.changePwd.get("confirmPassword").value.length >= 5){
        
        this.disabled =false;
      }else{
        this.disabled = true;
      }
    }
  }

}
