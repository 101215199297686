
<div class="margin-bottom" fxLayout="row">
    <div fxFlex="90" style="text-align: center;">
        <strong>Choose Menu</strong>
    </div>
    <div class="pull-right" fxFlex="10">
        <mat-icon class="close pull-right" (click)="closeDialog()">close</mat-icon>
    </div>
</div>
<div mat-dialog-content style="display: flex;justify-content: center">
  <div style="margin:5%;display:flex;justify-content: flex-start;">
    <div>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
            <button mat-icon-button disabled></button>
            <mat-checkbox class="checklist-leaf-node"
              [checked]="checklistSelection.isSelected(node)"
              (change)="todoLeafItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
          </mat-tree-node>
        
          <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
            <button mat-icon-button disabled></button>
            <mat-form-field appearance="fill">
              <mat-label>New item...</mat-label>
              <input matInput #itemValue placeholder="Ex. Lettuce">
            </mat-form-field>
            <button mat-button (click)="saveNode(node, itemValue.value)">Save</button>
          </mat-tree-node>
        
          <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'Toggle ' + node.item">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <mat-checkbox [checked]="descendantsAllSelected(node)"
              [indeterminate]="descendantsPartiallySelected(node)"
              (change)="todoItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
          </mat-tree-node>
        </mat-tree>
        <button class="submitButton" (click)="getSelectedValue()" style="width: 90px;height: 26px;border: 0px;">Submit</button>
      </div>
    </div>
  </div>
  <div class="overlay"  *ngIf="mySpinner">
    <div class="center">
        <mat-spinner></mat-spinner> 
    </div>
    </div>