<div class="margin-bottom" fxLayout="row" >
    <div class="pull-left" fxFlex="50">
        <strong>{{action}} Configurations</strong>
    </div>
    <div class="pull-right" fxFlex="50">
        <mat-icon class="close2 pull-right" (click)="closeDialog()">close</mat-icon>
    </div>
</div>

<div mat-dialog-content>
    <!-- <div class="row" *ngIf="action != 'delete'; else elseTemplate"> -->
    <form [formGroup]="roleForm">
                    <mat-form-field  class="width-80" *ngIf="action == 'Add'" appearance="outline">
                        <mat-label>{{language.propKey}}</mat-label>
                        <input matInput placeholder="{{action}}"  formControlName="propKey"
                            [(ngModel)]="local_data.propKey">
                    </mat-form-field>
                    <mat-form-field  class="width-80" style="display: none;" appearance="outline">
                        <mat-label>{{language.propKey}}</mat-label>
                        <input matInput placeholder="{{action}}" readonly  formControlName="id"
                            [(ngModel)]="local_data.id">
                    </mat-form-field>
                    <mat-form-field  class="width-80" *ngIf="action == 'Update'" appearance="outline">
                        <mat-label>{{language.propKey}}</mat-label>
                        <input matInput placeholder="{{action}}" readonly  formControlName="propKey"
                            [(ngModel)]="local_data.propKey">
                    </mat-form-field>
                    <mat-form-field class="width-80" appearance="outline">
                        <mat-label>{{language.propValue}}</mat-label>
                        <textarea matInput type="text" id="text" required placeholder="{{action}}" disabled="isSubmitBtn" 
                        formControlName="propValue" (keydown)="onKeyDownEvent($event)" [(ngModel)]="local_data.propValue"></textarea>
                    </mat-form-field>
                    <p style="font-size:12px; color: red;">{{language.note}} : {{language.line}}</p>
        <div class="modal-buttons" fxLayout.xs = "row">
            <button mat-button mat-flat-button class="submitButton" (click)="onNoClick()"
                style="margin-right:10px">{{action}}</button>
        </div>
    </form>
</div>