<!-- <div fxFlex.gt-sm="100%" fxFlex="100">
    <mat-toolbar>        
        <img  class="logo" src="assets/images/logo-icon2.png">
        <div fxFlex.gt-lg="90" fxFlex.gt-md="90" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100" style="text-align: center;">
            <mat-icon class="home" (click)="home()">home</mat-icon>
        </div>
        <div fxFlex.gt-lg="7" fxFlex.gt-md="7" fxFlex.gt-xs="100" fxFlex="100">
            <button [matMenuTriggerFor]="about" mat-icon-button class="m-r-5">ABOUT US</button>
            <mat-menu #about="matMenu" class="mymegamenu">
                <button mat-menu-item>VISSION, MISSION & COMMITMENTS</button>
                <button mat-menu-item>COMPANY PROFILE</button>
                <button mat-menu-item> EXECUTIVE COMMITTEE</button>
                <button mat-menu-item >BUSINESS PORTFOLIO</button>
            </mat-menu>
        </div>
        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100">
            <button [matMenuTriggerFor]="media" mat-icon-button class="m-r-5">MEDIA</button>
            <mat-menu #media="matMenu" class="mymegamenu">
                <button mat-menu-item>NEWS</button>
                <button mat-menu-item>TV</button>
                <button mat-menu-item>PRINT</button>
            </mat-menu>
        </div>
        <div fxFlex.gt-lg="15" fxFlex.gt-md="15" fxFlex.gt-xs="100" fxFlex="100">
            <button [matMenuTriggerFor]="product" mat-icon-button class="m-r-5">PRODUCTS & SERVICES</button>
            <mat-menu #product="matMenu" class="mymegamenu">
                <button mat-menu-item>INSEE FAMILY</button>
                <button mat-menu-item>LOGISTICS</button>
            </mat-menu>
        </div>
        <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
            <button [matMenuTriggerFor]="career" mat-icon-button class="m-r-5">CAREERS</button>
            <mat-menu #career="matMenu" class="mymegamenu">
                <button mat-menu-item>MANAGER</button>
                <button mat-menu-item>SENIOR ENGINEER</button>
                <button mat-menu-item> JUNIOR ENGINEER</button>
                <button mat-menu-item >INTERNSHIP</button>
            </mat-menu>
        </div>
        
        <div fxFlex.gt-lg="90" fxFlex.gt-md="90" fxFlex.gt-xs="100" fxFlex="100" ></div>
        <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
        <button mat-raised-button class="submitButton" (click)="goTo()" >Login</button>
        </div>
    </mat-toolbar>
<div fxLayout="row" fxLayoutWrap="wrap" >
<div fxFlex.gt-sm="100%" fxFlex="100" >
    <mat-card fxFlex="100" fxFlex.xs="100" class="main">

       <mat-card-content>
        <img  src="assets/images/background/insee_logo_en.png" style="height: 55vh;width: 100%;">

           <p>
            Siam City Concrete Company Limited (SCCO) is the leading ready mixed concrete and aggregates in Thailand consisting of two division – INSEE Concrete and INSEE Aggregates, has its plant network covering Bangkok Metropolitan Region, Upcountry and The Eastern Economic Corridor officially the Eastern Special Development Zone (EEC).
           </p>
           <p>
            SCCO are constantly striving to develop our products and services starting from selecting high quality of raw materials, researching and developing products to serve various specifications, providing on time delivery, including consulting service and product testing. SCCO also received the product’s Carbon Footprint Reduction (CFR) and global warming reduction label is the label that demonstrated an achievement in reduction of the product’s Carbon Footprint as required by the TGO’s Carbon Labeling Program.
           </p>
       </mat-card-content>
    </mat-card>
</div>
</div>
</div> -->


 <mat-toolbar class="toolbar"   >        
            <div fxFlex="20" >
             <div class="landingLogo">
                <img  class="logo" src="assets/images/insee_logo_en.png">
            </div>
            </div>
            
            <div fxFlex="60" >
            
                <!-- <div class="toolbarMenus" >
                        <mat-icon class="home home-icon" (click)="home()">home</mat-icon>
                        <button class="btnAboutUs" [matMenuTriggerFor]="about" mat-icon-button >ABOUT US</button>
                        <mat-menu #about="matMenu" class="mymegamenu">
                            <button mat-menu-item>VISSION, MISSION & COMMITMENTS</button>
                            <button mat-menu-item>COMPANY PROFILE</button>
                            <button mat-menu-item> EXECUTIVE COMMITTEE</button>
                            <button mat-menu-item >BUSINESS PORTFOLIO</button>
                        </mat-menu>
            
                        <button class="btnMedia" [matMenuTriggerFor]="media" mat-icon-button >MEDIA</button>
                        <mat-menu #media="matMenu" class="mymegamenu">
                            <button mat-menu-item>NEWS</button>
                            <button mat-menu-item>TV</button>
                            <button mat-menu-item>PRINT</button>
                        </mat-menu>
            
                        <button class="btnProductNServices" [matMenuTriggerFor]="product" mat-icon-button >PRODUCTS & SERVICES</button>
                        <mat-menu #product="matMenu" class="mymegamenu">
                            <button mat-menu-item>INSEE FAMILY</button>
                            <button mat-menu-item>LOGISTICS</button>
                        </mat-menu>
            
                        <button class="btnCarreers" [matMenuTriggerFor]="career" mat-icon-button >CAREERS</button>
                        <mat-menu #career="matMenu" class="mymegamenu">
                            <button mat-menu-item>MANAGER</button>
                            <button mat-menu-item>SENIOR ENGINEER</button>
                            <button mat-menu-item> JUNIOR ENGINEER</button>
                            <button mat-menu-item >INTERNSHIP</button>
                        </mat-menu>
            
                </div> -->
            </div>
            <div class="landingLoginButton" fxFlex="20" style="display:flex;" >
            <button mat-raised-button class="submitButton landingLogin" (click)="goTo()" >Login</button>
           
            <!-- <br/> -->

            <!-- <a  style="font-size:13px;cursor: pointer;" (click)="privacy()">Privacy Policy</a> -->
        </div>
                </mat-toolbar>

            
       
    
<div fxLayout="row">
<div fxFlex="100" >
    <mat-card fxFlex="100"  class="main">

       <mat-card-content>
        <img class="contentBg-logo" src="assets/images/background/insee_logo_en.png">

           <!-- <p>
            Siam City Concrete Company Limited (SCCO) is the leading ready mixed concrete and aggregates in Thailand consisting of two division – INSEE Concrete and INSEE Aggregates, has its plant network covering Bangkok Metropolitan Region, Upcountry and The Eastern Economic Corridor officially the Eastern Special Development Zone (EEC).
           </p>
           <p>
            SCCO are constantly striving to develop our products and services starting from selecting high quality of raw materials, researching and developing products to serve various specifications, providing on time delivery, including consulting service and product testing. SCCO also received the product’s Carbon Footprint Reduction (CFR) and global warming reduction label is the label that demonstrated an achievement in reduction of the product’s Carbon Footprint as required by the TGO’s Carbon Labeling Program.
           </p> -->
       </mat-card-content>
    </mat-card>
</div> 


