<mat-card>
        <mat-card-title>
            <div class="margin-bottotm" fxLayout="row" fxFlex="100">
                <div class="pull-left title" fxFlex="80">
                    {{language.audit}}
                </div>
            </div>
        </mat-card-title>
        <hr>
    
        <mat-card-content>
    <div fxFlex="100"  fxLayoutWrap="column">
            
        <form [formGroup]="auditReportForm">
            <div fxLayout="row">
                <div class="leftBox"  fxFlex="50" fxLayout="column">
                <div fxFlex="100" fxLayout="row" class="hide">
                <div fxFlex="25">
                    <p class="fontS label">{{language.optype}}</p>
                </div>
                <div fxFlex="75">
                    <ng-multiselect-dropdown  
              placeholder="{{language.selectoptype}}"
              [settings]="dropdownSettings"
              formControlName="optype" 
              [data]="optypelList" 
              >
            </ng-multiselect-dropdown>
                </div>
                </div>
                <div fxFlex="100" fxLayout="row">
                        <div fxFlex="25">
                            <p class="fontS label">{{language.tablename}}</p>
                        </div>
                        <div fxFlex="75">
                            <ng-multiselect-dropdown  placeholder="{{language.selecttablename}}"
                                [settings]="dropdownSettings1"
                                formControlName="selecttablename"
                                [data]="tableList"
                                (onSelect)="onSelect2($event)" (onDeSelect)="onDeSelect2($event)"
                                (onSelectAll)="onSelectAll2($event)" (onDeSelectAll)="onDeSelectAll2($event)">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div fxFlex="100" fxLayout="row" [formGroup]="date">
                            <div fxFlex="25">
                                <p class="fontS label">{{language.DateR}}</p>
                            </div>
                            <div fxFlex="35" fxFlex.xs="100">
                                    <mat-form-field appearance="outline" class="validations">
                                        <input matInput formControlName="DateFrom" (dateChange)="getDNStartDate($event)"
                                            [matDatepicker]="pickerStart3" [max]="maxDate" readonly />
                                        <mat-datepicker-toggle matSuffix [for]="pickerStart3">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #pickerStart3></mat-datepicker>
                                    </mat-form-field>
                            </div>
                            <div fxFlex="5">
                                <p class="fontS to">-</p>
                            </div>
                            <div fxFlex="35" fxFlex.xs="100">
                                <mat-form-field appearance="outline" class="validations">
                                    <input matInput formControlName="DateTo" [disabled]="isInvoiceStartDate" [max]="maxDate"
                                        (dateChange)="getInvoiceEndDate($event)" [matDatepicker]="pickerEnd4" readonly />
                                    <mat-datepicker-toggle matSuffix [for]="pickerEnd4">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickerEnd4></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="100" fxLayout="row">
                                <div fxFlex="25">
                                    <p class="fontS label">{{language.username}}</p>
                                </div>
                                <div fxFlex="50">
                                    <mat-form-field class="example-full-width input" appearance="outline">
                                        <input matInput placeholder="{{language.enterusername}}"
                                        formControlName="username"
                                        [(ngModel)]="username">
                                    </mat-form-field>
                                </div>
                        </div>
                </div>
            </div>
        </form>
       
        <div>
            <div class="buttonGroup">
                <button mat-flat-button (click)="onSubmit()">{{language.Search}}</button>
                <button mat-flat-button (click)="onClear()" >{{language.Clear}}</button>
                
            </div>
        </div>
    </div>        
        </mat-card-content>
    </mat-card>
    <mat-card class="reportTable">
        <mat-card-content>
                <div fxLayout="row" fxLayout.xs="column">
                        <div>
                                <p class="fontS label" style="margin-left:15px">Found:&nbsp;{{listLength == undefined?0:listLength}}&nbsp;Records</p>
                            </div>
                    <!-- <div fxFlex.gt-lg="80" fxFlex.gt-md="80" fxFlex.gt-xs="100" fxFlex="100"></div>  -->
                    <div fxFlex="77" fxFlex.sm="77" fxFlex.xs="100"></div>      
                    <div fxFlex="7" fxFlex.sm="7" fxFlex.xs="100">       
                       <div class="addUserParent">          
                            <a  style="cursor:pointer" (click)="exportexcel()"><img src="assets/images/icons8-export-excel-100.png" height="50px" width="50px" ></a>           
                        </div>           
                    </div>          
                </div>
            <div class="overflow">
                <mat-card>
                    <mat-table [dataSource]="dataSource"  matSort multiTemplateDataRows id="matTable" 
                   class="reportTable" >
                        <ng-container matColumnDef="dbId">
                                <mat-header-cell *matHeaderCellDef class="status-w" mat-sort-header>
                                    {{language.dbId}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Status :" class="status-w">
                                   {{(element.dbId == null || element.dbId == undefined)? '-' : element.dbId}}
                                </mat-cell>
                            </ng-container>   
                            <ng-container matColumnDef="dbUserName">
                                    <mat-header-cell *matHeaderCellDef class="status-w" mat-sort-header>
                                        {{language.dbuserName}}
                                     </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Status :" class="status-w">
                                       {{(element.dbUserName == null || element.dbUserName == undefined)? '-' : element.dbUserName}}
                                    </mat-cell>
                            </ng-container> 
                            <ng-container matColumnDef="applicationUserName">
                                    <mat-header-cell *matHeaderCellDef class="status-w" mat-sort-header>
                                        {{language.appuserName}}
                                     </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Status :" class="status-w">
                                       {{(element.applicationUserName == null || element.applicationUserName == undefined)? '-' : element.applicationUserName}}
                                    </mat-cell>
                            </ng-container> 
                        <ng-container matColumnDef="oldRowData">
                                <mat-header-cell *matHeaderCellDef class="sapResponse-w" mat-sort-header>
                                    {{language.oldRowData}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Material DOC Year :" class="sapResponse-w">
                                        <span class="textWrap" matTooltipClass="custom-tooltip" matTooltipPosition="before"  matTooltip="{{element.oldRowData | json}}">{{(element.oldRowData == null || element.oldRowData == undefined)?"-": element.oldRowData | json}}</span>
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="newRowData">
                                <mat-header-cell *matHeaderCellDef class="sapResponse-w" mat-sort-header>
                                    {{language.newRowData}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Material DOC Year :" class="sapResponse-w">
                                        <span class="textWrap" matTooltipClass="custom-tooltip" matTooltipPosition="before"  matTooltip="{{element.newRowData | json}}">{{(element.newRowData == null || element.newRowData == undefined)?"-":element.newRowData | json}}</span>
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="operationType">
                                <mat-header-cell *matHeaderCellDef class="po-wc" mat-sort-header>
                                    {{language.optype}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Material DOC Year :" class="po-wc">
                                    {{element.dmlType}}
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="featureName">
                                <mat-header-cell *matHeaderCellDef class="table-header shortText1-w" mat-sort-header>
                                    {{language.tablename}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Material DOC Year :" class="shortText1-w">
                                    {{element.featureName}}
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="createdDate">
                                <mat-header-cell *matHeaderCellDef class="invoiceDate-w" mat-sort-header>
                                    {{language.Date}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Material DOC Year :" class="invoiceDate-w">
                                    {{element.createdDate | date:"dd/MM/yyyy HH:mm:ss"}}
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef class="po-wc" mat-sort-header>
                                {{language.status}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="Material DOC Year :" class="po-wc">
                                {{(element.status == null || element.status == undefined)?"-":element.status}}
                            </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="reason">
                        <mat-header-cell *matHeaderCellDef class="sapResponse-w" mat-sort-header>
                            {{language.reason}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="Material DOC Year :" class="sapResponse-w">
                                <span class="textWrap" matTooltipClass="custom-tooltip" matTooltipPosition="before"  matTooltip="{{element.reason}}">{{(element.reason == null || element.reason == undefined)?"-":element.reason}}</span>
                        </mat-cell>
                </ng-container>
                       <mat-header-row *matHeaderRowDef="columnsToDisplay">
                        </mat-header-row>
                        <mat-row *matRowDef="let row; columns: columnsToDisplay;">{{language.Pending}}</mat-row>
    
                    </mat-table>
    
                </mat-card>
            </div>
            <mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" [pageSize]="pageSize"
            [length]="listLength"
            [pageSizeOptions]="[10, 25, 50, 100]" (page)="setTableData($event)"
            style="width:100%"></mat-paginator>
        </mat-card-content>
    </mat-card>
    <div class="overlay" *ngIf="mySpinner">
        <div class="center">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    