<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container">
    <!-- ============================================================== -->
    <!-- Topbar - style you can find in header.scss -->
    <!-- ============================================================== -->
    <mat-toolbar color="white" class="topbar telative">
        <!-- ============================================================== -->
        <!-- Logo - style you can find in header.scss -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" *ngIf="currentUser.firstTimeLogin == 0">
                <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo">
                <!-- Light Logo icon -->
                <img src="assets/images/insee_logo_en.png" alt="homepage" class="light-logo"
                    style="width: 165px; height: 80px;">
                <span fxShow="false" fxShow.gt-xs>
                    <!-- dark Logo text -->
                    <!-- <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo"> -->
                    <!-- Light Logo text -->
                    <!--  <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage"> -->
                </span>
            </a>
            <a class="navbar-brand" *ngIf="currentUser.firstTimeLogin == 1" routerLink="/dashboard">
                <!-- Logo icon -->
                <!-- <b> -->
                <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                <!-- Dark Logo icon -->
                <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo">
                <!-- Light Logo icon -->
                <img src="assets/images/insee_logo_en.png" alt="homepage" class="light-logo"
                    style="width: 165px; height: 80px;">
                <!-- </b> -->
                <!--End Logo icon -->
                <!-- Logo text -->
                <span fxShow="false" fxShow.gt-xs>
                    <!-- dark Logo text -->
                    <!-- <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo"> -->
                    <!-- Light Logo text -->
                    <!--  <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage"> -->
                </span>
            </a>
        </div>
        <!-- <div *ngIf="ScreenSizes">
            <p>Width: <span style="color: red;">{{ screenWidth }}</span> Height: <span style="color: red;">{{ screenHeight }}</span></p>
        
        </div> 
        <button style="margin-left: 20px;" mat-raised-button (click)="getScreenSize()">Get Screen Size</button> -->
        <!-- ============================================================== -->
        <!-- sidebar toggle -->
        <!-- ============================================================== -->

        <button class="menu" mat-icon-button (click)="snav.toggle()" [style.display]=" showMenu ? 'display' : 'none'"
            value="sidebarclosed">
            <mat-icon>menu</mat-icon>
        </button>
        <h2  class="appName"><b>{{langPref}}</b></h2>
        <span fxFlex></span>
        <!-- ============================================================== -->
        <!-- app header component - style you can find in header.scss / header.component.ts-->
        <!-- ============================================================== -->
        <app-header style="display: contents;"></app-header>
    </mat-toolbar>
    <!-- ============================================================== -->
    <!-- End Topbar - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <mat-sidenav #snav id="snav" [style.display]=" showMenu ? 'display' : 'none'" class="dark-sidebar pl-xs"
            [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0" [opened]="mobileQuery.matches"
            [disableClose]="mobileQuery.matches">

            <app-sidebar></app-sidebar>


        </mat-sidenav>
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <mat-sidenav-content class="page-wrapper">

            <div class="page-content">

                <router-outlet>
                    <app-spinner></app-spinner>
                </router-outlet>

            </div>
            <!-- <div class="footer">
                <footer  style="width: max-content; position: absolute; margin-left: 20px;display: flex;">
                    <p> 
                        <a href="/dashboard">Vendor Portal </a>©Copyright 2022
                        <span style="left: 340px; position:relative;">
                            Powered by  
                            <a href="https://www.integramicro.com/">Integra Micro Software Service Pvt.Ltd. </a>
                        </span>
                    </p>
                </footer>
            </div> -->
        </mat-sidenav-content>
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
    </mat-sidenav-container>
</div>
<div class="overlay" *ngIf="mySpinner">
    <div class="center">
        <mat-spinner></mat-spinner>
    </div>
</div>