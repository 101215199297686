<mat-card>
        <mat-card-title>
            <div fxLayout="row" fxFlex.gt-xs="100" fxFlex="100">
                {{headerToDisplay.roleManagement}}
            </div>            
        </mat-card-title>
        <hr>
        <mat-card-content>
            <div fxLayout="row" fxLayoutWrap="wrap">
                <!-- Card column -->
                <div fxFlex.gt-sm="100%" fxFlex="100">
            
                        <div  fxLayout="row"fxLayout.xs="column" >
                            <div class="search" fxFlex="30" fxFlex.sm="50" fxFlex.xs="100">
                                <mat-form-field class="mx-3 max-width">
                                    <mat-label>{{headerToDisplay.search}}</mat-label>
                                    <input matInput placeholder="Search" (keyup)="applyFilter1()" #input [(ngModel)]="searchString">
                                </mat-form-field>
                            </div>
                            
                            <div fxFlex="20" fxFlex.sm="50" fxFlex.xs="100" style="margin-top:15px">
                                <button (click)="applyFilter()" mat-raised-button class="submitButton btn-primary">{{headerToDisplay.search}}</button>
                            </div>
                        </div>
                        <!-- <div class="row mx-2 mt-2 my-2 summaryTab mat-elevation-z8  mx-3 mt-3 full-width"> -->
                        <div class="overflow">
                            <mat-card>
                                <mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="mat-elevation-z5 main-table roleManagementTable">
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef class="min-w" mat-sort-header> ID </th>
                                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="roleName">
                                        <mat-header-cell *matHeaderCellDef class="role-rm-w" mat-sort-header> {{headerToDisplay.rolename}} </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="role-rm-w" data-label ="Role"> {{element.roleName}} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="roleDesc">
                                        <mat-header-cell *matHeaderCellDef class="description-rm-w" mat-sort-header> {{headerToDisplay.roledesc}} </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="description-rm-w" data-label="Description"> {{element.roleDesc}} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="actions">
                                        <mat-header-cell *matHeaderCellDef class="actions-w"> {{headerToDisplay.actions}} </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="actions-w" data-label="Actions">
                                            <!-- <button (click)="openDialog('Update',element)" mat-flat-button class="submitButton"> -->
                                                <!-- <mat-icon aria-hidden="false" class="visibility" aria-label="visibility">visibility</mat-icon> -->
                                                <!-- Update
                                                    
                                            </button> -->
                                            <!-- <button (click)="openDialog('Update',element)" mat-flat-button class="button-actions mx-2">
                                                <mat-icon aria-hidden="false" class="visibility" aria-label="visibility">visibility</mat-icon>
                                            </button> --> 
                                            <button (click)="openDialog('Update',element)" mat-flat-button class="submitButton button-actions mx-2">
                                                <mat-icon aria-hidden="false" aria-label="info">info</mat-icon>
                                                <span class="mx-1">{{headerToDisplay.rolemenus}}</span>
                                            </button>
                                        </mat-cell>
                                    </ng-container>
                                    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                                    <mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"></mat-row>
                                </mat-table>
                        </mat-card>
                    </div>
                    <mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" [pageSize]="pageSize"
                    [length]="listLength" [pageSizeOptions]="[10, 25, 50, 100]"
                    (page)="getRolls($event,'')" style="width:100%"></mat-paginator>
                        <!-- </div> -->
            
                </div>
            </div>
        </mat-card-content>
        <div class="overlay"  *ngIf="mySpinner">
            <div class="center">
                <mat-spinner></mat-spinner> 
            </div>
            </div>
    </mat-card>