import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import { Router } from '@angular/router';
import { PurchaseOrderInterface } from 'src/app/purchase-order/purchase-order';
import { AdminServiceService } from '../services/admin-service.service';
import { first } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import Swal from 'sweetalert2';
import {browserRefresh} from '../app.component';
// import { DlApproveComponent } from '../dl-approve/dl-approve.component';

@Component({
  selector: 'app-weigh-bridge',
  templateUrl: './weigh-bridge.component.html',
  styleUrls: ['./weigh-bridge.component.css']
})
export class WeighBridgeComponent implements OnInit {
  mySpinner: boolean = false;
  expandedElement: PurchaseOrderInterface | null | undefined;
  searchString:any='';
  constructor(private router:Router,private dialog:MatDialog,  private cService: AdminServiceService) { }
  userType:any;
  dataSource:any = [];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  language:any;
  currentUser :any =[];
  browserRefresh:boolean = false;
  listData:any = [];
  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
    if(lang == 'en'){
        this.language = {
          "head": "Weight Bridge Delivery Notes",
          "vendorCode": "Vendor Code",
          "vendorName": "Vendor Name",
          "weightTicketNumber": "Weight Ticket Number",
          "truckPlateNumber": "Truck Plate Number",
          "shipmentNumber": "Shipment Number",
          "weightType": "Weight Type",
          "purchasingDocument": "Purchasing Document",
          "item": 'Item',
          "documentDate": "Vendor Delivery Date",
          "material": "Material No",
          "materialDescription": "Material Description",
          "unit": "Unit",
          "weightInQuantity": "Weight In Quantity",
          "vendorNetWeight": "Vendor Net Weight",
          "WeightOutQuantity": "Weight Out Quantity",
          "materialDocument": "Material Document",
          "materialDocYear": "Material Doc. Year",
          "supplyingPlant": "Supplying Plant",
          "supplyingStorage": "Supplying Storage",
          "receivingPlantInPO": "receiving Plant In PO",
          "receivingStorageInPO": "Receiving Storage In PO",
          "DestinationPlant": "Destination Plant",
          "DestinationStorage": "Destination Storage",
          "requiredQuantity": "Required Quantity",
          "baseUnitOfMeasure": "Base Unit Of Measure",
          "search":"Search",
          "vendoNo":"vendor Delivery Note",
          "checkInDate":"GR Posting Date",
          "convertedNetWeight":"SCCC NET WEIGHT",
          "diffWeight":"Diff Weight"

    }}
    else if(lang == 'th'){
      this.language = {
        "head"	: "บันทึกการส่งมอบสะพานชั่งน้ำหนัก",
        "vendorCode"	:"รหัสผู้ขาย",
        "vendorName":	"ชื่อผู้ขาย/ผู้ให้บริการ",
        "weightTicketNumber":	"หมายเลขตั๋วน้ำหนัก",
        "truckPlateNumber"	:"หมายเลขทะเบียนรถบรรทุก",
        "shipmentNumber":"จำนวนการจัดส่ง",
        "weightType":"ประเภทน้ำหนัก",
        "purchasingDocument":	"เอกสารการจัดซื้อ",
        "item"	:'สิ่งของ',
        "documentDate":	"วันที่จัดส่งของผู้ขาย",
        "material":	"รหัสสินค้า",
        "materialDescription":	"คำอธิบายวัสดุ",
        "unit"	:"หน่วย",
        "weightInQuantity":	"น้ำหนักในปริมาณ",
        "vendorNetWeight"	:"น้ำหนักสุทธิของผู้ขาย",
        "WeightOutQuantity"	:"ปริมาณน้ำหนักออก",
        "materialDocument"	:"เอกสารวัสดุ",
        "materialDocYear":	"เอกสารวัสดุ ปี",
        "supplyingPlant"	:"โรงงานจัดหา",
        "supplyingStorage":	"การจัดหาพื้นที่จัดเก็บ",
        "receivingPlantInPO"	:"รับพืชใน PO",
        "receivingStorageInPO"	:"การรับที่จัดเก็บใน PO",
        "DestinationPlant"	:"โรงงานปลายทาง",
        "DestinationStorage"	:"พื้นที่จัดเก็บปลายทาง",
        "requiredQuantity"	:"ปริมาณที่ต้องการ",
        "baseUnitOfMeasure":	"หน่วยพื้นฐานของการวัด",
        "search":"ค้นหา",
        "vendoNo":"บันทึกการจัดส่งของผู้ขาย",
        "checkInDate":"วันที่โพสต์ GR",
        "convertedNetWeight":"SCCC น้ำหนักสุทธิ",
        "diffWeight":"น้ำหนักส่วนต่าง"
      }
}

    if (this.currentUser.roleID == 2 ){
      this.columnsToDisplay = ['poNumber','itemNo','bldat','vendoNo','truckNo','vendorNetWeight','checkInDate','weighTicketNo','convertedNetWeight','diffWeight','weightUnit','mblnr','mjahr'];
    } else {
      this.columnsToDisplay = ['poNumber','itemNo','bldat','vendoNo','truckNo','vendorNetWeight','checkInDate','weighTicketNo','convertedNetWeight','diffWeight','weightUnit','mblnr','mjahr','vendorCode','vendorName'];
    }
    this.userType = localStorage.getItem("user");
    this.dataSource.sort=this.sort;
    this.dataSource1.sort=this.sort;

    if(localStorage.getItem("searchData") != null){
      this.searchString = localStorage.getItem("searchData")
    }
    
    if(browserRefresh == true){
      this.searchString = '';
      localStorage.setItem("searchData","")
      localStorage.setItem("tableData",JSON.stringify([]))
    }
    let tableData:any = [];
    if(localStorage.getItem("tableData")){
      tableData = JSON.parse(localStorage.getItem("tableData"));
      this.listData = tableData;
    
    }
    
    if(tableData.length == 0){
      this.getWeighBridge(null, this.searchString);
    }else{
      this.dataSource = tableData.response;
      this.listLength = tableData.pagination.count;
      localStorage.setItem("tableData",JSON.stringify([]))
    }
  }
  selection = new SelectionModel<any>(true, []);

  // dataSource:any=[
  //   {
  //     'shipmentNo':"S102020202",'weighTicketNo':"4100087149", 'dlNoteNo':"1010062_2763733", 'dlNoteDate':"2022-01-22",'currency':"THB", 'qty':"22", 'totalAmt':"51480"
  //   },
  //   {
  //     'shipmentNo':"S102020202",'weighTicketNo':"4100087148", 'dlNoteNo':"1010062_2763733", 'dlNoteDate':"2022-01-22", 'currency':"THB",'qty':"20", 'totalAmt':"2000"
  //   }
  // ];
  dataSource1:any=[
    {
      'shipmentNo':"DL12995",'poNumber':"PO1233", 'dlNoteNo':"1010062_2763744","grnNo":"G1234577", 'dlNoteDate':"04/12/2021",'currency':"THB", 'qty':"100", 'totalAmt':"1000"
    },
    {
      'shipmentNo':"DL12996",'poNumber':"PO1232", 'dlNoteNo':"1010062_2763744","grnNo":"G1234577", 'dlNoteDate':"04/12/2021",'currency':"THB", 'qty':"100", 'totalAmt':"500"
    }
  ];
  columnsToDisplay = [];
  columnsToDisplay1 = ['shipmentNo','poNumber', 'dlNoteNo','grnNo', 'dlNoteDate','currency', 'qty', 'totalAmt','actions'];

  onInfo(element: PurchaseOrderInterface){
    localStorage.setItem("tableData",JSON.stringify(this.listData));
    this.router.navigate(["/delivery-notes/delivery-notes-info", "dNotes1"]);
  }

  pageIndex = 0;
  pageSize = 10;
  listLength: any;

  async getWeighBridge(event:any, searchString:string) {
    //this.showOverlay = true;
    this.mySpinner = true;
    if(event ==  null){
      this.pageIndex = 0;
      this.pageSize = 10;
     }else{
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
     }

    (await this.cService.getAllWeighBridge(this.pageIndex, this.pageSize, this.searchString)).pipe(first()).subscribe(res => {
      
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        this.mySpinner = false;
        this.dataSource = res.response;
        this.listData = res;
        this.dataSource.sort=this.sort;
        this.listLength = res.pagination.count;
        } else {
        this.mySpinner = false;
        //this.showOverlay=false;
      }
    }, error => {
      // this.showOverlay=false;
    })
  }

  addDelivery(){
    this.router.navigate(["delivery-notes/add-delivery-notes"])
  }
  openDialog(): void{
    
    // obj.action = _add;
    // let userid = obj.userID;
    // this.dialog.open(DlApproveComponent, {
    //   data: 'Confirm Delivery note approval'
      
    // }).afterClosed()
    // .subscribe((confirm: Boolean) => {
    //   if (confirm) {
    //     // alert("¡A mí también!");
    //   } else {
    //     // alert("Deberías probarlo, a mí me gusta :)");
    //   }
    // })
  }
  applyFilter1(){
    const filterValue = this.searchString;
    localStorage.setItem("searchData", this.searchString);
    // const filterValue = (event.target as HTMLInputElement).value;
    // const filterValue = (event.target as HTMLInputElement).value;//this.searchString;
    if(this.searchString == "" ){
      this.getWeighBridge(null, this.searchString);
    }
  }
  applyFilter() {
    // 
    const filterValue = this.searchString;
    // const filterValue = (event.target as HTMLInputElement).value;
    // const filterValue = (event.target as HTMLInputElement).value;//this.searchString;
    if(filterValue.length >= 2 || this.searchString == "" ){
      this.getWeighBridge(null, this.searchString);
    }
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    
  }
  editDeliveryNote(){
    // 
    // if(this.routeFromD == 'dNotes'){
      this.router.navigate(["delivery-notes/edit-delivery-note" ])
    // }else if(this.routeFromD == 'dNotes1'){
    //   this.router.navigate(["invoice-list/invoice-details", "dNotes1"])
    // }else if(this.routeFromD == 'po'){
    //   this.router.navigate(["invoice-list/invoice-details", "po"])
    // }
    
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.forEach(row => this.selection.select(row));
  }

  async logSelection() {

    let test:any= [];
    this.selection.selected.forEach(s => {
      // 
      // 
       test.push(s.id);

      
    });
    
    if(test.length > 0){
      let req = {
        "ids": test
      };
    this.mySpinner = true;
    
    (await this.cService.bulkApproveWB(req)).pipe(first()).subscribe(res =>{
      if(res.responseStatus.toUpperCase == 'SUCCESS'){
        this.mySpinner = false;
        
        // Swal.fire(res.responseMessage);
        this.selection.clear();
        this.getWeighBridge(null, this.searchString);
        
      }else{
        this.mySpinner = false;
        Swal.fire(res.responseMessage,"","error");
      }
      
    },error =>{
      this.selection.clear();
      this.mySpinner = false;
    })
    this.mySpinner = false;
  }else{
    Swal.fire("Please Select Brands","","warning");
  }
  }

  /** The label for the checkbox on the passed row */
  // checkboxLabel(row?: PeriodicElement): string {
  //   
  //   if (!row) {
  //     return `${this.isAllSelected() ? 'deselect' : 'select'} all`;

  //   }
  //   
  //   return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  // }


searchStr:any;
exportexcel() {
  this.mySpinner = true;
  
  if(this.searchString == '' || this.searchString == undefined){
      this.searchStr = '';
  }else{
      this.searchStr = this.searchString;
  }
    this.cService.exportWeighbridgeData(this.searchStr).pipe(first()).subscribe(res =>{
       this.mySpinner = false;
    },error =>{
       this.mySpinner = false; 
    })
  
  }
}
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];