import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-delivery-notes',
  templateUrl: './add-delivery-notes.component.html',
  styleUrls: ['./add-delivery-notes.component.css']
})
export class AddDeliveryNotesComponent implements OnInit {

  constructor(
    private router: Router, private fb: FormBuilder,
  ) { }
  addDeliveryNotes: FormGroup = this.fb.group({
    poNumber: ['', [Validators.required]],
    dlDate: ['', [Validators.required]],
    postingDate: ['', [Validators.required]],
    totalQty: ['', [Validators.required]],
    header: ['', [Validators.required]],
    dlPerson: ['', [Validators.required]],
    dlNumber: ['', [Validators.required]],
    shipmentNo: ['', [Validators.required]],
    billOfLanding: ['', [Validators.required]],
    totalAmt: ['', [Validators.required]],
    category: ['', [Validators.required]],
    dlPersonEmail: ['', [Validators.required]],
    productInformationMasters: this.fb.array([
    ])

  });
  date: any;
  ngOnInit(): void {
    this.date = new Date();
    this.addDeliveryNotes = this.fb.group({
      poNumber: ['', [Validators.required]],
      dlDate: ['', [Validators.required]],
      postingDate: ['', [Validators.required]],
      totalQty: ['', [Validators.required]],
      header: ['', [Validators.required]],
      dlPerson: ['', [Validators.required]],
      dlNumber: ['', [Validators.required]],
      shipmentNo: ['', [Validators.required]],
      billOfLanding: ['', [Validators.required]],
      totalAmt: ['', [Validators.required]],
      category: ['', [Validators.required]],
      dlPersonEmail: ['', [Validators.required]],
      productInformationMasters: this.fb.array([
      ])
    })
  }
  get productInformationMasters() {
    return this.addDeliveryNotes.get('productInformationMasters') as FormArray;
  }
  goTo() {
    this.router.navigate(["purchase-order/po-info/"])
  }
  createNewTask(){}
}
