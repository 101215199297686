import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DemoMaterialModule } from '../demo-material-module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ChartistModule } from 'ng-chartist';
import { ProfileComponent } from './profile.component';
import { ProfileRoutes } from './profile.routing';
import { StickerComponent } from './profile-components/sticker/sticker.component';
import { ContactsComponent } from './profile-components/contacts/contacts.component';
import { ActivityComponent } from './profile-components/activity/activity.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    DemoMaterialModule,
    FlexLayoutModule,
    ChartistModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(ProfileRoutes)
  ],
  declarations: [ProfileComponent, StickerComponent, ContactsComponent, ActivityComponent]
})
export class ProfileModule {}