<mat-card>
        <mat-card-title>
            <div class="margin-bottotm" fxLayout="row" fxFlex="100">
                <div class="pull-left title" fxFlex="80">
                    {{language.Head}}
                </div>
                <!-- <div class="pull-right" fxFlex="20">
                    <mat-icon  class="close1">
                        close
                    </mat-icon>
                </div> -->
            </div>
        </mat-card-title>
        <hr>
    
        <mat-card-content>
    <div fxFlex="100"  fxLayoutWrap="column">
            
        <form [formGroup]="apReportForm">
            <div fxLayout="row">
                <div class="leftBox"  fxFlex="50" fxLayout="column">
                <div fxFlex="100" fxLayout="row">
                <div fxFlex="25">
                    <p class="fontS label">{{language.company}}</p>
                </div>
                <div fxFlex="75">
                    <ng-multiselect-dropdown  formControlName="multiSelectCompany" 
              placeholder="{{language.selectCompany}}"
              [settings]="dropdownSettings"
              [data]="companyMaster"
              (onSelect)="onSelect($event)"
              (onSelectAll)="onSelectAll($event)"
              (onDeSelect)="onDeSelect($event)"
              (onDeSelectAll)="onDeSelectAll($event)">
            </ng-multiselect-dropdown>
                </div>
                </div>
                <div fxFlex="100" fxLayout="row">
                        <div fxFlex="25">
                            <p class="fontS label">{{language.status}}</p>
                        </div>
                        <div fxFlex="75">
                            <ng-multiselect-dropdown formControlName="multiSelectStatus" placeholder="{{language.SelectStatus}}"
                                [settings]="dropdownSettings1" [data]="poStatus" (onSelect)="onSelect1($event)"
                                (onDeSelect)="onDeSelect1($event)"
                                (onSelectAll)="onSelectAll1($event)"
                                (onDeSelectAll)="onDeSelectAll1($event)">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div fxFlex="100" fxLayout="row">
                        <div fxFlex="25">
                            <p class="fontS label">{{language.poNumber}}</p>
                        </div>
                        <div fxFlex="35">
                            <mat-form-field class="example-full-width input" appearance="outline">
                                <!-- <mat-label>PO Number</mat-label> -->
                                <input matInput placeholder="{{language.EnterPONumberFrom}}" 
                                formControlName="purchaseOrderNoFrom"
                                [(ngModel)]="poNoFrom">
                                </mat-form-field>
                        </div>
                        <div fxFlex="5"><p class="fontS to">-</p></div>
                        <div fxFlex="35">
                                <mat-form-field  class="example-full-width input" appearance="outline">
                                    <!-- <mat-label>To</mat-label> -->
                                    <input  matInput placeholder="{{language.EnterPONumberTo}}"
                                    formControlName="purchaseOrderNoTo"
                                    [(ngModel)]="poNoTo">
                                    </mat-form-field>
                        </div>
                    </div>
                    <!-- <div fxFlex="100" fxLayout="row">
                        <div fxFlex="25">
                            <p class="fontS label">{{language.plantCode}}</p>
                        </div>
                        <div fxFlex="50">
                            <mat-form-field class="example-full-width input" appearance="outline">
                                <input matInput placeholder="{{language.EnterPlantCodeFrom}}" 
                                formControlName="plantCodeFrom"
                                [(ngModel)]="plantCodeFrom">
                            </mat-form-field>
                        </div>
                    </div> -->
                    <div fxFlex="100" fxLayout="row">
                        <div fxFlex="25">
                            <p class="fontS label">{{language.plantname}}</p>
                        </div>
                        <div fxFlex="75">
                            <!-- <mat-form-field class="example-full-width input" appearance="outline">
                                <input matInput placeholder="{{language.EnterPlantName}}" 
                                formControlName="plantName"
                                [(ngModel)]="plantName">
                            </mat-form-field> -->
                            <ng-multiselect-dropdown formControlName="multiSelectPlantName" placeholder="{{language.plantname}}"
                                    [settings]="dropdownSettings4" [data]="plantNameList" 
                                    (onSelect)="onSelect4($event)" (onDeSelect)="onDeSelect4($event)"
                                (onSelectAll)="onSelectAll4($event)" (onDeSelectAll)="onDeSelectAll4($event)">
                                </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div fxFlex="100" fxLayout="row">
                            <div fxFlex="25">
                                <p class="fontS label">{{language.materialNo}}</p>
                            </div>
                            <div fxFlex="75">
                                <ng-multiselect-dropdown formControlName="multiSelectStatus" placeholder="{{language.materialNo}}"
                                    [settings]="dropdownSettings3" [data]="materialList" 
                                    (onSelect)="onSelect3($event)" (onDeSelect)="onDeSelect3($event)"
                                (onSelectAll)="onSelectAll3($event)" (onDeSelectAll)="onDeSelectAll3($event)">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                </div>
                <div class="rightBox" fxFlex="50" fxLayout="column">
                        <div fxFlex="100" fxLayout="row" *ngIf="currentUser.roleID != '2'">
                                <div fxFlex="25">
                                    <p class="fontS label">{{language.vedorCode}}</p>
                                </div>
                                <div fxFlex="75">
                                    <ng-multiselect-dropdown formControlName="multiSelectVendor" placeholder="{{language.vedorCode}}"
                                        [settings]="dropdownSettings2" [data]="vendorMaster" (onSelect)="onSelect2($event)" (onSelectAll)="onSelectAll2($event)"
                                        (onDeSelect)="onDeSelect2($event)" (onDeSelectAll)="onDeSelectAll2($event)">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                </div>
            </div>
        </form>
       
        <div>
            <div class="buttonGroup">
                <button mat-flat-button (click)="onSubmit()">{{language.Search}}</button>
                <button mat-flat-button (click)="onClear()" >{{language.Clear}}</button>
                
            </div>
        </div>
    </div>        
        </mat-card-content>
    </mat-card>
    <mat-card class="reportTable">
        <mat-card-content>
                <div fxLayout="row" fxLayout.xs="column">
                        <div>
                                <p class="fontS label" style="margin-left:15px">Found:&nbsp;{{listLength == undefined?0:listLength}}&nbsp;Records</p>
                            </div>
                    <div fxFlex.gt-lg="80" fxFlex.gt-md="80" fxFlex.gt-xs="100" fxFlex="100"></div>       
                    <!-- <div fxFlex="10" fxFlex.sm="10" fxFlex.xs="100">           
                        <div class="addUserParent">           
                                <a (click)="openPDF()" style="cursor:pointer"><img src="assets/images/icons8-export-pdf-100.png" height="50px" width="50px" ></a>         
                        </div>          
                    </div>            -->
                    <div fxFlex="7" fxFlex.sm="7" fxFlex.xs="100">       
                       <div class="addUserParent">          
                            <a (click)="exportexcel()" style="cursor:pointer"><img src="assets/images/icons8-export-excel-100.png" height="50px" width="50px" ></a>           
                        </div>           
                    </div>          
                </div>
            <div class="overflow">
                <mat-card>
                    <mat-table [dataSource]="dataSource"  matSort multiTemplateDataRows id="matTable" 
                    [ngClass] = "currentUser.roleID == '2'?'reportTable':'reportTable2'">
    
                        <!-- <span > -->
                            <ng-container matColumnDef="status">
                                <mat-header-cell *matHeaderCellDef class="status-w" mat-sort-header>
                                    {{language.status}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Status :" class="status-w">
                                   {{(element.status == null || element.status == undefined)? '-' : element.status}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="company">
                                <mat-header-cell *matHeaderCellDef class="table-header shortText1-w"  mat-sort-header>
                                    {{language.company}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="shortText1-w" data-label="Company :">
                                 {{(element.company == null || element.company == undefined)? '-' : element.company}}
                                </mat-cell>
                            </ng-container>     
                            <ng-container matColumnDef="vendorCode">
                                    <mat-header-cell *matHeaderCellDef class="vendorCode-w"  mat-sort-header>
                                        {{language.vendorCode}}
                                     </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="vendorCode-w" data-label="Company :">
                                     {{(element.vendorCode == null || element.vendorCode == undefined)? '-' : element.vendorCode}}
                                    </mat-cell>
                                </ng-container>     
                                <ng-container matColumnDef="name">
                                        <mat-header-cell *matHeaderCellDef class="table-header shortText1-w"  mat-sort-header>
                                            {{language.name}}
                                         </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="shortText1-w" data-label="Company :">
                                         {{(element.name == null || element.name == undefined)? '-' : element.name}}
                                        </mat-cell>
                                </ng-container>                             
                        <ng-container matColumnDef="poNo">
                            <mat-header-cell *matHeaderCellDef class="vendorCode-w" mat-sort-header>
                                {{language.poNumber}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="vendorCode-w" data-label="PO Number :">
                               {{(element.poNumber == null || element.poNumber == undefined)? '-' : element.poNumber}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="poItem">
                            <mat-header-cell *matHeaderCellDef class="poItem-ws" mat-sort-header>
                                {{language.poItem}} 
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="poItem-ws" data-label="PO Item :">
                               {{(element.poItem == null || element.poItem == undefined)? '-' : element.poItem}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="svLineItem">
                            <mat-header-cell *matHeaderCellDef class="poItem-ws" mat-sort-header>
                                {{language.svLineItem}} 
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="poItem-ws" data-label="PO Item :">
                               {{(element.svLineItem == null || element.svLineItem == undefined || element.svLineItem == "")? '-' : element.svLineItem}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="svText">
                            <mat-header-cell *matHeaderCellDef class="table-header shortText1-w" mat-sort-header>
                                {{language.svText}} 
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="shortText1-w" data-label="PO Item :">
                               {{(element.svText == null || element.svText == undefined)? '-' : element.svText}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="plantCode">
                            <mat-header-cell *matHeaderCellDef class="DOC" mat-sort-header>
                                {{language.plantCode}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="Plant Code :"  class="DOC">
                               {{(element.plantCode == null || element.plantCode == undefined)? '-' : element.plantCode}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="plantName">
                            <mat-header-cell *matHeaderCellDef class="table-header shortText1-w" mat-sort-header>
                                {{language.plantName}} 
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="Plant Name:" class="shortText1-w">
                               {{(element.plantName == null || element.plantName == undefined)? '-' : element.plantName}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="material">
                                <mat-header-cell *matHeaderCellDef class="DOC" mat-sort-header>
                                    {{language.materialNo}} 
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Plant Name:" class="DOC">
                                   {{(element.material == null || element.material == undefined)? '-' : element.material}}
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="serviceNumber">
                                <mat-header-cell *matHeaderCellDef class="DOC"  mat-sort-header>
                                    {{language.serviceNumber}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="DOC" data-label="plantName :">
                                    {{(element.serviceNumber == null || element.serviceNumber == undefined)? '-' : element.serviceNumber}}
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="materialGroup">
                                <mat-header-cell *matHeaderCellDef class="DOC" mat-sort-header>
                                    {{language.materialGroup}} 
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Plant Name:" class="DOC">
                                   {{(element.materialGroup == null || element.materialGroup == undefined)? '-' : element.materialGroup}}
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="shortText">
                                <mat-header-cell *matHeaderCellDef class="table-header shortText1-w" mat-sort-header>
                                    {{language.shortText}} 
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Plant Name:" class="shortText1-w">
                                   {{(element.shortText == null || element.shortText == undefined)? '-' : element.shortText}}
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="poQuantity">
                                <mat-header-cell *matHeaderCellDef class="poItem-ws" mat-sort-header>
                                    {{language.poQuantity}} 
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Plant Name:" class="poItem-ws">
                                   {{(element.poQuantity == null || element.poQuantity == undefined)? '-' : element.poQuantity | number : '1.3-3'}}
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="unit">
                                <mat-header-cell *matHeaderCellDef class="poItem-ws" mat-sort-header>
                                    {{language.unit}} 
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Plant Name:" class="poItem-ws">
                                   {{(element.unit == null || element.unit == undefined)? '-' : element.unit}}
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="unitPrice">
                                <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header>
                                    {{language.unitPrice}} 
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Plant Name:" class="totalAmt-w">
                                   {{(element.unitPrice == null || element.unitPrice == undefined)? '-' : element.unitPrice | number : '1.2-2'}}
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="deliveryDate">
                            <mat-header-cell *matHeaderCellDef class="invoiceDate-w" mat-sort-header>
                                {{language.deliveryDate}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="Delivery Date :" class="invoiceDate-w">
                                {{(element.deliveryNoteDate == null || element.deliveryNoteDate == undefined)? '-' : element.deliveryNoteDate | date:"dd/MM/yyyy"}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="vpdn">
                            <mat-header-cell *matHeaderCellDef class="invoiceDate-w" mat-sort-header>
                                {{language.vpdn}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="VPDN# :" class="invoiceDate-w">
                              {{(element.vpDnNumber == null || element.vpDnNumber == undefined)? '-' : element.vpDnNumber.substring(element.vpDnNumber.indexOf('_')+1)}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="dnNumber">
                            <mat-header-cell *matHeaderCellDef class="dnNo" mat-sort-header>
                                {{language.dnNumber}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="Delivery Note Number :" class="dnNo">
                                {{(element.deliveryNoteNumber == null || element.deliveryNoteNumber == undefined)? '-' : element.deliveryNoteNumber}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="deliveryNoteDate">
                            <mat-header-cell *matHeaderCellDef class="invoiceDate-w" mat-sort-header>
                                {{language.deliveryNoteDate}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="Delivery Date :" class="invoiceDate-w">
                                {{(element.deliveryNoteDate == null || element.deliveryNoteDate == undefined)? '-' : element.deliveryNoteDate | date:"dd/MM/yyyy"}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="dnPostingDate">
                            <mat-header-cell *matHeaderCellDef class="dndate" mat-sort-header> 
                                {{language.dnPostingDate}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="DN Posting Date :" class="dndate" >
                                {{(element.postingDate == null || element.postingDate == undefined)? '-' : element.postingDate | date:"dd/MM/yyyy"}}
                            </mat-cell>
                        </ng-container>   
                        <ng-container matColumnDef="dpEmail">
                            <mat-header-cell *matHeaderCellDef class="companyName-ws" mat-sort-header> 
                                {{language.dpEmail}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="Delivery Person Email :" class="companyName-ws" >
                                {{element.deliveryPersonEmail}}
                            </mat-cell>
                        </ng-container> 
                        <ng-container matColumnDef="dpName">
                            <mat-header-cell *matHeaderCellDef class="dnNo" mat-sort-header> 
                                {{language.dpName}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="Delivery Person Name :" class="dnNo" >
                                {{(element.deliveryPersonName == null || element.deliveryPersonName == undefined)? '-' : element.deliveryPersonName}}
                            </mat-cell>
                        </ng-container>     
                        <ng-container matColumnDef="dnQty">
                            <mat-header-cell *matHeaderCellDef class="poItem-ws" mat-sort-header> 
                                {{language.dnQty}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="poItem-ws" data-label="DN Quantity:">
                                {{(element.dnQuantity == null || element.dnQuantity == undefined)? '-' : element.dnQuantity | number : '1.3-3'}}
                            </mat-cell>
                        </ng-container>   
                        <ng-container matColumnDef="totalAmount">
                            <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header>
                                {{language.totalAmount}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="Total Amount :" class="totalAmt-w">
                               {{(element.totalAmount == null || element.totalAmount == undefined)? '-' : element.totalAmount}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="mDocNumber">
                            <mat-header-cell *matHeaderCellDef class="invoiceDate-w" mat-sort-header>
                                {{language.mDocNumber}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="Material DOC Number :" class="invoiceDate-w">
                                {{(element.materialDocNumber == null || element.materialDocNumber == undefined)? '-' : element.materialDocNumber}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="mDocYear">
                            <mat-header-cell *matHeaderCellDef class="materialDocNum-ws" mat-sort-header>
                                {{language.mDocYear}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="Material DOC Year :" class="materialDocNum-ws">
                                {{(element.materialDocYear == null || element.materialDocYear == undefined)? '-' : element.materialDocYear}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="receivedQty">
                                <mat-header-cell *matHeaderCellDef class="poItem-ws" mat-sort-header>
                                    {{language.receivedQty}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Material DOC Year :" class="poItem-ws">
                                    {{(element.grQuantity == null || element.grQuantity == undefined)? '-' : element.grQuantity | number : '1.3-3'}}
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="pendingQty">
                                <mat-header-cell *matHeaderCellDef class="poItem-ws" mat-sort-header>
                                    {{language.pendingQty}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Material DOC Year :" class="poItem-ws">
                                    {{(element.outStandingQuantity == null || element.outStandingQuantity == undefined)? '-' : element.outStandingQuantity | number : '1.3-3'}}
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="itemAmount">
                                <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header>
                                    {{language.itemAmount}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Material DOC Year :" class="totalAmt-w">
                                    {{(element.itemAmount == null || element.itemAmount == undefined)? '-' : element.itemAmount | number : '1.2-2'}}
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="invoicedAmount">
                                <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header>
                                    {{language.invoicedAmount}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Material DOC Year :" class="totalAmt-w">
                                    {{element.invoicedAmount | number : '1.2-2'}}
                                </mat-cell>
                        </ng-container>
                       <mat-header-row *matHeaderRowDef="columnsToDisplay">
                        </mat-header-row>
                        <mat-row *matRowDef="let row; columns: columnsToDisplay;">{{language.Pending}}</mat-row>
    
                    </mat-table>
    
                </mat-card>
            </div>
            <mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" [pageSize]="pageSize"
            [length]="listLength"
            [pageSizeOptions]="[10, 25, 50, 100]" (page)="setTableData($event)"
            style="width:100%"></mat-paginator>
        </mat-card-content>
    </mat-card>
    <div class="overlay" *ngIf="mySpinner">
        <div class="center">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    