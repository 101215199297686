import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MyModalComponent } from 'src/app/my-modal/my-modal.component';
import { PurchaseOrderInterface } from 'src/app/purchase-order/purchase-order';
import { DlApproveComponent } from '../dl-approve/dl-approve.component';
import { PageEvent } from '@angular/material/paginator';
import { CommonServicesService } from 'src/app/services/common-services.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2';
import { AddDeliveryNotesComponent } from '../add-delivery-notes/add-delivery-notes.component';
import { MatTableDataSource } from '@angular/material/table';
import { elementAt } from 'rxjs-compat/operator/elementAt';
import {browserRefresh} from '../../app.component';



@Component({
  selector: 'app-delivery-notes',
  templateUrl: './delivery-notes.component.html',
  styleUrls: ['./delivery-notes.component.css']
})
export class DeliveryNotesComponent implements OnInit {
  mySpinner:boolean = false;
  expandedElement: PurchaseOrderInterface | null | undefined;
  showOverlay: boolean;
  searchString:any='';
  constructor(private router:Router,private dialog:MatDialog,public _snackBar: MatSnackBar,private cService: CommonServicesService) { }
  userType:any;
  dataSource:MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  currentUser:any;
  language:any;
  columnsToDisplay:any=[];
  browserRefresh:boolean = false;
  listData:any = [];
  ngOnInit(): void {
    this.userType = localStorage.getItem("user");
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    // this.dataSource.sort=this.sort;
    // this.dataSource1.sort=this.sort;
    let lang = this.currentUser.langPreference;
		if(lang == 'en'){

			this.language = {
        "vendorName":"Vendor Name",
        "poNumber":"PO Number",
        "SAPResponse":"SAP Response",
        "plantCode":"Plant Code",
        "poShorttext":"PO Short Text",
         "deliveryNoteNumber":"Delivery Note Number",
         "vpdn":"VPDN#",
			  "vendorCode":"Vendor Code",
			  "shipmentNumber":"Shipment Number",
        "PONumber":"PO Number",
			  "deliveryNoteRefNumVp":"Delivery Ref. Number",
			  "deliveryNoteDate":"Delivery Note Date",
			  "quantity":"Quantity",
			  "deliveryStatus":"Status",
        "totalAmount":"Total Amount",
        "Currency":"Currency",
        "actions":"Actions",
        "search":"Search",
        "CreatedBy":"Created By",
        "Head":" Delivery Notes Pending",
        "button":"Add Delivery Note",
        "outStandingUnit":"Unit",
        "Requisitioner":"Requisitioner"
			}; 

		  }else if(lang == 'th'){

			this.language = {
        "vendorName":"ชื่อผู้ขาย/ผู้ให้บริการ",
        "poNumber":"เลขที่ใบสั่งซื้อ",
        "SAPResponse":"การตอบสนองของ SAP",
        "plantCode":"รหัสโรงงาน/สำนักงาน",
        "poShorttext":"PO ข้อความสั้น",
         "deliveryNoteNumber":"หมายเลขใบส่งสินค้า",
        "vendorCode":"รหัสผู้ขาย",
			  "shipmentNumber":"จำนวนการจัดส่ง",
        "PONumber":"เลขที่ใบสั่งซื้อ",
			  "deliveryNoteRefNumVp":"อ้างอิงการจัดส่ง ตัวเลข",
			  "deliveryNoteDate":"วันที่บันทึกการจัดส่ง",
        "vpdn":"VPDN#",
			  "quantity":"ปริมาณ/จำนวน",
			  "deliveryStatus":"สถานะ",
        "totalAmount":"จำนวนเงินรวม",
        "Currency":"สกุลเงิน",
        "actions":"การดำเนินการ",
        "search":"ค้นหา",
        "Head":" ใบส่งสินค้าที่รอดำเนินการ",
        "button":"เพิ่มใบส่งสินค้า",
        "CreatedBy":"สร้างโดย",
        "outStandingUnit":"หน่วย",
        "Requisitioner":"ผู้ร้องขอ"
			};
		  }

    if(this.currentUser.roleID == 3 || this.currentUser.roleID == 7){
      // this.columnsToDisplay = ["vendorCode","deliveryNoteDate","quantity","totalAmount","deliveryStatus","CreatedBy","actions"];
      this.columnsToDisplay = ["deliveryStatus","actions","vendorName","poNumber","plantCode","prRequisitioner","deliveryNoteRefNumVp","deliveryNoteNumberByVendor","deliveryNoteDate","warehouseReceivedQuantity","amount","sapResponse"];//
    }else{
      this.columnsToDisplay = ["deliveryStatus","actions","poNumber","plantCode","deliveryNoteRefNumVp","deliveryNoteNumberByVendor","deliveryNoteDate","warehouseReceivedQuantity","outStandingUnit","amount","sapResponse"];
    }

    if(localStorage.getItem("searchData") != null){
      this.searchString = localStorage.getItem("searchData")
    }
    if(browserRefresh == true){
      this.searchString = '';
      localStorage.setItem("searchData","")
      localStorage.setItem("tableData",JSON.stringify([]))
    }
    let tableData:any = [];
    if(localStorage.getItem("tableData")){
      tableData = JSON.parse(localStorage.getItem("tableData"));
      this.listData = tableData;
    }
    if(tableData.length == 0){
      this.getDeliveryNoteList(null, this.searchString);
    }else{
      this.dataSource = tableData.response;
      this.listLength = tableData.pagination.count;
      localStorage.setItem("tableData",JSON.stringify([]))
    }
  }

 // "poShorttext"
  
  // columnsToDisplay = ['shipmentNo','poNumber', 'dlNoteNo', 'dlNoteDate','currency', 'qty', 'totalAmt','actions'];
  // columnsToDisplay1 = ["vendorCode",'shipmentNo','poNumber', 'dlNoteNo','grnNo', 'dlNoteDate','currency', 'qty', 'totalAmt','actions'];
  
  onInfo(element: any,type:any){
    localStorage.setItem("tableData",JSON.stringify(this.listData));
    this.router.navigate(["/delivery-notes/delivery-notes-info", element.id,"pending"]);
  }

  addDelivery(){
    this.router.navigate(["delivery-notes/add-delivery-notes"])
  }
  openDialog(): void{
    // obj.action = _add;
    // let userid = obj.userID;
    this.dialog.open(DlApproveComponent, {
      data: 'Confirm Delivery note approval'
      
    }).afterClosed()
    .subscribe((confirm: Boolean) => {
      if (confirm) {
        // alert("¡A mí también!");
      } else {
        // alert("Deberías probarlo, a mí me gusta :)");
      }
    })
  }

  editDeliveryNote(element:any){
    // if(this.routeFromD == 'dNotes'){
      this.router.navigate(["delivery-notes/edit-delivery-note",element.id ])
    // }else if(this.routeFromD == 'dNotes1'){
    //   this.router.navigate(["invoice-list/invoice-details", "dNotes1"])
    // }else if(this.routeFromD == 'po'){
    //   this.router.navigate(["invoice-list/invoice-details", "po"])
    // }
    
  }
  onPoInfo(element:any){
    this.router.navigate(["delivery-notes/po-info", element.poNumber, "p", element.id ])
  }
  newName:any=[];
  pageIndex = 0;
  pageSize = 10;
  listLength: any;
  dataSource1:any;
  async getDeliveryNoteList(event: PageEvent, searchString:any) {
    this.mySpinner = true;
    // this.showOverlay = true;
    if (event == null) {
      this.pageIndex = 0;
      this.pageSize = 10;
    } else {
      if(event.pageIndex == 0){
        this.pageIndex = 0;
        this.pageSize = event.pageSize;
      }else{
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      }
    }
    
    (await this.cService.getDeliveryNoteList(this.currentUser.usertype, this.pageIndex, this.pageSize, this.searchString)).pipe(first()).subscribe(res => { 
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        // this.showOverlay=false;
        this.dataSource = new MatTableDataSource(res.response);
       
        this.listLength = res.pagination.count;
        this.listData = res;
        this.dataSource.sort = this.sort;
        this.mySpinner = false;
      } else {
        // this.showOverlay=false;
        this.mySpinner = false;
        this.dataSource = new MatTableDataSource([]);
        this.listData = [];
        this.listLength = 0;
        
        // swal.fire(res.responseMessage , "", "error");
      }
    }, error => {
      // this.showOverlay=false;
      this.mySpinner = false;
    })
  }
  calculateClass(status) {
    let val = true;
    if (status == "Pending") {
      val = false;
    }
    return {
      "color-green": val,
      "color-red": !val
    }
  }
  onVendorInfo(vendorCode:any){
    this.router.navigate(["delivery-notes/vendor-info", vendorCode])
  }

  applyFilter1(){
    const filterValue = this.searchString;
    localStorage.setItem("searchData", this.searchString);
    // const filterValue = (event.target as HTMLInputElement).value;
    // const filterValue = (event.target as HTMLInputElement).value;//this.searchString;
    if(this.searchString == "" ){
      this.getDeliveryNoteList(null, this.searchString);
    }
  }
  applyFilter() {
    const filterValue = this.searchString;
    // const filterValue = (event.target as HTMLInputElement).value;
    // const filterValue = (event.target as HTMLInputElement).value;//this.searchString;
    if(filterValue.length >= 2 || this.searchString == "" ){
      this.getDeliveryNoteList(null, this.searchString);
    }
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    
  }

}
