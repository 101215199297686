<mat-card class="oh text-center little-profile">
    <img mat-card-image src="assets/images/background/profile-bg.jpg" alt="Photo of a Shiba Inu">
    <mat-card-content>
        <div class="pro-img">
            <img src="assets/images/users/4.jpg" width="100" alt="user" class="img-circle">
        </div>
        <h3 class="m-b-0">Angela Dominic</h3>
        <!-- <h6 class="m-t-0 ">Web Designer &amp; Developer</h6> -->
        <mat-card-actions>
            <button mat-raised-button class="submit">Follow</button>
        </mat-card-actions>
        <div fxLayout="row" fxLayoutWrap="wrap" class="m-t-30">
            <div fxFlex.gt-sm="33.33%" fxFlex.gt-xs="33.33%" fxFlex="100">
                <h3 class="m-0 font-light">1099</h3>
                <small>Articles</small>
            </div>
            <div fxFlex.gt-sm="33.33%" fxFlex.gt-xs="33.33%" fxFlex="100">
                <h3 class="m-0 font-light">23,469</h3>
                <small>Followers</small>
            </div>
            <div fxFlex.gt-sm="33.33%" fxFlex.gt-xs="33.33%" fxFlex="100">
                <h3 class="m-0 font-light">6035</h3>
                <small>Likes</small>
            </div>
        </div>
    </mat-card-content>
</mat-card>