import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MyModalComponent } from '../my-modal/my-modal.component';
import swal from 'sweetalert2';
import { VendorModalComponent } from './vendor-modal/vendor-modal.component';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { first } from 'rxjs/operators';
import { AdminServiceService } from '../services/admin-service.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import {browserRefresh} from '../app.component';
import { TableUtil } from 'src/app/reports/TableUtil';
import { Location } from '@angular/common';

@Component({
  selector: 'app-vendor-management',
  templateUrl: './vendor-management.component.html',
  styleUrls: ['./vendor-management.component.css']
})
export class VendorManagementComponent implements OnInit {
  showOverlay: boolean;
  mySpinner:boolean =false;
  constructor(private dialog: MatDialog, public _snackBar: MatSnackBar, private router: Router, private cService: AdminServiceService,
    private route:ActivatedRoute,private location: Location) { }
  dataSource: MatTableDataSource<any>;
  // columnsToDisplay=["vendorCode","companyCode","vendorGroup","currency","city","country","actions"];
  // columnsToDisplay1=["vendorCode","firstName","lastName","email","mobileNo","status","actions"];
  columnsToDisplay = ["isBlocked","actions","usertype","vendorName","userName","firstName", "lastName", "email", "contactNo", "lastLoginTime"];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  currentUser: any;
  headerToDisplay:any;
  browserRefresh:boolean = false;
  listData:any = [];
  search:any;
  ngOnInit(): void {

    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    // this.getUsersList(null);
    let currentUsers = this.currentUser.response;
		let lang = currentUsers.langPreference;
		

    let states;
    states = this.location.getState();
    

		if(lang == 'en'){
      this.headerToDisplay={
        usertype:"Vendor Code",
        vendorName:"VENDOR NAME",
        firstName:"FIRST NAME",
        lastName:"LAST NAME",
        email:"EMAIL",
        mobileNo:"Contact No",
        status:"Status",
        addUser:"Add User",
        actions:"Actions",
        userManagement:"Vendor User Management",
        search:"Search",
        userName:"User Id",
        lastLoginTime: "Last Login Date & Time"
      }
		  }else if(lang == 'th'){
      this.headerToDisplay={
        usertype:"รหัสผู้ขาย",
        vendorName:"ชื่อผู้ขาย",
        firstName:"ชื่อจริง",
        lastName:"นามสกุล",
        email:"อีเมล",
        mobileNo:"เบอร์ติดต่อ",
        status:"สถานะ",
        addUser:"เพิ่มผู้ใช้",
        actions:"การกระทำ",
        userManagement:"การจัดการผู้ใช้ของผู้จำหน่าย",
        search:"ค้นหา",
        userName:"รหัสผู้ใช้",
        lastLoginTime:"วันที่และเวลาเข้าสู่ระบบล่าสุด"
      }
      }
      
      if(localStorage.getItem("searchData") != null){
        this.searchString = localStorage.getItem("searchData")
      }
      
      if(browserRefresh == true){
        this.searchString = '';
        localStorage.setItem("searchData","")
        localStorage.setItem("tableData",JSON.stringify([]))
      }
      let tableData:any = [];
      if(localStorage.getItem("tableData")){
        tableData = JSON.parse(localStorage.getItem("tableData"));
        this.listData = tableData;
      
      }
      
      if(tableData.length == 0){
        this.searchString = states.search?states.search:"";
        localStorage.setItem("searchData",states.search?states.search:"")
        this.getVendors(null, this.searchString);
      }else{
        this.dataSource = tableData.response;
        this.listLength = tableData.pagination.count;
        localStorage.setItem("tableData",JSON.stringify([]))
      }

  }
  pageIndex = 0;
  pageSize = 10;
  listLength: any;
  searchString:any='';
  getVendors(event: PageEvent, searchString:any) {
    // this.showOverlay = true;
    this.mySpinner = true;
    if (event == null) {
      this.pageIndex = 0;
      this.pageSize = 10;
    } else {
      
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      
    }

    this.cService.getVendorUsers(this.pageIndex, this.pageSize, this.searchString).pipe(first()).subscribe(res => {
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        // this.showOverlay=false;
        this.dataSource = new MatTableDataSource(res.response);
        this.listData = res;
        this.listLength = res.pagination.count;
        this.dataSource.sort = this.sort;
        // this.listLength = res.response.length;
        this.mySpinner = false;
        //

      } else {
        this.mySpinner = false;
        this.dataSource = new MatTableDataSource([]);
        this.listData = [];
        this.listLength = 0;
        
        swal.fire(res.responseMessage,"", "error");
      }
    }, error => {
      this.mySpinner = false;
    })
  }
  openDialog(_add: any, obj: any) {
    obj.action = _add;
    let vendorid = obj.id;
    //

    const dialogRef = this.dialog.open(VendorModalComponent, {
      width: '500px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      
      if (result != undefined) {

        // let roleId;
        // if (result.data.usertype == "Administrator") {
        //   roleId = 1;
        // }

        let vendordata;
        if (result.event == "Add") {
          vendordata = {
            "firstName": result.data.firstName,
            "lastName": result.data.lastName,
            "fullName": result.data.firstName+" "+result.data.lastName,
            "email": result.data.email,
            "contactNo": result.data.contactNo,
            "roleID": "2",
            // "isactive": 1,
            "vendorName": result.data.vendorName,
            "usertype": result.data.usertype,
            "vendorId": result.data.vendorId

        };
        
          this.addVendor(vendordata);
        }
        else if (result.event == "Update") {
          vendordata = {
            "firstName": result.data.firstName,
            "lastName": result.data.lastName,
            "fullName": result.data.firstName + " " + result.data.lastName,
            "email": result.data.email,
            "contactNo": result.data.contactNo,
            "roleID": 2,
            "id":result.data.id,
            "vendorName": result.data.vendorName,
            "usertype": result.data.usertype,
            "vendorId": result.data.vendorId
          };
          
          this.updateVendor(vendordata);
        }
      }

    })
  }

  addVendor(row_obj) {
    this.showOverlay = true;
    this.mySpinner = true;
    this.cService.addVendor(row_obj).pipe(first()).subscribe(data => {
      if (data != null) {
        if (data.responseStatus.toUpperCase() == "SUCCESS") {
          // this._snackBar.open('Vendor Added Succesfully', 'Ok', {
          //   duration: 5000,
          // });
          // this.dataSource = data;
          this.showOverlay = false;
          this.mySpinner = false;
          swal.fire(
            { 
              icon: 'success',
              title: '',
              text: data.responseMessage,
              footer: ''
          }
          );
          this.getVendors(null, this.searchString);
        } else {
          this.showOverlay = false;
          this.mySpinner = false;
          // swal.fire(data.responseMessage)
          swal.fire(
            { 
              icon: 'success',
              title: '',
              text: data.responseMessage,
              footer: ''
          }
          );
        }
      }
      // this.showOverlay=false;
    }, () => {
      this.showOverlay = false;
    })

  }


  updateVendor(row_obj) {
    this.showOverlay = true;
    this.mySpinner = true;
    this.cService.updateVendor(row_obj).pipe(first()).subscribe(data => {
      if (data != null) {
        if (data.responseStatus.toUpperCase() == "SUCCESS") {
          this.mySpinner = false;
          // this._snackBar.open('Vendor Updated Succesfully', 'Ok', {
          //   duration: 5000,
          // });
          // this.dataSource = data;
          this.showOverlay = false;
          swal.fire(
            { 
              icon: 'success',
              title: '',
              text: data.responseMessage,
              footer: ''
          }
          );
          this.getVendors(null, this.searchString);
        } else {
          this.showOverlay = false;
          this.mySpinner = false;
          swal.fire(
            { 
              icon: 'error',
              title: '',
              text: data.responseMessage,
              footer: ''
          });
          // swal.fire(data.responseMessage)
        }
      }
      // this.showOverlay=false;
    }, () => {
      this.showOverlay = false;
    })

  }
  openView(element: any) {
    localStorage.setItem("tableData",JSON.stringify(this.listData));
    this.router.navigate(["vendor-management/vendor-info", element.vendorId,"vendorUser"]);
  }

  applyFilter1(){
    const filterValue = this.searchString;
    localStorage.setItem("searchData", this.searchString);
    if(this.searchString == "" ){
      this.getVendors(null, this.searchString); 
    }
  }
  applyFilter() {
    const filterValue = this.searchString;
    if(filterValue.length >= 2 || this.searchString == "" ){
      this.getVendors(null, this.searchString);
    }
}
  calculateClass(status) {
    let val = true;
    if (status == "1") {
      val = false;
    }
    return {
      "color-green": val,
      "color-red": !val
    }
  }
  //     openConfirm(element: any) {
  //       const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
  //         data: {
  //           message: 'Are you sure you want to block/unblock?',
  //           buttonText: {
  //             ok: 'Submit',
  //             cancel: 'Cancel',
  //           },
  //           buttonColor: {
  //             ok: 'primary',
  //             cancel: '',
  //           },
  //         },
  //       });


  //       //
  //       let act = element["isBlocked"];
  //       if (act == '0') {
  //         act = 1;
  //       } else {
  //         act = 0;
  //       }
  //       dialogRef.afterClosed().subscribe((confirmed: boolean) => {
  //         if (confirmed) {
  //           var updateVendorData = {
  //             "isBlocked": act,
  //             "id": element["id"],

  //           }
  //           this.updateRowData(updateVendorData);
  //         }
  //       })
  //     }
  // updateRowData(row_obj) {
  //       this.showOverlay = true;

  //       this.cService.updateVendor(row_obj).pipe(first()).subscribe(data => {
  //         if (data != null) {
  //           if (data.responseStatus != "ERROR") {
  //             this._snackBar.open('Vendor Updated Succesfully', 'Ok', {
  //               duration: 5000,
  //             });
  //             // this.dataSource = data;
  //             this.showOverlay = false;
  //             this.getVendors(null);
  //           } else {
  //             this.showOverlay = false;
  //             swal.fire(data.responseMessage)
  //           }
  //         }
  //         // this.showOverlay=false;
  //       }, () => {
  //         this.showOverlay = false;
  //       })

  //     }

  openConfirm(element: any) {
    var dialogRef;
    // if(element["isBlocked"] == 1)
    if (element["isBlocked"] == 1) {

      dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: 'Vendor will get access to portal after confirmation',
          buttonText: {
            ok: 'Submit',
            cancel: 'Cancel',
          },
          buttonColor: {
            ok: 'primary',
            cancel: '',
          },
        },
      });
    } else {
      dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: 'Vendor will not be able to access portal after confirmation',
          buttonText: {
            ok: 'Submit',
            cancel: 'Cancel',
          },
          buttonColor: {
            ok: 'primary',
            cancel: '',
          },
        },
      });
    }


    
    let act = element["isBlocked"];
    if (act == '0') {
      act = 1;
    } else {
      act = 0;
    }
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        var updateUserData = {
          "isBlocked": act,
          "id": element["id"],

        }
        
        this.updateRowData(updateUserData);
      }
    })
  }
  updateRowData(row_obj) {
    this.showOverlay = true;
    this.mySpinner = true;
    this.cService.blockUser(row_obj).pipe(first()).subscribe(data => {
      
      if (data != null) {
        if (data.responseStatus != "ERROR") {
          if(row_obj.isBlocked == 1){
            this.mySpinner = false;
            this._snackBar.open('Vendor User Blocked Succesfully', 'Ok', {
              duration: 5000,
            });
          }else{
            this.mySpinner = false;
            this._snackBar.open('Vendor User Unblocked Succesfully', 'Ok', {
              duration: 5000,
            });
          }
          // this.dataSource = data;
          this.mySpinner = false;
          this.showOverlay = false;
          this.getVendors(null, this.searchString);
        } else {
          this.mySpinner = false;
          this.showOverlay = false;
          swal.fire(data.responseMessage,"", "error")
        }
      }
      // this.showOverlay=false;
    }, () => {
      this.showOverlay = false;
    })

  }

  async exportexcel(searchString:any)
  {
    
    this.mySpinner = true;
  
    // if (event == null) {
    //   this.pageIndex = 0;
    //   this.pageSize = 10;
    // } else {
     
    //     this.pageIndex = "";
    //     this.pageSize = "";
     
    // }
      (await this.cService.getVendorUsersExport(this.searchString)).pipe(first()).subscribe(res => {
        if(res.response != null){
          if (res.responseStatus.toUpperCase() == "SUCCESS") {
            
            // this.table = true;
            this.mySpinner = false;
          } else {
            this.mySpinner = false;
            swal.fire(res.responseMessage,"","error");
          }
        }else{
          this.mySpinner = false;
          swal.fire(res.responseMessage,"","error");
        }
  
        let onlyNameAndSymbolArr: Partial<PeriodicElement>[];
  
         onlyNameAndSymbolArr = res.response.map(
          x => ({
            "STATUS":x.isBlocked == 0?'Active':'Blocked',
            "VENDOR CODE":x.usertype,
            "VENDOR NAME":x.vendorName,
            "USER ID":x.userName,
            "FIRST NAME":x.firstName,
            "LAST NAME":x.lastName,
            "EMAIL":x.email,
            "CONTACT NO":x.contactNo
          })
        )
  
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "Vendor Users-Report");
  })
  }

}

export interface PeriodicElement {
  status:any,
  vendorCode:any,
  vendorName:any,
  userId:any,
  firstName:any,
  lastName:any,
  email:any,
  contactNo:any
}