import { Routes } from '@angular/router';
import { VendorInfoComponent } from './vendro-info/vendor-info.component';
import { AddDeliveryNotesComponent } from './add-delivery-notes/add-delivery-notes.component';
import { PoInfoComponent } from './po-info/po-info.component';
import { PurchaseOrderHistoryComponent } from './purchase-order-history/purchase-order-history.component';
import { PurchaseOrderInfoComponent } from './purchase-order-info/purchase-order-info.component';

import { PurchaseOrderComponent } from './purchase-order.component';

// import { DashboardComponent } from './dashboard.component';

export const PuchaseOrderRoutes: Routes = [{
  path: 'purchase-order',
  component: PurchaseOrderComponent
},{
  path: 'purchase-order-history', 
  component: PurchaseOrderHistoryComponent
  },
{
  path: 'purchase-order/purchase-order-info',
  component: PurchaseOrderInfoComponent
},{
  path: 'purchase-order/po-info/:id/:name',
  component: PoInfoComponent
}
,{
  path: 'purchase-order/add-delivery-notes',
  component: AddDeliveryNotesComponent
}
,{
  path: 'purchase-order/vendor-info/:id',
  component: VendorInfoComponent
}
];
