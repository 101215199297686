import { Component, OnInit } from '@angular/core';
import { CommonServicesService } from "src/app/services/common-services.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import swal from "sweetalert2";
import { first } from "rxjs/operators";
import { Router } from "@angular/router";
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
   language:any = 'English';
   display:boolean=true;
   currentUser: any = [];
   mySpinner: boolean = false;
  constructor( private cService: CommonServicesService,public _snackBar: MatSnackBar,
    private router: Router,) { }

  ngOnInit(): void {
    let data = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = data.response;
  }
  // privacylang(lang:any){
  //   this.language = lang;
  //   // this.display=true;
  // }
 accept(e:any){
   if(e.checked){
   this.display = false;
   }else{
     this.display= true;
   }
 }
  async agree(e: any){
    let dn ={
      privacy:"Accepted"
    };
    (await this.cService
      .updatePrivacyPolicy(this.currentUser.id, dn))
      .pipe(first())
      .subscribe(
        (data) => {
            if (data.responseStatus.toUpperCase() == "SUCCESS") {
              this._snackBar.open(data.responseMessage, "Ok", {
                duration: 5000,
              });
              this.mySpinner = false;
               this.router.navigate(["change-pwd"]);
            } else {
              this.mySpinner = false;
              swal.fire(data.responseMessage,"", "error");
            }
        },
        error => {     
          this.mySpinner = false;
          swal.fire(error.error.error,"", "error");
        }
      );
}

}
