import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AdminServiceService } from 'src/app/services/admin-service.service';
// import { PurchaseOrderInterface } from '../purchase-order';
import { TableUtil } from 'src/app/reports/TableUtil';

@Component({
    selector: 'app-vendor-info',
    templateUrl: './vendor-info.component.html',
    styleUrls: ['./vendor-info.component.css']
})
export class VendorInfoComponent implements OnInit {
    mySpinner: boolean = false;
    routeFrom: any;
    Source:any;
    userType: string | null | undefined;
    language:any;
    currentUser:any;
    vendorData:any = [];
    //   expandedElement: PurchaseOrderInterface | null | undefined;
    constructor(private router: Router, private route: ActivatedRoute,private fb:FormBuilder,private cService: AdminServiceService) { }
    sub: any;
    ngOnInit(): void {
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
        let currentUsers = this.currentUser.response;
            let lang = currentUsers.langPreference;
            
            if(lang == 'en'){
                this.language = {
                    "VendorInfo":"Vendor Info",
                    "BasicInfo":"Basic Info",
                    "VendorCode":"Vendor Code",
                    "CompanyCode":"Company Code",
                    "Pmtmeths":"Payment Terms",
                    "VendorAddress":"Vendor Address",
                    "Street":"Street",
                    "City":"City",
                    "District":"District",
                    "PostalCode":"Postal Code",
                    "Country":"Country",
                    "Communication":"Communication",
                    "telephone":"telephone",
                    "Fax":"Fax",
                    "Email":"Email",
                    "Language":"Language",
                    "Salesperson":"Salesperson",
                    "TelePhone":"TelePhone",
                    "SPEmail":"SP-Email",
                    "Street4":"Street Four",
                    "Street5":"Street Five",
                    "nameOne":"Name One",
                    "nameTwo":"Name Two"
                }
              }
              else if(lang == 'th'){
                this.language = {
                    "VendorInfo":"ข้อมูลผู้ขาย",
                    "BasicInfo":"ข้อมูลพื้นฐาน",
                    "VendorCode":"รหัสผู้ขาย",
                    "CompanyCode":"รหัส บริษัท",
                    "Pmtmeths":"เงื่อนไขการชำระเงิน",
                    "VendorAddress":"ที่อยู่ผู้ขาย",
                    "Street": "ถนน",
                    "City":"เมือง",
                    "District":"เขต",
                    "PostalCode":"รหัสไปรษณีย์",
                    "Country":"ประเทศ",
                    "Communication":"การสื่อสาร",
                    "telephone":"โทรศัพท์",
                    "Fax": "แฟกซ์",
                    "Email": "อีเมล",
                    "Language":"ภาษา",
                    "Salesperson":"พนักงานขาย",
                    "TelePhone":"โทรศัพท์",
                    "SPEmail":"พนักงานขาย-อีเมล",
                    "Street4":"สตรีทโฟร์",
                    "Street5":"สตรีทไฟว์",
                    "nameOne":"ชื่อหนึ่ง",
                    "nameTwo":"ชื่อสอง"
                }
              }
        this.sub = this.route.params.subscribe((params) => {
         //   
            this.routeFrom = params["id"];
            this.Source = params["name"]
            
             })
        this.getVendorId(this.routeFrom);
        this.userType = localStorage.getItem("user");
        
        
    }
    vendorInfo : FormGroup= this.fb.group({
        
        vendorCode : ['',[Validators.required]],
        companyCode : ['',[Validators.required]],
        //reconAcc : ['201110',[Validators.required]],
        pmtMeths : ['',[Validators.required]],
        //searchTerms : ['ABC',[Validators.required]],
        // streetAddr : this.fb.group({
        street: ['', Validators.required],
        street4: ['', Validators.required],
        street5: ['', Validators.required],
        city: ['', [Validators.required]],
        district: ['', [Validators.required]],
        postalCode: ['', [Validators.required]],
        country: ['', [Validators.required]],
        //communications
        language: ['', [Validators.required]],
        telphone: ['', [Validators.required]],
        fax: ['', [Validators.required]],
        email: ['', [Validators.required]], 
        salesperson:['', [Validators.required]],
        purTelephone:['', [Validators.required]],
        spEmail:['', [Validators.required]],
        nameOne:['', [Validators.required]],
        nameTwo:['', [Validators.required]],
        

        //   }),

    })

    goTo() {
        if(this.Source =='vendorUser'){
        this.router.navigate(["vendor-management"]);
        }else{
            this.router.navigate(["vendor-list"]);
        }
     }
     vendorAddDetails:any = [];
     isboolean:boolean = false;
     isstored:any;
     isAdded:boolean = false;
     getVendorId(id:any){
         this.mySpinner = true;
        this.cService.getVendorInfoById(id).pipe(first()).subscribe(res =>{
            
            this.vendorData = [];
            this.vendorData.push(res.response);
            let Address = res.response.vendorAddress;
            let email = res.response.vendorEmailDetails;
            let Eemail;
            if(email.length == 0){
                Eemail = '';
            }else{
                Eemail = res.response.vendorEmailDetails[0].email;
            }

            // for(let x of Address){
            //   if(x.addVersion == undefined || x.addVersion == ""){
            //     this.vendorAddDetails = x;
            //   }
            //   else if(x.addVersion == "E"){
            //     this.vendorAddDetails = x;
            //   }
            //   }

              for(let i = 0; i < Address.length; i++){
                if(Address[i].addVersion == undefined || Address[i].addVersion == ""){
                  this.isboolean = true;
                  this.vendorAddDetails = Address[i]
                  break;
              }else if(Address[i].addVersion == "E" && this.isAdded == false){
                this.isAdded = true;
                this.isstored = i;
              }
            }
            if(this.isboolean == false){
              this.vendorAddDetails = Address[this.isstored];
            }
            
            if(res.responseStatus.toUpperCase() == "SUCCESS"){
                this.vendorInfo.controls.vendorCode.setValue(res.response.vendorCode);
                // this.vendorInfo.controls.companyCode.setValue(res.response.companyCode);
                // this.vendorInfo.controls.pmtMeths.setValue(res.response.paymentTerms);
                this.vendorInfo.controls.nameOne.setValue(res.response.nameOne);
                this.vendorInfo.controls.nameTwo.setValue(res.response.nameTwo);
                this.vendorInfo.controls.street.setValue(this.vendorAddDetails.busStreet);
                this.vendorInfo.controls.street4.setValue(this.vendorAddDetails.busStreetFour);
                this.vendorInfo.controls.street5.setValue(this.vendorAddDetails.busStreetFive);
                this.vendorInfo.controls.city.setValue(this.vendorAddDetails.busCity);
                this.vendorInfo.controls.postalCode.setValue(this.vendorAddDetails.busPostalCode);
                this.vendorInfo.controls.country.setValue(this.vendorAddDetails.busCountry);
                this.vendorInfo.controls.language.setValue(res.response.lang);
                this.vendorInfo.controls.district.setValue(res.response.district);
                this.vendorInfo.controls.telphone.setValue(res.response.comTelephone);
                this.vendorInfo.controls.fax.setValue(res.response.comFaxNum);
                this.vendorInfo.controls.email.setValue(Eemail);
                this.vendorInfo.controls.salesperson.setValue(res.response.salesPerson);
                this.vendorInfo.controls.purTelephone.setValue(res.response.spTelephone);
                this.vendorInfo.controls.spEmail.setValue(res.response.spEmail);
                this.mySpinner = false;
            }else{
              // this.showOverlay=false;
            }
          },error =>{
            this.mySpinner = false;
            // this.showOverlay=false;
          })
     }
     async exportexcel(){ 
        let vendorInfo: Partial<PeriodicElement>[];
        vendorInfo = this.vendorData.map(
          x => ({
            "vendorCode":x.vendorCode,
            "nameOne":x.nameOne,
            "nameTwo":x.nameTwo,
            "address":x.address,
            "country":x.country,
            "vendorGroup":x.vendorGroup,
            "postalCode":x.postalCode,
            "centralDeletionFlag":x.centDeletionFlag,
            "centralPostingBgBlock":x.centralPurchasingBlock,
            "cHKFlagDBLInvoice":x.chkFlagDblInvoice,
            "city":x.city,
            "commonFaxNumber":x.comFaxNum,
            "commonTelephone":x.comTelephone,
            "district":x.district,
            "isActive":x.isActive == "Y"? "Yes":"No",
            "isAlternatePayeeDocument":x.isAlternatePayeeDoc,
            "isOneTime":x.isOneTime,
            "keySort":x.keySort,
            "plantRelevant":x.plantRelevant,
            "purchaseOrderCurrency":x.purOrderCurrency,
            "purchaseOrgCreatedBy":x.purOrgCreatedBy,
            "purchaseOrgCreatedDate":x.purOrgCreatedDate,
            "purchasingOrg":x.purchasingOrgm,
            "rowVersion":x.rowVersion,
            "searchCity":x.searchCity,
            "searchNameOne":x.searchNameOne,
            "statusDataTrnsNextRel":x.statusDataTrnsNextRel,
            "street":x.street,
            "taxNumberThree":x.taxNumberThree,
            "venSubRelevant":x.venSubRelevant
          })
        )
        let vendorAddress: Partial<PeriodicElement>[];
        vendorAddress = this.vendorData[0].vendorAddress.map(
          x=> ({
              "addressNumber":x.addressNumber,
              "addValidfrom":x.addValidfrom,
              "addValidTo":x.addValidTo,
              "addVersion": x.addVersion,
              "busNameOne":x.busNameOne,
              "busNameTwo":x.busNameTwo,
              "busCity":x.busCity,
              "busDistrict":x.busDistrict,
              "busPostalCode":x.busPostalCode,
              "transportZone":x.transportZone,
              "busStreet":x.busStreet,
              "busCountry":x.busCountry,
              "busLanguage":x.busLanguage,
              "region":x.region,
              "addGroupKey":x.addGroupKey,
              "searchTermOne":x.searchTermOne,
              "searchTermTwo":x.searchTermTwo,
              "firstTelephoneNum":x.firstTelephoneNum,
              "firstFaxNum":x.firstFaxNum,
              "name1Upper":x.name1Upper,
              "cityUpeer":x.cityUpeer,
              "streetUpper":x.streetUpper,
              "timezone":x.timezone,
              "busStreetFour":x.busStreetFour,
              "busStreetFive":x.busStreetFive
          })
        )

        let vendorEmailDetails: Partial<PeriodicElement>[];
        vendorEmailDetails = this.vendorData[0].vendorEmailDetails.map(
          x=> ({
              "emailPerson": x.emailPerson,
              "emailValidFrom": x.emailValidFrom,
              "emailSeqId": x.emailSeqId,
              "flagDefAddress": x.flagDefAddress,
              "emailHomeAddress": x.emailHomeAddress,
              "email": x.email,
              "emailSearch": x.emailSearch
          })
        )
        let vendorOrg: Partial<PeriodicElement>[];
        vendorOrg = this.vendorData[0].vendorOrg.map(
          x=> (
          {
            "vendorCode": x.vendorCode,
            "companyCode": x.companyCode,
            "vendorCreatedDate": x.vendorCreatedDate,
            "vendorCreatedBy": x.vendorCreatedBy,
            "reconAccountNum": x.reconAccountNum,
            "paymentMethods": x.paymentMethods,
            "blockKeyPayment": x.blockKeyPayment,
            "paymentTerms": x.paymentTerms,
            "planGroup": x.planGroup,
            "preAccountNum": x.preAccountNum,
            "paymentGroupingKey": x.paymentGroupingKey,
            "purchasingOrg": x.purchasingOrg,
            "purOrgCreatedDate": x.purOrgCreatedDate,
            "purOrgCreatedBy": x.purOrgCreatedBy,
            "purOrgVenDeletion": x.purOrgVenDeletion,
            "purOrderCurrency": x.purOrderCurrency,
            "salesPerson": x.salesPerson,
            "spTelephone": x.spTelephone,
            "currency": x.currency,
            "branchCode": x.branchCode,
            "branchDescription": x.branchDescription,
            "bankAccount": x.bankAccount,
            "bankKey": x.bankKey
        }))
        let vendorTax: Partial<PeriodicElement>[];
        vendorTax = this.vendorData[0].vendorTax.map(
          x=> ({
              "grInvoiceVerif": x.grInvoiceVerif,
              "orderAckReq": x.orderAckReq,
              "autoGenPo": x.autoGenPo,
              "evaluatedReceiptSetl": x.evaluatedReceiptSetl,
              "plannedDeliveryTime": x.plannedDeliveryTime,
              "confirmationCtrKey": x.confirmationCtrKey,
              "autoEvalReceiptSetl": x.autoEvalReceiptSetl,
              "srInvoiceVerif": x.srInvoiceVerif,
              "withHldTaxType": x.withHldTaxType,
              "isWithHldTax": x.isWithHldTax,
              "receiptType": x.receiptType,
              "companyCode": x.companyCode
        })
        )
        let vendorTelephoneDetails: Partial<PeriodicElement>[];
        vendorTelephoneDetails = this.vendorData[0].vendorTelephoneDetails.map(
          x=> ({
            "telPerson": x.telPerson,
            "telValidFrom": x.telValidFrom,
            "telSeqId": x.telSeqId,
            "telCountry": x.telCountry,
            "stdComunAddress": x.stdComunAddress,
            "telHomeAddress": x.telHomeAddress,
            "telephone": x.telephone,
            "complTelephone": x.complTelephone,
            "callerTelephone": x.callerTelephone,
            "isMobile": x.isMobile
        })
        )
          let data = [
            {
              arr:vendorInfo,
              name:"Vendor"
            },
            {
              arr:vendorAddress,
              name:"Address"
            },
            {
              arr:vendorEmailDetails,
              name:"Email"
            },
            {
              arr:vendorOrg,
              name:"Organization"
            },
            {
              arr:vendorTax,
              name:"Tax"
            },
            {
              arr:vendorTelephoneDetails,
              name:"Telephone"
            }
          ]
    TableUtil.exportArrayToExcelInMultiSheets(data, "Vendore-Info-Report");
  }
}

export interface PeriodicElement {
    vendorCode:any,
    nameOne:any,
    nameTwo:any,
    address:any,
    country:any,
    createdDate:any,
    vendorGroup:any,
    postalCode:any,
    centralDeletionFlag:any,
    centralPostingBlock:any,
    centralPurchasingBlock:any,
    cHKFlagDBLInvoice:any,
    city:any,
    commonFaxNumber:any,
    commonTelephone:any,
    district:any,
    isActive:any,
    isAlternatePayeeDocument:any,
    isBlocked:any,
    isOneTime:any,
    keySort:any,
    plantRelevant:any,
    purchaseOrderCurrency:any,
    purchaseOrgCreatedBy:any,
    purchaseOrgCreatedDate:any,
    purchasingOrg:any,
    rowVersion:any,
    searchCity:any,
    searchNameOne:any,
    statusDataTrnsNextRel:any,
    street:any,
    taxNumberThree:any,
    venSubRelevant:any,
    addressNumber:any,
    addValidfrom:any,
    addValidTo:any,
    addVersion: any,
    busNameOne:any,
    busNameTwo:any,
    busCity:any,
    busDistrict:any,
    busPostalCode:any,
    transportZone:any,
    busStreet:any,
    busCountry:any,
    busLanguage:any,
    region:any,
    addGroupKey:any,
    searchTermOne:any,
    searchTermTwo:any,
    firstTelephoneNum:any,
    firstFaxNum:any,
    name1Upper:any,
    cityUpeer:any,
    streetUpper:any,
    timezone:any,
    busStreetFour:any,
    busStreetFive:any,
    emailPerson: any,
    emailValidFrom: any,
    emailSeqId: any,
    flagDefAddress: any,
    emailHomeAddress: any,
    email: any,
    emailSearch: any,
    endorId: any,
    urOrgCreatedDate: any,
    urOrgCreatedBy: any,
    urOrgVenDeletion: any,
    urOrderCurrency: any,
    alesPerson: any,
    pTelephone: any,
    urrency: any,
    ranchCode: any,
    ranchDescription: any,
    ankAccount: any,
    ankKey: any,
    grInvoiceVerif: any,
    orderAckReq: any,
    autoGenPo: any,
    evaluatedReceiptSetl: any,
    plannedDeliveryTime: any,
    confirmationCtrKey: any,
    autoEvalReceiptSetl: any,
    srInvoiceVerif: any,
    withHldTaxType: any,
    isWithHldTax: any,
    receiptType: any,
    companyCode: any,
    telPerson: any,
    telValidFrom: any,
    telSeqId: any,
    telCountry: any,
    stdComunAddress: any,
    telHomeAddress: any,
    telephone: any,
    complTelephone: any,
    callerTelephone: any,
    isMobile: any
  }