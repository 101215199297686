import { Routes } from '@angular/router';
import { ChangePwdComponent } from './change-pwd/change-pwd.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { DeliveryNotesModule } from './delivery-notes/delivery-notes.module';

import { FullComponent } from './layouts/full/full.component';
import { NotificationDetailsComponent } from './notifications/notification-details/notification-details.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileModule } from './profile/profile.module';
import { PurchaseOrderModule } from './purchase-order/purchase-order.module';
import { ResponseMessageComponent } from './response-messages/response-message.component';
import { ResponsiveTableComponent } from './responsive-table/responsive-table.component';
import { RoleManagementComponent } from './role-management/role-management.component';
import { SigninComponent } from './signin/signin.component';
import { UserManagementComponent } from './user-management/user-management.component';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { WeighBridgeComponent } from './weigh-bridge/weigh-bridge.component';
import { CreditNoteComponent } from './credit-note/credit-note.component';
import { DebitNoteComponent } from './debit-note/debit-note.component';
import { VendorManagementComponent } from './vendor-management/vendor-management.component';
import { VendorInfoComponent } from './vendor-management/vendro-info/vendor-info.component';
import { VendorListComponent } from './vendor-list/vendor-list.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuditRecordsComponent } from './audit-records/audit-records.component';
import {PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ReportsModule } from './reports/reports.module';
import { LoginComponent } from './login/login.component';
import { UserModalComponent } from 'src/app/user-management/user-modal/user-modal.component';
import { MasterRecordsComponent } from 'src/app/master-records/master-records.component';
import { ErrorLoginComponent } from 'src/app/error-login/error-login.component';
import { UserAuditLogComponent } from './user-audit-log/user-audit-log.component';


export const AppRoutes: Routes = [
  {
    path: 'landing-page',
    component: LandingPageComponent,
  },
  {
    path: '',
    component: LandingPageComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'error-login', component: ErrorLoginComponent
  },
  {
    path: 'signin',
    component: SigninComponent,
  },
  { path: 'resetpassword/:id/:token', component: ForgotPasswordComponent },

  {
    path: '',
    component: FullComponent,
    children: [ 
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: '',
        loadChildren:
          () => import('./material-component/material.module').then(m => m.MaterialComponentsModule)
      },
      {
        path: '',
        loadChildren: () => import('./invoice/invoice.module').then(m => m.InvoiceModule)
      },  
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },     
      {
        path: '', loadChildren: () => PurchaseOrderModule
      },
      {
        path: '', loadChildren: () => DeliveryNotesModule
      },
      {
        path: '', loadChildren: () => ReportsModule
      },
      {
        path: 'notification', component: NotificationsComponent
      },
      {
        path: 'change-pwd', component: ChangePwdComponent
      },
      {
        path: 'profile', loadChildren: () => ProfileModule
      },
      {
        path: 'user-management', component: UserManagementComponent
      },
      {
        path: 'role-management', component: RoleManagementComponent
      },
      {
        path: 'app-configurations', component: ConfigurationComponent
      },
      {
        path: 'response-messages', component: ResponseMessageComponent
      },
      {
        path: 'audit', component: AuditRecordsComponent
      },
      {
        path: 'master', component: MasterRecordsComponent
      },
      {
        path: 'vendor-management', component: VendorManagementComponent
      },
      {
        path: 'vendor-list', component: VendorListComponent
      },
      {
        path: 'privacy-policy', component: PrivacyPolicyComponent
      },
      {
        path: 'notification/notification-details',
        component: NotificationDetailsComponent
      },
      {
        path: 'responsive-table',
        component: ResponsiveTableComponent
      }
      ,
      {
        path: 'user-management/user-modal/:id',
        component: UserModalComponent
      },
      {
        path: 'vendor-management/vendor-info/:id/:name',
        component: VendorInfoComponent
      },
      {
        path: 'weigh-bridge',
        component: WeighBridgeComponent
      },
      {
        path: 'credit-note',
        component: CreditNoteComponent
      },
      {
        path: 'debit-note',
        component: DebitNoteComponent
      },
      {
        path: 'user-audit-log', component: UserAuditLogComponent
      },
    ]
  },     
  {
    path: '**',
    component: NotFoundComponent,
  },
    ]

