import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../interceptors/auth.service';
import { first } from 'rxjs/operators';
import { PasswordStrengthValidator } from '../shared/password-strength.validators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  // sub;
  mySpinner = false;
  showOverlay = false;
  error: string = "";
  email: any;
  form: FormGroup;
  hide = true;
  constructor(private router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private authservice: AuthService,
    private fb: FormBuilder) {

  }


  claimId: any;
  claimToken: any;
  sub: any;
  ngOnInit() {
      this.form = this.fb.group({
      usertype: ['',Validators.required],
      userName: ['',Validators.required],
      password: ['',[Validators.required,Validators.minLength(8),Validators.maxLength(12),PasswordStrengthValidator]],
      confirmPassword:['',[Validators.required,Validators.minLength(8),Validators.maxLength(12),PasswordStrengthValidator]],
    });
    this.sub = this.route.params.subscribe((params) => {
      this.claimId = params['id'];
      this.claimToken = params['token'];
      // let data = JSON.parse(localStorage.getItem("nviForm"));
      if (this.claimId) {
        this.getUserDetails(this.claimId, this.claimToken);
        // this.claimView = data;
        // this.dataLoaded = true;
      }
      else {
        this.router.navigate(["/page-not-found"]);
      }
    })
  }
  userName: any;
  usertype:any;
  getUserDetails(claimId, claimtoken) {
    this.authservice.getUserDetails(claimId, claimtoken).pipe(first()).subscribe(data => {
      this.usertype = this.claimId;
      this.userName = data.userName;
    }, error => { })
  }
  submit() {
    this.showOverlay = true;
    let obj = {
      "userId": this.form.value.usertype,
      "userName": this.form.value.userName,
      "newPassword": this.form.value.password,
      "confirmPassword": this.form.value.confirmPassword,
    }
    this.mySpinner = true;
    this.authservice.resetPwd(obj, this.claimToken).pipe(first()).subscribe(data => {
    this.mySpinner = false;
      // const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      // data: {
      // message: 'Are you sure want to reset password?',
      // buttonText: {
      // ok: 'Submit',
      // cancel: 'Cancel',
      // },
      // buttonColor: {
      // ok: 'primary',
      // cancel: '',
      // },
      // },
      // });
      // dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      // if (confirmed) {
      // element.approvedRejected = ApprovedRejected.APPROVED;
      this._snackBar.open('Password reset succefully', 'Ok', {
        duration: 5000,
      });
      // }
      this.showOverlay = false;
      // this.getData();
      this.router.navigate(["/signin"]).then(() => {
        // this.showOverlay = true;
        window.location.reload();
      });
      // window.location.reload();
      // }) },error =>{
      this.showOverlay = false;
    })
  }



// }
goTo(){

  this.router.navigate(["/signin"]);
}

  
}
