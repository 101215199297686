import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-notification-details",
  templateUrl: "./notification-details.component.html",
  styleUrls: ["./notification-details.component.css"],
})
export class NotificationDetailsComponent implements OnInit {
  mySpinner: boolean = false;
  routeFrom: any;
  activeData: any;
  userType: string | null | undefined;
  sub: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {}

  notificationInfo: FormGroup = this.fb.group({
    subject: ["", [Validators.required]],
    body: ["", [Validators.required]],
  });

  ngOnInit(): void {
    // this.sub = this.route.params.subscribe((params) => {
    //   
    //   this.routeFrom = params["id"];
    // });
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.userType = currentUser.response;

    this.activeData = {
      apiName: "create_delivery_note",
      body: "Welcome to Vendor Management Portal! You have created Delivery Note with id --> 320 and your delivery Note Reference number VP is --> 26687_2022_2916583.",
      id: 1,
      notificationType: "email",
      rowVersion: 18,
      subject: "Notification from INSEE Vendor Portal",
    };

    this.notificationInfo.controls.subject.setValue(this.activeData.subject);
    this.notificationInfo.controls.body.setValue(this.activeData.body);
  }

 

  goTo() {
    this.router.navigate(["/notification"]);
  }
}
