<mat-card>
  <mat-card-title>
    <div class="margin-bottotm" fxLayout="row" fxFlex="100">
      <div class="pull-left title" fxFlex="80">
       {{language.head}}
      </div>
      <div class="pull-right" fxFlex="20">
        <mat-icon (click)="goTo()" class="close1">
          close
        </mat-icon>
      </div>
    </div>
  </mat-card-title>
  <hr />

  <mat-card-content>
    <div fxLayout="row" fxLayoutWrap="wrap" class="page-body">
      <div fxFlex.gt-sm="100%" fxFlex="100">
        <div fxLayout="column">
          <!-- <form  [formGroup]="addDeliveryNotes" (submit)="createNewTask()"> -->
          <!-- <div fxFlex.gt-sm="50%" fxFlex="100"> -->
          
            <div fxLayout="row" fxLayout.xs="column" fxFlex="100">
              <div class="padding-right" *ngIf="currentUser.roleID == '3' || currentUser.roleID == '7'" fxFlex="33" fxFlex.xs="100">
               
                  <mat-label *ngIf="show" class="poclass" style="font-weight: 500;">{{language.vendorCode}}</mat-label>
                  <ng-multiselect-dropdown placeholder="{{language.vendorCode}}" 
                  [settings]="dropdownSettings1" [data]="vendorList" [(ngModel)]="vendorCodes"
                  (onSelect)="changeVendor($event)"
                  (onDeSelect)="onItemDeSelectVn($event)" 
                  (onDropDownClose)="onDropDownClose()">
                </ng-multiselect-dropdown>
                <!-- </mat-form-field> -->
              </div>
              <div class="padding-right padding-left" fxFlex="33" fxFlex.xs="100" >
               
                <mat-label *ngIf="showP" class="poclass1" style="font-weight: 500;width: 10%;height:20px">{{language.companyName}}</mat-label>
                <ng-multiselect-dropdown  placeholder="{{language.companyName}}"
                  [settings]="dropdownSettings4" [data]="companyMaster"
                  (onSelect)="changeCompany($event)"
                  (onDeSelect)="onItemDeSelectCom($event)" 
                  >
                </ng-multiselect-dropdown>
              </div>

              <div class="padding-right padding-left" fxFlex="33" fxFlex.xs="100" >
               
                <mat-label *ngIf="showCN" class="poclass1" style="font-weight: 500;">{{language.plantName}}</mat-label>
                <ng-multiselect-dropdown  placeholder="{{language.plantName}}"  [disabled]="displayData"
                  [settings]="dropdownSettings2" [data]="plantMaster" [(ngModel)]="plantCode"
                  (onSelect)="changePlant($event)"
                  (onDeSelect)="onItemDeSelectCN($event)" 
                  (onDropDownClose)="onDropDownClose()">
                </ng-multiselect-dropdown>
              </div>
              <div class="padding-right padding-left" fxFlex="33" fxFlex.xs="100" *ngIf="currentUser.roleID == '2'" >
                <mat-label *ngIf="showPO" class="poclass" style="font-weight: 500;">{{language.PONumber}}</mat-label>
                <ng-multiselect-dropdown placeholder="{{language.PONumber}}"  [disabled]="displayData"
                  [settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems"
                  (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                  (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)"
                  (onDropDownClose)="onDropDownClose()"> 
                </ng-multiselect-dropdown>
              </div>
             
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxFlex="100" class="mt-2" style="margin-top: 5px;" *ngIf="currentUser.roleID == '3' || currentUser.roleID == '7'">
            <div class="padding-right" fxFlex="33" fxFlex.xs="100" >
              <mat-label *ngIf="showPO" class="poclass" style="font-weight: 500;">{{language.PONumber}}</mat-label>
              <ng-multiselect-dropdown placeholder="{{language.PONumber}}"  [disabled]="displayData"
                [settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems"
                (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)"
                (onDropDownClose)="onDropDownClose()">
              </ng-multiselect-dropdown>
            </div>
          </div>


            <form fxLayout="column" class="basic-form" [formGroup]="addDeliveryNotes">
            <div fxLayout="row" fxLayout.xs="column" fxFlex="100" class="mt-2" style="margin-top: 5px;">
              <div class="padding-right" fxFlex="33" fxFlex.xs="100">
                <mat-form-field appearance="outline" class="validations">
                  <mat-label>{{language.deliveryNoteDate}}</mat-label>
                  <input matInput [(ngModel)]="deliveryDate" formControlName="dlDate" [value]="deliveryDate" required
                    [matDatepicker]="picker" />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="padding-right padding-left" fxFlex="33" fxFlex.xs="100">
                <mat-form-field appearance="outline" class="validations">
                  <mat-label>{{language.deliveryNoteNumber}}</mat-label>
                  <input matInput formControlName="dlNum" type="text" minlength="1" maxlength="16" 
                  (keypress)="DLNumMax($event)"
                  required />
                </mat-form-field>
              </div>
              <div class="padding-right padding-left" fxFlex="33" fxFlex.xs="100">
                <mat-form-field appearance="outline" class="validations">
                  <mat-label>{{language.postingDate}}</mat-label>
                  <input matInput [(ngModel)]="deliveryDate1" formControlName="psDate" [value]="deliveryDate1"
                    [matDatepicker]="picker1" required readonly />
                  <!-- <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle> -->
                  <mat-datepicker #picker1></mat-datepicker>
                  <!-- {{deliveryDate}} -->
                  <!-- <input matInput formControlName="psDate" type="date" required /> -->
                </mat-form-field>
              </div>
            </div>
            
              <!-- <div class="padding-right" fxFlex="33" fxFlex.xs="100">
                <mat-form-field appearance="outline" class="validations">
                  <mat-label>Shipment Number</mat-label>
                  <input matInput formControlName="shipmentNo" type="text" />
                </mat-form-field>
              </div>
              <div class="padding-right padding-left" fxFlex="33" fxFlex.xs="100">
                <mat-form-field appearance="outline" class="validations">
                  <mat-label>Bill Of Lading</mat-label>
                  <input matInput formControlName="billOfLading" type="text"  />
                </mat-form-field>
              </div> -->
            
            <div fxLayout="row" fxLayout.xs="column" fxFlex="100">
              <div class="padding-right" fxFlex="33" fxFlex.xs="100">
                <mat-form-field appearance="outline" class="validations">
                  <mat-label>{{language.totalQuantity}}</mat-label>
                  <input matInput formControlName="totalQty" type="text" required readonly />
                </mat-form-field>
              </div>
              <div class="padding-right padding-left" fxFlex="33" fxFlex.xs="100">
                <mat-form-field appearance="outline" class="validations">
                  <mat-label>{{language.totalAmount}}</mat-label>
                  <input matInput formControlName="totalAmt"  type="text" required readonly />
                </mat-form-field>
              </div>
              <div class="padding-right padding-left" fxFlex="33" fxFlex.xs="100">
                <mat-form-field appearance="outline" class="validations">
                  <mat-label>{{language.headerText}}</mat-label>
                  <input matInput formControlName="hedText" type="text" minlength="1" maxlength="25" (keypress)="header($event)"
                  />
                </mat-form-field>
              </div>
              <!-- <div class="padding-right padding-left" fxFlex="33" fxFlex.xs="100">
                            <mat-form-field appearance="outline">
                              <mat-label>Vendor Name</mat-label>
                              <input matInput formControlName="venName" type="text" required />
                            </mat-form-field>
                          </div> -->
              
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxFlex="100">
              <div class="padding-right" fxFlex="33" fxFlex.xs="100">
                <mat-form-field appearance="outline" class="validations">
                  <mat-label>{{language.deliveryPersonEmail}}</mat-label>
                  <input matInput formControlName="dlPersonEmail" type="text" />
                </mat-form-field>
              </div>
              <div class="padding-right padding-left" fxFlex="33" fxFlex.xs="100">
                <mat-form-field appearance="outline" class="validations">
                  <mat-label>{{language.deliveryPersonName}}</mat-label>
                  <input matInput formControlName="dlPerson" type="text" (keydown)="Space($event)" minlength="1" maxlength="12" (keypress)="dlPerson($event)"
                  />
                  <!-- (keypress)="keypressalpha($event)" -->
                </mat-form-field>
              </div>
              <!-- <div class="padding-right padding-left" fxFlex="33" fxFlex.xs="100">
                            <mat-form-field appearance="outline" class="validations">
                              <mat-label>Purchasing Group</mat-label>
                              <input matInput formControlName="prGroup" type="text" required />
                            </mat-form-field>
                          </div> -->
            </div>

          </form>

          <div fxFlex="100" fxLayout="row" fxLayout.xs="column" style="padding-bottom: 2px;">
            <div class="padding-right" fxFlex="50">
              {{language.attachments}}:
              <!-- <input type="file" (change)="onFileChanged($event)" /> -->
              <div class="custom-file width-80">
                <!-- <form method="post" enctype="multipart/form-data">
                  <input (change)="upload($event)" #myInput type="file" accept=".jpeg,.png,.pdf,.doc,.docx"
                    class="custom-file-input" aria-describedby="inputGroupFileAddon01" />
                </form>
                <label class="custom-file-label" for="inputGroupFile01">Choose File</label> -->
                <form method="post" enctype="multipart/form-data">
                  <label for="upfile" class="custom-file-input">
                      <input  #myInput id="upfile" type="file" (change)="upload($event)" accept=".jpeg,.png,.pdf,.doc,.docx" 
                    class="custom-file-input" aria-describedby="inputGroupFileAddon01" />
                  </label>
                </form>
              </div>
              <label style="font-size: 12px;"> {{language.note}}: {{language.noteLine1}}<br/>{{language.noteLine2}} </label>
            </div>
            <div fxFlex.gt-sm="50" fxFlex="100">
              <ul *ngFor="let download of datacache;let item of listValue">
                <li>
                  <a (click)="downLoad(item)" style="cursor: pointer;">

                    <!-- <mat-icon [inline]="true" aria-hidden="false" aria-label="Download">get_app</mat-icon> -->
                    {{ item.documentName | slice:0:25 }}{{ item.documentName.length > 25 ? '....' : '' }}
                  </a>
                  <a style="cursor:pointer">
                    <mat-icon (click)="deleteItem(item.id)" style="font-size:17px">delete</mat-icon>

                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="deliveryNoteDetails" fxFlex="100" fxLayout="row">
            <h5>{{language.deliveryNoteDetails}}</h5>
          </div>

          <div class="overflow">
            <mat-table [dataSource]="deliveryDetails" multiTemplateDataRows
              class="mat-elevation-z8 main-table deliveryNoteDetailsTable">
              <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef class="actions-w actions-align">
                  <span>{{language.actions}}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Actions" class="actions-w">
                  <span >
                    <mat-icon class="delete" *ngIf="deliveryDetails.length > 1" (click)="removeItem(element.purchaseOrderDetailsId)"
                        aria-hidden="false" aria-label="delete">
                       delete
                    </mat-icon>
                    </span>
                  <span *ngIf="subCateg">
                    <mat-icon (click)="
                      expandedElement = expandedElement === element ? null : element;toggle()"
                      *ngIf="currentUser.roleID != '2'"
                      aria-hidden="false" 
                      class="expand"
                    >
                      expand
                    </mat-icon>
                  </span>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="delQty">
                <mat-header-cell *matHeaderCellDef class="editDelQty-w">{{language.deliveringQty}}</mat-header-cell>
                <mat-cell *matCellDef="let element;let i=index" class="editDelQty-w">
                  <input type="text" (keyup)="onDelQtyChange($event,element)"
                  (keypress)="allowTwodigit($event)"
                  (keypress)="notAllow($event)"
                  (keypress)="notAllowHyphen($event)"
                  (keypress)="keyPressNumbers($event)"
                  (blur)="blurFunction($event,element)"
                  [value]="element.deliveryQty == null ? '': element.deliveryQty " #input step="0.01" maxlength="13" (copy)="$event.preventDefault()" (paste)="$event.preventDefault()" appBlockCopyPaste/>
                </mat-cell>
                
              </ng-container>
              <ng-container matColumnDef="unittype">
                <mat-header-cell *matHeaderCellDef class="editPoNo-w">{{language.unit}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="PO Number" class="editPoNo-w">
                 {{element.outStandingUnit != undefined ? element.outStandingUnit : '-'}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="poNumber">
                <mat-header-cell *matHeaderCellDef class="editPoNo-w">{{language.PONumber}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="PO Number" class="editPoNo-w">
                  <a (click)="onPoInfo(element.poNumber)" class="poInfoCls" style="text-decoration: underline; color: #0088cc">
                    {{ element.poNumber != null ? element.poNumber : '-' }}
                  </a>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="poItem">
                <mat-header-cell *matHeaderCellDef class="editPoItem-w">{{language.POItem}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="PO Item" class="editPoItem-w">
                  {{ element.poItem != null ? element.poItem : '-' }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="plantName">
                <mat-header-cell *matHeaderCellDef class="plantName-w">{{language.plantName}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Material Group" class="plantName-w">
                  {{ element.plantName != null ? element.plantName : '-' }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="materialNo">
                <mat-header-cell *matHeaderCellDef class="editMaterialNo-w">{{language.materialNo}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Material No" class="editMaterialNo-w">
                  {{ element.material != null ? element.material : '-' }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="storageLocation">
                <mat-header-cell *matHeaderCellDef class="editStorageLocation-w">
                  <!-- <span *ngIf="currentUser.roleID == '3' || currentUser.roleID == '7'"> -->
                    {{language.storageLocation}}
                  <!-- </span> -->
                </mat-header-cell>
            
                  <mat-cell *matCellDef="let element" data-label="Storage Location" class="editStorageLocation-w">
                    <!-- <span> -->
                      <mat-select appearance="outline" style="border: 0.5px solid black;width: 90%;"
                        (selectionChange)="onStorageChange($event,element)" >

                        <mat-option [(ngModel)]="element.storageLocation" name="storageLocation" ngDefaultControl *ngFor="let item of storageMaster" selected 
                          [value]="item.storageLocationCode">
                          {{ item.name }}

                        </mat-option>
                      </mat-select>
                    <!-- </span> -->
                  </mat-cell>
                <!-- </span> -->
              </ng-container>
              <ng-container matColumnDef="category">
                <mat-header-cell *matHeaderCellDef class="editCategory-w">
                  {{language.category}}
                </mat-header-cell>
                  <mat-cell *matCellDef="let element" data-label="Category" class="editCategory-w">
                    <mat-select appearance="outline" [(ngModel)]="element.category" style="border: 0.5px solid black;width: 90%;" (selectionChange)="onCategoryChange($event,element)">
                      <mat-option value="001">Blocked Stock (MIGO #001)</mat-option>
                      <mat-option value="002">GR PO Creation (MIGO #002)</mat-option>
                      <mat-option value="001-1">Release GR Blocked Stock (MIGO #001-1)</mat-option>
                      <mat-option value="005">Sub Contract GR (MIGO #005)</mat-option>
                      <mat-option value="008">GR of Scheduling Agreement (MIGO #008)</mat-option>
                    </mat-select>
                  </mat-cell>
                
              </ng-container>
              <ng-container matColumnDef="shortText">
                <mat-header-cell *matHeaderCellDef class="editShortText-w">{{language.shortText}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Short Text" class="editShortText-w">
                  {{ element.shortText != null ? element.shortText : '-' }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="poQty">
                <mat-header-cell *matHeaderCellDef class="editPoQty-w">{{language.POQTY}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Short Text" class="editPoQty-w">
                  {{ element.scheduledQuantity | number : '1.3-3'  }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="unit">
                <mat-header-cell *matHeaderCellDef class="unit-w">{{language.unit}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Short Text" class="unit-w">
                  {{ element.outStandingUnit != undefined ? element.outStandingUnit : '-' }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="deliveryDate">
                <mat-header-cell *matHeaderCellDef class="deliveryDate-w">{{language.deliveryDate}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Short Text" class="deliveryDate-w">
                  {{ element.poDeliveryDate | date:"dd/MM/yyyy" }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="receivedQty">
                <mat-header-cell *matHeaderCellDef class="editReceivedQty-w">{{language.receivedQTY}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Received Qty" class="editReceivedQty-w">
                  {{ element.grQuantity | number : '1.3-3'   }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="pendingQty">
                <mat-header-cell *matHeaderCellDef class="editPendingQty-w">{{language.pendingQTY}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Pending Qty" class="editPendingQty-w">
                  {{ element.outStandingQuantity | number : '1.3-3'  }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="unitPrice">
                <mat-header-cell *matHeaderCellDef class="editUnitPrice-w">{{language.unitPrice}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Unit Price" class="editUnitPrice-w">
                  {{ element.netPrice | number : '1.2-2'  }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="poAmount">
                <mat-header-cell *matHeaderCellDef class="editPoAmount-w">{{language.itemAmount}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Item Amount" class="editPoAmount-w">
                  {{ element.grossValue | number : '1.2-2'}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="invoicedAmount">
                <mat-header-cell *matHeaderCellDef class="invoicedAmount-w">{{language.invoicedAmount}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Invoiced Amount" class="invoicedAmount-w">
                  {{element.grQuantity * element.netPrice | number : '1.2-2' }}
                </mat-cell>
              </ng-container>
             
              <ng-container matColumnDef="totalAmt">
                <mat-header-cell *matHeaderCellDef class="editDNtotalAmt-w">{{language.totalAmount}}</mat-header-cell>
                <mat-cell *matCellDef="let element" class="editDNtotalAmt-w">
                  {{element.totalAmt | number:'1.2-2'}}
                </mat-cell>
              </ng-container>
             
              <ng-container matColumnDef="expandedDetail" >
                <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                  <div class="example-element-detail" *ngIf="currentUser.roleID != '2'" [@detailExpand]="element == expandedElement? 'expanded': 'collapsed' && lCategory?'expanded':'collapsed'"
                  >
                    <div class="overflow1">
                      <table class="basic">
                        <thead>
                          <tr style="display: flex;">
                            <th class="tableClsx glAccount-h">
                              {{language.subItemNo}}
                            </th>
                            <th class="tableClsx qty-h">Quantity</th>
                            <th class="tableClsx costCenter-h">
                              {{language.materialNo}}
                            </th>
                            <th class="tableClsx costCenter-h" *ngIf="currentUser.roleID == '3' || currentUser.roleID == '7'" >
                              {{language.bom}}
                            </th>
                            <th class="tableClsx costCenter-h" style="margin-left: -5px;margin-right: -3px;">
                              {{language.shortText}}
                            </th>
                            
                          </tr>
                        </thead>
                        <tbody>
                          <!-- <tr *ngFor="let element of dataSubSource; let i=index"> -->
                          <tr style="display: flex;">
                            <td data-name=" Sub Item No">
                              {{ element.itemNo != null ? element.itemNo : '-' }}
                            </td>
                            <td data-name="Quantity" class="quantity">
                              <input type="text" [value]="0 | number : '1.3-3'" (keyup)="onSubQty($event,element)"
                              (keypress)="allowTwodigit($event)"
                              (keypress)="notAllow($event)"
                              (keypress)="keyPressNumbers($event)"
                              (keypress)="notAllowHyphen($event)"
                              (blur)="blurFunction($event,element)"
                                [(ngModel)]="element.quantity " step="0.01" maxlength="13" appBlockCopyPaste/>
                            </td>
                            <td data-name="Material No">
                              {{ element.subContractMaterial != null ? element.subContractMaterial : '-' }}
                            </td>
                            <td data-name="BOM" *ngIf="currentUser.roleID == '3' || currentUser.roleID == '7'">
                              {{ element.billOfMaterial != null ? element.billOfMaterial : '-' }}
                            </td>
                            <td data-name="Short Text">
                              {{ element.shortText != null ? element.shortText : '-' }}
                            </td>
                            
                          </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </td>
              </ng-container>
              <!-- ,,,unitPrice,poAmount,delQty,totalAmt -->
              <mat-header-row *matHeaderRowDef="columnsToDisplay;let i = index;"></mat-header-row>
              <mat-row *matRowDef="let element; columns: columnsToDisplay; let i = index;" class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"></mat-row>
              
              <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row expandRow"></mat-row>
            </mat-table>
          </div>
          
          <div fxFlex="100" class="mt-2">

            <div class="buttonGroup">
              <!-- <button mat-raised-button class="leftBtn submitButton" (click)="onsave()">
                Save
              </button> -->
              <button mat-raised-button class="leftBtn submitButton" (click)="onSubmit()">
                {{language.submit}}
              </button>
              <button mat-raised-button class="rejectButton" routerLink="/delivery-notes">
                {{language.cancel}}
              </button>
            </div>
          </div>
          <!-- </form> -->
          <!-- </form> -->
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<div class="overlay" *ngIf="mySpinner">
  <div class="center">
    <mat-spinner></mat-spinner>
  </div>
</div>
