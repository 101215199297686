import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApReportComponent } from './ap-report/ap-report.component';
import { CheckerReportComponent } from './checker-report/checker-report.component';
import { VendorReportComponent } from './vendor-report/vendor-report.component';
import {DnReportComponent} from './dn-report/dn-report.component';
import { PoReportComponent } from 'src/app/reports/po-report/po-report.component';
const routes: Routes = [
  {
    path: 'vendor-report',
    component: VendorReportComponent
  },
  {
    path: 'ap-report',
    component: ApReportComponent
  },
  {
    path: 'checker-report',
    component: CheckerReportComponent
  },
  {
    path: 'dn-report',
    component: DnReportComponent
  },
  {
    path: 'po-report',
    component: PoReportComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
