import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { SigninDTO } from "../model-dto/signin-dto";
import Swal from "sweetalert2";
import { saveAs } from 'file-saver';
@Injectable({
    providedIn: "root",
})
export class CommonServicesService {
 


    constructor(private http: HttpClient) {
    }
    async getCompanyMaster(vendorCode:any) {
        return this.http
            .get<any>(`${environment.serverUrl}/company-list/${vendorCode}`)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }
    async getMasterData() {
        return this.http
            .get<any>(`${environment.serverUrl}/master-data`)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }
    async getauditMasterData() {
        return this.http
            .get<any>(`${environment.serverUrl}/master-audit`)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }
    async getDNMasterData() {
        return this.http
            .get<any>(`${environment.serverUrl}/master-data?dn=true`)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }
   async getPlantMaster(com) {
        return this.http
            .get<any>(`${environment.serverUrl}/plant-master/list?companyCode=${com}`)
            // .get<any>(`${environment.serverUrl}/plant-master/list`)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }

    async getPlantMasterForReport() {
        return this.http
            .get<any>(`${environment.serverUrl}/plant-master/list`)
            // .get<any>(`${environment.serverUrl}/plant-master/list`)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }
    async getPlantMasterSec() {
        return this.http
            .get<any>(`${environment.serverUrl}/plant-master/list`)
            // .get<any>(`${environment.serverUrl}/plant-master/list`)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }
   async getBranchMaster(vendorId,companyCode) {
        return this.http
            .get<any>(`${environment.serverUrl}/add-invoice-branch-code/${vendorId}/${companyCode}`)
            // .get<any>(`${environment.serverUrl}/plant-master/list`)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }
    async getVendorMaster(vendorId,companyCode) {
        return this.http
            .get<any>(`${environment.serverUrl}/vendors/${vendorId}?companyCode=${companyCode}`)
            // .get<any>(`${environment.serverUrl}/plant-master/list`)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }
    async getstorageLocation(plantId: any) {
        return this.http
            .get<any>(`${environment.serverUrl}/plant-master/storage/list/${plantId}`)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }

   async getPONumbers(plantId, category,vendorId,com) {
        return this.http
            .get<any>(
                `${environment.serverUrl}/purchase-order/list/${plantId}/${category}/${vendorId}/?companyCode=${com}`
                // `${environment.serverUrl}/purchase-order/list/${plantId}/${category}/${vendorId}`
            )
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }
   async getPONumbersNew(plantId, category,vendorId) {
        return this.http
            .get<any>(
                `${environment.serverUrl}/purchase-order/list/${plantId}/${category}/${vendorId}`
                // `${environment.serverUrl}/purchase-order/list/${plantId}/${category}/${vendorId}`
            )
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }

    async getDeliveryNotedDetailsPO(plantId) {
        
        //purchase-order-id/1/5?ids=1,2
        return this.http
            // .get<any>(`${environment.serverUrl}/purchase-order-id/1/5?ids=${plantId}`)
            .post<any>(`${environment.serverUrl}/purchase-order-id?pageNo=0&pageSize=2000`, plantId)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }
    async getDeliveryNotedDetailsPOAp(plantId) {
        
        //purchase-order-id/1/5?ids=1,2
        return this.http
            // .get<any>(`${environment.serverUrl}/purchase-order-id/1/5?ids=${plantId}`)
            .post<any>(`${environment.serverUrl}/purchase-order-id-ap?pageNo=0&pageSize=2000`, plantId)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }
    async getDeliveryNotedDetailsWB(plantId) {
        
        //purchase-order-id/1/5?ids=1,2
        return this.http
            // .get<any>(`${environment.serverUrl}/purchase-order-id/1/5?ids=${plantId}`)
            .post<any>(`${environment.serverUrl}/weigh-bridge-id`, plantId)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }

    async getInvoiceNotedDetailsPO(plantId) {
        
        //purchase-order-id/1/5?ids=1,2
        return this.http
            // .get<any>(`${environment.serverUrl}/purchase-order-id/1/5?ids=${plantId}`)
            .post<any>(`${environment.serverUrl}/delivery-notes-id`, plantId)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }



    changePWD(data: any) {
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        return this.http.put<any>(`${environment.serverUrl}/users/changepwd/` + currentUser["response"].id, data).pipe(map(res => {
            return res;
        }))
    }
    async getProfile(id: any) {
        return this.http.get<any>(`${environment.serverUrl}/profile/${id}`).pipe(map(res => {
            return res;
        }))
    }

    async updateProfile(req: any) {
        return this.http.post<any>(`${environment.serverUrl}/profile-update`, req).pipe(map(res => {
            return res;
        }))
    }


    login(username: any, password: any) {
        return this.http.post<any>(`${environment.serverUrl}/authenticate`, { username, password }).pipe(map(res => {
            return res;
        }))
    }
    // get all purchase order
    async getPurchaseOrderList(vendorId: any, page: any, limit: any, searchString:any) {//+ `&searchString=`+searchString
        // vendorId = "25811";
        // return this.http.get<any>(`${environment.serverUrl}/purchase-order/`+vendorId+`/`+page+`/`+limit).pipe(map(res => {
        const encodedString = encodeURIComponent(searchString);
        return this.http.get<any>(`${environment.serverUrl}/purchase-order/${vendorId}/1/` + page + `/` + limit +`?` +  `&searchString=`+encodedString).pipe(map(res => {
            return res;
        }))
    }

    async getPurchaseOrderHistoryList(vendorId: any, page: any, limit: any, searchString:any) {//+ `&searchString=`+searchString
        const encodedString = encodeURIComponent(searchString);
        // vendorId = "25811";
        return this.http.get<any>(`${environment.serverUrl}/purchase-order/${vendorId}/2/` + page + `/` + limit+`?` +  `&searchString=`+encodedString).pipe(map(res => {
            return res;
        }))
    }

    // get all purchase order
    async getPurchaseOrderListOtherUser(page: any, limit: any, searchString:any) {//?page=`+page+`&limit=`+limit
        // vendorId = "25811";
        // return this.http.get<any>(`${environment.serverUrl}/purchase-order/`+vendorId+`/`+page+`/`+limit).pipe(map(res => {
        const encodedString = encodeURIComponent(searchString);
        return this.http.get<any>(`${environment.serverUrl}/purchase-order-status/1/` + page + `/` + limit+`?` +  `&searchString=`+encodedString).pipe(map(res => {
            return res;
        }))
    }

    async getPurchaseOrderHistoryListOtherUser(page: any, limit: any, searchString:any) {//?page=`+page+`&limit=`+limit
        // vendorId = "25811";
        const encodedString = encodeURIComponent(searchString);
        return this.http.get<any>(`${environment.serverUrl}/purchase-order-status/2/` + page + `/` + limit +`?` +  `&searchString=`+encodedString).pipe(map(res => {
            return res;
        }))
    }
	
    // create a purchase order
    addPurchaseOrder(data: any) {
        return this.http.post<any>(`${environment.serverUrl}/purchase-order/create`, data).pipe(map(res => {
            return res;
        }))
    }
    // Update a purchase order
    updatePurchaseOrder(data: any) {
        return this.http.post<any>(`${environment.serverUrl}/purchase-order/update`, data).pipe(map(res => {
            return res;
        }))
    }
    //get a purchase order by id
    async getPOInfoByPONumber(poNumber: number) {
        return this.http.get<any>(`${environment.serverUrl}/purchase-order/po-number/${poNumber}`).pipe(map(res => {
            return res;
        }))
    }

    // delivery note apis

    async getDeliveryNoteList(vendorId, page, limit, searchString) {//&searchString=${searchString}
        //delivery-notes/vendor/{vendorId}?page={page}&limit={limit}
        // return this.http.get<any>(`${environment.serverUrl}/delivery-notes?page=${page}&limit=${limit}&statusId=1`).pipe(map(res => {
            const encodedString = encodeURIComponent(searchString);
            return this.http.get<any>(`${environment.serverUrl}/delivery-notes-status/1/${page}/${limit}` +`?` +  `&searchString=`+encodedString).pipe(map(res => {
            // return this.http.get<any>(`${environment.serverUrl}/vendor/${vendorId}?page=${page}&limit=${limit}`).pipe(map(res => {
            return res;
        }))
    }

    async getDeliveryNoteRefListByVI(vendorId) {
            //  return this.http.get<any>(`${environment.serverUrl}/delivery-notes/list/${vendorId}/2`)           
            return this.http.get<any>(`${environment.serverUrl}/delivery-notes/list/${vendorId}`).pipe(map(res => {
             return res;
        }))
    }
    async getDeliveryNoteRefListByVI1(vendorId,companyCode) {
        //  return this.http.get<any>(`${environment.serverUrl}/delivery-notes/list/${vendorId}/2`)           
        return this.http.get<any>(`${environment.serverUrl}/delivery-notes/list/${vendorId}?companyCode=${companyCode}`).pipe(map(res => {
         return res;
    }))
}
    async getWeighTicketNoListByVI(vendorId) {
        return this.http.get<any>(`${environment.serverUrl}/weigh-bridge/vendor-code/${vendorId}`).pipe(map(res => {
         return res;
    }))
}

async getTaxMaster() {
        return this.http.get<any>(`${environment.serverUrl}/tax-master`).pipe(map(res => {
            return res;
        }))
    }

    async getHoldTaxMaster(vendor:any,com:any) {
        return this.http.get<any>(`${environment.serverUrl}/wht-type/${vendor}?companyCode=${com}`).pipe(map(res => {
            return res;
        }))
    }
    async getHoldTaxCodeMaster(id:any) {
        return this.http.get<any>(`${environment.serverUrl}/with-hold-tax-master/${id}`).pipe(map(res => {
            return res;
        }))
    }

    // create a Delivery Note
    async addDeliveryNote(data: any) {
        return this.http.post<any>(`${environment.serverUrl}/delivery-notes`, data).pipe(map(res => {
            return res;
        }))
    }
    // Update a Delivery Note
    async updateDeliveryNote(id:any,data: any) {
        return this.http.put<any>(`${environment.serverUrl}/delivery-notes/${id}`, data).pipe(map(res => {
            return res;
        }))
    }

    //privacy Policy
    async updatePrivacyPolicy(id:any,data: any) {
        return this.http.put<any>(`${environment.serverUrl}/users/update-consent/${id}`,data).pipe(map(res => {
            return res;
        }))
    }

    // upload delivery note support document
    async uploadDeliveryNoteSupportDoc(data: any) {
        return this.http.post<any>(`${environment.serverUrl}/supportDocs`, data).pipe(map(res => {
            return res;
        }))
    }

    // delivery note apis

    async getDeliveryNoteHistory(page: any, limit: any,searchString:any) {//&searchString=${searchString}
        // return this.http.get<any>(`${environment.serverUrl}/delivery-notes?page=${page}&limit=${limit}&statusId=2`).pipe(map(res => {
            const encodedString = encodeURIComponent(searchString);
            return this.http.get<any>(`${environment.serverUrl}/delivery-notes-status/2/${page}/${limit}` + `?` +  `&searchString=`+encodedString).pipe(map(res => {
            return res;
        }))
    }
    // delivery note by reference number
    async getDeliveryNoteByRefNo(id: any) {
        return this.http.get<any>(`${environment.serverUrl}/delivery-notes/${id}`).pipe(map(res => {
            return res;
        }))
    }

     // delivery note by reference number
     async getDeliveryNoteById(id: any) {
        return this.http.get<any>(`${environment.serverUrl}/dn-edit/${id}`).pipe(map(res => {
            return res;
        }))
    }

    forgotPwd(email) {
        return this.http.get<any>(`${environment.serverUrl}/forgotpwd/` + email).pipe(map(user => {
            //
            return user;
        }))
    }
    resetpassword: boolean = false
    resetPwd(row_obj, userToken) {
        this.resetpassword = true;
        const headers = new HttpHeaders()
            .set("Authorization", 'Bearer ' + userToken);
        return this.http.put<any>(`${environment.serverUrl}/resetpwd`, row_obj, { headers }).pipe(map(user => {
            //
            return user["respBuffer"];
        }))
    }

    async upload(formData){
        return this.http.post<any>(`${environment.serverUrl}/delivery-notes-support-docs`, formData).pipe(map(res => {
            
            return res;
          })
          );
    }
    async masterUpload(formData:any,data:string){
        // const encodedString = encodeURI(data);
        return this.http.post<any>(`${environment.serverUrl}/master-upload?tableName=${data}`, formData).pipe(map(res => {
            
            return res;
          })
          );
    }
    async uploadInvoiceData(formData){
        return this.http.post<any>(`${environment.serverUrl}/invoice-support-docs`, formData).pipe(map(res => {
            return res;
          })
          );
    }

    async uploadInvoiceService(formData){
        return this.http.post<any>(`${environment.serverUrl}/invoiceServiceSupportDocs`, formData).pipe(map(res => {
            return res;
          })
          );
    }

	async addInvoice(data: any) {
        return this.http.post<any>(`${environment.serverUrl}/add-invoice`, data).pipe(map(res => {
            return res;
        }))
    }
    async addServiceInvoice(data: any) {
        return this.http.post<any>(`${environment.serverUrl}/service-invoice`, data).pipe(map(res => {
            return res;
        }))
    }

    async updateInvoice(invoiceId:any,data: any) {
        return this.http.put<any>(`${environment.serverUrl}/edit-invoice/${invoiceId}`, data).pipe(map(res => {
            return res;
        }))
    }
    async updateServiceInvoice(invoiceId:any,data: any) {
        return this.http.put<any>(`${environment.serverUrl}/invoiceServiceVp/${invoiceId}`, data).pipe(map(res => {
            return res;
        }))
    }

    async getInvoiceList(usertype: any, pageNo: number, pageSize: number,searchString:string) {//&searchString=${searchString}
        const encodedString = encodeURIComponent(searchString);
        return this.http.get<any>(`${environment.serverUrl}/invoice-list?page=${pageNo}&limit=${pageSize}&statusId=1&searchString=${encodedString}`).pipe(map(user => {
            //
            return user;
        }))
      }

      
      async getInvoiceServicePList(usertype: any, pageNo: number, pageSize: number,searchString:string) {//&searchString=${searchString}
        return this.http.get<any>(`${environment.serverUrl}/service-invoice-list?page=${pageNo}&limit=${pageSize}&statusId=1&searchString=${searchString}`).pipe(map(user => {
            //
            return user;
        }))
      }
      async getInvoiceServiceHList(pageNo: number, pageSize: number, status:any, searchString:string) {//&searchString=${searchString}
        return this.http.get<any>(`${environment.serverUrl}/invoice-services?page=${pageNo}&limit=${pageSize}&statusId=${status}&searchString=${searchString}`).pipe(map(user => {
            //
            return user;
        }))
      }

     async getInvoiceServiceList1(usertype: any, pageNo: number, pageSize: number,searchString:string) {//&searchString=${searchString}
        return this.http.get<any>(`${environment.serverUrl}/invoice-list?page=${pageNo}&limit=${pageSize}&statusId=1&searchString=${searchString}`).pipe(map(user => {
            //
            return user;
        }))
      }
      async getInvoiceHistory(pageNo: number, pageSize: number,searchString:string) {//&searchString=${searchString}
      const encodedString = encodeURIComponent(searchString);
        return this.http.get<any>(`${environment.serverUrl}/invoice-list?page=${pageNo}&limit=${pageSize}&statusId=2&searchString=${encodedString}`).pipe(map(user => {
            //
            return user;
        }))
      }

      
    async getInvoiceServiceList(usertype: any, pageNo: number, pageSize: number) {
        return this.http.get<any>(`${environment.serverUrl}/invoice-service-status/1/${pageNo}/${pageSize}`).pipe(map(user => {
            //
            return user;
        }))
      }
      async getInvoiceServiceHistory(pageNo: number, pageSize: number) {
        return this.http.get<any>(`${environment.serverUrl}/invoice-service-status/2/${pageNo}/${pageSize}`).pipe(map(user => {
            //
            return user;
        }))
      }


    //   http://localhost:9091/vendormanagementportal/invoice-service-status/{statusId}/{pageNo}/{pageSize}
     async updateDeliveryStatus(data: any) {
        return this.http.post<any>(`${environment.serverUrl}/delivery-notes-status`, data).pipe(map(res => {
            return res;
        }))
    }

    async getNotificationList(pageNo: number, pageSize: number){
        return this.http.get<any>(`${environment.serverUrl}/notification?page=${pageNo}&limit=${pageSize}`).pipe(map(user => {
            return user;
        }))
    }
    async getLangPreference(langPreference: any) {
        return this.http.get<any>(`${environment.serverUrl}/users/lang?langPreference=${langPreference}`).pipe(map(res => {
            return res;
        }))
    }
    async getInvoiceDetailsById(id: any) {
        return this.http.get<any>(`${environment.serverUrl}/invoice/${id}`).pipe(map(user => {
            //
            return user;
        }))
      }
      async getInvoiceDetailsByIdView(id: any) {
        return this.http.get<any>(`${environment.serverUrl}/invoice/${id}?view=true`).pipe(map(user => {
            //
            return user;
        }))
      }
      async getInvoiceDetailsByInvId(id: any) {
        return this.http.get<any>(`${environment.serverUrl}/invoice-edit/${id}`).pipe(map(user => {
            //
            return user;
        }))
      }
      async getInvoiceDetailsByInvIdG(id: any) {
        return this.http.get<any>(`${environment.serverUrl}/invoice-edit/${id}?checker=true`).pipe(map(user => {
            //
            return user;
        }))
      }
      async getInvoiceDetailsByIdWB(id: any) {
        return this.http.get<any>(`${environment.serverUrl}/invoice-edit-wb/${id}`).pipe(map(user => {
            //
            return user;
        }))
      }
      async getInvoiceDetailsByServiceId(id: any) {
        return this.http.get<any>(`${environment.serverUrl}/invoice-service-outbound/${id}`).pipe(map(user => {
            //
            return user;
        }))
      }
      async releaseCategoryStock(id:any,statusId:any,data:any){
        return this.http.post<any>(`${environment.serverUrl}/delivery-notes-sap/${id}/${statusId}`,data).pipe(map(user => {
            //
            return user;
        }))
    }
    async rejectCategoryStock(id:any){
        return this.http.get<any>(`${environment.serverUrl}/delivery-notes-sap/${id}/2`).pipe(map(user => {
            //
            return user;
        }))
    }
    async invoiceApproveAPC(id:any,request:any){
        return this.http.post<any>(`${environment.serverUrl}/invoice-status/${id}/0`, request).pipe(map(user => {
            //
            return user;
        }))
    }
    async invoiceRejectAPC(id:any,request:any){
        return this.http.post<any>(`${environment.serverUrl}/invoice-status/${id}/1`,request).pipe(map(user => {
            //
            return user;
        }))
    }
    async download(id:any){
        // return this.http.get<any>(`${environment.serverUrl}/download-dn-doc/${id}`).pipe(map(user => {
        //     //
        //     return user;
        // }))
        const header = { Accept: "application/octet-stream" };
    let fileType = [];
    const options = { headers: header, responseType: 'blob' };
    
    return this.http.get<any>(`${environment.serverUrl}/download-dn-doc/${id.id}`, { responseType: 'blob' as 'json', observe: 'response' })
      .pipe(map(user => {
        if(user.body.type != "application/json"){
          let fileName = user.headers;
          let test = user;
          const blob = new Blob([test.body], { type: id.documentType });
          const file = new File([blob], id.documentName, { type: id.documentType });
        
          var fileURL = window.URL.createObjectURL(file);
          window.open(fileURL, "_blank");
          saveAs(file);
        }else{
          Swal.fire("Unabale to process request");
        }
        
      }))
    
    }
   async downloadInv(id:any){
        // return this.http.get<any>(`${environment.serverUrl}/download-dn-doc/${id}`).pipe(map(user => {
        //     //
        //     return user;
        // }))
        const header = { Accept: "application/octet-stream" };
    let fileType = [];
    const options = { headers: header, responseType: 'blob' };
    
    return this.http.get<any>(`${environment.serverUrl}/download-invoice-doc/${id.id}`, { responseType: 'blob' as 'json', observe: 'response' })
      .pipe(map(user => {
        if(user.body.type != "application/json"){
          let fileName = user.headers;
          let test = user;
          const blob = new Blob([test.body], { type: id.documentType });
          const file = new File([blob], id.documentName, { type: id.documentType });
          var fileURL = window.URL.createObjectURL(file);
          window.open(fileURL, "_blank");
          saveAs(file);
        }else{
          Swal.fire("Unabale to process request");
        }
        
      }))
    
    }
    async downloadInvService(id:any){
        // return this.http.get<any>(`${environment.serverUrl}/download-dn-doc/${id}`).pipe(map(user => {
        //     //
        //     return user;
        // }))
        const header = { Accept: "application/octet-stream" };
    let fileType = [];
    const options = { headers: header, responseType: 'blob' };
    
    return this.http.get<any>(`${environment.serverUrl}/download-invoice-service-doc/${id.id}`, { responseType: 'blob' as 'json', observe: 'response' })
      .pipe(map(user => {
        if(user.body.type != "application/json"){
          let fileName = user.headers;
          let test = user;
          const blob = new Blob([test.body], { type: id.documentType });
          const file = new File([blob], id.documentName, { type: id.documentType });
          var fileURL = window.URL.createObjectURL(file);
          window.open(fileURL, "_blank");
          saveAs(file);
        }else{
          Swal.fire("Unabale to process request");
        }
        
      }))
    
    }
    async getCostCenterMaster(com) {
        return this.http
            .get<any>(`${environment.serverUrl}/cost-center/${com}`)
            // .get<any>(`${environment.serverUrl}/plant-master/list`)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }

    async getGlAccountMaster(com) {
        return this.http
            .get<any>(`${environment.serverUrl}/gl-account/${com}`)
            // .get<any>(`${environment.serverUrl}/plant-master/list`)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }
    isRoleExists(userRoles: number[], verifyRole: number) {
        
        var isExist: Boolean = false
        for (let role of userRoles) {
            if (role == verifyRole) {
                isExist = true;
                return true;
            }
        }
        return isExist;
    }

    getWBData(data) {
        return this.http
            .post<any>(
            `${environment.serverUrl}/weigh-bridge/filter`,data
            )
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }
    getWBDataBydate(poNumber,dnDateStart,dnDateEnd) {
        return this.http
            .get<any>(
            `${environment.serverUrl}/weigh-bridge/filter?poNumber=${poNumber}&dnDateStart=${dnDateStart}&dnDateEnd=${dnDateEnd}`
            )
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }

    getWBDataByDnNo(data) {
        return this.http
            .post<any>(
            `${environment.serverUrl}/weigh-bridge/filter`,data
            )
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }

    async getTaxCaluation(deliverynoteid:any, reqBody:any) {
        
        //  return this.http.get<any>(`${environment.serverUrl}/delivery-notes/list/${vendorId}/2`) grnInvoice/taxCalc/{deliverynoteid}          
        return this.http.post<any>(`${environment.serverUrl}/grnInvoice/taxCalc/${deliverynoteid}`, reqBody).pipe(map(res => {
         return res;
    }))
}
async getVendorReport(request: any,pageNo: number, pageSize: number) {//&searchString=${searchString} ?page=0&limit=10
    return this.http.post<any>(`${environment.serverUrl}/vendor/report?page=${pageNo}&limit=${pageSize}`, request).pipe(map(res => {
        return res;
   }))
  }
async getAPTeamReport(request: any,pageNo: number, pageSize: number) {//&searchString=${searchString} ?page=0&limit=10
    return this.http.post<any>(`${environment.serverUrl}/ap-team/report?page=${pageNo}&limit=${pageSize}`, request).pipe(map(res => {
        return res;
   }))
  }
  async getDNReport(request: any,pageNo: number, pageSize: number) {//&searchString=${searchString} ?page=0&limit=10
    return this.http.post<any>(`${environment.serverUrl}/dn-report?page=${pageNo}&limit=${pageSize}`, request).pipe(map(res => {
        return res;
   }))
  }
  async getPOReport(request: any,pageNo: number, pageSize: number) {//&searchString=${searchString} ?page=0&limit=10
  return this.http.post<any>(`${environment.serverUrl}/po-report?page=${pageNo}&limit=${pageSize}`, request).pipe(map(res => {
      return res;
 }))
}
async getauditReport(request: any,pageNo: number, pageSize: number) {//&searchString=${searchString} ?page=0&limit=10
    return this.http.post<any>(`${environment.serverUrl}/master-filter?page=${pageNo}&limit=${pageSize}`, request).pipe(map(res => {
        return res;
   }))
  }
  async getexportExcelData(request: any) {//&searchString=${searchString} ?page=0&limit=10
    return this.http.post<any>(`${environment.serverUrl}/master-filter?export=true`, request).pipe(map(res => {
        return res;
   }))
  }
  async getexportPDFdata(request: any) {//&searchString=${searchString} ?page=0&limit=10
    return this.http.post<any>(`${environment.serverUrl}/ap-team/report?export=true`, request).pipe(map(res => {
        return res;
   }))
  }
  async getDNExportPDFdata(request: any) {//&searchString=${searchString} ?page=0&limit=10
    return this.http.post<any>(`${environment.serverUrl}/dn-report?export=true`, request).pipe(map(res => {
        return res;
   }))
  }
  async getPOExportPDFdata(request: any) {//&searchString=${searchString} ?page=0&limit=10
  return this.http.post<any>(`${environment.serverUrl}/po-report?export=true`, request).pipe(map(res => {
      return res;
 }))
}
  async getexportPDFdataVendor(request: any) {//&searchString=${searchString} ?page=0&limit=10
  return this.http.post<any>(`${environment.serverUrl}/vendor/report?export=true`, request).pipe(map(res => {
      return res;
 }))
}
async getAPCheckerReport(request: any,pageNo: number, pageSize: number) {//&searchString=${searchString}
    return this.http.post<any>(`${environment.serverUrl}/ap-checker/report?page=${pageNo}&limit=${pageSize}`, request).pipe(map(res => {
        return res;
   }))
  }
  async getInvoiceInProgress(pageNo: number, pageSize: number,searchString:string) {//&searchString=${searchString}invoice/request-in-progress?page=2&limit=10
  const encodedString = encodeURIComponent(searchString);
    return this.http.get<any>(`${environment.serverUrl}/invoice/request-in-progress?page=${pageNo}&limit=${pageSize}&statusId=2&searchString=${encodedString}`).pipe(map(user => {
        //
        return user;
    }))
  }
  async getInvoiceInProgressChangeStaus(id:any) {//&searchString=${searchString}invoice/request-in-progress?page=2&limit=10
    return this.http.get<any>(`${environment.serverUrl}/invoice/edit-status/${id}`).pipe(map(user => {
        //
        return user;
    }))
  }

  async getInvoiceInProgressUpdate(id:any,req:any) {//&searchString=${searchString}invoice/request-in-progress?page=2&limit=10
    return this.http.put<any>(`${environment.serverUrl}/invoice/edit-inProgress/${id}`, req).pipe(map(user => {
        //
        return user;
    }))
  }

  async masterDownload(masterData: any) {//&searchString=${searchString} ?page=0&limit=10
  return this.http.get<any>(`${environment.serverUrl}/master-download?tableName=${masterData}`).pipe(map(res => {
      return res;
 }))
}

async APTeamExport(payload:any){
    const header = { Accept: "application/octet-stream" };
    const options = {headers: header, responseType: 'blob'};
        return this.http.post<any>(`${environment.serverUrl}/ap-team/report/export`, payload , { responseType: 'blob' as 'json', observe: 'response' }) 
        .pipe(map(user => {
        
                  if(user.body.type != 'application/json'){
                    let test = user;
                    let header = user.headers;
                    let contentDisposition = header.get('content-disposition');
                    contentDisposition = contentDisposition.substring(22,contentDisposition.length-1);
                    
                    const blob = new Blob([test.body], { type : 'application/octet-stream' });  
                    const file = new File([blob], contentDisposition, { type: 'application/octet-stream' });
                    saveAs(file);
                  }else{
                    Swal.fire("Reports not found","","error");
                  }
        }))
}

async vendorExport(payload:any){
    const header = { Accept: "application/octet-stream" };
    const options = {headers: header, responseType: 'blob'};
        return this.http.post<any>(`${environment.serverUrl}/vendor/report/export`, payload , { responseType: 'blob' as 'json', observe: 'response' }) 
        .pipe(map(user => {
        
                  if(user.body.type != 'application/json'){
                    let test = user;
                    let header = user.headers;
                    let contentDisposition = header.get('content-disposition');
                    contentDisposition = contentDisposition.substring(22,contentDisposition.length-1);
                    
                    const blob = new Blob([test.body], { type : 'application/octet-stream' });  
                    const file = new File([blob], contentDisposition, { type: 'application/octet-stream' });
                    saveAs(file);
                  }else{
                    Swal.fire("Reports not found","","error");
                  }
        }))
}

async dnExport(payload:any){
    const header = { Accept: "application/octet-stream" };
    const options = {headers: header, responseType: 'blob'};
        return this.http.post<any>(`${environment.serverUrl}/dn-report/export`, payload , { responseType: 'blob' as 'json', observe: 'response' }) 
        .pipe(map(user => {
        
                  if(user.body.type != 'application/json'){
                    let test = user;
                    let header = user.headers;
                    let contentDisposition = header.get('content-disposition');
                    contentDisposition = contentDisposition.substring(22,contentDisposition.length-1);
                    
                    const blob = new Blob([test.body], { type : 'application/octet-stream' });  
                    const file = new File([blob],contentDisposition, { type: 'application/octet-stream' });
                    saveAs(file);
                  }else{
                    Swal.fire("Reports not found","","error");
                  }
        }))
}

async poExport(payload:any){
    const header = { Accept: "application/octet-stream" };
    const options = {headers: header, responseType: 'blob'};
        return this.http.post<any>(`${environment.serverUrl}/po-report/export`, payload , { responseType: 'blob' as 'json', observe: 'response' }) 
        .pipe(map(user => {
        
                  if(user.body.type != 'application/json'){
                    let test = user;
                    let header = user.headers;
                    let contentDisposition = header.get('content-disposition');
                    contentDisposition = contentDisposition.substring(22,contentDisposition.length-1);
                    
                    const blob = new Blob([test.body], { type : 'application/octet-stream' });  
                    const file = new File([blob],contentDisposition, { type: 'application/octet-stream' });
                    saveAs(file);
                  }else{
                    Swal.fire("Reports not found","","error");
                  }
        }))
}
}

function data<T>(arg0: string, data: any) {
    throw new Error("Function not implemented.");
}
