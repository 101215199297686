import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfigModel } from './config-model/config-model';
import swal from "sweetalert2";
import { MatSort } from '@angular/material/sort';
import { first } from 'rxjs/operators';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
// import { ConfigModel, ConfiModel } from './config-model/config-model';
import { AdminServiceService } from '../services/admin-service.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-configuration-management',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {
  mySpinner:boolean = false;
  constructor(private dialog: MatDialog,private router: Router, private cService: AdminServiceService) { }
  dataSource:MatTableDataSource<any>;
  dataSource1:MatTableDataSource<any>;
  columnsToDisplay=["propKey","propValue","actions"];
  columnsToDisplay1=["propKey","propValue"];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  currentUser:any;
  headerToDisplay:any;
  ngOnInit(): void {
    // this.dataSource.sort=this.sort;
    // this.dataSource1.sort=this.sort;
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.getConfig(null,this.searchString);
    this.getConfigSystem(null,this.searchString);
    let currentUsers = this.currentUser.response;
		let lang = currentUsers.langPreference;
		if(lang == 'en'){
      this.headerToDisplay={
        propKey:"Property Key",
        propValue:"Property Value",
        actions:"Actions",
        Configurations:"Configurations",
        search:"Search",
      }
		  }else if(lang == 'th'){
      this.headerToDisplay={
        propKey:"รหัสทรัพย์สิน",
        propValue:"มูลค่าทรัพย์สิน",
        actions:"การกระทำ",
        Configurations:"การกำหนดค่า",
        search:"ค้นหา",
      }
		  }
  }
  pageIndex = 0;
  pageSize = 10;
  pageIndex1 = 0;
  pageSize1 = 10;
  listLength: any;
  listLength1: any;
  searchString:any='';
  getConfig(event: PageEvent, searchString:any) {
    this.mySpinner = true;
    // this.showOverlay = true;
    if(event ==  null){
      this.pageIndex = 0;
      this.pageSize = 10;
     }else{
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
     }

    this.cService.getConfigurations(1,this.pageIndex,this.pageSize,this.searchString).pipe(first()).subscribe(res => {
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        //this.showOverlay=false;
       
        this.dataSource= new MatTableDataSource(res.response);
        // this.dataSource1= new MatTableDataSource(res.response.nonEdit);
        this.listLength = res.pagination.count;
        this.dataSource.sort=this.sort
        // this.listLength1 = res.pagination.count;
        this.mySpinner = false;
      } else {
        this.mySpinner = false;
        this.dataSource = new MatTableDataSource([]);
        this.listLength = 0;
        
        swal.fire(res.responseMessage,"", "error");
      }
    }, error => {
      this.mySpinner = false;
      // this.showOverlay=false;
    })
  }
  getConfigSystem(event: PageEvent, searchString:any) {
    this.mySpinner = true;
    // this.showOverlay = true;
    if(event ==  null){
      this.pageIndex1 = 0;
      this.pageSize1 = 10;
     }else{
      // if (event.pageIndex == 0) {
      //   this.pageIndex = 1;
      //   this.pageSize = event.pageSize;
      // } else {
        this.pageIndex1 = event.pageIndex;
        this.pageSize1 = event.pageSize;
      // }
     }

    this.cService.getConfigurations(0,this.pageIndex1,this.pageSize1,this.searchString).pipe(first()).subscribe(res => {
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        //this.showOverlay=false;
       
        // this.dataSource= new MatTableDataSource(res.response);
        this.dataSource1= new MatTableDataSource(res.response);
        // this.listLength = res.pagination.count;
        this.listLength1 = res.pagination.count;
        this.mySpinner = false;
      } else {
        this.mySpinner = false;
        // this.showOverlay=false;
      }
    }, error => {
      this.mySpinner = false;
      // this.showOverlay=false;
    })
  }
  openDialog(_add: any, obj: any){
    obj.action = _add;
    let userid = obj.userID;
    const dialogRef = this.dialog.open(ConfigModel, {
      width: '500px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
        let req = {
          "propValue": result.data.propValue
        };
        // swal.fire("User creation successful!");
        this.updateConfigProp(result.data.id, req);
      }
      
    })
  }
  updateConfigProp(id:any,req:any){
    this.cService.updateConfigProp(id,req).pipe(first()).subscribe(res => {
      if(res.responseStatus.toUpperCase() == "SUCCESS"){
        swal.fire(
          { 
            icon: 'success',
            title: '',
            text: res.responseMessage,
            footer: ''
        }
        );
        this.getConfig(null, this.searchString);
        
      }else{
        swal.fire(
          { 
            icon: 'error',
            title: '',
            text: res.responseMessage,
            footer: ''
        }
        );
      }
      
    })
  }
  applyFilter1(){
    const filterValue = this.searchString;
    if(this.searchString == "" ){
      this.getConfig(null, this.searchString);
    }
  }
  applyFilter() {
    const filterValue = this.searchString;
    if(filterValue.length >= 2 || this.searchString == "" ){
      this.getConfig(null, this.searchString);
    }

}

}
