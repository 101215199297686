<mat-card>
    <mat-card-title>
        <div fxLayout="row" fxFlex.gt-xs="100" fxFlex="100">
            {{headerToDisplay.userManagement}}
        </div>
    </mat-card-title>
    <hr>
    <mat-card-content>
        <div fxLayout="row" fxLayoutWrap="wrap">
            <!-- Card column -->
            <div fxFlex.gt-sm="100%" fxFlex="100">

                <div fxLayout="row" fxLayout.xs="column">
                    <div class="search" fxFlex="30" fxFlex.sm="50" fxFlex.xs="100">
                        <mat-form-field class="mx-3 max-width">
                            <mat-label>{{headerToDisplay.search}}</mat-label>
                            <input matInput placeholder="Search" (keyup)="applyFilter1()" #input [(ngModel)]="searchString">
                        </mat-form-field>             
                    </div>
                    <div fxFlex="50" fxFlex.sm="70" fxFlex.xs="100" style="margin-top:15px">
                        <button (click)="applyFilter()"  mat-raised-button class="submitButton btn-primary">{{headerToDisplay.search}}</button>
                    </div>
                    <div fxFlex="30" fxFlex.sm="50" fxFlex.xs="100"></div>           
                         <div fxFlex="30" fxFlex.sm="50" fxFlex.xs="100" style="text-align:end;margin-top:10px;"> 
                           <a  style="cursor:pointer;" (click)="exportexcel('')"><img src="assets/images/icons8-export-excel-100.png" height="50px" width="50px" ></a>
                    </div>
                    <div class="addUserParent" fxFlex="15" fxFlex.sm="20" fxFlex.xs="20">
                        <button mat-raised-button class="submitButton addUser"
                            (click)="openDialog('Add',{})">{{headerToDisplay.addUser}}</button>
                    </div>
                </div>
                <!-- <div class="row mx-2 mt-2 my-2 summaryTab mat-elevation-z8  mx-3 mt-3 full-width"> -->
                <div class="overflow">
                    <mat-card>
                        <mat-table [dataSource]="dataSource" matSort multiTemplateDataRows
                            class="mat-elevation-z5 main-table userManagementTable">
                            <ng-container matColumnDef="userName">
                                    <mat-header-cell *matHeaderCellDef class="vendorCode-w" mat-sort-header> {{headerToDisplay.userName}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="vendorCode-w" data-label="VENDOR NAME" > {{element.userName}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="firstName">
                                <mat-header-cell *matHeaderCellDef class="first-name-w" mat-sort-header>
                                    {{headerToDisplay.firstName}} </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="first-name-w" data-label="FIRST NAME">
                                    {{element.firstName}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="lastName">
                                <mat-header-cell *matHeaderCellDef class="last-name-w" mat-sort-header>
                                    {{headerToDisplay.lastName}} </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="last-name-w" data-label="LAST NAME">
                                    {{element.lastName}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="email">
                                <mat-header-cell *matHeaderCellDef class="email-w" mat-sort-header>
                                    {{headerToDisplay.email}} </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="email-w" data-label="EMAIL">
                                    {{element.email}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="mobileNo">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="contact-w">
                                    {{headerToDisplay.mobileNo}} </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="contact-w" data-label="Contact No">
                                    {{element.contactNo}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="companyAllowed">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header class="role-w">
                                        {{headerToDisplay.companyAllowed}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="role-w" data-label="Contact No">
                                            <span class="textWrap" matTooltipPosition="before"  matTooltip="{{element.companyAllowed}}"
                                            matTooltipClass="my-custom-tooltip" *ngIf="element.roleID == 4 || element.roleID == 5 || element.roleID == 8">
                                                    {{element.companyAllowed}}
                                            </span>   
                                            <!-- <span *ngIf="element.roleID != 4 || element.roleID != 5 || element.roleID != 8">
                                                    {{"-"}}
                                            </span>  -->
                                    </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="plantAllowed">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header class="role-w">
                                        {{headerToDisplay.plantAllowed}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="role-w" data-label="Contact No">
                                            <span class="textWrap"  matTooltipPosition="before"  matTooltip="{{element.companyAllowed}}"
                                            matTooltipClass="my-custom-tooltip" *ngIf=" element.roleID == 3 ||  element.roleID == 6 ||  element.roleID == 7">
                                                    {{element.companyAllowed}}
                                            </span>   
                                            <!-- <span *ngIf=" element.roleID != 3 ||  element.roleID != 6 ||  element.roleID != 7">
                                                    {{"-"}}
                                            </span>     -->
                                    </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="roleName">
                                <mat-header-cell *matHeaderCellDef class="role-w" mat-sort-header>
                                    {{headerToDisplay.usertype}} </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="role-w" data-label="Role Name">
                                    {{element.roleName}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="isBlocked">
                                <mat-header-cell *matHeaderCellDef class="status1-w" mat-sort-header>
                                    {{headerToDisplay.status}} </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="status1-w"
                                    [ngClass]="calculateClass(element.isBlocked)" data-label="Status">
                                    {{element.isBlocked == 0 ? 'Active' : 'Blocked'}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="lastLoginTime">
                                <mat-header-cell *matHeaderCellDef  mat-sort-header class="lastLoginTime-w"> {{headerToDisplay.lastLoginTime}} </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="lastLoginTime-w" data-label="Last Login Date/Time"> {{element.lastLoginTime? (element.lastLoginTime  | date:"dd/MM/yyyy HH:mm:ss"): "-"}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="actions">
                                <mat-header-cell *matHeaderCellDef class="actions-w actions-align">
                                    {{headerToDisplay.actions}} </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="actions-w" data-label="Actions">
                                        <mat-icon class="block" aria-label="lock" *ngIf="element.isBlocked == 1 && element.isactive == 1"
                                        (click)="openConfirm(element)">lock</mat-icon>
                                    <mat-icon class="block" aria-label="lock_open"
                                        *ngIf="(element.isBlocked == 0 || element.isBlocked == null) && element.isactive == 1"
                                        (click)="openConfirm(element)">lock_open</mat-icon>
                                        <mat-icon aria-hidden="false"  class="visibility" aria-label="visibility" (click)="openUserDetails(element)">visibility</mat-icon>
                                    <mat-icon class="edit" aria-label="edit" *ngIf="element.isBlocked == 0 && element.isactive == 1" (click)="openDialog('Update',element)">edit
                                    </mat-icon>
                                    
                                </mat-cell>
                            </ng-container>
                            <mat-header-row class="vendorRow" *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                            <mat-row class="vendorRow" *matRowDef="let element; columns: columnsToDisplay;"></mat-row>
                        </mat-table>
                    </mat-card>
                </div>
                <mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" [pageSize]="pageSize"
                    [length]="listLength" [pageSizeOptions]="[10, 25, 50, 100]"
                    (page)="getUsersList($event,'')" style="width:100%"></mat-paginator>
                <!-- </div> -->
            </div>
        </div>
        <div class="overlay" *ngIf="mySpinner">
            <div class="center">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </mat-card-content>
</mat-card>