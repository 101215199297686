import { Injectable } from '@angular/core';
import {
    HttpInterceptor, HttpRequest,
    HttpHandler, HttpEvent, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorPageService } from '../shared/error-page/error-page.service';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import swal from 'sweetalert2';
@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {
    showOverlay:boolean = false;
    constructor(private errorService: ErrorPageService, private router : Router, private authService : AuthService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
            // //
            
        return next.handle(req)
        .pipe(
            // retry(1),
            catchError((error: HttpErrorResponse) => {
                
                
              let errorMessage = '';
              if (error.error instanceof ErrorEvent) {
                // client-side error
                
                // localStorage.removeItem("currentUser");
                // this.router.navigate(['signin']); 
                errorMessage = `Error: ${error.error.message}`;
              } else {
                // server-side error
                
                
                // //
                if(error.error.message != null && error.error.message != ""){
                    // //
                    // localStorage.removeItem("currentUser");
                    // this.router.navigate(['signin']); 
                    errorMessage = `${error.error.details[0]}`;
                }else if(error.error.error === "Unauthorized"){
                    
                    errorMessage = `${error.error.error}`;
                    localStorage.removeItem("currentUser");
                    this.router.navigate(['signin']);  
                    
                }else if(error.error.status === 417){
                    
                    errorMessage = 'Login Expired. Please login again';
                    
                }
                else{
                    if(error.error.responseCode == 401){
                    
                    errorMessage = 'Login Expired. Please login again';
                    
                    }else if(error.error.error == undefined){
                        
                        
                        errorMessage = "Login Expired. Please login again";
                        // errorMessage = "Internet connection closed while processing the request";
                        // errorMessage = error.error.error.message
                        // localStorage.removeItem("currentUser");
                        // this.router.navigate(['signin']); 
                    }else{
                        errorMessage = `${error.error.error}`;
                        // localStorage.removeItem("currentUser");
                        // this.router.navigate(['signin']); 
                    }
                    
                }
                
              }
            //   window.alert(errorMessage);
                swal.fire(errorMessage,"","error").then(function() {
                    if(error.error.status === 417 || error.error.responseCode === 401 || error.error.error == undefined){
                    localStorage.removeItem("currentUser");
                    localStorage.clear();
                    // this.router.navigate(['signin']);  
                    window.location.href = "signin";
                    }
                });
                  this.showOverlay = false;
              return throwError(errorMessage);
            })
          )
            // .pipe(
            //     catchError((error) => {
            //         this.showOverlay = false;
            //         //
            //         // //
            //         // //
            //         // swal.fire(error.error.details[0]);
                            
            //         if ([401, 403].indexOf(error.status) !== -1) {
            //             // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            //             this.authService.logout();
            //            location.reload(true);
            //             this.router.navigate[''];
            //         return throwError(error);
                    
            //         }
            //         this.errorService.errorNumber =  error.status;
            //         this.errorService.errorContents = error.error.message;
            //         this.router.navigate(['error'], {replaceUrl: true})
            //         return throwError(error);
            //     })
            // )
    };
}