<div class="row" style="display: flex;">
    <div fxFlex.gt-lg="90" fxFlex.gt-md="90" fxFlex.gt-xs="100" fxFlex="100">
        <strong>Upload Invoice</strong>
    </div>
    <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
        <mat-icon class="close pull-right" (click)="closeDialog()">close</mat-icon>
    </div>


</div>

<div mat-dialog-content>
    <!-- <div class="row" *ngIf="action != 'delete'; else elseTemplate"> -->
    <form [formGroup]="changePwd">
        <div class="row mt-2">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field class="width-80" appearance="outline">
                    <mat-label>Type of invoice</mat-label>
                     <mat-select class="langSelect width-80" formControlName="typeOfInvoice" style="font-size: 14px;" [(ngModel)]="taxType">
                          <mat-option value="0">Tax Invoice</mat-option>
                          <mat-option value="1">Invoice</mat-option>
                          <mat-option value="2">E-Invoice</mat-option>
                      </mat-select>
                </mat-form-field>
            </div>
        </div>
            <div class="row">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    
                    <mat-form-field class="width-80" appearance="outline">
                        <mat-label>Invoice Number</mat-label>
                        <input matInput formControlName="inoviceNumber" type="text">
                    </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <div fxFlex.gt-sm="30%" fxFlex="100">
                    Upload Invoice:
                    <input type="file" formControlName="invoiceFile">
                    <!-- <mat-form-field appearance="outline">
                        <input matInput type="text">
                    </mat-form-field> -->
                </div>
            </div>
        </div>

        <div class="row">
            <div fxFlex.gt-lg="60" fxFlex.gt-md="60" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
                <button mat-button mat-flat-button class="submitButton" (click)="onNoClick()"
                    style="margin-right:10px">Upload</button>
                <button mat-button (click)="closeDialog()" class="rejectButton" mat-flat-button color="warn">Cancel</button>
            </div>

        </div>

    </form>
    <!-- </div> -->
    <!-- <ng-template #elseTemplate>
        Sure to delete
        <b>{{local_data.username}}</b>?
    </ng-template> -->

</div>
<!-- 
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Submit</button>
    
</div> -->