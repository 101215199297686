// my-modal.component.ts
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router'
import { UserManagementComponent } from '../user-management/user-management.component';
import { CommonServicesService } from '../services/common-services.service';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';import swal from "sweetalert2";
import { PageEvent } from '@angular/material/paginator';
import { AdminServiceService } from '../services/admin-service.service';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-my-modal',
  templateUrl: './my-modal.component.html',
  styleUrls: ['./my-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class MyModalComponent {
  emailPattern = /^([\w-]|(?<!\.)\.)+[a-zA-Z0-9]@[a-zA-Z0-9]([\w\-]+)((\.([a-zA-Z]){2,9})+)$/;
  NamePattern="^[a-zA-Z ]*$";
  food: string | undefined;
  local_data: any;
  action: string;
  status: boolean = true;
  dataSource:MatTableDataSource<any>;
  show:boolean=false;
  item:any;
  constructor(private fb: FormBuilder, private router: Router,private aService: AdminServiceService,
    public dialogRef: MatDialogRef<MyModalComponent>,
    private cService: CommonServicesService,
    @Inject(MAT_DIALOG_DATA) public data: UserManagementComponent) {
    this.item = {...data}
    if(this.item.action == "Update"){
      if(this.item.roleID == 3 || this.item.roleID == 6 || this.item.roleID == 7){
        this.getPlantList(null);
      }else if(this.item.roleID == 4 || this.item.roleID == 5 || this.item.roleID == 8){
        this.getCompanyList();
      }
    }
    this.local_data = { ...data };
    this.action = this.local_data.action;
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
    
    
    
    if (this.action == 'Add') {
      if (lang == 'en') {
        this.action = "Add"
      } else {
        this.action = 'เพิ่ม'
      }
      this.status = false;
    } 
    else {
      if (this.action == 'Update') {
        if (lang == 'en') {
          this.action = "Update"
          this.show = true;
        } else {
          this.action = "อัปเดต"
        }
      }
      this.status = true;
    }
    // this.local_data.companyAllowed = []
    //
    
  }

  get f() { return this.changePwd.controls; }
  changePwd: FormGroup = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    // username : ['',[Validators.required]],
    contactNo: ['', [Validators.required]],
    // status: ['', [Validators.required]],
    roleID: ['', [Validators.required]],
    UserName: ['', [Validators.required]],
    companyAllowed: ['', [Validators.required]],
    // wareHouseLevel: ['',[Validators.required]],
    email: ['', [Validators.required, Validators.email, Validators.pattern(this.emailPattern)]],
    // accessBy: ['no', [Validators.required]]
  });
  dropdownList = [];
  // selectedItems = [];
  dropdownSettings = {};
  companyDropdownSettings = {};
  currentUser: any;
  language:any;
  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
    if(lang == 'en'){
        this.language = {
        "FirstName":	"First Name",
        "LastName"	:"Last Name",
        "Email":"Email",
        "contactNo":	"Contact No",
        "UserType":	"User Type",
        "companyAllowed":"Company Allowed",
        "plantAllowed":"Plant Allowed",
        "cancel":"Cancel",
        "swalFirst":"First name is required",
        "swalLast":"Last name is required",
        "swalAlpha":"Only alphabets are allowed",
        "swalEmail":"Email is required",
        "swalvalEmail":"Enter a valid email address",
        "swalcontact":"Contact number is required",
        "swalvalcon":"Contact number must contain atleast 11 digits",
        "swaluser":"User type is required",
        "swalcompany":"Company allowed is Required",
        "user":"User",
        "UserName":"User Name",
        "swaluserName":"User name is required"
    }}
    else if(lang == 'th'){
      this.language = {
        "FirstName":	"ชื่อจริง",
        "LastName"	:"นามสกุล",
        "Email":"อีเมล",
        "contactNo":	"เบอร์ติดต่อ",
        "UserType":	"ประเภทผู้ใช้",
        "companyAllowed":"บริษัทอนุญาต",
        "plantAllowed":"อนุญาติให้ปลูกพืชได้",
        "cancel":"ยกเลิก",
        "swalFirst":"ต้องระบุชื่อ",
        "swalLast":"ต้องระบุนามสกุล",
        "swalAlpha":"อนุญาตเฉพาะตัวอักษรเท่านั้น",
        "swalEmail":"จำเป็นต้องใช้อีเมล",
        "swalvalEmail":"ใส่อีเมล์ที่ถูกต้อง",
        "swalcontact":"ต้องระบุเบอร์ติดต่อ",
        "swalvalcon":"เบอร์ติดต่อต้องมีอย่างน้อย 11 หลัก",
        "swaluser":"ต้องระบุประเภทผู้ใช้",
        "swalcompany":"จำเป็นต้องได้รับอนุญาตบริษัท",
        "user":"ผู้ใช้",
        "UserName":"ชื่อผู้ใช้",
        "swaluserName":"ต้องระบุชื่อผู้ใช้"
  }
}



    this.changePwd = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      // storeName : ['',[Validators.required]],
      // username : ['',[Validators.required]],
      contactNo: ['', [Validators.required]],
      // status: ['', [Validators.required]],
      roleID: ['', [Validators.required]],
      UserName: ['', [Validators.required]],
      companyAllowed: ['', [Validators.required]],
      // wareHouseLevel: ['',[Validators.required]],
      email: ['', [Validators.required, Validators.email, Validators.pattern(this.emailPattern)]],
      // accessBy: ['no', [Validators.required]]
    })
    this.getRolls(null,this.searchString);
    this.dropdownSettings = {
      singleSelection: false,
      idField: "plantCode",
      textField: "plantName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
    this.companyDropdownSettings = {
      singleSelection: false,
      idField: "companyCode",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
  }
  searchString:any='';
  pageIndex = 0;
  pageSize = 10;
  listLength: any;
  mySpinner = false;
  Rolls:any=[];
  filterRolls:any=[];
  getRolls(event: PageEvent, searchString:any) {
    
     this.mySpinner = true;
    if(event ==  null){
      this.pageIndex = 0;
      this.pageSize = 10;
     }else{
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
     }

    this.aService.getRollList(this.pageIndex,this.pageSize, this.searchString).pipe(first()).subscribe(res => {
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        
        // this.showOverlay=false;
        this.mySpinner = false;
        // this.dataSource = new MatTableDataSource(res.response) ;
        // this.listLength = res.pagination.count;
        res.response.forEach(element => {
          if(element.id != 2){
           this.filterRolls.push(element)
          }
        });
        
        this.Rolls = this.filterRolls
        // this.Rolls = res.response;
        
      } else {
        this.mySpinner = false;
        // this.dataSource = new MatTableDataSource([]);
        this.listLength = 0;
        
        swal.fire(res.responseMessage,"", "error");
      }
    }, error => {
      
      // this.showOverlay=false;
    })
  }
  plantMaster: any = [];
  async getPlantList(com:any) {
    this.mySpinner = true;
    (await this.cService.getPlantMasterSec()).pipe(first()).subscribe(
      (data) => {

        if (data != null) {
          if (data.responseStatus.toUpperCase() == "SUCCESS") {
            this.mySpinner = false;
            // this.dropdownList = data.response;
            let plantData = [];
            if (data != null) {
              if (data.responseStatus.toUpperCase() == "SUCCESS") {
                for(let i  of data.response){
                  let obj = {
                    "plantCode":i.plantCode,
                    "plantName":i.plantCode+"-"+i.nameEn
                  }
                  plantData.push(obj);
                }
                this.dropdownList = plantData;   
                
                let companyList = this.local_data.companyAllowed.split(",");
                let newData = [];
                // 
                for (let i of companyList){
                  for(let x of this.dropdownList){
                    if(i == x.plantCode){
                      this.plantMaster = this.plantMaster.concat(x);
                      this.companyAllowed.push(x);
                      // this.plantMaster.push(x);
                    }
                  }
                }

                
                
              } else {
              
                // swal.fire(data.responseMessage);
              }
            }
            
            // this.showOverlay = false;
          } else {
            this.mySpinner = false;
            // this.showOverlay = false;
            // swal.fire(data.responseMessage);
          }
        }
      },
      () => {
        this.mySpinner = false;
        // this.showOverlay = false;
      }
    );
  }
  numberOnly(event) {
    var regex = new RegExp("^[0-9-!@#$%*?()<>^&+ _-]");
        var key = String.fromCharCode(event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
  }
  
  onNoClick(): void {
    
    let nameregex = /^[a-zA-Z ]*$/;
    let emailregex = /^([\w-]|(?<!\.)\.)+[a-zA-Z0-9]@[a-zA-Z0-9]([\w\-]+)((\.([a-zA-Z]){2,9})+)$/;
      if(this.changePwd.value.firstName == "" || this.changePwd.value.firstName == undefined){
        Swal.fire(`${this.language.swalFirst}`,"","warning");
        return;
      }
      // else if(!nameregex.test(this.changePwd.value.firstName)){
        
      //   Swal.fire(`${this.language.swalAlpha}`,"","warning");
      //   return
      // }
       if(this.changePwd.value.lastName == "" || this.changePwd.value.lastName == undefined){
        Swal.fire(`${this.language.swalLast}`,"","warning");
        return
      }
      // else if(!nameregex.test(this.changePwd.value.lastName)){
      //   // 
      //   Swal.fire(`${this.language.swalAlpha}`,"","warning");
      //   return
      // }
      if(this.changePwd.value.email == "" || this.changePwd.value.email == undefined){
        Swal.fire(`${this.language.swalEmail}`,"","warning");
        return;
      }else if(!emailregex.test(this.changePwd.value.email)){
        // 
        Swal.fire(`${this.language.swalvalEmail}`,"","warning");
        return;
      }
       if(this.changePwd.value.contactNo == "" || this.changePwd.value.contactNo == undefined){
        Swal.fire(`${this.language.swalcontact}`,"","warning");
        return;
      }
       if(this.changePwd.value.roleID == "" || this.changePwd.value.roleID == undefined){
        Swal.fire(`${this.language.swaluser}`,"","warning");
        return;
      }
      if(this.action == "Add"){
        if(this.changePwd.value.UserName == "" || this.changePwd.value.UserName == undefined){
          Swal.fire(`${this.language.swaluserName}`,"","warning");
          return;
        }
      }

    if ((this.changePwd.value.firstName != "" && this.changePwd.value.firstName != undefined)
      && (this.changePwd.value.lastName != "" && this.changePwd.value.lastName != undefined)
      && (this.changePwd.value.email != "" && this.changePwd.value.email != undefined)
      && (this.changePwd.value.contactNo != "" && this.changePwd.value.contactNo != undefined)
      && (this.changePwd.value.roleID != "" && this.changePwd.value.roleID != undefined)) {
      let companyAllowed = [];

      if (this.changePwd.value.companyAllowed != undefined || this.changePwd.value.companyAllowed != "") {
        let company = this.companyAllowed.length;
        
        if(this.local_data.roleID == 3 || this.local_data.roleID == 6 || this.local_data.roleID == 7){
          if (company > 0) {
            
             for (let i of this.companyAllowed) {
               
               companyAllowed.push(i.plantCode);
   
             }
             
           }
        }else if(this.local_data.roleID == 4 || this.local_data.roleID == 5 || this.local_data.roleID == 8){
          if (company > 0) {
            
             for (let i of this.companyAllowed) {
               
               companyAllowed.push(i.companyCode);
             }
             
           }
        }
      }
      if(this.changePwd.value.roleID !== 1){
        if(this.changePwd.value.roleID !== 2){
          if(this.changePwd.value.companyAllowed == "" || this.changePwd.value.companyAllowed == undefined){
            Swal.fire(`${this.language.swalcompany}`,"","warning");
            return;
          }
        }
      }
      let userdata = {
        "firstName": this.changePwd.value.firstName,
        "lastName": this.changePwd.value.lastName,
        "fullName": this.changePwd.value.firstName + " " + this.changePwd.value.lastName,
        "email": this.changePwd.value.email,
        "contactNo": this.changePwd.value.contactNo,
        "userName":this.changePwd.value.UserName,
        // "isactive": 1,
        // "isBlocked":1,
        "roleID": this.changePwd.value.roleID,
        "companyAllowed": companyAllowed
      };
      
      this.dialogRef.close({ event: this.action, data: userdata });

    } else {
      return

    }

  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
  companyAllowed=[];
  onItemSelect(item: any) {
    
    this.show=true;
    this.companyAllowed.push(item);
    
    // this.getDeliveryNoteDetails();
  }

  onSelectAll(item: any) {
    this.show=true;
    this.companyAllowed = [];
    
    for (let i = 0; i <= item.length - 1; i++) {
      this.companyAllowed.push(item[i]);
    }
    // this.getDeliveryNoteDetails();
  }

  onItemDeSelect(item: any) {
    // this.show=false;
    const index = this.companyAllowed.indexOf(item);
    // if (index > -1) { 
    // this.companyAllowed.splice(item, index);
    this.companyAllowed = this.plantMaster;

    if(this.companyAllowed.length < 1){
      this.show = false;
    }else{
      this.show = true;
    }
    
    // }
    // this.getDeliveryNoteDetails();
  }
  onItemDeSelectAll(item: any) {
    this.show=false;
    // const index = this.local_data.companyAllowed.indexOf(item);
    this.companyAllowed = [];
    // this.getDeliveryNoteDetails();
    //}


  }

  Space(event:any){
    if( event.key === ' ' && event.target.selectionStart === 0){
      event.preventDefault(); 
    }
  }
  
  keypressalpha(event:any){
    var inp = String.fromCharCode(event.keyCode);
    if ( !/^[/,<>+{}?|%="':;]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  keypressalphaNumeric(event:any){
    var inp = String.fromCharCode(event.keyCode);
    if (/^[a-zA-Z0-9]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

async getCompanyList() {
    this.mySpinner = true;
    (await this.aService.getCompanyList()).pipe(first()).subscribe(
      (data) => {

        if (data != null) {
          if (data.responseStatus.toUpperCase() == "SUCCESS") {
            
            this.mySpinner = false;
            // this.dropdownList = data.response;
            let plantData = [];
            if (data != null) {
              if (data.responseStatus.toUpperCase() == "SUCCESS") {
                for(let i  of data.response){
                  let obj = {
                    "companyCode":i.companyCode,
                    "name":i.companyCode +"-"+ i.name
                  }
                  plantData.push(obj);
                }
                this.dropdownList = plantData;   
                
                let companyList = this.local_data.companyAllowed.split(",");
                let newData = [];
                // 
                for (let i of companyList){
                  for(let x of this.dropdownList){
                    if(i == x.companyCode){
                      this.plantMaster = this.plantMaster.concat(x);
                      this.companyAllowed.push(x);
                      // this.plantMaster.push(x);
                    }
                  }
                }

                
                
              } else {
                this.mySpinner = false;
                // swal.fire(data.responseMessage);
              }
            }
            
            // this.showOverlay = false;
          } else {
            this.mySpinner = false;
            // this.showOverlay = false;
            // swal.fire(data.responseMessage);
          }
        }
      },
      () => {
        this.mySpinner = false;
        // this.showOverlay = false;
      }
    );
  }
  onChange(event:any){
    
    let data = event.source.value;
    this.plantMaster = [];
    if(data == 3 || data == 6 || data == 7){
      this.getPlantList(null);
    }else if(data == 4 || data == 5 || data == 8){
      this.getCompanyList();
    }else{
      this.changePwd.controls.companyAllowed.setValue([]);
    }
  }
}