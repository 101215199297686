import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2';
import { CommonServicesService } from 'src/app/services/common-services.service';
import { Idle } from '@ng-idle/core';
import { AdminServiceService } from 'src/app/services/admin-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']

})
export class AppHeaderComponent {
  constructor(
     private router: Router,
     private cService: CommonServicesService,
     private idle: Idle,
     private aService: AdminServiceService 
     ) { }
  langData:any
  selectedValue:any
  userName:any;
  currentUser: any = [];
  userType: any;
  welcome: any;
  ngOnInit(): void {
    let data = JSON.parse(localStorage.getItem("currentUser"));
    this.userName = data.response.fullName;
    this.langData = data.response.langPreference;
    this.currentUser = data.response;
    
    if (this.langData == "en"){
      this.selectedValue = "ไทย";
      this.welcome = "Welcome";
      // this.userName = data.response.firstName;
    }
    if (this.langData == "th"){
      this.welcome = "ยินดีต้อนรับ";
      this.selectedValue = "en";
      this.langData = "ไทย";
      // this.userName = data.response.lastName;
    }
    }
    data:any;
    otherValue(value:any){
      if (value == "ไทย"){
          this.data = "th"
      }
      else {
        this.data = "en"
      }
      this.getLangPreference(this.data)
    
  }

  async getLangPreference(value:any){
    (await this.cService.getLangPreference(value)).pipe(first()).subscribe(res => {
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        
        this.langData = res.response.userDetails.langPreference;
        
        let user = {
          pagination: res.pagination,
          response: res.response.userDetails,
          responseCode: res.responseCode,
          responseMessage: res.responseMessage,
          responseStatus: res.responseStatus,
          token: res.token,

        }
       
        localStorage.setItem("currentUser", JSON.stringify(user));
        localStorage.setItem('menuList', JSON.stringify(res.response.menuList));
        localStorage.setItem('menuAccess', JSON.stringify(res.response.menuAccess));
        
        if (this.langData == "en"){
            this.selectedValue = "ไทย"
            // swal.fire("Language Changed To English Successfully")
        }
        else if (this.langData == "th"){
          this.selectedValue = "en";
          this.langData = "ไทย"
          // swal.fire("ภาษาเปลี่ยนเป็นภาษาไทยได้สำเร็จ")
        }
        location.reload();
      } else {
        swal.fire(res.responseMessage)
      }
    }

    )}
  // isLoggedIn = new BehaviorSubject(false);
  signOut() {
    // this.isLoggedIn.next(false);
    // var Backlen=history.length;   
    // history.go(-Backlen);
    let data = {
      "userId": this.currentUser.id,
      "type": "Normal"      
    }
    this.aService.logout(data).pipe(first()).subscribe(data => {}, () => {});
    localStorage.removeItem("currentUser");
    localStorage.clear();
    this.router.navigate(["signin"]);
    this.idle.stop();
    this.idle.ngOnDestroy();
    history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function () {
    // history.go(1);
});
  }

  openProfile(){
    this.router.navigate(["profile"]);
  }

  privacy(){
    this.router.navigate(["/privacy-policy"]);
  }
}
