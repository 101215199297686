import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  constructor( private router: Router,) { }

  ngOnInit(): void {
    // localStorage.removeItem("currentUser");
    localStorage.clear();
  }
  goTo(){
    
    this.router.navigate(["/signin"])
  }
  home(){
    this.router.navigate(["landing-page"])
  }
  privacy(){
    this.router.navigate(["/privacy-policy"])
  }
}
