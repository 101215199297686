import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {DatePipe } from '@angular/common';
import { first } from 'rxjs/operators';
import { CommonServicesService } from 'src/app/services/common-services.service';
import Swal from 'sweetalert2';
import { TableUtil } from 'src/app/reports/TableUtil';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { AdminServiceService } from 'src/app/services/admin-service.service';
import { thFont } from 'src/app/reports/thai-lang';

@Component({
  selector: 'app-dn-report',
  templateUrl: './dn-report.component.html',
  styleUrls: ['./dn-report.component.css']
})
export class DnReportComponent implements OnInit {
  @ViewChild('fromDate') fromDate: ElementRef;
  @ViewChild('endDate') endDate: ElementRef;
  @ViewChild('matTable') matTable!: ElementRef;
  // @ViewChild('matTable') matTable: ElementRef;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  requestData:any;
  dnRange = new FormGroup({
    postingDateFrom: new FormControl(''),
    postingDateTo: new FormControl(''),
  });
  paymentRange = new FormGroup({
    paymentDateFrom: new FormControl(''),
    paymentDateTo: new FormControl(''),
  });
  dnDateRange = new FormGroup({
    dnDateFrom: new FormControl(''),
    dnDateTo: new FormControl(''),
  });
  dnPostingDateRange = new FormGroup({
    dnPostingDateFrom: new FormControl(''),
    dnPostingDateTo: new FormControl(''),
  });

  maxDate = new Date();
  table: boolean = false;
  dropdownSettings = {};
  dropdownSettings1 = {};
  dropdownSettings2 = {};
  dropdownSettings3 = {};
  dropdownSettings4 = {};
  columnsToDisplay:any=[];
  dataSource:MatTableDataSource<any>;
  dataSource2:any=[];
  today= new Date();
  fileName= 'ExcelSheet.xlsx';
  mySpinner:boolean=false;
  InvoiceFor:any;
  typeOfInvoice:any;
  mDocNo:any;
  //jsttoday = '';
  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private cService:CommonServicesService,
    private adminService: AdminServiceService,
    ) {
    // this.jsttoday = formatDate(this.today, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
   }
   potypes = [
    { key: "G", value: "Goods" },
    { key: "W", value: "Goods - Weigh bridge" },
    { key: "S", value: "Service" }

  ];
  invoicetypes = [
    { key: "Y", value: "Invoice" },
    { key: "N", value: "E-Invoice" },
  ]
  utfString:any;
   async openPDF() {
    this.mySpinner = true;
    (await this.cService.getDNExportPDFdata(this.requestData)).pipe(first()).subscribe(res => {
      if(res.response != null){
        if (res.responseStatus.toUpperCase() == "SUCCESS") {
          
          this.table = true;
          this.mySpinner = false;
        } else {
          this.mySpinner = false;
          Swal.fire(res.responseMessage,"","error");
        }
      }else{
        this.mySpinner = false;
        Swal.fire(res.responseMessage,"","error");
      }

      var font = thFont;
   
      const doc = new jsPDF('l', 'pt', "a4",true);
      doc.addFileToVFS("supermarket.ttf",font);
      doc.addFont("supermarket.ttf", "Amiri",'normal', '800');
      doc.setFont("Amiri",'normal');
    
    // this.utfString = decodeURIComponent(res.response.replace(/\\x/g,"%"));
    doc.setFontSize(5);
    if(this.currentUser.roleID == 2){
      var col = ["Status","Company Name","PO Number","PO Item","Plant Code","Plant Name","Material","service No","Material Group","Short Text","PO Quantity","Unit","Unit Price","Delivery Date","VPDN#","Delivery Note Number","Delivery Note Date","DN Posting Date","Delivery Person Email","Delivery Person Name","DN Quantity","Total Amount","Material DOC No","Material DOC Year"];
    }else{
      var col = ["Status","Company Name","Vendor Code","Name","PO Number","PO Item","Plant Code","Plant Name","Material","service No","Material Group","Short Text","PO Quantity","Unit","Unit Price","Delivery Date","VPDN#","Delivery Note Number","Delivery Note Date","DN Posting Date","Delivery Person Email","Delivery Person Name","DN Quantity","Total Amount","Material DOC No","Material DOC Year","SAP Response"];
    }
    
    var rows = [];

    
    if(this.currentUser.roleID == 2){
      res.response.forEach(x => {      
        var temp = [
            x.status,
            x.company,
            x.poNumber,
            x.poItem,
            x.plantCode,
            x.plantName,
            x.material,
            x.serviceNumber,
            x.materialGroup,
            x.shortText,
            Number(x.poQuantity).toLocaleString('en', { minimumFractionDigits: 3 }),
            x.unit,
            Number(x.unitPrice).toLocaleString('en', { minimumFractionDigits: 2 }),
            x.deliveryNoteDate != '-'?this.datePipe.transform(x.deliveryNoteDate,'dd/MM/yyyy'):'-',
            x.vpDnNumber?x.vpDnNumber.substring(x.vpDnNumber.indexOf('_')+1):"",
            x.deliveryNoteNumber,
            x.deliveryNoteDate != '-'?this.datePipe.transform(x.deliveryNoteDate,'dd/MM/yyyy'):'-',
            x.postingDate != '-'?this.datePipe.transform(x.postingDate,'dd/MM/yyyy'):'-',
            x.deliveryPersonEmail,
            x.deliveryPersonName,
            Number(x.dnQuantity).toLocaleString('en', { minimumFractionDigits: 3 }),
            Number(x.totalAmount).toLocaleString('en', { minimumFractionDigits: 2 }),
            x.materialDocNumber,
            x.materialDocYear
        ];
        rows.push(temp);

    }); 
    }else{
      res.response.forEach(x => {      
        var temp = [
            x.status,
            x.company,
            x.vendorCode,
            x.vendorName,
            x.poNumber,
            x.poItem,
            x.plantCode,
            x.plantName,
            x.material,
            x.serviceNumber,
            x.materialGroup,
            x.shortText,
            Number(x.poQuantity).toLocaleString('en', { minimumFractionDigits: 3 }),
            x.unit,
            Number(x.unitPrice).toLocaleString('en', { minimumFractionDigits: 2 }),
            x.deliveryNoteDate != '-'?this.datePipe.transform(x.deliveryNoteDate,'dd/MM/yyyy'):'-',
            x.vpDnNumber?x.vpDnNumber.substring(x.vpDnNumber.indexOf('_')+1):"",
            x.deliveryNoteNumber,
            x.deliveryNoteDate != '-'?this.datePipe.transform(x.deliveryNoteDate,'dd/MM/yyyy'):'-',
            x.postingDate != '-'?this.datePipe.transform(x.postingDate,'dd/MM/yyyy'):'-',
            x.deliveryPersonEmail,
            x.deliveryPersonName,
            Number(x.dnQuantity).toLocaleString('en', { minimumFractionDigits: 3 }),
            Number(x.totalAmount).toLocaleString('en', { minimumFractionDigits: 2 }),
            x.materialDocNumber,
            x.materialDocYear,
            x.sapResponse
        ];
        rows.push(temp);

    }); 
    }
           

    (doc as any).autoTable(col, rows,{
      startY: false,
      theme: 'grid',
      tableWidth: 'auto',
      cellWidth: 'wrap',
      showHead: 'everyPage',
      tableLineColor: 200,
      tableLineWidth: 0,
      columnStyles: {
          0: {
              cellWidth: 30.5
          },
          1: {
              cellWidth: 30.5
          },
          2: {
              cellWidth: 30.5
          },
          3: {
              cellWidth: 30.5
          },
          4: {
              cellWidth: 30.5
          },
          5: {
              cellWidth: 30.5
          },
          6: {
              cellWidth: 30.5
          },
          7: {
              cellWidth: 30.5
          },
          8: {
              cellWidth: 30.5
          },
          9: {
              cellWidth: 30.5
          },
          10: {
              cellWidth: 30.5
          },
          11: {
              cellWidth: 30.5
          },
          12: {
              cellWidth: 30.5
          },
          13: {
              cellWidth: 30.5
          },
          14: {
              cellWidth: 30.5
          },
          15: {
              cellWidth: 30.5
          },
          16: {
              cellWidth: 30.5
          },
          17: {
              cellWidth: 30.5
          },
          18: {
              cellWidth: 30.5
          },
          19: {
              cellWidth: 30.5
          },
          20: {
              cellWidth: 30.5
          },
          21: {
              cellWidth: 30.5
          },
          22: {
              cellWidth: 30.5
          },
          23: {
              cellWidth: 30.5
          },
          24: {
              cellWidth: 30.5
          },
          25: {
              cellWidth: 30.5
          },
          26: {
              cellWidth: 30.5
          }
      },
      headStyles: {
          theme: 'grid',
          fillColor : [237, 28, 36],
          font: 'helvetica',
          fontStyle:"bold",
      },
      margins :{
        top: 10,
        bottom: 10,
        left: 10,
        width: 522
      },
      styles: {
          overflow: 'linebreak',
          cellWidth: 'wrap',
          // font: 'arial',
          fontSize: 3,
          font:"Amiri",
          fontStyle:"bold",
          // cellPadding: 2,
          overflowColumns: 'linebreak'
      },
  });
    // 
    doc.addPage('a3');
    // doc.addFileToVFS('NotoSerifThai-Regular.ttf', 'yourCustomFontTtfBase64Encoded');
    // doc.addFont('/assets/fonts/NotoSerifThai-Regular.ttf', 'ThaiFont', 'normal','StandardEncoding');
    // doc.setFont('ThaiFont');
    // doc.setLanguage("th")
    doc.save('Delivery-Note-Report.pdf');
  });
  }


async exportexcel()
{
  if(this.requestData == undefined){
  this.requestData = {
    "companyCodes": [],
    "status": [],
    "material": [],
    "plantName": [],
    "vendorCode": []
}
}
  this.mySpinner = true;
    (await this.cService.getDNExportPDFdata(this.requestData)).pipe(first()).subscribe(res => {
      if(res.response != null){
        if (res.responseStatus.toUpperCase() == "SUCCESS") {
          
          this.table = true;
          this.mySpinner = false;
        } else {
          this.mySpinner = false;
          Swal.fire(res.responseMessage,"","error");
        }
      }else{
        this.mySpinner = false;
        Swal.fire(res.responseMessage,"","error");
      }

      let onlyNameAndSymbolArr: Partial<PeriodicElement>[];
    if(this.currentUser.roleID == 2){
       onlyNameAndSymbolArr = res.response.map(
        x => ({
          "STATUS":x.status,
          "COMPANY NAME":x.company,
          "PO NUMBER":x.poNumber,
          "PO ITEM":x.poItem,
          "PLANT CODE":x.plantCode,
          "PLANT NAME":x.plantName,
          "MATERIAL NO":x.material,
          "SERVICE NUMBER":x.serviceNumber,
          "MATERIAL GROUP":x.materialGroup,
          "SHORT TEXT":x.shortText,
          "PO QTY":Number(x.poQuantity).toLocaleString('en', { minimumFractionDigits: 3 }),
          "UNIT":x.unit,
          "UNIT PRICE":Number(x.unitPrice).toLocaleString('en', { minimumFractionDigits: 2 }),
          "DELIVERY DATE":x.deliveryNoteDate != '-'?this.datePipe.transform(x.deliveryNoteDate,'dd/MM/yyyy'):'-',
          "VPDN#":x.vpDnNumber?x.vpDnNumber.substring(x.vpDnNumber.indexOf('_')+1):"",
          "DELIVERY NOTE NUMBER":x.deliveryNoteNumber,
          "DELIVERY NOTE DATE":x.deliveryNoteDate != '-'?this.datePipe.transform(x.deliveryNoteDate,'dd/MM/yyyy'):'-',
          "DELIVERY PERSON NAME":x.deliveryPersonName,
          "DN POSTING DATE":x.postingDate != '-'?this.datePipe.transform(x.postingDate,'dd/MM/yyyy'):'-',
          "DELIVERY PERSON EMAIL":x.deliveryPersonEmail,
          "DN QUANTITY":Number(x.dnQuantity).toLocaleString('en', { minimumFractionDigits: 3 }),
          "TOTAL AMOUNT":Number(x.totalAmount).toLocaleString('en', { minimumFractionDigits: 2 }),
          "MATERIAL DOC NUMBER":x.materialDocNumber,
          "MATERIAL DOC YEAR":x.materialDocYear
        })
      )
    }else{
      onlyNameAndSymbolArr = res.response.map(
        x => ({
          "STATUS":x.status,
          "COMPANY NAME":x.company,
          "VENDOR CODE":x.vendorCode,
          "NAME":x.vendorName,
          "PO NUMBER":x.poNumber,
          "PO ITEM":x.poItem,
          "PLANT CODE":x.plantCode,
          "PLANT NAME":x.plantName,
          "MATERIAL NO":x.material,
          "SERVICE NUMBER":x.serviceNumber,
          "MATERIAL GROUP":x.materialGroup,
          "SHORT TEXT":x.shortText,
          "PO QTY":Number(x.poQuantity).toLocaleString('en', { minimumFractionDigits: 3 }),
          "UNIT":x.unit,
          "UNIT PRICE":Number(x.unitPrice).toLocaleString('en', { minimumFractionDigits: 2 }),
          "DELIVERY DATE":x.deliveryNoteDate != '-'?this.datePipe.transform(x.deliveryNoteDate,'dd/MM/yyyy'):'-',
          "VPDN#":x.vpDnNumber?x.vpDnNumber.substring(x.vpDnNumber.indexOf('_')+1):"",
          "DELIVERY NOTE NUMBER":x.deliveryNoteNumber,
          "DELIVERY NOTE DATE":x.deliveryNoteDate != '-'?this.datePipe.transform(x.deliveryNoteDate,'dd/MM/yyyy'):'-',
          "DELIVERY PERSON NAME":x.deliveryPersonName,
          "DN POSTING DATE":x.postingDate != '-'?this.datePipe.transform(x.postingDate,'dd/MM/yyyy'):'-',
          "DELIVERY PERSON EMAIL":x.deliveryPersonEmail,
          "DN QUANTITY":Number(x.dnQuantity).toLocaleString('en', { minimumFractionDigits: 3 }),
          "TOTAL AMOUNT":Number(x.totalAmount).toLocaleString('en', { minimumFractionDigits: 2 }),
          "MATERIAL DOC NUMBER":x.materialDocNumber,
          "MATERIAL DOC YEAR":x.materialDocYear,
          "SAP RESPONSE":x.sapResponse
        })
      )
    }

  TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "Dn-Report");
})
// 
// if(this.requestData == undefined){
//   this.requestData = {
//     "companyCodes": [],
//     "status": [],
//     "material": [],
//     "plantName": [],
//     "vendorCode": []
// }
// }
// this.mySpinner = true;
//   (await this.cService.dnExport(this.requestData)).pipe(first()).subscribe(res => {
//     this.mySpinner = false;
//     
//   }, error => {
//     this.mySpinner = false;
//   })
}

  apReportForm: FormGroup = this.fb.group({
    multiSelectCompany:[],
    multiSelectStatus:[],
    materialNo:[],
    multiSelectPlantName:[],
    companyCodes: [],
    status: [],
    vendorCodeFrom: [],
    vendorCodeTo: [],
    postingDateFrom: [],
    postingDateTo:[],
    dpEmail: [],
    dpName:[],
    invoiceNoTo: [],
    purchaseOrderNoFrom: [],
    purchaseOrderNoTo: [],
    plantCodeFrom: [],
    plantName:[],
    vpdnFrom:[],
    vpdnTo:[],
    dnNo:[],
    mDocNo:[],
    vendorPortalRefNoTo: [],
    vendorName: [],
    InvoiceFor:[],
    typeOfInvoice:[],
    invoiceDateFrom:[],
    invoiceDateTo:[],
    multiSelectVendor:[],
    year:[]
  });

  language:any;
  plantName:any;
  vpdnFrom:any;
  vpdnTo:any;
  dnNo:any;
  year:any;
  currentUser:any=[];
  ngOnInit() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
    if(lang == 'en'){
        this.language = {
          "poNumber":"PO Number",
          "plantname":"Plant Name",
          "EnterPlantName":"Enter Plant Name",
          "vpdn":"VPDN#",
          "materialNo":"Material No",
          "totalAmount":"Total Amount",
          "Currency":"Currency",
          "PaymentTerm":"Payment Terms",
          "button1":"Approve",
          "button2":"Reject",
          "VendorCode":"Vendor Code",
          "poItem":"PO Item",
          "postingDate":"AP Posting Date",
          "Head":"Delivery note Tracking Report",
          "invoiceDate":"Invoice Date",
          "CodeFrom":"Enter Code From",
          "ToCode":"Enter Code To",
          "dateRange":"Enter a date range",
          "Startdate":"Start date",
          "Enddate":"End date",
          "plantCode":"Plant Code",
          "plantName":"Plant Name",
          "deliveryDate":"Delivery Date",
          "InvoiceNumber":"Invoice No",
          "NumberFrom":"Number From",
          "ToNumber":"To Number",
          "dnNumber":"Delivery Note Number",
          "Search":"Search",
          "DOC":"DOC#",
          "Invoice":"INVOICE#",
          "taxType":"TAX TYPE",
          "netDueDate":"NET DUE DATE",
          "paymentDate":"PAYMENT DATE",
          "paymentDoc":"PAYMENT DOC.",
          "paymentMethod":"PAYMENT METHOD",
          "WHTTYPE1":"WHT TYPE 1",
          "WHTCODE1":"WHT CODE 1",
          "WHTBASEAmt1":"WHT BASE Amt 1",
          "WHTAmt1":"WHT Amt 1",
          "WHTTYPE2":"WHT TYPE 2",
          "WHTCODE2":"WHT CODE 2",
          "WHTBASEAmt2":"WHT BASE Amt 2",
          "WHTAmt2":"WHT Amt 2",
          "vp" :"VP#",
          "po":"PO#",
          "dnQty":"DN Quantity",
          "dpEmail":"Delivery Person Email",
          "dpName":"Delivery Person Name",
          "mDocNumber":"Material DOC Number",
          "mDocYear":"Material DOC Year",
          "SAPAPUSER":"SAP AP USER",
          "print":"Print",
          "status":"Status",
          "Comment":"Comment",
          "Pending":"Pending",
          "Clear":"Clear",
          "company":"Company Name",
          "selectCompany":"Select Company",
          "SelectStatus":"Select Status",
          "EnterPlantCodeFrom":"Enter Plant Code",
          "EnterPlantCodeTo":"Enter Plant Code To",
          "EnterVendorName":"Enter Vendor Name",
          "EnterPONumberFrom":"Enter PO No From",
          "EnterPONumberTo":"Enter PO No To",
          "swalVenFrom":"Vendor Code 'From' is required",
          "swalVenTo":"Vendor Code 'To' is required",
          "swalInFrom":"Invoice Number 'From' is required",
          "swalInFromVp":"VP Number 'From' is required",
          "swalInFromPoNo":"PO Number 'From' is required",
          "swalInToPoNo":"PO Number 'To' is required",
          "swalInToVp":"VP Number 'To' is required",
          "swalInTo":"Invoice Number 'To' is required",
          "swalVenGFT":"Vendor Code 'From' cannot be greater than Vendor Code 'To'",
          "swalInGFT":"Invoice Number 'From' cannot be greater than Invoice Number 'To'",
          "swalStatus":"Select status to get AP Team Report",
          "swalPostingDate":"Posting Date 'From' cannot be greater than Posting Date 'To'",
          "swalPaymentDate":"Payment Date 'From' cannot be greater than Payment Date 'To'",
          "swalInvoiceDate":"Invoice Date 'From' cannot be greater than Invoice Date 'To'",
          "invoiceFor":"Invoice For",
          "typeOfInvoice":"Type of Invoice",
          "AddinvoiceDate":"Vendor Add Invoice Date",
          "vedorCode":"Vendor Code",
          "swalvenCode":"Please Select Vendor Code",
          "vpdnFrom":"Enter VPDN No From",
          "vpdnTo":"Enter VPDN No To",
          "deliveryNoteNo":"Delivery Note No",
          "EnterDeliveryNoteNo":"Enter Delivery Note No",
          "deliveryNoteDate":"Delivery Note Date",
          "dnPostingDate":"DN Posting Date",
          "materialDocNo":"Material Doc No",
          "deliveryPersonEmail":"Delivery Person Email",
          "EntermaterialDocNo":"Enter Material Doc No",
          "enterdeliveryPersonEmail":"Enter Delivery Person Email",
          "deliveryPersonName":"Delivery Person Name",
          "enterdeliveryPersonName":"Enter Delivery Person Name",
          "materialGroup":"Material Group",
          "shortText":"Short Text",
          "poQuantity":"Po Qty",
          "unit":"Unit",
          "unitPrice":"Unit price",
          "vendorCode":"Vendor Code",
          "name":"Name",
          "sapResponse":"SAP Response",
          "serviceNumber":"Service number",
          "year":" VPDN# Year",
          "EnterYear":"Enter Year",
          "swalyear":"Enter Valid Year"
    }}
    else if(lang == 'th'){
      this.language = {
        "poNumber":"เลขที่ใบสั่งซื้อ",
        "plantname":"รหัสโรงงาน",
        "EnterPlantName":"ใส่ชื่อพืช",
        "materialNo":"หมายเลขวัสดุ",
        "vpdn":"VPDN#",
        "totalAmount":"จำนวนเงินทั้งหมด",
        "Currency":"สกุลเงิน",
        "PaymentTerm":"เงื่อนไขการชำระเงิน",
        "button1":"อนุมัติ",
        "button2":"ปฏิเสธ",
        "VendorCode":"รหัสผู้ขาย",
        "poItem":"รายการ PO",
        "postingDate":"วันที่โพสต์ AP",
        "Head":"บันทึกการจัดส่งรายงานการติดตาม",
        "invoiceDate":"วันที่ออกใบแจ้งหนี้",
        "deliveryDate":"วันที่จัดส่ง",
        "dnNumber":"หมายเลขใบส่งสินค้า",
        "CodeFrom":"ใส่รหัสจาก",
        "ToCode":"ใส่รหัส To",
        "dnQty":"จำนวนใบส่งของ",
        "dateRange":"ป้อนช่วงวันที่",
        "Startdate":"วันที่เริ่มต้น",
        "Enddate":"วันที่สิ้นสุด",
        "plantCode":"รหัสพืช",
        "plantName":"ชื่อพืช",
        "dpEmail":"อีเมล์คนส่งของ",
        "dpName":"ชื่อผู้ส่งสินค้า",
        "InvoiceNumber":"เลขใบสั่งของ",
        "NumberFrom":"หมายเลขจาก",
        "ToNumber":"ไปที่หมายเลข",
        "Search":"ค้นหา",
        "DOC":"เอกสาร#",
        "Invoice":"ใบแจ้งหนี้#",
        "taxType":"ประเภทภาษี",
        "mDocNumber":"หมายเลข DOC วัสดุ",
        "mDocYear":"วัสดุ DOC ปี",
        "netDueDate":"วันที่ครบกำหนดสุทธิ",
        "paymentDate":"วันจ่าย",
        "paymentDoc":"เอกสารการชำระเงิน.",
        "paymentMethod":"วิธีการชำระเงิน",
        "WHTTYPE1":"WHT พิมพ์ 1",
        "WHTCODE1":"WHT รหัส 1",
        "WHTBASEAmt1":"WHT ปริมาณพื้นฐาน 1",
        "WHTAmt1":"WHT จำนวน 1",
        "WHTTYPE2":"WHT พิมพ์ 2",
        "WHTCODE2":"WHT รหัส 2",
        "WHTBASEAmt2":"WHT ปริมาณพื้นฐาน 2",
        "WHTAmt2":"WHT จำนวน 2",
        "vp":"VP#",
        "po":"PO#",
        "SAPAPUSER":"ผู้ใช้ SAP AP",
        "print":"พิมพ์",
        "status":"สถานะ",
        "Comment":"ความคิดเห็น",
        "Pending":"รอดำเนินการ",
        "Clear":"ล้างข้อมูลการค้นหา",
        "company":"บริษัท",
        "selectCompany":"เลือกบริษัท",
        "SelectStatus":"เลือกสถานะ",
        "EnterVendorName":"ใส่ชื่อผู้ขาย",
        "swalVenFrom":"ต้องระบุรหัสผู้จำหน่าย 'จาก'",
        "swalVenTo":"ต้องระบุรหัสผู้จำหน่าย 'ถึง'",
        "swalInFrom":"ต้องระบุหมายเลขใบแจ้งหนี้ 'จาก'",
        "swalInTo":"ต้องระบุหมายเลขใบแจ้งหนี้ 'ถึง'",
        "swalVenGFT":"รหัสผู้ขาย 'จาก' ต้องไม่มากกว่ารหัสผู้ขาย 'ถึง'",
        "swalInGFT":"หมายเลขใบแจ้งหนี้ 'จาก' ต้องไม่มากกว่าหมายเลขใบแจ้งหนี้ 'ถึง'",
        "swalStatus":"เลือกสถานะเพื่อรับรายงานทีม AP",
        "swalPostingDate":"วันที่โพสต์ 'จาก' ต้องไม่มากกว่าวันที่โพสต์ 'ถึง'",
        "EnterPlantCodeFrom":"ใส่รหัสโรงงาน",
        "EnterPlantCodeTo":"ใส่รหัสโรงงานถึง",
        "EnterPONumberFrom":"ป้อนหมายเลข PO จาก",
        "EnterPONumberTo":"ป้อนหมายเลข PO ถึง",
        "swalInFromVp":"ต้องระบุหมายเลข VP 'จาก'",
        "swalInToVp":"ต้องการหมายเลข VP 'ถึง'",
        "swalInFromPoNo":"ต้องระบุหมายเลข PO 'จาก'",
        "swalInToPoNo":"ต้องระบุหมายเลข PO 'ถึง'",
        "swalPaymentDate":"วันที่ชำระเงิน 'จาก' ต้องไม่มากกว่าวันที่ชำระเงิน 'ถึง'",
        "swalInvoiceDate":"วันที่ในใบแจ้งหนี้ 'จาก' ต้องไม่มากกว่าวันที่ในใบแจ้งหนี้ 'ถึง'",
        "invoiceFor":"ใบแจ้งหนี้สำหรับ",
        "typeOfInvoice":"ประเภทของใบแจ้งหนี้",
        "AddinvoiceDate":"ผู้ขายเพิ่มวันที่ในใบแจ้งหนี้",
        "vedorCode":"รหัสผู้ขาย",
        "swalvenCode":"กรุณาเลือกรหัสผู้จำหน่าย",
        "vpdnFrom":"ป้อนหมายเลข VPDN จาก",
        "vpdnTo":"ป้อนหมายเลข VPDN ถึง",
        "deliveryNoteNo":"เลขที่ใบส่งของ",
        "EnterDeliveryNoteNo":"ใส่ใบส่งสินค้าเลขที่",
        "deliveryNoteDate":"วันที่ใบส่งของ",
        "dnPostingDate":"วันที่โพสต์ DN",
        "materialDocNo":"วัสดุ Doc No",
        "deliveryPersonEmail":"อีเมล์คนส่งของ",
        "EntermaterialDocNo":"ใส่เอกสารเลขที่",
        "enterdeliveryPersonEmail":"ป้อนอีเมลของผู้จัดส่ง",
        "deliveryPersonName":"ชื่อผู้ส่งสินค้า",
        "enterdeliveryPersonName":"ใส่ชื่อผู้จัดส่ง",
        "materialGroup":"กลุ่มวัสดุ",
        "shortText":"ข้อความสั้น",
        "poQuantity":"Po จำนวน",
        "unit":"หน่วย",
        "unitPrice":"ราคาต่อหน่วย",
        "vendorCode":"รหัสผู้ขาย",
        "name":"ชื่อ",
        "sapResponse":"SAP Response",
        "serviceNumber":"หมายเลขบริการr",
        "year":"VPDN# ปี",
        "EnterYear":"ใส่ ปี",
        "swalyear":"ป้อนปีที่ถูกต้อง"
  }
}

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'companyCode',
      textField: 'companyName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    this.dropdownSettings1 = {
      singleSelection: false,
      closeDropDownOnSelection: true,
      idField: 'id',
      textField: 'status',        
      itemsShowLimit: 1,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };
    this.dropdownSettings2 = {
      singleSelection: false,
      closeDropDownOnSelection: true,
      idField: "vendorCode",
      textField: "nameOne",
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
    this.dropdownSettings3 = {
      singleSelection: false,
      closeDropDownOnSelection: true,      
      itemsShowLimit: 1,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };
    this.dropdownSettings4 = {
      singleSelection: false,
      closeDropDownOnSelection: true,      
      itemsShowLimit: 1,
      idField: "plantCode",
      textField: "plantName",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };
    if(this.currentUser.roleID == 2){
      this.columnsToDisplay = ["status","company","poNo","poItem","plantCode","plantName","material","serviceNumber","materialGroup","shortText","poQuantity","unit","unitPrice","deliveryDate","vpdn","dnNumber","deliveryNoteDate","dpName","dnPostingDate","dpEmail","dnQty","totalAmount","mDocNumber","mDocYear"];
    }else{
      this.columnsToDisplay = ["status","company","vendorCode","name","poNo","poItem","plantCode","plantName","material","serviceNumber","materialGroup","shortText","poQuantity","unit","unitPrice","deliveryDate","vpdn","dnNumber","deliveryNoteDate","dpName","dnPostingDate","dpEmail","dnQty","totalAmount","mDocNumber","mDocYear","sapResponse"];
    }
    this.getCompanyMaster();
    this.getVendorCodes();
    this.getplantMasterReport();
  }
  companyMaster:any=[];
  invoiceStatus: any =[];
  vendorMaster = [];
  async getVendorCodes() {
    this.mySpinner = true;
    (await this.adminService.getVendorsCode()).pipe(first()).subscribe(res => {
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        let plantData:any=[];
        for(let i  of res.response){
          let obj = {
            "vendorCode":i.vendorCode,
            "nameOne":i.vendorCode+"-"+i.nameOne,
            "id":i.id
          }
          plantData.push(obj);
        }
        this.vendorMaster = plantData;
        
        this.mySpinner = false;
      }else{
        this.mySpinner = false;
      } 
    },error =>{
      this.mySpinner = false;
    })

  }
  materialList:any = [];
  async getCompanyMaster(){
    (await this.cService.getDNMasterData()).pipe(first()).subscribe((data) => {
    // (await this.cService.getCompanyMaster(this.currentUser.usertype)).pipe(first()).subscribe((data) => {
      let companyData = [];
      let invoiceStatus = [];
      // if (data != null) {
        
      if (data.responseStatus.toUpperCase() == "SUCCESS") {
        for (let i of data.response.companyLists) {
          let obj = {
            companyCode: i.companyCode,
            companyName: i.companyCode + "-" + i.name,
          };
          companyData.push(obj);     
          this.invoiceStatus = data.response.deliveryNoteStatus;
        }
        
        this.companyMaster = companyData;
        this.materialList = data.response.materialNo;
        
        
        // 

        this.mySpinner = false;
      } else {
        this.mySpinner = false;
        Swal.fire({ 
          icon:'error',
          text: data.responseMessage
        });
      }
      // }
    },
      error => {
        this.mySpinner = false;
      }
    );
  }
  companyLists =[];
  companyCode:any;
  companyName:any;
  vendorName:any;
  vendorList = [];
  onSelect(item: any) {
    // this.companyCode = item.companyCode;
    // this.companyName = item.companyName;
    this.companyLists.push(item.companyCode);
    // 
    
  }

  onDeSelect(item: any) {
    
    // item.companyCode = "";
    // item.companyName = "";
    // this.companyCode = "";
    // this.companyName = "";
    const index = this.companyLists.indexOf(item.companyCode);
    
    this.companyLists.splice(index, 1);
    // 
    
  }

  onSelectAll(items: any) {
    this.companyLists = [];
    for (let i = 0; i < items.length; i++){
    //  let obj ={
    //     "companyCode":items[i].companyCode,
    //     "companyName":items[i].companyName
    //   };
    //   companyL.push(obj);
      this.companyLists.push(items[i].companyCode);
    }
    //this.companyLists = companyL;
    // 
    // 
    // 
    
  }
  
  onDeSelectAll(items: any) {
    this.companyLists = [];
    
  }

  status: any;
  statusId:any;
  selectedItems: any = [];
  itemstatus = [];
  listMaterail = [];
  listPlant = [];
  onSelect1(item: any) {
    
    this.status = item.status;
    this.itemstatus.push(item.id);
  }

  onDeSelect1(item: any) {
    
    item.status = "";
    item.statusId = "";
    this.statusId = "";
    
    const index = this.itemstatus.indexOf(item.id);
    
    this.itemstatus.splice(index, 1);
    
  }  
 
  onSelectAll1(items: any) {
    
    this.itemstatus = [];
    for (let i = 0; i < items.length; i++){
      this.itemstatus.push(items[i].id);
    }
    
  }
  
  onDeSelectAll1(items: any) {
    this.itemstatus = [];
    
  }

  onSelect2(item){
    this.vendorName = item.nameOne;
    this.vendorList.push(item.vendorCode);
  }

  onSelectAll2(items: any) {
    this.vendorList = [];
    for (let i = 0; i < items.length; i++){
      this.vendorList.push(items[i].vendorCode);
    }
    
  }

  onDeSelect2(item: any) {
    
    const index = this.vendorList.indexOf(item.vendorCode);
    
    this.vendorList.splice(index, 1);
    
  }

  onDeSelectAll2(items: any) {
    this.vendorList = [];
    
  }

  onSelect3(item: any) {
    
    this.listMaterail.push(item);
  }
  onDeSelect3(item: any) {
    
    const index = this.listMaterail.indexOf(item);
    
    this.listMaterail.splice(index, 1);
    
  } 
  onSelectAll3(items: any) {
    
    this.listMaterail = [];
    for (let i = 0; i < items.length; i++){
      this.listMaterail.push(items[i]);
    }
    
  }

  onDeSelectAll3(items: any) {
    this.listMaterail = [];
    
  }

  onSelect4(item: any) {
    
    this.listPlant.push(item.plantCode);
  }
  
  onDeSelect4(item: any) {
    
    const index = this.listPlant.indexOf(item.plantCode);
    
    this.listPlant.splice(index, 1);
    
  }

  onSelectAll4(items: any) {
    
    this.listMaterail = [];
    for (let i = 0; i < items.length; i++){
      this.listPlant.push(items[i].plantCode);
    }
    
  }

  onDeSelectAll4(items: any) {
    this.listPlant = [];
    
  }
  search(){
    this.table = true;
  }

//   companyName: any;
//   companyCode: any;
//   company: any;
//   getCompanyList(i:any){
//     for (let j=0; j<i.length; j++){
// this.companyCode = i[j].companyCode;
// this.companyName = i[j].companyName;
// this.company = this.companyCode + "-"+this.companyName
//      }
//     
//   }
onInvoiceForChange(event){
  
  this.InvoiceFor = event;
}
ontypeOfInvoiceChange(event){
  
  this.typeOfInvoice = event
}
onSubmit(){

//   if(this.currentUser.roleID != 2){
//     if (this.apReportForm.value.multiSelectVendor == "" || this.apReportForm.value.multiSelectVendor == undefined) {
//       Swal.fire(`${this.language.swalvenCode}`,"","warning");
//       return;
//   }
// }
  let plantCodeFrom:any;
  let poFrom:any;
  let poTo:any;
  let mDocNO:any;
  let dpEmail:any;
  let dpName:any;
  let postingDateFrom:any;
  let postingDateTo:any;
  let dnDateFrom:any;
  let dnDateTo:any;
  let plantName:any;
  let dnNo:any;
  let vpdnFrom:any;
  let vpdnTo:any;
  let Year:any;
  
  
  // if(this.apReportForm.value.plantCodeFrom){
  //   plantCodeFrom = this.apReportForm.value.plantCodeFrom;
  // }
  if(this.apReportForm.value.purchaseOrderNoFrom){
    poFrom = this.apReportForm.value.purchaseOrderNoFrom;
  }
  if(this.apReportForm.value.purchaseOrderNoTo){
    poTo = this.apReportForm.value.purchaseOrderNoTo;
  }
  if(this.mDocNo){
    mDocNO = this.mDocNo;
    
  }
  if(this.apReportForm.value.dpEmail){
    dpEmail = this.apReportForm.value.dpEmail;
  }
  if(this.dnNo){
    dnNo = this.dnNo;
  }
  if(this.vpdnFrom){
    vpdnFrom = this.vpdnFrom;
  }
  if(this.vpdnTo){
    vpdnTo = this.vpdnTo;
  }
  if(this.apReportForm.value.dpName){
    dpName = this.apReportForm.value.dpName;
  }
  if(this.dnPostingDateFrom){
    postingDateFrom = this.dnPostingDateFrom;
  }
  if(this.dnPostingDateTo){
    postingDateTo = this.dnPostingDateTo;
  }
  if(this.dnDateFrom){
    dnDateFrom = this.dnDateFrom;
  }
  if(this.dnDateTo){
    dnDateTo = this.dnDateTo;
  }
  // if(this.plantName){
  //   plantName = this.plantName;
  // }
  if(this.year){
    Year = Number(this.year);
  }
  // 
  if(this.year && this.year.length != 4){
    Swal.fire({
      icon: 'warning',
      text: `${this.language.swalyear}`
    });
    return;
  }
  

  this.requestData = {
      "companyCodes": this.companyLists,
      "status": this.itemstatus,
      "material":this.listMaterail,
      "vendorCode":this.vendorList,
      "purchaseOrderNoFrom": poFrom,
      "purchaseOrderNoTo": poTo,
      // "plantCodeFrom": plantCodeFrom,
      "plantName": this.listPlant,
      "deliveryNoteNumber":dnNo,
      "vpDnNoFrom":vpdnFrom,
      "vpDnNoTo":vpdnTo,
      "deliveryNoteDateFrom":dnDateFrom,
      "deliveryNoteDateTo":dnDateTo,
      "postingDateFrom": postingDateFrom,
      "postingDateTo": postingDateTo,
      "materialDocNo": mDocNO,
      "deliveryPersonEmail": dpEmail,
      "deliveryPersonName": dpName,
      "year":Year
  };

  
  this.setTableData(null);
}   
pageIndex = 0;
pageSize = 10;
listLength: any;
count:any;
  async setTableData(event: PageEvent){

    if (event == null) {
      this.pageIndex = 0;
      this.pageSize = 10;
    } else {
      // if(event.pageIndex == 0){
      //   this.pageIndex = 0;
      //   this.pageSize = event.pageSize;
      // }else{
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      // }
    }
  let tableData: any [];
  let status : any;
  let comName: any;
      this.mySpinner = true;
      (await this.cService.getDNReport(this.requestData,this.pageIndex, this.pageSize)).pipe(first()).subscribe(res => {
        if(res.response != null){
          if (res.responseStatus.toUpperCase() == "SUCCESS") {
            
            
            this.table = true;
            this.dataSource = new MatTableDataSource(res.response);
            this.dataSource2 = res.response;
            this.listLength = res.pagination.count;
            
            this.mySpinner = false;
          } else {
            this.mySpinner = false;
            this.listLength = 0;
            Swal.fire(res.responseMessage,"","error");
          }
        }else{
          this.mySpinner = false;
          this.dataSource = new MatTableDataSource([]);
          this.listLength = 0;
          Swal.fire(res.responseMessage,"","error");
        }
      },
      error => {
        this.mySpinner = false;
        this.listLength = 0;
      })
}

onChangeOfVendorCode(event: any){
  let etv: any;
  etv = event.target.value;
  let vendorCode :any;
  vendorCode = this.apReportForm.value.vendorCodeFrom;
  
  
  if(vendorCode == "" || vendorCode == undefined){
    this.apReportForm.controls.vendorCodeTo.setValue("");
    Swal.fire(`${this.language.swalVenFrom}`,"","warning")    
    return false;
  }
}

onChangeOfVpNumber(event: any){
  let etv: any;
  etv = event.target.value;
  let invoiceNoVP :any;
  invoiceNoVP = this.apReportForm.value.plantCodeFrom;
  if(invoiceNoVP == "" || invoiceNoVP == undefined){
    this.apReportForm.controls.vendorPortalRefNoTo.setValue("");
    Swal.fire(`${this.language.swalInFromVp}`,"","warning")    
    return false;
  }
}
onChangeOfpoNumber(event: any){
  let etv: any;
  etv = event.target.value;
  let PoNumber :any;
  PoNumber = this.apReportForm.value.purchaseOrderNoFrom;
  if(PoNumber == "" || PoNumber == undefined){
    this.apReportForm.controls.purchaseOrderNoTo.setValue("");
    Swal.fire(`${this.language.swalInFromPoNo}`,"","warning")    
    return false;
  }
}
vendorCFrom: any;
vendorCTo: any;
plantCodeFrom: any;
vpNoTo: any
vName: any ;
poNoFrom : any;
poNoTo : any;
dpEmail : any;
dpName : any;
invoiceTo : any;
onClear(){
  
  
  
  this.requestData = {};
  //this.dataSource = new MatTableDataSource([]);
  this.companyLists = [];
  this.itemstatus = [];
  this.listMaterail = [];
  this.listPlant = [];
  //this.companyCode = "";
  this.apReportForm.get('multiSelectCompany').setValue([]);
  this.apReportForm.get('InvoiceFor').setValue([]);
  this.apReportForm.get('typeOfInvoice').setValue([]);
  this.apReportForm.get('multiSelectVendor').setValue([]);
  this.vendorList = [];
  this.dnRange.controls.postingDateFrom.setValue("");
  this.dnRange.controls.postingDateTo.setValue("");
  this.dnPostingDateRange.controls.dnPostingDateFrom.setValue("");
  this.dnPostingDateRange.controls.dnPostingDateTo.setValue("");
  this.dnPostingDateFrom = "";
  this.dnPostingDateTo = "";
  this.dnDateRange.controls.dnDateFrom.setValue("");
  this.dnDateRange.controls.dnDateTo.setValue("");
  this.isStartDate = true;
  this.isPaymentStartDate = true;
  this.isInvoiceStartDate = true;
  this.statusId = "";
  this.apReportForm.get('multiSelectStatus').setValue([]);
  this.apReportForm.get('multiSelectPlantName').setValue([]);
  this.apReportForm.get('materialNo').setValue([]);
  this.plantCodeFrom = "";
  this.vpNoTo = "";
  this.vName = "";
  this.poNoFrom = "";
  this.poNoTo = "";
  this.dpEmail = "";
  this.dpName = "";
  this.invoiceTo = "";
  this.plantName = "";
  this.vpdnFrom = "";
  this.vpdnTo = "";
  this.dnNo = "";
  this.year = "";
  this.mDocNo = "";
  this.table = false;    
}

isStartDate:boolean = true;
postingDateFrom: any;
postingDateTo:any;
getStartDate(event){
  this.postingDateFrom = this.datePipe.transform(event.target.value,'yyyy-MM-dd');
   if(new Date(this.postingDateTo) < new Date(this.postingDateFrom)){
       Swal.fire(`${this.language.swalPostingDate}`,'','warning');      
     event.target.value = new Date(this.postingDateTo);
     this.postingDateFrom = this.datePipe.transform(this.postingDateTo,'yyyy-MM-dd');
   }else{
     if(this.postingDateTo != "" || this.postingDateTo != undefined){
       //this.deliveryWbDetails = [];
    //    let selectedItem = []
    //    
    //    for (let i = 0; i <= this.selectedValuesWb.length - 1; i++) {
    //      selectedItem.push(this.selectedValuesWb[i].poNumber);
    //    }
    //    this.getWBDetails(selectedItem);
    }
     
   }
  //  
  this.isStartDate = false;     
 }
 isPaymentStartDate:boolean = true;
 paymentDateFrom:any;
 paymentDateTo:any;
 getPaymentStartDate(event){
  this.paymentDateFrom = this.datePipe.transform(event.target.value,'yyyy-MM-dd');
   if(new Date(this.paymentDateTo) < new Date(this.paymentDateFrom)){
       Swal.fire(`${this.language.swalPaymentDate}`,'','warning');      
     event.target.value = new Date(this.paymentDateTo);
     this.paymentDateFrom = this.datePipe.transform(this.paymentDateTo,'yyyy-MM-dd');
   }else{
     if(this.paymentDateTo != "" || this.paymentDateTo != undefined){
       //this.deliveryWbDetails = [];
    //    let selectedItem = []
    //    
    //    for (let i = 0; i <= this.selectedValuesWb.length - 1; i++) {
    //      selectedItem.push(this.selectedValuesWb[i].poNumber);
    //    }
    //    this.getWBDetails(selectedItem);
    }
     
   }
  //  
  this.isPaymentStartDate = false;     
 }
 isInvoiceStartDate:boolean = true;
 dnDateFrom:any;
 dnDateTo:any;
 getDNStartDate(event){
  this.dnDateFrom = this.datePipe.transform(event.target.value,'yyyy-MM-dd');
   if(new Date(this.dnDateTo) < new Date(this.dnDateFrom)){
       Swal.fire(`${this.language.swalInvoiceDate}`,'','warning');      
     event.target.value = new Date(this.dnDateTo);
     this.dnDateFrom = this.datePipe.transform(this.dnDateTo,'yyyy-MM-dd');
   }else{
     if(this.dnDateTo != "" || this.dnDateTo != undefined){
       //this.deliveryWbDetails = [];
    //    let selectedItem = []
    //    
    //    for (let i = 0; i <= this.selectedValuesWb.length - 1; i++) {
    //      selectedItem.push(this.selectedValuesWb[i].poNumber);
    //    }
    //    this.getWBDetails(selectedItem);
    }
     
   }
  //  
  this.isInvoiceStartDate = false;     
 }
 dnPostingDateFrom:any;
 dnPostingDateTo:any;
 dNPostingStartDate(event){
  this.dnPostingDateFrom = this.datePipe.transform(event.target.value,'yyyy-MM-dd');
   if(new Date(this.dnPostingDateTo) < new Date(this.dnPostingDateFrom)){
       Swal.fire(`${this.language.swalInvoiceDate}`,'','warning');      
     event.target.value = new Date(this.dnPostingDateTo);
     this.dnPostingDateFrom = this.datePipe.transform(this.dnPostingDateTo,'yyyy-MM-dd');
   }else{
     if(this.dnPostingDateTo != "" || this.dnPostingDateTo != undefined){
       //this.deliveryWbDetails = [];
    //    let selectedItem = []
    //    
    //    for (let i = 0; i <= this.selectedValuesWb.length - 1; i++) {
    //      selectedItem.push(this.selectedValuesWb[i].poNumber);
    //    }
    //    this.getWBDetails(selectedItem);
    }
     
   }
  //  
  this.isInvoiceStartDate = false;     
 }

  getEndDate(event) {   
    this.postingDateTo = this.datePipe.transform(event.target.value,'yyyy-MM-dd');
    if(new Date(this.postingDateTo) < new Date(this.postingDateFrom)){
      Swal.fire(`${this.language.swalPostingDate}`,'','warning');
      event.target.value = new Date(this.postingDateFrom);
      this.postingDateTo = this.datePipe.transform(this.postingDateFrom,'yyyy-MM-dd');
    }
    // let selectedItem = []
    // 
    // for (let i = 0; i <= this.selectedValuesWb.length - 1; i++) {
    //   selectedItem.push(this.selectedValuesWb[i].poNumber);
    // }

    // let POIDs= (this.selectedValues).toString();
    //
    // let request = {
    //   "poNumber": selectedItem,
    //  // "plantCode": this.plantId
    // }
    // this.dataSourceWb = [];
    // this.invoiceSvData = [];
    if(this.postingDateFrom == null || this.postingDateFrom == undefined ){
        return;
    }
    if(this.postingDateTo == null || this.postingDateTo == undefined ){
      return;
  }
  }

  dnPostingEndDate(event) {   
    this.dnPostingDateTo = this.datePipe.transform(event.target.value,'yyyy-MM-dd');
    if(new Date(this.dnPostingDateTo) < new Date(this.dnPostingDateFrom)){
      Swal.fire(`${this.language.swalInvoiceDate}`,'','warning');
      event.target.value = new Date(this.dnPostingDateFrom);
      this.dnPostingDateTo = this.datePipe.transform(this.dnPostingDateFrom,'yyyy-MM-dd');
    }
    // let selectedItem = []
    // 
    // for (let i = 0; i <= this.selectedValuesWb.length - 1; i++) {
    //   selectedItem.push(this.selectedValuesWb[i].poNumber);
    // }

    // let POIDs= (this.selectedValues).toString();
    //
    // let request = {
    //   "poNumber": selectedItem,
    //  // "plantCode": this.plantId
    // }
    // this.dataSourceWb = [];
    // this.invoiceSvData = [];
    if(this.dnPostingDateFrom == null || this.dnPostingDateFrom == undefined ){
        return;
    }
    if(this.dnPostingDateFrom == null || this.dnPostingDateFrom == undefined ){
      return;
  }
  }
  getInvoiceEndDate(event) {   
    this.dnDateTo = this.datePipe.transform(event.target.value,'yyyy-MM-dd');
    if(new Date(this.dnDateTo) < new Date(this.dnDateFrom)){
      Swal.fire(`${this.language.swalInvoiceDate}`,'','warning');
      event.target.value = new Date(this.dnDateFrom);
      this.dnDateTo = this.datePipe.transform(this.dnDateFrom,'yyyy-MM-dd');
    }
    // let selectedItem = []
    // 
    // for (let i = 0; i <= this.selectedValuesWb.length - 1; i++) {
    //   selectedItem.push(this.selectedValuesWb[i].poNumber);
    // }

    // let POIDs= (this.selectedValues).toString();
    //
    // let request = {
    //   "poNumber": selectedItem,
    //  // "plantCode": this.plantId
    // }
    // this.dataSourceWb = [];
    // this.invoiceSvData = [];
    if(this.dnDateFrom == null || this.dnDateFrom == undefined ){
        return;
    }
    if(this.dnDateTo == null || this.dnDateTo == undefined ){
      return;
  }
  }
  getPaymentEndDate(event) {   
    this.paymentDateTo = this.datePipe.transform(event.target.value,'yyyy-MM-dd');
    if(new Date(this.paymentDateTo) < new Date(this.paymentDateFrom)){
      Swal.fire(`${this.language.swalPaymentDate}`,'','warning');
      event.target.value = new Date(this.paymentDateFrom);
      this.paymentDateTo = this.datePipe.transform(this.paymentDateFrom,'yyyy-MM-dd');
    }
    // let selectedItem = []
    // 
    // for (let i = 0; i <= this.selectedValuesWb.length - 1; i++) {
    //   selectedItem.push(this.selectedValuesWb[i].poNumber);
    // }

    // let POIDs= (this.selectedValues).toString();
    //
    // let request = {
    //   "poNumber": selectedItem,
    //  // "plantCode": this.plantId
    // }
    // this.dataSourceWb = [];
    // this.invoiceSvData = [];
    if(this.paymentDateFrom == null || this.paymentDateFrom == undefined ){
        return;
    }
    if(this.paymentDateTo == null || this.paymentDateTo == undefined ){
      return;
  }
  }

  keyPressNumbers(event) {
    
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) && (charCode != 46)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  allowFour(event){
    if (
      event.target.value.length > 3
    ) {
      event.preventDefault();
      return;
    }
  }

  numberOnly(event) {
    var regex = new RegExp("^[0-9]");
        var key = String.fromCharCode(event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
  }

  
  plantNameList:any = [];
  async getplantMasterReport(){
    (await this.cService.getPlantMasterForReport()).pipe(first()).subscribe((data) => {
        
        let plantData = [];
      if (data.responseStatus.toUpperCase() == "SUCCESS") {
        // this.mySpinner = false;
        for (let i of data.response) {
          let obj = {
            plantCode: i.plantCode,
            plantName: i.plantCode + "-" + i.nameEn,
          };
          plantData.push(obj);
        }
        this.plantNameList = plantData;
        
      } else {
        this.mySpinner = false;
        Swal.fire({ 
          icon:'error',
          text: data.responseMessage
        });
      }
      // }
    },
      error => {
        this.mySpinner = false;
      }
    );
  }

}
export interface PeriodicElement {
    status:any,
    company:any,
    vendorCode:any,
    name:any,
    sapResponse:any
    poNumber:any,
    poItem:any,
    plantCode:any,
    plantName:any,
    material:any,
    serviceNumber:any;
    materialGroup:any,
    shortText:any,
    poQuantity:any,
    unit:any,
    unitPrice:any,
    deliveryDate:any,
    vpDnNumber:any,
    deliveryNoteNumber:any,
    deliveryNoteDate:any,
    postingDate:any,
    deliveryPersonEmail:any,
    deliveryPersonName:any,
    dnQuantity:any,
    totalAmount:any,
    materialDocNumber:any,
    materialDocYear:any
}
