<div  fxFlex="100" class="main">
    <div class="backg mx-4">
    <div class="contains-top">
      
      <div class="contains mat-elevation-z13">
      
        <form [formGroup]="form" >
         
          <div class="formcontrol">
              <h4>RESET PASSWORD</h4>
            </div>
            
          <div class="formcontrol">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>User id</mat-label>
              <input matInput placeholder="User id" formControlName="usertype" [(ngModel)]="usertype" type="text" required readonly>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>User Name</mat-label>
            <input matInput placeholder="User Name" [(ngModel)]="userName" formControlName="userName" type="text" required readonly>
        </mat-form-field>
            <mat-form-field appearance="outline" class="full-width validations">
              <mat-label>New Password</mat-label>
              <input matInput type="password"  placeholder="New Password" formControlName="password" required>
              <!-- <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button> -->
            </mat-form-field>
            <span class="errors-pass" *ngIf="form.get('password').hasError('passwordStrength')">
              {{form.get('password').errors['passwordStrength']}}
          </span>
          </div>
          <div class="formcontrol">
              <mat-form-field appearance="outline" class="full-width validations">
                <mat-label>Confirm Password</mat-label>
                <input type="password" matInput placeholder="Confirm Password" formControlName="confirmPassword" required>
              </mat-form-field>
              <span class="errors-pass" *ngIf="form.get('confirmPassword').hasError('passwordStrength')">
                {{form.get('confirmPassword').errors['passwordStrength']}}
            </span>
            </div>
          <button class="btn" type="submit"  (click)="submit()">Submit</button>
        </form> 
      </div>
    </div>
  </div>
</div>