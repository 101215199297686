<div fxLayoutAlign="center center" fxLayoutAlign.xs="center center" fxLayout.xs="column" fxFlex="100" class="main">
    
    <!-- <div class="my-overlay" *ngIf="showOverlay">
        <mat-spinner></mat-spinner>
    </div> -->
    <mat-card fxFlex="30" fxFlex.xs="100" style="box-shadow: 0px 0px 0px rgb(0 0 0 / 50%);">
        <mat-toolbar style="box-shadow: 0px 0px 0px rgb(0 0 0 / 50%);">
            <img class="logo" src="assets/images/insee_logo_en.png" alt="logo"> <span class="login">Login</span>

        </mat-toolbar>
        <form fxLayoutAlign="stretch" fxLayout="column" class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <mat-form-field appearance="outline" class="validations">
                <mat-label>User Name</mat-label>
                <input matInput placeholder="User Name" formControlName="username" required>
                <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
                <!-- <mat-hint>Hint</mat-hint> -->
            </mat-form-field>
            <mat-form-field appearance="outline" class="validations">
                <mat-label>Password</mat-label>
                <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" required  formControlName="password" maxlength="15">
                <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      <mat-icon style="margin-left: 15px;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
            </mat-form-field>
            <div fxLayout="column" style="margin-top: 1px;">
                <!-- <button mat-raised-button class="login-btn" [disabled]=!form.valid type="submit">Login</button> -->
                <button mat-raised-button class="login-btn"  type="submit">Login</button>
            </div>
            <div class="checkbox-container">
                <a class="frt_pwd" (click)="loginWithAdfs()" target="_blank" style="cursor: pointer;margin-left:0px">Login INSEE SSO</a>
                <a class="frt_pwd" (click)="openDialog('add',{})"  style="cursor: pointer;">Forgot Password?</a>
            </div>
        </form>
        <div class="overlay"  *ngIf="mySpinner">
            <div class="center">
                <mat-spinner></mat-spinner> 
            </div>
            </div>
    </mat-card>
</div>