<mat-card>
  <!-- <mat-card-content> -->
  <mat-card-title>
    <div class="margin-bottotm" fxLayout="row" fxFlex="100">
      <div class="pull-left title" fxFlex="80">
        {{language.head}}
      </div>
      <div class="pull-right" fxFlex="20">
        <mat-icon (click)="goTo()" class="close1">
          close
        </mat-icon>
      </div>
    </div>
  </mat-card-title>
  <hr />

  <mat-card-content>
    <div fxLayout="row" fxLayoutWrap="wrap" class="page-body">
      <div fxFlex.gt-sm="100%" fxFlex="100">
        <div fxLayout="column">
          <!-- <form  [formGroup]="editDeliveryNotes" (submit)="createNewTask()"> -->
          <!-- <div fxFlex.gt-sm="50%" fxFlex="100"> -->
          <div fxLayout="row" fxLayout.xs="column" fxFlex="100" class="padding-right">
            <div *ngIf="currentUser.roleID == '3' || currentUser.roleID == '7'" class="padding-right padding-left" fxFlex="33">
              <mat-form-field appearance="outline">
                <mat-label>{{language.vendorCode}}</mat-label>
                <mat-select appearance="outline" [(ngModel)]="vendorType" [disabled]="blockedHeaders"
                  (selectionChange)="changeVendor($event)">
                  <mat-option *ngFor="let item of vendorList" [value]="item.id+'-'+item.vendorCode">{{
                    item.vendorCode +"-"+ item.nameOne }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="padding-right padding-left" fxFlex="33" fxFlex.xs="100" style="margin-left: -8px;">
              <mat-form-field appearance="outline">
                <mat-label >{{language.companyName}}</mat-label>
                <mat-select appearance="outline" [(ngModel)]="companyCode" [disabled]="blockedHeaders">
                  <mat-option *ngFor="let item of companyMaster" [value]="item.companyCode">
                    {{ item.companyCode + "-" + item.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!-- <mat-label *ngIf="showP" class="poclass1" style="font-weight: 500;width: 11%;">Company Name</mat-label> -->
              <!-- <ng-multiselect-dropdown  [placeholder]="'Company Name'"  [disabled]="displayData"
                [settings]="dropdownSettings4" [data]="companyMaster" [(ngModel)]="companyCode"
                (onSelect)="changeCompany($event)"
                (onDeSelect)="onItemDeSelectCom($event)" >
              </ng-multiselect-dropdown> -->
            </div>

            <div class="padding-right padding-left" fxFlex="33">
              <mat-form-field appearance="outline">
                <mat-label>{{language.plantName}}</mat-label>
                <mat-select appearance="outline" [(ngModel)]="plantType" [disabled]="blockedHeaders"
                  (selectionChange)="changePlant($event)">
                  <mat-option *ngFor="let item of plantMaster" [value]="item.plantCode">{{
                    item.plantCode+"-"+item.nameEn }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div style="margin-top: 5px;" class="padding-right padding-left" fxFlex="33">
              <mat-label *ngIf="show" class="poclass" style="font-weight: 500;">{{language.PONumber}}</mat-label>
              <!-- <mat-label>PO Number</mat-label> -->
              <!-- <angular2-multiselect [data]="itemList" formControlName="poNumber"  [(ngModel)]="selectedItems"
                                [settings]="settings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)"
                                (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)" #dropdownRef>
                              </angular2-multiselect> -->
              <ng-multiselect-dropdown placeholder="{{language.PONumber}}" name="poNumber" [disabled]="blockedHeaders"
                [settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems"
                (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)" (keyup)="onInputchanges($event)">
              </ng-multiselect-dropdown>
            </div>
          </div>



          <form class="basic-form" [formGroup]="editDeliveryNotes" fxLayout="column" class="">

            <div fxFlex="100" fxLayout="row" fxLayout.xs="column">
              <div class="padding-right" fxFlex="33">
                <mat-form-field appearance="outline">
                  <mat-label>{{language.deliveryNoteDate}}</mat-label>
                  <input matInput formControlName="dlDate" [matDatepicker]="picker" [(ngModel)]="deliveryDate"
                    required (dateChange)="changeInput($event)" (keyup)="onInputchanges($event)" [disabled]="isCatogary"/>
                  <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="padding-right padding-left" fxFlex="33">
                <mat-form-field appearance="outline">
                  <mat-label>{{language.deliveryNoteNumber}}</mat-label>
                  <input matInput formControlName="dlNum" type="text" required (keyup)="changeInput($event)" minlength="1" maxlength="16" (keypress)="dlNum($event)"(keyup)="onInputchanges($event)"
                  [readonly]="isCatogary"/>
                </mat-form-field>
              </div>
              <div class="padding-right padding-left" fxFlex="33">
                <mat-form-field appearance="outline">
                  <mat-label>{{language.postingDate}}</mat-label>
                  <input matInput formControlName="psDate" [matDatepicker]="picker1" required (dateChange)="changeInput($event)" (keyup)="onInputchanges($event)"/>
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                  <!-- <input matInput formControlName="psDate" type="date" required /> -->
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxFlex="100">
              <!-- <div class="padding-right" fxFlex="33">
                <mat-form-field appearance="outline">
                  <mat-label>Shipment Number</mat-label>
                  <input matInput formControlName="shipmentNo" type="text" (keyup)="changeInput($event)"/>
                </mat-form-field>
              </div>
              <div class="padding-right padding-left" fxFlex="33">
                <mat-form-field appearance="outline">
                  <mat-label>Bill Of Lading</mat-label>
                  <input matInput formControlName="billOfLading" type="text" minlength="1" maxlength="16" (keypress)="billOfLading($event)"
                  (keyup)="changeInput($event)" />
                </mat-form-field>
              </div> -->
              <div class="padding-right" fxFlex="33">
                <mat-form-field appearance="outline">
                  <mat-label>{{language.totalQuantity}}</mat-label>
                  <input matInput formControlName="totalQty" type="text" required readonly />
                </mat-form-field>
              </div>
              <div class="padding-right padding-left" fxFlex="33">
                <mat-form-field appearance="outline">
                  <mat-label>{{language.totalAmount}}</mat-label>
                  <input matInput formControlName="totalAmt" type="text" required readonly />
                </mat-form-field>
              </div>
              <div class="padding-right padding-left" fxFlex="33">
                <mat-form-field appearance="outline">
                  <mat-label>{{language.headerText}}</mat-label>
                  <input matInput formControlName="hedText" type="text" minlength="1" maxlength="25"
                  (keyup)="changeInput($event)" (keypress)="hedText($event)" (keyup)="onInputchanges($event)"/>
                </mat-form-field>
              </div>
              
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxFlex="100">
              <!-- <div class="padding-right padding-left" fxFlex="33">
                <mat-form-field appearance="outline">
                  <mat-label>Vendor Name</mat-label>
                  <input matInput formControlName="venName" type="text" required />
                </mat-form-field>
              </div> -->
              <div class="padding-right" fxFlex="33">
                <mat-form-field appearance="outline">
                  <mat-label>{{language.deliveryPersonName}}</mat-label>
                  <input matInput formControlName="dlPerson" type="text" (keydown)="Space($event)"
                    (keyup)="changeInput($event)" minlength="1" maxlength="12" (keypress)="dlPerson($event)" (keyup)="onInputchanges($event)"
                    />
                    <!-- (keypress)="keypressalpha($event)"  -->
                </mat-form-field>
              </div>
              <div class="padding-right padding-left" fxFlex="33">
                <mat-form-field appearance="outline" class="validations">
                  <mat-label>{{language.deliveryPersonEmail}}</mat-label>
                  <input matInput formControlName="dlPersonEmail" type="email" (keyup)="changeInput($event)" (keyup)="onInputchanges($event)"/>
                </mat-form-field>
              </div>
              <div fxFlex="33" fxFlex.xs="100" class="padding-right padding-left" *ngIf="currentUser.roleID == '3' || currentUser.roleID == '7'">
                <mat-form-field class="validations" appearance="outline" *ngIf="categoryShow == '001' || categoryShow == '002' || categoryShow == '001-1'">
                    <mat-label>{{language.category}}</mat-label>
                    
                    <mat-select appearance="outline" formControlName="category" [disabled]="isCatogary" [(ngModel)]="cate" (selectionChange)="onCategoryChange($event)" (click)="onInputchanges($event)"
                    required> 
                    <mat-option *ngFor="let item of catelist" [value]="item.value">{{item.name}}
                    </mat-option>
                        <!-- <mat-option value="001">Goods receive Mvt 103</mat-option>
                      <mat-option value="002">Goods receive Mvt 101</mat-option> -->
                    </mat-select>
                    
                </mat-form-field>

                <mat-form-field class="validations" appearance="outline" *ngIf="categoryShow == '005' || categoryShow == '008'">
                  <mat-label>{{language.category}}</mat-label>
                  <input matInput  formControlName="category" [(ngModel)]="categoryValue"  type="text"  readonly />
                 
                      <!-- <mat-option value="001">Goods receive Mvt 103</mat-option>
                    <mat-option value="002">Goods receive Mvt 101</mat-option> -->
                  <!-- </mat-select> -->
                  
              </mat-form-field>
</div>

            </div>
            <!-- <div fxLayout="row" fxLayout.xs="column" fxFlex="100">
             
            </div> -->
            <!-- <div fxFlex.gt-sm="30" class="padding-right padding-left" fxFlex="30">
                              <mat-form-field appearance="outline">
                                <mat-label>Purchasing Group</mat-label>
                                <input matInput formControlName="prGroup" type="text" required />
                              </mat-form-field>
                            </div> -->

          </form>

          <!-- </form> -->
          <div fxFlex="100" fxLayout="row" fxLayout.xs="column">
            <div class="padding-right" fxFlex="50" *ngIf="!categoryBlocked" style="padding-bottom: 2px;">
              {{language.attachments}}:
              <!-- <input type="file" (change)="onFileChanged($event)" /> -->
              <div class="custom-file width-80">
                <form method="post" enctype="multipart/form-data">
                  <label for="upfile" class="custom-file-input">
                    <input #myInput id="upfile" type="file" (change)="upload($event)"
                      accept=".jpeg,.png,.pdf,.doc,.docx" class="custom-file-input"
                      aria-describedby="inputGroupFileAddon01"/>
                  </label>
                </form>
              </div>
              <label style="font-size: 12px;"> {{language.note}}: {{language.noteLine1}}<br/>{{language.noteLine2}}</label>
            </div>

            <div fxFlex="50">
              <ul *ngFor="let download of documents ;">
                <li>
                  <a (click)="downLoad(download)" style="cursor: pointer;">
                    <!-- <mat-icon [inline]="true" aria-hidden="false" aria-label="Download">get_app</mat-icon> -->
                    {{ download.documentName | slice:0:25 }}{{ download.documentName.length > 25 ? '....' : '' }}
                  </a>
                  <!-- <a style="cursor:pointer">
                                <mat-icon (click)="deleteItem(download.id)" style="font-size:17px">delete</mat-icon>
                              </a> -->
                </li>
              </ul>
              <ul *ngFor="let download of datacache ;let item of listValue">
                <li>
                  <a (click)="downLoad(item)" style="cursor: pointer;">
                    <!-- <mat-icon [inline]="true" aria-hidden="false" aria-label="Download">get_app</mat-icon> -->
                    {{ item.documentName | slice:0:25 }}{{ item.documentName.length > 25 ? '....' : '' }}
                  </a>
                  <a style="cursor:pointer">
                    <mat-icon (click)="deleteItem(item.id)" style="font-size:17px">delete</mat-icon>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="delStatusId == '3'">
            <label>{{language.comment}} :<mark style="margin-left: 5px;">
              <span matTooltipClass="custom-tooltip" matTooltipPosition="before" matTooltip="{{comment}}">

                {{ comment | slice:0:40 }}{{ comment.length > 40 ?
                
                '....' : '' }}
                
                </span>
            </mark></label>
          </div>
          <div class="deliveryNoteDetails" fxFlex="100" fxLayout="row">
            <h5>{{language.deliveryNoteDetails}}</h5>
          </div>


          <div class="overflow">
            <mat-table [dataSource]="deliveryNoteDetails" multiTemplateDataRows
              class="mat-elevation-z8 main-table deliveryNoteDetailsTable">
              <ng-container matColumnDef="poNumber">
                <mat-header-cell *matHeaderCellDef class="editPoNo-w">{{language.PONumber}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="{{language.PONumber}}" class="editPoNo-w">
                  <a (click)="onPoInfo(element.poNumber)" class="poInfoCls"
                    style="text-decoration: underline; color: #0088cc">
                    {{ element.poNumber }}
                  </a>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="poItem">
                <mat-header-cell *matHeaderCellDef class="editPoItem-w">{{language.POItem}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="PO Item" class="editPoItem-w">
                  {{ element.poItem }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="materialG">
                <mat-header-cell *matHeaderCellDef class="editMaterialGroup-w">Material Group</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Material Group" class="editMaterialGroup-w">
                  {{ element.materialG }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="materialNo">
                <mat-header-cell *matHeaderCellDef class="editMaterialNo-w">{{language.materialNo}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Material No" class="editMaterialNo-w">
                  {{ element.material != null ? element.material : '-'}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="plantName">
                <mat-header-cell *matHeaderCellDef class="plantName-w">{{language.plantName}}<</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Plant Name" class="plantName-w">
                  {{ element.plantName }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="unit">
                <mat-header-cell *matHeaderCellDef class="unit-w">{{language.unit}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Unit" class="unit-w">
                  {{ element.outStandingUnit != undefined ? element.outStandingUnit : '-' }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="deliveryDate">
                <mat-header-cell *matHeaderCellDef class="delDate-w">{{language.deliveryDate}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Delivery Date" class="delDate-w">
                  {{ element.poDeliveryDate | date:"dd/MM/yyyy" }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="invoicedAmount">
                <mat-header-cell *matHeaderCellDef class="invAmt-w">{{language.invoicedAmount}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Invoiced Amount" class="invAmt-w">
                  {{element.grQuantity * element.netPrice | number : '1.2-2' }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="storageLocation">
                <mat-header-cell *matHeaderCellDef class="editStorageLocation-w">
                  <span *ngIf="currentUser.roleID == '3' || currentUser.roleID == '7'">{{language.storageLocation}}</span>
                </mat-header-cell>
               
                <span *ngIf="currentUser.roleID == '3' || currentUser.roleID == '7'">
                  
                  <mat-cell *matCellDef="let element" data-label="Storage Location" class="editStorageLocation-w">
                    <span>
                      <select appearance="outline" [(ngModel)]="element.storageLocation"
                        style="border: 0.5px solid black;" (change)="onStorageChange($event,element)">
                        <option name="storageLocation" ngDefaultControl *ngFor="let item of storageMaster"
                          [value]="item.storageLocationCode">{{ item.name }}</option>
                      </select>
                      
                    </span>
                  </mat-cell>
                </span>
                
              </ng-container>
              
              <ng-container matColumnDef="shortText">
                <mat-header-cell *matHeaderCellDef class="editShortText-w">{{language.shortText}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Short Text" class="editShortText-w">
                  {{ element.shortText }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="poQty">
                <mat-header-cell *matHeaderCellDef class="editPoQty-w">{{language.POQTY}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Short Text" class="editPoQty-w">
                  {{ element.scheduledQuantity | number : '1.3-3' }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="receivedQty">
                <mat-header-cell *matHeaderCellDef class="editReceivedQty-w">{{language.receivedQTY}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Received Qty" class="editReceivedQty-w">
                  {{ element.grQuantity | number : '1.3-3' }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="pendingQty">
                <mat-header-cell *matHeaderCellDef class="editPendingQty-w">{{language.pendingQTY}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Pending Qty" class="editPendingQty-w">
                  {{element.totalPenQty | number : '1.3-3'}}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="unitPrice">
                <mat-header-cell *matHeaderCellDef class="editUnitPrice-w">{{language.unitPrice}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Unit Price" class="editUnitPrice-w">
                  {{ element.netPrice | number : '1.2-2' }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="poAmount">
                <mat-header-cell *matHeaderCellDef class="editPoAmount-w">{{language.POAmount}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="PO Amount" class="editPoAmount-w">
                  {{ element.grossValue | number : '1.2-2' }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="delQty">
                <mat-header-cell *matHeaderCellDef class="editDelQty-w">{{language.deliveringQty}}</mat-header-cell>
                <mat-cell *matCellDef="let element;let i=index" class="editDelQty-w" data-label="Del Qty">
                  <input type="text"  (keyup)="onDelQtyChange($event,element)"
                  [disabled]="isCatogary"
                  (keypress)="notAllow($event)"
                  (keypress)="keyPressNumbers($event)"
                  (blur)="blurFunction1($event,element)" maxlength="13"  (copy)="$event.preventDefault()" (paste)="$event.preventDefault()"
                    [value]="element.warehouseReceivedQuantity == undefined ? 0 : element.warehouseReceivedQuantity"
                    #input (keypress)="notAllowHyphen($event)" (keypress)="allowTwodigit($event)" appBlockCopyPaste/>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="totalAmt">
                <mat-header-cell *matHeaderCellDef class="editDNtotalAmt-w">{{language.totalAmount}}</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Total Amount" class="editDNtotalAmt-w">
                  {{ element.totalAmt | number:'1.2-2' }} 
                  <!-- (element.warehouseReceivedQuantity == undefined ? 0 : element.warehouseReceivedQuantity) *
                  element.netPrice | number:'1.2-3' -->
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef class="actions-w actions-align">
                  <span *ngIf="subCateg || delStatus == 'Rejected'">Actions</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Actions" class="actions-w">
                  <!-- <span *ngIf="delStatus == 'Rejected'">
                    <mat-icon class="delete" (click)="removeItem(element.purchaseOrderDetailsId)" aria-hidden="false"
                      aria-label="delete">
                      delete
                    </mat-icon>
                  </span> -->
                  <span *ngIf="subCateg">
                    <mat-icon (click)="
                    expandedElement = expandedElement === element ? null : element;toggle()" 
                     aria-hidden="false"
                     *ngIf="currentUser.roleID != '2'"
                      class="expand">
                      expand
                    </mat-icon>
                  </span>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">

                  <div class="example-element-detail" *ngIf="currentUser.roleID != '2'"
                    [@detailExpand]="element == expandedElement? 'expanded': 'collapsed'">
                    <div class="overflow1" >
                      <table class="basic">
                        <thead>
                          <tr>
                            <th class="tableClsx glAccount-h">
                              {{language.subItemNo}}
                            </th>
                            <th class="tableClsx qty-h">Quantity</th>
                            <th class="tableClsx costCenter-h">
                              {{language.materialNo}}
                            </th>
                            <th class="tableClsx costCenter-h" *ngIf="currentUser.roleID == '3' || currentUser.roleID == '7'">
                              {{language.bom}}
                            </th>
                            <th class="tableClsx costCenter-h">
                              {{language.shortText}}
                            </th>
                            
                          </tr>
                        </thead>
                        <tbody> 
                          <!-- <tr *ngFor="let element of dataSubSource; let i=index"> -->
                            <tr *ngFor="let newelement of element.subContractSubItems; let i=index">
                              <td data-name=" Sub Item No">
                                {{ newelement.subItemNo }}
                              </td>
                              <td data-name="Quantity" class="quantity" >
                                <input type="text" [value]="0" maxlength="13"
                                (keyup)="onSubQty($event,element)" 
                                (keyup)="onInputchanges($event)"
                                (keypress)="keyPressNumbers($event)"
                                (blur)="blurFunction($event,element)"
                                (keypress)="allowTwodigit($event)" 
                                (keypress)="notAllow($event)" 
                                (keypress)="notAllowHyphen($event)" 
                                  [(ngModel)]="element.subContractSubItems[0].subItemReceivedQty" appBlockCopyPaste/>
                                  <!-- (keypress)="allowTwodigit($event)"
                                (keypress)="notAllow($event)"
                                (keypress)="keyPressNumbers($event)" -->
                              </td>
                              <td data-name="Material No">
                                {{newelement.subItemMaterial }}
                              </td>
                              <td data-name="BOM" *ngIf="currentUser.roleID == '3' || currentUser.roleID == '7'">
                                {{ element.billOfMaterial }}
                              </td>
                              <td data-name="Short Text">
                                {{ newelement.subItemShortText }}
                              </td>
                              
                            </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </td>
              </ng-container>
              <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
              <mat-row *matRowDef="let element; columns: columnsToDisplay" class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"></mat-row>
              <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row expandRow"></mat-row>
            </mat-table>
          </div>

          <!-- <div class="row addr">
                        <div fxFlex.gt-lg="90" fxFlex.gt-md="90" fxFlex.gt-xs="100" fxFlex="100"></div>
                        <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
                          <button mat-raised-button class="submitButton" (click)="onSubmit()">
                            Submit
                          </button>
                        </div>
                        <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
                          <button mat-raised-button class="rejectButton">Cancel</button>
                        </div>
                      </div> -->
          <!-- <div class="mt-2 addr " *ngIf="deliveryStatus != 'Rejected'">
            <div fxFlex="100">
              <div class="buttonGroup">
                <button mat-raised-button class="submitButton" type="button" style="margin-right: 11px;"
                  (click)="openDialog()">Submit</button>
              </div>
            </div>
          </div> -->
          <div class="mt-2 addr " *ngIf="(currentUser.roleID == '3' || currentUser.roleID == '7') && statusId != '4'">
            <!-- <div fxFlex.gt-lg="80" fxFlex.gt-md="80" fxFlex.gt-xs="100" fxFlex="100">
                      </div> -->
            <div fxFlex="100">
              <div class="buttonGroup">
                <button mat-raised-button class="leftBtn submitButton" type="button"
                  (click)="openDialog()">{{language.approve}}</button>
                <button mat-raised-button class="rejectButton" type="button" (click)="onReject()">{{language.reject}}</button>
              </div>
            </div>
          </div>
          <div class="row mt-2 addr " *ngIf="(currentUser.roleID == '3' || currentUser.roleID == '7') && statusId == '4'">
            <!-- <div fxFlex.gt-lg="70" fxFlex.gt-md="70" fxFlex.gt-xs="100" fxFlex="100">
                      </div> -->
            <div fxFlex="100">
              <div class="buttonGroup">
                <button mat-raised-button class="leftBtn submitButton" type="button" style="margin-right: 11px;"
                  (click)="onSubmit()">{{language.releaseStock}}</button>
                <!-- <button mat-raised-button class="rejectButton" type="button" (click)="onRejectStock()">Reject
                  Stock</button> -->
              </div>
            </div>
          </div>
          <div class="row mt-2 addr " *ngIf="currentUser.roleID == '2' && !categoryBlocked">
            <!-- <div fxFlex.gt-lg="70" fxFlex.gt-md="70" fxFlex.gt-xs="100" fxFlex="100">
                      </div> -->
            <div fxFlex="100">
              <div class="buttonGroup">
                <button mat-raised-button class="leftBtn submitButton" type="button" style="margin-right: 11px;"
                  (click)="onSubmit()">{{language.update}}</button>
                <button mat-raised-button class="rejectButton" type="button" (click)="onCancel()">{{language.cancel}}
                </button>
              </div>
            </div>
          </div>
          <!-- </form> -->
          <!-- </form> -->
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<div class="overlay" *ngIf="mySpinner">
  <div class="center">
    <mat-spinner></mat-spinner>
  </div>
</div>