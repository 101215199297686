import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ForgotModalComponent } from '../forgot-modal/forgot-modal.component';
import { AuthService } from '../interceptors/auth.service';
import { CommonServicesService } from '../services/common-services.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})

export class SigninComponent implements OnInit {
  mySpinner: boolean = false;
  hide = true;

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    
      this.endChat();
  }

  get name() {
    return this.form.get('username');
  }

  get password() {
    return this.form.get('password');
  }
  form: FormGroup;
  constructor(private router: Router,
    private cService: CommonServicesService,
    private dialog: MatDialog,
    private authService: AuthService,
    public _snackBar: MatSnackBar,) {

    this.form = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', Validators.required),
    });
  }

  verifyRole = [1, 2, 3, 4, 5, 6, 7];
  currentUser: any;
  ngOnInit() {
    // localStorage.removeItem("currentUser");
    localStorage.clear();
    // this.getRoles();
  }
  showOverlay: boolean = false;
  error: string = "";

  onSubmit() {
    if (this.form.value.username == "" || this.form.value.username == undefined) {
      Swal.fire("Username is Required", "", "error");
      return
    } else if (this.form.value.password == "" || this.form.value.password == undefined) {
      Swal.fire("Password is Required", "", "error");
      return
    }
    this.mySpinner = true;
   
    // this.cService.login(this.form.value.username, this.form.value.password).pipe(first()).subscribe(res =>{
    localStorage.removeItem("currentUser");
    localStorage.clear();
    this.authService.login(this.form.get("username").value, this.form.get("password").value)
      .pipe(first()).subscribe(res => {
        
        // if(res.responseStatus.toUpperCase() == "SUCCESS"){

        this.mySpinner = false;

        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        

        this.currentUser = currentUser.response.userDetails;
        
        
        if (res.userDetails.consentFlag == false && res.userDetails.firstTimeLogin == 0 && res.userDetails.roleID == 2) {
          //  localStorage.setItem("firstTimeLogin", "0");
          this.router.navigate(["/privacy-policy"]);
        }
        else if (res.userDetails.firstTimeLogin == 0) {
          // localStorage.setItem("firstTimeLogin", "0");
          this.router.navigate(["/change-pwd"]);
        }
        else {
          this.router.navigate(["/dashboard"]);
        }

        //   this.mySpinner = false;
        //   Swal.fire(res.responseMessage, "", "error");
        // }

      }, error => {
        this.mySpinner = false;

      })
  }
  isLoggedIn = new BehaviorSubject(false);
  // logoutUser() {


    isRoleExists(roleIds:any[]){
      var isExist:Boolean = false;
      for( let role of roleIds ){
        for(let i  of this.verifyRole){
          if(role == i ){
            isExist = true;
            // return true;
          }else{
            isExist = false;
          }
        }

      }
      return isExist;
  }



  // }
  goTo() {
    this.router.navigate(["/forgot-password"])
  }
  openDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(ForgotModalComponent, {
      width: '500px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      
      //this.forgotPwd(result.data.userName);
      this.forgotPwd(result.data.userName);

      // Swal.fire("link has send to registered email");

    })
  }

  forgotPwd(userName) {
    this.authService.forgotPwd(userName).pipe(first()).subscribe(data => {
      if (data["responseStatus"] === 'SUCCESS') {
        this.mySpinner = false;
        Swal.fire("Password reset link has sent to your registered email address","","success")
        this.router.navigate(["/signin"]);
        // this.showOverlay = false;
      } else {
        this.mySpinner = false;
        // this.showOverlay = false;
        Swal.fire(data.responseMessage, "", "error");
      }

    })
  }

  getRoles(){
    this.authService.getRoles().pipe(first()).subscribe(res => {
      if(res.responseStatus.toUpperCase == 'SUCCESS'){
        this.mySpinner = false;

        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        

        this.currentUser = currentUser.response.userDetails;
        
        
        if (res.userDetails.consentFlag == false && res.userDetails.firstTimeLogin == 0 && res.userDetails.roleID == 2) {
          //  localStorage.setItem("firstTimeLogin", "0");
          this.router.navigate(["/privacy-policy"]);
        }
        else if (res.userDetails.firstTimeLogin == 0) {
          // localStorage.setItem("firstTimeLogin", "0");
          this.router.navigate(["/change-pwd"]);
        }
        else {
          this.router.navigate(["dashboard"]);
        }
        // }else{
        //   this.mySpinner = false;
        //   Swal.fire(res.responseMessage, "", "error");
        // }

      }
      
    })
    // var w = window.open("https://www.google.com", "MsgWindow", "width=500,height=500"); //has a function on `window` called "test"
    //w.test();
  }

  loginWithAdfs(){
    this.authService.loginWithADFS();
    // .pipe(first()).subscribe(data => {
    //   
    // })
    // var w = window.open("https://www.google.com", "MsgWindow", "width=500,height=500"); //has a function on `window` called "test"
    //w.test();
  }

  endChat() {
    
}

}