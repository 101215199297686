<h1 mat-dialog-title>{{language.Reject}} {{data}} </h1>
<div mat-dialog-content>
        <div *ngIf="currentUser.roleID != 4 && currentUser.roleID != 5 && currentUser.roleID != 8">
           <h4>{{language.head}}</h4> 
        </div>
        
        <form [formGroup]="firstFormGroup">
         <div *ngIf="currentUser.roleID != 4 && currentUser.roleID != 5 && currentUser.roleID != 8">
        <mat-form-field class="comments mt-2">
              <!-- <input  type="file" accept="image/*" multiple (change)="showPreview($event)" /> -->
              <!-- <input type="file" class="custom-file-input" id="customFile" name="myfile" formControlName="fileupload" (change)="onFileSelect($event)"
                                      #UploadFileInput>
                                  <label class="custom-file-label" for="customFile">{{fileInputLabel || 'Choose File'}}</label> -->
          <mat-label>{{language.Comments}}</mat-label>
          <textarea matInput type="text" placeholder="{{language.commentex}}" disabled="isSubmitBtn" formControlName ="comment" (change)="onCommentChange()" required></textarea>
        </mat-form-field>
        </div>
        <div *ngIf="currentUser.roleID != 4 && currentUser.roleID != 5 && currentUser.roleID != 8">
            <mat-form-field class="comments">
                <mat-label>{{language.Emails}}</mat-label>
                <textarea matInput type="text"  placeholder="{{language.emailex}}" disabled="isSubmitBtn" formControlName ="emails" (change)="onEmailChange()" required></textarea>
              </mat-form-field>
        </div>
        <div *ngIf="currentUser.roleID == 4 || currentUser.roleID == 5 || currentUser.roleID == 8">
            <mat-form-field class="comments">
                <mat-label>{{language.Emails}}</mat-label>
                <textarea matInput type="text"  placeholder="{{language.emailex}}" disabled="isSubmitBtn" formControlName ="emails" (change)="onEmailChange()"></textarea>
              </mat-form-field>
        </div>  
    </form>
</div>
<div mat-dialog-actions>
    <button (click)="onReject()" mat-flat-button color="primary" class="submitButton button-actions green mx-2">
        <mat-icon aria-hidden="false" aria-label="check">check</mat-icon>
        <span class="mx-1">{{language.Reject}}</span>
    </button>
    <button mat-flat-button color="warn" (click)="onCancel()" class="rejectButton button-actions  mx-2">
        <mat-icon aria-hidden="false" aria-label="clear">clear</mat-icon>
        <span class="mx-1">{{language.Cancel}}</span>
    </button>
</div>
  <!-- <button mat-button (click)="()" class="button-actions red mx-2" cdkFocusInitial>Cancel</button> -->
  <div class="overlay"  *ngIf="mySpinner">
    <div class="center">
        <mat-spinner></mat-spinner> 
    </div>
    </div>
