<div class="margin-bottom" fxLayout="row" >
    <div class="pull-left" fxFlex="50">
        <strong>{{action}} Response Message</strong>
    </div>
    <div class="pull-right" fxFlex="50">
        <mat-icon class="close2 pull-right" (click)="closeDialog()">close</mat-icon>
    </div>
</div>

<div mat-dialog-content>
    <!-- <div class="row" *ngIf="action != 'delete'; else elseTemplate"> -->
    <form [formGroup]="roleForm">        
                <mat-form-field *ngIf="action == 'Add'" class="width-80" appearance="outline">
                    <mat-label>{{language.responseKey}}</mat-label>
                    <input matInput placeholder="{{language.responseKey}}"  formControlName="respCode"
                        [(ngModel)]="local_data.respCode">
                </mat-form-field>  
                <mat-form-field class="width-80" style="display: none;" appearance="outline">
                    <mat-label>{{language.responseKey}}</mat-label>
                    <input matInput placeholder="{{language.responseKey}}"  formControlName="id"
                        [(ngModel)]="local_data.id">
                </mat-form-field>                
                <mat-form-field *ngIf="action == 'Update'" class="width-80" appearance="outline">
                    <mat-label>{{language.responseKey}}</mat-label>
                    <input matInput placeholder="{{language.responseKey}}" readonly formControlName="respCode"
                        [(ngModel)]="local_data.respCode">
                </mat-form-field>
                <mat-form-field class="width-80" appearance="outline">
                    <mat-label>{{language.responseValue}}</mat-label>
                    <textarea matInput type="text" required placeholder="{{language.responseValue}}" 
                    formControlName="message" [(ngModel)]="local_data.message"></textarea>
                </mat-form-field>
        <div class="modal-buttons"  fxLayout.xs = "row">
            <button mat-button mat-flat-button class="submitButton" (click)="onNoClick()"
                style="margin-right:10px">{{action}}</button>
        </div>
    </form>
</div>