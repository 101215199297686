<div class="overlay">
        <div class="center">
            <h3>Please wait while redirecting to dashboard</h3>
            <mat-spinner></mat-spinner>
        </div>
        <!-- <div class="background">
            <mat-card>
                <mat-card-title class="mb-3 mt-3">
                    <img src="assets/images/insee-logo.PNG" alt="homepage" class="dark-logo">
                    <br>
                    Sign In
                </mat-card-title>
                <mat-card-content>
                    <form [formGroup]="loginForm">
                        <p>
                            <mat-form-field >
                                <input type="text" matInput placeholder="Email" formControlName="username">
                            </mat-form-field>
                        </p>

                        <p>
                            <mat-form-field >
                                <input type="password" matInput placeholder="Password" formControlName="password">
                            </mat-form-field>
                        </p>

                        <p *ngIf="error" class="error">
                            {{ error }}
                        </p>

                        <div class="button">
                            <button mat-raised-button class="submitButton">Sig In</button>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </div> -->
</div>