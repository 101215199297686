// my-modal.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import swal from 'sweetalert2';

// import { RoleManagementComponent } from '../role-management.component';
// import { DeliveryNotesInfoComponent } from '../delivery-notes-info/delivery-notes-info.component';

@Component({
  selector: 'app-common-reject',
  templateUrl: './common-reject.component.html',
  styleUrls: ['./common-reject.component.css']
})

export class CommonRejectComponent {
  mySpinner:boolean = false;
  isSubmitBtn:any = false;
  currentUser:any=[];
  language:any;
  
  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<CommonRejectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
    // firstFormGroup = this.fb.group({
    //   comment:['', [Validators.required]],
    //   emails :['', [Validators.required]]
    // });
    get f() { return this.firstFormGroup.controls; }
    firstFormGroup = this.fb.group({
        comment:['', [Validators.required]],
        emails :['', [Validators.required]]
      });
  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
    if(lang == 'en'){
        this.language = {
        "head":	"Please add remarks and Reject ?",
        "Comments":"Comments",
        "Emails" :"Emails",
        "Reject": "Reject",
        "Cancel" :"Cancel",
        "swalComment":"Please enter Comment",
        "swalEmail":"Please enter Email",
        "commentex":"eg. Price match",
        "emailex":"eg. abc@gmail.com"
       
    }}
    else if(lang == 'th'){
      this.language = {
        "head":	"กรุณาใส่หมายเหตุและปฏิเสธ ?",
        "Comments":"ความคิดเห็น",
        "Emails" :"อีเมล",
        "Reject": "ปฏิเสธ",
        "Cancel" :"ยกเลิก",
        "swalComment":"กรุณาใส่ความคิดเห็น",
        "swalEmail":"กรุณากรอกอีเมล์",
        "commentex":"เช่น. จับคู่ราคา",
        "emailex":"เช่น. abc@gmail.com"
  }
}

    this.firstFormGroup = this.fb.group({
      comment:['', [Validators.required]],
      emails :['', [Validators.required]]
    });
  }

  // comment = new FormControl(null, Validators.required);
  // emails = new FormControl("", [Validators.required]);
  onCommentChange() {
    this.isSubmitBtn = true ;
} 
arr:any=[];
onEmailChange(){
  let email = this.firstFormGroup.value.emails.split(",");
  this.arr.push(email);
    this.isSubmitBtn = true ;
}
  onReject() {
    let emailregex = /^([\w\.\+]{1,})([^\W])(@)([\w]{2,})(\.[{a-z}]{2,})+$/;
    if (this.firstFormGroup.value.comment == "" || this.firstFormGroup.value.comment == undefined) {
      if(this.currentUser.roleID != 4 && this.currentUser.roleID != 5 && this.currentUser.roleID != 8){
        swal.fire(`${this.language.swalComment}`,"", "info");
        return;
      }
    } 

    if(this.currentUser.roleID != 4 && this.currentUser.roleID != 5 && this.currentUser.roleID != 8){
      if (this.firstFormGroup.value.emails == "" || this.firstFormGroup.value.emails == undefined) {
        swal.fire(`${this.language.swalEmail}`,"", "warning");
        return;
      } 
    }
        this.dialogRef.close({ data: "Rejected", comment: this.firstFormGroup.value.comment, newemail: this.arr });
  }
  onCancel() {
    this.dialogRef.close({ data: "Cancelled" });
  }

}