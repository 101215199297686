import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RoleModel } from './role-model/role-model';
import swal from "sweetalert2";
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { first } from 'rxjs/operators';
import { AdminServiceService } from '../services/admin-service.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.css']
})
export class RoleManagementComponent implements OnInit {
  mySpinner = false;
  constructor(private dialog: MatDialog, private router: Router, private cService: AdminServiceService) { }
  dataSource:MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  columnsToDisplay=["roleName","roleDesc","actions"];
  headerToDisplay:any;
  currentUser:any;
  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.getRolls(null,this.searchString);
    let currentUsers = this.currentUser.response;
		let lang = currentUsers.langPreference;
		
		if(lang == 'en'){
      this.headerToDisplay={
        rolename:"Role",
        roledesc:"Description",
        actions:"Actions",
        roleManagement:"Role Management",
        search:"Search",
        rolemenus:"Role menus"
      }
		  }else if(lang == 'th'){
      this.headerToDisplay={
        rolename:"บทบาท",
        roledesc:"คำอธิบาย",
        actions:"การกระทำ",
        roleManagement:"การจัดการบทบาท",
        search:"ค้นหา",
        rolemenus:"เมนูบทบาท"
      }
		  }

  }
  pageIndex = 0;
  pageSize = 10;
  listLength: any;
  searchString:any='';
  getRolls(event: PageEvent, searchString:any) {
     this.mySpinner = true;
    if(event ==  null){
      this.pageIndex = 0;
      this.pageSize = 10;
     }else{
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
     }

    this.cService.getRollList(this.pageIndex,this.pageSize, this.searchString).pipe(first()).subscribe(res => {
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        // this.showOverlay=false;
        this.mySpinner = false;
        this.dataSource = new MatTableDataSource(res.response) ;
        this.listLength = res.pagination.count;
        this.dataSource.sort = this.sort;
        
      } else {
        this.mySpinner = false;
        this.dataSource = new MatTableDataSource([]);
        this.listLength = 0;
        
        swal.fire(res.responseMessage,"", "error");
      }
    }, error => {
      // this.showOverlay=false;
    })
  }
  openDialog(_add: any, obj: any){
    obj.action = _add;
    let userid = obj.userID;
    const dialogRef = this.dialog.open(RoleModel, {
      width: '500px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      
      if(result != undefined){
        // swal.fire("User creation successful!");
      }
      
    })
  }
  
  // applyFilter(event:any){
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  //   }

  applyFilter1(){
    const filterValue = this.searchString;
    if(this.searchString == "" ){
      this.getRolls(null, this.searchString);
    }
  }
  applyFilter() {
    const filterValue = this.searchString;
    if(filterValue.length >= 2 || this.searchString == "" ){
      this.getRolls(null, this.searchString);
    }

}
}
