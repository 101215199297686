import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ReportsRoutingModule } from './reports-routing.module';
import { VendorReportComponent } from './vendor-report/vendor-report.component';
import { DemoMaterialModule } from '../demo-material-module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ApReportComponent } from './ap-report/ap-report.component';
import { CheckerReportComponent } from './checker-report/checker-report.component';
import { DnReportComponent } from './dn-report/dn-report.component';
import { PoReportComponent } from './po-report/po-report.component';
// import { TableUtil } from 'src/app/reports/TableUtil';

@NgModule({
  declarations: [
    VendorReportComponent,
    ApReportComponent,
    CheckerReportComponent,
    DnReportComponent,
    PoReportComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReportsRoutingModule,
    DemoMaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [
    DatePipe
  ]
})
export class ReportsModule { }
