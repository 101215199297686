<div class="margin-bottom" fxLayout="row">
    <div class="pull-left" fxFlex="50">
        <strong>{{action}} {{language.user}}</strong>
    </div>
    <div class="pull-right" fxFlex="50">
        <mat-icon class="close2 pull-right" (click)="closeDialog()">close</mat-icon>
    </div>


</div>

<div mat-dialog-content>
    <!-- <div class="row" *ngIf="action != 'delete'; else elseTemplate"> -->
    <form [formGroup]="vendorForm">
        <div fxLayout="row" fxLayout.xs="column">
            <div class="padding-right" fxFlex="50" fxFlex.xs="100">
                <mat-form-field class="width-80 validations" appearance="outline">
                    <mat-label>{{language.FirstName}}</mat-label>
                    <input matInput placeholder="{{language.FirstName}}" required formControlName="firstName"
                        (keydown)="Space($event)" [(ngModel)]="local_data.firstName">

                </mat-form-field>
            </div>
            <div class="padding-right" fxFlex="50" fxFlex.xs="100" style="display: none;">
                <mat-form-field class="width-80 validations" appearance="outline">
                    <mat-label>language.FirstName</mat-label>
                    <input matInput placeholder="{{action}}" required formControlName="id" [(ngModel)]="local_data.id">

                </mat-form-field>
            </div>
            <!-- <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100"></div> -->
            <div class="padding-left" fxFlex="50" fxFlex.xs="100">
                <mat-form-field class="width-80 validations" appearance="outline">
                    <mat-label>{{language.LastName}}</mat-label>
                    <input matInput placeholder="{{language.LastName}}" required formControlName="lastName"
                        (keydown)="Space($event)" [(ngModel)]="local_data.lastName">

                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div class="padding-right" fxFlex="50" fxFlex.xs="100">
                <mat-form-field class="width-80 validations" appearance="outline">
                    <mat-label>{{language.Email}}</mat-label>
                    <input matInput placeholder="{{language.Email}}" required formControlName="email"
                        (keydown)="Space($event)" [(ngModel)]="local_data.email">

                </mat-form-field>
            </div>
            <!-- <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100"></div> -->
            <div class="padding-left" fxFlex="50" fxFlex.xs="100">
                <mat-form-field class="width-80 validations" appearance="outline">
                    <mat-label>{{language.contactNo}}</mat-label>
                    <input matInput required placeholder="{{language.contactNo}}" formControlName="contactNo"
                        [(ngModel)]="local_data.contactNo" (keypress)="numberOnly($event)">

                </mat-form-field>
            </div>
            <div class="padding-left" fxFlex="50" fxFlex.xs="100" style="display: none;">
                <mat-form-field class="width-80 validations" appearance="outline">
                    <mat-label>{{language.contact}}</mat-label>
                    <input matInput required placeholder="{{language.phoneNo}}" formControlName="vendorId"
                        [(ngModel)]="local_data.vendorId">
                </mat-form-field>
            </div>
            <div class="padding-left" fxFlex="50" fxFlex.xs="100" style="display: none;">
                <mat-form-field class="width-80 validations" appearance="outline">
                    <mat-label>{{language.contact}}</mat-label>
                    <input matInput required placeholder="{{language.phoneNo}}" formControlName="vendorName"
                        [(ngModel)]="local_data.vendorName">
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div class="padding-right" fxFlex="50" fxFlex.xs="100" *ngIf="action == 'Add'">
                <!-- <mat-form-field class="width-80 validations" appearance="outline">
                    <mat-label>Vendor code</mat-label> -->
                <!-- <input matInput required placeholder="City" minlength="10" maxlength="11"
                        formControlName="city" [(ngModel)]="local_data.city" (keypress)="numberOnly($event)"> -->
                <!-- <select matNativeControl required placeholder="{{action}}" formControlName="usertype"
                        [(ngModel)]="local_data.usertype">
                        <option *ngFor="let item of vendorList" [value]="{{item.vendorCode}}">{{item.vendorCode}}</option> -->
                <!-- <mat-select appearance="outline" formControlName="usertype" [(ngModel)]="local_data.usertype">
                        <mat-option *ngFor="let food of vendorList" [value]="food.vendorCode+'-'+food.id">
                            {{food.nameOne}}

                        </mat-option>
                    </mat-select> -->
                <!-- <option disabled>Select vendorcode</option>
                        <option value="1">VND1001</option>
                        <option value="3">VND1002</option>                      
                        <option value="4">VND1003</option> -->
                <!-- </select> -->
                <!-- </mat-form-field> -->
                <mat-label *ngIf="show" class="poclass" style="font-weight: 500;">{{language.vendor}}*</mat-label>
                <ng-multiselect-dropdown placeholder="{{language.vendor}}*" class="dropDownClass"
                    formControlName="usertype" [settings]="dropdownSettings" [data]="dropdownList"
                    (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)"
                    [(ngModel)]="usertype.vendorCode">
                </ng-multiselect-dropdown>
            </div>
            <div class="padding-right" fxFlex="50" fxFlex.xs="100" *ngIf="action == 'Update'">
                <!-- <mat-form-field class="width-80 validations" appearance="outline">
                <mat-label>Vendor code</mat-label> -->
                <!-- <input matInput required placeholder="City" minlength="10" maxlength="11"
                        formControlName="city" [(ngModel)]="local_data.city" (keypress)="numberOnly($event)"> -->
                <!-- <select matNativeControl required placeholder="{{action}}" formControlName="usertype"
                        [(ngModel)]="local_data.usertype">
                        <option *ngFor="let item of vendorList" [value]="{{item.vendorCode}}">{{item.vendorCode}}</option> -->
                <!-- <mat-select appearance="outline" formControlName="usertype"   [(ngModel)]="local_data.usertype">
                <mat-option *ngFor="let food of vendorList" [value]="food.vendorCode" >{{food.nameOne}}

                </mat-option>
                </mat-select> -->
                <!-- <option disabled>Select vendorcode</option>
                        <option value="1">VND1001</option>
                        <option value="3">VND1002</option>                      
                        <option value="4">VND1003</option> -->
                <!-- </select> -->

                <!-- </mat-form-field> -->
                <mat-label *ngIf="show" class="poclass" style="font-weight: 500;">{{language.vendor}}*</mat-label>
                <ng-multiselect-dropdown placeholder="{{language.vendor}}*" class="dropDownClass"
                    formControlName="usertype" [settings]="dropdownSettings" [data]="dropdownList"
                    (onSelect)="onItemSelect($event)" [(ngModel)]="usertype" [disabled]="isboolean">
                </ng-multiselect-dropdown>

            </div>
        </div>
        <div class="modal-buttons" fxLayout.xs="row">
            <button mat-button mat-flat-button class="submitButton" (click)="onNoClick()"
                style="margin-right:10px">{{action}}</button>
            <!-- <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button> -->
        </div>
    </form>
    <!-- </div> -->
    <!-- <ng-template #elseTemplate>
        Sure to delete
        <b>{{local_data.username}}</b>?
    </ng-template> -->
    <div class="overlay" *ngIf="mySpinner">
        <div class="center">
            <mat-spinner strokeWidth="5" [diameter]="60"></mat-spinner>
        </div>
    </div>
</div>
<!-- 
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Submit</button>
    
</div> -->