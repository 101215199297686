<mat-card>
    <!-- <div style="display: flex; justify-content: end;" >  
        <mat-icon class="print" (click)="printPage('printPage')">print</mat-icon>
 </div>    -->
    <mat-card-title>
        <div class="margin-bottotm" fxLayout="row" fxFlex="100">
            <div class="pull-left title" fxFlex="80">
            </div>
            <div class="pull-right" fxFlex="20">
                <mat-icon (click)="goTo()" class="close1">
                    close
                </mat-icon>
            </div>
        </div>
    </mat-card-title>

    <div id="printPage">
        <mat-card-content>
            <div fxFlex="100" fxLayout="column" class="padding-left padding-right page-body"
                *ngIf="currentUser.roleID != '3' && currentUser.roleID != '7'">
                <div class="f-middle pageHeading" fxLayout="row">
                  
                </div>
                <h5 class="pageSubHeading">
                   {{language.Head}}
                </h5>
                <div fxFlex="100" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" class="addr mt-3 printRow">
                    <div fxFlex="50" fxFlex.xs="100" fxLayout="column">
                        <ul class="listStyle">
                            <li>
                                <strong>{{language.VendorCode}}&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                                <a (click)="onVenInfo(data.vendorId)" class="poInfoCls">{{data.sapVendorCode}}</a>
                            </li>
                            <li>
                                <strong>{{language.VendorName}}&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                                <!-- {{data.deliveryToPersonName}} -->
                                <span *ngIf="vendorDetail.busNameOne != undefined && vendorDetail.busNameOne != ''">{{vendorDetail.busNameOne}}&nbsp;&nbsp;{{vendorDetail.busNameTwo}}<br></span>
                            </li>
                            <li>
                                <strong>{{language.Address}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                                <span fxFlex="auto">
                                    <!-- <span *ngIf="vendorDetail.busNameOne != undefined">{{vendorDetail.busNameOne}}&nbsp;&nbsp;{{vendorDetail.busNameTwo}}<br></span> -->
                                    <span *ngIf="vendorDetail.busStreet != undefined && vendorDetail.busStreet != ''">{{vendorDetail.busStreet}}<br></span>
                                    <span *ngIf="vendorDetail.busStreetFour != undefined && vendorDetail.busStreetFour != ''">{{vendorDetail.busStreetFour}}<br></span>
                                    <span *ngIf="vendorDetail.busStreetFive != undefined && vendorDetail.busStreetFive != ''">{{vendorDetail.busStreetFive}}<br></span>
                                    <span >{{vendorDetails.district}}<br></span>
                                    <span *ngIf="vendorDetail.busCity != undefined && vendorDetail.busCity != ''">{{vendorDetail.busCity +" - "+vendorDetail.busPostalCode}}    <br> </span>
                                      <abbr title="Phone">{{vendorDetail.firstTelephoneNum == null ? '' :'P:'+vendorDetail.firstTelephoneNum}}</abbr>
                                  </span>
                            </li>
                        </ul>
                    </div>
                    <div fxFlex="50" fxFlex.xs="100" class="page-group">
                        <ul class="listStyle">
                            <li>
                                <strong>{{language.Date}}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;: &nbsp;</strong>{{data.deliveryNoteDate |
                                date:"dd/MM/yyyy"}}
                            </li>
                            <li>
                                <strong>{{language.Duedate}}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    &nbsp;: &nbsp;</strong>{{data.deliveryNoteDate | date:"dd/MM/yyyy"}}
                            </li>
                            <li>
                                <strong>{{language.ShipmentNo}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                                    &nbsp;&nbsp;</strong>{{data.shipmentNumber}}
                            </li>
                            <li>
                                <strong>{{language.deliveryNoteNo}}&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</strong>{{data.deliveryNoteRefNumVp.substring(data.deliveryNoteRefNumVp.indexOf('_')+1)}}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="supportDocHeading" fxFlex="100">
                    <h6 class="mt-3">{{language.SupportDocuments}}</h6>
                    <ul class="listStyle" style="list-style: none;margin-left: 20px;">
                        <li *ngFor="let item of data.deliveryNoteSupportDocs;">
                            <!-- <a href="delivery-notes-history"><mat-icon style="font-size: 14px;">download</mat-icon> {{item.documentName}}</a> -->
                            <a (click)="downLoad(item)" style="cursor: pointer;">
                                {{ item.documentName | slice:0:20 }}{{ item.documentName.length > 20 ?
                                    '....' : '' }}{{item.documentName.length > 20? item.documentExtension:''}}</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <mat-table [dataSource]="detailsTableData" matSort multiTemplateDataRows
                        class=" full-width mat-elevation-z5 main-table dnInfoTable font-info">
                        <ng-container matColumnDef="poNumber">
                            <mat-header-cell *matHeaderCellDef class="po-number-dn" mat-sort-header>
                                {{language.poNumber}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="po-number-dn" data-label="PO Number :">
                                <a (click)="onPoInfo(element.poNumber)" class="poInfoCls">{{element.poNumber}}</a>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="poItem">
                            <mat-header-cell *matHeaderCellDef class="po-item-dn" mat-sort-header>
                                {{language.poItem}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="po-item-dn" data-label="PO Item :">
                                {{element.poItem}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="materialGroup">
                            <mat-header-cell *matHeaderCellDef class="material-dn" mat-sort-header>
                                {{language.materialGroup}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="material-dn" data-label="Material Group :">
                                {{element.materialGroup}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="material">
                            <mat-header-cell *matHeaderCellDef class="materialNo-dn" mat-sort-header>
                               {{language.material}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="materialNo-dn" data-label="Material No :">
                                {{element.material}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="shortText">
                            <mat-header-cell *matHeaderCellDef class="shortText-dn" mat-sort-header>
                                {{language.shortText}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="shortText-dn" data-label="Short Text :">
                                {{element.shortText}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="scheduledQuantity">
                            <mat-header-cell *matHeaderCellDef class="PoQty-dn" mat-sort-header>
                                {{language.scheduledQuantity}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="PoQty-dn" data-label="PO Qty :">
                                {{element.scheduledQuantity | number : '1.3-3' }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="grQuantity">
                            <mat-header-cell *matHeaderCellDef class="recQty-dn" mat-sort-header>{{language.grQuantity}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="recQty-dn" data-label="Received Qty : ">
                                {{element.grQuantity | number : '1.3-3' }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="outStandingQuantity">
                            <mat-header-cell *matHeaderCellDef class="penQty-dn" mat-sort-header>
                                {{language.outStandingQuantity}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="penQty-dn" data-label="Pending Qty : ">
                                {{element.outStandingQuantity | number : '1.3-3' }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="netPrice">
                            <mat-header-cell *matHeaderCellDef class="unitPrice-dn" mat-sort-header>
                                {{language.netPrice}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="unitPrice-dn" data-label="Unit Price : ">
                                {{element.netPrice | number : '1.2-2'}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="grossValue">
                            <mat-header-cell *matHeaderCellDef class="PoAmt-dn" mat-sort-header>
                                {{language.grossValue}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="PoAmt-dn" data-label="PO Amount : ">
                                {{element.grossValue | number : '1.2-2'}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="warehouseReceivedQuantity">
                            <mat-header-cell *matHeaderCellDef class="deliveringQty -dn" mat-sort-header> {{language.warehouseReceivedQuantity}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="deliveringQty -dn" data-label="Delivering Qty : ">
                                {{element.warehouseReceivedQuantity | number : '1.3-3' }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="totalAmount">
                            <mat-header-cell *matHeaderCellDef class="totalAmt-dn" mat-sort-header> {{language.totalAmount}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="totalAmt-dn" data-label="Total Amount : ">
                                {{element.warehouseReceivedQuantity * element.netPrice | number : '1.2-2' }}
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="columnsToDisplay">
                        </mat-header-row>
                        <mat-row *matRowDef="let element; columns: columnsToDisplay;"></mat-row>
                    </mat-table>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxFlex="50" fxFlex.xs="100"
                    class="addr mt-2 padding-left padding-right ">
                    <div fxFlex="100" class="pull-left">
                        <address>
                            <strong>{{language.totalinwords}}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;: &nbsp;
                                &nbsp;</strong>{{subTotal | AmountToWordPipe }}
                        </address>
                    </div>
                    <div class="f-end page-group" fxFlex="50" fxFlex.xs="100" fxLayout="column" fxLayout.xs="row">
                        <div fxFlex="100">
                            <ul class="listStyle">
                                <li>
                                    <strong>{{language.SubTotal}}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;: &nbsp;
                                        &nbsp;</strong>{{newsub}}&nbsp;{{vendorDetails.currency}}
                                </li>
                                <li>
                                    <strong>{{language.LessDiscount}}&nbsp; &nbsp;: &nbsp; &nbsp;</strong>0.00
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div fxFlex="100" fxLayout="column" class="addr">
                    <div fxFlex="50" fxFlex.xs="100">
                        <ul class="listStyle">
                            <li>
                                <strong>{{language.Currency}}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;: &nbsp; &nbsp;</strong>{{vendorDetails.currency == null ? '-' : vendorDetails.currency}}
                            </li>
                            <!-- <li>
                                <strong>{{language.AdditionalConditions}}&nbsp;&nbsp;&nbsp;:&nbsp; &nbsp;</strong><span
                                    fxFlex="auto">The price is exclusive of VAT.</span>
                            </li> -->
                            <li>
                                <strong>{{language.PaymentTerm}}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:
                                    &nbsp; &nbsp;</strong> {{data.paymentTermsDescription == null ? '-' : data.paymentTermsDescription}}
                            </li>
                            <li>
                                <strong>{{language.DownPayment}}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;:
                                    &nbsp; &nbsp;</strong>{{dpytAmount == null ? '-' : dpytAmount}}&nbsp;{{vendorDetails.currency}}
                            </li>
                        </ul>
                    </div>
                    <div fxFlex="50" fxFlex.xs="100">
                    </div>
                    <div fxFlex="50" fxFlex.xs="100" *ngIf="data.statusId == '3'">
                        <label><b>{{language.status}} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;: &nbsp; &nbsp; <mark>{{data.status.status}}</mark></b></label> <br>
                        <label><b>{{language.comment}}  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; :&nbsp; &nbsp; <mark>
                                <!-- {{comment}} -->
                                <span matTooltipClass="custom-tooltip" matTooltipPosition="before" matTooltip="{{comment}}">

                                    {{ comment | slice:0:25 }}{{ comment.length > 25 ?
                                    
                                    '....' : '' }}
                                    
                                    </span>
                            </mark></b></label>
                     </div>
                </div>
                <div class="row mt-2 addr " *ngIf="currentUser.roleID == '3' || currentUser.roleID == '7'">
                    <div fxFlex="100">
                        <div class="buttonGroup">
                            <button mat-raised-button class="leftBtn submitButton" type="button" style="margin-right: 11px;"
                                (click)="openDialog()">{{language.button1}}</button>
                            <button mat-raised-button class="rejectButton" type="button"
                                (click)="onCancel()">{{language.button2}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <mat-tab-group *ngIf="currentUser.roleID == '3' || currentUser.roleID == '7'"> -->
            <div fxFlex="100" fxLayout="column" class="padding-left padding-right page-body"
                *ngIf="currentUser.roleID == '3' || currentUser.roleID == '7'">
                <div class="f-middle pageHeading" fxLayout="row">
                    <div fxFlex="100" fxFlex.xs="100">
                       
                        <!-- <abbr title="Phone">P:</abbr> (123) 456-7890<br> -->
                    </div>
                </div>
                <h5 class="pageSubHeading">
                    {{language.Head}}
                </h5>
                <div fxFlex="100" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" class="addr mt-3 printRow">
                    <div fxFlex="50" fxFlex.xs="100" fxLayout="column">
                        <ul class="listStyle">
                            <li>
                                <strong>{{language.VendorCode}}&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                                <a (click)="onVenInfo(data.vendorId)" class="poInfoCls">{{data.sapVendorCode}}</a>
                            </li>
                            <li>
                                <strong>{{language.VendorName}}&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                                <!-- {{data.deliveryToPersonName}} -->
                                <span *ngIf="vendorDetail.busNameOne != undefined && vendorDetail.busNameOne != ''">{{vendorDetail.busNameOne}}&nbsp;&nbsp;{{vendorDetail.busNameTwo}}<br></span>
                            </li>
                            <li>
                                <strong>{{language.Address}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                                <span fxFlex="auto">
                                    <!-- <span *ngIf="vendorDetail.busNameOne != undefined">{{vendorDetail.busNameOne}}&nbsp;&nbsp;{{vendorDetail.busNameTwo}}<br></span> -->
                                    <span *ngIf="vendorDetail.busStreet != undefined && vendorDetail.busStreet != ''">{{vendorDetail.busStreet}}<br></span>
                                    <span *ngIf="vendorDetail.busStreetFour != undefined && vendorDetail.busStreetFour != ''">{{vendorDetail.busStreetFour}}<br></span>
                                    <span *ngIf="vendorDetail.busStreetFive != undefined && vendorDetail.busStreetFive != ''">{{vendorDetail.busStreetFive}}<br></span>
                                    <span *ngIf="vendorDetail.busDistrict != undefined && vendorDetail.busDistrict != ''">{{vendorDetail.busDistrict}}<br></span>
                                    <span *ngIf="vendorDetail.busCity != undefined && vendorDetail.busCity != ''">{{vendorDetail.busCity +" - "+vendorDetail.busPostalCode}}    <br> </span>
                                      <abbr title="Phone">{{vendorDetail.firstTelephoneNum == null ? '' :'P:'+vendorDetail.firstTelephoneNum}}</abbr>
                                  </span>
                            </li>
                        </ul>
                    </div>
                    <div fxFlex="50" fxFlex.xs="100" class="page-group">
                        <ul class="listStyle">
                            <li>
                                <strong>{{language.Date}}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;: &nbsp;</strong>{{data.deliveryNoteDate |
                                date:"dd/MM/yyyy"}}
                            </li>
                            <li>
                                <strong>{{language.Duedate}}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    &nbsp;: &nbsp; &nbsp;</strong>{{data.deliveryNoteDate | date:"dd/MM/yyyy"}}
                            </li>
                            <li>
                                <strong>{{language.ShipmentNo}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                                    &nbsp;&nbsp;</strong>{{data.shipmentNumber}}
                            </li>
                            <li>
                                <strong>{{language.deliveryNoteNo}}&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</strong>{{data.deliveryNoteRefNumVp.substring(data.deliveryNoteRefNumVp.indexOf('_')+1)}}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="supportDocHeading" fxFlex="100">
                    <h6 class="mt-3">{{language.SupportDocuments}}</h6>
                    <ul class="listStyle">
                        <li *ngFor="let item of data.deliveryNoteSupportDocs;">
                            <a (click)="downLoad(item)" style="cursor: pointer;">
                                {{ item.documentName | slice:0:20 }}{{ item.documentName.length > 20 ?
                                    '....' : '' }}{{item.documentName.length > 20? item.documentExtension:''}}</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <mat-table [dataSource]="detailsTableData" matSort multiTemplateDataRows
                        class=" full-width mat-elevation-z5 main-table dnInfoTable font-info">
                        <ng-container matColumnDef="poNumber">
                            <mat-header-cell *matHeaderCellDef class="po-number-dn" mat-sort-header>
                                {{language.poNumber}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="po-number-dn" data-label="PO Number :">
                                <a (click)="onPoInfo(element.poNumber)" class="poInfoCls">{{element.poNumber}}</a>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="poItem">
                            <mat-header-cell *matHeaderCellDef class="po-item-dn" mat-sort-header>
                                {{language.poItem}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="po-item-dn" data-label="PO Item :">
                                {{element.poItem}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="materialGroup">
                            <mat-header-cell *matHeaderCellDef class="material-dn" mat-sort-header>
                               {{language.materialGroup}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="material-dn" data-label="Material Group :">
                                {{element.materialGroup}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="material">
                            <mat-header-cell *matHeaderCellDef class="materialNo-dn" mat-sort-header>
                                {{language.material}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="materialNo-dn" data-label="Material No :">
                                {{element.material}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="shortText">
                            <mat-header-cell *matHeaderCellDef class="shortText-dn" mat-sort-header>
                                {{language.shortText}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="shortText-dn" data-label="Short Text :">
                                {{element.shortText}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="scheduledQuantity">
                            <mat-header-cell *matHeaderCellDef class="ItemQty-dn" mat-sort-header>
                                {{language.scheduledQuantity}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="ItemQty-dn" data-label="Item Qty :">
                                {{element.scheduledQuantity | number : '1.3-3' }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="grQuantity">
                            <mat-header-cell *matHeaderCellDef class="recQty-dn" mat-sort-header> {{language.grQuantity}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="recQty-dn" data-label="Received Qty : ">
                                {{element.grQuantity | number : '1.3-3' }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="outStandingQuantity">
                            <mat-header-cell *matHeaderCellDef class="penQty-dn" mat-sort-header>
                                {{language.outStandingQuantity}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="penQty-dn" data-label="Pending Qty : ">
                                {{element.outStandingQuantity | number : '1.3-3' }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="netPrice">
                            <mat-header-cell *matHeaderCellDef class="unitPrice-dn" mat-sort-header>
                                {{language.netPrice}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="unitPrice-dn" data-label="Unit Price : ">
                                {{element.netPrice | number : '1.2-2'}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="grossValue">
                            <mat-header-cell *matHeaderCellDef class="itemAmt-dn" mat-sort-header>
                                {{language.grossValue}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="itemAmt-dn" data-label="Item Amount : ">
                                {{element.grossValue | number : '1.2-2'}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="warehouseReceivedQuantity">
                            <mat-header-cell *matHeaderCellDef class="invoicedAmt-dn" mat-sort-header> {{language.warehouseReceivedQuantity}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="invoicedAmt-dn" data-label="Delivering Qty : ">
                                {{element.warehouseReceivedQuantity | number : '1.3-3' }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="totalAmount">
                            <mat-header-cell *matHeaderCellDef class="penAmt-dn" mat-sort-header> {{language.totalAmount}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="penAmt-dn" data-label="Total Amount : ">
                                {{element.warehouseReceivedQuantity * element.netPrice | number : '1.2-2' }}
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="columnsToDisplay">
                        </mat-header-row>
                        <mat-row *matRowDef="let element; columns: columnsToDisplay;"></mat-row>
                    </mat-table>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxFlex="50" fxFlex.xs="100"
                    class="addr mt-2 padding-left padding-right ">
                    <div fxFlex="100" class="pull-left">
                        <address>
                            <!-- <strong>{{language.totalinwords}}</strong> -->
                            <strong>{{language.totalinwords}}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;: &nbsp;
                                &nbsp;</strong>{{subTotal | AmountToWordPipe }}
                        </address>
                    </div>
                    <div class="f-end page-group" fxFlex="50" fxFlex.xs="100" fxLayout="column" fxLayout.xs="row">
                        <div fxFlex="100">
                            <ul class="listStyle">
                                <li>
                                    <strong>{{language.SubTotal}}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;: &nbsp;
                                        &nbsp;</strong>{{newsub}}&nbsp;{{vendorDetails.currency}}
                                </li>
                                <li>
                                    <strong>{{language.LessDiscount}}&nbsp; &nbsp;: &nbsp; &nbsp;</strong>0.00
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
               
                <div fxFlex="100" fxLayout="column" class="addr">
                    <div fxFlex="50" fxFlex.xs="100">
                        <ul class="listStyle">
                            <li>
                                <strong>{{language.Currency}}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;: &nbsp; &nbsp;</strong>{{vendorDetails.currency}}
                            </li>
                            <!-- <li>
                                <strong>{{language.AdditionalConditions}}&nbsp;&nbsp;&nbsp;:&nbsp; &nbsp;</strong><span
                                    fxFlex="auto">The price is exclusive of VAT.</span>
                            </li> -->
                            <li>
                                <strong>{{language.PaymentTerm}}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:
                                    &nbsp; &nbsp;</strong> {{data.paymentTermsDescription}}
                            </li>
                            <li>
                                <strong>{{language.DownPayment}}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;:
                                    &nbsp; &nbsp;</strong>{{dpytAmount == null ? '-' : dpytAmount}}&nbsp;{{vendorDetails.currency}}
                            </li>
                        </ul>
                    </div>
                    <div fxFlex="50" fxFlex.xs="100">
        
                    </div>
                </div>
                <div fxFlex="50" fxFlex.xs="100"  class="addr" *ngIf="data.statusId == '3'">
                    <label><b>{{language.status}} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;: &nbsp; &nbsp; <mark>{{data.status.status}}</mark></b></label> <br>
                    <label><b>{{language.comment}}  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; :&nbsp; &nbsp; <mark>
                            <span matTooltipClass="custom-tooltip" matTooltipPosition="before" matTooltip="{{comment}}">

                                {{ comment | slice:0:40 }}{{ comment.length > 40 ?
                                
                                '....' : '' }}
                                
                                </span>
                        </mark></b></label>
                 </div>
            </div>
            <!-- </mat-tab-group> -->
            <div class="overlay" *ngIf="mySpinner">
                <div class="center">
                    <mat-spinner></mat-spinner>
                </div>
            </div>
        </mat-card-content>
    </div>
    <!-- </form> -->

 <div class="printButton" >  
            <button class="printBtn" (click)="printPage('printPage')">
                <mat-icon>print</mat-icon>{{language.print}}               
            </button>           
         </div> 
</mat-card>