<h1 mat-dialog-title>{{language.Approve}} {{data}} </h1>
<div mat-dialog-content>
    <!-- <div *ngIf="userType.toUpperCase() != 'APU123'">
    <h5>Please select category of delivery</h5> 
        
    <mat-form-field appearance="outline" class="selectLang" style="padding:0px;margin: 0px;">
        <mat-label>Select Category</mat-label>
        <mat-select class="langSelect width-80" style="font-size: 14px;padding: 0.5em 0 0.5em 0 !important;" [(ngModel)]="category">
              <mat-option value="0">Sub Contract</mat-option>
              <mat-option value="1">Gasoline</mat-option>
              <mat-option value="2">Diesel</mat-option>
              <mat-option value="3">GR block stock</mat-option>
              <mat-option value="4">Direct GR</mat-option>
          </mat-select>
        </mat-form-field>
    </div> -->
    <!-- <div *ngIf="currentUser.roleID == '4'">
        <h5>Please select category of delivery</h5> 
            
        <mat-form-field appearance="outline" class="selectLang" style="padding:0px;margin: 0px;">
            <mat-label>Select Category</mat-label>
            <mat-select class="langSelect width-80" style="font-size: 14px;padding: 0.5em 0 0.5em 0 !important;" [(ngModel)]="category">
                  <mat-option value="0">Normal SES, Reverse (System Default this major scenario)</mat-option>
                  <mat-option value="1">Multiple cost center allocation</mat-option>
                  <mat-option value="2">SRN #1.1 - Service entry sheet Normal, 1 job 1 baht</mat-option>
                  <mat-option value="3">Pumping Service</mat-option>
                  <mat-option value="4">AGG Hauling</mat-option>
                  <mat-option value="5">AGG Hauling</mat-option>
                  <mat-option value="6">RMX truck transfer cost (CDS)</mat-option>
              </mat-select>
            </mat-form-field>
        </div> -->
    <div *ngIf="currentUser.roleID == '3' || currentUser.roleID == '7'">
        
        <h4>{{language.head}}</h4> 
        
        <mat-form-field class="comments">
             
        <mat-label>{{language.Comments}}</mat-label>
          <textarea matInput matInput [(ngModel)]="comment"  placeholder="{{language.commentex}}"></textarea>
        </mat-form-field>
        <mat-form-field class="emails" *ngIf="currentUser.roleID == '3' || currentUser.roleID == '7'">
             
            <mat-label>{{language.Emails}}</mat-label>
            <textarea matInput matInput [(ngModel)]="emails"  placeholder="{{language.emailex}}"></textarea>
          </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button (click)="onApproved()" mat-flat-button color="primary" class="submitButton button-actions green mx-2">
        <mat-icon aria-hidden="false" aria-label="check">check</mat-icon>
        <span class="mx-1">{{language.Approve}}</span>
    </button>
    <button mat-flat-button color="warn" (click)="onCancel()" class="rejectButton button-actions  mx-2">
        <mat-icon aria-hidden="false" aria-label="clear">clear</mat-icon>
        <span class="mx-1">{{language.Cancel}}</span>
    </button>
</div>
  <!-- <button mat-button (click)="()" class="button-actions red mx-2" cdkFocusInitial>Cancel</button> -->
