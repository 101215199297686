<mat-card>
    <mat-card-title>Notification Details
        
        <mat-icon (click)="goTo()" class="close" style="float: right;">close</mat-icon>
    </mat-card-title>
    <hr>
    <mat-card-content>

        <div style=" padding: 10px;">
            
            <p>{{activeData.subject}}</p>
            <p>{{activeData.body}}</p>
            <!-- <p>Regards,<br>Dishadvisor Team</p> -->
        </div>
        <!-- <div fxLayout="row" fxLayoutWrap="wrap">
           
            <div fxFlex.gt-sm="100%" fxFlex="100">
        <form  [formGroup]="notificationInfo" class="form-style">
        <div fxLayout="row" fxLayout.xs ="column">

            <div class="padding-right" fxFlex="33">
                <mat-form-field appearance="outline">
                    <mat-label>Subject</mat-label>
                    <input matInput formControlName="subject" type="text">
                </mat-form-field>
            </div>
         
            <div class="padding-right padding-left" fxFlex="33">
                <mat-form-field appearance="outline">
                    <mat-label>Body</mat-label>
                    <input matInput formControlName="body" type="text">
                </mat-form-field>
            </div>
          
        </div>
        </form>
            </div>
        </div> -->
        <div class="overlay"  *ngIf="mySpinner">
            <div class="center">
                <mat-spinner></mat-spinner> 
            </div>
            </div>
    </mat-card-content>
</mat-card>