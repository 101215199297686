import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { activities, Activity, PurchaseOrderInterface } from './notification.data';
import { CommonServicesService } from "src/app/services/common-services.service";
import { first, map, startWith } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  mySpinner: boolean = false;
  activityData: Activity[];
  responses: any;
  pageIndex = 0;
  pageSize = 10;
  listLength: any;
  userType: any;
  language:any;
  constructor(private router: Router, private cService: CommonServicesService) {

    // this.activityData = activities
  }


  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.userType = currentUser.response;
    let lang = this.userType.langPreference;
    if(lang == 'en'){
        this.language = {
          "notification": "Notification",
        }}
        else if(lang == 'th'){
          this.language = {
            "notification": "การแจ้งเตือน",
          }}
    
    this.getNotificationList(null);

  }
  onInfo(element: any) {
    this.router.navigate(["/notification/notification-details"]);
  }

  async getNotificationList(event: PageEvent) {
    this.mySpinner = true;
    if (event == null) {
      this.pageIndex = 0;
      this.pageSize = 10;
    } else {
      
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      
    }
    // notification?page={page}&limit={limit}
    (await this.cService.getNotificationList(this.pageIndex, this.pageSize)).pipe(first()).subscribe(res => {
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        this.activityData = res.response;
        
        this.listLength = res.pagination.count;
        this.mySpinner = false;
      } else {
        this.mySpinner = false;
      }
    }, error => {
      this.mySpinner = false;
    })
  }

  addNotificaton() {
    
  }
}