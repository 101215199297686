<mat-card>
        <mat-card-title>
            <div class="margin-bottotm" fxLayout="row">
                <div class="pull-left" fxFlex="50">
                    {{language.userInfo}}
                </div>
                <div class="pull-right" fxFlex="50">
                    <mat-icon (click)="goTo()" class="close1" >close</mat-icon>
                </div>
            </div>
        </mat-card-title>
           <hr>
              <mat-card-content>
                  <div fxLayout="row" fxLayoutWrap="wrap">
                      <!-- Card column -->
                      <div fxFlex.gt-sm="100%" fxFlex="100">
                  <form  [formGroup]="userInfo" class="form-style">
                  <div fxLayout="row" fxLayout.xs ="column">
                      <div class="padding-right" fxFlex="33">
                          <mat-form-field appearance="outline" >
                              <mat-label>{{language.FirstName}}</mat-label>
                              <input matInput formControlName="FirstName" type="text" readonly>
                          </mat-form-field>
                      </div>
                      <div class="padding-left padding-right" fxFlex="33">
                          <mat-form-field appearance="outline">
                              <mat-label>{{language.LastName}}</mat-label>
                              <input matInput formControlName="LastName" type="text" readonly>
                          </mat-form-field>
                      </div>
                      <div class=" padding-right padding-left" fxFlex="33">
                          <mat-form-field appearance="outline">
                              <mat-label>{{language.Email}}</mat-label>
                              <input matInput formControlName="Email" type="text" readonly>
                          </mat-form-field>
                      </div>
                  </div>
                  <div fxLayout="row" fxLayout.xs ="column">
                      <div class="padding-right" fxFlex="33">
                          <mat-form-field appearance="outline">
                              <mat-label>{{language.contactNo}} </mat-label>
                              <input matInput formControlName="contactNo" type="text" readonly>
                          </mat-form-field>
                      </div>
                      <div class="padding-right padding-left" fxFlex="33">
                              <mat-form-field appearance="outline">
                                  <mat-label>{{language.RoleName}} </mat-label>
                                  <input matInput formControlName="RoleName" type="text" readonly>
                              </mat-form-field>
                      </div>
                      <div class="padding-right padding-left" fxFlex="33" *ngIf="RoleId == '4' || RoleId == '5' || RoleId == '8'">
                              <mat-form-field appearance="outline">
                                  <mat-label>{{language.companyAllowed}} </mat-label>
                                  <input matInput formControlName="companyAllowed" type="text" readonly>
                              </mat-form-field>
                      </div>
                      <div class="padding-right padding-left" fxFlex="33" *ngIf="RoleId == '3' || RoleId == '6' || RoleId == '7'">
                            <mat-form-field appearance="outline">
                                <mat-label>{{language.plantAllowed}}</mat-label>
                                <input matInput formControlName="plantAllowed" type="text" readonly>
                            </mat-form-field>
                        </div>
                      </div>
              </form>
       </div>
  </div>
  </mat-card-content>
  </mat-card>
  <div class="overlay"  *ngIf="mySpinner">
      <div class="center">
          <mat-spinner></mat-spinner> 
      </div>
      </div>