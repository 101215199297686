import { Component, OnInit,Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Router} from '@angular/router'
import { map } from 'rxjs/operators';
import { first } from 'rxjs/operators';
// import { SigninComponent } from 'src/app/pages/signin/signin.component';
//import { User } from 'src/app/core/users/user.interface';

@Component({
  selector: 'app-forgot-modal',
  templateUrl: './forgot-modal.component.html',
  styleUrls: ['./forgot-modal.component.scss']
})
export class ForgotModalComponent implements OnInit {

  local_data:any;
  action:string;
  
  constructor(private fb: FormBuilder,private router: Router,
    public dialogRef: MatDialogRef<ForgotModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      // this.categoryType = data.categoryType;
      this.local_data = {...data};
      this.action = this.local_data.action;
    }

    changePwd: FormGroup;
    
  ngOnInit(): void {
    // this.getCategoryDropDown();
    this.changePwd = this.fb.group({
      //email : ['',[Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
      userName: ['', [Validators.required]]
    })
  }
  get f() { return this.changePwd.controls; }
    disabled = true;
      forgotPwd(){
        if(this.changePwd.get("userName").value){
          this.disabled = false;
        }else{
          this.disabled = true;
        }
      }
      onNoClick(): void {
        if (this.changePwd.invalid) {
            return;
        }
        this.dialogRef.close({event:this.action, data:this.changePwd.value});
      }
      closeDialog(){
        this.dialogRef.close({event:'Cancel'});
      }
      
}