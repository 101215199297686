import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// import { MyModalComponent } from './my-modal/my-modal.component';
import swal from 'sweetalert2';
import { VendorModalComponent } from '../vendor-management/vendor-modal/vendor-modal.component';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { first } from 'rxjs/operators';
import { AdminServiceService } from '../services/admin-service.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import {browserRefresh} from '../app.component';
import { TableUtil } from 'src/app/reports/TableUtil';
import { Location } from '@angular/common';

@Component({
  selector: 'app-user-audit-log',
  templateUrl: './user-audit-log.component.html',
  styleUrls: ['./user-audit-log.component.css']
})
export class UserAuditLogComponent implements OnInit {

  showOverlay: boolean;
  mySpinner:boolean = false;
  @ViewChild('myInput')
  myInputVariable: ElementRef
  constructor(private dialog: MatDialog, public _snackBar: MatSnackBar, private router: Router, private cService: AdminServiceService,
  private route:ActivatedRoute,private location: Location) { }
  dataSource: MatTableDataSource<any>;
  columnsToDisplay = ["requestId","userName", "name", "email", "description","lastLoginTime","createdDate", "updatedUserName", "newData", "previousData"];
  // columnsToDisplay1 = ["vendorCode", "firstName", "lastName", "email", "mobileNo", "isBlocked", "actions"];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  currentUser: any;
  status: boolean = false;
  supportDocs: any = [];
  listValue: any;
  datacache: any = [];
  newDocuments: any = [];
  headerToDisplay:any;
  browserRefresh:boolean = false;
  listData:any = [];
  search:any;
  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    let currentUsers = this.currentUser.response;
		let lang = currentUsers.langPreference;
    let states;
    states = this.location.getState();
		if(lang == 'en'){
      this.headerToDisplay={
        requestId: "Request Id",
        logedInUserName:"Login User Name",
        updatedUserName:"Updated User Name",
        userName:"User Name",
        name:"Name",
        description: "Description",
        previousData:"Previous Data",
        newData:"New Data",
        lastLoginTime:"Last Login Date & Time",
        expiryDate:"Expiry Date",
        createdDate: "Date",
        userManagement:"Audit Logs",
        search:"Search", 
        email:"Email"
      }
    }else if(lang == 'th'){
        this.headerToDisplay={
          requestId: "Request Id",
          logedInUserName:"ชื่อผู้ใช้เข้าสู่ระบบ",
          updatedUserName:"ชื่อผู้ใช้ที่อัปเดต",
          userName: "ชื่อผู้ใช้",
          name:"ชื่อ",
          description: "คำอธิบาย",
          previousData:"Previous Data",
          newData:"New Data",
          lastLoginTime:"วันที่และเวลาเข้าสู่ระบบล่าสุด",
          expiryDate:"Expiry Date",
          createdDate: "วันที่",
          userManagement:"บันทึกการตรวจสอบ",
          search:"ค้นหา",
          email:"อีเมล"
        }
      }
      
      if(localStorage.getItem("searchData") != null){
        this.searchString = localStorage.getItem("searchData")
      }
      if(browserRefresh == true){
        this.searchString = '';
        localStorage.setItem("searchData","")
        localStorage.setItem("tableData",JSON.stringify([]))
      }
      let tableData:any = [];
      if(localStorage.getItem("tableData")){
        tableData = JSON.parse(localStorage.getItem("tableData"));
        this.listData = tableData;
      }
      if(tableData.length == 0){
        this.searchString = states.search?states.search:"";
        localStorage.setItem("searchData",states.search?states.search:"")
        this.getAuditLogs(null, this.searchString);
      }else{
        this.dataSource = tableData.response;
        this.listLength = tableData.pagination.count;
        localStorage.setItem("tableData",JSON.stringify([]))
      }
  }
  pageIndex = 0;
  pageSize = 10;
  listLength: any;
  searchString:any='';
  getAuditLogs(event: PageEvent, searchString:any) {
    // this.showOverlay = true;
    this.mySpinner = true;
    if (event == null) {
      this.pageIndex = 0;
      this.pageSize = 10;
    } else {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
    }

    this.cService.getAuditLogs(this.pageIndex, this.pageSize, this.searchString).pipe(first()).subscribe(res => {
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        // this.showOverlay=false;
        //
        
        res.response.forEach(element => {
          if(element.newData){
            element.jsonNewData = JSON.parse(element.newData);
          }
          if(element.previousData){
            element.jsonPreviousData = JSON.parse(element.previousData);
          }
        });
        this.dataSource = new MatTableDataSource(res.response);
        this.listData = res;
        this.listLength = res.pagination.count;
        this.dataSource.sort=this.sort;
        this.mySpinner = false;
        //

      } else {
        this.mySpinner = false;
        this.dataSource = new MatTableDataSource([]);
        this.listData = [];
        this.listLength = 0;
        
        swal.fire(res.responseMessage,"", "error");
      }
    }, error => {
      this.mySpinner = false;
      // this.showOverlay=false;
    })
  }

  openView(element: any) {
    localStorage.setItem("tableData",JSON.stringify(this.listData));
    this.router.navigate(["vendor-management/vendor-info", element.id,'venderList']);
  }

  applyFilter1(){
    const filterValue = this.searchString;
    localStorage.setItem("searchData", this.searchString);
    if(this.searchString == "" ){
      this.getAuditLogs(null, this.searchString);
    }
  }
  applyFilter() {
    const filterValue = this.searchString;
    if(filterValue.length >= 2 || this.searchString == "" ){
      this.getAuditLogs(null, this.searchString);
    }
}

  calculateClass(status:any) {
    let val = false;
    if (status == "0") {
      val = true;
    }
    return {
      "color-green": val,
      "color-red": !val
    }
  }

  changeClass(status:any) {
    let val = false;
    if (status == "Y") {
      val = true;
    }
    return {
      "color-green": val,
      "color-red": !val
    }
  }

  openConfirm(element: any) {
    var dialogRef;
    // if(element["isBlocked"] == 1)
    if (element["isBlocked"] == 1) {

      dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: 'Vendor will get access to portal after confirmation',
          buttonText: {
            ok: 'Yes',
            cancel: 'Cancel',
          },
          buttonColor: {
            ok: 'primary',
            cancel: 'primary',
          },
        },
      });
    } else {
      dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: 'Vendor will not be able to access portal after confirmation',
          buttonText: {
            ok: 'Yes',
            cancel: 'Cancel',
          },
          buttonColor: {
            ok: 'primary',
            cancel: 'primary',
          },
        },
      });
    }


    
    let act = element["isBlocked"];
    if (act == '0') {
      act = 1;
    } else {
      act = 0;
    }
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      
      
      if (confirmed) {
        // var updateUserData = {
        //   "isBlocked": act,
        //   "id": element["id"],

        // }
        if(act == 1){
          this.updateRowData(element["id"]);
        }else{
          this.updateRowData1(element["id"]);
        }
        
      }
    })
  }
  updateRowData(row_obj) {
    this.showOverlay = true;

    this.cService.updateVendorUser(row_obj).pipe(first()).subscribe(data => {
      
      if (data != null) {
        if (data.responseStatus != "ERROR") {          
            this._snackBar.open('Vendor Blocked Succesfully', 'Ok', {
              duration: 5000,
            });
          
          // this.dataSource = data;
          this.showOverlay = false;
          this.getAuditLogs(null, this.searchString);
        } else {
          this.showOverlay = false;
          swal.fire(data.responseMessage,"", "error")
        }
      }
      // this.showOverlay=false;
    }, () => {
      this.showOverlay = false;
    })

  }
  updateRowData1(row_obj) {
    this.showOverlay = true;

    this.cService.updateVendorUser1(row_obj).pipe(first()).subscribe(data => {
      
      if (data != null) {
        if (data.responseStatus != "ERROR") {
          // this._snackBar.open('User Updated Succesfully', 'Ok', {
          this._snackBar.open('Vendor Unblocked Succesfully', 'Ok', {
            duration: 5000,
          });
          // this.dataSource = data;
          this.showOverlay = false;
          this.getAuditLogs(null, this.searchString);
        } else {
          this.showOverlay = false;
          swal.fire(data.responseMessage,"", "error")
        }
      }
      // this.showOverlay=false;
    }, () => {
      this.showOverlay = false;
    })

  }

  async exportexcel(){
    this.mySpinner = true;
    if(this.searchString == undefined){
      this.searchString = '';
    }
    this.cService.exportUserAuditLogData(this.searchString).pipe(first()).subscribe(res =>{
       this.mySpinner = false;
    },error =>{
       this.mySpinner = false; 
    })
  
  }
}

export interface PeriodicElement {
  status:any,
  vendorCode:any,
  vendorName:any,
  vendorGroup:any,
  city:any
  country:any,
}
