// my-modal.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router'
import { ConfigurationComponent } from '../configuration.component';

@Component({
  selector: 'app-config-modal',
  templateUrl: './config-model.html',
  styleUrls: ['./config-model.scss']
})

export class ConfigModel {
    local_data:any;
    action:any;
    status:boolean=false;
    currentUser: any;
    language:any;
    constructor(private fb: FormBuilder, private router: Router,
        public dialogRef: MatDialogRef<ConfigModel>,
        @Inject(MAT_DIALOG_DATA) public data: ConfigurationComponent) {
            this.local_data = { ...data };
            this.local_data.propValue = this.local_data.propValue + ' '; 
            this.action = this.local_data.action;
            if (this.action == 'Add') {
            this.status = false;
            } else {
            this.status = true;
            }
        }

        roleForm:FormGroup= this.fb.group({
          id : ['',[Validators.required]],
          propKey : ['',[Validators.required]],
          propValue : ['',[Validators.required]]
        })

        ngOnInit(): void {
          this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
          let currentUsers = this.currentUser.response;
          let lang = currentUsers.langPreference;
          if(lang == 'en'){
            this.language={
              propKey:"Property Key",
              propValue:"Property Value",
              note:"Note",
              line:"Please don't edit the static values like for E.g: userName, password"
            }
            }else if(lang == 'th'){
            this.language={
              propKey:"รหัสทรัพย์สิน",
              propValue:"มูลค่าทรัพย์สิน",
              note:"บันทึก",
              line:"โปรดอย่าแก้ไขค่าคงที่เช่น: ชื่อผู้ใช้, รหัสผ่าน"
            }
            }


            this.roleForm = this.fb.group({
              id : ['',[Validators.required]],
              propKey : ['',[Validators.required]],
              propValue : ['',[Validators.required]]
 
            })
        }

        onNoClick(): void {
            
            this.dialogRef.close({event:this.action, data:this.roleForm.value});
            // this.dialogRef.close({ event: this.action, data: this.local_data });
           
        
          }
          closeDialog() {
            this.dialogRef.close({ event: 'Cancel' });
          }
          onKeyDownEvent(event: any){
            {
              var tb = event.target;
              var data = tb.value;
              var start = tb.selectionStart;
              var end = tb.selectionEnd;
              var reg = new RegExp("(<.+?>)","g");
              var amatch = null;
              while ((amatch = reg.exec(data)) != null) {
                  var thisMatchStart = amatch.index-1;
                  var thisMatchEnd = amatch.index + amatch[0].length+1;
                  if (start <= thisMatchStart && end > thisMatchStart) {
                      event.preventDefault();
                      return false;
                  }
                  else if (start > thisMatchStart && start < thisMatchEnd) {
                    var keycode = event.keyCode;
                      if(keycode >= 37 && keycode <= 40) {
                        return true
                      }
                      event.preventDefault();
                      return false;
                  }
              }
            }
        }
}