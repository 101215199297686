<mat-card>
    <mat-card-title>
        <div fxLayout="row" fxFlex.gt-xs="100" fxFlex="100">
            {{headerToDisplay.userManagement}}
        </div>
    </mat-card-title>
    <hr>
    <mat-card-content>
        <div fxLayout="row" fxLayoutWrap="wrap">
            <!-- Card column -->
            <div fxFlex.gt-sm="100%" fxFlex="100">
                <!-- <mat-card> -->
                    <!-- <div class="row mx-2 mt-2 my-2 summaryTab mat-elevation-z8  mx-3 mt-3 full-width"> -->
                
                        <div fxLayout="row"fxLayout.xs="column">
                            <div class="search" fxFlex="30" fxFlex.sm="50" fxFlex.xs="100">
                                <mat-form-field class="mx-3 max-width">
                                 <mat-label>{{headerToDisplay.search}}</mat-label>
                                  <input matInput placeholder="Search" (keyup)="applyFilter1()" #input [(ngModel)]="searchString">
                               </mat-form-field>
                               </div>
                                
                               <div fxFlex="20" fxFlex.sm="50" fxFlex.xs="100" style="margin-top:15px">
                                    <button (click)="applyFilter()" mat-raised-button class="submitButton btn-primary">{{headerToDisplay.search}}</button>
                               </div>
                            <div fxFlex.gt-lg="60" fxFlex.gt-md="40" fxFlex.gt-xs="40" fxFlex="100"></div>
                            <div fxFlex="22" fxFlex.sm="50" fxFlex.xs="100" style="margin-top:15px">
                                <a  style="cursor:pointer;margin-left:155px" (click)="exportexcel()"><img src="assets/images/icons8-export-excel-100.png" height="50px" width="50px" ></a> 
                           </div>
                        </div>
                        <div class="overflow">
                    <mat-card>
                      
                            <mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="mat-elevation-z5 main-table auditLogsTable">
                                <ng-container matColumnDef="requestId">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header class="requestId-w"> {{headerToDisplay.requestId}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="requestId-w" data-label = "Request Id"> {{element.id}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="userName">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header class="userName-w"> {{headerToDisplay.logedInUserName}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="userName-w" data-label = "User Name"> {{element.userName}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="name">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header class="userName-w"> {{headerToDisplay.name}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="userName-w" data-label = "Name"> {{element.name}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="email">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header class="email-w"> {{headerToDisplay.email}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="email-w" data-label = "Email"> {{element.email}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="description">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header class="description-w"> {{headerToDisplay.description}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="description-w" data-label="Description"> {{element.description}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="previousData">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header class="sapResponse-w"> {{headerToDisplay.previousData}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="sapResponse-w" data-label="Previous Data">
                                        <span class="textWrap" matTooltipClass="custom-tooltip" matTooltipPosition="before"  matTooltip="{{element.jsonPreviousData | json}}">{{(element.previousData == null || element.previousData == undefined)?"-":element.previousData}}</span>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="newData">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header class="sapResponse-w"> {{headerToDisplay.newData}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="sapResponse-w" data-label="New Data"> 
                                        <span class="textWrap" matTooltipClass="custom-tooltip" matTooltipPosition="before"  matTooltip="{{element.jsonNewData | json}}">{{(element.newData == null || element.newData == undefined)?"-":element.newData}}</span>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="lastLoginTime">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header class="lastLoginTime-w"> {{headerToDisplay.lastLoginTime}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="lastLoginTime-w" data-label="Last Login Date/Time"> {{element.lastLoginTime? (element.lastLoginTime  | date:"dd/MM/yyyy HH:mm:ss"): "-"}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="expiryDate">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header class="expiryDate-w"> {{headerToDisplay.expiryDate}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="expiryDate-w" data-label="Expiry Date"> {{element.expiryDate  | date:"dd/MM/yyyy HH:mm:ss"}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="createdDate">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header class="createdDate-w"> {{headerToDisplay.createdDate}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="createdDate-w" data-label="Date"> {{element.createdDate  | date:"dd/MM/yyyy HH:mm:ss"}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="updatedUserName">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header class="description-w"> {{headerToDisplay.updatedUserName}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="description-w" data-label="Description"> {{element.updatedUserName? element.updatedUserName : "-"}} </mat-cell>
                                </ng-container>
                                <mat-header-row vendorRow *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                                <mat-row vendorRow *matRowDef="let element; columns: columnsToDisplay;" ></mat-row>
                            </mat-table>
                        
                       
                    </mat-card>
                    
                </div>  
                <mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" [pageSize]="pageSize" [length]="listLength" [pageSizeOptions]="[5, 10, 20]" [pageSizeOptions]="[10, 25, 50, 100]" (page)="getAuditLogs($event,'')"  style="width:100%"></mat-paginator> 
            </div>
        </div>
    </mat-card-content>
    <div class="overlay"  *ngIf="mySpinner">
        <div class="center">
            <mat-spinner ></mat-spinner> 
        </div>
    </div>
</mat-card>