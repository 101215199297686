import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { CommonServicesService } from 'src/app/services/common-services.service';
import { AdminServiceService } from 'src/app/services/admin-service.service';
import { first } from 'rxjs/operators';

export interface NavItem {
  name: string;
  type?: string;
  icon: string;
  state?: string;
  children?: NavItem[];
}


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})

export class AppSidebarComponent implements OnDestroy {
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  roleListHeirarchy: any = [];
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public cService: AdminServiceService,
    public menuItems: MenuItems
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);

  }
  navItems: NavItem[] = [];
  currentUser: any = [];
  ngOnInit(): void {
    // let data = localStorage.getItem("user");
    // if(data != null){
    //   if (data.toUpperCase() == "VND123") {
    //     this.navItems = this.MENUITEMSVND
    //   } else if (data.toUpperCase() == "WAR123") {
    //     this.navItems = this.MENUITEMSWAR
    //     // return MENUITEMSWAR;
    //   } else if (data.toUpperCase() == "ADM123") {
    //     // return MENUITEMSADM;
    //     this.navItems = this.MENUITEMSADM
    //   } else if (data.toUpperCase() == "APV123") {
    //     // return MENUITEMSAPV;
    //     this.navItems = this.MENUITEMSAPV
    //   } else if (data.toUpperCase() == "APU123") {
    //     // return MENUITEMSAPU;
    //     this.navItems = this.MENUITEMSAPU
    //   }else if (data.toUpperCase() == "PJO123") {
    //     // return MENUITEMSPJO;
    //     this.navItems = this.MENUITEMSPJO
    //   }
    //    else {
    //     // return MENUITEMSADM;
    //     this.navItems = this.MENUITEMSADM
    //   }
    // }else{
    //   // return MENUITEMSADM;
    //   this.navItems = this.MENUITEMSADM
    // }
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    this.getSelectedlistheirarchyRoles();

  }
  menuList: any = [];
  selectedHeirarchay: any = [];
  async getSelectedlistheirarchyRoles() {
    // throw new Error('Method not implemented.');
    // (await this.cService.getListHeirarchy(this.currentUser.roleID)).pipe(first()).subscribe(res => {
    // if (res != null) {
    let res = JSON.parse(localStorage.getItem("menuList"));
    
    this.selectedHeirarchay = res;
    let menus = res;
    this.roleListHeirarchy = res
    for (let i of menus) {
      if (i.isParent == 'Y') {
        this.menuList.push(i);
        if (i.children != undefined) {
          for (let x of i.children) {
            this.menuList.push(x);
          }
        }
      } else {
        this.menuList.push(i)
      }
    }
    this.getSelectedRoles();
    // }
    // })
  }
  finalMenu: any = [];
  mySpinner = false;
  async getSelectedRoles() {
    this.mySpinner = true;
    // let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    let res = JSON.parse(localStorage.getItem("menuAccess"));
    // (await this.cService.getSelectedRoles(this.currentUser.roleID)).pipe(first()).subscribe(res => {
    if (res != null) {

      let menus = [];
      menus = res;
      let sequence = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
      let order = {};
      sequence.forEach(function (id, i) { order[id] = i + 1 });

      this.roleListHeirarchy.sort(function (a, b) { return order[a.orderId] - order[b.orderId]; });

      let finalMenu = [];

      for (let j = 0; j < this.roleListHeirarchy.length; j++) {
        for (let i = 0; i < menus.length; i++) {
          if (menus[i].orderId === this.roleListHeirarchy[j].orderId) {
            finalMenu.push(menus[i])
          }
        }
      }
      menus = finalMenu;
      let menuss = [];
      for (let x = 0; x <= menus.length - 1; x++) {
        for (let y = 0; y <= this.selectedHeirarchay.length - 1; y++) {
          if (menus[x].id == this.selectedHeirarchay[y].id) {
            if (menus[x].isParent == 'Y') {
              if (this.selectedHeirarchay[y].children != undefined) {
                let objz = [];
                let objx = {};
                
                  for (let x1 = 0; x1 <= menus.length - 1; x1++) {
                    for (let i of this.selectedHeirarchay[y].children) {
                      if (menus[x1].id == i.id) {
                        objx = { state: i.menuUrl, name: i.menuName, type: 'link', icon: 'av_timer', children: [] };
                        objz.push(objx);
                      }
                    }
                  }
                
                let obj = { state: this.selectedHeirarchay[y].menuUrl, name: this.selectedHeirarchay[y].menuName, type: 'link', icon: 'av_timer', children: objz }

                menuss.push(obj);
              }
            } else if (menus[x].parentId != 11 || menus[x].parentId != 2 || menus[x].parentId != 3 || menus[x].parentId != 5) {
              

              let obj = { state: menus[x].menuUrl, name: menus[x].menuName, type: 'link', icon: 'av_timer', children: [] }
              menuss.push(obj);
            }
          }
        }
      }

      let menuData = [];
      for (let i = 0; i < menuss.length - 1; i++) {
        // i++;
        if (menuss[i].orderBy == i) {
          menuData.push(menuss[i]);
        }
      }
      
      this.navItems = menuss;
      
      this.mySpinner = false;
    }
    // })
  }

  MENUITEMSVND = [
    { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer', children: [] },

    {
      state: '', name: 'Purchase Order', type: 'link', icon: 'inventory', children: [
        { state: 'purchase-order', name: 'Pending', type: 'link', icon: 'inventory', children: [] },
        { state: 'purchase-order-history', name: 'History', type: 'link', icon: 'inventory', children: [] }
      ]
    },
    // { state: 'delivery-notes', name: 'Delivery Notes', type: 'link', icon: 'text_snippet', children:[] },
    {
      state: '', name: 'Delivery Notes', type: 'link', icon: 'text_snippet', children: [
        { state: 'delivery-notes', name: 'Pending', type: 'link', icon: 'text_snippet', children: [] },
        { state: 'delivery-notes-history', name: 'History', type: 'link', icon: 'text_snippet', children: [] }
      ]
    },
    { state: 'weigh-bridge', name: 'Weigh Bridge', type: 'link', icon: 'text_snippet', children: [] },
    {
      state: '', type: 'link', name: 'Invoices', icon: 'receipt', children: [
        {
          state: 'invoice-list', type: 'link', name: 'Invoice List', icon: 'receipt', children: []
        },
        {
          state: 'invoice-history', type: 'link', name: 'Invoice History', icon: 'receipt', children: []
        }
      ]
    },
    // { state: 'debit-note', type: 'link', name: 'Debit Note', icon: 'receipt', children:[] },
    // { state: 'credit-note', type: 'link', name: 'Credit Note', icon: 'receipt', children:[] },
    { state: 'notification', type: 'link', name: 'Notifications', icon: 'notifications_active', children: [] },
    // { state: 'vendor-management', type: 'link', name: 'Vendor info', icon: 'group', children:[] },
    { state: 'profile', type: 'link', name: 'Profile', icon: 'manage_accounts', children: [] },
    { state: 'change-pwd', type: 'link', name: 'Change Password', icon: 'password', children: [] }
  ];

  MENUITEMSWAR = [
    { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer', children: [] },
    // { state: 'purchase-order', name: 'Purchase Order', type: 'link', icon: 'inventory',children: [] },
    {
      state: '', name: 'Delivery Notes', type: 'link', icon: 'text_snippet', children: [
        { state: 'delivery-notes', name: 'Pending', type: 'link', icon: 'text_snippet', children: [] },
        { state: 'delivery-notes-history', name: 'History', type: 'link', icon: 'text_snippet', children: [] }
      ]
    },
    // { state: 'invoice-list', type: 'link', name: 'Invoices', icon: 'receipt',children: [] },
    { state: 'weigh-bridge', name: 'Weigh Bridge', type: 'link', icon: 'text_snippet', children: [] },
    { state: 'notification', type: 'link', name: 'Notifications', icon: 'notifications_active', children: [] },
    { state: 'profile', type: 'link', name: 'Profile', icon: 'manage_accounts', children: [] },
    { state: 'change-pwd', type: 'link', name: 'Change Password', icon: 'password' }
  ];

  MENUITEMSADM = [
    { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer', children: [] },
    { state: 'user-management', type: 'link', name: 'User Management', icon: 'view_list', children: [] },
    { state: 'role-management', type: 'link', name: 'Role Management', icon: 'view_headline', children: [] },
    // { state: 'vendor-management', type: 'link', name: 'Vendor Management', icon: 'group',children: [] },
    {
      state: '', type: 'link', name: 'Vendor Management', icon: 'group', children: [
        {
          state: 'vendor-list', type: 'link', name: 'Vendor List', icon: 'group', children: []
        },
        {
          state: 'vendor-management', type: 'link', name: 'Vendor Users', icon: 'group', children: []
        }

      ]
    },
    { state: 'app-configurations', type: 'link', name: 'Configuration', icon: 'settings_suggest', children: [] },
    { state: 'response-messages', type: 'link', name: 'Response Messages', icon: 'message', children: [] },

    { state: 'notification', type: 'link', name: 'Notifications', icon: 'notifications_active', children: [] },
    { state: 'audit', type: 'link', name: 'Audit', icon: 'list_alt', children: [] },
    { state: 'profile', type: 'link', name: 'Profile', icon: 'manage_accounts', children: [] },
    { state: 'change-pwd', type: 'link', name: 'Change Password', icon: 'password', children: [] },
  ];

  MENUITEMSAPV = [
    { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer', children: [] },
    {
      state: '', type: 'link', name: 'Invoices', icon: 'receipt', children: [
        {
          state: 'invoice-list', type: 'link', name: 'Invoice List', icon: 'receipt', children: []
        },
        // {
        //   state: 'invoice-pending-verification', type: 'link', name: 'Pending For Verify', icon: 'receipt', children:[]
        // },
        // {
        //   state: 'invoice-pending-verification-sap', type: 'link', name: 'Pending From SAP', icon: 'receipt', children:[]
        // },
        // {
        //   state: 'invoice-rejected-verification-sap', type: 'link', name: 'Rejected For SAP', icon: 'receipt', children:[]
        // },
        {
          state: 'invoice-history', type: 'link', name: 'Invoice History', icon: 'receipt', children: []
        }
      ]
    },
    { state: 'notification', type: 'link', name: 'Notifications', icon: 'notifications_active', children: [] },
    { state: 'profile', type: 'link', name: 'Profile', icon: 'manage_accounts', children: [] },
    { state: 'change-pwd', type: 'link', name: 'Change Password', icon: 'password' }
  ];

  MENUITEMSAPU = [
    { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer', children: [] },
    {
      state: '', type: 'link', name: 'Invoices', icon: 'receipt', children: [
        {
          state: 'invoice-list', type: 'link', name: 'Invoice List', icon: 'receipt', children: []
        },
        // {
        //   state: 'invoice-pending-verification', type: 'link', name: 'Pending For Verify', icon: 'receipt', children:[]
        // },
        // {
        //   state: 'invoice-pending-verification-sap', type: 'link', name: 'Pending From SAP', icon: 'receipt', children:[]
        // },
        // {
        //   state: 'invoice-rejected-verification-sap', type: 'link', name: 'Rejected For SAP', icon: 'receipt', children:[]
        // },
        {
          state: 'invoice-history', type: 'link', name: 'Invoice History', icon: 'receipt', children: []
        }
      ]
    },
    { state: 'notification', type: 'link', name: 'Notifications', icon: 'notifications_active', children: [] },
    { state: 'profile', type: 'link', name: 'Profile', icon: 'manage_accounts', children: [] },
    { state: 'change-pwd', type: 'link', name: 'Change Password', icon: 'password' }
  ];
  MENUITEMSPJO = [
    { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer', children: [] },
    {
      state: '', name: 'Purchase Order', type: 'link', icon: 'inventory', children: [
        { state: 'purchase-order', name: 'Pending', type: 'link', icon: 'inventory', children: [] },
        { state: 'purchase-order-history', name: 'History', type: 'link', icon: 'inventory', children: [] }
      ]
    },
    {
      state: '', type: 'link', name: 'Invoices', icon: 'receipt', children: [
        {
          state: 'invoice-list', type: 'link', name: 'Invoice List', icon: 'receipt', children: []
        },
        // {
        //   state: 'invoice-pending-verification', type: 'link', name: 'Pending For Verify', icon: 'receipt', children:[]
        // },
        // {
        //   state: 'invoice-pending-verification-sap', type: 'link', name: 'Pending From SAP', icon: 'receipt', children:[]
        // },
        // {
        //   state: 'invoice-rejected-verification-sap', type: 'link', name: 'Rejected For SAP', icon: 'receipt', children:[]
        // },
        {
          state: 'invoice-history', type: 'link', name: 'Invoice History', icon: 'receipt', children: []
        }
      ]
    },
    { state: 'notification', type: 'link', name: 'Notifications', icon: 'notifications_active', children: [] },
    { state: 'profile', type: 'link', name: 'Profile', icon: 'manage_accounts', children: [] },
    { state: 'change-pwd', type: 'link', name: 'Change Password', icon: 'password' }
  ];



  // [
  //   {
  //     name: 'DevFestFL',
  //     icon: 'recent_actors',
  //     state: 'devfestfl',
  //     children: [
  //       {
  //         name: 'Speakers',
  //         icon: 'group',
  //         state: 'devfestfl/speakers',
  //         children: [
  //           {
  //             name: 'Michael Prentice',
  //             icon: 'person',
  //             state: 'devfestfl/speakers/michael-prentice',
  //             children: [
  //               {
  //                 name: 'Create Enterprise UIs',
  //                 icon: 'star_rate',
  //                 state: 'devfestfl/speakers/michael-prentice/material-design'
  //               }
  //             ]
  //           },
  //           {
  //             name: 'Stephen Fluin',
  //             icon: 'person',
  //             state: 'devfestfl/speakers/stephen-fluin',
  //             children: [
  //               {
  //                 name: 'What\'s up with the Web?',
  //                 icon: 'star_rate',
  //                 state: 'devfestfl/speakers/stephen-fluin/what-up-web'
  //               }
  //             ]
  //           },
  //           {
  //             name: 'Mike Brocchi',
  //             icon: 'person',
  //             state: 'devfestfl/speakers/mike-brocchi',
  //             children: [
  //               {
  //                 name: 'My ally, the CLI',
  //                 icon: 'star_rate',
  //                 state: 'devfestfl/speakers/mike-brocchi/my-ally-cli'
  //               },
  //               {
  //                 name: 'Become an Angular Tailor',
  //                 icon: 'star_rate',
  //                 state: 'devfestfl/speakers/mike-brocchi/become-angular-tailer'
  //               }
  //             ]
  //           }
  //         ]
  //       },
  //       {
  //         name: 'Sessions',
  //         icon: 'speaker_notes',
  //         state: 'devfestfl/sessions',
  //         children: [
  //           {
  //             name: 'Create Enterprise UIs',
  //             icon: 'star_rate',
  //             state: 'devfestfl/sessions/material-design'
  //           },
  //           {
  //             name: 'What\'s up with the Web?',
  //             icon: 'star_rate',
  //             state: 'devfestfl/sessions/what-up-web'
  //           },
  //           {
  //             name: 'My ally, the CLI',
  //             icon: 'star_rate',
  //             state: 'devfestfl/sessions/my-ally-cli'
  //           },
  //           {
  //             name: 'Become an Angular Tailor',
  //             icon: 'star_rate',
  //             state: 'devfestfl/sessions/become-angular-tailer'
  //           }
  //         ]
  //       },
  //       {
  //         name: 'Feedback',
  //         icon: 'feedback',
  //         state: 'devfestfl/feedback'
  //       }
  //     ]
  //   },
  //   {
  //     name: 'Disney',
  //     icon: 'videocam',
  //     children: [
  //       {
  //         name: 'Speakers',
  //         icon: 'group',
  //         children: [
  //           {
  //             name: 'Michael Prentice',
  //             icon: 'person',
  //             state: 'michael-prentice',
  //             children: [
  //               {
  //                 name: 'Create Enterprise UIs',
  //                 icon: 'star_rate',
  //                 state: 'material-design'
  //               }
  //             ]
  //           },
  //           {
  //             name: 'Stephen Fluin',
  //             icon: 'person',
  //             state: 'stephen-fluin',
  //             children: [
  //               {
  //                 name: 'What\'s up with the Web?',
  //                 icon: 'star_rate',
  //                 state: 'what-up-web'
  //               }
  //             ]
  //           },
  //           {
  //             name: 'Mike Brocchi',
  //             icon: 'person',
  //             state: 'mike-brocchi',
  //             children: [
  //               {
  //                 name: 'My ally, the CLI',
  //                 icon: 'star_rate',
  //                 state: 'my-ally-cli'
  //               },
  //               {
  //                 name: 'Become an Angular Tailor',
  //                 icon: 'star_rate',
  //                 state: 'become-angular-tailer'
  //               }
  //             ]
  //           }
  //         ]
  //       },
  //       {
  //         name: 'Sessions',
  //         icon: 'speaker_notes',
  //         children: [
  //           {
  //             name: 'Create Enterprise UIs',
  //             icon: 'star_rate',
  //             state: 'material-design'
  //           },
  //           {
  //             name: 'What\'s up with the Web?',
  //             icon: 'star_rate',
  //             state: 'what-up-web'
  //           },
  //           {
  //             name: 'My ally, the CLI',
  //             icon: 'star_rate',
  //             state: 'my-ally-cli'
  //           },
  //           {
  //             name: 'Become an Angular Tailor',
  //             icon: 'star_rate',
  //             state: 'become-angular-tailer'
  //           }
  //         ]
  //       },
  //       {
  //         name: 'Feedback',
  //         icon: 'feedback',
  //         state: 'feedback'
  //       }
  //     ]
  //   },
  //   {
  //     name: 'Orlando',
  //     icon: 'movie_filter',
  //     children: [
  //       {
  //         name: 'Speakers',
  //         icon: 'group',
  //         children: [
  //           {
  //             name: 'Michael Prentice',
  //             icon: 'person',
  //             state: 'michael-prentice',
  //             children: [
  //               {
  //                 name: 'Create Enterprise UIs',
  //                 icon: 'star_rate',
  //                 state: 'material-design'
  //               }
  //             ]
  //           },
  //           {
  //             name: 'Stephen Fluin',
  //             icon: 'person',
  //             state: 'stephen-fluin',
  //             children: [
  //               {
  //                 name: 'What\'s up with the Web?',
  //                 icon: 'star_rate',
  //                 state: 'what-up-web'
  //               }
  //             ]
  //           },
  //           {
  //             name: 'Mike Brocchi',
  //             icon: 'person',
  //             state: 'mike-brocchi',
  //             children: [
  //               {
  //                 name: 'My ally, the CLI',
  //                 icon: 'star_rate',
  //                 state: 'my-ally-cli'
  //               },
  //               {
  //                 name: 'Become an Angular Tailor',
  //                 icon: 'star_rate',
  //                 state: 'become-angular-tailer'
  //               }
  //             ]
  //           }
  //         ]
  //       },
  //       {
  //         name: 'Sessions',
  //         icon: 'speaker_notes',
  //         children: [
  //           {
  //             name: 'Create Enterprise UIs',
  //             icon: 'star_rate',
  //             state: 'material-design'
  //           },
  //           {
  //             name: 'What\'s up with the Web?',
  //             icon: 'star_rate',
  //             state: 'what-up-web'
  //           },
  //           {
  //             name: 'My ally, the CLI',
  //             icon: 'star_rate',
  //             state: 'my-ally-cli'
  //           },
  //           {
  //             name: 'Become an Angular Tailor',
  //             icon: 'star_rate',
  //             state: 'become-angular-tailer'
  //           }
  //         ]
  //       },
  //       {
  //         name: 'Feedback',
  //         icon: 'feedback',
  //         state: 'feedback'
  //       }
  //     ]
  //   },
  //   {
  //     name: 'Maleficent',
  //     type: "true",
  //     icon: 'report_problem',
  //     children: [
  //       {
  //         name: 'Speakers',
  //         icon: 'group',
  //         children: [
  //           {
  //             name: 'Michael Prentice',
  //             icon: 'person',
  //             state: 'michael-prentice',
  //             children: [
  //               {
  //                 name: 'Create Enterprise UIs',
  //                 icon: 'star_rate',
  //                 state: 'material-design'
  //               }
  //             ]
  //           },
  //           {
  //             name: 'Stephen Fluin',
  //             icon: 'person',
  //             state: 'stephen-fluin',
  //             children: [
  //               {
  //                 name: 'What\'s up with the Web?',
  //                 icon: 'star_rate',
  //                 state: 'what-up-web'
  //               }
  //             ]
  //           },
  //           {
  //             name: 'Mike Brocchi',
  //             icon: 'person',
  //             state: 'mike-brocchi',
  //             children: [
  //               {
  //                 name: 'My ally, the CLI',
  //                 icon: 'star_rate',
  //                 state: 'my-ally-cli'
  //               },
  //               {
  //                 name: 'Become an Angular Tailor',
  //                 icon: 'star_rate',
  //                 state: 'become-angular-tailer'
  //               }
  //             ]
  //           }
  //         ]
  //       },
  //       {
  //         name: 'Sessions',
  //         icon: 'speaker_notes',
  //         children: [
  //           {
  //             name: 'Create Enterprise UIs',
  //             icon: 'star_rate',
  //             state: 'material-design'
  //           },
  //           {
  //             name: 'What\'s up with the Web?',
  //             icon: 'star_rate',
  //             state: 'what-up-web'
  //           },
  //           {
  //             name: 'My ally, the CLI',
  //             icon: 'star_rate',
  //             state: 'my-ally-cli'
  //           },
  //           {
  //             name: 'Become an Angular Tailor',
  //             icon: 'star_rate',
  //             state: 'become-angular-tailer'
  //           }
  //         ]
  //       },
  //       {
  //         name: 'Feedback',
  //         icon: 'feedback',
  //         state: 'feedback'
  //       }
  //     ]
  //   }
  // ];

}
