import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { filter, map } from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {NavigationStart} from '@angular/router';
export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  data: any
  subscription: Subscription;
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    location: PlatformLocation,
    private cookieService: CookieService
  ) { 

    location.onPopState(() => {
    // this.router.navigate(["/signin"]);

    });
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });

  }

  ngOnInit() {
    // this.router.navigate(["landing-page"]);
    // this.clearAppData();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      const rt = this.getChild(this.activatedRoute);
      rt.data.subscribe((data: any) => {
        this.titleService.setTitle("INSEE Vendor Portal")
      });
    });
  }
  getChild(activatedRoute: ActivatedRoute): any {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

    //   const appTitle = this.titleService.getTitle();
    //   this.router
    //     .events.pipe(
    //       filter(event => event instanceof NavigationEnd),
    //       map(() => {
    //         let child = this.activatedRoute.firstChild;
    //         while (child.firstChild) {
    //           child = child.firstChild;
    //         }
    //         if (child.snapshot.data['title']) {
    //           return child.snapshot.data['title'];
    //         }
    //         return appTitle;
    //       })
    //     ).subscribe((ttl: string) => {
    //       this.titleService.setTitle(ttl + " ISEE");
    //     });
  }

  private clearAppData(): void {
    sessionStorage.clear();
    localStorage.clear();
    this.cookieService.deleteAll();
this.router.navigate(["signin"]);
}
}

