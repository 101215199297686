import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { PurchaseOrderInterface } from 'src/app/purchase-order/purchase-order';

@Component({
  selector: 'app-credit-note',
  templateUrl: './credit-note.component.html',
  styleUrls: ['./credit-note.component.css']
})
export class CreditNoteComponent implements OnInit {

  expandedElement: PurchaseOrderInterface | null | undefined;
  constructor(private router:Router,private dialog:MatDialog) { }
  userType:any;
  @ViewChild(MatSort) sort: MatSort;
  ngOnInit(): void {
    this.userType = localStorage.getItem("user");
    this.dataSource.sort=this.sort;
    
  }
  dataSource:any=[
    {
      'invoiceNo':"1010062_IV12991",'documentNo':"87146", 'creditNoteRefNo':"12991",
    },
    {
      'invoiceNo':"1010062_IV12992",'documentNo':"87147", 'creditNoteRefNo':"12992",
    }
  ];
  columnsToDisplay = ['invoiceNo','documentNo', 'creditNoteRefNo','actions'];
  

  
  keyword:any;
  applyFilter(event:any){
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
