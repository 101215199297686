import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
    
  }

  navigate(){
    this.router.navigate(["signin"]);
    history.pushState(null, null, location.href);
    window.addEventListener('popstate', function () {
    history.go(1);
    
    // window.onbeforeunload = function () {return false;}
    
    // window.location.reload()
});
  }
}
