import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpXsrfTokenExtractor
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
// import { ApiConfiguration } from "../api/api-configuration";
import { environment } from "../../environments/environment";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  authenticationService = JSON.parse(localStorage.getItem("currentUser"));

  constructor(
    private authService: AuthService,
    private tokenExtractor: HttpXsrfTokenExtractor
    // private apiConfiguration: ApiConfiguration
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (request.url.startsWith(environment.serverUrl)) {
      // add auth header with jwt if user is logged in and request is to api url
      const currentUser = JSON.parse(localStorage.getItem("currentUser"));//this.authenticationService;
      const isLoggedIn = currentUser;
      const isApiUrl = request.url.startsWith(environment.serverUrl);
      // 
      if (isApiUrl && isLoggedIn) {
        // //
        const cookieheaderName = 'X-XSRF-TOKEN';
        let csrfToken = this.tokenExtractor.getToken() as string;
        if (csrfToken !== null) {
          request = request.clone({ headers: request.headers.set(cookieheaderName, csrfToken) });
        }

        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.token}`,
            // 'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
            'Access-Control-Allow-Headers': 'Content-Type'
          }
        });
        // //
      } else if (isApiUrl && this.authService.resetpassword) {
        // //
        const restPwdtoken = request.headers["lazyUpdate"][0]["value"];
        request = request.clone({
          setHeaders: {
            Authorization: restPwdtoken,
            // 'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
            'Access-Control-Allow-Headers': 'Content-Type'
          }
        });
      }
    }
    // 
    return next.handle(request);
  }
}
