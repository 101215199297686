// my-modal.component.ts
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router'
import { VendorManagementComponent } from '../vendor-management.component';

import { AdminServiceService } from 'src/app/services/admin-service.service';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vendor-modal',
  templateUrl: './vendor-modal.component.html',
  styleUrls: ['./vendor-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class VendorModalComponent {
  emailPattern = /^([\w-]|(?<!\.)\.)+[a-zA-Z0-9]@[a-zA-Z0-9]([\w\-]+)((\.([a-zA-Z]){2,9})+)$/;
  NamePattern = "^[a-zA-Z ]*$";
  food: string | undefined;
  local_data: any;
  action: string;
  status: boolean = true;
  show: boolean = false;
  isboolean:boolean = true;
  constructor(private fb: FormBuilder, private router: Router,
    public dialogRef: MatDialogRef<VendorModalComponent>,
    private cService: AdminServiceService,
    @Inject(MAT_DIALOG_DATA) public data: VendorManagementComponent) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
    
    
    if (this.action == 'Add') {
      if (lang == 'en') {
        this.action = "Add"
      } else {
        this.action = 'เพิ่ม'
      }
      this.status = false;
    } 
    else {
      if (this.action == 'Update') {
        if (lang == 'en') {
          this.action = "Update"
          this.show = true;
        } else {
          this.action = "อัปเดต"
        }
      }
      this.status = true;
    }
    // if(this.action == 'Update'){
    //   this.local_data.usertype = this.local_data.usertype+"-"+this.local_data.id;
    // }
    
    // 
  }

  get f() { return this.vendorForm.controls; }
  vendorForm: FormGroup = this.fb.group({
    firstName: ['', [Validators.required, Validators.pattern(this.NamePattern)]],
    lastName: ['', [Validators.required, Validators.pattern(this.NamePattern)]],
    contactNo: ['', [Validators.required]],
    usertype: ['', [Validators.required]],
    id: ['', [Validators.required]],
    wareHouseLevel: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email, Validators.pattern(this.emailPattern)]],
    vendorId: [''],
    vendorName: ['']
  });
  vendorList: any = [];
  dropdownList: any = [];
  dropdownSettings = {};
  selectedData: any = [];
  currentUser: any;
  language:any;
  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
    if(lang == 'en'){
        this.language = {
        "FirstName":"First Name",
        "LastName":"Last Name",
        "Email":"Email",
        "contactNo":"Contact No",
        "vendor":"Vendor",
        "cancel":"Cancel",
        "contact":"Contact",
        "phoneNo":"Phone No",
        "swalFirst":"First name is required",
        "swalLast":"Last name is required",
        "swalAlpha":"Only alphabets are allowed",
        "swalEmail":"Email is required",
        "swalvalEmail":"Enter a valid email address",
        "swalcontact":"Contact number is required",
        "swalvalcon":"Contact number must contain atleast 11 digits",
        "swaluser":"User type is required",
        "swalcompany":"Company allowed is Required",
        "swalvenCode":"Vendor code is required",
        "user":"User"
    }}
    else if(lang == 'th'){
      this.language = {
        "FirstName":	"ชื่อจริง",
        "LastName"	:"นามสกุล",
        "Email":"อีเมล",
        "contactNo":"เบอร์ติดต่อ",
        "vendor":"ผู้ขาย",
        "cancel":"ยกเลิก",
        "contact":"ติดต่อ",
        "phoneNo":"หมายเลขโทรศัพท์",
        "swalFirst":"ต้องระบุชื่อ",
        "swalLast":"ต้องระบุนามสกุล",
        "swalAlpha":"อนุญาตเฉพาะตัวอักษรเท่านั้น",
        "swalEmail":"จำเป็นต้องใช้อีเมล",
        "swalvalEmail":"ใส่อีเมล์ที่ถูกต้อง",
        "swalcontact":"ต้องระบุเบอร์ติดต่อ",
        "swalvalcon":"เบอร์ติดต่อต้องมีอย่างน้อย 11 หลัก",
        "swaluser":"ต้องระบุประเภทผู้ใช้",
        "swalcompany":"จำเป็นต้องได้รับอนุญาตบริษัท",
        "swalvenCode":"ต้องระบุรหัสผู้จำหน่าย",
        "user":"ผู้ใช้"
  }
}


    // 
    this.vendorForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern(this.NamePattern)]],
      lastName: ['', [Validators.required, Validators.pattern(this.NamePattern)]],
      contactNo: ['', [Validators.required]],
      usertype: ['', [Validators.required]],
      id: ['', [Validators.required]],
      wareHouseLevel: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email, Validators.pattern(this.emailPattern)]],
      vendorId: [''],
      vendorName: ['']
    })
    // 
    // this.vendorList = [
    //   {"vendorCode":"10004","vendorName":"dsaf"},
    //   {"vendorCode":"10005","vendorName":"dsaf"},
    //   {"vendorCode":"10006","vendorName":"dsaf"}
    // ]
    this.dropdownSettings = {
      singleSelection: true,
      idField: "vendorCode",
      textField: "nameOne",
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
    this.getVendorCodes();
  }
  mySpinner = false;
 async getVendorCodes() {
    this.mySpinner = true;
    (await this.cService.getVendorsCode()).pipe(first()).subscribe(res => {
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        // this.vendorList = res.response;
        // let myData:any=[];
        // for(let i of res.response){
        //   if(i.isActive =='Y' && i.isBlocked == 0){
        //     myData.push(i);
        //   }
        // }
        // this.mySpinner = false;
        // this.dropdownList = myData;//res.response;

        // this.dropdownList = res.response;
        let plantData:any=[];
        for(let i  of res.response){
          let obj = {
            "vendorCode":i.vendorCode,
            "nameOne":i.vendorCode+"-"+i.nameOne,
            "id":i.id
          }
          plantData.push(obj);
        }
        this.dropdownList = plantData;
        
        for(let i of this.dropdownList){
          if(this.local_data.usertype == i.vendorCode){
            this.usertype = this.usertype.concat({
              vendorCode: i.vendorCode,
              nameOne: i.nameOne
            });
            // return
          }
          
        }
        
        
        this.mySpinner = false;
      }else{
        this.mySpinner = false;
      }
     
      // this.vendorForm.controls.usertype.setValue(this.local_data.usertype);
    },error =>{
      this.mySpinner = false;
    })

  }
  numberOnly(event) {
    var regex = new RegExp("^[0-9-!@#$%*?()<>^&+ _-]");
    var key = String.fromCharCode(event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
        event.preventDefault();
        return false;
    }

  }
  onNoClick(): void {
    let nameregex = /^[a-zA-Z ]*$/;
    let emailregex = /^([\w-]|(?<!\.)\.)+[a-zA-Z0-9]@[a-zA-Z0-9]([\w\-]+)((\.([a-zA-Z]){2,9})+)$/;
    if (this.vendorForm.value.firstName == "" || this.vendorForm.value.firstName == undefined) {
      Swal.fire(`${this.language.swalFirst}`,"","warning");
      return;
    } 
    // else if (!nameregex.test(this.vendorForm.value.firstName)) {
    //   // 
    //   Swal.fire(`${this.language.swalAlpha}`,"","warning");
    //   return
    // }
    if (this.vendorForm.value.lastName == "" || this.vendorForm.value.lastName == undefined) {
      Swal.fire(`${this.language.swalLast}`,"","warning");
      return
    }
    // else if (!nameregex.test(this.vendorForm.value.lastName)) {
    //   // 
    //   Swal.fire(`${this.language.swalAlpha}`,"","warning");
    //   return
    // }
    if (this.vendorForm.value.contactNo == "" || this.vendorForm.value.contactNo == undefined) {
      Swal.fire(`${this.language.swalcontact}`,"","warning"); 
      return;
    }
    if (this.vendorForm.value.email == "" || this.vendorForm.value.email == undefined) {
      Swal.fire(`${this.language.swalEmail}`,"","warning");
      return
    } else if (!emailregex.test(this.vendorForm.value.email)) {
      // 
      Swal.fire(`${this.language.swalvalEmail}`,"","warning");
      return
    }
    if (this.vendorForm.value.usertype == "" || this.vendorForm.value.usertype == undefined) {
      Swal.fire(`${this.language.swalvenCode}`,"","warning");
      return;
    }
    let vendorCode,vendorId,vendorName;
    for(let i of this.dropdownList){
      if(i.vendorCode == this.usertype[0].vendorCode){
          vendorCode = i.vendorCode;
          vendorName = i.nameOne;
          vendorId = i.id;
      }
    }
    let vendorD = {
      contactNo: this.vendorForm.value.contactNo,
      email: this.vendorForm.value.email,
      firstName: this.vendorForm.value.firstName,
      id: this.vendorForm.value.id,
      lastName: this.vendorForm.value.lastName,
      usertype: vendorCode,
      vendorName: vendorName,
      vendorId: vendorId,
      wareHouseLevel: ""
    }
    
    this.dialogRef.close({ event: this.action, data: vendorD });
    // this.dialogRef.close({
      
    //     vendorCode :this.vendorForm.value.vendorCode,
    //     companyCode: this.vendorForm.value.companyCode,
    //     vendorGroup:this.vendorForm.value.vendorGroup ,
    //     currency: this.vendorForm.value.currency,
    //     city: this.vendorForm.value.city,
    //     country:this.vendorForm.value.country
    // });


  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
  usertype:any=[];
  onItemSelect(item: any) {
    this.show = true
    this.usertype = [];
    this.usertype.push(item);
    // this.selectedData.usertype.push(item);
    // // this.getDeliveryNoteDetails();
    // 
  }
  onItemDeSelect(item: any) {
    this.usertype = [];
    this.show = false;
  }

  Space(event:any){
    if( event.key === ' ' && event.target.selectionStart === 0 ){
      event.preventDefault(); 
    }
  }

    keypressalpha(event:any){
    var inp = String.fromCharCode(event.keyCode);
    if ( !/^[/,<>+{}?|%="':;]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}