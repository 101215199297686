import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuditRecordsComponent } from '../audit-records.component';

@Component({
  selector: 'app-audit-model',
  templateUrl: './audit-model.component.html',
  styleUrls: ['./audit-model.component.css']
})
export class AuditModelComponent {

  local_data:any;
    action:any;
    status:boolean=false;
    constructor(private fb: FormBuilder, private router: Router,
        public dialogRef: MatDialogRef<AuditModelComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AuditRecordsComponent) {
            this.local_data = { ...data };
            this.action = this.local_data.action;
            if (this.action == 'Add') {
            this.status = false;
            } else {
            this.status = true;
            }
        }

        roleForm:FormGroup= this.fb.group({
          respCode : ['',[Validators.required]],
          message : ['',[Validators.required]],
            
        })

        ngOnInit(): void {
            this.roleForm = this.fb.group({
              respCode : ['',[Validators.required]],
              message : ['',[Validators.required]]
 
            })
        }

        onNoClick(): void {
            
            this.dialogRef.close({event:this.action, data:this.roleForm.value});
            // this.dialogRef.close({ event: this.action, data: this.local_data });
            this.dialogRef.close({
              //food: this.food
              respCode : this.roleForm.value.respCode,
              message : this.roleForm.value.message
            });
        
          }
          closeDialog() {
            this.dialogRef.close({ event: 'Cancel' });
          }

}
