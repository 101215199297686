import { Component, OnInit, ViewChild } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { PurchaseOrderInterface } from './purchase-order';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSort } from '@angular/material/sort';
// import { error } from 'console';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CommonServicesService } from '../services/common-services.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2';
import { MyModalComponent } from '../my-modal/my-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {browserRefresh} from '../app.component';

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.css'],
  animations: [
    trigger('detailExpand', [
      state('single-view', style({ height: '0px', minHeight: '0' })),
      state('drilled', style({ height: '*' })),
      transition(
        'drilled <=> single-view',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class PurchaseOrderComponent implements OnInit {
  expandedElement: PurchaseOrderInterface | null | undefined;
  mySpinner: boolean = false;
  showOverlay:boolean;
  language:any;
  searchString:any='';
  constructor(private dialog: MatDialog,private router:Router,public _snackBar: MatSnackBar,private cService: CommonServicesService, private http:HttpClient) { }
  dataSource:MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  currentUser:any=[];
  browserRefresh:boolean = false;
  listData:any = [];
  ngOnInit(): void {
    
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
		
		if(lang == 'en'){

			this.language = {
        "companyName":"Company Name",
			  "poNumber":"PO number",
			  "poItem":"PO Item",
        "plantName":"Plant Name",
        "plantCode":"Plant Code",
        "materialNo":"Material No",
			  "materialGroup":"Material Group",
			  "shortText":"Short Text",
			  "scheduledQuantity":"PO Qty",
        "unit":"unit",
        "deliverydate":"deliverydate",
			  "grQuantity":"Received Qty",
			  "outStandingQuantity":"Pending Qty",
			  "netPrice": "Unit Price",
			  "grossValue": "Item Amount",
        "invoicedAmt":"GR Amount",
        "totalAmount":"Total Amount",
        "actions":"ACTIONS",
        "search":"Search",
        "Head":"Purchase Order Pending",
        "svItem":"SV Item"
			}; 

		  }else if(lang == 'th'){

			this.language = {
        "companyName":"ชื่อบริษัท",
        "plantName":"โรงงาน/สำนักงาน",
        "plantCode":"รหัสโรงงาน/สำนักงาน",
        "materialNo":"รหัสสินค้า",
        "unit":"หน่วย",
        "deliverydate":"วันที่ส่งมอบ",
			  "poNumber":"เลขที่ใบสั่งซื้อ",
			  "poItem":"รายการที่",
			  "materialGroup":"กลุ่มสินค้า/บริการ",
			  "shortText":"ชื่อรายการ",
			  "scheduledQuantity":"จำนวนที่สั่งซื้อ",
			  "grQuantity":"จำนวนที่ทำรับแล้ว",
			  "outStandingQuantity":"จำนวนที่รอดำเนินการ",
			  "netPrice": "ราคาต่อหน่วย",
			  "grossValue": "จำนวนเงิน",
        "invoicedAmt":"จำนวน GR",
        "totalAmount":"จำนวนเงินรวม",
        "actions":"การดำเนินการ",
        "search":"ค้นหา",
        "Head":"ใบสั่งซื้อที่รอดำเนินการ",
        "svItem":"รายการเซอร์วิส"
			};
      }
      if(localStorage.getItem("searchData") != null){
        this.searchString = localStorage.getItem("searchData")
      }
      
      if(browserRefresh == true){
        this.searchString = '';
        localStorage.setItem("searchData","")
        localStorage.setItem("tableData",JSON.stringify([]))
      }
      let tableData:any = [];
      if(localStorage.getItem("tableData")){
        tableData = JSON.parse(localStorage.getItem("tableData"));
        this.listData = tableData;
      
      }
      
      if(tableData.length == 0){
        this.getPurchaseOrderList(null, this.searchString);
      }else{
        this.dataSource = tableData.response;
        this.listLength = tableData.pagination.count;
        localStorage.setItem("tableData",JSON.stringify([]))
      }
  }
 
  // columnsToDisplay = ["poNumber","poCreatedDate", "scheduledQuantity", "grossValue","poDeliveryDate", "headerTextDeliveryTo",'actions'];

  // columnsToDisplay1 = ["poNumber","poCreatedDate", "scheduledQuantity", "netValue","poDeliveryDate", "headerTextDeliveryTo",'actions'];
  columnsToDisplay = ['actions',"companyName","poNumber","poItem","svLineItem","plant","plantName","material","shortText", "scheduledQuantity","outStandingUnit","poDeliveryDate","grQuantity","outStandingQuantity","netPrice","grossValue","invoicedAmt","totalAmount"];
  onInfo(element: any){
    localStorage.setItem("tableData",JSON.stringify(this.listData));
    this.router.navigate(["/purchase-order/po-info",element.poNumber,'pending']);
  }
  pageIndex = 0;
  pageSize = 10;
  listLength: any;
  newDatasource:any=[];
  async getPurchaseOrderList(event: PageEvent, searchString:any) {
    this.mySpinner = true;
    // this.showOverlay = true;
    if (event == null) {
      this.pageIndex = 0;
      this.pageSize = 10;
    } else {
      if(event.pageIndex == 0){
        this.pageIndex = 0;
        this.pageSize = event.pageSize;
      }else{
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      }
      
    }
    if(this.currentUser.roleID == 2){
      (await this.cService.getPurchaseOrderList(this.currentUser.vendorId, this.pageIndex, this.pageSize, this.searchString)).pipe(first()).subscribe(res => {
        if (res.responseStatus.toUpperCase() == "SUCCESS") {
          
          this.dataSource = new MatTableDataSource(res.response);
          this.listLength = res.pagination.count;
          this.dataSource.sort = this.sort;
          this.listData = res;
          // let obj = {
          //   "totalAmount": ETV,
          //   "id": element.id
          // }
          // lineItems.push(obj);
          this.mySpinner = false;
        } else {
          this.dataSource = new MatTableDataSource([]);
          this.listData = [];
          // this.showOverlay=false;
          this.mySpinner = false;
          swal.fire({ 
            icon:'error',
            text: res.responseMessage
          });
        }
      }, error => {
        // this.showOverlay=false;
        this.mySpinner = false;
      })
    }else{
      (await this.cService.getPurchaseOrderListOtherUser(this.pageIndex, this.pageSize, this.searchString)).pipe(first()).subscribe(res => {
        if (res.responseStatus.toUpperCase() == "SUCCESS") {
          // this.showOverlay=false;
          
          this.dataSource = new MatTableDataSource(res.response);
          this.listLength = res.pagination.count;
          this.dataSource.sort = this.sort;
          this.mySpinner = false;
        } else {
          // this.showOverlay=false;
          this.mySpinner = false;
          this.dataSource = new MatTableDataSource([]);
          this.listData = [];
          this.listLength = 0;
          swal.fire({ 
            icon:'error',
            text: res.responseMessage
          }); 
        }
      }, error => {
        // this.showOverlay=false;
        
        this.mySpinner = false;
      })
    }
    


  }
 
  applyFilter1(){
    const filterValue = this.searchString;
    localStorage.setItem("searchData", this.searchString);
    // const filterValue = (event.target as HTMLInputElement).value;
    // const filterValue = (event.target as HTMLInputElement).value;//this.searchString;
    if(this.searchString == "" ){
      this.getPurchaseOrderList(null, this.searchString);
    }
  }
  applyFilter() {
    // 
    const filterValue = this.searchString;
    // const filterValue = (event.target as HTMLInputElement).value;
    // const filterValue = (event.target as HTMLInputElement).value;//this.searchString;
    if(filterValue.length >= 2 || this.searchString == "" ){
      this.getPurchaseOrderList(null, this.searchString);
    }
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    
  }
}
