        <mat-card>
            <mat-card-title>
                <div fxLayout="row" fxFlex.gt-xs="100" fxFlex="100">
                    {{headerToDisplay.userManagement}}
                </div>
                <!-- <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
                    <button mat-raised-button class="submitButton" (click)="openDialog('Add',{})">Add Vendor</button>
                </div> -->
            </mat-card-title>
            <hr>
            <mat-card-content>
                <div fxLayout="row" fxLayoutWrap="wrap">
                    <!-- Card column -->
                    <div fxFlex.gt-sm="100%" fxFlex="100">
                        <!-- <mat-card> -->
                            <!-- <div class="row mx-2 mt-2 my-2 summaryTab mat-elevation-z8  mx-3 mt-3 full-width"> -->
                        
                                <div fxLayout="row"fxLayout.xs="column">
                                    <div class="search" fxFlex="30" fxFlex.sm="50" fxFlex.xs="100">
                                        <mat-form-field class="mx-3 max-width">
                                         <mat-label>{{headerToDisplay.search}}</mat-label>
                                          <input matInput placeholder="Search" (keyup)="applyFilter1()" #input [(ngModel)]="searchString">
                                       </mat-form-field>
                                       </div>
                                        
                                       <div fxFlex="20" fxFlex.sm="50" fxFlex.xs="100" style="margin-top:15px">
                                            <button (click)="applyFilter()" mat-raised-button class="submitButton btn-primary">{{headerToDisplay.search}}</button>
                                       </div>
                                    <div fxFlex.gt-lg="60" fxFlex.gt-md="40" fxFlex.gt-xs="40" fxFlex="100"></div>
                                    <div fxFlex="22" fxFlex.sm="50" fxFlex.xs="100" style="margin-top:15px">
                                        <a  style="cursor:pointer;margin-left:155px" (click)="exportexcel('')"><img src="assets/images/icons8-export-excel-100.png" height="50px" width="50px" ></a> 
                                   </div>
                                    <!-- <div fxFlex.gt-lg="40" fxFlex.gt-sm="100" fxFlex.gt-md="90" fxFlex.gt-xs="100" fxFlex="100">
                                        <div fxFlex.gt-sm="33" fxFlex="100">
                                            <div class="custom-file width-80" style="margin-top: 30px;">
                                              <form method="post" enctype="multipart/form-data">
                                                <label for="upfile" class="custom-file-input">
                                                    <input  #myInput id="upfile" type="file" (change)="upload($event)" accept=".csv,.xlsx" 
                                                  class="custom-file-input" aria-describedby="inputGroupFileAddon01" />
                                                </label>
                                              </form>
                                            </div>
                                        </div>
                                        <div fxFlex.gt-lg="70" fxFlex.gt-sm="70" fxFlex.gt-md="70" fxFlex.gt-xs="100" fxFlex="100">
                                            <div class="custom-file width-80" style="margin-top: 30px;min-width: 212px;">
                                                <a href="../../assets/support-doc/ven-active-sample.csv" 
                                                    download="ven-active-sample.csv">
                                                    <mat-icon style="font-size: 16px;"  >download</mat-icon>                                                    
                                                    ven-active-sample.csv
                                                </a>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="overflow">
                            <mat-card>
                              
                                    <mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="mat-elevation-z5 main-table vendorListTable">
                                        <ng-container matColumnDef="vendorCode">
                                            <mat-header-cell *matHeaderCellDef  class="vendorCode-w" mat-sort-header> {{headerToDisplay.vendorCode}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="vendorCode-w" data-label = "Vendor Code"> {{element.vendorCode}} </mat-cell>
                                        </ng-container>
                                    
                                        <ng-container matColumnDef="companyCode">
                                            <mat-header-cell *matHeaderCellDef  class="companyCode-w" mat-sort-header> {{headerToDisplay.companyCode}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="companyCode-w" data-label="Company Code"> {{element.companyCode}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="vendorName">
                                                <mat-header-cell *matHeaderCellDef class="invoiceDate-w" mat-sort-header> {{headerToDisplay.vendorName}} </mat-header-cell>
                                                <mat-cell *matCellDef="let element" class="invoiceDate-w" data-label="VENDOR NAME" > {{(element.nameOne == null?" ":element.nameOne) + (element.nameTwo == null?" ":element.nameTwo)}} </mat-cell>
                                            </ng-container>
                                        <ng-container matColumnDef="vendorGroup">
                                            <mat-header-cell *matHeaderCellDef class="vendorGroup-w" mat-sort-header> {{headerToDisplay.vendorGroup}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="vendorGroup-w" data-label="Vendor Group"> {{element.vendorGroup}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="currency">
                                            <mat-header-cell *matHeaderCellDef class="currency-w" mat-sort-header> {{headerToDisplay.currency}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="currency-w"  data-label="Currency"> {{element.currency}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="city">
                                            <mat-header-cell *matHeaderCellDef class="city-w"  mat-sort-header> {{headerToDisplay.city}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element"  class="city-w"  data-label="City"> {{element.city}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="country">
                                            <mat-header-cell *matHeaderCellDef class="country-w" mat-sort-header> {{headerToDisplay.country}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="country-w" data-label="Country"> {{element.country}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="isActive">
                                            <mat-header-cell *matHeaderCellDef class="active-w" mat-sort-header> {{headerToDisplay.isActive}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="active-w" [ngClass]="changeClass(element.isActive)" data-label="Access"> {{element.isActive == "Y" ? 'Yes' : 'No'}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="isBlocked">
                                            <mat-header-cell *matHeaderCellDef class="status1-user-w" mat-sort-header> {{headerToDisplay.isBlocked}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="status1-user-w" [ngClass]="calculateClass(element.isBlocked)" data-label="Status"> {{element.isBlocked == 0 ? 'Active' : 'Blocked'}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="actions">
                                            <mat-header-cell *matHeaderCellDef  class="actions-w actions-align"> {{headerToDisplay.actions}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="actions-w" data-label="Actions">
                                    
                                          <!-- <mat-icon class="edit" aria-label="edit" (click)="openDialog('Update',element)">edit</mat-icon> -->
                                         <!-- <mat-icon class="block" aria-label="block" (click)="openConfirm(element)">block</mat-icon> -->
                                         <!-- <mat-icon class="block" aria-label="lock" *ngIf="element.isBlocked == 1" (click)="openConfirm(element)">lock</mat-icon>
                                        <mat-icon class="block" aria-label="lock_open" *ngIf="element.isBlocked == 0 || element.isBlocked == null " (click)="openConfirm(element)">lock_open</mat-icon> -->
                                        <!-- <mat-icon class="block" aria-label="lock" *ngIf="element.isBlocked == 1" (click)="openConfirm(element)">lock</mat-icon> -->
                                        <mat-icon class="block" aria-label="lock" *ngIf="element.isBlocked !== 0" (click)="openConfirm(element)">lock</mat-icon>
                                        <mat-icon class="block" aria-label="lock_open" *ngIf="element.isBlocked == 0" (click)="openConfirm(element)">lock_open</mat-icon>
                                          <mat-icon aria-hidden="false"  class="visibility" aria-label="visibility" (click)="openView(element)">visibility</mat-icon>
                                            </mat-cell>
                                        </ng-container>
                                        <mat-header-row vendorRow *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                                        <mat-row vendorRow *matRowDef="let element; columns: columnsToDisplay;" ></mat-row>
                                    </mat-table>
                                
                               
                            </mat-card>
                            
                        </div>  
                        <mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" [pageSize]="pageSize" [length]="listLength" [pageSizeOptions]="[5, 10, 20]" [pageSizeOptions]="[10, 25, 50, 100]" (page)="getVendors($event,'')"  style="width:100%"></mat-paginator> 
                    </div>
                </div>
            </mat-card-content>
            <div class="overlay"  *ngIf="mySpinner">
                <div class="center">
                    <mat-spinner ></mat-spinner> 
                </div>
            </div>
        </mat-card>