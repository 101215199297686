import { Injectable } from '@angular/core';
// import { NavigationService } from '../shared/navigation-new/navigation.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User } from './user/user.interface';
//import { CookieService } from 'ngx-cookie-service';
import swal from 'sweetalert2';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AdminServiceService } from 'src/app/services/admin-service.service';
// import {browserRefresh} from '../app.component';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  resetpassword = false;
  loggedIn = false;
  username = '';
  auth:any;
  // browserRefresh:boolean = false;
  constructor(private http: HttpClient, private router: Router,public _snackBar: MatSnackBar,private idle: Idle, private keepalive: Keepalive
  ,private adminS:AdminServiceService) {

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    ////
    this.currentUser = this.currentUserSubject.asObservable();
    if (localStorage.getItem('currentUser')) {
      // //
      this.loggedIn = true;
      this.auth = JSON.parse(localStorage.getItem('Auth')); 
    }
  }


  /*   constructor() {
      if(localStorage.getItem('currentUser')){
        this.loggedIn = true;
        let i :any = JSON.parse(localStorage.getItem('currentUser'));
        this.username = i.name;
        this.assignRoles();
  
      }
     } */

  /*    private assignRoles(){
      
     } */
  /* 
    login(user){
      this.username = user;
      localStorage.setItem('currentUser', JSON.stringify({ token: "hello", name: "nitin" }));
      this.assignRoles();
      this.loggedIn = true;
    } */

  /* logout(){
    localStorage.removeItem("currentUser");
    this.loggedIn = false;

  } */
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;



  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  menuList:any=[];
  menuAccess:any=[];
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  login(username: string, password: string) {
    //
    let loginData = {
      "username": username,
      "password": password
    }
    const headers = new HttpHeaders()
      .set("withCredentials", "true");
    
    return this.http.post<User>(`${environment.serverUrl}/authenticate`, loginData).pipe(map(user => {
      // debugger
      if (user["responseStatus"] == "SUCCESS") {
        
        
        let data: any = user["response"]; 
        
        
        this.menuList = data.menuList;
        this.menuAccess = data.menuAccess;
       

        //
        // this.cookie.set("Authorization", JSON.stringify(user.token));
        ////
        // login successful if there's a jwt token in the response
        
        if (user["responseStatus"].error) {
          return user["responseStatus"];
        }
        
        for (let i = 0; i <= data.userDetails.length - 1; i++) {
          //
          if (data.userDetails[i].email == username) {
            data = {
              "id": data.userDetails[i].id,
              "username": data.userDetails[i].username,
              // "name": data.userDetails[i].name,
              "firstName": data.userDetails[i].firstname,//"admin",
              "lastName": data.userDetails[i].lastname,//"admin",
              "fullName":data.userDetails[i].fullName,
              // "password": data.userDetails[i].password,
              "token": user.token,
              "email": data.userDetails[i].email,
              "contactNo":data.userDetails[i].contactNo,
              // "usertype": data.userDetails[i].usertype.toUpperCase(),
              "resetPasswordFlag": data.userDetails[i].resetPasswordFlag,
              "parentId": data.userDetails[i].parentId,
              // "roles": data.userDetails[i].usertype,
              "roleID": data.userDetails[i].roleID,
              "isactive": data.userDetails[i].isactive,
              "isBlocked":data.userDetails[i].isBlocked

            };

          }

          if(data.userDetails[i].firstTimeLogin == 1){
            
            this._snackBar.open('Logged in successfully', 'Ok', {
              duration: 5000,
            });
          }
          
        }

        ////
        //   //
        if (user && user.token) {
          //
          let userDetailsData = {
            pagination: user["pagination"],
            response: user["response"].userDetails,
            responseCode: user["responseCode"],
            responseMessage: user["responseMessage"],
            responseStatus: user["responseStatus"],
            token: user.token,
  
          }
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(userDetailsData));
          localStorage.setItem('menuList', JSON.stringify(this.menuList));
          localStorage.setItem('menuAccess', JSON.stringify(this.menuAccess));
          localStorage.setItem("Auth",JSON.stringify(user));
          localStorage.setItem("refreshToken",user["refreshToken"]);
          localStorage.setItem("token",user.token);
          localStorage.setItem("idleTime",user["idleTime"]);
          localStorage.setItem("refreshInterval",user["refreshInterval"]);
          this.loggedIn = true;

          if (user["response"].userDetails.consentFlag == false && user["response"].userDetails.firstTimeLogin == 0 && user["response"].userDetails.roleID == 2) {
            this.router.navigate(["/privacy-policy"]);
          }
          else if (user["response"].userDetails.firstTimeLogin == 0) {
            this.router.navigate(["/change-pwd"]);
          }
          else {
            this.router.navigate(["/dashboard"]);
          }
          this.currentUserSubject.next(user);
          let Crent = localStorage.getItem("currentUser");
          if(Crent){
          this.idle.setIdle(Number(2));
          
          this.idle.setTimeout(Number(user["idleTime"]));

          this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      
          this.idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
          this.idle.onTimeout.subscribe(() => {
            this.idleState = 'Timed out!';
          this.timedOut = true;
          swal.fire("Login Expired. Please login again", "", "error").then(()=> this.logout());
         });
          this.idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
          this.idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');
          this.keepalive.interval(15);
      
          this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
      
          this.reset();
        
        }
        
          let varr = setInterval(async ()=>{
           let refToken =  localStorage.getItem("refreshToken");
           
            let obj = 
            {
                "refreshToken": refToken
          }
          if(this.timedOut == true){
            clearInterval(varr)
          }
          let Val = localStorage.getItem("currentUser");
          if(Val){
           await  this.getRefreshToken(obj).pipe(first()).subscribe(res => {
              
              user["refreshToken"] = res.refreshToken;
              user["accessToken"] = res.accessToken;
              let vVal = JSON.parse(localStorage.getItem("currentUser"));
              let refVal = localStorage.getItem("refreshToken");
              vVal.token = res.accessToken;
              refVal = res.refreshToken;
              localStorage.setItem("currentUser",JSON.stringify(vVal));
              localStorage.setItem("refreshToken",refVal);
            })
          }              
          },Number(user["refreshInterval"]) * 1000) 

        }
        return data;

      } else {
        // this.showOverlay = false;
        // swal.fire(user["responseMessage"])
        swal.fire(user["responseMessage"], "", "error");
        return user;
      }
    }, error =>{
      swal.fire(error);
    }));
  }
  showOverlay=false;

  logout() {
    
    let user = JSON.parse(localStorage.getItem('currentUser'));
    let data = {
      "userId": user["response"].id,
      "type": "Auto"      
    }
    this.adminS.logout(data).pipe(first()).subscribe(data => {}, () => {});
    localStorage.removeItem("currentUser");
    localStorage.clear();
    this.router.navigate(["signin"]);
    this.idle.stop();
    this.idle.ngOnDestroy();
    history.pushState(null, null, document.URL);
    // remove user from local storage to log user out
    // localStorage.removeItem('currentUser');
    // localStorage.removeItem('navigation');
    // localStorage.removeItem('nviFilled');
    // // window.location.reload();
    // // this.timedOut = false;
    // // localStorage.clear();
    // // this.idle.stop();
    // // this.idle.ngOnDestroy();

    // this.currentUserSubject.next(null);
    // this.loggedIn = false;
    // // location.reload(true);
    // this.router.navigate(['/sign']);
  }

  forgotPwd(email) {
    return this.http.get<any>(`${environment.serverUrl}/forgotpwd/` + email).pipe(map(user => {
      //
      return user;
    }))
  }
  getUserDetails(claimId, claimtoken) {
    this.resetpassword = true;
    // Authorization: `Bearer ${currentUser.token}`,
    const headers = new HttpHeaders()
      .set("Authorization", 'Bearer ' + claimtoken);
    return this.http.get<any>(`${environment.serverUrl}/users/` + claimId, { headers }).pipe(map(user => {
      //
      return user.response
    }))
  }
  resetPwd(row_obj, userToken) {
    this.resetpassword = true;
    const headers = new HttpHeaders()
      .set("Authorization", 'Bearer ' + userToken);
    return this.http.put<any>(`${environment.serverUrl}/users/resetpwd`, row_obj, { headers }).pipe(map(user => {
      //
      return user["response"];
    }))
  }
  loginWithADFS(){
    window.location.href = `${environment.serverUrl}/saml/login`;
    // return this.http.get<any>(`${environment.serverUrl}/smal/login`).pipe(map(user => {
    //   //
    //   return user.response
    // }))
  }
  getRoles(){
    return this.http.get<any>(`${environment.serverUrl}/users/role`).pipe(map(user => {
      //
      return user
    }))
  }

  getRefreshToken(data:any){
    return this.http.post<any>(`${environment.serverUrl}/refresh_token`,data).pipe(map(user => {
      //
      return user
    }))
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
    }
}
