
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { APP_BASE_HREF, LocationStrategy, PathLocationStrategy, DatePipe } from '@angular/common';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { SigninComponent } from './signin/signin.component';
import { ChangePwdComponent } from './change-pwd/change-pwd.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { RoleManagementComponent } from './role-management/role-management.component';
import { NotificationDetailsComponent } from './notifications/notification-details/notification-details.component';
import { MyModalComponent } from './my-modal/my-modal.component';
import { ResponsiveTableComponent } from './responsive-table/responsive-table.component';
import { ChecklistDatabase, RoleModel } from './role-management/role-model/role-model';
import { ConfigModel } from './configuration/config-model/config-model';
import { ConfigurationComponent } from './configuration/configuration.component';
import { ResponseMessageComponent } from './response-messages/response-message.component';
import { ResponseModel } from './response-messages/response-model/response-model';

import { VendorModalComponent } from './vendor-management/vendor-modal/vendor-modal.component';
import { DlApproveComponent } from './delivery-notes/dl-approve/dl-approve.component';
import { CommonRejectComponent } from './common-reject/common-reject.component';
import { JwtInterceptor } from './interceptors/jwt-interceptor';
import { InvoiceUploadComponent } from './invoice/invoice-upload/invoice-upload.component';
// import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotModalComponent } from './forgot-modal/forgot-modal.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MatSortModule } from '@angular/material/sort';
import { WeighBridgeComponent } from './weigh-bridge/weigh-bridge.component';
import { DebitNoteComponent } from './debit-note/debit-note.component';
import { CreditNoteComponent } from './credit-note/credit-note.component';
import { MenuListItemComponent } from './layouts/full/menu-list-item/menu-list-item.component';
import { NavService } from './layouts/full/nav.service';
import { VendorInfoComponent } from './vendor-management/vendro-info/vendor-info.component';
import { VendorManagementComponent } from './vendor-management/vendor-management.component';
import { VendorListComponent } from './vendor-list/vendor-list.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuditRecordsComponent } from './audit-records/audit-records.component';
import { AuditModelComponent } from './audit-records/audit-model/audit-model.component';
import { TwoDigitDecimaNumberDirective } from './twodigitdecimalnumber.directive';
import { MatTreeModule } from '@angular/material/tree';
// import { AmountToWordPipe } from './amount-to-word.pipe';
import { CommonPipesModule } from './common-pipes.module';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ErrorInterceptorService } from './interceptors/error-interceptor.service';
import { BlockCopyPasteDirective } from './block-copy-paste.directive';
import { LoginComponent } from './login/login.component';
import { UserModalComponent } from 'src/app/user-management/user-modal/user-modal.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MasterRecordsComponent } from 'src/app/master-records/master-records.component';
import { ErrorLoginComponent } from 'src/app/error-login/error-login.component';
import { UserAuditLogComponent } from './user-audit-log/user-audit-log.component';
// import {APP_BASE_HREF} from '@angular/common';
//import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    SigninComponent,
    ChangePwdComponent,
    NotificationsComponent,
    UserManagementComponent,
    RoleManagementComponent,
    NotificationDetailsComponent,
    MyModalComponent,
    RoleModel,
    ConfigModel,
    ConfigurationComponent,
    ResponseMessageComponent,
    ResponseModel,
    VendorManagementComponent,
    VendorModalComponent,
    UserModalComponent,
    ResponsiveTableComponent,
    DlApproveComponent,
    CommonRejectComponent,
    InvoiceUploadComponent,
    VendorInfoComponent,
    ForgotPasswordComponent,
    ForgotModalComponent,
    ConfirmationDialogComponent,
    LandingPageComponent,
    WeighBridgeComponent,
    DebitNoteComponent,
    CreditNoteComponent,
    MenuListItemComponent,
    VendorListComponent,
    NotFoundComponent,
    AuditRecordsComponent,
    AuditModelComponent,
    PrivacyPolicyComponent,
    BlockCopyPasteDirective,
    LoginComponent,
    MasterRecordsComponent,
    ErrorLoginComponent,
    UserAuditLogComponent
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    MatSortModule,
    MatTreeModule,
    // AngularMultiSelectModule,
    RouterModule.forRoot(AppRoutes),
    NgMultiSelectDropDownModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    CommonPipesModule,
    HttpClientXsrfModule
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    {provide: APP_BASE_HREF, useValue: '/ui'},
    NavService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    ChecklistDatabase,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
