import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AdminServiceService } from 'src/app/services/admin-service.service';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.css']
})
export class UserModalComponent implements OnInit {
  sub: any;
  mySpinner: boolean = false;
  currentUser:any;
  language:any;
  routeFrom:any;
  userType: string | null | undefined;
  Source:any;
  constructor(private router: Router,private fb:FormBuilder, private route: ActivatedRoute, private cService: AdminServiceService) { }
    ngOnInit(): void {
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
        let currentUsers = this.currentUser.response;
            let lang = currentUsers.langPreference;
            
            if(lang == 'en'){
                this.language = {
                    "userInfo":"User Info",
                    "FirstName":	"First Name",
                    "LastName"	:"Last Name",
                    "Email":"Email",
                    "contactNo":	"Contact Number",
                    "RoleName":	"Role Name",
                    "companyAllowed":"Company Allowed",
                    "plantAllowed":"Plant Allowed",
                }
              }
              else if(lang == 'th'){
                this.language = {
                    "userInfo":"ข้อมูลผู้ใช้",
                    "FirstName":	"ชื่อจริง",
                    "LastName"	:"นามสกุล",
                    "Email":"อีเมล",
                    "contactNo":	"เบอร์ติดต่อ",
                    "RoleName":	"ชื่อบทบาท",
                    "companyAllowed":"บริษัทอนุญาต",
                    "plantAllowed":"อนุญาติให้ปลูกพืชได้",
                }
              }
        this.sub = this.route.params.subscribe((params) => {
         //   
            this.routeFrom = params["id"];
            this.Source = params["name"]
            
             })
        this.getuserId(this.routeFrom);
        this.userType = localStorage.getItem("user");
        
        
    }
  userInfo : FormGroup= this.fb.group({
    FirstName : ['',[Validators.required]],
    LastName : ['',[Validators.required]],
    Email : ['',[Validators.required]],
    contactNo: ['', Validators.required],
    RoleName: ['', Validators.required],
    companyAllowed: ['', Validators.required],
    plantAllowed: ['', [Validators.required]],
})

RoleId:any;
getuserId(id:any){
  this.mySpinner = true;
 this.cService.getUserInfoById(id).pipe(first()).subscribe(res =>{
     
     let result=res;
     this.RoleId = res.response.roleID;
     if(res.responseStatus.toUpperCase() == "SUCCESS"){
         this.userInfo.controls.FirstName.setValue(res.response.firstName);
         this.userInfo.controls.LastName.setValue(res.response.lastName);
         this.userInfo.controls.Email.setValue(res.response.email);
         this.userInfo.controls.contactNo.setValue(res.response.contactNo);
         this.userInfo.controls.RoleName.setValue(res.response.roleName);
         this.userInfo.controls.companyAllowed.setValue(res.response.companyAllowed);
         this.userInfo.controls.plantAllowed.setValue(res.response.companyAllowed);
         this.mySpinner = false;
     }else{
       // this.showOverlay=false;
     }
   },error =>{
     this.mySpinner = false;
     // this.showOverlay=false;
   })
}
goTo(){
  this.router.navigate(["user-management"]);
}
}
