<mat-card>
    <mat-card-content>
        <mat-card-title>Credit Notes</mat-card-title>
        <div fxLayout="row" fxLayoutWrap="wrap">

            <div fxFlex.gt-sm="100%" fxFlex="100">

                <div class="row mx-2 my-2">
                    <div>

                        <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-form-field class="mx-3 max-width">
                                <mat-label>Search</mat-label>
                                <input matInput placeholder="Search" #input (keypress)="applyFilter($event)">
                            </mat-form-field>
                        </div>
                    </div>
                    <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows
                        class=" full-width mat-elevation-z5 main-table">
                        <ng-container matColumnDef="invoiceNo">
                            <th mat-header-cell *matHeaderCellDef class="min-w" mat-sort-header> Invoice No
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.invoiceNo}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="documentNo">
                            <th mat-header-cell *matHeaderCellDef class="min-w" mat-sort-header>
                                Document No </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.documentNo}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="creditNoteRefNo">
                            <th mat-header-cell *matHeaderCellDef class="min-w" mat-sort-header>
                                Credit Note Ref No </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.creditNoteRefNo}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef class="min-w"> ACTIONS
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="button-group my-2 float-right">
                                    <mat-icon aria-hidden="false" class="visibility" aria-label="visibility">archive
                                    </mat-icon>
                                </div>
                            </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="columnsToDisplay">
                        </tr>
                        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
                            [class.example-expanded-row]="expandedElement === element"
                            (click)="expandedElement = expandedElement === element ? null : element">
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                </div>

            </div>
        </div>
    </mat-card-content>
</mat-card>