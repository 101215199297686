<mat-card>
    <mat-card-title>
        <div fxLayout="row" fxFlex.gt-xs="100" fxFlex="100">
            {{language.head}}
        </div>
    </mat-card-title>
    <hr>
    <mat-card-content>
        <div fxLayout="row" fxLayoutWrap="wrap">
            <div fxFlex.gt-sm="100%" fxFlex="100">
                <div fxLayout="row" fxLayout.xs="column">
                    <div class="search" fxFlex="30" fxFlex.sm="50" fxFlex.xs="100">
                        <mat-form-field class="mx-3 max-width">
                            <mat-label>{{language.search}}</mat-label>
                            <input matInput placeholder="Search" (keyup)="applyFilter1()" #input
                                [(ngModel)]="searchString">
                        </mat-form-field>
                    </div>
                    <div fxFlex="20" fxFlex.sm="50" fxFlex.xs="100" style="margin-top:15px">
                        <button (click)="applyFilter()" mat-raised-button
                            class="submitButton btn-primary">{{language.search}}</button>
                    </div>
                    <!-- <div class="addUserParent" fxFlex="70" fxFlex.sm="50" fxFlex.xs="100" *ngIf="currentUser.roleID == '3' || currentUser.roleID == '7'">
                                        <button mat-raised-button class="submitButton addUser" (click)="logSelection()">
                                            Bulk Approval
                                        </button>
                                    </div> -->
                    <div fxFlex="30" fxFlex.sm="50" fxFlex.xs="100"></div>
                    <div fxFlex="30" fxFlex.sm="50" fxFlex.xs="100"
                        *ngIf="currentUser.roleID == '2' || currentUser.roleID == '3'|| currentUser.roleID == '7'"
                        class="exportIcon">
                        <a style="cursor:pointer;" (click)="exportexcel('')"><img
                                src="assets/images/icons8-export-excel-100.png" height="50px" width="50px"></a>
                    </div>
                </div>
                <div class="overflow">
                    <mat-card>
                        <mat-table [dataSource]="dataSource" matSort multiTemplateDataRows
                            [ngClass]="currentUser.roleID == '2'?'weighBridgeTable':'weighBridgeTable2'"
                            style="width: fit-content;">
                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef style="height:56px">
                                    <mat-checkbox style="margin-top:15px;" (change)="$event ? masterToggle() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                                    </mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(element) : null"
                                        [checked]="selection.isSelected(element)">
                                    </mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="vendorCode">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="vendor-wb">
                                    {{language.vendorCode}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Vendor :" class="vendor-wb">
                                    {{element.lifnr}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="vendorName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="name1-wb">
                                    {{language.vendorName}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Name 1 :" class="name1-wb">
                                    {{element.name1}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="weighTicketNo">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="weighTicketNo-wb">
                                    {{language.weightTicketNumber}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Weight Ticket Number :"
                                    class="weighTicketNo-wb">
                                    {{element.weighTicketNo}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="truckNo">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="truckNo-wb">
                                    {{language.truckPlateNumber}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Truck Plate Number :"
                                    class="truckNo-wb">
                                    {{element.truckNo}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="shipmentNo">
                                <mat-header-cell *matHeaderCellDef mat-sort-header
                                    class="shipmentNo-wb">{{language.shipmentNumber}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Shipment Number :"
                                    class="shipmentNo-wb">
                                    {{element.shipmentNo}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="weightType">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="weightType-wb">
                                    {{language.weightType}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Weight Type :" class="weightType-wb">
                                    {{element.weightType}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="poNumber">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="poNumber-wb">
                                    {{language.purchasingDocument}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Purchasing Document :"
                                    class="poNumber-wb">
                                    {{element.poNumber}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="itemNo">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="table-header poItem-w">
                                    {{language.item}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Item :" class="poItem-w">
                                    {{element.itemNo}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="bldat">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="actions-w">
                                    {{language.documentDate}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Document Date :" class="actions-w">
                                    {{element.bldat.includes("-")?(element.bldat | date:"dd/MM/yyyy") :
                                    (element.bldat.substring(6,8) + "/"+ element.bldat.substring(4,6) + "/" +
                                    element.bldat.substring(0,4)) }}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="vendoNo">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="docDate-wb">
                                    {{language.vendoNo}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Document Date :" class="docDate-wb">
                                    {{element.vendoNo }}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="checkInDate">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="docDate-wb">
                                    {{language.checkInDate}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Document Date :" class="docDate-wb">
                                    {{element.checkInDate.includes("-")?(element.checkInDate | date:"dd/MM/yyyy") :
                                    (element.checkInDate.substring(6,8) + "/" + element.checkInDate.substring(4,6) + "/"
                                    + element.checkInDate.substring(0,4)) }}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="convertedNetWeight">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="totalQty-w">
                                    {{language.convertedNetWeight}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Document Date :" class="totalQty-w">
                                    {{element.convertedNetWeight | number : '1.3-3' }}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="diffWeight">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="totalQty-w">
                                    {{language.diffWeight}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Document Date :" class="totalQty-w">
                                    {{element.diffWeight | number : '1.3-3'}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="matnr">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="material-wb">
                                    {{language.material}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Material :" class="material-wb">
                                    {{element.matnr}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="maktx">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="materialDesc-wb">
                                    {{language.materialDescription}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Material Description :"
                                    class="materialDesc-wb">
                                    {{element.maktx}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="weightUnit">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="weightUnit-wb">
                                    {{language.unit}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Unit :" class="weightUnit-wb">
                                    {{element.weightUnit}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="weightInQty">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="weightInQty-wb">
                                    {{language.weightInQuantity}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Weight In Quantity :"
                                    class="weightInQty-wb">
                                    {{element.weightInQty | number : '1.3-3'}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="vendorNetWeight">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="totalQty-w">
                                    {{language.vendorNetWeight}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Vendor Net Weight :" class="totalQty-w">
                                    {{element.convertedVendorNetWeight | number : '1.3-3'}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="weightOutQty">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="weightOutQty-wb">
                                    {{language.WeightOutQuantity}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Weight Out Quantity :"
                                    class="weightOutQty-wb">
                                    {{element.weightOutQty | number : '1.3-3'}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="mblnr">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="materialDoc-wb">
                                    {{language.materialDocument}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Material Document :"
                                    class="materialDoc-wb">
                                    {{element.mblnr}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="mjahr">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="materialDocYear-wb">
                                    {{language.materialDocYear}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Material Doc. Year :"
                                    class="materialDocYear-wb">
                                    {{element.mjahr}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="supplyPlant">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="supplyPlant-wb">
                                    {{language.supplyingPlant}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Supplying Plant :"
                                    class="supplyPlant-wb">
                                    {{element.supplyPlant}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="supplyPtorage">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="supplyPtorage-wb">
                                    {{language.supplyingStorage}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Supplying Storage :"
                                    class="supplyPtorage-wb">
                                    {{element.supplyPtorage}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="recvpoPlant">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="recvpoPlant-wb">
                                    {{language.receivingPlantInPO}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Receiving Plant in PO :"
                                    class="recvpoPlant-wb">
                                    {{element.recvpoPlant}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="recvpoStorage">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="recvpoStorage-wb">
                                    {{language.receivingStorageInPO}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Receiving Storage in PO :"
                                    class="recvpoStorage-wb">
                                    {{element.recvpoStorage}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="destinationPlant">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="destinationPlant-wb">
                                    {{language.DestinationPlant}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Destination Plant :"
                                    class="destinationPlant-wb">
                                    {{element.destinationPlant}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="destinationStorage">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="destinationStorage-wb">
                                    {{language.DestinationStorage}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Destination Storage :"
                                    class="destinationStorage-wb">
                                    {{element.destinationStorage}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="menge">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="requiredQty-wb">
                                    {{language.requiredQuantity}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Required Quantity :"
                                    class="requiredQty-wb">
                                    {{element.menge | number : '1.3-3'}}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="meins">
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="buom-wb">
                                    {{language.baseUnitOfMeasure}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Base Unit of Measure :" class="buom-wb">
                                    {{element.meins}}
                                </mat-cell>
                            </ng-container>
                            <!-- <ng-container matColumnDef="actions">
                                    <mat-header-cell *matHeaderCellDef class="actions-w actions-align"> ACTIONS
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="ACTIONS :" class="actions-w">
                                            <mat-icon (click)="onInfo(element)" aria-hidden="false" class="visibility"
                                                aria-label="visibility">visibility</mat-icon>
                                    </mat-cell>
                                </ng-container> -->
                            <mat-header-row *matHeaderRowDef="columnsToDisplay">
                            </mat-header-row>
                            <mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
                                [class.example-expanded-row]="expandedElement === element"
                                (click)="expandedElement = expandedElement === element ? null : element">
                            </mat-row>
                        </mat-table>
                    </mat-card>
                </div>
                <mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" [pageSize]="pageSize"
                    [length]="listLength" [pageSizeOptions]="[5, 10, 20]" [pageSizeOptions]="[10, 25, 50, 100]"
                    (page)="getWeighBridge($event, '')" style="width:100%"></mat-paginator>
            </div>
        </div>
        <div class="overlay" *ngIf="mySpinner">
            <div class="center">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </mat-card-content>
</mat-card>