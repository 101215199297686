import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { PurchaseOrderInterface } from 'src/app/purchase-order/purchase-order';
import { CommonServicesService } from 'src/app/services/common-services.service';
import { InvoiceUploadComponent } from '../invoice-upload/invoice-upload.component';
import { SelectionModel } from '@angular/cdk/collections';
import Swal from 'sweetalert2';
import {browserRefresh} from '../../app.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css']
})
export class InvoiceListComponent implements OnInit {
  mySpinner:boolean = false;
  showOverlay: boolean;
  expandedElement: PurchaseOrderInterface | null | undefined;
  searchString:any='';
  constructor(private router:Router,private dialog:MatDialog,public _snackBar: MatSnackBar,private cService: CommonServicesService,
  private route:ActivatedRoute,private location: Location) { }
  userType:any;
  dataSource:MatTableDataSource<any>;
  currentUser:any;
  columnsToDisplay:any=[];
  language:any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  selection = new SelectionModel<any>(true, []);
  serchTrue:boolean = false;
  browserRefresh:boolean = false;
  listData:any = [];
  search:any;
  ngOnInit(): void {
    this.userType = localStorage.getItem("user");
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
		

    let states;
    states = this.location.getState();
    

		if(lang == 'en'){
			this.language = {
        "typeOfInvoices":"Type Of Invoice",
			  "invoiceNumberByVendor":"Invoice NO",
        "invoiceNumberByVp":"VP No",
			  "invoiceDate":"Invoice Date",
        "TaxNo":"Tax No",
        "paymentMethod":"Payment Methods",
        "vendorName":"Vendor Name",
        "vendorCode":"Vendor Code",
			  "typeOfInvoice":"Invoice Type",
			  "totalQuantity":"Total Qty.",
			  "amount":"Total Amt.",
			  "taxType":"Tax Type",
        "vatAmount":"Vat Amt.",
        "poNumber":"PO No",
        "companyCode":"Company Code",
        "apDoc":"Ap Doc",
        'postingDate':"Posting Date",
        'netDueDate':"Net Due Date",
        'paymentDoc':"Payment Doc",
        'paymentDate':"Payment Date",
        "SAPResponse":"SAP Response",
        "status":"STATUS",
        "actions":"ACTIONS",
        "search":"Search",
        "button":"Add Invoice",
        "sesNo":"SES Number",
        "Head":" Invoice Pending List",
        "MatDoc":"Material Document",
        "ApcheckerDate":"AP Checker Accept Date",
			}; 

		  }else if(lang == 'th'){
			this.language = {
        "typeOfInvoices":"ชนิดของใบแจ้งหนี้",
        "invoiceNumberByVendor":"หมายเลขใบแจ้งหนี้",
        "invoiceNumberByVp":"หมายเลขพอร์ทัลผู้ขาย",
			  "invoiceDate":"วันที่ออกใบแจ้งหนี้",
        "TaxNo":"หมายเลขภาษี",
        "paymentMethod":"วิธีการชำระเงิน",
        "vendorName":"ชื่อผู้จำหน่าย",
        "vendorCode":"รหัสผู้ขาย",
			  "typeOfInvoice":"ประเภทใบแจ้งหนี้",
			  "totalQuantity":"จำนวนรวม",
			  "amount":"จำนวนรวม",
			  "taxType":"ประเภทภาษี",
        "vatAmount":"ภาษีมูลค่าเพิ่ม",
        "poNumber":"เลขที่ใบสั่งซื้อ",
        "apDoc":"เอกสาร Ap",
        'postingDate':"วันที่ลงรายการบัญชี",
        'netDueDate':"วันที่ครบกําหนดสุทธิ",
        'paymentDoc':"เอกสารการชําระเงิน",
        'paymentDate':"วันที่ชําระเงิน",
        "SAPResponse":"การตอบสนองของ SAP",
        "status":"สถานะ",
        "actions":"การดำเนินการ",
        "search":"ค้นหา",
        "button":"เพิ่มใบแจ้งหนี้",
        "sesNo":"หมายเลข SES",
        "Head":" รายการรอดำเนินการในใบแจ้งหนี้",
        "companyCode":"รหัส บริษัท",
        "MatDoc":"เอกสารวัสดุ",
        "ApcheckerDate":"AP Checker ยอมรับวันที่",
			};
		  }
    if(this.currentUser.roleID == 2){
      this.columnsToDisplay = ['invStatus','actions','isTaxInvoice',"paymentMethods",'invoiceNumberByVp','invoiceDate','invoiceNumberByVendor',"amount",'poNumber'];
    }else if(this.currentUser.roleID == 5 || this.currentUser.roleID == 8){
      this.columnsToDisplay = ['invStatus','actions','companyCode','isTaxInvoice','vendorCode','vendorName',"paymentMethods","grnSrnNumber",'sesNumber','invoiceNumberByVp','invoiceDate','invoiceNumberByVendor',"apCheckerAcceptedDate","amount",'poNumber','sapResponse'];
      //this.columnsToDisplay =['status','actions','typeOfInvoice','vendorCode','vendorName','paymentMethod','VP#','invoiceDate','invoiceNumber','toatlAmountInDoc','poNumber']
    }else if(this.currentUser.roleID == 4){
      this.columnsToDisplay = ['invStatus','actions','companyCode','isTaxInvoice','vendorCode','vendorName',"paymentMethods","grnSrnNumber",'sesNumber','invoiceNumberByVp','invoiceDate','invoiceNumberByVendor',"amount",'poNumber','sapResponse'];
    }else{
      this.columnsToDisplay = ['invStatus','actions','isTaxInvoice','vendorCode','vendorName',"paymentMethods","sesNumber",'invoiceNumberByVp','invoiceDate','invoiceNumberByVendor',"amount",'poNumber','sapResponse'];
    }

    if(localStorage.getItem("searchData") != null){
      this.searchString = localStorage.getItem("searchData")
    }
    
    if(browserRefresh == true){
      this.searchString = '';
      localStorage.setItem("searchData","")
      localStorage.setItem("tableData",JSON.stringify([]))
    }
    let tableData:any = [];
    if(localStorage.getItem("tableData")){
      tableData = JSON.parse(localStorage.getItem("tableData"));
      this.listData = tableData;
    
    }
    
    if(tableData.length == 0){
      this.searchString = states.search?states.search:"";
      localStorage.setItem("searchData",states.search?states.search:"")
      this.getInvoiceList(null, this.searchString);
    }else{
      this.dataSource = tableData.response;
      this.listLength = tableData.pagination.count;
      localStorage.setItem("tableData",JSON.stringify([]))
    }
    // this.getInvoiceServiceList(null);
  }
  
  onInfo(element: any){
    localStorage.setItem("tableData",JSON.stringify(this.listData));
    this.router.navigate(["/invoice-list/invoice-details", element.id, "pending", element.typeOfInvoice])
  }
  onInfo1(element: PurchaseOrderInterface){
    this.router.navigate(["/invoice-list/invoice-history"])
  }
  
    uploadInvoice(){
    //   obj.action = _add;
    // let userid = obj.userID;
    const dialogRef = this.dialog.open(InvoiceUploadComponent, {
      width: '500px',
      data: ""
    });
    dialogRef.afterClosed().subscribe(result => {
      
      if(result != undefined){
        this.router.navigate(["invoice-list/view-invoice"]);
        // swal.fire("User creation successful!");
      }
      
    })
    }
    addInvoice(){
      this.router.navigate(["/invoice-list/add-invoice"])
    }
    onVendorInfo(element:any){
      this.router.navigate(["/invoice-list/vendor-info", element.vendorId, "pending"])
    }
    onEdit(element:any){
      
      this.router.navigate(["/invoice-list/edit-invoice", element.id, element.typeOfInvoice]);
    }
    onEditNew(element:any){
      
      this.router.navigate(["invoice-list/edit", element.id, element.typeOfInvoice]);
    }
    onEditChecker(element:any){
      
      this.router.navigate(["invoice-list/edit-inv-checker", element.id, element.typeOfInvoice]);
    }
    pageIndex = 0;
  pageSize = 10;
  listLength: any;
  
  async getInvoiceList(event: PageEvent, searchString:string) {
    this.mySpinner = true;
    // this.showOverlay = true;
    if (event == null) {
      this.pageIndex = 0;
      this.pageSize = 10;
    } else {
      // if(event.pageIndex == 0){
      //   this.pageIndex = 0;
      //   this.pageSize = event.pageSize;
      // }else{
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      // }
    }
// if(this.currentUser.roleID != 6){
  (await this.cService.getInvoiceList(this.currentUser.usertype, this.pageIndex, this.pageSize, this.searchString)).pipe(first()).subscribe(res => {
    if(res.response != null){
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        
        this.dataSource = new MatTableDataSource(res.response);
        this.listData = res;
        this.listLength = res.pagination.count;
        this.dataSource.sort = this.sort;
        this.mySpinner = false;
      } else {
       
        this.mySpinner = false;
      }
    }else{
      this.mySpinner = false;
      this.dataSource = new MatTableDataSource([]);
      this.listData = [];
      this.listLength = 0;
      Swal.fire({ 
        icon:'error',
        text: res.responseMessage
      }); 
    }
   
  }, error => {
    this.mySpinner = false;
    Swal.fire({ 
      icon:'error',
      text: error.error.error
    }); 
  })

    
// }else{
//   //, this.searchString
//   this.cService.getInvoiceServiceList(this.currentUser.usertype,this.pageIndex, this.pageSize).pipe(first()).subscribe(res => {
//     if (res.responseStatus.toUpperCase() == "SUCCESS") {
      
//       this.dataSource = new MatTableDataSource(res.response);
//       this.listLength = res.pagination.count;
//       this.dataSource.sort = this.sort;
//       this.mySpinner = false;
//     } else {
     
//       this.mySpinner = false;
//     }
//   }, error => {
    
//     this.mySpinner = false;
//   })
// }
  
  }

  async getInvoiceServiceList(event: PageEvent ) {
    this.mySpinner = true;
    // this.showOverlay = true;
    if (event == null) {
      this.pageIndex = 0;
      this.pageSize = 10;
    } else {
      // if(event.pageIndex == 0){
      //   this.pageIndex = 0;
      //   this.pageSize = event.pageSize;
      // }else{
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      // }
    }

    (await this.cService.getInvoiceServiceList(this.currentUser.usertype, this.pageIndex, this.pageSize)).pipe(first()).subscribe(res => {
      if(res.response != null) {
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        this.dataSource = new MatTableDataSource(res.response);
        this.listLength = res.pagination.count;
        this.listData = res;
        this.dataSource.sort = this.sort;
        this.mySpinner = false;
      } else {
        this.mySpinner = false;
        this.dataSource = new MatTableDataSource([]);
        this.listData = [];
        this.listLength = 0;
        // Swal.fire({ 
        //   icon:'error',
        //   text: res.responseMessage
        // }); 
      }
    }else{
      this.mySpinner = false;
      this.dataSource = new MatTableDataSource([]);
      this.listData = [];
      this.listLength = 0;
      Swal.fire({ 
        icon:'error',
        text: res.responseMessage
      }); 
    }
   
  }, error => {
    this.mySpinner = false;
    Swal.fire({ 
      icon:'error',
      text: error.error.errore
    }); 
  })
}

  calculateClass(status) {
    let val = true;
    if (status == "Pending") {
      val = false;
    }
    return {
      "color-green": val,
      "color-red": !val
    }
  }

  applyFilter1(){
    const filterValue = this.searchString;
    localStorage.setItem("searchData", this.searchString);
    this.serchTrue = false;
    if (this.serchTrue == false){
      if(this.currentUser.roleID == 2){
        this.columnsToDisplay = ['invStatus','actions','isTaxInvoice',"paymentMethods",'invoiceNumberByVp','invoiceDate','invoiceNumberByVendor',"amount",'poNumber'];
      }else if(this.currentUser.roleID == 5 || this.currentUser.roleID == 8){
        this.columnsToDisplay = ['invStatus','actions','companyCode','isTaxInvoice','vendorCode','vendorName',"paymentMethods","grnSrnNumber","sesNumber",'invoiceNumberByVp','invoiceDate','invoiceNumberByVendor',"apCheckerAcceptedDate","amount",'poNumber','sapResponse'];
        //this.columnsToDisplay =['status','actions','typeOfInvoice','vendorCode','vendorName','paymentMethod','VP#','invoiceDate','invoiceNumber','toatlAmountInDoc','poNumber']
      }else if(this.currentUser.roleID == 4){
        this.columnsToDisplay = ['invStatus','actions','companyCode','isTaxInvoice','vendorCode','vendorName',"paymentMethods","grnSrnNumber","sesNumber",'invoiceNumberByVp','invoiceDate','invoiceNumberByVendor',"amount",'poNumber','sapResponse'];
      }else{
        this.columnsToDisplay = ['invStatus','actions','isTaxInvoice','vendorCode','vendorName',"paymentMethods","sesNumber",'invoiceNumberByVp','invoiceDate','invoiceNumberByVendor',"amount",'poNumber','sapResponse'];
      }
    }
    // const filterValue = (event.target as HTMLInputElement).value;
    // const filterValue = (event.target as HTMLInputElement).value;//this.searchString;
    if(this.searchString == "" ){
      this.getInvoiceList(null, this.searchString);
    }
  }
  applyFilter() {
    // 
    const filterValue = this.searchString;
    this.serchTrue = true;
    if (this.serchTrue == true){
      if(this.currentUser.roleID == 2){
        this.columnsToDisplay = ['invStatus','actions','isTaxInvoice',"paymentMethods",'invoiceNumberByVp','invoiceDate','invoiceNumberByVendor',"amount",'poNumber'];
      }else if(this.currentUser.roleID == 5 || this.currentUser.roleID == 8){
        this.columnsToDisplay = ['invStatus','actions','companyCode','isTaxInvoice','vendorCode','vendorName',"paymentMethods","grnSrnNumber","sesNumber",'invoiceNumberByVp','invoiceDate','invoiceNumberByVendor',"apCheckerAcceptedDate","amount",'poNumber','sapResponse'];
        //this.columnsToDisplay =['status','actions','typeOfInvoice','vendorCode','vendorName','paymentMethod','VP#','invoiceDate','invoiceNumber','toatlAmountInDoc','poNumber']
      }else if(this.currentUser.roleID == 4){
        this.columnsToDisplay = ['invStatus','actions','companyCode','isTaxInvoice','vendorCode','vendorName',"paymentMethods","grnSrnNumber","sesNumber",'invoiceNumberByVp','invoiceDate','invoiceNumberByVendor',"amount",'poNumber','sapResponse'];
      }else{
        this.columnsToDisplay = ['invStatus','actions','isTaxInvoice','vendorCode','vendorName',"paymentMethods","sesNumber",'invoiceNumberByVp','invoiceDate','invoiceNumberByVendor',"amount",'poNumber','sapResponse'];
      }
    }
    // const filterValue = (event.target as HTMLInputElement).value;
    // const filterValue = (event.target as HTMLInputElement).value;//this.searchString;
    if(filterValue.length >= 2 || this.searchString == "" ){
      this.getInvoiceList(null, this.searchString);
    }
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    
  }
  isTotalAmount:any;
  totalSum:any = (0).toFixed(2);
  isAllSelected() { 
    
    let test:any= [];
    let reducer:any = [];
    
    this.selection.selected.forEach(s => {
       test.push(s.amount);
    });
    
    let totalAMT : any = 0; 
    for (let i of test) {
      if (i != undefined) {
        totalAMT = totalAMT + Number(i);
      }
    }
    this.totalSum = totalAMT;
    
    const numSelected = this.selection.selected.length;
    // const numRows = this.dataSource.length;
    // return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  // masterToggle() {
  //   this.isAllSelected() ?
  //       this.selection.clear() :
  //       this.dataSource.forEach(row => this.selection.select(row));
  //       
        
  //       let test:any= [];
  //   this.selection.selected.forEach(s => {
  //     // 
  //     // 
  //      test.push(s.id);

      
  //   });
  //   
  //   if(test.length == 0){
  //     this.totalSum = (0).toFixed(2)
  //   }
  // }
  unSelectedRows:any = [];
  unchecked (){
    setTimeout(() => {
      this.unSelectedRows = this.selection.selected;
      if(this.unSelectedRows.length == 0){
      this.totalSum = (0).toFixed(2);
      }
      
    })
  }

  // logSelection() {

  //   let test:any= [];
  //   this.selection.selected.forEach(s => {
  //     // 
  //     // 
  //      test.push(s.id);

      
  //   });
  //   
  //   if(test.length > 0){
  //     let req = {
  //       "ids": test
  //     };
  //   this.mySpinner = true;
  //   
  //   }
  // }
  onPoInfo(po:any, id:any,type:any){
    this.router.navigate(["invoice-list/po-info", po, id, type, "p","inv"])
  }
}
