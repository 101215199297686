import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
// import { ConfigModel } from './config-model/config-model';
// import { ConfigModel, ConfiModel } from './config-model/config-model';
import { AdminServiceService } from '../services/admin-service.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuditModelComponent } from './audit-model/audit-model.component';
import { MatTableDataSource } from '@angular/material/table';
import swal from "sweetalert2";
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TableUtil } from 'src/app/reports/TableUtil';
import { CommonServicesService } from 'src/app/services/common-services.service';
import { error } from 'selenium-webdriver';

@Component({
  selector: 'app-audit-records',
  templateUrl: './audit-records.component.html',
  styleUrls: ['./audit-records.component.css']
})
export class AuditRecordsComponent implements OnInit {

  lang:any = 'en';
  langSelect:any;
  mySpinner = false;
  constructor(private dialog: MatDialog,private router: Router, private cService: CommonServicesService,
    private fb: FormBuilder,private DDate:DatePipe) { }
  dataSource:MatTableDataSource<any>;
  dataSource1:any;
  currentUser: any;
  dropdownSettings = {};
  dropdownSettings1 = {};
  language:any;
  maxDate = new Date();
  columnsToDisplay:any = [];
  @ViewChild(MatSort) sort: MatSort;
  date = new FormGroup({
    DateFrom: new FormControl(''),
    DateTo: new FormControl(''),
  });

  auditReportForm: FormGroup = this.fb.group({
    optype:[],
    selecttablename:[],
    DateFrom:[],
    DateTo:[],
    username:[],
  });

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
          let currentUsers = this.currentUser.response;
          let lang = currentUsers.langPreference;
          if(lang == 'en'){
            this.language={
              "audit":"Audit Log",
              "optype":"Operation Type",
              "selectoptype":"Select Operation Type",
              "tablename":"Feature",
              "selecttablename":"Select Feature",
              "username":"User Name",
              "enterusername":"Enter User Name",
              "Date":"Date",
              "Search":"Search",
              "Clear":"Clear",
              "dbId":"Data Base ID",
              "oldRowData":"Old Row Data",
              "newRowData":"New Row Data",
              "swalDate":"Date 'From' cannot be greater than Date 'To'",
              "DateR":"Date Range",
              "dbuserName":"Db User Name",
              "appuserName":"Application User Name",
              "status":"Status",
              "reason":"Reason",
            }
            }else if(lang == 'th'){
            this.language={
              "audit":"บันทึกการตรวจสอบ",
              "optype":"ประเภทการดำเนินการ",
              "selectoptype":"เลือกประเภทการทำงานe",
              "tablename":"คุณสมบัติ",
              "selecttablename":"เลือกคุณสมบัติ",
              "username":"ชื่อผู้ใช้",
              "enterusername":"ป้อนชื่อผู้ใช้",
              "Date":"วันที่",
              "Search":"ค้นหา",
              "Clear":"ล้างข้อมูลการค้นหา",
              "dbId":"รหัสฐานข้อมูล",
              "oldRowData":"ข้อมูลแถวเก่า",
              "newRowData":"ข้อมูลแถวใหม่",
              "swalDate":"วันที่ 'จาก' ไม่สามารถมากกว่าวันที่ 'ถึง'",
              "DateR":"ช่วงวันที่",
              "dbuserName":"ชื่อผู้ใช้ฐานข้อมูล",
              "appuserName":"ชื่อผู้ใช้แอปพลิเคชัน",
              "status":"สถานะ",
              "reason":"เหตุผล",
            }
            }

  // this.getAudits(null,this.searchString);
  this.optypelList = [
    { keyvalue: 1, items_text: 'CREATE' },
    { keyvalue: 2, items_text: 'UPDATE' },
    { keyvalue: 2, items_text: 'DELETE' },
  ];

  this.tableList = [
    { item_id: 1, item_text: 'Business Place Master' },
    { item_id: 2, item_text: 'Storage Location Master' },
    { item_id: 3, item_text: 'Withholding Tax Master' },
    { item_id: 4, item_text: 'Company Master' },
    { item_id: 5, item_text: 'Cost Center Master' },
    { item_id: 6, item_text: 'GL Account Master' },
    { item_id: 7, item_text: 'Payment Terms Master' },
    { item_id: 8, item_text: 'Plant Master' },
    { item_id: 9, item_text: 'Tax Master' },
    { item_id: 10, item_text: 'WHT Type Master' },
  ];

  this.dropdownSettings = {
    singleSelection: true,
    idField: 'keyvalue',
    textField: 'items_text',
    closeDropDownOnSelection: true,        
    itemsShowLimit: 1,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true
  };
  this.dropdownSettings1 = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    closeDropDownOnSelection: true,        
    itemsShowLimit: 1,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true
  };

  this.columnsToDisplay = ["dbUserName","applicationUserName","createdDate","operationType","featureName","oldRowData","newRowData","status","reason"];  // this.auditMaster();
  }

  optypelList:any = [];
  tableList:any = [];
 
  DateFrom:any;
  DateTo:any;
  isInvoiceStartDate:boolean = true;
  username:any;
  getDNStartDate(event){
    this.DateFrom = this.DDate.transform(event.target.value,'yyyy-MM-dd');
     if(new Date(this.DateTo) < new Date(this.DateFrom)){
         swal.fire(`${this.language.swalDate}`,'','warning');      
       event.target.value = new Date(this.DateTo);
       this.DateFrom = this.DDate.transform(this.DateTo,'yyyy-MM-dd');
     }else{
       if(this.DateTo != "" || this.DateTo != undefined){
    this.isInvoiceStartDate = false;     
   }

  }
}

getInvoiceEndDate(event) {   
  this.DateTo = this.DDate.transform(event.target.value,'yyyy-MM-dd');
  if(new Date(this.DateTo) < new Date(this.DateFrom)){
    swal.fire(`${this.language.swalDate}`,'','warning');
    event.target.value = new Date(this.DateFrom);
    this.DateTo = this.DDate.transform(this.DateFrom,'yyyy-MM-dd');
  }
  if(this.DateFrom == null || this.DateFrom == undefined ){
      return;
  }
  if(this.DateTo == null || this.DateTo == undefined ){
    return;
}
}

requestData:any;
onSubmit(){
  let DateFrom,DateTo,userName;

  if(this.DateFrom){
    DateFrom = this.DateFrom;
  }
  if(this.DateTo){
    DateTo = this.DateTo;
  }

  if(this.auditReportForm.value.username){
    userName = this.auditReportForm.value.username;
  }

  this.requestData = {
    "dmlType": this.oplist,
    "tableName": this.tbleList,
    "fromDate": DateFrom,
    "toDate": DateTo,
    "userName":userName
    }

    this.setTableData(null);
  
}

pageIndex = 0;
pageSize = 10;
listLength: any;
count:any;
  async setTableData(event: PageEvent){

    if (event == null) {
      this.pageIndex = 0;
      this.pageSize = 10;
    } else {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
    }
  let tableData: any [];
  let status : any;
  let comName: any;
      this.mySpinner = true;
      (await this.cService.getauditReport(this.requestData,this.pageIndex, this.pageSize)).pipe(first()).subscribe(res => {
        if(res.response != null){
          if (res.responseStatus.toUpperCase() == "SUCCESS") {
            this.dataSource = new MatTableDataSource(res.response);
            this.listLength = res.pagination.count;
            this.dataSource.sort = this.sort;
            this.mySpinner = false;
          } else {
            this.mySpinner = false;
            this.listLength = 0;
            swal.fire(res.responseMessage,"","error");
          }
        }else{
          this.mySpinner = false;
          this.dataSource = new MatTableDataSource([]);
          this.listLength = 0;
          swal.fire(res.responseMessage,"","error");
        }
      },
      error => {
        this.mySpinner = false;
        this.listLength = 0;
      })
}


onClear(){
  // this.itemstatus = [];
  this.auditReportForm.get('optype').setValue([]);
  this.auditReportForm.get('selecttablename').setValue([]);
  this.oplist = [];
  this.tbleList = [];
  this.date.controls.DateFrom.setValue("");
  this.date.controls.DateTo.setValue("");
  this.DateFrom = '';
  this.DateTo = '';
  this.username = '';
  this.isInvoiceStartDate = true;    
}




async exportexcel()
{
  this.mySpinner = true;
    (await this.cService.getexportExcelData(this.requestData)).pipe(first()).subscribe(res => {
      if(res.response != null){
        if (res.responseStatus.toUpperCase() == "SUCCESS") {
          this.mySpinner = false;
        } else {
          this.mySpinner = false;
          swal.fire(res.responseMessage,"","error");
        }
      }else{
        this.mySpinner = false;
        swal.fire(res.responseMessage,"","error");
      }
  const onlyNameAndSymbolArr: Partial<PeriodicElement>[] = res.response.map(
    x => ({
      "DB USER NAME":x.dbUserName,
      "APPLICATION USER NAME":x.applicationUserName,
      "DATE":this.DDate.transform(x.createdDate,'dd/MM/yyyy HH:mm:ss'),
      "OPERATION TYPE": x.dmlType,
      "FEATURE": x.featureName,
      "OLD ROW DATA": x.oldRowData?JSON.stringify(x.oldRowData):"",
      "NEW ROW DATA": x.newRowData?JSON.stringify(x.newRowData):"",
      "STATUS":x.status,
      "REASON":x.reason
    })
  )

  TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "Audit-Report");
    },
    (error) => {
      this.mySpinner = false;
    })
  }

  oplist:any = [];
  tbleList:any = [];
  onSelect(item){
    this.oplist.push(item);
  }
  onDeSelect(item: any) {
    const index = this.oplist.indexOf(item);
    this.oplist.splice(index, 1);
  }
  onSelectAll(items: any) {
    this.oplist = [];
    for (let i = 0; i < items.length; i++){
      this.oplist.push(items[i]);
    }
  }
  onDeSelectAll(items: any) {
    this.oplist = [];
  }

  onSelect2(item){
    this.tbleList.push(item.item_text);
  }
  onDeSelect2(item: any) {
    const index = this.tbleList.indexOf(item.item_text);
    this.tbleList.splice(index, 1);
  }
  onSelectAll2(items: any) {
    this.tbleList = [];
    for (let i = 0; i < items.length; i++){
      this.tbleList.push(items[i].item_text);
    }
  }
  onDeSelectAll2(items: any) {
    this.tbleList = [];
  }
}

export interface PeriodicElement {
  dbuserName:any,
  applicationUserName:any,
  oldRowDate: any,
  newRowData: any,
  operationType: any,
  tableName: any,
  date: any,  
  status:any,
  reason:any   
}