
        <mat-card>
            <mat-card-title>
                <div fxFlex.gt-lg="90" fxFlex.gt-md="90" fxFlex.gt-xs="100" fxFlex="100">
                    {{headerToDisplay.userManagement}}
                </div>
                <!-- <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
                    <button mat-raised-button class="submitButton" (click)="openDialog('Add',{})">Add Vendor</button>
                </div> -->
            </mat-card-title>
            <hr>
            <mat-card-content >
                <div fxLayout="row" fxLayoutWrap="wrap">
                    <!-- Card column -->
                    <div fxFlex.gt-sm="100%" fxFlex="100">
                        <!-- <mat-card> -->
                            <!-- <div class="row mx-2 mt-2 my-2 summaryTab mat-elevation-z8  mx-3 mt-3 full-width"> -->
                        
                                <div fxLayout="row"fxLayout.xs="column" >
                                    <div class="search" fxFlex="30" fxFlex.sm="50" fxFlex.xs="100">
                                        <mat-form-field class="mx-3 max-width">
                                         <mat-label>{{headerToDisplay.search}}</mat-label>
                                          <input matInput placeholder="Search" (keyup)="applyFilter1()" #input [(ngModel)]="searchString">
                                       </mat-form-field>
                                       </div>
                                        
                                       <div fxFlex="20" fxFlex.sm="50" fxFlex.xs="100" style="margin-top:15px">
                                            <button (click)="applyFilter()" mat-raised-button class="submitButton btn-primary">{{headerToDisplay.search}}</button>
                                       </div>
                                       <div fxFlex="23" fxFlex.sm="50" fxFlex.xs="100"></div>  
                                             <div fxFlex="22" fxFlex.sm="50" fxFlex.xs="100" style="text-align:end;margin-top:10px;">
                                                <a  style="cursor:pointer;" (click)="exportexcel('')"><img src="assets/images/icons8-export-excel-100.png" height="50px" width="50px" ></a>       
                                        </div>
                                    <!-- <div fxFlex.gt-lg="60" fxFlex.gt-md="60" fxFlex.gt-xs="100" fxFlex="100"></div> -->
                                    <!-- <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
                                        <button mat-raised-button class="submitButton addUser" (click)="openDialog('Add',{})">Add User</button>
                                    </div> -->

                                    <div class="addUserParent" fxFlex="10" fxFlex.sm="50" fxFlex.xs="100">
                                        <button mat-raised-button class="submitButton addUser" (click)="openDialog('Add',{})">{{headerToDisplay.addUser}}</button>
                                    </div>
                                </div>
                
                       <div class="overflow">
                            <mat-card>
                                    <mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="mat-elevation-z5 main-table vendorUserTable">
                                        <!-- <ng-container matColumnDef="vendorCode">
                                            <mat-header-cell *matHeaderCellDef  mat-sort-header> Vendor Code </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label = "Vendor Code"> {{element.vendorCode}} </mat-cell>
                                        </ng-container>
                                    
                                        <ng-container matColumnDef="companyCode">
                                            <mat-header-cell *matHeaderCellDef  mat-sort-header> Company Code </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Company Code"> {{element.companyCode}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="vendorGroup">
                                            <mat-header-cell *matHeaderCellDef  mat-sort-header> vendor Group </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Vendor Group"> {{element.vendorGroup}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="currency">
                                            <mat-header-cell *matHeaderCellDef  mat-sort-header> currency </mat-header-cell>
                                            <mat-cell *matCellDef="let element"  data-label="Currency"> {{element.currency}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="city">
                                            <mat-header-cell *matHeaderCellDef  mat-sort-header> city </mat-header-cell>
                                            <mat-cell *matCellDef="let element"  data-label="City"> {{element.city}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="country">
                                            <mat-header-cell *matHeaderCellDef  mat-sort-header> country </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Country"> {{element.country}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="status">
                                            <mat-header-cell *matHeaderCellDef  mat-sort-header> Status </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Status"> {{element.status}} </mat-cell>
                                        </ng-container> -->
                                        <ng-container matColumnDef="vendorName">
                                            <mat-header-cell *matHeaderCellDef class="first-name-w" mat-sort-header> {{headerToDisplay.vendorName}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="first-name-w" data-label="VENDOR NAME" > {{element.vendorName == null?" ":element.vendorName}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="userName">
                                                <mat-header-cell *matHeaderCellDef class="vendorCode-w" mat-sort-header> {{headerToDisplay.userName}} </mat-header-cell>
                                                <mat-cell *matCellDef="let element" class="vendorCode-w" data-label="VENDOR NAME" > {{element.userName}} </mat-cell>
                                            </ng-container>
                                        <ng-container matColumnDef="firstName">
                                            <mat-header-cell *matHeaderCellDef class="first-name-w" mat-sort-header>{{headerToDisplay.firstName}}</mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="first-name-w" data-label="FIRST NAME" > {{element.firstName}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="lastName">
                                            <mat-header-cell *matHeaderCellDef class="last-name-w"  mat-sort-header>{{headerToDisplay.lastName}}</mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="last-name-w" data-label="LAST NAME"> {{element.lastName}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="email">
                                            <mat-header-cell *matHeaderCellDef class="email-w" mat-sort-header> {{headerToDisplay.email}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="email-w" data-label="EMAIL"> {{element.email}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="contactNo">
                                            <mat-header-cell *matHeaderCellDef  mat-sort-header class="contact-w"> {{headerToDisplay.mobileNo}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element"  class="contact-w" data-label="Contact No"> {{element.contactNo}} </mat-cell>
                                        </ng-container>
                                        <!-- <ng-container matColumnDef="usertype">
                                            <mat-header-cell *matHeaderCellDef  mat-sort-header> Role Name </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Role Name"> {{element.roleName}} </mat-cell>
                                        </ng-container> -->
                                        <ng-container matColumnDef="usertype">
                                            <mat-header-cell *matHeaderCellDef class="vendorCode-w"  mat-sort-header>{{headerToDisplay.usertype}}</mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="vendorCode-w" data-label="Role Name"> {{element.usertype}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="isBlocked">
                                            <mat-header-cell *matHeaderCellDef class="status2-user-w"  mat-sort-header> {{headerToDisplay.status}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="status2-user-w" [ngClass]="calculateClass(element.isBlocked)" data-label="Status"> {{element.isBlocked == 0 || element.isBlocked == null ? 'Active' : 'Blocked'}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="lastLoginTime">
                                            <mat-header-cell *matHeaderCellDef  mat-sort-header class="lastLoginTime-w"> {{headerToDisplay.lastLoginTime}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="lastLoginTime-w" data-label="Last Login Date/Time"> {{element.lastLoginTime? (element.lastLoginTime  | date:"dd/MM/yyyy HH:mm:ss"): "-"}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="actions">
                                            <mat-header-cell *matHeaderCellDef  class="actions-w actions-align"> {{headerToDisplay.actions}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element" class="actions-w" data-label="Actions">
                                                <!-- <button  mat-raised-button class="submitButton" aria-label="edit" (click)="openDialog('Update',element)" style="margin-right: 10px;"> -->
                                            <!-- <mat-icon aria-hidden="false" class="visibility" aria-label="edit">edit</mat-icon> -->
                                                    <!-- Update
                                          </button>
                                                <button (click)="openDialog('Update',element)" mat-raised-button class="cancelButton">
                                                    Block/unblock     -->
                                                    <!-- <mat-icon aria-hidden="false" class="visibility" aria-label="visibility">block</mat-icon> -->
                                                    <!-- </button>
                                                <button mat-icon-button aria-label="view" (click)="openView(element)">
                                          </button> -->
                                          <mat-icon class="block" aria-label="lock" *ngIf="element.isBlocked == 1 " (click)="openConfirm(element)">lock</mat-icon>
                                        <mat-icon class="block" aria-label="lock_open" *ngIf="element.isBlocked == 0 || element.isBlocked == null || element.isBlocked == undefined " (click)="openConfirm(element)">lock_open</mat-icon>
                                          <mat-icon aria-hidden="false"  class="visibility" aria-label="visibility" (click)="openView(element)">visibility</mat-icon>
                                          <mat-icon class="edit" aria-label="edit" *ngIf="element.isBlocked == 0" (click)="openDialog('Update',element)">edit</mat-icon>
                                         <!-- <mat-icon class="block" aria-label="block" (click)="openConfirm(element)">block</mat-icon> -->
                                         
                                            </mat-cell>
                                        </ng-container>
                                        <mat-header-row class="vendorRow" *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                                        <mat-row class="vendorRow" *matRowDef="let element; columns: columnsToDisplay;"></mat-row>
                                    </mat-table>                                
                            </mat-card>
                        </div>
                            <mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" [pageSize]="pageSize" [length]="listLength" [pageSizeOptions]="[5, 10, 20]" [pageSizeOptions]="[10, 25, 50, 100]" (page)="getVendors($event,'')"  style="width:100%"></mat-paginator>
                                
                    </div>
                </div>
            </mat-card-content>
            <div class="overlay"  *ngIf="mySpinner">
                <div class="center">
                    <mat-spinner></mat-spinner> 
                </div>
                </div>
        </mat-card>