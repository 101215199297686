import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root'
})

export class AdminServiceService {

    constructor(private http: HttpClient) {
    }

    login(data:any ) {
        return this.http.post<any>(`${environment.serverUrl}/auth/login/username`, data).pipe(map(res => {
            return res;
        }))
    }

    logout(data:any ) {
        
        return this.http.post<any>(`${environment.serverUrl}/logout`, data).pipe(map(res => {
            return res;
        }))
    }
    
    getUsersList(page,limit,searchString){//?page=`+page+`&limit=`+limit
    const encodedString = encodeURIComponent(searchString);
        return this.http.get<any>(`${environment.serverUrl}/users/list?page=`+page+`&limit=`+limit+`&searchString=`+encodedString).pipe(map(res => {
            return res;
        }))
    }

    getUsersListExport(searchString){//?page=`+page+`&limit=`+limit
    const encodedString = encodeURIComponent(searchString);
        return this.http.get<any>(`${environment.serverUrl}/users/list?`+`searchString=`+encodedString+`&export=true`).pipe(map(res => {
            return res;
        }))
    }
    
    //Get Vendor Users list
    getVendorUsers(page,limit, searchString){//sers/vendor/list
        const encodedString = encodeURIComponent(searchString);
        return this.http.get<any>(`${environment.serverUrl}/users/vendor/list?page=`+page+`&limit=`+limit+`&searchString=`+encodedString).pipe(map(res => {
            return res;
        }))
    }

    getVendorUsersExport(searchString){//sers/vendor/list
        const encodedString = encodeURIComponent(searchString);
        return this.http.get<any>(`${environment.serverUrl}/users/vendor/list?`+`&searchString=`+encodedString+`&export=true`).pipe(map(res => {
            return res;
        }))
    }
    addUser(data:any){
        return this.http.post<any>(`${environment.serverUrl}/users/create`, data).pipe(map(res => {
            return res;
        }))
    }
    // Update a user
    updateUser(data: any) {
        return this.http.post<any>(`${environment.serverUrl}/users/update`, data).pipe(map(res => {
            return res;
        }))
    }

    // Block a Vendor user
    updateVendorUser(data: any) {
        return this.http.get<any>(`${environment.serverUrl}/vendor/block/${data}` ).pipe(map(res => {
            return res;
        }))
    }
    
    // Unblock a Vendor user
    updateVendorUser1(data: any) {
        return this.http.get<any>(`${environment.serverUrl}/vendor/unblock/${data}` ).pipe(map(res => {
            return res;
        }))
    }
    
    blockUser(user: any) {
        return this.http.get<any>(`${environment.serverUrl}/users/user-access/` + user.id + `/` + user.isBlocked,).pipe(map(res => {
            return res;
        }))
    }

    getRollList(page, limit, searchString) {//?page=`+page+`&limit=`+limit
    const encodedString = encodeURIComponent(searchString);
        return this.http.get<any>(`${environment.serverUrl}/roles/list?page=`+page+`&limit=`+limit+`&searchString=`+encodedString).pipe(map(res => {
            return res;
        }))
    }

    getConfigurations(isEdit:any,page:any, limit:any, searchString) {//?page=`+page+`&limit=`+limit
    const encodedString = encodeURIComponent(searchString);
        return this.http.get<any>(`${environment.serverUrl}/app-props?isEditable=${isEdit}&page=`+page+`&limit=`+limit+`&searchString=`+encodedString).pipe(map(res => {
            return res;
        }))
    }
    getResMessages(page:any, limit:any,searchString,langCode) {//?page=`+page+`&limit=`+limit
    const encodedString = encodeURIComponent(searchString);
        return this.http.get<any>(`${environment.serverUrl}/resp-messages?page=`+page+`&limit=`+limit+`&searchString=`+encodedString+`&langCode=`+langCode).pipe(map(res => {
            return res;
        }))
    }
    updateResponseMessage(id:any,req:any){
        
        return this.http.put<any>(`${environment.serverUrl}/resp-messages/${id}`, req).pipe(map(res => {
            return res;
        }))
    }
    updateConfigProp(id:any,req:any){
        
        return this.http.put<any>(`${environment.serverUrl}/app-props/${id}`, req).pipe(map(res => {
            return res;
        }))
    }
    getVendorsList(page:any,limit:any, searchString){//?page=`+page+`&limit=`+limit
    const encodedString = encodeURIComponent(searchString);
        return this.http.get<any>(`${environment.serverUrl}/vendor?page=`+page+`&limit=`+limit+`&searchString=`+encodedString).pipe(map(res => {
            return res;
        }))
    }

    getAuditLogs(page:any,limit:any, searchString){//?page=`+page+`&limit=`+limit
    const encodedString = encodeURIComponent(searchString);
        return this.http.get<any>(`${environment.serverUrl}/audit?page=`+page+`&limit=`+limit+`&searchString=`+encodedString).pipe(map(res => {
            return res;
        }))
    }

    getVendorsListExport(searchString){//?page=`+page+`&limit=`+limit
    const encodedString = encodeURIComponent(searchString);
        return this.http.get<any>(`${environment.serverUrl}/vendor?`+`searchString=`+encodedString+`&export=true`).pipe(map(res => {
            return res;
        }))
    }
    addVendor(data:any){
        return this.http.post<any>(`${environment.serverUrl}/users/create`, data).pipe(map(res => {
            return res;
        }))
    }
    // Update a user
    updateVendor(data: any) {
        return this.http.post<any>(`${environment.serverUrl}/users/update`, data).pipe(map(res => {
            return res;
        }))
    }

    getVendorInfoById(id:number) {
        return this.http.get<any>(`${environment.serverUrl}/vendors/${id}`).pipe(map(res => {
            return res;
        }))
    }
    getUserInfoById(id:number) {
        return this.http.get<any>(`${environment.serverUrl}/users/${id}`).pipe(map(res => {
            return res;
        }))
    }
    async getVendorsCode(){//?page=`+page+`&limit=`+limit
        return this.http.get<any>(`${environment.serverUrl}/vendor/list`).pipe(map(res => {
            return res;
        }))
    }
    async getDashboardDetails(){//?page=`+page+`&limit=`+limit
        return this.http.get<any>(`${environment.serverUrl}/dashboard`).pipe(map(res => {
            return res;
        }))
    }
    async getAllAudits(page:any, limit:any, searchString) {//?page=`+page+`&limit=`+limit
    const encodedString = encodeURIComponent(searchString);
        return this.http.get<any>(`${environment.serverUrl}/audit?page=`+page+`&limit=`+limit+`&searchString=`+encodedString).pipe(map(res => {
            return res;
        }))
    }
    async vendorBulkUpload(formData:any){
        return this.http.post<any>(`${environment.serverUrl}/vendor-bulk-active`, formData).pipe(map(res => {
            
            return res;
        })
        );
    }
    async getAllWeighBridge(page:any, limit:any, searchString:string){//&searchString=${searchString}
    const encodedString = encodeURIComponent(searchString);
        return this.http.get<any>(`${environment.serverUrl}/weigh-bridge?page=`+page+`&limit=`+limit+`&searchString=`+encodedString).pipe(map(res => {
            return res;
        }))
    }
    async getSelectedRoles(roleId:any){
        return this.http.get<any>(`${environment.serverUrl}/menus/list/byrole/`+roleId).pipe(map(res => {
            return res;
        }))
      }
      async saveSelectedRoles(roleId:any, data:any){
        return this.http.post<any>(`${environment.serverUrl}/menus/add/byrole/`+roleId, data).pipe(map(res => {
            return res;
        })
        );
    }
    async deleteSelectedRoles(roleId:any, data:any){
        return this.http.post<any>(`${environment.serverUrl}/menus/delete/byrole/`+roleId, data).pipe(map(res => {
            return res;
        })
        );
    }
   async getListHeirarchy (roleId:any){
        return this.http.get<any>(`${environment.serverUrl}/menus/listheirarchy`).pipe(map(res => {
            return res;
        }))
      }
    async bulkApproveWB(data:any){
        return this.http.post<any>(`${environment.serverUrl}/weigh-bridge-status-approve`, data).pipe(map(res => {
            return res;
        }))
    }
    async getCompanyList() {//?page=`+page+`&limit=`+limit
    return this.http.get<any>(`${environment.serverUrl}/company/list`).pipe(map(res => {
        return res;
    }))
}

 exportWeighbridgeData(searchString){
    //const header = { Accept: "application/octet-stream" };
    //const options = {headers: header, responseType: 'blob'};
    return this.http.get<any>(`${environment.serverUrl}/wb-report/export/?searchString=`+ searchString, { responseType: 'blob' as 'json', observe: 'response' }).pipe(
  map((user) => {
    if(user.body.type != "application/json"){
        let test = user;
        let header = user.headers;
        let contentDisposition = header.get('content-disposition');
        let fileName = contentDisposition.split('filename=')[1].replace(/\"/g, '');
        const blob = new Blob([test.body], { type : 'application/octet-stream' });  
        const file = new File([blob], fileName, { type: 'application/octet-stream' });
        saveAs(file);
      }else{
        Swal.fire("Unabale to process request");
      }
  }));
  }

  exportUserAuditLogData(searchString){
    return this.http.get<any>(`${environment.serverUrl}/audit/download?searchString=`+ searchString, { responseType: 'blob' as 'json', observe: 'response' }).pipe(
  map((user) => {
    if(user.body.type != "application/json"){
        let test = user;
        let header = user.headers;
        let contentDisposition = header.get('content-disposition');
        let fileName = contentDisposition.split('filename=')[1].replace(/\"/g, '');
        const blob = new Blob([test.body], { type : 'application/octet-stream' });  
        const file = new File([blob], fileName, { type: 'application/octet-stream' });
        saveAs(file);
      }else{
        Swal.fire("Unabale to process request");
      }
  }));
  }
}
