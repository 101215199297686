<mat-card>
    <mat-card-title>
        <div fxLayout="row" fxFlex.gt-xs="100" fxFlex="100">
            {{language.Profile}}
        </div>

    </mat-card-title>
    <hr>
    <mat-card-content>
        <form [formGroup]="profileForm">
            <div fxFlex="60" fxFlex.xs="100">
                <div class="overflow">
                    <!-- <mat-card>
                                <mat-card-content class="mat-content"> -->
                                            <div fxLayout="row" fxLayout.xs="column" *ngIf="currentUser.roleID == 2">
                                                    <!-- column -->
                                                    <div class="padding-right" fxFlex="50">
                                                        <mat-form-field appearance="outline">
                                                            <mat-label>{{language.FirstNameTh}}</mat-label>
                                                            <input matInput placeholder="{{language.FirstNameTh}}" formControlName="firstNameTh"
                                                                readonly>
                                                        </mat-form-field>
                                                    </div>
                                                    <!-- <div fxFlex.gt-sm="10" fxFlex="10"></div> -->
                                                    <!-- column -->
                                                    <div class="padding-left" fxFlex="50">
                                                        <mat-form-field appearance="outline">
                                                            <mat-label>{{language.LastNameEn}}</mat-label>
                                                            <input matInput placeholder="{{language.LastNameEn}}" formControlName="lastNameEn"
                                                                type="text" readonly>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                        <div fxLayout="row" fxLayout.xs="column" *ngIf="currentUser.roleID != 2">
                        <!-- column -->
                        <div class="padding-right" fxFlex="50">
                            <mat-form-field appearance="outline">
                                <mat-label>{{language.FirstName}}</mat-label>
                                <input matInput placeholder="{{language.FirstName}}" formControlName="firstName"
                                    readonly>
                            </mat-form-field>
                        </div>
                        <!-- <div fxFlex.gt-sm="10" fxFlex="10"></div> -->
                        <!-- column -->
                        <div class="padding-left" fxFlex="50">
                            <mat-form-field appearance="outline">
                                <mat-label>{{language.LastName}}</mat-label>
                                <input matInput placeholder="{{language.LastName}}" formControlName="lastName"
                                    type="text" readonly>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- column -->
                    <div fxLayout.xs="column" fxLayout="row">
                        <div class="padding-right" fxFlex="50">
                            <mat-form-field appearance="outline">
                                <mat-label>{{language.Email}}</mat-label>
                                <input matInput placeholder="{{language.Email}}" type="email" formControlName="email"
                                    readonly>
                            </mat-form-field>
                        </div>
                      
                        <div class="padding-left" fxFlex="50">
                            <mat-form-field appearance="outline">
                                <mat-label>{{language.MobileNumber}}</mat-label>
                                <input matInput type="tel" placeholder="{{language.MobileNumber}}"
                                    formControlName="mobileNumber" readonly>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column">
                        <div class="padding-right" fxFlex="50">
                            <mat-form-field appearance="outline">
                                <mat-label>{{language.RoleName}}</mat-label>
                                <input matInput type="text" placeholder="{{language.RoleName}}"
                                    formControlName="roleName" readonly>
                            </mat-form-field>
                        </div>
                        <!-- <div fxFlex.gt-sm="10" fxFlex="10"></div> -->
                        <div class="padding-left" fxFlex="50">
                            <mat-form-field appearance="outline">
                                <mat-label>{{language.Username}}</mat-label>
                                <input matInput type="text" placeholder="{{language.Username}}"
                                    formControlName="userName" readonly>
                            </mat-form-field>
                        </div>
                    </div>
                    
                    <div fxLayout="row" fxLayout.xs="column" *ngIf="currentUser.roleID == 2">
                        <div class="padding-right" fxFlex="50">
                            <mat-form-field appearance="outline">
                                <mat-label>{{language.grnEmail}}</mat-label>
                                <input matInput type="email" placeholder="{{language.grnEmail}}"
                                    formControlName="grnEmail">
                            </mat-form-field>
                        </div>
                        
                        <!-- <div fxFlex.gt-sm="10" fxFlex="10"></div> -->
                        <div class="padding-left" fxFlex="50">
                            <mat-form-field appearance="outline">
                                <mat-label>{{language.srnEmail}}</mat-label>
                                <input matInput type="email" placeholder="{{language.srnEmail}}"
                                    formControlName="srnEmail">
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" *ngIf="currentUser.roleID == 2">
                        <div class="padding-right" fxFlex="50">
                            <mat-form-field appearance="outline">
                                <mat-label>{{language.invEmail}}</mat-label>
                                <input matInput type="email" placeholder="{{language.invEmail}}"
                                    formControlName="invEmail">
                            </mat-form-field>
                        </div>
                        
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" *ngIf="currentUser.roleID == 2">
                        <div class="buttonGroup mt-2">
                            <button mat-raised-button class="submitButton leftBtn" (click)="updateProfile()">{{language.submit}}</button>
                            <button mat-raised-button class="rejectButton" routerLink="/invoice-list">{{language.cancel}}</button>
                        </div>
                    </div>
                    <!-- </mat-card-content>
                            </mat-card> -->
                </div>
            </div>
            
            <!-- <div fxFlex.gt-sm="10" fxFlex="10"></div> -->
            <!-- column -->
            <!-- <div  fxFlex.gt-sm="40" fxFlex="40"></div> -->


        </form>


        <div class="overlay" *ngIf="mySpinner">
            <div class="center">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </mat-card-content>
</mat-card>