// my-modal.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router'
// import { RoleManagementComponent } from '../role-management.component';
import { DeliveryNotesInfoComponent } from '../delivery-notes-info/delivery-notes-info.component';

@Component({
  selector: 'app-dl-approve-component',
  templateUrl: './dl-approve.component.html',
  styleUrls: ['./dl-approve.component.css']
})

export class DlApproveComponent {

  // firstFormGroup: FormGroup;
  // incomeSheetData:any;
  // fileInputLabel;

  constructor(private _formBuilder: FormBuilder, public dialogRef: MatDialogRef<DlApproveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
    category:any=0;
    userType:any="";
    currentUser:any=[];
    language:any;
  ngOnInit(): void {
    this.userType = localStorage.getItem("user");
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
    if(lang == 'en'){
        this.language = {
        "head":	"Please add remarks and Approve",
        "Comments":"Comments",
        "Emails" :"Emails",
        "Reject": "Reject",
        "Approve":"Approve",
        "Cancel" :"Cancel",
        "swalComment":"Please enter Comment",
        "swalEmail":"Please enter Email",
        "commentex":"eg. Price match",
        "emailex":"eg. abc@gmail.com"
    }}
    else if(lang == 'th'){
      this.language = {
        "head":	"กรุณาเพิ่มข้อสังเกตและอนุมัติ",
        "Comments":"ความคิดเห็น",
        "Emails" :"อีเมล",
        "Reject": "ปฏิเสธ",
        "Approve":"อนุมัติ",
        "Cancel" :"ยกเลิก",
        "swalComment":"กรุณาใส่ความคิดเห็น",
        "swalEmail":"กรุณากรอกอีเมล์",
        "commentex":"เช่น. จับคู่ราคา",
        "emailex":"เช่น. abc@gmail.com"

  }
}
    // this.firstFormGroup = this._formBuilder.group({

    //   comment:['']
    //   // fileupload : ['',[Validators.required]]
    // });
  }

  comment:any;
  emails:any;
  onApproved() {
    // this.data.approvedRejected = ApprovedRejected.REJECTED;
    if(this.currentUser.roleID == 4 ){
      this.dialogRef.close({ data: "Approved", comment:this.comment});
      
    }else{
      this.dialogRef.close({ data: "Approved" });
    }
    


  }
  
  onCancel() {
    this.dialogRef.close({ data: "Cancelled" });
  }

}