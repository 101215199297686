import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { MyModalComponent } from 'src/app/my-modal/my-modal.component';
import { PurchaseOrderInterface } from 'src/app/purchase-order/purchase-order';
import { DlApproveComponent } from '../dl-approve/dl-approve.component';
import { PageEvent } from '@angular/material/paginator';
import { CommonServicesService } from 'src/app/services/common-services.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';
import swal from 'sweetalert2';
import { AddDeliveryNotesComponent } from '../add-delivery-notes/add-delivery-notes.component';
import { MatTableDataSource } from '@angular/material/table';
import {browserRefresh} from '../../app.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-delivery-notes-history',
  templateUrl: './delivery-notes-history.component.html',
  styleUrls: ['./delivery-notes-history.component.css']
})
export class DeliveryNotesHistoryComponent implements OnInit {
  mySpinner: boolean = false;
  expandedElement: any | null | undefined;
  showOverlay: boolean;
  searchString:any='';
  constructor(private router: Router, private dialog: MatDialog, public _snackBar: MatSnackBar, private cService: CommonServicesService,
     private route:ActivatedRoute, private location: Location) { }
  userType: any;
  dataSource: MatTableDataSource<any>;
  deliveryNoteRefNum: any;
  data: any;
  language:any;
  @ViewChild(MatSort) sort: MatSort;
  columnsToDisplay = [];
  currentUser:any=[];
  browserRefresh:boolean = false;
  listData:any = [];
  search:any;
  ngOnInit(): void {
    this.userType = localStorage.getItem("user");
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;

    let states;
    states = this.location.getState();

		if(lang == 'en'){

			this.language = {
        "vendorName":"Vendor Name",
        "poNumber":"PO Number",
        "plantCode":"Plant Code",
        "deliverynoteNumber":"Delivery Note Number",
			  "vendorCode":"Vendor Code",
			  "shipmentNumber":"Shipment Number",
        "PONumber":"PO Number",
			  "deliveryNoteRefNumVp":"Delivery Ref. Number",
			  "deliveryNoteDate":"Delivery Note Date",
        "vpdn":"VPDN#",
			  "quantity":"Quantity",
        "VendorName":"Vendor Name",
			  "Status":"Status",
        "totalAmount":"Total Amount",
        "Currency":"Currency",
        "actions":"Actions",
        "search":"Search",
        "Head":"Delivery Notes History",
        "MaterialDocNum":"Material Doc Num",
        "MaterialDocYear":"Material Doc Year",
        "CreatedBy":"CreatedBy",
        "SAPResponse":"SAP Response",
        "Requisitioner":"Requisitioner"

			}; 

		  }else if(lang == 'th'){

			this.language = {
        "vendorName":"ชื่อผู้ขาย/ผู้ให้บริการ",
        "poNumber":"เลขที่ใบสั่งซื้อ",
        "plantCode":"รหัสโรงงาน/สำนักงาน",
        "deliverynoteNumber":"หมายเลขใบส่งสินค้า",
        "vendorCode":"รหัสผู้ขาย",
			  "shipmentNumber":"จำนวนการจัดส่ง",
        "PONumber":"เลขที่ใบสั่งซื้อ",
			  "deliveryNoteRefNumVp":"อ้างอิงการจัดส่ง ตัวเลข",
			  "deliveryNoteDate":"วันที่บันทึกการจัดส่ง",
			  "quantity":"ปริมาณ/จำนวน",
			  "deliveryStatus":"สถานะ",
        "VendorName":"ชื่อผู้ขาย/ผู้ให้บริการ",
        "Status":"สถานะ",
        "vpdn":"VPDN#",
        "totalAmount":"จำนวนเงินรวม",
        "actions":"การดำเนินการ",
        "Currency":"สกุลเงิน",
        "search":"ค้นหา",
        "Head":" ประวัติบันทึกการจัดส่ง",
        "MaterialDocNum":"MATERIAL DOC NUM",
        "MaterialDocYear":"MATERIAL DOC YEAR",
        "CreatedBy":"สร้างโดย",
        "SAPResponse":"SAP RESPONSE",
        "Requisitioner":"ผู้ร้องขอ"
			};
		  }
    if(this.currentUser.roleID == 3 || this.currentUser.roleID == 7){
      this.columnsToDisplay = ["deliveryStatus","actions","vendorName","poNumber","plantCode","prRequisitioner","deliveryNoteRefNumVp","deliveryNoteNumberByVendor","deliveryNoteDate","warehouseReceivedQuantity","amount","itemMaterialDocNum","materialDocYear","sapResponse"];
    }else{
      this.columnsToDisplay = ["deliveryStatus","actions","vendorName","poNumber","plantCode","deliveryNoteRefNumVp","deliveryNoteNumberByVendor","deliveryNoteDate","warehouseReceivedQuantity","amount","itemMaterialDocNum","materialDocYear","sapResponse"];
    }

    if(localStorage.getItem("searchData") != null){
      this.searchString = localStorage.getItem("searchData")
    }
    if(browserRefresh == true){
      this.searchString = '';
      localStorage.setItem("searchData","")
      localStorage.setItem("tableData",JSON.stringify([]))
    }
    let tableData:any = [];
    if(localStorage.getItem("tableData")){
      tableData = JSON.parse(localStorage.getItem("tableData"));
      this.listData = tableData;
    }
    if(tableData.length == 0){
      this.searchString = states.search?states.search:"";
      localStorage.setItem("searchData",states.search?states.search:"")
      this.getDeliveryNoteHistory(null, this.searchString);
    }else{
      this.dataSource = tableData.response;
      this.listLength = tableData.pagination.count;
      localStorage.setItem("tableData",JSON.stringify([]))
    }
  }
  // vendorCode
  // columnsToDisplay = ['shipmentNo','poNumber', 'dlNoteNo', 'dlNoteDate','currency', 'qty', 'totalAmt','actions'];
  // columnsToDisplay1 = ['shipmentNo', 'poNumber', 'dlNoteNo', 'grnNo', 'dlNoteDate', 'currency', 'qty', 'totalAmt', 'actions'];

  onInfo(element: any) {
    localStorage.setItem("tableData",JSON.stringify(this.listData));
    this.router.navigate(["/delivery-notes/delivery-notes-info", element.id,"history"]);
  }

  addDelivery() {
    this.router.navigate(["delivery-notes/add-delivery-notes"])
  }
  openDialog(): void {
    // obj.action = _add;
    // let userid = obj.userID;
    this.dialog.open(DlApproveComponent, {
      data: 'Confirm Delivery note approval'

    }).afterClosed()
      .subscribe((confirm: Boolean) => {
        if (confirm) {
          // alert("¡A mí también!");
        } else {
          // alert("Deberías probarlo, a mí me gusta :)");
        }
      })
  }
  
  editDeliveryNote(element: any) {
    this.router.navigate(["delivery-notes/edit-delivery-note", element.id])
  }
  onPoInfo(element:any){
    this.router.navigate(["delivery-notes/po-info", element.poNumber, "h", element.id ])
  }

  newName:any=[];
  pageIndex = 0;
  pageSize = 10;
  listLength: any;
  dataSource1: any;
  async getDeliveryNoteHistory(event: PageEvent, searchString:any) {
    this.mySpinner = true;
    // this.showOverlay = true;
    if (event == null) {
      this.pageIndex = 0;
      this.pageSize = 10;
    } else {
      if (event.pageIndex == 0) {
        this.pageIndex = 0;
        this.pageSize = event.pageSize;
      } else {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      }
    }

    (await this.cService.getDeliveryNoteHistory(this.pageIndex, this.pageSize, this.searchString)).pipe(first()).subscribe(res => {
      
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        // this.showOverlay=false;
        this.dataSource = new MatTableDataSource(res.response);
        this.listLength = res.pagination.count;
        this.listData = res;
        this.dataSource.sort = this.sort;
        this.mySpinner = false;
      } else {
        // this.showOverlay=false;
        this.mySpinner = false;
        this.dataSource = new MatTableDataSource([]);
        this.listData = [];
        this.listLength = 0;
        
        // swal.fire(res.responseMessage, "", "error");
      }
    }, error => {
      // this.showOverlay=false;
      this.mySpinner = false;
    })
  }
  onVendorInfo(vendorCode:any){
    this.router.navigate(["delivery-notes/vendor-info", vendorCode])
  }

  applyFilter1(){
    const filterValue = this.searchString;
    localStorage.setItem("searchData", this.searchString);
    // const filterValue = (event.target as HTMLInputElement).value;
    // const filterValue = (event.target as HTMLInputElement).value;//this.searchString;
    if(this.searchString == "" ){
      this.getDeliveryNoteHistory(null, this.searchString);
    }
  }
  applyFilter() {
    const filterValue = this.searchString;
    // const filterValue = (event.target as HTMLInputElement).value;
    // const filterValue = (event.target as HTMLInputElement).value;//this.searchString;
    if(filterValue.length >= 2 || this.searchString == "" ){
      this.getDeliveryNoteHistory(null, this.searchString);
    }
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    
  }

}
