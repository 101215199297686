<mat-card>
    <mat-card-title>
        <div fxLayout="row" fxFlex.gt-xs="100" fxFlex="100">
           {{language.Head}}
        </div>
    </mat-card-title>
    <hr>
    <mat-card-content>       
        <div fxLayout="row" fxLayoutWrap="wrap">
            <!-- Card column -->
            <div fxFlex.gt-sm="100%" fxFlex="100">
                  <div fxLayout="row"fxLayout.xs="column">
                        <div  class="search" fxFlex="30" fxFlex.sm="50" fxFlex.xs="100">
                            <mat-form-field class="mx-3 max-width">
                                <mat-label> {{language.search}}</mat-label>
                                <input matInput placeholder="{{language.search}}" (keyup)="applyFilter1()" #input [(ngModel)]="searchString">
                            </mat-form-field>
                        </div>
                        <div fxFlex="20" fxFlex.sm="50" fxFlex.xs="100" style="margin-top:15px">
                            <button (click)="applyFilter()"  mat-raised-button class="submitButton btn-primary">{{language.search}}</button>
                        </div>
                    </div>

                    <div class="overflow">
                        <mat-card>
                            <mat-table [dataSource]="dataSource" matSort  multiTemplateDataRows
                            class=" full-width mat-elevation-z5 main-table purchaseOrderTable">

                                            <ng-container matColumnDef="actions">
                                                <mat-header-cell *matHeaderCellDef class="table-header actions-w" > {{language.actions}}
                                                </mat-header-cell>>
                                                <mat-cell *matCellDef="let element" class="actions-w" data-label="Actions :">
                                                    <div class="button-group my-2">
                                                        <mat-icon class="visibility" (click)="onInfo(element)" aria-hidden="false"
                                                            data-title="Details" aria-label="Details">visibility</mat-icon>
                                                    </div>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="companyName">
                                                <mat-header-cell *matHeaderCellDef class="table-header shortText1-w" mat-sort-header >
                                                    {{language.companyName}}
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" class="shortText1-w" data-label="Company Name :">
                                                    {{element.companyName}}
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="poNumber">
                                                <mat-header-cell *matHeaderCellDef class="table-header poNo-w" mat-sort-header >{{language.poNumber}}
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" class="poNo-w" data-label="PO Number:">
                                                    {{element.poNumber}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="poItem">
                                                <mat-header-cell *matHeaderCellDef class="table-header poItem-w" mat-sort-header >{{language.poItem}}
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" class="poItem-w" data-label="PO Item :">
                                                    {{element.poItem}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="svLineItem">
                                                <mat-header-cell *matHeaderCellDef class="table-header poItem-w" mat-sort-header >{{language.svItem}}
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" class="poItem-w" data-label="SV Item :">
                                                    {{element.svLineItem}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="plant">
                                                <mat-header-cell *matHeaderCellDef class="table-header plantCode-w" mat-sort-header >
                                                        {{language.plantCode}}
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" class="plantCode-w" data-label="Plant Code :">
                                                    {{element.plant}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="plantName">
                                                <mat-header-cell *matHeaderCellDef class="table-header shortText1-w" mat-sort-header >
                                                    {{language.plantName}}
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" class="shortText1-w" data-label="Plant Name :">
                                                    {{element.plantName}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="material">
                                                <mat-header-cell *matHeaderCellDef class="table-header materialNo-w" mat-sort-header >
                                                    {{language.materialNo}}
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" class="materialNo-w" data-label="Plant Name :">
                                                    {{element.material}}
                                                </mat-cell>
                                            </ng-container>
                                            <!-- <ng-container matColumnDef="materialGroup">
                                                <mat-header-cell *matHeaderCellDef class="table-header materialGroup-w" mat-sort-header >
                                                    {{language.materialGroup}}
                                                </mat-header-cell>>
                                                <mat-cell *matCellDef="let element" class="materialGroup-w" data-label="Material Group :">
                                                    {{element.materialGroup}}
                                                </mat-cell>
                                            </ng-container> -->
                                            <ng-container matColumnDef="shortText">
                                                <mat-header-cell *matHeaderCellDef class="table-header shortText1-w" mat-sort-header >
                                                    {{language.shortText}}
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" class="shortText1-w" data-label="Short Text :">
                                                    {{element.shortText}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="scheduledQuantity">
                                                <mat-header-cell *matHeaderCellDef class="table-header poQty-w" mat-sort-header > 
                                                    {{language.scheduledQuantity}}
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" class="poQty-w" data-label="PO Qty :">
                                                    <span *ngIf="element.itemCategory != 'D'">
                                                        {{element.scheduledQuantity | number : '1.3-3'}}
                                                    </span>
                                                    <span *ngIf="element.itemCategory == 'D'">
                                                        {{element.sv_scheduleQuantity | number : '1.3-3'}}
                                                    </span>                                              
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="outStandingUnit">
                                                <mat-header-cell *matHeaderCellDef class="table-header poItem-w" mat-sort-header > 
                                                    {{language.unit}}
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" class="poItem-w" data-label="PO Qty :">
                                                    {{element.outStandingUnit}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="poDeliveryDate">
                                                <mat-header-cell *matHeaderCellDef class="table-header plantCode-w" mat-sort-header > 
                                                    {{language.deliverydate}}
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" class="plantCode-w" data-label="PO Qty :">
                                                    {{element.poDeliveryDate | date:"dd/MM/yyyy"}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="grQuantity">
                                                <mat-header-cell *matHeaderCellDef class="table-header receivedQty-w" mat-sort-header >{{language.grQuantity}}
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" class="receivedQty-w" data-label="Received Qty : ">
                                                    <span *ngIf="element.itemCategory != 'D'">
                                                            {{element.grQuantity | number : '1.3-3'}}
                                                    </span>
                                                    <span *ngIf="element.itemCategory == 'D'">
                                                            {{element.svDeliverdQuantity | number : '1.3-3'}}
                                                    </span>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="outStandingQuantity">
                                                <mat-header-cell *matHeaderCellDef class="table-header pendingQty-w " mat-sort-header > 
                                                    {{language.outStandingQuantity}}
                                                </mat-header-cell>>
                                                <mat-cell *matCellDef="let element" class="pendingQty-w" data-label="Pending Qty : ">
                                                    <span *ngIf="element.itemCategory != 'D'">
                                                            {{element.outStandingQuantity | number : '1.3-3'}}
                                                    </span>
                                                    <span *ngIf="element.itemCategory == 'D'">
                                                            {{element.svOutStandingQuantity | number : '1.3-3'}}
                                                    </span>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="netPrice">
                                                <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header > 
                                                    {{language.netPrice}}
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" class="totalAmt-w" data-label="Unit Price : ">
                                                    <span *ngIf="element.itemCategory != 'D'">
                                                            {{element.netPrice | number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="element.itemCategory == 'D'">
                                                            {{(element.sv_grossValue/element.sv_scheduleQuantity) | number : '1.2-2'}}
                                                    </span>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="grossValue">
                                                <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header >
                                                    {{language.grossValue}}
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" class="totalAmt-w" data-label="Item Amount : ">
                                                    <span *ngIf="element.itemCategory != 'D'">
                                                            {{element.grossValue | number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="element.itemCategory == 'D'">
                                                            {{element.sv_grossValue | number : '1.2-2'}}
                                                    </span>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="invoicedAmt">
                                                <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header > {{language.invoicedAmt}}
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" class="totalAmt-w" data-label="Invoiced Amount : ">
                                                    <span *ngIf="element.itemCategory != 'D'">
                                                            {{element.grQuantity * element.netPrice | number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="element.itemCategory == 'D'">
                                                            {{ (element.sv_grossValue/element.sv_scheduleQuantity) * element.svDeliverdQuantity | number : '1.2-2'}}
                                                    </span>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="totalAmount">
                                                <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header >  {{language.totalAmount}}
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" class="totalAmt-w" data-label="Total Amount : ">
                                                    <!-- {{element.outStandingQuantity * element.netPrice | number : '1.2-2' }} -->
                                                    {{element.grossValue | number : '1.2-2'}}
                                                </mat-cell>
                                            </ng-container>
                                
                                <!--
                                            <ng-container matColumnDef="poCreatedDate">
                                                <mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>
                                                    PO date </mat-header-cell>>
                                                <mat-cell *matCellDef="let element" data-label="Po Date:-">
                                                    {{element.poCreatedDate | date:"dd/MM/yyyy" }}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="scheduledQuantity">
                                                <mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>
                                                    PO Quantity</mat-header-cell>>
                                                <mat-cell *matCellDef="let element" class="min-w" data-label="poQty-">
                                                    {{element.scheduledQuantity}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="grossValue">
                                                <mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Total Cost
                                                </mat-header-cell>>
                                                <mat-cell *matCellDef="let element" class="min-w" data-label="grossValue">
                                                    {{element.grossValue}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="poDeliveryDate">
                                                <mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Delivery date
                                                </mat-header-cell>>
                                                <mat-cell *matCellDef="let element" data-label="Delivery Date:-">
                                                    {{element.poDeliveryDate | date:"dd/MM/yyyy" }}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="headerTextDeliveryTo">
                                                <mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Delivery location
                                                </mat-header-cell>>
                                                <mat-cell *matCellDef="let element" data-label="Delivery Location:-">
                                                    {{element.headerTextDeliveryTo}}
                                                </mat-cell>
                                            </ng-container> -->
                                          
                                            <mat-header-row *matHeaderRowDef="columnsToDisplay">
                                            </mat-header-row>
                                            <mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
                                                [class.example-expanded-row]="expandedElement === element"
                                                (click)="expandedElement = expandedElement === element ? null : element">
                                        </mat-row>>
                            </mat-table>
                        </mat-card>
                    </div>
                        <mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" 
                            [pageSize]="pageSize" [length]="listLength" [pageSizeOptions]="[5, 10, 20]" 
                            [pageSizeOptions]="[10, 25, 50, 100]" 
                            (page)="getPurchaseOrderList($event, '')"  style="width:100%">
                        </mat-paginator>
            </div>
        </div>
        <div class="overlay"  *ngIf="mySpinner">
            <div class="center">
                <mat-spinner></mat-spinner> 
            </div>
            </div>
    </mat-card-content>
</mat-card>