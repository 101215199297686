// my-modal.component.ts
import { Component, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { RoleManagementComponent } from '../role-management.component';
import { BehaviorSubject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { AdminServiceService } from 'src/app/services/admin-service.service';
import { first } from 'rxjs/operators';

/**
 * Node for to-do item
 */
export class TodoItemNode {
  children: TodoItemNode[];
  item: string;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
}

/**
 * The Json object for to-do list data.
 */
const TREE_DATA = {
  'Dashboard': null,
  'User Management': null,
  'Role Management': null,
  "Vendor Management": {
    'Vendor List': null,
    'Vendor Users': null
  },
  "Purchase Order": {
    'PO Pending': null,
    'PO History': null
  },
  "Delivery Notes": {
    'DN Pending': null,
    'DN History': null
  },
  "Invoices": {
    'Invoice Pending': null,
    'Invoice History': null,
    'Invoice in Progress': null,
    'SES History': null
  },
  "Reports": {
    'AP Report': null,
    'Checker Report': null,
    'Vendor Report': null
  },
  "Configurations": null,
  "Weigh Bridge": null,
  "Response Messages": null,
  "Notifications": null,
  "Audit": null,
  "Profile": null,
  "Change Password": null
};

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }

  constructor() {
    this.initialize();
  }

  initialize() {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    const data = this.buildFileTree(TREE_DATA, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(obj: { [key: string]: any }, level: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TodoItemNode();
      node.item = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to to-do list */
  insertItem(parent: TodoItemNode, name: string) {
    if (parent.children) {
      parent.children.push({ item: name } as TodoItemNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: TodoItemNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }
}

/**
 * @title Tree with checkboxes
 */


@Component({
  selector: 'app-role-modal',
  templateUrl: './role-model.html',
  styleUrls: ['./role-model.scss']
})

export class RoleModel {
  local_data: any;
  action: any;
  status: boolean = false;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
  roleId: any;
  getRoleByIdMenue:any = [];
  selected:any = [];
  constructor(private fb: FormBuilder, private router: Router, private _database: ChecklistDatabase,
    public dialogRef: MatDialogRef<RoleModel>, private cService: AdminServiceService,
    @Inject(MAT_DIALOG_DATA) public data: RoleManagementComponent) {
    this.local_data = { ...data };
    
    this.action = this.local_data.action;
    this.roleId = this.local_data.id;
    if (this.action == 'Add') {
      this.status = false;
    } else {
      this.status = true;
    }
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  roleForm: FormGroup = this.fb.group({
    rolename: ['', [Validators.required]],
    roledesc: ['', [Validators.required]],
  })
  mySpinner:boolean=false;
  ngOnInit(): void {
    this.roleForm = this.fb.group({
      rolename: ['', [Validators.required]],
      roledesc: ['', [Validators.required]],

    })
    this.getSelectedlistheirarchyRoles();
    
  }
  selectedHeirarchay:any=[];
  async getSelectedlistheirarchyRoles() {
    // throw new Error('Method not implemented.');
    (await this.cService.getListHeirarchy(this.roleId)).pipe(first()).subscribe(res => {
      // 
      if (res != null) {
        this.selectedHeirarchay = res.response;
        let menus = res.response;
        // 
        for (let i of menus) {
          if (i.isParent == 'Y') {
            this.menuList.push(i);
            if (i.children != undefined) {
              for (let x of i.children) {
                this.menuList.push(x);
              }
            }
          } else {
            this.menuList.push(i)
          }
        }
        // 
        this.getSelectedRoles();
      }
    })
  }

  async getSelectedRoles() {
    // let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    (await this.cService.getSelectedRoles(this.roleId)).pipe(first()).subscribe(res => {
      // 
      
      if (res != null) {
        let menus = res.response;
        let menuss = [];
        
        for(let x = 0; x <= menus.length-1;x++){
          // 
          for(let y = 0; y <= this.selectedHeirarchay.length-1;y++){
            // 
            if(menus[x].id == this.selectedHeirarchay[y].id){
              if (menus[x].isParent == 'Y') {
                
                if (this.selectedHeirarchay[y].children != undefined) {
                  let obj = {
                    "id": this.selectedHeirarchay[y].id,
                    "rowVersion": this.selectedHeirarchay[y].rowVersion,
                    "menuName": this.selectedHeirarchay[y].menuName,
                    "menuUrl": this.selectedHeirarchay[y].menuUrl,
                    "isParent": this.selectedHeirarchay[y].isParent,
                    "orderId": this.selectedHeirarchay[y].orderId,
                    "levelId": this.selectedHeirarchay[y].levelId,
                    "children":this.selectedHeirarchay[y].children
                  }
                  menuss.push(obj);
                }
              } else if(menus[x].parentId != 11 || menus[x].parentId != 2 || menus[x].parentId != 3 || menus[x].parentId != 5) {
                
                menuss.push(menus[x])
                this.getRoleByIdMenue.push(menus[x])
              }
            }
          }
        }
        
        
        
        for (let j = 0; j <= menuss.length - 1; j++) {
          for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
            // 
            if (menuss[j].menuName == this.treeControl.dataNodes[i].item) {
              // 
              // 
              // 
              // 
              this.todoItemSelectionToggle(this.treeControl.dataNodes[i]);
              this.treeControl.expand(this.treeControl.dataNodes[i])
            }

          }
        }

      }
    })
  }

  menuList: any = [];

  onNoClick(): void {

    this.dialogRef.close({ event: this.action, data: this.roleForm.value });
    // this.dialogRef.close({ event: this.action, data: this.local_data });
    this.dialogRef.close({
      //food: this.food
      rolename: this.roleForm.value.rolename,
      roledesc: this.roleForm.value.roledesc
    });

  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: TodoItemFlatNode) {
    const parentNode = this.flatNodeMap.get(node);
    this._database.insertItem(parentNode!, '');
    this.treeControl.expand(node);
  }

  /** Save the node to database */
  saveNode(node: TodoItemFlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    this._database.updateItem(nestedNode!, itemValue);
  }

  async getSelectedValue() {

    const partial = this.treeControl.dataNodes.filter(x => this.descendantsPartiallySelected(x))

    let test: any = [];
    let obj: any = {};
    this.checklistSelection.selected.forEach(s => {
      test.push(s.item);
    });
    let selected = [];
    for (let i = 0; i <= this.menuList.length - 1; i++) {
      for (let x = 0; x <= test.length - 1; x++) {
        if (this.menuList[i].menuName == test[x]) {

          obj = {
            "menuId": this.menuList[i].id,
          };
          selected.push(obj);
          this.selected.push(obj)
        }
        // 
      }
    }
    

    
    
    
    let newArray5 = [];
    let unchecked = [];
    // Declare an empty object
    let uniqueObject5 = {};
    // Loop for the array elements
    for (let i in selected) {
      // Extract the title
      let objTitle = selected[i]['menuId'];
      // Use the title as the index
      uniqueObject5[objTitle] = selected[i];
    }
    // Loop to push unique object into array
    for (let i in uniqueObject5) {
      newArray5.push(uniqueObject5[i]);
    }


    let match: boolean;
    let unselected = [];
    
    //approach one: inline using filter, every functions with condition. 
    unselected = this.getRoleByIdMenue.filter(r => this.selected.every(selectedMenu => selectedMenu.menuId!==r.orderId));
    
    
    // unselected = [];
    

    //approach two: usual nesting looping and if condition. 
    // for (let j = 0; j < this.getRoleByIdMenue.length; j++) {
    //   for (let i = 0; i < selected.length; i++) {
        
    //     if (this.getRoleByIdMenue[j].orderId == selected[i].menuId) {
    //       match=true;
    //       break;
    //     }else {
    //       match=false;
    //     }
    //   }
    //   !match? unselected.push(this.getRoleByIdMenue[j]) : null;
    // }
    for (let i = 0; i <= unselected.length - 1; i++) {
      let obj1 = {
        "menuId":unselected[i].orderId
      }
      unchecked.push(obj1);
      
    }

    
    
    
    
    
    this.mySpinner = true;
    (await this.cService.saveSelectedRoles(this.roleId, newArray5)).pipe(first()).subscribe(res => {
      // 
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        this.mySpinner = false;
        this.deleteMenu(unchecked);
        Swal.fire(res.responseMessage);
        // this.dialogRef.close({ event: this.action, data: {} });
      } else {
        this.mySpinner = false;
        // swal.fire(res["respMessage"])
        Swal.fire(res.responseMessage,"", "error");
      }

    }, error => {

    })

  }
  async deleteMenu(unchecked: any){
    this.mySpinner = true;
    (await this.cService.deleteSelectedRoles(this.roleId, unchecked)).pipe(first()).subscribe(res => {
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        this.mySpinner = false;
        this.dialogRef.close({ event: this.action, data: {} });
      } else {
        this.mySpinner = false;
        Swal.fire(res.responseMessage);
      }
    })
  }
}