<mat-card>
    <mat-card-title>
        <div class="margin-bottotm" fxLayout="row" fxFlex="100">
            <div class="pull-left title" fxFlex="80">
                {{language.Head}}
            </div>
            <!-- <div class="pull-right" fxFlex="20">
                <mat-icon  class="close1">
                    close
                </mat-icon>
            </div> -->
        </div>
    </mat-card-title>
    <hr>

    <mat-card-content>
<div fxFlex="100"  fxLayoutWrap="column">
        
    <form [formGroup]="vendorReportForm">
        <div fxLayout="row">
            <div class="leftBox"  fxFlex="50" fxLayout="column">
            <div fxFlex="100" fxLayout="row">
            <div fxFlex="25">
                <p class="fontS label">{{language.company}}</p>
            </div>
            <div fxFlex="75">
                <ng-multiselect-dropdown formControlName="multiSelectCompany" placeholder="{{language.selectCompany}}"
                    [settings]="dropdownSettings" [data]="companyMaster" (onSelect)="onSelect($event)" (onSelectAll)="onSelectAll($event)"
                    (onDeSelect)="onDeSelect($event)" (onDeSelectAll)="onDeSelectAll($event)">
                </ng-multiselect-dropdown>
            </div>
            </div>
            <div fxFlex="100" fxLayout="row">
                    <div fxFlex="25">
                        <p class="fontS label">{{language.status}}</p>
                    </div>
                    <div fxFlex="75">
                        <ng-multiselect-dropdown formControlName="multiSelectStatus"
                        placeholder="{{language.SelectStatus}}"
                        [settings]="dropdownSettings1"
                        [data]="invoiceStatus"
                        (onSelect)="onSelect1($event)"
                        (onDeSelect)="onDeSelect1($event)"
                        (onSelectAll)="onSelectAll1($event)"
                        (onDeSelectAll)="onDeSelectAll1($event)">
                      </ng-multiselect-dropdown>
                    </div>
                </div>
                <div fxFlex="100" fxLayout="row">
                        <div fxFlex="25">
                            <p class="fontS label">{{language.poNumber}}</p>
                        </div>
                        <div fxFlex="35">
                            <mat-form-field class="example-full-width input" appearance="outline">
                                <!-- <mat-label>PO Number</mat-label> -->
                                <input matInput placeholder="{{language.EnterPONumberFrom}}" 
                                formControlName="purchaseOrderNoFrom"
                                [(ngModel)]="poNoFrom">
                              </mat-form-field>
                        </div>
                        <div fxFlex="5"><p class="fontS to">-</p></div>
                        <div fxFlex="35">
                                <mat-form-field  class="example-full-width input" appearance="outline">
                                    <!-- <mat-label>To</mat-label> -->
                                    <input  matInput placeholder="{{language.EnterPONumberTo}}"
                                    formControlName="purchaseOrderNoTo"
                                    [(ngModel)]="poNoTo">
                                  </mat-form-field>
                            </div>
                        </div>
            <div fxFlex="100" fxLayout="row" [formGroup]="dnRange" >
            <div fxFlex="25">
                <p class="fontS label">{{language.DeliveryNoteDate}}</p>
            </div>
            <div fxFlex="35" fxFlex.xs="100">
                <mat-form-field appearance="outline" class="validations">
                    <input matInput formControlName="deliveryNoteDateFrom" (dateChange)="getDNStartDate($event)"
                        [matDatepicker]="dnPickerStart" [max]="maxDate" readonly />
                    <mat-datepicker-toggle matSuffix [for]="dnPickerStart">
                    </mat-datepicker-toggle>
                    <mat-datepicker #dnPickerStart></mat-datepicker>
                </mat-form-field>
            </div>
            <div fxFlex="5">
                <p class="fontS to">-</p>
            </div>
            <div fxFlex="35" fxFlex.xs="100">
                <mat-form-field appearance="outline" class="validations">
                    <input matInput formControlName="deliveryNoteDateTo" [disabled]="isDeliveryNoteEndDate" [max]="maxDate"
                        (dateChange)="getDNEndDate($event)" [matDatepicker]="dnPickerEnd" readonly />
                    <mat-datepicker-toggle matSuffix [for]="dnPickerEnd">
                    </mat-datepicker-toggle>
                    <mat-datepicker #dnPickerEnd></mat-datepicker>
                </mat-form-field>
            </div>
            </div>
            <div fxFlex="100" fxLayout="row" [formGroup]="inRange">
            <div fxFlex="25">
                <p class="fontS label">{{language.invoiceDate}}</p>
            </div>
            <div fxFlex="35" fxFlex.xs="100">
                <mat-form-field appearance="outline" class="validations">
                    <input matInput formControlName="invoiceDateFrom" (dateChange)="getInvoiceStartDate($event)"
                        [matDatepicker]="inPickerStart" [max]="maxDate" readonly />
                    <mat-datepicker-toggle matSuffix [for]="inPickerStart">
                    </mat-datepicker-toggle>
                    <mat-datepicker #inPickerStart></mat-datepicker>
                </mat-form-field>
            </div>
            <div fxFlex="5">
                <p class="fontS to">-</p>
            </div>
            <div fxFlex="35" fxFlex.xs="100">
                <mat-form-field appearance="outline" class="validations">
                    <input matInput formControlName="invoiceDateTo" [disabled]="isInvoiceEndDate" [max]="maxDate"
                        (dateChange)="getInvoiceEndDate($event)" [matDatepicker]="inPickerEnd" readonly />
                    <mat-datepicker-toggle matSuffix [for]="inPickerEnd">
                    </mat-datepicker-toggle>
                    <mat-datepicker #inPickerEnd></mat-datepicker>
                </mat-form-field>
            </div>
            </div>
            <div fxFlex="100" fxLayout="row" [formGroup]="postingRange">
                    <div fxFlex="25">
                        <p class="fontS label">{{language.postingDate}}</p>
                    </div>
                    <div fxFlex="35" fxFlex.xs="100">
                        <mat-form-field appearance="outline" class="validations">
                            <input matInput formControlName="postingDateFrom" (dateChange)="getpostingDateFrom($event)"
                                [matDatepicker]="pickerStart" [max]="maxDate" readonly />
                            <mat-datepicker-toggle matSuffix [for]="pickerStart">
                            </mat-datepicker-toggle>
                            <mat-datepicker #pickerStart></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div fxFlex="5">
                        <p class="fontS to">-</p>
                    </div>
                    <div fxFlex="35" fxFlex.xs="100">
                        <mat-form-field appearance="outline" class="validations">
                            <input matInput formControlName="postingDateTo"  [max]="maxDate" (dateChange)="getpostingDateTo($event)"
                            [disabled]="isStartDate"  [matDatepicker]="pickerEnd" readonly />
                            <mat-datepicker-toggle matSuffix [for]="pickerEnd">
                            </mat-datepicker-toggle>
                            <mat-datepicker #pickerEnd></mat-datepicker>
                        </mat-form-field>
                    </div>
                    </div>
            </div>
        
            <div class="rightBox" fxFlex="50" fxLayout="column">
                    <div fxFlex="100" fxLayout="row">
                            <div fxFlex="25">
                                <p class="fontS label">{{language.invoiceFor}}</p>
                            </div>
                            <div fxFlex="50">
                            <mat-form-field class="example-full-width input"appearance="outline">
                                    <mat-select appearance="outline" [(ngModel)]="InvoiceFor" formControlName="InvoiceFor" (valueChange)="onInvoiceForChange($event)">
                                      <mat-option *ngFor="let item of potypes" [value]="item.key">{{item.value}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                            </div>
                    </div>
                    <div fxFlex="100" fxLayout="row">
                            <div fxFlex="25">
                                <p class="fontS label">{{language.typeOfInvoice}}</p>
                            </div>
                            <div fxFlex="50">
                            <mat-form-field class="example-full-width input"appearance="outline">
                                    <mat-select appearance="outline" [(ngModel)]="typeOfInvoice" formControlName="typeOfInvoice" (valueChange)="ontypeOfInvoiceChange($event)">
                                      <mat-option *ngFor="let item of invoicetypes" [value]="item.key">{{item.value}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                            </div>
                    </div>
                    <div fxFlex="100" fxLayout="row">
                            <div fxFlex="25">
                                <p class="fontS label">{{language.InvoiceNumber}}</p>
                            </div>
                            <div fxFlex="50">
                                <mat-form-field class="example-full-width input" appearance="outline">
                                    <!-- <mat-label>From</mat-label> -->
                                    <input matInput placeholder="{{language.NumberFrom}}" 
                                    formControlName="invoiceNoFrom"
                                    [(ngModel)]="invoiceFrom">
                                  </mat-form-field>
                            </div>
                        </div>
                    <div fxFlex="100" fxLayout="row">
                            <div fxFlex="25">
                                <p class="fontS label">{{language.VPNumber}}</p>
                            </div>
                            <div fxFlex="50">
                                <mat-form-field class="example-full-width input" appearance="outline">
                                    <!-- <mat-label>VP Number</mat-label> -->
                                    <input matInput placeholder="{{language.EnterVPNumberFrom}}" 
                                    formControlName="vendorPortalRefNoFrom"
                                    [(ngModel)]="vpNoFrom" maxlength="10" (keypress)="numberOnly($event)">
                                  </mat-form-field>
                                </div>
                                <div fxFlex="5"><p class="fontS to">-</p></div>
                                <div fxFlex="35">
                                    <mat-form-field  class="example-full-width input" appearance="outline">
                                        <!-- <mat-label>To</mat-label> -->
                                        <input  matInput placeholder="{{language.EnterVPNumberTo}}"
                                        formControlName="vendorPortalRefNoTo"
                                        [(ngModel)]="vpNoTo" maxlength="10" (keypress)="numberOnly($event)">
                                      </mat-form-field>
                                </div>
                            </div>
                            <div fxFlex="100" fxLayout="row">
                                    <div fxFlex="25">
                                        <p class="fontS label">{{language.VPDNNumber}}</p>
                                    </div>
                                    <div fxFlex="50">
                                        <mat-form-field class="example-full-width input" appearance="outline">
                                            <!-- <mat-label>VP Number</mat-label> -->
                                            <input matInput placeholder="{{language.EnterVPDNNumberFrom}}" 
                                            formControlName="vpdnFrom"
                                            [(ngModel)]="vpdnFrom" maxlength="10" (keypress)="numberOnly($event)">
                                          </mat-form-field>
                                        </div>
                                        <div fxFlex="5"><p class="fontS to">-</p></div>
                                        <div fxFlex="35">
                                            <mat-form-field  class="example-full-width input" appearance="outline">
                                                <!-- <mat-label>To</mat-label> -->
                                                <input  matInput placeholder="{{language.EnterVPDNNumberTo}}"
                                                formControlName="vpdnTo"
                                                [(ngModel)]="vpdnTo" maxlength="10" (keypress)="numberOnly($event)">
                                              </mat-form-field>
                                        </div>
                                    </div>
                                    <div fxFlex="100" fxLayout="row" [formGroup]="netDueDateRange">
                                            <div fxFlex="25">
                                                <p class="fontS label">{{language.netDueDate}}</p>
                                            </div>
                                            <div fxFlex="35" fxFlex.xs="100">
                                                <mat-form-field appearance="outline" class="validations">
                                                    <input matInput formControlName="netDueDateFrom" (dateChange)="getnetDateFrom($event)"
                                                        [matDatepicker]="netpickerStart" [max]="maxDate" readonly />
                                                    <mat-datepicker-toggle matSuffix [for]="netpickerStart">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #netpickerStart></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="5">
                                                <p class="fontS to">-</p>
                                            </div>
                                            <div fxFlex="35" fxFlex.xs="100">
                                                <mat-form-field appearance="outline" class="validations">
                                                    <input matInput formControlName="netDueDateTo"  [max]="maxDate" (dateChange)="getnetDueDateTo($event)"
                                                    [disabled]="isNetDuedate"  [matDatepicker]="netpickerEnd" readonly />
                                                    <mat-datepicker-toggle matSuffix [for]="netpickerEnd">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #netpickerEnd></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                            </div>
            <div fxFlex="100" fxLayout="row">
                <div fxFlex="25">
                    <p class="fontS label">{{language.DeliveryNoteNo}}</p>
                </div>
                    <div fxFlex="50">
                        <mat-form-field class="example-full-width input" appearance="outline">
                            <!-- <mat-label>VP Number</mat-label> -->
                            <input matInput placeholder="{{language.EnterVPNumber}}" 
                            formControlName="deliveryNoteNo"
                            [(ngModel)]="DNNo">
                          </mat-form-field>
                        </div>
            </div>
            <div fxFlex="100" fxLayout="row">
                    <div fxFlex="25">
                        <p class="fontS label">{{language.calculatetaxx}}</p>
                    </div>
                    <div fxFlex="50">
                    <mat-form-field class="example-full-width input"appearance="outline">
                            <mat-select appearance="outline" [(ngModel)]="calculatetax" formControlName="calculatetax" (valueChange)="ontypeOfcalculateTaxChange($event)">
                              <mat-option *ngFor="let item of calculatetaxtypes" [value]="item.key">{{item.value}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
            </div>
            <div fxFlex="100" fxLayout="row" *ngIf="currentUser.roleID != '2'">
                    <div fxFlex="25">
                        <p class="fontS label">{{language.vedorCode}}</p>
                    </div>
                    <div fxFlex="75">
                        <ng-multiselect-dropdown formControlName="multiSelectVendor" placeholder="{{language.vedorCode}}"
                            [settings]="dropdownSettings2" [data]="vendorMaster" (onSelect)="onSelect2($event)" (onSelectAll)="onSelectAll2($event)"
                            (onDeSelect)="onDeSelect2($event)" (onDeSelectAll)="onDeSelectAll2($event)">
                        </ng-multiselect-dropdown>
                    </div>
                    </div>
            </div>
        </div>
    </form>
   
    <div>
        <div class="buttonGroup">
            <button mat-flat-button (click)="onSubmit()">{{language.Search}}</button>
            <button mat-flat-button (click)="onClear()">{{language.Clear}}</button>
        </div>
    </div>
</div>
        
    </mat-card-content>
</mat-card>
<mat-card class="reportTable">
    <mat-card-content>
            <div fxLayout="row" fxLayout.xs="column">
                    <div>
                            <p class="fontS label" style="margin-left:15px">Found:&nbsp;{{listLength == undefined?0:listLength}}&nbsp;Records</p>
                        </div>
                    <div fxFlex.gt-lg="80" fxFlex.gt-md="80" fxFlex.gt-xs="100" fxFlex="100"></div>       
                    <!-- <div fxFlex="10" fxFlex.sm="10" fxFlex.xs="100">           
                        <div class="addUserParent">           
                                <a (click)="openPDF()" style="cursor:pointer"><img src="assets/images/icons8-export-pdf-100.png" height="50px" width="50px" ></a>         
                        </div>          
                    </div>            -->
                    <div fxFlex="7" fxFlex.sm="7" fxFlex.xs="100">       
                       <div class="addUserParent">          
                            <a (click)="exportexcel()" style="cursor:pointer"><img src="assets/images/icons8-export-excel-100.png" height="50px" width="50px" ></a>           
                        </div>           
                    </div>          
                </div>
        <div class="overflow">
            <mat-card>
                <mat-table [dataSource]="dataSource" id="matTable" matSort multiTemplateDataRows
                [ngClass] = "currentUser.roleID == '2'?'reportTable':'reportTable2'">
                    <!-- <span > -->
                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef class="status-w" mat-sort-header>
                                {{language.status}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="Status :" class="status-w">
                                {{(element.status == null || element.status == undefined)? '-' : element.status}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="company">
                            <mat-header-cell *matHeaderCellDef class="table-header shortText1-w"  mat-sort-header>
                                {{language.company}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="shortText1-w" data-label="Company :">
                                {{(element.company == null || element.company == undefined)? '-' : element.company}}
                            </mat-cell>
                        </ng-container>  
                        <ng-container matColumnDef="vendorCode">
                                <mat-header-cell *matHeaderCellDef class="vendorCode-w"  mat-sort-header>
                                    {{language.vendorCode}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="vendorCode-w" data-label="Company :">
                                 {{(element.vendorCode == null || element.vendorCode == undefined)? '-' : element.vendorCode}}
                                </mat-cell>
                            </ng-container>     
                            <ng-container matColumnDef="name">
                                    <mat-header-cell *matHeaderCellDef class="vendorCode-w"  mat-sort-header>
                                        {{language.name}}
                                     </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="vendorCode-w" data-label="Company :">
                                     {{(element.vendorName == null || element.vendorName == undefined)? '-' : element.vendorName}}
                                    </mat-cell>
                            </ng-container>    
                            <ng-container matColumnDef="sapResponse">
                                    <mat-header-cell *matHeaderCellDef class="companyName-w"  mat-sort-header>
                                        {{language.sapResponse}}
                                     </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="companyName-w" data-label="Company :">
                                            <span  class="textWrap" matTooltipClass="custom-tooltip" matTooltipPosition="before"  matTooltip="{{element.sapResponse}}">{{element.sapResponse ? element.sapResponse : '-' }}</span>
                                    </mat-cell>
                            </ng-container> 
                        <ng-container matColumnDef="poItem">
                                <mat-header-cell *matHeaderCellDef class="poItem-ws"  mat-sort-header>
                                    {{language.poItem}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="poItem-ws" data-label="poItem :">
                                    {{(element.poItem == null || element.poItem == undefined)? '-' : element.poItem}}
                                </mat-cell>
                        </ng-container>     
                        <ng-container matColumnDef="plantCode">
                                <mat-header-cell *matHeaderCellDef class="poItem-ws"  mat-sort-header>
                                    {{language.plantCode}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="poItem-ws" data-label="plantCode :">
                                    {{(element.plantCode == null || element.plantCode == undefined)? '-' : element.plantCode}}
                                </mat-cell>
                        </ng-container>      
                        <ng-container matColumnDef="plantName">
                                <mat-header-cell *matHeaderCellDef class="table-header shortText1-w"  mat-sort-header>
                                    {{language.plantName}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="shortText1-w" data-label="plantName :">
                                    {{(element.plantName == null || element.plantName == undefined)? '-' : element.plantName}}
                                </mat-cell>
                        </ng-container>   
                        <ng-container matColumnDef="material">
                                <mat-header-cell *matHeaderCellDef class="DOC"  mat-sort-header>
                                    {{language.materialNo}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="DOC" data-label="plantName :">
                                    {{(element.material == null || element.material == undefined)? '-' : element.material}}
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="serviceNumber">
                                <mat-header-cell *matHeaderCellDef class="DOC"  mat-sort-header>
                                    {{language.serviceNumber}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="DOC" data-label="plantName :">
                                    {{(element.serviceNumber == null || element.serviceNumber == undefined)? '-' : element.serviceNumber}}
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="materialGroup">
                                <mat-header-cell *matHeaderCellDef class="DOC"  mat-sort-header>
                                    {{language.materialGroup}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="DOC" data-label="plantName :">
                                    {{(element.materialGroup == null || element.materialGroup == undefined)? '-' : element.materialGroup}}
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="shortText">
                                <mat-header-cell *matHeaderCellDef class="table-header shortText1-w" mat-sort-header>
                                    {{language.shortText}} 
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Plant Name:" class="shortText1-w">
                                   {{(element.shortText == null || element.shortText == undefined)? '-' : element.shortText}}
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="poQuantity">
                                <mat-header-cell *matHeaderCellDef class="poItem-ws" mat-sort-header>
                                    {{language.poQuantity}} 
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Plant Name:" class="poItem-ws">
                                   {{(element.poQuantity == null || element.poQuantity == undefined)? '-' : element.poQuantity | number : '1.3-3'}}
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="unit">
                                <mat-header-cell *matHeaderCellDef class="poItem-ws" mat-sort-header>
                                    {{language.unit}} 
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="Plant Name:" class="poItem-ws">
                                   {{(element.unit == null || element.unit == undefined)? '-' : element.unit}}
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="unitPrice">
                                <mat-header-cell *matHeaderCellDef class="totalAmt-w"  mat-sort-header>
                                    {{language.unitPrice}}
                                 </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="totalAmt-w" data-label="unitPrice :">
                                    {{(element.unitPrice == null || element.unitPrice == undefined)? '-' : element.unitPrice | number : '1.2-2'}}
                                </mat-cell>
                        </ng-container>  
                        <ng-container matColumnDef="baseLineDate">
                                <mat-header-cell *matHeaderCellDef class="deliveryNoteDate-w" mat-sort-header>
                                  {{language.baseLineDate}}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" data-label="baseLineDate :" class="deliveryNoteDate-w">
                                   {{(element.baseLineDate == null || element.baseLineDate == undefined)? '-' : element.baseLineDate | date:"dd/MM/yyyy"}}
                                </mat-cell>
                            </ng-container>                      
                    <ng-container matColumnDef="deliveryNoteNumber">
                        <mat-header-cell *matHeaderCellDef class="deliveryNoteNo-w" mat-sort-header>
                            {{language.DeliveryNoteNo}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label=" Delivery Note Number :" class="deliveryNoteNo-w">
                            {{(element.deliveryNoteNo == null || element.deliveryNoteNo == undefined)? '-' : element.deliveryNoteNo}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="deliveryNoteDate">
                        <mat-header-cell *matHeaderCellDef class="deliveryNoteDate-w" mat-sort-header>
                          {{language.DeliveryNoteDate}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="Delivery Note Date :" class="deliveryNoteDate-w">
                           {{(element.deliveryNoteDate == null || element.deliveryNoteDate == undefined || element.deliveryNoteDate == "-")? '-' : element.deliveryNoteDate | date:"dd/MM/yyyy"}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="poNumber">
                        <mat-header-cell *matHeaderCellDef class="poNo-w" mat-sort-header>
                            {{language.po}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="PO Number :" class="poNo-w">
                           {{(element.poNumber == null || element.poNumber == undefined)? '-' : element.poNumber}}
                        </mat-cell>
                    </ng-container> 
                    <ng-container matColumnDef="invoiceNo">
                        <mat-header-cell *matHeaderCellDef class="invNo-w" mat-sort-header>
                           {{language.Invoice}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="PO Number :" class="invNo-w">
                           {{(element.invoiceNumber == null || element.invoiceNumber == undefined)? '-' : element.invoiceNumber}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="sesNumber">
                        <mat-header-cell *matHeaderCellDef class="date-w" mat-sort-header>
                           {{language.sesNumber}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="PO Number :" class="date-w">
                           {{(element.sesNumber == null || element.sesNumber == undefined)? '-' : element.sesNumber}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="invoiceDate">
                        <mat-header-cell *matHeaderCellDef class="date-w" mat-sort-header>
                           {{language.invoiceDate}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="PO Number :" class="date-w">
                           {{(element.invoiceDate == null || element.invoiceDate == undefined)? '-' : element.invoiceDate | date:"dd/MM/yyyy"}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="totalAmount">
                        <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header>
                            {{language.totalAmount}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="Total Amount :" class="totalAmt-w">
                            {{(element.totalAmount == null || element.totalAmount == undefined)? '-' : element.totalAmount | number : '1.2-2'}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="totalQuantity">
                            <mat-header-cell *matHeaderCellDef class="poItem-ws" mat-sort-header>
                                {{language.totalQuantity}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="totalQuantity :" class="poItem-ws">
                                {{(element.totalQuantity == null || element.totalQuantity == undefined)? '-' : element.totalQuantity | number : '1.3-3'}}
                            </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="amountVat">
                            <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header>
                                {{language.amountVat}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="amountVat :" class="totalAmt-w">
                                {{(element.amountVat == null || element.amountVat == undefined)? '-' : element.amountVat | number : '1.2-2'}}
                            </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="currency">
                        <mat-header-cell *matHeaderCellDef class="currency-w" mat-sort-header> 
                            {{language.Currency}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="Currency :" class="currency-w" >
                            {{(element.currency == null || element.currency == undefined)? '-' : element.currency}}
                        </mat-cell>
                    </ng-container>   
                    <ng-container matColumnDef="paymentTerms">
                        <mat-header-cell *matHeaderCellDef class="paymentTerms-w" mat-sort-header> 
                            {{language.PaymentTerm}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="paymentTerms-w" data-label="Payment Terms :">
                            {{(element.paymentTerms == null || element.paymentTerms == undefined)? '-' : element.paymentTerms}}
                        </mat-cell>
                    </ng-container>   
                    <ng-container matColumnDef="netDueDate">
                        <mat-header-cell *matHeaderCellDef class="date-w" mat-sort-header>
                           {{language.netDueDate}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="PO Number :" class="date-w">
                           {{(element.netDueDate == null || element.netDueDate == undefined)? '-' : element.netDueDate | date:"dd/MM/yyyy"}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="typeOfInvoice">
                        <mat-header-cell *matHeaderCellDef class="materialDocNum-w" mat-sort-header>
                           {{language.typeOfInvoice}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="PO Number :" class="materialDocNum-w">
                           {{(element.typeOfInvoice == null || element.typeOfInvoice == undefined)? '-' : element.typeOfInvoice}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="fiDocNumber">
                            <mat-header-cell *matHeaderCellDef class="materialDocNum-w" mat-sort-header>
                               {{language.fiDocNumber}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="fiDocNumber :" class="materialDocNum-w">
                               {{(element.fiDocNumber == null || element.fiDocNumber == undefined)? '-' : element.fiDocNumber}}
                            </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="calculateTax">
                            <mat-header-cell *matHeaderCellDef class="materialDocNum-w" mat-sort-header>
                               {{language.calculateTax}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="calculateTax :" class="materialDocNum-w">
                               {{(element.calculateTax == null || element.calculateTax == undefined)? '-' : element.calculateTax}}
                            </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="invoiceFor">
                        <mat-header-cell *matHeaderCellDef class="date-w" mat-sort-header>
                           {{language.invoiceFor}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="date-w" data-label="PAYMENT DOC. :">
                           {{(element.invoiceFor == null || element.invoiceFor == undefined)? '-' : element.invoiceFor}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="invoiceDocNumber">
                        <mat-header-cell *matHeaderCellDef class="paymentMethod-w" mat-sort-header>
                            {{language.paymentMethod}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="paymentMethod-w" data-label="PAYMENT METHODS :">
                           {{(element.paymentMethods == null || element.paymentMethods == undefined)? '-' : element.paymentMethods}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="paymentMethod">
                        <mat-header-cell *matHeaderCellDef class="paymentMethod-w" mat-sort-header>
                           {{language.DOC}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="paymentMethod-w" data-label="INVOICE DOC. No :">
                           {{(element.invoiceDocNumber == null || element.invoiceDocNumber == undefined)? '-' : element.invoiceDocNumber}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="invoicePostingDate">
                        <mat-header-cell *matHeaderCellDef class="date-w" mat-sort-header>
                           {{language.postingDate}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="INVOICE POSTING DATE :" class="date-w">
                           {{(element.postingDate == null || element.postingDate == undefined)? '-' : element.postingDate | date:"dd/MM/yyyy"}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="whtType1">
                        <mat-header-cell *matHeaderCellDef class="whtType-w" mat-sort-header>
                            {{language.WHTTYPE1}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="whtType-w" data-label="WHT TYPE 1 :">
                           {{(element.whtType1 == null || element.whtType1 == undefined)? '-' : element.whtType1}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="whtCode1">
                        <mat-header-cell *matHeaderCellDef class="whtCode-w" mat-sort-header>
                            {{language.WHTCODE1}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="whtCode-w" data-label="WHT CODE 1 :">
                           {{(element.whtCode1 == null || element.whtCode1 == undefined)? '-' : element.whtCode1}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="whtBaseAmount1">
                        <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header>
                            {{language.WHTBASEAmt1}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="totalAmt-w" data-label="WHT BASE AMOUNT 1 :">
                           {{(element.whtBaseAmount1 == null || element.whtBaseAmount1 == undefined)? '-' : element.whtBaseAmount1 | number : '1.2-2'}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="whtAmount1">
                        <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header>
                            {{language.WHTAmt1}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="totalAmt-w" data-label="WHT Amt 1 :">
                           {{(element.whtTaxAmount1 == null || element.whtTaxAmount1 == undefined)? '-' : element.whtTaxAmount1 | number : '1.2-2'}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="whtType2">
                        <mat-header-cell *matHeaderCellDef class="whtType-w"  mat-sort-header>
                            {{language.WHTTYPE2}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="whtType-w" data-label="WHT TYPE 2 :">
                           {{(element.whtType2 == null || element.whtType2 == undefined)? '-' : element.whtType2}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="whtCode2">
                        <mat-header-cell *matHeaderCellDef class="whtCode-w"  mat-sort-header>
                            {{language.WHTCODE2}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="whtCode-w" data-label="WHT CODE 2 :">
                           {{(element.whtCode2 == null || element.whtCode2 == undefined)? '-' : element.whtCode2}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="whtBaseAmount2">
                        <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header>
                            {{language.WHTBASEAmt2}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="totalAmt-w" data-label="WHT BASE AMOUNT 2 :">
                           {{(element.whtBaseAmount2 == null || element.whtBaseAmount2 == undefined)? '-' : element.whtBaseAmount2 | number : '1.2-2'}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="whtAmount2">
                        <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header>
                            {{language.WHTAmt2}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="totalAmt-w" data-label="WHT Amt 2 :">
                           {{(element.whtTaxAmount2 == null || element.whtTaxAmount2 == undefined)? '-' : element.whtTaxAmount2 | number : '1.2-2'}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="vpNumberDN">
                        <mat-header-cell *matHeaderCellDef class="companyName-w" mat-sort-header>
                            {{language.vp}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label=" VP# DN:" class="companyName-w">
                            {{(element.vpDnNumber == null || element.vpDnNumber == undefined)? '-' : element.vpDnNumber.substring(element.vpDnNumber.indexOf('_')+1)}}
                        </mat-cell>
                    </ng-container>                 
                    <ng-container matColumnDef="vpNumberInvoice">
                        <mat-header-cell *matHeaderCellDef class="companyName-w" mat-sort-header>
                            {{language.vpNo}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label=" VP# INVOICE:" class="companyName-w">
                            {{(element.vpNumber == null || element.vpNumber == undefined)? '-' : element.vpNumber.substring(element.vpNumber.indexOf('_')+1)}}
                        </mat-cell>
                    </ng-container>                 
                   
                               
                   <mat-header-row *matHeaderRowDef="columnsToDisplay">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: columnsToDisplay;">{{language.Pending}}</mat-row>

                </mat-table>
            </mat-card>
        </div>
        <mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" [pageSize]="pageSize"
        [length]="listLength"
        [pageSizeOptions]="[10, 25, 50, 100]" (page)="setTableData($event)"
        style="width:100%"></mat-paginator>
    </mat-card-content>
</mat-card>
<div class="overlay" *ngIf="mySpinner">
    <div class="center">
        <mat-spinner></mat-spinner>
    </div>
</div>
