import { Pipe, PipeTransform } from "@angular/core";
import { ToWords } from 'to-words';
@Pipe({
  name: "AmountToWordPipe",
})
export class AmountToWordPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const toWords = new ToWords({
      localeCode: 'en-US',
      converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: true,
        doNotAddOnly: false,
      }
    });
     if (value) {
    //   value = parseFloat(value).toFixed(2);
    //   
    //   // Nine Zeroes for Billions
    //   return Math.abs(Number(value)) >= 1.0e9
    //     ? Math.abs(Number(value)) / 1.0e9 + " " + "Billion" + " " + "BAHT"
    //     : // Six Zeroes for Millions
    //     Math.abs(Number(value)) >= 1.0e6
    //     ? Math.abs(Number(value)) / 1.0e6 + " " + "Million" + " " + "BAHT"
    //     : // Three Zeroes for Thousands
    //     Math.abs(Number(value)) >= 1.0e3
    //     ? Math.abs(Number(value)) / 1.0e3 + " " + "Thousand" + " " + "BAHT"
    //     : Math.abs(Number(value)) >= 1.0e2
    //     ? Math.abs(Number(value)) / 1.0e2 + " " + "Hundred" + " " + "BAHT"
    //     : Math.abs(Number(value));
 
      value = parseFloat(value).toFixed(2);
      let ttl= toWords.convert(value);

      function replaceAll(str, find, replace) {
        var escapedFind=find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return str.replace(new RegExp(escapedFind, 'g'), replace);
    }

    var bhat=replaceAll(ttl,"Dollars","BAHT"); 
    var final=replaceAll(bhat,"Cents","SATANG"); 
      return final;

    
      // let amounth = value.toString().split(".");
      // let price: any = amounth[0];
      // let pointer: any = amounth.length > 0 ? amounth[1] : null;
      // var singleDigit = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"],
      //   doubleDigit = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"],
      //   tensPlace = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
      //   handle_tens = function (digit: any, prevdigit: any) {
      //     return 0 == digit ? "" : " " + (1 == digit ? doubleDigit[prevdigit] : tensPlace[digit])
      //   },
      //   handle_utlc = function (digit: any, nextdigit: any, denom: any) {
      //     return (0 != digit && 1 != nextdigit ? " " + singleDigit[digit] : "") + (0 != nextdigit || digit > 0 ? " " + denom : "")
      //   };

      // var BAHT = "",
      //   digitIndex = 0,
      //   digit = 0,
      //   nextDigit = 0,
      //   words = [],
      //   paisaWords = [],
      //   SATANG = "";
      // if (price += "", isNaN(parseFloat(price))) BAHT = "";
      // else if (parseFloat(price) > 0 && price.length <= 10) {
      //   for (digitIndex = price.length - 1; digitIndex >= 0; digitIndex--)
      //     switch (digit = price[digitIndex] - 0, nextDigit = digitIndex > 0 ? price[digitIndex - 1] - 0 : 0, price.length - digitIndex - 1) {
      //       case 0:
      //         words.push(handle_utlc(digit, nextDigit, ""));
      //         break;
      //       case 1:
      //         words.push(handle_tens(digit, price[digitIndex + 1]));
      //         break;
      //       case 2:
      //         words.push(0 != digit ? " " + singleDigit[digit] + " Hundred" + (0 != price[digitIndex + 1] && 0 != price[digitIndex + 2] ? " and" : "") : "");
      //         break;
      //       case 3:
      //         words.push(handle_utlc(digit, nextDigit, "Thousand"));
      //         break;
      //       case 4:
      //         words.push(handle_tens(digit, price[digitIndex + 1]));
      //         break;
      //       case 5:
      //         words.push(handle_utlc(digit, nextDigit, "Million"));
      //         break;
      //       case 6:
      //         words.push(handle_tens(digit, price[digitIndex + 1]));
      //         break;
      //       case 7:
      //         words.push(handle_utlc(digit, nextDigit, "Billion"));
      //         break;
      //       case 8:
      //         words.push(handle_tens(digit, price[digitIndex + 1]));
      //         break;
      //       case 9:
      //         words.push(0 != digit ? " " + singleDigit[digit] + " Hundred" + (0 != price[digitIndex + 1] || 0 != price[digitIndex + 2] ? " and" : " Crore") : "")
      //     }
      //   BAHT = words.reverse().join("")
      // } else BAHT = "";

      // if (BAHT)
      //   BAHT = `${BAHT} BAHT`

      // if (pointer != "00") {
      //   digitIndex = 0;
      //   digit = 0;
      //   nextDigit = 0;

      //   for (digitIndex = pointer.length - 1; digitIndex >= 0; digitIndex--)
      //     switch (digit = pointer[digitIndex] - 0, nextDigit = digitIndex > 0 ? pointer[digitIndex - 1] - 0 : 0, pointer.length - digitIndex - 1) {
      //       case 0:
      //         paisaWords.push(handle_utlc(digit, nextDigit, ""));
      //         break;
      //       case 1:
      //         paisaWords.push(handle_tens(digit, pointer[digitIndex + 1]));
      //         break;
      //     }
      //   SATANG = paisaWords.reverse().join("");
      //   if (BAHT)
      //     BAHT = `${BAHT} and ${SATANG} SATANG`
      //   else
      //     BAHT = `${SATANG} SATANG`
      // }
      // return BAHT
   
    }
  }
}