 <mat-card>
            <mat-card-title>
                <div fxLayout="row" fxFlex.gt-xs="100" fxFlex="100">
                    {{language.ChangePassword}}
                </div>                
            </mat-card-title>               
         <hr>
            <mat-card-content>
                <!-- <div fxLayout="column" fxLayoutWrap="wrap">
                    <div fxFlex.gt-sm="100%" fxFlex="100"> -->
                <!-- <mat-card-title>Change Password</mat-card-title> -->
                    <!-- Card column -->
               

                          
                                <form [formGroup]="changePwd">
                                   
                                    <div fxLayout="column" fxFlex="40" fxFlex.xs="100">
                                        <!-- <mat-card>
                                            <mat-card-content class="mat-content"> -->
                                            <mat-form-field class="validations" appearance="outline">
                                                <mat-label>{{language.CurrentPassword}}</mat-label>
                                                <input matInput placeholder="{{language.CurrentPassword}}" formControlName="oldPassword" required type="password">
                                            </mat-form-field>
                                
                                            <mat-form-field class="validations" appearance="outline">
                                                <mat-label>{{language.NewPassword}}</mat-label>
                                                <input matInput placeholder="{{language.NewPassword}}" formControlName="newPassword" required type="password">
                                            </mat-form-field>
                                
                                        <!-- <div class="errors" *ngIf="changePwd.get('newPassword').errors.minlength">
                                                Password must be at least 8 characters
                                            </div> -->
                                        <span class="errors-pass" *ngIf="changePwd.get('newPassword').hasError('passwordStrength')">
                                            {{changePwd.get('newPassword').errors['passwordStrength']}}
                                        </span>
                                
                                            <mat-form-field class="validations" appearance="outline">
                                                <mat-label>{{language.ConfirmPassword}}</mat-label>
                                                <input matInput placeholder="{{language.ConfirmPassword}}" formControlName="confirmPassword" required (keyup)="changePwdValid()" type="password">
                                            </mat-form-field>
                                
                                        <!-- <div class="errors" *ngIf="changePwd.get('confirmPassword').errors.minlength">
                                            Password must be at least 8 characters
                                        </div> -->
                                        <span class="errors-pass" *ngIf="changePwd.get('confirmPassword').hasError('passwordStrength')">
                                            {{changePwd.get('confirmPassword').errors['passwordStrength']}}
                                        </span>
                                
                                            <!-- <div class="modal-buttons" fxLayout.xs="row"> -->
                                            <div  fxLayout.xs="row">
                                                <button mat-raised-button class="submitButton" mat-raised-button (click)="onSubmit()">
                                                    {{language.Submit}}
                                                </button>
                                            </div>
                                        <!-- </mat-card-content>
                                    </mat-card> -->
                                    </div>
                          
                                                        </form>
                           
                        
                
            <!-- </div>
        </div> -->
            </mat-card-content>  
            <div class="overlay"  *ngIf="mySpinner">
                <div class="center">
                    <mat-spinner></mat-spinner> 
                </div>
                </div>
</mat-card>