import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonRejectComponent } from 'src/app/common-reject/common-reject.component';
import swal from 'sweetalert2';
import { DlApproveComponent } from '../dl-approve/dl-approve.component';
import { first } from 'rxjs/operators';
import { CommonServicesService } from 'src/app/services/common-services.service';
import { AdminServiceService } from 'src/app/services/admin-service.service';
import * as converter from 'number-to-words';
import {AmountToWordPipe} from 'src/app/amount-to-word.pipe'

@Component({
  selector: 'app-delivery-notes-info',
  templateUrl: './delivery-notes-info.component.html',
  styleUrls: ['./delivery-notes-info.component.css']
})
export class DeliveryNotesInfoComponent implements OnInit {
  mySpinner: boolean = false;
  constructor(
    private  router: Router,
    private route:ActivatedRoute,
    private dialog:MatDialog,
    private cService: CommonServicesService,
    private aService: AdminServiceService,
    // private amountToWord : AmountToWordPipe
  ) { }
  dataSource:any=[ 
    {
      'groupName':"PO12992", 'commissionType':"type", 'commission':"10", 'ticketQuantity':"100", 'ticketAmt':"1000",'status':"Pending"
    },
    {
      'groupName':"PO12993", 'commissionType':"type", 'commission':"5", 'ticketQuantity':"100", 'ticketAmt':"500",'status':"Pending"
    }
  ];
  columnsToDisplay = ["poNumber","poItem","materialGroup","material","shortText", "scheduledQuantity","grQuantity","outStandingQuantity","netPrice","grossValue","warehouseReceivedQuantity","totalAmount"];
  //columnsToDisplay = ['groupName', 'commissionType', 'commission', 'ticketQuantity', 'ticketAmt','status','actions'];
  sub:any;
  detailsTableData :any = [];
  routeFromD:any;
  warHouseUser:any;
  userType:any;
  data:any;
  language:any;
  currentUser: any;
  Source:any;
  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      this.routeFromD = params["id"];
      this.Source = params["name"]
    })
    this.getDelivaryNotesByRefNo(this.routeFromD);
    this.userType = localStorage.getItem("user");
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
		if(lang == 'en'){
			this.language = {
			  "poNumber":"PO Number",
			  "poItem":"PO Item",
        "materialGroup":"Material Group",
        "material":"Material No",
			  "shortText":"Short Text",
			  "scheduledQuantity":"PO Qty",
			  "grQuantity":"Received Qty",
			  "outStandingQuantity":"Pending Qty",
        "netPrice":"Unit Price",
        "grossValue":"PO Amount",
        "warehouseReceivedQuantity":"Delivering Qty",
        "totalAmount":"Total Amount",
        "totalinwords":"Total Amount In Words",
        "SubTotal":"Sub Total",
        "LessDiscount":"Less Discount",
        "Currency":"Currency",
        "AdditionalConditions":"Additional Conditions",
        "PaymentTerm":"Payment Term",
        "DownPayment":"Down Payment",
        "button1":"Approve",
        "button2":"Reject",
        "VendorCode":"Vendor Code",
        "VendorName":"Vendor Name",
        "Head":" Delivery Note",
        "SoldTo":"Sold To",
        "Address":"Address",
        "Date":"Date",
        "Duedate":"Due date",
        "ShipmentNo":"Shipment No",
        "SupportDocuments":"Support Documents",
        "deliveryNoteNo":"Delivery Note No",
        "comment":"Comment",
        "status":"Status",
        "print":"Print"
			}; 

		  }else if(lang == 'th'){
			this.language = {
        "poNumber":"เลขที่ใบสั่งซื้อ",
			  "poItem":"รายการสั่งซื้อ",
        "materialGroup":"กลุ่มวัสดุ",
        "material":"หมายเลขวัสดุ",
			  "shortText":"ข้อความสั้น",
			  "scheduledQuantity":"จำนวน PO",
			  "grQuantity":"รับจำนวน",
			  "outStandingQuantity":"จำนวนที่รอดำเนินการ",
        "netPrice":"ราคาต่อหน่วย",
        "grossValue":"จำนวนใบสั่งซื้อ",
        "warehouseReceivedQuantity":"ส่งมอบจำนวน",
        "totalAmount":"จำนวนเงินทั้งหมด",
        "totalinwords":"จำนวนเงินทั้งหมดในคำ",
        "SubTotal":"ยอดรวม",
        "LessDiscount":"ลดน้อยลง",
        "Currency":"สกุลเงิน",
        "AdditionalConditions":"เงื่อนไขเพิ่มเติม",
        "PaymentTerm":"เงื่อนไขการชำระเงิน",
        "DownPayment":"เงินดาวน์",
        "button1":"อนุมัติ",
        "button2":"ปฏิเสธ",
        "VendorCode":"รหัสผู้ขาย",
        "VendorName":"ชื่อผู้จำหน่าย",
        "Head":" บันทึกการส่งมอบ",
        "SoldTo":"ขายให้",
        "Address":"ที่อยู่",
        "Date":"วันที่",
        "Duedate":"วันครบกำหนด",
        "ShipmentNo":"เลขที่จัดส่ง",
        "SupportDocuments":"เอกสารสนับสนุน",
        "deliveryNoteNo":"เลขที่ใบส่งของ",
        "comment":"ความคิดเห็น",
        "status":"สถานะ",
        "print":"พิมพ์"
			};
		  }
    this.warHouseUser = this.routeFromD;
  }

  goTo(){
    // if(this.routeFromD == 'po'){
    //   this.router.navigate(["purchase-order/po-info"])
    // }else if(this.routeFromD == 'warDl'){
    //   this.router.navigate(["dashboard"])
    // }else if(this.routeFromD == 'pending'){
    //   this.router.navigate(["/delivery-notes"]);
    // }else if(this.routeFromD == 'history'){
    //   this.router.navigate(["/delivery-notes-history"])
      // for (let i = 0; i < document.querySelectorAll('.mat-tab-label-content').length; i++) {
      //   if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == "Delivery Notes History") {
      //     (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
      //   }
      // }
    // }else{
    //   this.router.navigate(["/delivery-notes"])
    // }
    if(this.Source =='pending'){
      this.router.navigate(["delivery-notes"]);
      }else{
          this.router.navigate(["delivery-notes-history"]);
      }
    
  }

  // numberWithCommas(x) {
  //   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // }

  pageIndex = 1;
  pageSize = 10;
  purchaseOrder:any=[];
  subTotal:any=0;
  newsub:any=0;
  outputWords='';
  comment:any=[];
  // newsub:any=0;
  dpytAmount: any;
  currency: any;
  async getDelivaryNotesByRefNo(id:any){
 this.mySpinner = true;
  (await this.cService.getDeliveryNoteById(id)).pipe(first()).subscribe(res => {
    if (res.responseStatus.toUpperCase() == "SUCCESS") {
      // this.showOverlay=false;
      this.data = res.response.dnDetails;
      let len:any
      len = this.data.deliveryNoteStatus.length-1;
      if(len > 0){
        this.comment = this.data.deliveryNoteStatus[len].deliveryStatusComments;
      }
      // let vendorList = res.response.vendorList;
      this.getVendorInfo(this.data.vendorId);
      
      this.detailsTableData = this.data.deliveryNoteItemDetails;
      for(let i=0; i<this.detailsTableData.length; i++){
          this.dpytAmount = this.detailsTableData[i].dpytAmount;
          this.currency = this.detailsTableData[i].currency;
      }
      for(let i=0;i<this.data.deliveryNoteItemDetails.length;i++){
        if(this.data.deliveryNoteItemDetails[i].outStandingQuantity){
          this.subTotal = (parseFloat(this.subTotal) + (parseFloat(this.data.deliveryNoteItemDetails[i].amount))).toFixed(2);
          // this.newsub = this.subTotal.toLocaleString()
          this.newsub = this.numberWithCommas(this.subTotal);
         
          
        }
      }
      // this.outputWords= converter.toWords(this.subTotal);
      this.mySpinner = false;
    } else {
      // this.showOverlay=false;
     this.mySpinner = false;
    }
  });
}

  numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}


  raiseInvoice(){
    if(this.routeFromD == 'dNotes'){
      this.router.navigate(["invoice-list/invoice-details", "dNotes"])
    }else if(this.routeFromD == 'dNotes1'){
      this.router.navigate(["invoice-list/invoice-details", "dNotes1"])
    }else if(this.routeFromD == 'po'){
      this.router.navigate(["invoice-list/invoice-details", "po"])
    } 
    
  }
  element:any;
  openDialog():void{
    
    const dialogRef = this.dialog.open(DlApproveComponent, {
      width: '500px',
      data: "delivery note"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
        if(result.data == "Approved"){
          swal.fire("Delivery note is approved", "", "success");
          if(this.routeFromD == 'warDl'){
            this.router.navigate(["dashboard"])
          }else{
            this.router.navigate(["delivery-notes"])
          }
          
        }else{
          // swal.fire("Delivery note is approved");
        }
        
        // swal.fire("User creation successful!");
      }
      
    })
  }

  onCancel():void{
    const dialogRef = this.dialog.open(CommonRejectComponent, {
      width: '500px',
      data: "delivery note"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
        if(result.data == "Rejected"){
          swal.fire("Delivery note is Rejected", "", "error");
          if(this.routeFromD == 'warDl'){
            this.router.navigate(["dashboard"])
          }else{
            this.router.navigate(["delivery-notes"])
          }
          
        }else{
          // swal.fire("Delivery note is approved");
        }
        
        // swal.fire("User creation successful!");
      }
      
    })
  }
  onVenInfo(vendorCode:any){
    this.router.navigate(["delivery-notes/vendor-info", vendorCode], {state: {id:this.routeFromD,name:this.Source}})
  }
  onPoInfo(po:any){
    this.router.navigate(["delivery-notes/po-info", po, 'd', this.routeFromD])
  }
  async downLoad(id: any) {
    this.mySpinner = true;
    (await this.cService.download(id)).pipe(first()).subscribe(res => {
      this.mySpinner = false;
    }, error => {
      this.mySpinner = false;
    })
  }

  onPoInfop(poNumber){
      this.router.navigate(["delivery-notes/po-info", poNumber]);  
  }

  vendorDetails:any=[];
  vendorDetail:any=[];
  vendorTelePhoneDetails:any=[];
  isboolean:boolean = false;
  isstored:any;
  isAdded:boolean = false;
  getVendorInfo(vendorId: any) {
    this.aService.getVendorInfoById(vendorId).pipe(first()).subscribe(res =>{
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        
        this.mySpinner = false;
        this.vendorDetails = res.response;
        let vendorMasterDetails = this.vendorDetails.vendorAddress;
        // for(let x of this.vendorDetails.vendorAddress){
        //   if(x.addVersion == undefined || x.addVersion == ""){
        //     this.vendorDetail = x;
        //   }else if(x.addVersion == "E"){
        //     this.vendorDetail = x;
        //   }
        // }
        for(let i = 0; i < vendorMasterDetails.length; i++){
          if(vendorMasterDetails[i].addVersion == undefined || vendorMasterDetails[i].addVersion == ""){
            this.isboolean = true;
            this.vendorDetail = vendorMasterDetails[i]
            break;
        }else if(vendorMasterDetails[i].addVersion == "E" && this.isAdded == false){
          this.isAdded = true;
          this.isstored = i;
        }
      }
      if(this.isboolean == false){
        this.vendorDetail = vendorMasterDetails[this.isstored];
      }
      } else {
        this.mySpinner = false;
        
      }
    });
  }

  
 printPage(cmpName){
    let printContents = document.getElementById(cmpName).innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();      
    document.body.innerHTML = originalContents;
    location.reload() 
}
}