<mat-card>
        <mat-card-title>
            <div fxLayout="row" fxFlex.gt-xs="100" fxFlex="100">
                {{language.Head}}
            </div>
        </mat-card-title>
        <hr>
        <mat-card-content>
        <div fxLayout="row" fxLayoutWrap="wrap">
            <!-- Card column -->
            <div fxFlex.gt-sm="100%" fxFlex="100">
                <div fxLayout="row"fxLayout.xs="column"  >
                    <div class="search" fxFlex="30" fxFlex.sm="50" fxFlex.xs="100">
                        <mat-form-field class="mx-3 max-width">
                            <mat-label>{{language.search}}</mat-label>
                            <input matInput placeholder="{{language.search}}" (keyup)="applyFilter1()" #input [(ngModel)]="searchString">
                        </mat-form-field>
                    </div>
                    <div fxFlex="20" fxFlex.sm="50" fxFlex.xs="100" style="margin-top:15px">
                        <button (click)="applyFilter()"  mat-raised-button class="submitButton btn-primary">{{language.search}}</button>
                    </div>
                    <!-- <div fxFlex.gt-lg="60" fxFlex.gt-md="60" fxFlex.gt-xs="100" fxFlex="100"></div> -->
                    <div *ngIf="this.currentUser.roleID == 2"
                            fxFlex="70" fxFlex.sm="50" fxFlex.xs="100"
                        style="padding-top: 2%;">
                        <div class="addUserParent">
                            <button mat-raised-button class="submitButton addUser" (click)="addInvoice()">
                                {{language.button}}
                            </button>
                        </div>
                    </div>
                    <!-- <div *ngIf="userType.toUpperCase() != 'APU123' && userType.toUpperCase() != 'PJO123' "
                        fxFlex.gt-lg="14" fxFlex.gt-md="14" fxFlex.gt-xs="100" fxFlex="100"
                        style="padding-top: 2%;">
                        <button mat-raised-button class="submitButton" (click)="uploadInvoice()">Upload
                            Invoice</button>
                    </div> -->
              </div>

                            
                    
                            <div class="overflow">
                                <mat-card>
                                    <mat-table [dataSource]="dataSource" matSort multiTemplateDataRows
                                    [ngClass] = "currentUser.roleID == '2'?'Vpending':'invoiePendingListTable'" style="width: fit-content;">
                                        <ng-container matColumnDef="select">
                                            <th mat-header-cell *matHeaderCellDef style="height:56px">
                                                <mat-checkbox #myCheckbox style="margin-top:15px;" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected(myCheckbox)" [indeterminate]="selection.hasValue() && !isAllSelected()">
                                                </mat-checkbox>
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <mat-checkbox #myCheckbox (click)="$event.stopPropagation();unchecked();" (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)">
                                                </mat-checkbox>
                                            </td>
                                          </ng-container>
                                          <ng-container matColumnDef="isTaxInvoice">
                                            <mat-header-cell *matHeaderCellDef class="vendorCode-w" mat-sort-header> {{language.typeOfInvoices}}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Type Of Invoice :" class="vendorCode-w">
                                                <span *ngIf="element.isTaxInvoice == 'Y'">
                                                    Invoice
                                               </span>
                                               <span *ngIf="element.isTaxInvoice == 'N'">
                                                   E-Invoice
                                               </span>
                                               <span *ngIf="!element.isTaxInvoice">
                                                   -
                                               </span>
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="invoiceNumberByVendor">
                                            <mat-header-cell *matHeaderCellDef class="invoiceDate-w" mat-sort-header> {{language.invoiceNumberByVendor}}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Invoice NO :" class="invoiceDate-w">
                                                {{element.invoiceNumberByVendor}}
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="invoiceNumberByVp">
                                            <mat-header-cell *matHeaderCellDef class="vp-w" mat-sort-header> {{language.invoiceNumberByVp}}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Invoice NO :" class="vp-w">
                                                {{element.invoiceNumberByVp.substring(element.invoiceNumberByVp.indexOf("_")+1)}}
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="TaxNo">
                                            <mat-header-cell *matHeaderCellDef class="taxNo-w" mat-sort-header> {{language.TaxNo}}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Tax NO :" class="taxNo-w">
                                                {{element.taxId}}
                                            </mat-cell>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="invoiceDate">
                                            <mat-header-cell *matHeaderCellDef class="actions-w" mat-sort-header>
                                                {{language.invoiceDate}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Invoice Date :" class="actions-w">
                                                {{element.invoiceDate | date:"dd/MM/yyyy" }}
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="vendorName">
                                            <mat-header-cell *matHeaderCellDef class="invoiceDate-w" mat-sort-header>
                                                {{language.vendorName}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Vendor Name :" class="invoiceDate-w">
                                                {{element.nameOne +" "+(element.nameTwo == undefined ? '' : '')}}
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="paymentMethods">
                                            <mat-header-cell *matHeaderCellDef class="paymentMethod-w" mat-sort-header>
                                                {{language.paymentMethod}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Invoice Type :" class="paymentMethod-w">
                                                {{element.paymentMethods}}
                                                </mat-cell>
                                                </ng-container>
                                        <!-- <ng-container matColumnDef="typeOfInvoice">
                                            <mat-header-cell *matHeaderCellDef class="invoiceType-w" mat-sort-header>
                                                {{language.typeOfInvoice}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Invoice Type :" class="invoiceType-w">
                                                <span *ngIf="element.typeOfInvoice == 'G'">
                                                    GRN
                                                </span>

                                                <span *ngIf="element.typeOfInvoice == 'S'">
                                                    SRN
                                                </span>
                                                <span *ngIf="element.typeOfInvoice == 'W'">
                                                    GRN - Weigh Bridge
                                                </span>
                                                
                                            </mat-cell>
                                        </ng-container> -->

                                        <ng-container matColumnDef="vendorCode">
                                            <mat-header-cell *matHeaderCellDef class="vendorCode-w" mat-sort-header>
                                               {{language.vendorCode}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Vendor Code :" class="vendorCode-w">
                                               <a class="poInfoCls" (click)="onVendorInfo(element)"> {{element.vendorCode}}</a>
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="totalQuantity">
                                            <mat-header-cell *matHeaderCellDef class="totalQty-w" mat-sort-header> {{language.totalQuantity}}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Total Qty. :" class="totalQty-w">
                                                {{element.totalQuantity | number : '1.3-3'}}
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="amount">
                                            <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header> {{language.amount}}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Total Amt. :" class="totalAmt-w">
                                                <!-- {{element.amount + element.amountVat | number : '1.2-2' }} -->
                                                {{element.amount | number : '1.2-2' }}
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="taxType">
                                            <mat-header-cell *matHeaderCellDef class="taxType-w" mat-sort-header> {{language.taxType}}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Tax Type :" class="taxType-w">
                                                {{element.taxType != "" ? element.taxType : '-' }}
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="vatAmount">
                                            <mat-header-cell *matHeaderCellDef class="vatAmt-w" mat-sort-header> {{language.vatAmount}}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Vat Amt. :" class="vatAmt-w">
                                                {{element.amountVat != "" ? element.amountVat : '-' }}
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="poNumber">
                                            <mat-header-cell *matHeaderCellDef class="actions-w" mat-sort-header> {{language.poNumber}}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Vat Amt. :" class="actions-w">
                                                <a (click)="onPoInfo(element.poNumber, element.id,element.typeOfInvoice)" class="poInfoCls">{{element.poNumber}}</a>
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="apDoc">
                                            <mat-header-cell *matHeaderCellDef class="invoiceDate-w"> {{language.apDoc}}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="AP Doc :" class="invoiceDate-w">
                                                {{element.invNumBySap?element.invNumBySap:"-"}}
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="postingDate">
                                            <mat-header-cell *matHeaderCellDef class="invoiceDate-w"> {{language.postingDate}}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Posting Date :" class="invoiceDate-w">
                                                {{element.postingDate | date:"dd/MM/yyyy"}}
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="netDueDate">
                                            <mat-header-cell *matHeaderCellDef class="invoiceType-w" mat-sort-header>
                                               {{language.netDueDate}} </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Net due date :" class="invoiceType-w">
                                                 {{element.netDueDate | date:"dd/MM/yyyy"}}
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="paymentDoc">
                                            <mat-header-cell *matHeaderCellDef class="invoiceDate-w"> {{language.paymentDoc}}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Payment Doc :" class="invoiceDate-w">
                                                {{element.paymentDoc?element.paymentDoc:"-"}}
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="sesNumber">
                                            <mat-header-cell *matHeaderCellDef class="actions-w" mat-sort-header> {{language.sesNo}}
                                            </mat-header-cell>
                                            <mat-cell  *matCellDef="let element" data-label="Payment Doc :" class="actions-w">
                                                        {{element.sesNumber ? element.sesNumber:"-"}}                             
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="grnSrnNumber">
                                            <mat-header-cell *matHeaderCellDef class="actions-w" mat-sort-header> {{language.MatDoc}}
                                            </mat-header-cell>
                                            <mat-cell  *matCellDef="let element" data-label="Payment Doc :" class="actions-w">
                                                        {{element.grnSrnNumber ? element.grnSrnNumber:"-"}} 
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="paymentDate">
                                            <mat-header-cell *matHeaderCellDef class="invoiceDate-w"> {{language.paymentDate}}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="Payment Date :" class="invoiceDate-w">
                                                {{element.paymentDate | date:"dd/MM/yyyy"}}
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="companyCode">
                                                <mat-header-cell *matHeaderCellDef class="po-wc" mat-sort-header> {{language.companyCode}}
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" data-label="Payment Doc :" class="po-wc">
                                                    {{element.companyCode?element.companyCode:"-"}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="apCheckerAcceptedDate">
                                                    <mat-header-cell *matHeaderCellDef class="materialDocNum-w" mat-sort-header>
                                                        {{language.ApcheckerDate}}
                                                     </mat-header-cell>
                                                    <mat-cell *matCellDef="let element" class="materialDocNum-w" data-label="PAYMENT DOC. :">
                                                        {{(element.apCheckerAcceptedDate == null || element.apCheckerAcceptedDate == undefined)? '-' : element.apCheckerAcceptedDate | date:"dd/MM/yyyy"}}
                                                    </mat-cell>
                                                </ng-container>
                                        <ng-container matColumnDef="sapResponse">
                                            <mat-header-cell *matHeaderCellDef class="sapResponse-w" mat-sort-header>
                                                <span >{{language.SAPResponse}}</span>
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="SAP Response :" class="sapResponse-w">
                                               <span  class="textWrap" matTooltipClass="custom-tooltip" matTooltipPosition="before"  matTooltip="{{element.sapResponse}}">{{element.sapResponse ? element.sapResponse : '-' }}</span>
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="invStatus">
                                            <mat-header-cell *matHeaderCellDef class="status-w" mat-sort-header> {{language.status}}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="STATUS :" class="status-w" [ngClass]="calculateClass(element.invStatus)">
                                                {{element.invStatus}}
                                            </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="actions">
                                            <mat-header-cell *matHeaderCellDef class="actions-w"> {{language.actions}}
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let element" data-label="ACTIONS :" class="actions-w">
                                                <div class="button-group my-2 float-right" style="display:flex">
                                                    <!-- <button (click)="onInfo(element)" mat-flat-button color="primary" class="button-actions"> -->
                                                    <mat-icon (click)="onInfo(element)" class="visibility" aria-hidden="false"
                                                        aria-label="visibility">visibility
                                                    </mat-icon>
                                                    <!-- <mat-icon  (click)="onEdit(element)" class="visibility" aria-hidden="false"
                                                        aria-label="edit">edit
                                                    </mat-icon> -->
                                                    <mat-icon *ngIf="(currentUser.roleID == '2') && (element.statusId == '1' || element.statusId == '7' || element.statusId == '19' || element.statusId == '20' || element.statusId == '22' || element.statusId == '24')" 
                                                    (click)="onEdit(element)" class="visibility" aria-hidden="false"
                                                    aria-label="edit">edit
                                                    </mat-icon>
                                                    <!-- || element.statusId == '12' -->
                                                    <mat-icon *ngIf="(currentUser.roleID == '4') && (element.statusId == '1' || element.statusId == '7' || element.statusId == '19' || element.statusId == '20' || element.statusId == '23')" 
                                                        (click)="onEditChecker(element)" class="visibility" aria-hidden="false"
                                                        aria-label="edit">edit
                                                    </mat-icon>
                                                <!-- || element.statusId == '12' -->
                                                <!-- <mat-icon *ngIf="(currentUser.roleID == '5') && (element.typeOfInvoice != 'W') && (element.statusId == '2' || element.statusId == '12' || element.statusId == '19'  || element.statusId == '20')" 
                                                    (click)="onEditNew(element)" class="visibility" aria-hidden="false"
                                                    aria-label="edit">edit
                                                </mat-icon> -->
                                                <mat-icon  *ngIf="(currentUser.roleID == '5') && (element.statusId == '2' || element.statusId == '12' || element.statusId == '19' || element.statusId == '20')" 
                                                    (click)="onEditNew(element)" class="visibility" aria-hidden="false"
                                                    aria-label="edit">edit
                                                </mat-icon>

                                                <!-- <mat-icon *ngIf="(currentUser.roleID == '8') && (element.statusId == '2' || element.statusId == '1' || element.statusId == '5' || element.statusId == '7' || element.statusId == '12' || element.statusId == '19' || element.statusId == '20' || element.statusId == '23')" 
                                                    (click)="onEdit(element)" class="visibility" aria-hidden="false"
                                                    aria-label="edit">edit
                                                </mat-icon> -->
                                                <mat-icon *ngIf="(currentUser.roleID == '8') && (element.statusId == '1' || element.statusId == '7' || element.statusId == '23')"
                                                    (click)="onEditChecker(element)" class="visibility" aria-hidden="false"
                                                    aria-label="edit">edit
                                                </mat-icon>



                                                <mat-icon *ngIf="(currentUser.roleID == '8') && (element.statusId == '2' || element.statusId == '12' || element.statusId == '19' || element.statusId == '20')"
                                                    (click)="onEditNew(element)" class="visibility" aria-hidden="false"
                                                    aria-label="edit">edit
                                                </mat-icon>

                                                <mat-icon *ngIf="(currentUser.roleID == '6' || currentUser.roleID == '7') && (element.statusId == '1' || element.statusId == '3' || (element.statusId == '5' && element.typeOfInvoice == 'S' ) ||element.statusId == '7'|| element.statusId == '19' || element.statusId == '20' || element.statusId == '22' || element.statusId == '24' || element.statusId == '11' || element.statusId == '28')" 
                                                    (click)="onEdit(element)" class="visibility" aria-hidden="false"
                                                    aria-label="edit">edit
                                                </mat-icon>
                                                <!-- || element.statusId == '12'  -->
                                                    <!-- *ngIf="userType.toUpperCase() == 'APU123' || userType.toUpperCase() == 'PJO123' " -->
                                                    <!-- <mat-icon *ngIf="userType.toUpperCase() != 'APU123' || userType.toUpperCase() != 'PJO123' || userType.toUpperCase() != 'VND123' " (click)="onEdit(element)" class="visibility" aria-hidden="false"
                                                        aria-label="visibility">visibility
                                                    </mat-icon> -->
                                                    <!-- <span class="mx-1">Info</span> -->
                                                    <!-- </button> -->
                                                </div>
                                            </mat-cell>
                                        </ng-container>
                                        <mat-header-row *matHeaderRowDef="columnsToDisplay">
                                        </mat-header-row>
                                        <mat-row *matRowDef="let element; columns: columnsToDisplay;"
                                            class="example-element-row"
                                            [class.example-expanded-row]="expandedElement === element"
                                            (click)="expandedElement = expandedElement === element ? null : element">
                                        </mat-row>
                                        <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
                                    </mat-table>
                                </mat-card>
                            </div>
                            <!-- <div *ngIf="serchTrue" >Summary of Total amount in document of selected item : {{totalSum}}</div> -->
                            <mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" [pageSize]="pageSize"
                        [length]="listLength"
                        [pageSizeOptions]="[10, 25, 50, 100]" (page)="getInvoiceList($event,'')"
                        style="width:100%"></mat-paginator>
                        <!-- <mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" [pageSize]="pageSize"
                        [length]="listLength"
                        [pageSizeOptions]="[10, 25, 50, 100, 500, 1000]" (page)="getInvoiceServiceList($event)"
                        style="width:100%"></mat-paginator> -->
                  
                        <!--  -->
            </div>
        </div>
    </mat-card-content>
</mat-card>
<div class="overlay"  *ngIf="mySpinner">
    <div class="center">
        <mat-spinner></mat-spinner> 
    </div>
    </div>