import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
// import { ConfigModel } from './config-model/config-model';
//import swal from "sweetalert2";
import { ResponseModel } from './response-model/response-model';
// import { ConfigModel, ConfiModel } from './config-model/config-model';
import { AdminServiceService } from '../services/admin-service.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import swal from 'sweetalert2';


@Component({
  selector: 'app-response-message-management',
  templateUrl: './response-message.component.html',
  styleUrls: ['./response-message.component.scss']
})
export class ResponseMessageComponent implements OnInit {
  lang:any = 'en';
  langSelect:any;
  mySpinner = false;
  constructor(private dialog: MatDialog,private router: Router, private cService: AdminServiceService) { }
  dataSource:MatTableDataSource<any>;
  dataSource1:MatTableDataSource<any>;
  columnsToDisplay=["respCode","message","actions"];
  columnsToDisplay1=["respCode","message","actions"];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  currentUser: any;
  language:any;
  ngOnInit(): void {
 
  this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  let currentUsers = this.currentUser.response;
  let lang = currentUsers.langPreference;
  
  if(lang == 'en'){
    this.language={
      responseMessages:"Response Messages",
      actions:"Actions",
      search:"Search",
    }
    }else if(lang == 'th'){
    this.language={
      responseMessages:"ข้อความตอบกลับ",
      actions:"การกระทำ",
      search:"ค้นหา",
    }
    }
    this.getResponseMessage(null, this.searchString, "en");
  }

  pageIndex = 0;
  pageSize = 10;
  listLength: any;
  listLength1: any;
  searchString:any='';
  getResponseMessage(event: PageEvent,searchString:any, lang:any) {
    // this.showOverlay = true;
    this.mySpinner = true;
    if(event ==  null){
      this.pageIndex = 0;
      this.pageSize = 10;
     }else{
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
     }
     
    this.cService.getResMessages(this.pageIndex,this.pageSize,searchString,lang).pipe(first()).subscribe(res => {
      
      if (res.responseMessage.toUpperCase() == "SUCCESS") {
        //this.showOverlay=false;
        this.mySpinner = false;
       this.listLength = res.pagination.count;
       this.dataSource= new MatTableDataSource(res.response);
       this.dataSource.sort=this.sort
      } else {
        this.mySpinner = false;
        this.dataSource = new MatTableDataSource([]);
        this.dataSource1 = new MatTableDataSource([]);
        this.listLength = 0;
        
        swal.fire(res.responseMessage,"", "error");
      }
    }, error => {
      // this.showOverlay=false;
    })
  }
  openDialog(_add: any, obj: any){
    obj.action = _add;
    let userid = obj.userID;
    const dialogRef = this.dialog.open(ResponseModel, {
      width: '500px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      
      if(result != undefined){
        let req = {
          "message":result.data.message
        }
     this.updateRepsonse(result.data.id, req);
        // swal.fire("User creation successful!");
      }
      
    })
  }
  updateRepsonse(id:any,req:any){
    this.cService.updateResponseMessage(id,req).pipe(first()).subscribe(res => {
      if(res.responseStatus.toUpperCase() == "SUCCESS"){
        swal.fire(
          { 
            icon: 'success',
            title: '',
            text: res.responseMessage,
            footer: ''
        }
        );
      this.getResponseMessage(null, this.searchString,'en');
      }else{
        swal.fire(
          { 
            icon: 'error',
            title: '',
            text: res.responseMessage,
            footer: ''
        }
        );
      }
    })
    
  }
  langChange(){
    
    
    if(this.langSelect == 'en'){
      this.lang = "en";
    }else{
      this.lang = "thai";
    }

  }
  
  applyFilter1(tab:any){
    const filterValue = this.searchString;
    if(this.searchString == "" ){
      if(tab == 'en'){
        this.getResponseMessage(null, this.searchString,'en');
       }else{
        this.getResponseMessage(null, this.searchString,'th');
       }
      
    }
  }
  applyFilter(tab:any) {
    
    const filterValue = this.searchString;
    if(filterValue.length >= 2 || this.searchString == "" ){
       if(tab == 'en'){
        this.getResponseMessage(null, this.searchString,'en');
       }else{
        this.getResponseMessage(null, this.searchString,'th');
       }
    }
}
tabClick(event){
  if(event.tab.textLabel == "English"){
    this.getResponseMessage(null, this.searchString, "en");
  }else{
    this.getResponseMessage(null, this.searchString, "th");

  }
}

}
