<mat-card class="paddings">
    <mat-card-title>
        {{language.notification}}
    </mat-card-title>
    <hr>
<!-- <div style="padding-left: 55%">
    <div fxFlex.gt-lg="60" fxFlex.gt-md="60" fxFlex.gt-xs="100" fxFlex="100"></div>
    <div *ngIf="userType.roleID ==1"
        fxFlex.gt-lg="14" fxFlex.gt-md="14" fxFlex.gt-xs="100" fxFlex="100"
        style="padding-top: 2%;">
        <button mat-raised-button class="submitButton" (click)="addNotificaton()">Add
            Notification</button>
    </div>
</div> -->
<mat-card *ngFor='let activity of activityData'>
   
    <mat-card-content style="cursor: pointer;"  (click)="onInfo(element)">
        

        <div class="d-flex no-blcok">
            <div fxLayout="row">
                <div fxLayout="column">
                    <h4 class="m-0">{{activity.subject}}
                        <!-- <small class="text-muted">({{activity.commentTime}})</small> -->
                    </h4>
                    <p class="text-muted" ng-bind-html="myHTML">{{activity.body}}</p>
                </div>

                <!-- <mat-icon class="icon" (click)="onInfo(element)" aria-hidden="false" data-title="Details" aria-label="Details">visibility</mat-icon> -->
               
                <!-- <button *ngIf="activity.bottomImage ===''" mat-raised-button color="{{activity.buttonColor}}" class="submit">Check Now</button> -->

            </div>
            <!-- <mat-icon style="padding-left: 60%;" aria-hidden="false"  class="visibility" aria-label="visibility" (click)="onInfo(activity)">visibility</mat-icon> -->
        </div>
        
    </mat-card-content>
</mat-card>
<mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" [pageSize]="pageSize" [length]="listLength" [pageSizeOptions]="[5, 10, 20]" [pageSizeOptions]="[10, 25, 50, 100]" (page)="getNotificationList($event)"  style="width:100%"></mat-paginator>
</mat-card>
<div class="overlay"  *ngIf="mySpinner">
    <div class="center">
        <mat-spinner></mat-spinner> 
    </div>
    </div>