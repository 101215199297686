import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonServicesService } from 'src/app/services/common-services.service';
import { first } from 'rxjs/operators';
import swal from "sweetalert2";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TableUtil } from 'src/app/reports/TableUtil';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-master-records',
  templateUrl: './master-records.component.html',
  styleUrls: ['./master-records.component.css']
})
export class MasterRecordsComponent implements OnInit {

  mySpinner = false;
  currentUser:any;
  language:any;
  dropdownSettings1 = {};
  @ViewChild("myInput")
  myInputVariable: ElementRef;
  tableList:any = [];
  constructor(private cService: CommonServicesService,private fb: FormBuilder,private datePipe: DatePipe,) { }

  actionTypes = [
    { key: "Upload", value: "Upload" },
    { key: "Download", value: "Download" },
  ]
  masterReportForm: FormGroup = this.fb.group({
    selecttablename:['', [Validators.required]],
    action:['', [Validators.required]]
   
  });
  ngOnInit(): void {
          this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
          let currentUsers = this.currentUser.response;
          let lang = currentUsers.langPreference;
          
          if(lang == 'en'){
            this.language={
              "masterRecords":"Master Records",
              "selecttablename":"Select Feature",
              "tablename":"Feature *",
              "action":"Action *",
              "attachments":"Attachments",
              "note":"Note",
              "noteLine1":"Please Select xls,xlsx file Formats,",
              "noteLine2":"Max file size less than 5Mb, Max file quantity is 1.",
              "swalmaxfile":"Maximum limit 1 file",
              "swalformat":"Only xls and xlsx files are allowed.",
              "swaldiffile":"File is already uploaded, please upload different file",
              "swalsize":"File size cannot be greater than 5mb",
              "Submit":"Submit",
              "Clear":"Clear",
              "featureName":"Please select feature",
              "choosefile":"Please choose a file",
              "actionSelect":"Please select a action"
            }
          }else if(lang == 'th'){
            this.language={
              "masterRecords":"บันทึกหลัก",
              "selecttablename":"เลือกคุณสมบัติ",
              "tablename":"คุณสมบัติ *",
              "action":"การกระทำ *",
              "attachments":"เอกสารแนบ",
              "note":"บันทึก",
              "noteLine1":"กรุณาเลือกรูปแบบไฟล์ xls,xlsx,",
              "noteLine2":"ขนาดไฟล์สูงสุดน้อยกว่า 5Mb จำนวนไฟล์สูงสุดคือ 1.",
              "swalmaxfile":"Maximum limit 1 file",
              "swalformat":"อนุญาตเฉพาะไฟล์ xls และ xlsx เท่านั้น",
              "swaldiffile":"ไฟล์ถูกอัพโหลดแล้ว กรุณาอัพโหลดไฟล์อื่น",
              "swalsize":"ขนาดไฟล์ต้องไม่เกิน 5Mb",
              "Submit":"ส่ง",
              "Clear":"ล้างข้อมูลการค้นหา",
              "featureName":"กรุณาเลือกคุณสมบัติ",
              "choosefile":"กรุณาเลือกไฟล์",
              "actionSelect":"โปรดเลือกการดำเนินการ"
            }
          }
          
          this.tableList = [
            { item_id: 1, item_text: 'Business Place Master' },
            { item_id: 2, item_text: 'Storage Location Master' },
            { item_id: 3, item_text: 'Withholding Tax Master' },
            { item_id: 4, item_text: 'Company Master' },
            { item_id: 5, item_text: 'Cost Center Master' },
            { item_id: 6, item_text: 'GL Account Master' },
            { item_id: 7, item_text: 'Payment Terms Master' },
            { item_id: 8, item_text: 'Plant Master' },
            { item_id: 9, item_text: 'Tax Master' },
            { item_id: 10, item_text: 'WHT Type Master' },
          ];

          this.dropdownSettings1 = {
            singleSelection: true,
            idField: 'item_id',
            textField: 'item_text',
            closeDropDownOnSelection: true,        
            itemsShowLimit: 1,
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            allowSearchFilter: true
          };
  }

  tbleList:any = [];
  selected:any;
  isBusiness:any;
  isValue:any;
  onSelect2(item){
//     

    this.tbleList.push(item);
    this.isBusiness = item.item_text; 
   if(this.selected){
     this.showFile = item.item_text;
   }
  }
  onDeSelect2(item: any) {
    const index = this.tbleList.indexOf(item);  
    this.tbleList.splice(index, 1);  
    this.isBusiness = "";
    this.showFile = "";
  }
  
  onactionTypeChange(event){
    
    this.selected = event;

    if(this.selected == "Download"){
      
      // this.masterdownload(this.isBusiness);
    }else{
     this.showFile = this.isBusiness;
    }
  }

  supportDocs: any = [];
  listValue: any;
  datacache: any = [];
  newFile:any;
  fileName:any = [];
  showFile:any;
  async upload(event: any) {
    
    let formData = new FormData();
    formData.append("files", event.target.files[0]);
    this.newFile = formData;
    
    this.fileName = event.target.files[0].name;
    let sFileExtension = this.fileName
      .split(".")
      [this.fileName.split(".").length - 1].toLowerCase();
    if (
      !(
        sFileExtension === "xls" ||
        sFileExtension === "xlsx"
      )
    ) {
      swal.fire(`${this.language.swalformat}`, "", "error");
      this.newFile = [];
      this.fileName = "";
    } else {
      if (event.target.files[0].size > 5000000) {
        swal.fire(`${this.language.swalsize}`, "", "error");
        this.myInputVariable.nativeElement.value = "";
        return;
      } else {
        this.supportDocs = JSON.parse(localStorage.getItem("supportDocs"));
        // if (this.supportDocs != null && this.supportDocs != undefined) {
        //   for (let i = 0; i <= this.supportDocs.length - 1; i++) {
        //     if (
        //       this.supportDocs[i].documentName == event.target.files[0].name
        //     ) {
        //       
        //         this.supportDocs[i].documentName,
        //         event.target.files[0].name
        //       );
        //       swal.fire(
        //         `${this.language.swaldiffile}`, "", "error"
        //       );
        //       this.myInputVariable.nativeElement.value = "";
        //       return;
        //     }
        //   }
        //   if (this.supportDocs.length >= 1) {
        //     swal.fire(`${this.language.swalmaxfile}`, "", "error");
        //     this.myInputVariable.nativeElement.value = "";
        //     return;
        //   }
        // }

        // 
      }
    }
  }

  // newDocuments: any = [];
  deleteItem(id) {
    this.fileName = [];
    this.newFile = [];
    this.supportDocs = [];
    this.myInputVariable.nativeElement.value = "";
  }

  async masterdownload(feature:any){
    // 
    this.mySpinner = true;
    (await this.cService.masterDownload(feature)).pipe(first()).subscribe(
      (res)=>{
        if (res.responseStatus.toUpperCase() == "SUCCESS") {
          this.mySpinner = false;
          
        }else{
          this.mySpinner = false;
          swal.fire(res.responseMessage,"","error")
        }

        let onlyNameAndSymbolArr: Partial<PeriodicElement>[];
        if(this.isBusiness == "Business Place Master"){
          onlyNameAndSymbolArr = res.response.map(
            a=>({
              "Address Group": a.addressGroup,
              "Address Version": a.addressVersion,
              "Business Name": a.businessName,
              "Business Place":a.businessPlace,
              "City":a.city,
              "City One":a.cityOne,
              "Company Code":a.companyCode,
              "Country Code":a.countryCode,
              "District":a.district,
              "Extension":a.extension,
              "Fax":a.fax,
              "Fax Extension":a.faxExtension,
              "Fax One":a.faxOne,
              "House Number":a.houseNumber,
              "Language Key":a.languageKey,
              "Name":a.name,
              "Name Four":a.nameFour,
              "Name Three":a.nameThree,
              "Name Two":a.nameTwo,
              "Postal Code":a.postalCode,
              "Region":a.region,
              "Search Term One":a.searchTermOne,
              "Search Term Two":a.searchTermTwo,
              "Street":a.street,
              "Street Five":a.streetFive,
              "Street Four":a.streetFour,
              "Telephone":a.telephone,
              "Telephone One":a.telephoneOne,
              "Time Zone":a.timeZone,
              "Title":a.title,
              "Created By":a.createdBy,
              "Created Date":this.datePipe.transform(a.createdDate,'dd/MM/yyyy'),
              "Modified Date":this.datePipe.transform(a.modifiedDate,'dd/MM/yyyy'),
              "Modified By":a.modifiedBy,
            })
          )
        }else if(this.isBusiness == "Storage Location Master"){
          onlyNameAndSymbolArr = res.response.map(
            b=>({
              "Created By": b.createdBy,
              "Created Date": this.datePipe.transform(b.createdDate,'dd/MM/yyyy'),
              "Modified Date": this.datePipe.transform(b.modifiedDate,'dd/MM/yyyy'),
              "Modified By": b.modifiedBy,
              "Name": b.name,
              "Plant Code": b.plantCode,
              "Storage Location code": b.storageLocationCode,
            })
          )
        }else if(this.isBusiness == "Withholding Tax Master"){
          onlyNameAndSymbolArr = res.response.map(
            c=>({
              "Created By": c.createdBy,
              "Created Date": this.datePipe.transform(c.createdDate,'dd/MM/yyyy'),
              "Description": c.description,
              "Percentage": c.percentage,
              "Modified Date": this.datePipe.transform(c.modifiedDate,'dd/MM/yyyy'),
              "Modified By": c.modifiedBy,
              "WHT Code": c.whtCode,
            })
          )
        }else if(this.isBusiness == "Company Master"){
          onlyNameAndSymbolArr = res.response.map(
          d=>({
            "Company Code":d.companyCode,
            "Created Date": this.datePipe.transform(d.createdDate,'dd/MM/yyyy'),
            "Created By":d.createdBy,
            "Modified Date": this.datePipe.transform(d.modifiedDate,'dd/MM/yyyy'),
            "Modified By":d.modifiedBy,
            "Name":d.name,
          })
        )
        }else if(this.isBusiness == "Cost Center Master"){
          onlyNameAndSymbolArr = res.response.map(
          e=>({
            "Company Code":e.companyCode,
            "Created By":e.createdBy,
            "Created Date":this.datePipe.transform(e.createdDate,'dd/MM/yyyy'),
            "Cost Center":e.costCenter,
            "Description":e.description,
            "Modified Date":this.datePipe.transform(e.modifiedDate,'dd/MM/yyyy'),
            "Modified By":e.modifiedBy,
          })
        )
        }else if(this.isBusiness == "GL Account Master"){
          onlyNameAndSymbolArr = res.response.map(
          f=>({
            "Company Code":f.companyCode,
            "Created By":f.createdBy,
            "Created Date":this.datePipe.transform(f.createdDate,'dd/MM/yyyy'),
            "Description":f.description,
            "GL Account":f.glAccount,
            "Modified Date":this.datePipe.transform(f.modifiedDate,'dd/MM/yyyy'),
            "Modified By":f.modifiedBy,
          })
        )
        }else if(this.isBusiness == "Payment Terms Master"){
          onlyNameAndSymbolArr = res.response.map(
          g=>({
            "Code":g.code,
            "Created By":g.createdBy,
            "Created Date":this.datePipe.transform(g.createdDate,'dd/MM/yyyy'),
            "Days":g.days,
            "Description":g.description,
            "Modified Date":this.datePipe.transform(g.modifiedDate,'dd/MM/yyyy'),
            "Modified By":g.modifiedBy
          })
        )
        }else if(this.isBusiness == "Plant Master"){
          onlyNameAndSymbolArr = res.response.map(
          h=>({
            "Address En":h.addressEn,
            "Address Th":h.addressTh,
            "City":h.city,
            "Company Code":h.companyCode,
            "Created By":h.createdBy,
            "Created Date":this.datePipe.transform(h.createdDate,'dd/MM/yyyy'),
            "Modified Date":this.datePipe.transform(h.modifiedDate,'dd/MM/yyyy'),
            "Modified By":h.modifiedBy,
            "Name En":h.nameEn,
            "Name Th":h.nameTh,
            "Plant Code":h.plantCode,
            "Postal Code":h.postalCode,
          })
        )
        }else if(this.isBusiness == "Tax Master"){
          onlyNameAndSymbolArr = res.response.map(
          i=>({
            "Created By":i.createdBy,
            "Created Date":this.datePipe.transform(i.createdDate,'dd/MM/yyyy'),
            "Description":i.description,
            "Percentage":i.percentage,
            "Tax Code":i.taxCode,
            "Modified Date":this.datePipe.transform(i.modifiedDate,'dd/MM/yyyy'),
            "Modified By":i.modifiedBy,
          })
        )
        }else if(this.isBusiness =="WHT Type Master"){
          onlyNameAndSymbolArr = res.response.map(
          j=>({
            "Created By":j.createdBy,
            "Created Date":this.datePipe.transform(j.createdDate,'dd/MM/yyyy'),
            "Tax Type":j.taxType,
            "Modified Date":this.datePipe.transform(j.modifiedDate,'dd/MM/yyyy'),
            "Modified By":j.modifiedBy,
            "Description":j.description
          })
        )
        } 

        TableUtil.exportArrayToExcel(onlyNameAndSymbolArr,this.isBusiness);
      },
      (error) => {
        this.mySpinner = false;
      }
    )
  }

  onSubmit(){
    if(this.isBusiness == undefined || this.isBusiness == ""){
      swal.fire({
        icon: 'warning',
        text: `${this.language.featureName}`
      });
      return;
    }
    if(this.selected == undefined || this.selected == ""){
      swal.fire({
        icon: 'warning',
        text: `${this.language.actionSelect}`
      });
      return;
    }
    if(this.selected == "Upload"){
      if(this.newFile == undefined || this.newFile == ""){
        swal.fire({
          icon: 'warning',
          text: `${this.language.choosefile}`
        });
        return;
      }
      
      this.masterUPload(this.newFile)
    }else{
      
      this.masterdownload(this.isBusiness.replaceAll(" ",""));
    }

  }
  onClear(){
    this.isBusiness = "";
    this.masterReportForm.get('selecttablename').setValue([]);
    this.masterReportForm.get('action').setValue([]);
    this.fileName = [];
    this.showFile = "";
    this.myInputVariable.nativeElement.value = "";
  }
 async masterUPload(formData){
    this.mySpinner = true;
        (await this.cService
          .masterUpload(formData,this.isBusiness.replaceAll(" ","")))
          .pipe(first())
          .subscribe(
            (res) => {
              
              if(res.responseStatus != null){
              if (res.responseStatus.toUpperCase() == "SUCCESS") {
                this.mySpinner = false;
                swal.fire({
                  icon: 'success',
                  text: res.responseMessage
                })
                // let test: any = res.response[0]; 

                // this.listValue = [];

                // this.listValue.push({
                //   id: test.id,
                //   documentType: test.documentType,
                //   documentPath: test.documentPath,
                //   documentName: test.documentName,
                //   documentExtension: test.documentExtension,
                //   documentSize: test.documentSize,
                // });

                // var last = this.listValue
                //   .slice(-1)
                //   .pop(); 

                // var obj = last;
                // this.datacache.push(obj); 

                // let supportDoc: any = [];

                // supportDoc = JSON.parse(localStorage.getItem("supportDocs"));
                // let arr: any = [];
                // if (supportDoc == null) {
                //   localStorage.setItem(
                //     "supportDocs",
                //     JSON.stringify(this.listValue)
                //   );
                // } else {
                //   for (let i = 0; i <= this.listValue.length - 1; i++) {
                //     supportDoc.push(this.listValue[i]);
                //   }
                //   var result = supportDoc.reduce((unique, o) => {
                //     if (!unique.some((obj) => obj.name === o.name)) {
                //       unique.push(o);
                //     }
                //     return unique;
                //   }, []);
                //   localStorage.setItem(
                //     "supportDocs",
                //     JSON.stringify(supportDoc)
                //   );
                // }
                // this.mySpinner = false;
                // this.listValue = JSON.parse(
                //   localStorage.getItem("supportDocs")
                // );
              } else {
                this.mySpinner = false;
                swal.fire(res.responseMessage, "", "error");
              }
            }else{
              this.mySpinner = false;
            }
          },
            (error) => {
              this.mySpinner = false;
            }
          );
  }
}

export interface PeriodicElement {
    vendorCode:any,
    nameOne:any,
    nameTwo:any,
    address:any,
    country:any,
    createdDate:any,
    vendorGroup:any,
    postalCode:any,
    centralDeletionFlag:any,
    centralPostingBlock:any,
    centralPurchasingBlock:any,
    cHKFlagDBLInvoice:any,
    city:any,
    commonFaxNumber:any,
    commonTelephone:any,
    district:any,
    isActive:any,
    isAlternatePayeeDocument:any,
    isBlocked:any,
    isOneTime:any,
    keySort:any,
    plantRelevant:any,
    purchaseOrderCurrency:any,
    purchaseOrgCreatedBy:any,
    purchaseOrgCreatedDate:any,
    purchasingOrg:any,
    rowVersion:any,
    searchCity:any,
    searchNameOne:any,
    statusDataTrnsNextRel:any,
    street:any,
    bsstreet:any
    mDate:any,
    mby:any
}
