import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MyModalComponent } from '../my-modal/my-modal.component';
import swal from 'sweetalert2';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AdminServiceService } from '../services/admin-service.service';
import { first } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import {browserRefresh} from '../app.component';
import { TableUtil } from 'src/app/reports/TableUtil';
import { Location } from '@angular/common';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  // mySpinner: boolean;
  // private _snackBar: any;
  mySpinner: boolean = false;
  constructor(private dialog: MatDialog,
    public _snackBar: MatSnackBar,
     private router: Router,
    private cService: AdminServiceService,
    private route:ActivatedRoute,private location: Location) { }
    dataSource:MatTableDataSource<any>;
    headerToDisplay:any;
  columnsToDisplay = ["isBlocked","actions","userName","firstName", "lastName", "email", "mobileNo","companyAllowed","plantAllowed", "roleName", "lastLoginTime"];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  currentUser: any;
  language:any;
  browserRefresh:boolean = false;
  listData:any = [];
  search:any;
  ngOnInit(): void {
    // this.dataSource.sort=this.sort;
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    // this.dataSource:any=[];
    // this.dataSource.sort=this.sort;
    // this.dataSource.sort=this.sort;
    // this.getUsersList(null);
    // let currentUser = JSON.parse(localStorage.getItem("currentUser"));
		let currentUsers = this.currentUser.response;
		let lang = currentUsers.langPreference;
		

    let states;
    states = this.location.getState();
    

		if(lang == 'en'){
      this.headerToDisplay={
        firstName:"FIRST NAME",
        lastName:"LAST NAME",
        email:"EMAIL",
        mobileNo:"Contact No",
        usertype:"Role Name",
        status:"Status",
        actions:"Actions",
        userManagement:"User Management",
        search:"Search",
        addUser:"Add User",
        companyAllowed:"Company Allowed",
        plantAllowed:"Plant Allowed",
        userName:"User Id",
        lastLoginTime: "Last Login Date & Time"
      }
		  }else if(lang == 'th'){
      this.headerToDisplay={
        firstName:"ชื่อจริง",
        lastName:"นามสกุล",
        email:"อีเมล",
        mobileNo:"เบอร์ติดต่อ",
        usertype:"ชื่อบทบาท",
        status:"สถานะ",
        actions:"การกระทำ",
        userManagement:"การจัดการผู้ใช้",
        search:"ค้นหา",
        addUser:"เพิ่มผู้ใช้",
        companyAllowed:"บริษัทอนุญาต",
        plantAllowed:"อนุญาติให้ปลูกพืชได้",
        userName:"รหัสผู้ใช้",
        lastLoginTime:"วันที่และเวลาเข้าสู่ระบบล่าสุด"
      }
      }
      if(localStorage.getItem("searchData") != null){
        this.searchString = localStorage.getItem("searchData")
      }
      
      if(browserRefresh == true){
        this.searchString = '';
        localStorage.setItem("searchData","")
        localStorage.setItem("tableData",JSON.stringify([]))
      }
      let tableData:any = [];
      if(localStorage.getItem("tableData")){
        tableData = JSON.parse(localStorage.getItem("tableData"));
        this.listData = tableData;
      
      }
      
      if(tableData.length == 0){
        this.searchString = states.search?states.search:"";
        localStorage.setItem("searchData",states.search?states.search:"")
        this.getUsersList(null, this.searchString);
      }else{
        this.dataSource = tableData.response;
        this.listLength = tableData.pagination.count;
        localStorage.setItem("tableData",JSON.stringify([]))
      }
  }
  
  pageIndex = 0;
  pageSize = 10;
  listLength: any;
  searchString:any='';
  getUsersList(event: PageEvent, searchString:any) {
    this.mySpinner = true;
    if (event == null) {
      this.pageIndex = 0;
      this.pageSize = 10;
    } else {
     
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
     
    }

    this.cService.getUsersList(this.pageIndex, this.pageSize, this.searchString).pipe(first()).subscribe(res => {
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        this.mySpinner = false;
        // this.mySpinner=false;
        let response = [];
        // for(let i=0;i <= res.response.length-1;i++){
        //   if(res.response[i].roleID != 2){
        //     response.push(res.response[i]);
        //   }
        // }
        this.dataSource = new MatTableDataSource(res.response);
        this.listData = res;
        this.listLength = res.pagination.count;
        this.dataSource.sort = this.sort;
      } else {
        // this.mySpinner=false;
        this.mySpinner = false;
        this.dataSource = new MatTableDataSource([]);
        this.listData = [];
        this.listLength = 0;
        
        swal.fire(res.responseMessage, "", "error");
      }
    }, error => {
      this.mySpinner = false;
    })
  }
  openDialog(_add: any, obj: any) {
    obj.action = _add;
    let userid = obj.id;
    

    const dialogRef = this.dialog.open(MyModalComponent, {
      width: '500px',
      data: obj
    });
    dialogRef.afterClosed().subscribe(result => {
      
      if (result != undefined) {
        // swal.fire("User creation successful!");
        // let companyAllowed=[];
        // if (result.data.companyAllowed.length > 1) {
        //   for(let i=0;i <= result.data.companyAllowed.length-1;i++){
        //     
        //     companyAllowed.push(result.data.companyAllowed[i].plantCode)
        //   }
          
        // }
        // 
        let userdata;
        if (result.event == "Add") {
          userdata = {
            "firstName": result.data.firstName,
            "lastName": result.data.lastName,
            "fullName": result.data.firstName + " " + result.data.lastName,
            "email": result.data.email,
            "contactNo": result.data.contactNo,
            // "isactive": 1,
            // "isBlocked":1,
            "roleID": result.data.roleID,
            "userName":result.data.userName,
            "companyAllowed":result.data.companyAllowed.toString()
          };
          
          this.addUser(userdata);
        }
        else if (result.event == "Update") {
          userdata = {
            "id": userid,
            "firstName": result.data.firstName,
            "lastName": result.data.lastName,
            "fullName": result.data.firstName + " " + result.data.lastName,
            "email": result.data.email,
            "contactNo": result.data.contactNo,
            
            "roleID": result.data.roleID,
            "companyAllowed":result.data.companyAllowed.toString()
          };
          
          this.updateUser(userdata);
        }
      }

    })
  }

  addUser(row_obj) {
    this.mySpinner = true;
    this.mySpinner = true;
    this.cService.addUser(row_obj).pipe(first()).subscribe(data => {
      if (data != null) {
        if (data.responseStatus == "SUCCESS") {
          // this._snackBar.open('User Added Successfully', 'Ok', {
          //   duration: 5000,
          // });
          
          // this.dataSource = data;
          this.mySpinner = false;
          this.mySpinner = false;
          swal.fire(
            { 
              icon: 'success',
              title: '',
              text: data.responseMessage,
              footer: ''
          }
          );
          this.getUsersList(null,this.searchString);
        } else {
          this.mySpinner = false;
         
          // swal.fire(data.responseMessage)
          swal.fire(
            { 
              icon: 'error',
              title: '',
              text: data.responseMessage,
              footer: ''
          }
          );
        }
      }
      // this.mySpinner=false;
    }, () => {
      this.mySpinner = false;
    })

  }


  updateUser(row_obj) {
    this.mySpinner = true;
    this.mySpinner = true;
    this.cService.updateUser(row_obj).pipe(first()).subscribe(data => {
      if (data != null) {
        if (data.responseStatus == "SUCCESS") {
        //   this._snackBar.open('User Updated Successfully', 'Ok', {
        //     duration: 5000,
        //   });
        
          // this.dataSource = data;
          this.mySpinner = false;
          this.mySpinner = false;
          swal.fire(
            { 
              icon: 'success',
              title: '',
              text: data.responseMessage,
              footer: ''
          }
          );
          this.getUsersList(null, this.searchString);
        } else {
          this.mySpinner = false;
          this.mySpinner = false;
          swal.fire(
            { 
              icon: 'error',
              title: '',
              text: data.responseMessage,
              footer: ''
          }
          );
          // swal.fire(data.responseMessage)
          
        }
      }
      // this.mySpinner=false;
    }, () => {
      this.mySpinner = false;
    })

  }

  keyword:any;
  applyFilter1(){
    const filterValue = this.searchString;
    localStorage.setItem("searchData", this.searchString);
    // const filterValue = (event.target as HTMLInputElement).value;
    // const filterValue = (event.target as HTMLInputElement).value;//this.searchString;
    if(this.searchString == "" ){
      this.getUsersList(null, this.searchString);
    }
  }
  applyFilter() {
    // 
    const filterValue = this.searchString;
    // const filterValue = (event.target as HTMLInputElement).value;
    // const filterValue = (event.target as HTMLInputElement).value;//this.searchString;
    if(filterValue.length >= 2 || this.searchString == "" ){
      this.getUsersList(null, this.searchString);
    }
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    
  }
  calculateClass(status) {
    let val = true;
    if (status == "1") {
      val = false;
    }
    return {
      "color-green": val,
      "color-red": !val
    }
  }
  openConfirm(element: any) {
    var dialogRef;
    if(element["isBlocked"] == 1){

     dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'User will get access to portal after confirmation',
        buttonText: {
          ok: 'Submit',
          cancel: 'Cancel',
        },
        buttonColor: {
          ok: 'primary',
          cancel: '',
        },
      },
    });
  } else {
     dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'User will not be able to access portal after confirmation',
        buttonText: {
          ok: 'Submit',
          cancel: 'Cancel',
        },
        buttonColor: {
          ok: 'primary',
          cancel: '',
        },
      },
    });
  }


    
    let act = element["isBlocked"];
    if (act == '0') {
      act = 1;
    } else {
      act = 0;
    }
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        var updateUserData = {
          "isBlocked": act,
          "id": element["id"],

        }
        this.updateRowData(updateUserData);
      }
    })
  }
  updateRowData(row_obj) {
    this.mySpinner = true;

    this.cService.blockUser(row_obj).pipe(first()).subscribe(data => {
      if (data != null) {
        if (data.responseStatus != "ERROR") {
          if(row_obj.isBlocked == 1){
            this._snackBar.open('User Blocked Successfully', 'Ok', {
              duration: 5000,
            });
          }else{
            this._snackBar.open('User Unblocked Successfully', 'Ok', {
              duration: 5000,
            });
          }
          
          // this.dataSource = data;
          this.mySpinner = false;
          this.getUsersList(null, this.searchString);
        } else {
          this.mySpinner = false;
          swal.fire(data.responseMessage, "", "error")
        }
      }
      // this.mySpinner=false;
    }, error => {
      this.mySpinner = false;
    })

  }
  openUserDetails(element: any) {
    
    localStorage.setItem("tableData",JSON.stringify(this.listData));
    this.router.navigate(["user-management/user-modal", element.id]);
  }

  async exportexcel(searchString:any)
{
  
  this.mySpinner = true;

  // if (event == null) {
  //   this.pageIndex = 0;
  //   this.pageSize = 10;
  // } else {
   
  //     this.pageIndex = "";
  //     this.pageSize = "";
   
  // }
    (await this.cService.getUsersListExport(this.searchString)).pipe(first()).subscribe(res => {
      if(res.response != null){
        if (res.responseStatus.toUpperCase() == "SUCCESS") {
          
          // this.table = true;
          this.mySpinner = false;
        } else {
          this.mySpinner = false;
          swal.fire(res.responseMessage,"","error");
        }
      }else{
        this.mySpinner = false;
        swal.fire(res.responseMessage,"","error");
      }

      let onlyNameAndSymbolArr: Partial<PeriodicElement>[];
      let filterData:any = [];
       res.response.map((x:any) =>{ 
        if(x.roleID == 4 || x.roleID == 5 || x.roleID == 8) {        
          let data =
         ({ "STATUS": x.isBlocked == 0 ? 'Active' : 'Blocked',  
          "USER ID": x.userName,    
          "FIRST NAME": x.firstName,
          "LAST NAME": x.lastName,
          "EMAIL": x.email,     
          "CONTACT NO": x.contactNo,    
          "COMPANY ALLOWED": x.companyAllowed, 
          "PLANT ALLOWED": "", 
          "ROLE NAME": x.roleName, 
        })
        filterData.push(data);
       } else if (x.roleID == 3 || x.roleID == 6 || x.roleID == 7) {  
         let data =
        ({ "STATUS": x.isBlocked == 0 ? 'Active' : 'Blocked',  
         "USER ID": x.userName,  
         "FIRST NAME": x.firstName, 
         "LAST NAME": x.lastName,  
         "EMAIL": x.email,  
         "CONTACT NO": x.contactNo, 
         "COMPANY ALLOWED": "",    
         "PLANT ALLOWED": x.companyAllowed,  
         "ROLE NAME": x.roleName,  })

        filterData.push(data);
             } else{
          let data =
               ({ "STATUS": x.isBlocked == 0 ? 'Active' : 'Blocked',  
                "USER ID": x.userName,  
                "FIRST NAME": x.firstName, 
                "LAST NAME": x.lastName,  
                "EMAIL": x.email,  
                "CONTACT NO": x.contactNo, 
                "COMPANY ALLOWED": "",    
                "PLANT ALLOWED": "",  
                "ROLE NAME": x.roleName,  })
       
               filterData.push(data);
         }   
     } )
     onlyNameAndSymbolArr = filterData;
  TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "User Managment-Report");
})
}

}

export interface PeriodicElement {
  status:any,
  userId:any,
  firstName:any,
  lastName:any,
  email:any
  contactNo:any,
  companyAllowed:any,
  plantAllowed:any,
  roleName:any,
}