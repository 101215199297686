import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { CommonServicesService } from 'src/app/services/common-services.service';
import { first } from 'rxjs/operators';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { TableUtil } from 'src/app/reports/TableUtil';
import { AdminServiceService } from 'src/app/services/admin-service.service';
import { thFont } from 'src/app/reports/thai-lang';


@Component({
  selector: 'app-vendor-report',
  templateUrl: './vendor-report.component.html',
  styleUrls: ['./vendor-report.component.css']
})
export class VendorReportComponent implements OnInit {
  @ViewChild('DNSDate') DNSDate : ElementRef;
  @ViewChild('DNEDate') DNEDate : ElementRef;
  @ViewChild('invSDate') invSDate : ElementRef;
  @ViewChild('invEDate') invEDate : ElementRef;
  @ViewChild('matTable') matTable!: ElementRef;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  requestData:any;
  dnRange = new FormGroup({
    deliveryNoteDateFrom: new FormControl(''),
    deliveryNoteDateTo: new FormControl(''),
  });

  inRange = new FormGroup({
    invoiceDateFrom: new FormControl(''),
    invoiceDateTo: new FormControl(''),
  })
  postingRange = new FormGroup({
    postingDateFrom: new FormControl(''),
    postingDateTo: new FormControl(''),
  })
  netDueDateRange = new FormGroup({
    netDueDateFrom: new FormControl(''),
    netDueDateTo: new FormControl(''),
  })
  table: boolean = false;
  dropdownSettings = {};
  dropdownSettings1 = {};
  dropdownSettings2 = {}
  columnsToDisplay:any=[];
  InvoiceFor:any;
  typeOfInvoice:any;
  calculatetax:any;
  mySpinner:boolean=false;
  dataSource:MatTableDataSource<any>;
  constructor(
    private fb: FormBuilder,
    public datepipe: DatePipe,
    private cService: CommonServicesService,
    private adminService: AdminServiceService,
    ) { }
    potypes = [
      { key: "G", value: "Goods" },
      { key: "W", value: "Goods - Weigh bridge" },
      { key: "S", value: "Service" }
  
    ];
    invoicetypes = [
      { key: "Y", value: "Invoice" },
      { key: "N", value: "E-Invoice" },
    ]
    calculatetaxtypes = [
      { key: "true", value: "true" },
      { key: "false", value: "false" },
    ]
    async openPDF() {
      this.mySpinner = true;
      (await this.cService.getexportPDFdataVendor(this.requestData)).pipe(first()).subscribe(res => {
        if(res.response != null){
          if (res.responseStatus.toUpperCase() == "SUCCESS") {
            
            this.table = true;
            this.mySpinner = false;
          } else {
            this.mySpinner = false;
            Swal.fire(res.responseMessage,"","error");
          }
        }else{
          this.mySpinner = false;
          Swal.fire(res.responseMessage,"","error");
        }

        var font = thFont;
      
      const doc = new jsPDF('l', 'pt', "a4",true);
      doc.addFileToVFS("supermarket.ttf",font);
      doc.addFont("supermarket.ttf", "Amiri",'bold', '800');
      doc.setFont("Amiri",'bold');
      doc.setFontSize(5);

      if(this.currentUser.roleID == 2){
        var col = ["Status","Company","po Number","po Item","plant Code","plant Name","material","service No","materialGroup","short Text","po Quantity","unit","unitPrice","baseLine Date","Delivery Note Date","Delivery Note Number","invoice No","Invoice Date","Total Amount","Currency","Payment Terms","Net Due Date","Payment Method","Invoice Doc Number","Invoice Posting Date","Wht Type1","Wht Code1","Wht Base Amount1","Wht Amount1","Wht Type2","Wht Code2","Wht Base Amount2","Wht Amount2","VpDN#","VP No","Type Of Invoice", "Invoice For","total Quantity","Vat Amt","fiDoc Number","calculate Tax"];
      }else{
        var col = ["Status","Company","Vendor Code","Name","po Number","po Item","plant Code","plant Name","material","service No","materialGroup","short Text","po Quantity","unit","unitPrice","baseLine Date","Delivery Note Date","Delivery Note Number","invoice No","Invoice Date","Total Amount","Currency","Payment Terms","Net Due Date","Payment Method","Invoice Doc Number","Invoice Posting Date","Wht Type1","Wht Code1","Wht Base Amount1","Wht Amount1","Wht Type2","Wht Code2","Wht Base Amount2","Wht Amount2","VpDN#","VP No","Type Of Invoice", "Invoice For","total Quantity","Vat Amt","fiDoc Number","calculate Tax","SAP Response"];
      }
      
      var rows = [];
  
      
      if(this.currentUser.roleID == 2){
        res.response.forEach(x => {      
          var temp = [
              x.status,
              x.company,
              x.poNumber,
              x.poItem,
              x.plantCode,
              x.plantName,
              x.material,
              x.serviceNumber,
              x.materialGroup,
              x.shortText,
              Number(x.poQuantity).toLocaleString('en', { minimumFractionDigits: 3 }),
              x.unit,
              Number(x.unitPrice).toLocaleString('en', { minimumFractionDigits: 2 }),
              x.baseLineDate != '-'?this.datepipe.transform(x.baseLineDate,'dd/MM/yyyy'):'-',
              x.deliveryNoteDate != "-"?this.datepipe.transform(x.deliveryNoteDate,'dd/MM/yyyy'):'-',
              x.deliveryNoteNo,
              x.invoiceNumber,
              x.invoiceDate != '-'?this.datepipe.transform(x.invoiceDate,'dd/MM/yyyy'):'-',
              Number(x.totalAmount).toLocaleString('en', { minimumFractionDigits: 2 }),
              x.currency,
              x.paymentTerms,
              x.netDueDate != '-'?this.datepipe.transform(x.netDueDate,'dd/MM/yyyy'):'-',  
              x.paymentMethods,
              x.invoiceDocNumber,
              x.postingDate != '-'?this.datepipe.transform(x.postingDate,'dd/MM/yyyy'):'-',
              x.whtType1,
              x.whtCode1,
              x.whtBaseAmount1?Number(x.whtBaseAmount1).toLocaleString('en', { minimumFractionDigits: 2 }):"",
              x.whtTaxAmount1?Number(x.whtTaxAmount1).toLocaleString('en', { minimumFractionDigits: 2 }):"",
              x.whtType2,
              x.whtCode2,
              x.whtBaseAmount2?Number(x.whtBaseAmount2).toLocaleString('en', { minimumFractionDigits: 2 }):"",
              x.whtTaxAmount2?Number(x.whtTaxAmount2).toLocaleString('en', { minimumFractionDigits: 2 }):"",
              x.vpDnNumber?x.vpDnNumber.substring(x.vpDnNumber.indexOf('_')+1):"",
              x.vpNumber.substring(x.vpNumber.indexOf('_')+1),
              x.typeOfInvoice,
              x.invoiceFor,
              Number(x.totalQuantity).toLocaleString('en', { minimumFractionDigits: 3 }),
              Number(x.amountVat).toLocaleString('en', { minimumFractionDigits: 2 }),
              x.fiDocNumber,
              x.calculateTax
          ];
          rows.push(temp);
  
      }); 
      }else{
        res.response.forEach(x => {      
          var temp = [
              x.status,
              x.company,
              x.vendorCode,
              x.vendorName,
              x.poNumber,
              x.poItem,
              x.plantCode,
              x.plantName,
              x.material,
              x.serviceNumber,
              x.materialGroup,
              x.shortText,
              Number(x.poQuantity).toLocaleString('en', { minimumFractionDigits: 3 }),
              x.unit,
              Number(x.unitPrice).toLocaleString('en', { minimumFractionDigits: 2 }),
              x.baseLineDate != '-'?this.datepipe.transform(x.baseLineDate,'dd/MM/yyyy'):'-',
              x.deliveryNoteDate != "-"?this.datepipe.transform(x.deliveryNoteDate,'dd/MM/yyyy'):'-',
              x.deliveryNoteNo,
              x.invoiceNumber,
              x.invoiceDate != '-'?this.datepipe.transform(x.invoiceDate,'dd/MM/yyyy'):'-',
              Number(x.totalAmount).toLocaleString('en', { minimumFractionDigits: 2 }),
              x.currency,
              x.paymentTerms,
              x.netDueDate != '-'?this.datepipe.transform(x.netDueDate,'dd/MM/yyyy'):'-',  
              x.paymentMethods,
              x.invoiceDocNumber,
              x.postingDate != '-'?this.datepipe.transform(x.postingDate,'dd/MM/yyyy'):'-',
              x.whtType1,
              x.whtCode1,
              x.whtBaseAmount1?Number(x.whtBaseAmount1).toLocaleString('en', { minimumFractionDigits: 2 }):"",
              x.whtTaxAmount1?Number(x.whtTaxAmount1).toLocaleString('en', { minimumFractionDigits: 2 }):"",
              x.whtType2,
              x.whtCode2,
              x.whtBaseAmount2?Number(x.whtBaseAmount2).toLocaleString('en', { minimumFractionDigits: 2 }):"",
              x.whtTaxAmount2?Number(x.whtTaxAmount2).toLocaleString('en', { minimumFractionDigits: 2 }):"",
              x.vpDnNumber?x.vpDnNumber.substring(x.vpDnNumber.indexOf('_')+1):"",
              x.vpNumber.substring(x.vpNumber.indexOf('_')+1),
              x.typeOfInvoice,
              x.invoiceFor,
              Number(x.totalQuantity).toLocaleString('en', { minimumFractionDigits: 3 }),
              Number(x.amountVat).toLocaleString('en', { minimumFractionDigits: 2 }),
              x.fiDocNumber,
              x.calculateTax,
              x.sapResponse
          ];
          rows.push(temp);
      }); 
      }
             
  
      (doc as any).autoTable(col, rows,{
        startY: false,
        theme: 'grid',
        tableWidth: 'auto',
        cellWidth: 'wrap',
        showHead: 'everyPage',
        tableLineColor: 200,
        tableLineWidth: 0,
        columnStyles: {
            0: {
                cellWidth: 23
            },
            1: {
                cellWidth: 23
            },
            2: {
              cellWidth: 19
            },
            3: {
              cellWidth: 19
            },
            4: {
              cellWidth: 19
            },
            5: {
              cellWidth: 15
            },
            6: {
              cellWidth: 20
            },
            7: {
                cellWidth: 20
            },
            8: {
                cellWidth: 20
            },
            9: {
                cellWidth: 20
            },
            10: {
                cellWidth: 18
            },
            11: {
                cellWidth: 24
            },
            12: {
                cellWidth: 15
            },
            13: {
                cellWidth: 18
            },
            14: {
                cellWidth: 18
            },
            15: {
                cellWidth: 18
            },
            16: {
                cellWidth: 18
            },
            17: {
                cellWidth: 18
            },
            18: {
                cellWidth: 18
            },
            19: {
                cellWidth: 18
            },
            20: {
                cellWidth: 18
            },
            21: {
                cellWidth: 18
            },
            22: {
                cellWidth: 18
            },
            23: {
                cellWidth: 18
            },
            24: {
                cellWidth: 18
            },
            25: {
                cellWidth: 18
            },
            26: {
                cellWidth: 18
            },
            27: {
                cellWidth: 18
            },
            28: {
                cellWidth: 18
            },
            29: {
                cellWidth: 18
            },
            30: {
              cellWidth: 18
            },
            31: {
                cellWidth: 18
            },
            32: {
              cellWidth: 18
            },
            33: {
              cellWidth: 18
            },
            34: {
              cellWidth: 18
            },
            35: {
              cellWidth: 18
            },
            36: {
              cellWidth: 18
            },
            37: {
              cellWidth: 18
            },
            38: {
              cellWidth: 18
            },
            39: {
              cellWidth: 18
            },
            40: {
              cellWidth: 18
            },
            41: {
              cellWidth: 18
            },
            42: {
              cellWidth: 18
            },
            43: {
              cellWidth: 24
            },
        },
        headStyles: {
            theme: 'grid',
            fillColor : [237, 28, 36],
            font: 'helvetica',
            fontStyle:"bold",
        },
        margins :{
          top: 10,
          bottom: 10,
          left: 10,
          width: 492
        },
        styles: {
            overflow: 'linebreak',
            cellWidth: 'wrap',
            // font: 'arial',
            fontSize: 3,
            font:"Amiri",
            fontStyle:"bold",
            // cellPadding: 2,
            overflowColumns: 'linebreak'
        },
    });
      doc.save('Vendor-Report.pdf');
    });
    }

    async exportexcel() {
      if(this.requestData == undefined){
  this.requestData = {
    "companyCodes": [],
    "status": [],
    "vendorCode": []
}
}
  this.mySpinner = true;
    (await this.cService.getexportPDFdataVendor(this.requestData)).pipe(first()).subscribe(res => {
      if(res.response != null){
        if (res.responseStatus.toUpperCase() == "SUCCESS") {
          
          this.table = true;
          this.mySpinner = false;
        } else {
          this.mySpinner = false;
          Swal.fire(res.responseMessage,"","error");
        }
      }else{
        this.mySpinner = false;
        Swal.fire(res.responseMessage,"","error");
      }

      let onlyNameAndSymbolArr: Partial<PeriodicElement>[];
      if(this.currentUser.roleID == 2){
        onlyNameAndSymbolArr = res.response.map(
          x => ({
            "STATUS": x.status,
            "COMPANY": x.company,
            "PO NUMBER": x.poNumber,
            "PO ITEM":x.poItem,
            "PLANT CODE":x.plantCode,
            "PLANT NAME":x.plantName,
            "MATERIAL NO":x.material,
            "SERVICE NUMBER":x.serviceNumber,
            "MATERIAL GROUP":x.materialGroup,
            "SHORT TEXT":x.shortText,
            "PO QTY": Number(x.poQuantity).toLocaleString('en', { minimumFractionDigits: 3 }),
            "UNIT":x.unit,
            "UNIT PRICE": Number(x.unitPrice).toLocaleString('en', { minimumFractionDigits: 2 }),
            "BASELINE DATE": x.baseLineDate != '-'?this.datepipe.transform(x.baseLineDate,'dd/MM/yyyy'):'-',
            "DELIVERY DATE": x.deliveryNoteDate != "-"?this.datepipe.transform(x.deliveryNoteDate,'dd/MM/yyyy'):'-',
            "DELIVERY NOTE NO": x.deliveryNoteNo,
            "INVOICE NO": x.invoiceNumber,
            "INVOICE DATE": x.invoiceDate != '-'?this.datepipe.transform(x.invoiceDate,'dd/MM/yyyy'):'-',
            "TOTAL AMOUNT": Number(x.totalAmount).toLocaleString('en', { minimumFractionDigits: 2 }),
            "CURRENCY": x.currency,
            "PAYMENT TERMS": x.paymentTerms,
            "NET DUE DATE": x.netDueDate != '-'?this.datepipe.transform(x.netDueDate,'dd/MM/yyyy'):'-',
            "INVOICE DOC#": x.invoiceDocNumber,
            "PAYMENT METHOD": x.paymentMethods,
            "POSTING DATE": x.postingDate != '-'?this.datepipe.transform(x.postingDate,'dd/MM/yyyy'):'-',
            "WHT TYPE 1": x.whtType1,
            "WHT CODE 1": x.whtCode1,
            "WHT BASE AMT 1": x.whtBaseAmount1?Number(x.whtBaseAmount1).toLocaleString('en', { minimumFractionDigits: 2 }):"",
            "WHT AMT 1": x.whtTaxAmount1?Number(x.whtTaxAmount1).toLocaleString('en', { minimumFractionDigits: 2 }):"",
            "WHT TYPE 2": x.whtType2,
            "WHT CODE 2": x.whtCode2,
            "WHT BASE AMT 2": x.whtBaseAmount2?Number(x.whtBaseAmount2).toLocaleString('en', { minimumFractionDigits: 2 }):"",
            "WHT AMT 2": x.whtTaxAmount2?Number(x.whtTaxAmount2).toLocaleString('en', { minimumFractionDigits: 2 }):"",
            "VPDN#": x.vpDnNumber ? x.vpDnNumber.substring(x.vpDnNumber.indexOf('_')+1) : "",
            "VP NO": x.vpNumber.substring(x.vpNumber.indexOf('_')+1),
            "TYPE OF INVOICE":x.typeOfInvoice,
            "INVOICE FOR":x.invoiceFor,
            "TOTAL QTY": Number(x.totalQuantity).toLocaleString('en', { minimumFractionDigits: 3 }),
            "VAT AMT": Number(x.amountVat).toLocaleString('en', { minimumFractionDigits: 2 }),
            "FI DOC#":x.fiDocNumber,
            "CALCULATE TAX":x.calculateTax
          })
        )
      }else{
        onlyNameAndSymbolArr = res.response.map(
          x => ({
            "STATUS": x.status,
            "COMPANY": x.company,
            "VENDOR CODE":x.vendorCode,
            "NAME":x.vendorName,
            "PO NUMBER": x.poNumber,
            "PO ITEM":x.poItem,
            "PLANT CODE":x.plantCode,
            "PLANT NAME":x.plantName,
            "MATERIAL NO":x.material,
            "SERVICE NUMBER":x.serviceNumber,
            "MATERIAL GROUP":x.materialGroup,
            "SHORT TEXT":x.shortText,
            "PO QTY": Number(x.poQuantity).toLocaleString('en', { minimumFractionDigits: 3 }),
            "UNIT":x.unit,
            "UNIT PRICE": Number(x.unitPrice).toLocaleString('en', { minimumFractionDigits: 2 }),
            "BASELINE DATE": x.baseLineDate != '-'?this.datepipe.transform(x.baseLineDate,'dd/MM/yyyy'):'-',
            "DELIVERY DATE": x.deliveryNoteDate != "-"?this.datepipe.transform(x.deliveryNoteDate,'dd/MM/yyyy'):'-',
            "DELIVERY NOTE NO": x.deliveryNoteNo,
            "INVOICE NO": x.invoiceNumber,
            "SES NUMBER":x.sesNumber,
            "INVOICE DATE": x.invoiceDate != '-'?this.datepipe.transform(x.invoiceDate,'dd/MM/yyyy'):'-',
            "TOTAL AMOUNT": Number(x.totalAmount).toLocaleString('en', { minimumFractionDigits: 2 }),
            "CURRENCY": x.currency,
            "PAYMENT TERMS": x.paymentTerms,
            "NET DUE DATE": x.netDueDate != '-'?this.datepipe.transform(x.netDueDate,'dd/MM/yyyy'):'-',
            "INVOICE DOC#": x.invoiceDocNumber,
            "PAYMENT METHOD": x.paymentMethods,
            "POSTING DATE": x.postingDate != '-'?this.datepipe.transform(x.postingDate,'dd/MM/yyyy'):'-',
            "WHT TYPE 1": x.whtType1,
            "WHT CODE 1": x.whtCode1,
            "WHT BASE AMT 1": x.whtBaseAmount1?Number(x.whtBaseAmount1).toLocaleString('en', { minimumFractionDigits: 2 }):"",
            "WHT AMT 1": x.whtTaxAmount1?Number(x.whtTaxAmount1).toLocaleString('en', { minimumFractionDigits: 2 }):"",
            "WHT TYPE 2": x.whtType2,
            "WHT CODE 2": x.whtCode2,
            "WHT BASE AMT 2": x.whtBaseAmount2?Number(x.whtBaseAmount2).toLocaleString('en', { minimumFractionDigits: 2 }):"",
            "WHT AMT 2": x.whtTaxAmount2?Number(x.whtTaxAmount2).toLocaleString('en', { minimumFractionDigits: 2 }):"",
            "VPDN#": x.vpDnNumber ? x.vpDnNumber.substring(x.vpDnNumber.indexOf('_')+1) : "",
            "VP NO": x.vpNumber.substring(x.vpNumber.indexOf('_')+1),
            "TYPE OF INVOICE":x.typeOfInvoice,
            "INVOICE FOR":x.invoiceFor,
            "TOTAL QTY": Number(x.totalQuantity).toLocaleString('en', { minimumFractionDigits: 3 }),
            "VAT AMT": Number(x.amountVat).toLocaleString('en', { minimumFractionDigits: 2 }),
            "FI DOC#":x.fiDocNumber,
            "CALCULATE TAX":x.calculateTax,
            "SAP RESPONSE":x.sapResponse
          })
        )
      }

  

  TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "Vendor-Report");
})
// if(this.requestData == undefined){
//   this.requestData = {
//     "companyCodes": [],
//     "status": [],
//     "vendorCode": []
// }
// }
// this.mySpinner = true;
//   (await this.cService.vendorExport(this.requestData)).pipe(first()).subscribe(res => {
//     this.mySpinner = false;
//     
//   }, error => {
//     this.mySpinner = false;
//   })
}
  vendorReportForm: FormGroup = this.fb.group({
        companyCodes:[],
        multiSelectCompany:[],
        multiSelectStatus:[],
        // deliveryNoteNoFrom: [],
        // deliveryNoteNoTo: [],
        deliveryNoteNo:[],
        invoiceNoFrom: [],
        invoiceNoTo: [],
        purchaseOrderNoFrom: [],
        purchaseOrderNoTo:[],
        vendorPortalRefNo: [],
        InvoiceFor:[],
        typeOfInvoice:[],
        calculatetax:[],
        postingDateFrom:[],
        postingDateTo:[],
        netDueDateFrom:[],
        netDueDateTo:[],
        vendorPortalRefNoFrom: [],
        vendorPortalRefNoTo: [],
        vpNoFrom:[],
        vpNoTo:[],
        vpdnTo:[],
        vpdnFrom:[],
        multiSelectVendor:[]
  });

  // companyList =[
  //   {"companyCode": "1000","companyName": "Siam City Cement"},
  //   {"companyCode": "1001","companyName": "INSEE"}
  // ];
  // invoiceStatus =[
  //     {"statusId": "1","status": "Success"},
  //     {"statusId": "2","status": "Failed"},
  //     {"statusId": "3","status": "Pending"},
  //     {"statusId": "4","status": "To be Verified"}
  // ];
  dropdownList = [];
  vendorMaster = [];
  selectedItems = [];
  language:any;
  currentUser:any=[];
  ngOnInit() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
    if(lang == 'en'){
        this.language = {
          "poNumber":"PO No",
          "EnterPONumberFrom":"Enter PO No From",
          "EnterPONumberTo":"Enter PO No To",
          "totalAmount":"Total Amount",
          "Currency":"Currency",
          "PaymentTerm":"Payment Terms",
          "button1":"Approve",
          "button2":"Reject",
          "VendorCode":"Vendor Code",
          "VendorName":"Vendor Name",
          "postingDate":" Posting Date",
          "Head":"Invoice Tracking Report",
          "invoiceDate":"Invoice Date",
          "CodeFrom":"Code From",
          "ToCode":"To Code",
          "dateRange":"Enter a date range",
          "Startdate":"Start date",
          "Enddate":"End date",
          "VPNumber":"VP#",
          "InvoiceNumber":"Invoice No",
          "NumberFrom":"Enter Invoice No",
          "ToNumber":"To Number",
          "Search":"Search",
          "DOC":"Invoice DOC#",
          "Invoice":"Invoice No",
          "taxType":"TAX TYPE",
          "paymentDate":"PAYMENT DATE",
          "paymentDoc":"PAYMENT DOC.",
          "paymentMethod":"PAYMENT METHOD",
          "WHTTYPE1":"WHT TYPE 1",
          "WHTCODE1":"WHT CODE 1",
          "WHTBASEAmt1":"WHT BASE Amt 1",
          "WHTAmt1":"WHT Amt 1",
          "WHTTYPE2":"WHT TYPE 2",
          "WHTCODE2":"WHT CODE 2",
          "WHTBASEAmt2":"WHT BASE Amt 2",
          "WHTAmt2":"WHT Amt 2",
          "vp":"VPDN#",
          "vpIn":"VP# Invoice",
          "po":"PO Number",
          "SAPAPUSER":"SAP AP USER",
          "print":"Print",
          "status":"Status",
          "Comment":"Comment",
          "Pending":"Pending",
          "Clear":"Clear",
          "company":"Company",
          "selectCompany":"Select Company",
          "SelectStatus":"Select Status",
          "EnterVPNumber":"Enter Delivery Note No",
          "EnterVendorName":"Enter Vendor Name",
          "EnterPONumber":"Enter PO Number",
          "EntryDate":"Entry Date",
          "typeOfInvoice":"Type Of Invoice",
          "DeliveryNoteDate":"Delivery Date",
          "DeliveryNoteNo":"Delivery Note No",
          "swalDNFrom":"DN Number 'From' is required",
          "swalDNTo":"DN Number 'To' is required",
          "swalInFrom":"Invoice Number 'From' is required",
          "swalInTo":"Invoice Number 'To' is required",
          "swalDNGFT":"DN Number 'From' cannot be greater than DN Number 'To'",
          "swalInGFT":"Invoice Number 'From' cannot be greater than Invoice Number 'To'",
          "swalStatus":"Select status to get Vendor Report",
          "swalInvoiceDate":"Invoice Date 'From' cannot be greater than Invoice Date 'To'",
          "swalPostingDate":"Posting Date 'From' cannot be greater than Posting Date 'To'",
          "swalnetDueDate":"Net Due Date 'From' cannot be greater than Net Due Date 'To'",
          "swaldeliveryNoteDate":"DN Date 'From' cannot be greater than DN Date 'To'",
          "invoiceFor":"Invoice For",
          "EnterVPNumberFrom":"Enter VP No From",
          "EnterVPNumberTo":"Enter VP No To",
          "VPDNNumber":"VPDN#",
          "EnterVPDNNumberFrom":"Enter VPDN No From",
          "EnterVPDNNumberTo":"Enter VPDN No To",
          "poItem":"PO ITEM",
          "plantCode":"PLANT CODE",
          "plantName":"PLANT NAME",
          "unitPrice":"UNIT PRICE",
          "baseLineDate":"BASELINE DATE",
          "totalQuantity":"TOTAL QTY",
          "amountVat":"VAT AMT",
          "fiDocNumber":"FI DOC#",
          "calculateTax":"CALCULATE TAX",
          "vedorCode":"Vendor Code",
          "swalvenCode":"Please Select Vendor Code",
          "netDueDate":"Net Due Date",
          "vpNo":"VP No",
          "materialNo":"Material No",
          "materialGroup":"Material Group",
          "shortText":"Short Text",
          "poQuantity":"PO QTY",
          "unit":"Unit",
          "calculatetaxx":"Calculate Tax",
          "vendorCode":"Vendor Code",
          "name":"Name",
          "sapResponse":"SAP Response",
          "serviceNumber":"Service number",
          "sesNumber":"SES Number"

    }}
    else if(lang == 'th'){
      this.language = {
        "poNumber":"ตัวเลข PO",
        "EnterPONumberFrom":"ป้อนหมายเลข PO จาก",
        "EnterPONumberTo":"ป้อนหมายเลข PO ถึง",
        "totalAmount":"จำนวนเงินทั้งหมด",
        "Currency":"สกุลเงิน",
        "PaymentTerm":"เงื่อนไขการชำระเงิน",
        "button1":"อนุมัติ",
        "button2":"ปฏิเสธ",
        "VendorCode":"รหัสผู้ขาย",
        "VendorName":"ชื่อผู้จำหน่าย",
        "postingDate":"วันที่โพสต์",
        "Head":"รายงานการติดตามใบแจ้งหนี้",
        "invoiceDate":"วันที่ออกใบแจ้งหนี้",
        "CodeFrom":"รหัสจาก",
        "ToCode":"รหัส",
        "dateRange":"ป้อนช่วงวันที่",
        "Startdate":"วันที่เริ่มต้น",
        "Enddate":"วันที่สิ้นสุด",
        "VPNumber":"VP#",
        "InvoiceNumber":"เลขใบสั่งของ",
        "NumberFrom":"ป้อนหมายเลขใบแจ้งหนี้",
        "ToNumber":"ไปที่หมายเลข",
        "Search":"ค้นหา",
        "DOC":"เอกสารใบแจ้งหนี้#",
        "Invoice":"เลขใบสั่งของ",
        "taxType":"ประเภทภาษี",
        "paymentDate":"วันจ่าย",
        "paymentDoc":"เอกสารการชำระเงิน.",
        "paymentMethod":"วิธีการชำระเงิน",
        "WHTTYPE1":"WHT พิมพ์ 1",
        "WHTCODE1":"WHT รหัส 1",
        "WHTBASEAmt1":"WHT ปริมาณพื้นฐาน 1",
        "WHTAmt1":"WHT จำนวน 1",
        "WHTTYPE2":"WHT พิมพ์ 2",
        "WHTCODE2":"WHT รหัส 2",
        "WHTBASEAmt2":"WHT ปริมาณพื้นฐาน 2",
        "WHTAmt2":"WHT จำนวน 2",
        "vp":"VPDN#",
        "vpIn":"VP# ใบแจ้งหนี้",
        "po":"เลขที่ใบสั่งซื้อ ",
        "SAPAPUSER":"SAP AP USER",
        "print":"พิมพ์",
        "status":"สถานะ",
        "Comment":"ความคิดเห็น",
        "Pending":"รอดำเนินการ",
        "Clear":"ล้างข้อมูลการค้นหา",
        "company":"บริษัท",
        "selectCompany":"เลือกบริษัท",
        "SelectStatus":"เลือกสถานะ",
        "EnterVPNumber":"ใส่ใบส่งสินค้าเลขที่",
        "EnterVendorName":"ใส่ชื่อผู้ขาย",
        "EnterPONumber":"ป้อนหมายเลข PO",
        "EntryDate":"วันที่เข้า",
        "typeOfInvoice":"ประเภทของใบแจ้งหนี้",
        "DeliveryNoteDate":"วันที่จัดส่ง",
        "DeliveryNoteNo":"หมายเลขใบส่งสินค้า",
        "swalDNFrom":"ต้องระบุหมายเลข DN 'จาก'",
        "swalDNTo":"ต้องระบุหมายเลข DN 'ถึง'",
        "swalInFrom":"ต้องระบุหมายเลขใบแจ้งหนี้ 'จาก'",
        "swalInTo":"ต้องระบุหมายเลขใบแจ้งหนี้ 'ถึง'",
        "swalInGFT":"หมายเลขใบแจ้งหนี้ 'จาก' ต้องไม่มากกว่าหมายเลขใบแจ้งหนี้ 'ถึง'",
        "swalStatus":"เลือกสถานะเพื่อรับรายงานผู้ขาย",
        "swalDNGFT":"หมายเลข DN 'จาก' ต้องไม่มากกว่าหมายเลข DN 'ถึง'",
        "swalInvoiceDate":"วันที่ในใบแจ้งหนี้ 'จาก' ต้องไม่มากกว่าวันที่ในใบแจ้งหนี้ 'ถึง'",
        "swaldeliveryNoteDate":"วันที่ DN 'จาก' ต้องไม่มากกว่าวันที่ DN 'ถึง'",
        "swalPostingDate":"วันที่โพสต์ 'จาก' ต้องไม่มากกว่าวันที่โพสต์ 'ถึง'",
        "swalnetDueDate":"วันที่ครบกำหนดสุทธิ 'จาก' ต้องไม่เกินวันที่ครบกำหนดสุทธิ 'ถึง'",
        "invoiceFor":"ใบแจ้งหนี้สำหรับ",
        "EnterVPNumberFrom":"ใส่หมายเลข VP From",
        "EnterVPNumberTo":"ใส่หมายเลข VP To",
        "EnterVPDNNumberFrom":"ป้อน VPDN ไม่มีจาก",
        "EnterVPDNNumberTo":"ป้อน VPDN No To",
        "poItem":"รายการสั่งซื้อ",
        "plantCode":"รหัสพืช",
        "plantName":"ชื่อพืช",
        "unitPrice":"ราคาต่อหน่วย",
        "baseLineDate":"วันที่พื้นฐาน",
        "totalQuantity":"จำนวนรวม",
        "amountVat":"จำนวนเงินVat",
        "fiDocNumber":"FI DOC#",
        "calculateTax":"คำนวณภาษี",
        "vedorCode":"รหัสผู้ขาย",
        "swalvenCode":"กรุณาเลือกรหัสผู้จำหน่าย",
        "netDueDate":"วันครบกำหนดสุทธิ",
        "vpNo":"หมายเลข VP",
        "materialNo":"หมายเลขวัสดุ",
        "materialGroup":"กลุ่มวัสดุ",
        "shortText":"ข้อความสั้น",
        "poQuantity":"ปริมาณ PO",
        "unit":"หน่วย",
        "calculatetaxx":"คำนวณภาษี",
        "vendorCode":"รหัสผู้ขาย",
        "name":"ชื่อ",
        "sapResponse":"SAP Response",
        "serviceNumber":"หมายเลขบริการr",
        "VPDNNumber":"VPDN#",
        "sesNumber":"หมายเลข SES"

  }
}

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'companyCode',
      textField: 'companyName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownSettings1 = {
      singleSelection: false,
      closeDropDownOnSelection: true,
      idField: 'id',
      textField: 'status',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    this.dropdownSettings2 = {
      singleSelection: false,
      closeDropDownOnSelection: true,
      idField: "vendorCode",
      textField: "nameOne",
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
    if(this.currentUser.roleID == 2){
      this.columnsToDisplay = ["status","company","poNumber","poItem","plantCode","plantName","material",
      "serviceNumber","materialGroup","shortText","poQuantity","unit","unitPrice","baseLineDate","deliveryNoteDate","deliveryNoteNumber","invoiceNo","invoiceDate","totalAmount","currency","paymentTerms","netDueDate","paymentMethod","invoiceDocNumber","invoicePostingDate","whtType1","whtCode1","whtBaseAmount1","whtAmount1","whtType2","whtCode2","whtBaseAmount2","whtAmount2","vpNumberDN","vpNumberInvoice","typeOfInvoice", "invoiceFor","totalQuantity","amountVat","fiDocNumber","calculateTax"];
    }else{
      this.columnsToDisplay = ["status","company","vendorCode","name","poNumber","poItem","plantCode","plantName","material","serviceNumber","materialGroup","shortText","poQuantity","unit","unitPrice","baseLineDate","deliveryNoteDate","deliveryNoteNumber","invoiceNo","sesNumber","invoiceDate","totalAmount","currency","paymentTerms","netDueDate","paymentMethod","invoiceDocNumber","invoicePostingDate","whtType1","whtCode1","whtBaseAmount1","whtAmount1","whtType2","whtCode2","whtBaseAmount2","whtAmount2","vpNumberDN","vpNumberInvoice","typeOfInvoice", "invoiceFor","totalQuantity","amountVat","fiDocNumber","calculateTax","sapResponse"];
    }
    this.getCompanyMaster();
    this.getVendorCodes();
  }

  async getVendorCodes() {
    this.mySpinner = true;
    (await this.adminService.getVendorsCode()).pipe(first()).subscribe(res => {
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        let plantData:any=[];
        for(let i  of res.response){
          let obj = {
            "vendorCode":i.vendorCode,
            "nameOne":i.vendorCode+"-"+i.nameOne,
            "id":i.id
          }
          plantData.push(obj);
        }
        this.vendorMaster = plantData;
        

      
        this.mySpinner = false;
      }else{
        this.mySpinner = false;
      }
    },error =>{
      this.mySpinner = false;
    })

  }

  companyMaster:any = [];
  invoiceStatus:any = [];
  async getCompanyMaster(){
    (await this.cService.getMasterData()).pipe(first()).subscribe((data) => {
    // (await this.cService.getCompanyMaster(this.currentUser.usertype)).pipe(first()).subscribe((data) => {
      let companyData = [];
      // if (data != null) {
      if (data.responseStatus.toUpperCase() == "SUCCESS") {
        for (let i of data.response.companyLists) {
          let obj = {
            companyCode: i.companyCode,
            companyName: i.companyCode + "-" + i.name,
          };
          companyData.push(obj);
          this.invoiceStatus = data.response.invoiceStatus;
        }
        this.companyMaster = companyData;
        
        

        this.mySpinner = false;
      } else {
        this.mySpinner = false;
        Swal.fire({ 
          icon:'error',
          text: data.responseMessage
        });
      }
      // }
    },
      error => {
        this.mySpinner = false;
      }
    );
  }

  companyLists =[];
  companyCode:any;
  companyName:any;
  vendorName:any;
  vendorList = [];
  onSelect(item: any) {
    this.companyName = item.companyName;
    this.companyLists.push(item.companyCode);
    
  }

  onDeSelect(item: any) {
    
    const index = this.companyLists.indexOf(item.companyCode);
    
    this.companyLists.splice(index, 1);
    
  }

  onSelectAll(items: any) {
    this.companyLists = [];
    for (let i = 0; i < items.length; i++){
      this.companyLists.push(items[i].companyCode);
    }
    
  }
  
  onDeSelectAll(items: any) {
    this.companyLists = [];
    
  }
  status: any;
  statusId:any;
  itemstatus = [];
  onSelect1(item: any) {
    
    this.status = item.status;
    this.itemstatus.push(item.id);
  }

  onDeSelect1(item: any) {
    
    item.status = "";
    item.statusId = "";
    this.statusId = "";
    
    const index = this.itemstatus.indexOf(item.id);
    
    this.itemstatus.splice(index, 1);
    
  }  
 
  onSelectAll1(items: any) {
    
    this.itemstatus = [];
    for (let i = 0; i < items.length; i++){
      this.itemstatus.push(items[i].id);
    }
    
  }
  
  onDeSelectAll1(items: any) {
    this.itemstatus = [];
    
  }
  
  onSelect2(item){
    this.vendorName = item.nameOne;
    this.vendorList.push(item.vendorCode);
  }

  onSelectAll2(items: any) {
    this.vendorList = [];
    for (let i = 0; i < items.length; i++){
      this.vendorList.push(items[i].vendorCode);
    }
    
  }

  onDeSelect2(item: any) {
    
    const index = this.vendorList.indexOf(item.vendorCode);
    
    this.vendorList.splice(index, 1);
    
  }

  onDeSelectAll2(items: any) {
    this.vendorList = [];
    
  }

  search(){
    this.table = true;
  }
 
  onInvoiceForChange(event){
    
    this.InvoiceFor = event;
  }
  ontypeOfInvoiceChange(event){
    
    this.typeOfInvoice = event
  }
  ontypeOfcalculateTaxChange(event){
    
    this.calculatetax = event
  }
  onSubmit(){
 

  //   if(this.currentUser.roleID != 2){
  //     if (this.vendorReportForm.value.multiSelectVendor == "" || this.vendorReportForm.value.multiSelectVendor == undefined) {
  //       Swal.fire(`${this.language.swalvenCode}`,"","warning");
  //       return;
  //   }
  // }
    
    
    let poFrom:any;
    let poTo:any;
    let deliveryNoteDateFrom:any;
    let deliveryNoteDateTo:any;
    let postingDateFrom:any;
    let postingDateTo:any;
    let deliveryNoteNo:any;
    let invoiceDateFrom:any;
    let invoiceDateTo:any;
    let invoiceNo:any;
    let InvoiceFor:any;
    let typeOfInvoice:any;
    let calculatetax:any;
    let vpNoFrom:any;
    let vpNoTo:any;
    let vpdnFrom:any;
    let vpdnTo:any;
    let netDueDateFrom:any;
    let netDueDateTo:any;
    if(this.vendorReportForm.value.purchaseOrderNoFrom){
      poFrom = this.vendorReportForm.value.purchaseOrderNoFrom;
    }
    if(this.vendorReportForm.value.purchaseOrderNoTo){
      poTo = this.vendorReportForm.value.purchaseOrderNoTo;
    }
    if(this.deliveryNoteDateFrom){
      deliveryNoteDateFrom = this.deliveryNoteDateFrom;
    }
    if(this.deliveryNoteDateTo){
      deliveryNoteDateTo = this.deliveryNoteDateTo;
    }
    if(this.invoiceDateFrom){
      invoiceDateFrom = this.postingDateFrom;
    }
    if(this.invoiceDateTo){
      invoiceDateTo = this.postingDateTo;
    }
    if(this.postingDateFrom){
      postingDateFrom = this.postingDateFrom;
    }
    if(this.postingDateTo){
      postingDateTo = this.postingDateTo;
    }
    
    if(this.netDueDateFrom){
      netDueDateFrom = this.netDueDateFrom;
    }
    if(this.netDueDateTo){
      netDueDateTo = this.netDueDateTo;
    }
    if(this.vendorReportForm.value.deliveryNoteNo){
      deliveryNoteNo = this.vendorReportForm.value.deliveryNoteNo;
    }
    if(this.vendorReportForm.value.invoiceNoFrom){
      invoiceNo = this.vendorReportForm.value.invoiceNoFrom;
    }
    if(this.vendorReportForm.value.vendorPortalRefNoFrom){
      vpNoFrom = this.vendorReportForm.value.vendorPortalRefNoFrom;
    }
    if(this.vendorReportForm.value.vendorPortalRefNoTo){
      vpNoTo = this.vendorReportForm.value.vendorPortalRefNoTo;
    }
    if(this.vendorReportForm.value.vpdnFrom){
      vpdnFrom = this.vendorReportForm.value.vpdnFrom;
    }
    if(this.vendorReportForm.value.vpdnTo){
      vpdnTo = this.vendorReportForm.value.vpdnTo;
    }
    if(this.InvoiceFor){
      InvoiceFor = this.InvoiceFor;
    }
    if(this.typeOfInvoice){
      typeOfInvoice = this.typeOfInvoice;
    }
    if(this.calculatetax){
      calculatetax = this.calculatetax;
    }
    this.requestData = {
        "companyCodes": this.companyLists,
        "status": this.itemstatus,
        "vendorCode":this.vendorList,
        "deliveryNoteDateFrom":deliveryNoteDateFrom,
        "deliveryNoteDateTo":deliveryNoteDateTo,
        "postingDateFrom":postingDateFrom,
        "postingDateTo":postingDateTo,
        "netDueDateFrom":netDueDateFrom,
        "netDueDateTo":netDueDateTo,
        "deliveryNoteNo": deliveryNoteNo,
        "invoiceDateFrom": invoiceDateFrom,
        "invoiceDateTo": invoiceDateTo,
        "invoiceNo": invoiceNo,
        // "invoiceNoTo": this.vendorReportForm.value.invoiceNoTo,
        "purchaseOrderNoFrom": poFrom,
        "purchaseOrderNoTo": poTo,
        "vpNoFrom": vpNoFrom,
        "vpNoTo": vpNoTo,
        "vpDnNoFrom":vpdnFrom,
        "vpDnNoTo":vpdnTo,
        "invoiceFor": InvoiceFor,
        "calculateTax":calculatetax,
        "typeOfInvoice": typeOfInvoice,
     // "deliveryNoteDateFrom":this.datepipe.transform(this.dnRange.value.deliveryNoteDateFrom, 'dd-MM-yyyy'),
     // "deliveryNoteDateTo":this.datepipe.transform(this.dnRange.value.deliveryNoteDateTo, 'dd-MM-yyyy'),
     // "deliveryNoteNoFrom": this.vendorReportForm.value.deliveryNoteNoFrom,
     // "deliveryNoteNoTo": this.vendorReportForm.value.deliveryNoteNoTo,
     // "invoiceDateFrom": this.datepipe.transform(this.inRange.value.invoiceDateFrom, 'dd-MM-yyyy'),
     // "invoiceDateTo": this.datepipe.transform(this.inRange.value.invoiceDateTo, 'dd-MM-yyyy'),
    };
  
    
    this.setTableData(null);
  }

pageIndex = 0;
pageSize = 10;
listLength: any;
  async setTableData(event: PageEvent){

    if (event == null) {
      this.pageIndex = 0;
      this.pageSize = 10;
    } else {
      // if(event.pageIndex == 0){
      //   this.pageIndex = 0;
      //   this.pageSize = event.pageSize;
      // }else{
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      // }
    }
  let tableData: any [];
  let status : any;
  let comName: any;
      this.mySpinner = true;
      (await this.cService.getVendorReport(this.requestData,this.pageIndex, this.pageSize)).pipe(first()).subscribe(res => {
        if(res.response != null){
          if (res.responseStatus.toUpperCase() == "SUCCESS") {
            
            
            this.table = true;
            this.dataSource = new MatTableDataSource(res.response);
            this.listLength = res.pagination.count;
            
            this.mySpinner = false;
          } else {
            this.mySpinner = false;
            this.listLength = 0;
            Swal.fire(res.responseMessage,"","error");
          }
        }else{
          this.mySpinner = false;
          this.dataSource = new MatTableDataSource([]);
          this.listLength = 0;
          Swal.fire(res.responseMessage,"","error");
        }
      })
}

  // setTableData(){
  //   let tableData: any [];
  //   let status : any;
    
  //   if(this.statusId != null || this.statusId != undefined){
  //    status = this.status;
  //    this.table = true;
  //   }else{
  //     Swal.fire(`${this.language.swalStatus}`,"","error");
  //   }
  //   tableData=[
    
  //     {
  //       "status":status, 
  //       "company":this.companyName,
  //       "deliveryNoteDate":"02-06-2022",
  //       "deliveryNoteNumber":"1234567",
  //       "poNumber":"987654321234",
  //       "invoiceNo":"13579864",
  //       "invoiceDate":"05-06-2022",
  //       "totalAmount":"90097487",
  //       "currency":"THB",
  //       "paymentTerms":"Z0001",
  //       "netDueDate":"08-06-2022",
  //       "paymentDate":"23-05-2022",
  //       "paymentDoc":"",
  //       "paymentMethod":"CNV",
  //       "invoiceDocNumber":"784372",
  //       "invoicePostingDate":"20-06-2022",
  //       "whtType1":"P1",
  //       "whtCode1":"N1",
  //       "whtBaseAmount1":"2000",
  //       "whtAmount1":"200",
  //       "whtType2":"P2",
  //       "whtCode2":"V7",
  //       "whtBaseAmount2":"9999",
  //       "whtAmount2":"00",
  //       "vpNumberDN":"36473",
  //       "vpNumberInvoice":"873654",
  //       },
  //       {
  //         "status":status, 
  //         "company":this.companyName,
  //         "deliveryNoteDate":"04-06-2022",
  //         "deliveryNoteNumber":"81234567",
  //         "poNumber":"65444321234",
  //         "invoiceNo":"575439864",
  //         "invoiceDate":"10-06-2022",
  //         "totalAmount":"10097487",
  //         "currency":"THB",
  //         "paymentTerms":"Z0002",
  //         "netDueDate":"011-06-2022",
  //         "paymentDate":"25-05-2022",
  //         "paymentDoc":"",
  //         "paymentMethod":"CNV",
  //         "invoiceDocNumber":"12784372",
  //         "invoicePostingDate":"01-06-2022",
  //         "whtType1":"P1",
  //         "whtCode1":"N0",
  //         "whtBaseAmount1":"12000",
  //         "whtAmount1":"3200",
  //         "whtType2":"P1",
  //         "whtCode2":"V1",
  //         "whtBaseAmount2":"19999",
  //         "whtAmount2":"200",
  //         "vpNumberDN":"26473",
  //         "vpNumberInvoice":"173654",
  //       }
  //       ]
  //       this.dataSource = new MatTableDataSource(tableData);
  // }
  
  maxDate = new Date();

  onChangeOfDnNo(event: any){
    let etv: any;
    etv = event.target.value;
    let dLNo :any;
    dLNo = this.vendorReportForm.value.deliveryNoteNoFrom;
    if(dLNo == "" || dLNo == undefined){
      this.vendorReportForm.controls.deliveryNoteNoTo.setValue("");
      Swal.fire(`${this.language.swalDNFrom}`,"","warning")    
      return false;
    }
  }
  
  onChangeOfInvoiceNumber(event: any){
    let etv: any;
    etv = event.target.value;
    let invoiceNo :any;
    invoiceNo = this.vendorReportForm.value.invoiceNoFrom;
    if(invoiceNo == "" || invoiceNo == undefined){
      this.vendorReportForm.controls.invoiceNoTo.setValue("");
      Swal.fire(`${this.language.swalInFrom}`,"","warning")    
      return false;
    }
  }

vpNoFrom: any;
vpdnFrom:any;
vpdnTo:any;
vpNoTo: any;
poNoFrom : any;
poNoTo :any;
// DNFrom : any;
// DNTo : any;
DNNo: any;
invoiceFrom : any;
invoiceTo : any;
onClear(){
  
  this.requestData = {};
  this.companyLists=[];
  this.vendorList = [];
  this.itemstatus = [];
  this.vendorReportForm.get('multiSelectCompany').setValue([]);
  this.vendorReportForm.get('InvoiceFor').setValue([]);
  this.vendorReportForm.get('typeOfInvoice').setValue([]);
  this.vendorReportForm.get('calculatetax').setValue([]);
  this.vendorReportForm.get('multiSelectVendor').setValue([]);
  this.deliveryNoteDateFrom = '';
  this.deliveryNoteDateTo = '';
  this.InvoiceFor = '';
  this.typeOfInvoice = '';
  this.calculatetax = '';
  this.dnRange.controls.deliveryNoteDateFrom.setValue("");
  this.dnRange.controls.deliveryNoteDateTo.setValue("");
  this.isDeliveryNoteEndDate = true;
  this.isStartDate = true;
  this.isNetDuedate = true;
  this.netDueDateRange.controls.netDueDateFrom.setValue("");
  this.netDueDateRange.controls.netDueDateTo.setValue("");
  this.netDueDateFrom = '';
  this.netDueDateTo = '';
  // this.dnRange.value.deliveryNoteDateFrom = "";
  // this.DNSDate.nativeElement.value = "";
  // this.dnRange.value.deliveryNoteDateTo = "";
  // this.DNEDate.nativeElement.value = "";
  this.postingRange.controls.postingDateFrom.setValue("");
  this.postingRange.controls.postingDateTo.setValue("");
  this.postingDateFrom = '';
  this.postingDateTo = '';
  this.invoiceDateFrom = '';
  this.invoiceDateTo = '';
  this.inRange.controls.invoiceDateFrom.setValue("");
  this.inRange.controls.invoiceDateTo.setValue("");
  this.isInvoiceEndDate = true;
  // this.inRange.value.invoiceDateFrom = "";
  // this.invSDate.nativeElement.value = "";
  // this.inRange.value.invoiceDateTo = "";
  // this.invEDate.nativeElement.value = "";
  this.vpNoFrom = "";
  this.vpdnTo = "";
  this.vpdnFrom = "";
  this.vpNoTo = "";
  // this.poNo = "";
  this.poNoFrom = "";
  this.poNoTo = "";
  // this.DNFrom = "";
  // this.DNTo = "";
  this.DNNo = "";
  this.invoiceFrom = "";
  this.invoiceTo = ""; 
  this.statusId = "";
  this.vendorReportForm.get('multiSelectStatus').setValue([]);   
  this.table = false;    
}

isInvoiceEndDate:boolean = true;
isStartDate:boolean = true;
isNetDuedate:boolean = true;
invoiceDateFrom: any;
invoiceDateTo:any;
postingDateFrom:any;
netDueDateFrom:any;
netDueDateTo:any;
postingDateTo:any;
getInvoiceStartDate(event){
  this.invoiceDateFrom = this.datepipe.transform(event.target.value,'yyyy-MM-dd');
   if(new Date(this.invoiceDateTo) < new Date(this.invoiceDateFrom)){
       Swal.fire(`${this.language.swalInvoiceDate}`,'','warning');      
     event.target.value = new Date(this.invoiceDateTo);
     this.invoiceDateFrom = this.datepipe.transform(this.invoiceDateTo,'yyyy-MM-dd');
   }else{
     if(this.invoiceDateTo != "" || this.invoiceDateTo != undefined){;
    }
     
   }
  this.isInvoiceEndDate = false;     
 }

 getpostingDateFrom(event){
  this.postingDateFrom = this.datepipe.transform(event.target.value,'yyyy-MM-dd');
   if(new Date(this.postingDateTo) < new Date(this.postingDateFrom)){
       Swal.fire(`${this.language.swalPostingDate}`,'','warning');      
     event.target.value = new Date(this.postingDateTo);
     this.postingDateFrom = this.datepipe.transform(this.postingDateTo,'yyyy-MM-dd');
   }else{
     if(this.postingDateTo != "" || this.postingDateTo != undefined){;
    }
     
   }
  this.isStartDate = false;     
 }
 getnetDateFrom(event){
  this.netDueDateFrom = this.datepipe.transform(event.target.value,'yyyy-MM-dd');
   if(new Date(this.netDueDateTo) < new Date(this.netDueDateFrom)){
       Swal.fire(`${this.language.swalnetDueDate}`,'','warning');      
     event.target.value = new Date(this.netDueDateTo);
     this.netDueDateFrom = this.datepipe.transform(this.netDueDateTo,'yyyy-MM-dd');
   }else{
     if(this.netDueDateTo != "" || this.netDueDateTo != undefined){;
    }
     
   }
  this.isNetDuedate = false;     
 }

 getInvoiceEndDate(event) {   
    this.invoiceDateTo = this.datepipe.transform(event.target.value,'yyyy-MM-dd');
    if(new Date(this.invoiceDateTo) < new Date(this.invoiceDateFrom)){
      Swal.fire(`${this.language.swalInvoiceDate}`,'','warning');
      event.target.value = new Date(this.invoiceDateFrom);
      this.invoiceDateTo = this.datepipe.transform(this.invoiceDateFrom,'yyyy-MM-dd');
    }
    if(this.invoiceDateFrom == null || this.invoiceDateFrom == undefined ){
        return;
    }
    if(this.invoiceDateTo == null || this.invoiceDateTo == undefined ){
      return;
  }
  }

  getpostingDateTo(event) {   
    this.postingDateTo = this.datepipe.transform(event.target.value,'yyyy-MM-dd');
    if(new Date(this.postingDateTo) < new Date(this.postingDateFrom)){
      Swal.fire(`${this.language.swalPostingDate}`,'','warning');
      event.target.value = new Date(this.postingDateFrom);
      this.postingDateTo = this.datepipe.transform(this.postingDateFrom,'yyyy-MM-dd');
    }
    if(this.postingDateFrom == null || this.postingDateFrom == undefined ){
        return;
    }
    if(this.postingDateTo == null || this.postingDateTo == undefined ){
      return;
  }
  }
  getnetDueDateTo(event) {   
    this.netDueDateTo = this.datepipe.transform(event.target.value,'yyyy-MM-dd');
    if(new Date(this.netDueDateTo) < new Date(this.netDueDateFrom)){
      Swal.fire(`${this.language.swalnetDueDate}`,'','warning');
      event.target.value = new Date(this.netDueDateFrom);
      this.netDueDateTo = this.datepipe.transform(this.netDueDateFrom,'yyyy-MM-dd');
    }
    if(this.netDueDateFrom == null || this.netDueDateFrom == undefined ){
        return;
    }
    if(this.netDueDateTo == null || this.netDueDateTo == undefined ){
      return;
  }
  }

isDeliveryNoteEndDate:boolean = true;
deliveryNoteDateFrom: any;
deliveryNoteDateTo:any;
getDNStartDate(event){
  this.deliveryNoteDateFrom = this.datepipe.transform(event.target.value,'yyyy-MM-dd');
   if(new Date(this.deliveryNoteDateTo) < new Date(this.deliveryNoteDateFrom)){
       Swal.fire(`${this.language.swaldeliveryNoteDate}`,'','warning');      
     event.target.value = new Date(this.deliveryNoteDateTo);
     this.deliveryNoteDateFrom = this.datepipe.transform(this.deliveryNoteDateTo,'yyyy-MM-dd');
   }else{
     if(this.deliveryNoteDateTo != "" || this.deliveryNoteDateTo != undefined){
       //this.deliveryWbDetails = [];
    //    let selectedItem = []
    //    
    //    for (let i = 0; i <= this.selectedValuesWb.length - 1; i++) {
    //      selectedItem.push(this.selectedValuesWb[i].poNumber);
    //    }
    //    this.getWBDetails(selectedItem);
    }
     
   }
  //  
  this.isDeliveryNoteEndDate = false;     
 }

 getDNEndDate(event) {   
    this.deliveryNoteDateTo = this.datepipe.transform(event.target.value,'yyyy-MM-dd');
    if(new Date(this.deliveryNoteDateTo) < new Date(this.deliveryNoteDateFrom)){
      Swal.fire(`${this.language.swaldeliveryNoteDate}`,'','warning');
      event.target.value = new Date(this.deliveryNoteDateFrom);
      this.deliveryNoteDateTo = this.datepipe.transform(this.deliveryNoteDateFrom,'yyyy-MM-dd');
    }
    // let selectedItem = []
    // 
    // for (let i = 0; i <= this.selectedValuesWb.length - 1; i++) {
    //   selectedItem.push(this.selectedValuesWb[i].poNumber);
    // }

    // let POIDs= (this.selectedValues).toString();
    //
    // let request = {
    //   "poNumber": selectedItem,
    //  // "plantCode": this.plantId
    // }
    // this.dataSourceWb = [];
    // this.deliveryNoteSvData = [];
    if(this.deliveryNoteDateFrom == null || this.deliveryNoteDateFrom == undefined ){
        return;
    }
    if(this.deliveryNoteDateTo == null || this.deliveryNoteDateTo == undefined ){
      return;
  }
  }

  numberOnly(event) {
    var regex = new RegExp("^[0-9]");
        var key = String.fromCharCode(event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
  }
}

export interface PeriodicElement {
  status:any,
  company:any,
  vendorCode:any,
  name:any,
  sapResponse:any
  poNumber:any,
  poItem:any;
  plantCode:any;
  plantName:any;
  material:any,
  serviceNumber:any,
  materialGroup:any,
  shortText:any,
  poQuantity:any,
  unit:any,
  unitPrice:any;
  baseLineDate:any;
  deliveryNoteDate:any,
  deliveryNoteNo:any,
  invoiceNumber:any,
  sesNumber:any,
  invoiceDate:any,
  totalAmount:any,
  currency:any,
  paymentTerms:any,
  netDueDate:any,  
  // paymentDate:any,
  // paymentDoc:any,
  invoiceDocNumber:any,
  paymentMethods:any,
  postingDate:any,
  whtType1:any,
  whtCode1:any,
  whtBaseAmount1:any,
  whtAmount1:any,
  whtType2:any,
  whtCode2:any,
  whtBaseAmount2:any,
  whtAmount2:any,
  vpDnNumber:any,
  vpNumber:any,
  typeOfInvoice:any,
  invoiceFor:any;
  totalQuantity:any;
  amountVat:any;
  fiDocNumber:any;
  calculateTax:any;
}