<mat-card>   
        <mat-card-title>
            <div fxLayout="row" fxFlex.gt-xs="100" fxFlex="100">
               {{language.Head}}
            </div>
        </mat-card-title>
        <hr>
<mat-card-content>
        <div fxLayout="row" fxLayoutWrap="wrap">
            <!-- Card column -->
            <div fxFlex.gt-sm="100%" fxFlex="100">
                <!-- <mat-card> -->
        
                    <div fxLayout="row"fxLayout.xs="column"  >
                        <div class="search" fxFlex="30" fxFlex.sm="50" fxFlex.xs="100">
                            <mat-form-field class="mx-3 max-width">
                                <mat-label>{{language.search}}</mat-label>
                                <input matInput placeholder="Search" (keyup)="applyFilter1()" #input [(ngModel)]="searchString">
                            </mat-form-field>
                        </div>
                        <div fxFlex="20" fxFlex.sm="50" fxFlex.xs="100" style="margin-top:15px">
                            <button (click)="applyFilter()"  mat-raised-button class="submitButton btn-primary">{{language.search}}</button>
                        </div>

                        <div *ngIf="currentUser.roleID != '3'" 
                             fxFlex.gt-lg="30" 
                             fxFlex.gt-md="30"
                             fxFlex.gt-xs="100"
                             fxFlex="100">
                        </div>
                       
                         <div class="addUserParent" fxFlex="70" fxFlex.sm="50" fxFlex.xs="100">
                            <button mat-raised-button class="submitButton addUser" (click)="addDelivery()">
                               {{language.button}}
                            </button>
                        </div>
                    </div>
                    
                    <div class="overflow">
                        <mat-card>
                            <mat-table  [dataSource]="dataSource" matSort multiTemplateDataRows
                            [ngClass] = "currentUser.roleID == '2'?'deliveryNotePendingTable':'Dpending'" style="width: fit-content;">
                                <!-- <span > -->
                                    <ng-container matColumnDef="deliveryStatus">
                                        <mat-header-cell *matHeaderCellDef class="statusWide-w" mat-sort-header> {{language.deliveryStatus}} </mat-header-cell>
                                        <mat-cell *matCellDef="let element" data-label="Status :" class="statusWide-w" [ngClass]="calculateClass(element.deliveryStatus)">
                                            {{element.deliveryStatus}}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="actions">
                                        <mat-header-cell *matHeaderCellDef class="actions-w actions-align"> 
                                            {{language.actions}}
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" data-label="Actions :" class="actions-w">
                                            <!-- <div class="button-group my-2 float-right" style="display: flex;"> -->
                                                <!-- <button (click)="onInfo(element)" mat-flat-button color="primary" class="button-actions"> -->
                                                <mat-icon (click)="onInfo(element,'pending')" aria-hidden="false" class="visibility"
                                                    aria-label="visibility">visibility</mat-icon>
                                                    
                                                <mat-icon *ngIf="element.statusId == '1' || element.statusId == '5' || element.statusId == '6'  || element.statusId == '8' || element.statusId == '9' || (currentUser.roleID == '3' && (element.statusId == '4' || element.statusId == '10')) ||(currentUser.roleID == '7' && (element.statusId == '4' || element.statusId == '10')) || ((currentUser.roleID == '3' || currentUser.roleID == '7') && element.statusId == '7')"  (click)="editDeliveryNote(element)" aria-hidden="false" class="edit"
                                                    aria-label="edit">edit
                                                </mat-icon>
                                              
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="vendorCode"  >
                                        <mat-header-cell  class="vendorCode-w"*matHeaderCellDef mat-sort-header>
                                            <span  *ngIf="currentUser.roleID == '3' ||currentUser.roleID == '7'">{{language.vendorCode}}</span>
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" data-label="Vendor Code :" class="vendorCode-w"  >
                                            <span *ngIf="currentUser.roleID == '3' || currentUser.roleID == '7'" >
                                                <a class="poInfoCls" (click)="onVendorInfo(element.vendorId)">{{ element.sapVendorCode != null ? element.sapVendorCode : '-'}}</a>
                                            </span>
                                        </mat-cell>
                                    </ng-container>
                                <!-- </span> -->
                                <ng-container matColumnDef="vendorName">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header class="vendorName-w" style="min-width: 140px; max-width:300px;word-break:break-word"> {{language.vendorName}}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="vendorName-w" data-label="Vendor Name :" style="min-width: 140px; max-width:300px;word-break:break-word">
                                        {{element.name1 != null ? element.name1 : '-'}} {{element.name2 != null ? element.name2: '' }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="poNumber">
                                    <mat-header-cell *matHeaderCellDef class="poNumber-w" style="min-width: 100px; max-width:150px;word-break:break-word" mat-sort-header>
                                       {{language.PONumber}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="PO Number :" style="min-width: 100px; max-width:150px;word-break:break-word" class="poNumber-w">
                                        <a (click)="onPoInfo(element)" class="poInfoCls"
                                            style="text-decoration: underline;color:#0088CC">{{element.poNumber}}</a>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="outStandingUnit">
                                    <mat-header-cell *matHeaderCellDef class="actions-ws" mat-sort-header>
                                       {{language.outStandingUnit}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label=" Out Standing Unit :" class="actions-ws">
                                        {{element.outStandingUnit}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="sapResponse">
                                    <mat-header-cell *matHeaderCellDef class="sapResponse-w" mat-sort-header>
                                        <span >{{language.SAPResponse}}</span>
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="SAP Response :" class="sapResponse-w">
                                       <span class="textWrap" matTooltipClass="custom-tooltip" matTooltipPosition="before"  matTooltip="{{element.sapResponse}}"> {{element.sapResponse != null ? element.sapResponse : '-'}}</span>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="plantCode">
                                    <mat-header-cell *matHeaderCellDef class="po-wc" mat-sort-header>
                                       {{language.plantCode}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label=" Plant Code :" class="po-wc">
                                        {{element.plantCode != null ? element.plantCode : '-'}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="prRequisitioner">
                                    <mat-header-cell *matHeaderCellDef  mat-sort-header style="min-width: 140px; max-width:300px;word-break:break-word">
                                       {{language.Requisitioner}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label=" Plant Code :"  style="min-width: 140px; max-width:300px;word-break:break-word">
                                        {{element.prRequisitioner != null ? element.prRequisitioner : '-'}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="poShorttext">
                                    <mat-header-cell *matHeaderCellDef class="plantCode-w" mat-sort-header>
                                       {{language.poShorttext}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label=" PO Short Text :" class="plantCode-w">
                                        {{element.poShortText != null ? element.poShortText : '-'}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="deliveryNoteRefNumVp">
                                    <mat-header-cell *matHeaderCellDef class="invoiceDate-w" mat-sort-header>
                                       {{language.vpdn}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label=" VPDN#:" class="invoiceDate-w">
                                        {{element.deliveryNoteRefNumVp.substring(element.deliveryNoteRefNumVp.indexOf("_")+1)}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="deliveryNoteNumberByVendor">
                                    <mat-header-cell *matHeaderCellDef class="deliveryNoteDate-w" mat-sort-header>
                                       {{language.deliveryNoteNumber}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label=" Delivery Note Number :" class="deliveryNoteDate-w">
                                        {{element.deliveryNoteNumberByVendor ? element.deliveryNoteNumberByVendor : '-'}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="deliveryNoteDate">
                                    <mat-header-cell *matHeaderCellDef class="invoiceDate-w" mat-sort-header>
                                        {{language.deliveryNoteDate}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Delivery Note Date :" class="invoiceDate-w">
                                        {{element.deliveryNoteDate | date:"dd/MM/yyyy"}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="warehouseReceivedQuantity">
                                    <mat-header-cell *matHeaderCellDef class="quantity-w" mat-sort-header> {{language.quantity}}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Quantity :" class="quantity-w">
                                        {{element.warehouseReceivedQuantity | number : '1.3-3' }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="amount">
                                    <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header>{{language.totalAmount}}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Total Amount :" class="totalAmt-w">
                                        {{element.amount | number : '1.2-2'}}
                                    </mat-cell>
                                </ng-container>
                                <!-- <ng-container matColumnDef="vendorName">
                                    <mat-header-cell *matHeaderCellDef class="vendorName-w" mat-sort-header>{{language.vendorName}}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Vendor Name :" class="vendorName-w">
                                        {{element.vendorName}}
                                    </mat-cell>
                                </ng-container> -->
                                <ng-container matColumnDef="currency">
                                    <mat-header-cell *matHeaderCellDef class="currency-w" mat-sort-header> {{language.currency}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Currency :" class="currency-w" >
                                        {{element.currency != null ? element.currency : '-'}}
                                    </mat-cell>
                                </ng-container>
                               
                                <ng-container matColumnDef="CreatedBy">
                                    <mat-header-cell *matHeaderCellDef class="createdBy-w" mat-sort-header> {{language.CreatedBy}} </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Created By :" class="createdBy-w" >
                                        {{element.dnAddedByType != null ? element.dnAddedByType : '-'}}
                                    </mat-cell>
                                </ng-container>
                               
                                <mat-header-row *matHeaderRowDef="columnsToDisplay">
                                </mat-header-row>
                                <mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
                                    [class.example-expanded-row]="expandedElement === element"
                                    (click)="expandedElement = expandedElement === element ? null : element">
                                </mat-row>
                            </mat-table>
                        </mat-card>
                    </div>
            
                    <mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" [pageSize]="pageSize"
                        [length]="listLength"
                        [pageSizeOptions]="[10, 25, 50, 100]" (page)="getDeliveryNoteList($event, '')"
                        style="width:100%"></mat-paginator>
            
            </div>
        </div>
    </mat-card-content>
</mat-card>
<div class="overlay"  *ngIf="mySpinner">
    <div class="center">
        <mat-spinner></mat-spinner> 
    </div>
    </div>