<div class="margin-bottom" fxLayout="row">
    <div class="pull-left" fxFlex="50">
        <strong>{{action}} {{language.user}}</strong>
    </div>
    <div class="pull-right" fxFlex="50">
        <mat-icon class="close2 pull-right" (click)="closeDialog()">close</mat-icon>
    </div>


</div>

<div mat-dialog-content>
    <!-- <div class="row" *ngIf="action != 'delete'; else elseTemplate"> -->
    <form [formGroup]="changePwd">
        <div fxLayout="row" fxLayout.xs="column">
            <div class="padding-right" fxFlex="50" fxFlex.xs="100">
                <mat-form-field class="width-80 validations" appearance="outline">
                    <mat-label>{{language.FirstName}}</mat-label>
                    <input matInput placeholder="{{language.FirstName}}" required formControlName="firstName"
                        (keydown)="Space($event)" (keypress)="keypressalpha($event)" [(ngModel)]="local_data.firstName">

                </mat-form-field>
            </div>

            <div class="padding-left" fxFlex="50" fxFlex.xs="100">
                <mat-form-field class="width-80 validations" appearance="outline">
                    <mat-label>{{language.LastName}}</mat-label>
                    <input matInput placeholder="{{language.LastName}}" required formControlName="lastName"
                        (keydown)="Space($event)" (keypress)="keypressalpha($event)" [(ngModel)]="local_data.lastName">

                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
            <div class="padding-right" fxFlex="50" fxFlex.xs="100">
                <mat-form-field class="width-80 validations" appearance="outline">
                    <mat-label>{{language.Email}}</mat-label>
                    <input matInput placeholder="{{language.Email}}" required formControlName="email"
                        (keydown)="Space($event)" [(ngModel)]="local_data.email">
                </mat-form-field>
            </div>
            <div class="padding-left" fxFlex="50" fxFlex.xs="100">
                <mat-form-field class="width-80 validations" appearance="outline">
                    <mat-label>{{language.contactNo}}</mat-label>
                    <input matInput required placeholder="{{language.contactNo}}" formControlName="contactNo"
                        [(ngModel)]="local_data.contactNo" (keypress)="numberOnly($event)">
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" *ngIf="action == 'Update'">
            <div class="padding-right" fxFlex="50" fxFlex.xs="100">
                <mat-form-field class="dropdown-w validations" appearance="outline">
                    <mat-label>{{language.UserType}}</mat-label>
                    <mat-select (selectionChange)="onChange($event)" appearance="outline" formControlName="roleID"
                        [(ngModel)]="local_data.roleID" required>

                        <mat-option *ngFor="let item of Rolls" [value]="item.id">
                            {{ item.roleName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="50" fxFlex.xs="100" class="Allowed2"
                *ngIf="local_data.roleID == '3' || local_data.roleID == '6' || local_data.roleID == '7'">
                <mat-label *ngIf="show" class="poclass" style="font-weight: 500;">{{language.plantAllowed}}
                    *</mat-label>
                <ng-multiselect-dropdown placeholder="{{language.plantAllowed}} *" class="dropDownClass"
                    formControlName="companyAllowed" [settings]="dropdownSettings" [data]="dropdownList"
                    [(ngModel)]="plantMaster" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                    (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
                </ng-multiselect-dropdown>
            </div>
            <div fxFlex="50" fxFlex.xs="100" class="Allowed2"
                *ngIf="local_data.roleID == '4' || local_data.roleID == '5' || local_data.roleID == '8'">
                <mat-label *ngIf="show" class="poclass" style="font-weight: 500;">{{language.companyAllowed}}
                    *</mat-label>
                <ng-multiselect-dropdown placeholder="{{language.companyAllowed}} *" class="dropDownClass"
                    formControlName="companyAllowed" [settings]="companyDropdownSettings" [data]="dropdownList"
                    [(ngModel)]="plantMaster" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                    (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
                </ng-multiselect-dropdown>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" *ngIf="action == 'Add'">
            <div class="padding-right" fxFlex="50" fxFlex.xs="100">
                <mat-form-field class="width-80 validations" appearance="outline">
                    <mat-label>{{language.UserName}}</mat-label>
                    <input matInput required placeholder="{{language.UserName}}" formControlName="UserName"
                        [(ngModel)]="local_data.UserName" (keypress)="keypressalphaNumeric($event)">
                </mat-form-field>
            </div>
            <div class="padding-left" fxFlex="50" fxFlex.xs="100">
                <mat-form-field class="dropdown-w validations" appearance="outline">
                    <mat-label>{{language.UserType}}</mat-label>
                    <mat-select (selectionChange)="onChange($event)" appearance="outline" formControlName="roleID"
                        [(ngModel)]="local_data.roleID" required>

                        <mat-option *ngFor="let item of Rolls" [value]="item.id">
                            {{ item.roleName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" *ngIf="action == 'Add'">
            <div fxFlex="50" fxFlex.xs="100" class="Allowed"
                *ngIf="local_data.roleID == '3' || local_data.roleID == '6' || local_data.roleID == '7'">
                <mat-label *ngIf="show" class="poclass" style="font-weight: 500;">{{language.plantAllowed}}
                    *</mat-label>
                <ng-multiselect-dropdown placeholder="{{language.plantAllowed}} *" class="dropDownClass"
                    formControlName="companyAllowed" [settings]="dropdownSettings" [data]="dropdownList"
                    [(ngModel)]="plantMaster" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                    (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
                </ng-multiselect-dropdown>
            </div>
            <div fxFlex="50" fxFlex.xs="100" class="Allowed"
                *ngIf="local_data.roleID == '4' || local_data.roleID == '5' || local_data.roleID == '8'">
                <mat-label *ngIf="show" class="poclass" style="font-weight: 500;">{{language.companyAllowed}}
                    *</mat-label>
                <ng-multiselect-dropdown placeholder="{{language.companyAllowed}} *" class="dropDownClass"
                    formControlName="companyAllowed" [settings]="companyDropdownSettings" [data]="dropdownList"
                    [(ngModel)]="plantMaster" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                    (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onItemDeSelectAll($event)">
                </ng-multiselect-dropdown>
            </div>
        </div>
        <div class="modal-buttons" fxLayout.xs="row">
            <button mat-button mat-flat-button class="submitButton" (click)="onNoClick()"
                style="margin-right:10px">{{action}}</button>
            <button mat-button (click)="closeDialog()" class="rejectButton" mat-flat-button
                color="warn">{{language.cancel}}</button>
        </div>
    </form>
</div>