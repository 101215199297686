import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {DatePipe, formatDate } from '@angular/common';
import { first } from 'rxjs/operators';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { N } from '@angular/cdk/keycodes';
import { MatInput } from '@angular/material/input';
import { MatDateRangePicker } from '@angular/material/datepicker/date-range-picker';
import { CommonServicesService } from 'src/app/services/common-services.service';
import Swal from 'sweetalert2';
// import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import { TableUtil } from 'src/app/reports/TableUtil';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { error } from '@angular/compiler/src/util';
import { MatSort } from '@angular/material/sort';
import { thFont } from 'src/app/reports/thai-lang';
// let jsPDF;

@Component({
  selector: 'app-ap-report',
  templateUrl: './ap-report.component.html',
  styleUrls: ['./ap-report.component.css']
})
// const getFileName = (name: string) => {
//   let timeSpan = new Date().toISOString();
//   let sheetName = name || "ExportResult";
//   let fileName = `${sheetName}-${timeSpan}`;
//   return {
//     sheetName,
//     fileName
//   };
// };
export class ApReportComponent implements OnInit {
  @ViewChild('fromDate') fromDate: ElementRef;
  @ViewChild('endDate') endDate: ElementRef;
  @ViewChild('matTable') matTable!: ElementRef;
  // @ViewChild('matTable') matTable: ElementRef;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  requestData:any;
  dnRange = new FormGroup({
    postingDateFrom: new FormControl(''),
    postingDateTo: new FormControl(''),
  });
  paymentRange = new FormGroup({
    paymentDateFrom: new FormControl(''),
    paymentDateTo: new FormControl(''),
  });
  invoiceDateRange = new FormGroup({
    invoiceDateFrom: new FormControl(''),
    invoiceDateTo: new FormControl(''),
  });
  netDueDateRange = new FormGroup({
    netDueDateFrom: new FormControl(''),
    netDueDateTo: new FormControl(''),
  })
  maxDate = new Date();
  table: boolean = false;
  dropdownSettings = {};
  dropdownSettings1 = {};
  columnsToDisplay:any=[];
  dataSource:MatTableDataSource<any>;
  dataSource2:any=[];
  today= new Date();
  fileName= 'ExcelSheet.xlsx';
  mySpinner:boolean=false;
  InvoiceFor:any;
  typeOfInvoice:any;
  //jsttoday = '';
  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private cService:CommonServicesService
    ) {
    // this.jsttoday = formatDate(this.today, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
   }
   potypes = [
    { key: "G", value: "Goods" },
    { key: "W", value: "Goods - Weigh bridge" },
    { key: "S", value: "Service" }

  ];
  invoicetypes = [
    { key: "Y", value: "Invoice" },
    { key: "N", value: "E-Invoice" },
  ]
  utfString:any;
   async openPDF() {
    this.mySpinner = true;
    (await this.cService.getexportPDFdata(this.requestData)).pipe(first()).subscribe(res => {
      if(res.response != null){
        if (res.responseStatus.toUpperCase() == "SUCCESS") {
          
          this.table = true;
          this.mySpinner = false;
        } else {
          this.mySpinner = false;
          Swal.fire(res.responseMessage,"","error");
        }
      }else{
        this.mySpinner = false;
        Swal.fire(res.responseMessage,"","error");
      }
      // const doc = new jsPDF({ filters: ["ASCIIHexEncode"] });
  // const doc = new jsPDF({ filters: ["ASCIIHexEncode"] });
  var font = thFont; 


    const doc = new jsPDF('l', 'pt', "a4",true); 
    // const doc = new jsPDF({ filters: ["ASCIIHexEncode"] });
    doc.addFileToVFS("supermarket.ttf",font);
    doc.addFont("supermarket.ttf", "Amiri",'normal','800');
    doc.setFont("Amiri",'normal');
    // doc.setFontSize(10);
    // doc.text(res.response[0].vendorName, 10, 10);
  //   doc.save("test.pdf");
    // this.utfString = decodeURIComponent(res.response.replace(/\\x/g,"%"));
    
    doc.setFontSize(5);
    var col = ["STATUS","COMPANY","VENDOR CODE","VENDOR NAME","DOC#","INVOICE#","INVOICE DATE","AP POSTING DATE","AMOUNT","TOTAL AMOUNT","CURRENCY","TAX TYPE","PAYMENT TERMS","NET DUE DATE","PAYMENT METHOD","WHT TYPE 1"
    ,"WHT CODE 1","WHT BASE AMT 1","WHT AMT 1","WHT TYPE 2","WHT CODE 2","WHT BASE AMT 2","WHT AMT 2","VP#",
    "PO#","SAP AP USER","VENDOR ADD INVOICE DATE","AP CHECKER USER","AP CHECKER ACCEPT DATE","INVOICE FOR","TYPE OF INVOICE"];
    var rows = [];

    
    res.response.forEach(x => {    
        var temp = [
            x.status,
            x.company,
            x.vendorCode,
            x.vendorName,
            x.documentNumber,
            x.invoiceNumber,
            x.invoiceDate != '-'?this.datePipe.transform(x.invoiceDate,'dd/MM/yyyy'):'-',
            x.postingDate != '-'?this.datePipe.transform(x.postingDate,'dd/MM/yyyy'):'-',
            Number(x.amount).toLocaleString('en', { minimumFractionDigits: 2 }),
            Number(x.totalAmount).toLocaleString('en', { minimumFractionDigits: 2 }),
            x.currency,
            x.taxCode,
            x.paymentTerms,
            x.netDueDate != '-'?this.datePipe.transform(x.netDueDate,'dd/MM/yyyy'):'-',
            x.paymentMethods,
            x.whtType1,
            x.whtCode1,
            x.whtBaseAmount1?Number(x.whtBaseAmount1).toLocaleString('en', { minimumFractionDigits: 2 }):"",
            x.whtTaxAmount1?Number(x.whtTaxAmount1).toLocaleString('en', { minimumFractionDigits: 2 }):"",
            x.whtType2,
            x.whtCode2,
            x.whtBaseAmount2?Number(x.whtBaseAmount2).toLocaleString('en', { minimumFractionDigits: 2 }):"",
            x.whtTaxAmount2?Number(x.whtTaxAmount2).toLocaleString('en', { minimumFractionDigits: 2 }):"",
            x.vpNumber.substring(x.vpNumber.indexOf('_')+1),
            x.poNumber,
            x.approvedBy,
            x.createdDate != '-'?this.datePipe.transform(x.createdDate,'dd/MM/yyyy'):'-',
            x.apCheckerUsername,
            x.apCheckerAcceptedDate != '-'?this.datePipe.transform(x.apCheckerAcceptedDate,'dd/MM/yyyy'):'-',
            x.invoiceFor,
            x.typeOfInvoice
        ];
        rows.push(temp);

    });       


    (doc as any).autoTable(col, rows,{
      startY: false,
      theme: 'grid',
      tableWidth: 'auto',
      cellWidth: 'wrap',
      showHead: 'everyPage',
      tableLineColor: 200,
      tableLineWidth: 0,
      
      columnStyles: {
          0: {
              cellWidth: 34
          },
          1: {
              cellWidth: 34
          },
          2: {
              cellWidth: 34
          },
          3: {
              cellWidth: 34
          },
          4: {
              cellWidth: 29
          },
          5: {
              cellWidth: 29
          },
          6: {
              cellWidth: 29
          },
          7: {
              cellWidth: 24
          },
          8: {
              cellWidth: 24
          },
          9: {
              cellWidth: 24
          },
          10: {
              cellWidth: 24
          },
          11: {
              cellWidth: 34
          },
          12: {
              cellWidth: 0
          },
          13: {
              cellWidth: 0
          },
          14: {
              cellWidth: 24
          },
          15: {
              cellWidth: 24
          },
          16: {
              cellWidth: 24
          },
          17: {
              cellWidth: 24
          },
          18: {
              cellWidth: 24
          },
          19: {
              cellWidth: 24
          },
          20: {
              cellWidth: 24
          },
          21: {
              cellWidth: 24
          },
          22: {
              cellWidth: 24
          },
          23: {
              cellWidth: 24
          },
          24: {
              cellWidth: 24
          },
          25: {
              cellWidth: 24
          },
          26: {
              cellWidth: 24
          },
          27: {
              cellWidth: 24
          },
          28: {
              cellWidth: 24
          },
          29: {
              cellWidth: 24
          },
          30: {
              cellWidth: 24
          },
          31: {
              cellWidth: 24
          },
          32: {
              cellWidth: 24
          }
      },
      headStyles: {
          theme: 'grid',
          fillColor : [237, 28, 36],
          font: 'helvetica',
          fontStyle:"bold",
      },
      margins :{
        top: 10,
        bottom: 10,
        left: 10,
        width: 522
      },
      styles: {
          overflow: 'linebreak',
          cellWidth: 'wrap',
          // font: 'arial',
          fontSize: 3,
          font:"Amiri",
          fontStyle:"bold",
          // fontWeight:200,
          // cellPadding: 2,
          overflowColumns: 'linebreak'
      },
  });
    doc.addPage('a3');
    doc.save('Ap-Report.pdf');
  });
  }


async exportexcel()
{

  if(this.requestData == undefined){
  this.requestData = {
    "companyCodes": [],
    "status": [],
}
}
  this.mySpinner = true;
    (await this.cService.getexportPDFdata(this.requestData)).pipe(first()).subscribe(res => {
      if(res.response != null){
        if (res.responseStatus.toUpperCase() == "SUCCESS") {
          
          this.table = true;
          this.mySpinner = false;
        } else {
          this.mySpinner = false;
          Swal.fire(res.responseMessage,"","error");
        }
      }else{
        this.mySpinner = false;
        Swal.fire(res.responseMessage,"","error");
      }
  const onlyNameAndSymbolArr: Partial<PeriodicElement>[] = res.response.map(
    x => ({
      "STATUS":x.status,
      "COMPANY": x.company,
      "VENDOR CODE": x.vendorCode,
      "VENDOR NAME": x.vendorName,
      "DOC#": x.documentNumber,
      "INVOICE#": x.invoiceNumber,
      "INVOIC DATE": x.invoiceDate != '-'?this.datePipe.transform(x.invoiceDate,'dd/MM/yyyy'):'-',
      "AP POSTING DATE": x.documentNumber?this.datePipe.transform(x.postingDate,'dd/MM/yyyy'):'-',
      "AMOUNT": Number(x.amount).toLocaleString('en', { minimumFractionDigits: 2 }),
      "TOTAL AMOUNT": Number(x.totalAmount).toLocaleString('en', { minimumFractionDigits: 2 }),
      "CURRENCY": x.currency,
      "TAX TYPE": x.taxCode,
      "PAYMENT TERMS": x.paymentTerms,
      "NET DUE DATE": x.netDueDate != '-'?this.datePipe.transform(x.netDueDate,'dd/MM/yyyy'):'-',
      "PAYMENT METHOD": x.paymentMethods,
      "WHT TYPE 1":x.whtType1,
      "WHT CODE 1": x.whtCode1,
      "WHT BASE AMT 1":x.whtBaseAmount1?Number(x.whtBaseAmount1).toLocaleString('en', { minimumFractionDigits: 2 }):"",
      "WHT AMT 1": x.whtTaxAmount1?Number(x.whtTaxAmount1).toLocaleString('en', { minimumFractionDigits: 2 }):"",
      "WHT TYPE 2":x.whtType2,
      "WHT CODE 2": x.whtCode2,
      "WHT BASE AMT 2":x.whtBaseAmount2?Number(x.whtBaseAmount2).toLocaleString('en', { minimumFractionDigits: 2 }):"",
      "WHT AMT 2": x.whtTaxAmount2?Number(x.whtTaxAmount2).toLocaleString('en', { minimumFractionDigits: 2 }):"",
      "VP#":x.vpNumber.substring(x.vpNumber.indexOf('_')+1),
      "PO#":x.poNumber,
      "SAP AP USER":x.approvedBy,
      "VENDOR ADD INVOICE DATE":x.createdDate != '-'?this.datePipe.transform(x.createdDate,'dd/MM/yyyy'):'-',
      "AP CHECKER USER":x.apCheckerUsername,
      "AP CHECKER ACCEPT DATE":x.apCheckerAcceptedDate != '-'?this.datePipe.transform(x.apCheckerAcceptedDate,'dd/MM/yyyy'):'-',
      "INVOICE FOR":x.invoiceFor,
      "TYPE OF INVOICE":x.typeOfInvoice

    })
  )

  TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "Ap-Report");
})
// 
// if(this.requestData == undefined){
//   this.requestData = {
//     "companyCodes": [],
//     "status": [],
// }
// }
// this.mySpinner = true;
//   (await this.cService.APTeamExport(this.requestData)).pipe(first()).subscribe(res => {
//     this.mySpinner = false;
//     
//   }, error => {
//     this.mySpinner = false;
//   })
}

  apReportForm: FormGroup = this.fb.group({
    multiSelectCompany:[],
    multiSelectStatus:[],
    companyCodes: [],
    status: [],
    vendorCodeFrom: [],
    vendorCodeTo: [],
    postingDateFrom: [],
    postingDateTo:[],
    invoiceNoFrom: [],
    invoiceNoTo: [],
    purchaseOrderNoFrom: [],
    purchaseOrderNoTo: [],
    vendorPortalRefNoFrom: [],
    vendorPortalRefNoTo: [],
    vendorName: [],
    InvoiceFor:[],
    typeOfInvoice:[],
    invoiceDateFrom:[],
    invoiceDateTo:[],
    netDueDateFrom:[],
    netDueDateTo:[],
    year:[]
  });

  // companyList =[
  //   {"companyCode": "1000","companyName": "1000-Siam City Cement"},
  //   {"companyCode": "1001","companyName": "1001-INSEE"},
  // ];
  // invoiceStatus =[
  //     {"statusId": "1","status": "Success"},
  //     {"statusId": "2","status": "AP Checker Accepted"},
  //     {"statusId": "3","status": "Pending"},
  //     {"statusId": "4","status": "AP Team Approved"}
  // ];

  language:any;
  currentUser:any=[];
  ngOnInit() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
    if(lang == 'en'){
        this.language = {
          "poNumber":"PO No",
          "totalAmount":"Total Amount",
          "Currency":"Currency",
          "PaymentTerm":"Payment Terms",
          "button1":"Approve",
          "button2":"Reject",
          "VendorCode":"Vendor Code",
          "VendorName":"Vendor Name",
          "postingDate":"AP Posting Date",
          "Head":"Tracking Report",
          "invoiceDate":"Invoice Date",
          "CodeFrom":"Enter Code From",
          "ToCode":"Enter Code To",
          "dateRange":"Enter a date range",
          "Startdate":"Start date",
          "Enddate":"End date",
          "VPNumber":"VP No",
          "InvoiceNumber":"Invoice No",
          "NumberFrom":"Number From",
          "ToNumber":"To Number",
          "Search":"Search",
          "DOC":"DOC#",
          "Invoice":"INVOICE#",
          "taxType":"TAX TYPE",
          "netDueDate":"NET DUE DATE",
          "paymentDate":"PAYMENT DATE",
          "paymentDoc":"PAYMENT DOC.",
          "paymentMethod":"PAYMENT METHOD",
          "WHTTYPE1":"WHT TYPE 1",
          "WHTCODE1":"WHT CODE 1",
          "WHTBASEAmt1":"WHT BASE Amt 1",
          "WHTAmt1":"WHT Amt 1",
          "WHTTYPE2":"WHT TYPE 2",
          "WHTCODE2":"WHT CODE 2",
          "WHTBASEAmt2":"WHT BASE Amt 2",
          "WHTAmt2":"WHT Amt 2",
          "vp" :"VP#",
          "po":"PO#",
          "SAPAPUSER":"SAP AP USER",
          "print":"Print",
          "status":"Status",
          "Comment":"Comment",
          "Pending":"Pending",
          "Clear":"Clear",
          "company":"Company",
          "selectCompany":"Select Company",
          "SelectStatus":"Select Status",
          "EnterVPNumberFrom":"Enter VP No From",
          "EnterVPNumberTo":"Enter VP No To",
          "EnterVendorName":"Enter Vendor Name",
          "EnterPONumberFrom":"Enter PO No From",
          "EnterPONumberTo":"Enter PO No To",
          "swalVenFrom":"Vendor Code 'From' is required",
          "swalVenTo":"Vendor Code 'To' is required",
          "swalInFrom":"Invoice Number 'From' is required",
          "swalInFromVp":"VP Number 'From' is required",
          "swalInFromPoNo":"PO Number 'From' is required",
          "swalInToPoNo":"PO Number 'To' is required",
          "swalInToVp":"VP Number 'To' is required",
          "swalInTo":"Invoice Number 'To' is required",
          "swalVenGFT":"Vendor Code 'From' cannot be greater than Vendor Code 'To'",
          "swalInGFT":"Invoice Number 'From' cannot be greater than Invoice Number 'To'",
          "swalStatus":"Select status to get AP Team Report",
          "swalPostingDate":"Posting Date 'From' cannot be greater than Posting Date 'To'",
          "swalPaymentDate":"Payment Date 'From' cannot be greater than Payment Date 'To'",
          "swalInvoiceDate":"Invoice Date 'From' cannot be greater than Invoice Date 'To'",
          "invoiceFor":"Invoice For",
          "typeOfInvoice":"Type of Invoice",
          "AddinvoiceDate":"Vendor Add Invoice Date",
          "ApcheckerDate":"AP Checker Accept Date",
          "EnterInvoiceNumber":"Enter Invoice No",
          "netDueDates":"Net Due Date",
          "swalnetDueDate":"Net Due Date 'From' cannot be greater than Net Due Date 'To'",
          "amount":"Amount",
          "apCheckerUsername":"Ap Checker User",
          "year":"VP No Year",
          "EnterYear":"Enter Year",
          "swalyear":"Enter Valid Year"

    }}
    else if(lang == 'th'){
      this.language = {
        "poNumber":"ตัวเลข PO",
        "totalAmount":"จำนวนเงินทั้งหมด",
        "Currency":"สกุลเงิน",
        "PaymentTerm":"เงื่อนไขการชำระเงิน",
        "button1":"อนุมัติ",
        "button2":"ปฏิเสธ",
        "VendorCode":"รหัสผู้ขาย",
        "VendorName":"ชื่อผู้จำหน่าย",
        "postingDate":"วันที่โพสต์ AP",
        "Head":"รายงานการติดตาม",
        "invoiceDate":"วันที่ออกใบแจ้งหนี้",
        "CodeFrom":"ใส่รหัสจาก",
        "ToCode":"ใส่รหัส To",
        "dateRange":"ป้อนช่วงวันที่",
        "Startdate":"วันที่เริ่มต้น",
        "Enddate":"วันที่สิ้นสุด",
        "VPNumber":"หมายเลข VP",
        "InvoiceNumber":"เลขใบสั่งของ",
        "NumberFrom":"หมายเลขจาก",
        "ToNumber":"ไปที่หมายเลข",
        "Search":"ค้นหา",
        "DOC":"เอกสาร#",
        "Invoice":"ใบแจ้งหนี้#",
        "taxType":"ประเภทภาษี",
        "netDueDate":"วันที่ครบกำหนดสุทธิ",
        "paymentDate":"วันจ่าย",
        "paymentDoc":"เอกสารการชำระเงิน.",
        "paymentMethod":"วิธีการชำระเงิน",
        "WHTTYPE1":"WHT พิมพ์ 1",
        "WHTCODE1":"WHT รหัส 1",
        "WHTBASEAmt1":"WHT ปริมาณพื้นฐาน 1",
        "WHTAmt1":"WHT จำนวน 1",
        "WHTTYPE2":"WHT พิมพ์ 2",
        "WHTCODE2":"WHT รหัส 2",
        "WHTBASEAmt2":"WHT ปริมาณพื้นฐาน 2",
        "WHTAmt2":"WHT จำนวน 2",
        "vp":"VP#",
        "po":"PO#",
        "SAPAPUSER":"ผู้ใช้ SAP AP",
        "print":"พิมพ์",
        "status":"สถานะ",
        "Comment":"ความคิดเห็น",
        "Pending":"รอดำเนินการ",
        "Clear":"ล้างข้อมูลการค้นหา",
        "company":"บริษัท",
        "selectCompany":"เลือกบริษัท",
        "SelectStatus":"เลือกสถานะ",
        "EnterVendorName":"ใส่ชื่อผู้ขาย",
        "swalVenFrom":"ต้องระบุรหัสผู้จำหน่าย 'จาก'",
        "swalVenTo":"ต้องระบุรหัสผู้จำหน่าย 'ถึง'",
        "swalInFrom":"ต้องระบุหมายเลขใบแจ้งหนี้ 'จาก'",
        "swalInTo":"ต้องระบุหมายเลขใบแจ้งหนี้ 'ถึง'",
        "swalVenGFT":"รหัสผู้ขาย 'จาก' ต้องไม่มากกว่ารหัสผู้ขาย 'ถึง'",
        "swalInGFT":"หมายเลขใบแจ้งหนี้ 'จาก' ต้องไม่มากกว่าหมายเลขใบแจ้งหนี้ 'ถึง'",
        "swalStatus":"เลือกสถานะเพื่อรับรายงานทีม AP",
        "swalPostingDate":"วันที่โพสต์ 'จาก' ต้องไม่มากกว่าวันที่โพสต์ 'ถึง'",
        "EnterVPNumberFrom":"ใส่หมายเลข VP From",
        "EnterVPNumberTo":"ใส่หมายเลข VP To",
        "EnterPONumberFrom":"ป้อนหมายเลข PO จาก",
        "EnterPONumberTo":"ป้อนหมายเลข PO ถึง",
        "swalInFromVp":"ต้องระบุหมายเลข VP 'จาก'",
        "swalInToVp":"ต้องการหมายเลข VP 'ถึง'",
        "swalInFromPoNo":"ต้องระบุหมายเลข PO 'จาก'",
        "swalInToPoNo":"ต้องระบุหมายเลข PO 'ถึง'",
        "swalPaymentDate":"วันที่ชำระเงิน 'จาก' ต้องไม่มากกว่าวันที่ชำระเงิน 'ถึง'",
        "swalInvoiceDate":"วันที่ในใบแจ้งหนี้ 'จาก' ต้องไม่มากกว่าวันที่ในใบแจ้งหนี้ 'ถึง'",
        "invoiceFor":"ใบแจ้งหนี้สำหรับ",
        "typeOfInvoice":"ประเภทของใบแจ้งหนี้",
        "AddinvoiceDate":"ผู้ขายเพิ่มวันที่ในใบแจ้งหนี้",
        "ApcheckerDate":"AP Checker ยอมรับวันที่",
        "EnterInvoiceNumber":"ป้อนหมายเลขใบแจ้งหนี้",
        "netDueDates":"วันครบกำหนดสุทธิ",
        "swalnetDueDate":"วันที่ครบกำหนดสุทธิ 'จาก' ต้องไม่เกินวันที่ครบกำหนดสุทธิ 'ถึง'",
        "amount":"จำนวน",
        "apCheckerUsername":"ผู้ใช้ตัวตรวจสอบ Ap",
        "year":"รองประธาน ไม่มีปี",
        "EnterYear":"ใส่ ปี",
        "swalyear":"ป้อนปีที่ถูกต้อง"
  }
}

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'companyCode',
      textField: 'companyName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    this.dropdownSettings1 = {
      singleSelection: false,
      closeDropDownOnSelection: true,
      idField: 'id',
      textField: 'status',        
      itemsShowLimit: 1,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };
    this.columnsToDisplay = ["status","company","vendorCode","vendorName","documentNumber","invoiceNumber","invoiceDate","postingDate","amount","totalAmount","currency","taxCode","paymentTerms","netDueDate","paymentMethods","whtType1","whtCode1","whtBaseAmount1","whtTaxAmount1","whtType2","whtCode2","whtBaseAmount2","whtTaxAmount2","vpNumber","poNumber","approvedBy","createdDate","apCheckerUsername","apCheckerAcceptedDate","invoiceFor","typeOfInvoice"];
    this.getCompanyMaster();
  }
  companyMaster:any=[];
  invoiceStatus: any =[]
  async getCompanyMaster(){
    (await this.cService.getMasterData()).pipe(first()).subscribe((data) => {
    // (await this.cService.getCompanyMaster(this.currentUser.usertype)).pipe(first()).subscribe((data) => {
      let companyData = [];
      let invoiceStatus = [];
      // if (data != null) {
        
      if (data.responseStatus.toUpperCase() == "SUCCESS") {
        for (let i of data.response.companyLists) {
          let obj = {
            companyCode: i.companyCode,
            companyName: i.companyCode + "-" + i.name,
          };
          companyData.push(obj);     
          this.invoiceStatus = data.response.invoiceStatus;
        }
        
        this.companyMaster = companyData;
        
        

        this.mySpinner = false;
      } else {
        this.mySpinner = false;
        Swal.fire({ 
          icon:'error',
          text: data.responseMessage
        });
      }
      // }
    },
      error => {
        this.mySpinner = false;
      }
    );
  }
  companyLists =[];
  companyCode:any;
  companyName:any;
  onSelect(item: any) {
    // this.companyCode = item.companyCode;
    // this.companyName = item.companyName;
    this.companyLists.push(item.companyCode);
    // 
    
  }

  onDeSelect(item: any) {
    
    // item.companyCode = "";
    // item.companyName = "";
    // this.companyCode = "";
    // this.companyName = "";
    const index = this.companyLists.indexOf(item.companyCode);
    
    this.companyLists.splice(index, 1);
    // 
    
  }

  onSelectAll(items: any) {
    this.companyLists = [];
    for (let i = 0; i < items.length; i++){
    //  let obj ={
    //     "companyCode":items[i].companyCode,
    //     "companyName":items[i].companyName
    //   };
    //   companyL.push(obj);
      this.companyLists.push(items[i].companyCode);
    }
    //this.companyLists = companyL;
    // 
    // 
    // 
    
  }
  
  onDeSelectAll(items: any) {
    this.companyLists = [];
    
  }

  status: any;
  statusId:any;
  selectedItems: any = [];
  itemstatus = [];
  onSelect1(item: any) {
    
    this.status = item.status;
    this.itemstatus.push(item.id);
  }

  onDeSelect1(item: any) {
    
    item.status = "";
    item.statusId = "";
    this.statusId = "";
    
    const index = this.itemstatus.indexOf(item.id);
    
    this.itemstatus.splice(index, 1);
    
  }  
 
  onSelectAll1(items: any) {
    
    this.itemstatus = [];
    for (let i = 0; i < items.length; i++){
      this.itemstatus.push(items[i].id);
    }
    
  }
  
  onDeSelectAll1(items: any) {
    this.itemstatus = [];
    
  }

  search(){
    this.table = true;
  }

//   companyName: any;
//   companyCode: any;
//   company: any;
//   getCompanyList(i:any){
//     for (let j=0; j<i.length; j++){
// this.companyCode = i[j].companyCode;
// this.companyName = i[j].companyName;
// this.company = this.companyCode + "-"+this.companyName
//      }
//     
//   }
onInvoiceForChange(event){
  
  this.InvoiceFor = event;
}
ontypeOfInvoiceChange(event){
  
  this.typeOfInvoice = event
}
onSubmit(){

  let venFrom:any;
  let venTo:any;
  let poFrom:any;
  let poTo:any;
  let Vname:any;
  let vCodeFrom:any
  let vCodeTo:any;
  let invNo:any;
  let postingDateFrom:any;
  let postingDateTo:any;
  let invoiceDateFrom:any;
  let invoiceDateTo:any;
  let InvoiceFor:any;
  let typeOfInvoice:any;
  let netDueDateFrom:any;
  let netDueDateTo:any;
  let Year:any;
  
  if(this.apReportForm.value.vendorPortalRefNoFrom){
    venFrom = this.apReportForm.value.vendorPortalRefNoFrom;
  }
  if(this.apReportForm.value.vendorPortalRefNoTo){
    venTo = this.apReportForm.value.vendorPortalRefNoTo;
  }
  if(this.apReportForm.value.purchaseOrderNoFrom){
    poFrom = this.apReportForm.value.purchaseOrderNoFrom;
  }
  if(this.apReportForm.value.purchaseOrderNoTo){
    poTo = this.apReportForm.value.purchaseOrderNoTo;
  }
  if(this.apReportForm.value.vendorName){
    Vname = this.apReportForm.value.vendorName;
  }
  if(this.apReportForm.value.vendorCodeFrom){
    vCodeFrom = this.apReportForm.value.vendorCodeFrom;
  }
  if(this.apReportForm.value.vendorCodeTo){
    vCodeTo = this.apReportForm.value.vendorCodeTo;
  }
  if(this.apReportForm.value.invoiceNoFrom){
    invNo = this.apReportForm.value.invoiceNoFrom;
  }
  if(this.postingDateFrom){
    postingDateFrom = this.postingDateFrom;
  }
  if(this.postingDateTo){
    postingDateTo = this.postingDateTo;
  }
  if(this.invoiceDateFrom){
    invoiceDateFrom = this.invoiceDateFrom;
  }
  if(this.invoiceDateTo){
    invoiceDateTo = this.invoiceDateTo;
  }
  if(this.InvoiceFor){
    InvoiceFor = this.InvoiceFor;
  }
  if(this.typeOfInvoice){
    typeOfInvoice = this.typeOfInvoice;
  }
  if(this.netDueDateFrom){
    netDueDateFrom = this.netDueDateFrom;
  }
  if(this.netDueDateTo){
    netDueDateTo = this.netDueDateTo;
  }
  
  if(this.year){
    Year = Number(this.year);
  }
  // 
    if(this.year && this.year.length != 4){
      Swal.fire({
        icon: 'warning',
        text: `${this.language.swalyear}`
      });
      return;
    }
  

  this.requestData = {
      "companyCodes": this.companyLists,
      "status": this.itemstatus,
      //"status": this.status,
      "vendorCodeFrom": vCodeFrom,
      "vendorCodeTo": vCodeTo,
      "postingDateFrom": postingDateFrom,
      "postingDateTo": postingDateTo,
      // "paymentDateFrom": this.paymentDateFrom,
      // "paymentDateTo": this.paymentDateTo,
      "createdDateFrom": invoiceDateFrom,
      "createdDateTo": invoiceDateTo,
      "netDueDateFrom":netDueDateFrom,
      "netDueDateTo":netDueDateTo,
      "invoiceNo": invNo,
      // "invoiceNoTo": this.apReportForm.value.invoiceNoTo,
      "purchaseOrderNoFrom": poFrom,
      "purchaseOrderNoTo": poTo,
      "vpNoFrom": venFrom,
      "vpNoTo": venTo,
      "vendorName": Vname,
      "invoiceFor": InvoiceFor,
      "typeOfInvoice": typeOfInvoice,
      "year":Year
      // "postingDateFrom": this.datePipe.transform(this.dnRange.value.postingDateFrom, 'dd-MM-yyyy'),
      // "postingDateTo": this.datePipe.transform(this.dnRange.value.postingDateTo, 'dd-MM-yyyy'),      
      //"purchaseOrderNo":this.apReportForm.value.purchaseOrderNo,      
      // "vendorPortalRefNo": this.apReportForm.value.vendorPortalRefNo,      
      // "vendorName": this.apReportForm.value.vendorName,
  };

  
  this.setTableData(null);
}   
pageIndex = 0;
pageSize = 10;
listLength: any;
count:any;
  async setTableData(event: PageEvent){

    if (event == null) {
      this.pageIndex = 0;
      this.pageSize = 10;
    } else {
      // if(event.pageIndex == 0){
      //   this.pageIndex = 0;
      //   this.pageSize = event.pageSize;
      // }else{
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      // }
    }
  let tableData: any [];
  let status : any;
  let comName: any;
      this.mySpinner = true;
      (await this.cService.getAPTeamReport(this.requestData,this.pageIndex, this.pageSize)).pipe(first()).subscribe(res => {
        if(res.response != null){
          if (res.responseStatus.toUpperCase() == "SUCCESS") {
            
            
            this.table = true;
            this.dataSource = new MatTableDataSource(res.response);
            this.dataSource2 = res.response;
            this.listLength = res.pagination.count;
            this.dataSource.sort = this.sort;
            
            this.mySpinner = false;
          } else {
            this.mySpinner = false;
            this.listLength = 0;
            Swal.fire(res.responseMessage,"","error");
          }
        }else{
          this.mySpinner = false;
          this.dataSource = new MatTableDataSource([]);
          this.listLength = 0;
          Swal.fire(res.responseMessage,"","error");
        }
      },
      error => {
        this.mySpinner = false;
        this.listLength = 0;
      })
}

onChangeOfVendorCode(event: any){
  let etv: any;
  etv = event.target.value;
  let vendorCode :any;
  vendorCode = this.apReportForm.value.vendorCodeFrom;
  
  
  if(vendorCode == "" || vendorCode == undefined){
    this.apReportForm.controls.vendorCodeTo.setValue("");
    Swal.fire(`${this.language.swalVenFrom}`,"","warning")    
    return false;
  }
}

onChangeOfInvoiceNumber(event: any){
  let etv: any;
  etv = event.target.value;
  let invoiceNo :any;
  invoiceNo = this.apReportForm.value.invoiceNoFrom;
  if(invoiceNo == "" || invoiceNo == undefined){
    this.apReportForm.controls.invoiceNoTo.setValue("");
    Swal.fire(`${this.language.swalInFrom}`,"","warning")    
    return false;
  }
}
onChangeOfVpNumber(event: any){
  let etv: any;
  etv = event.target.value;
  let invoiceNoVP :any;
  invoiceNoVP = this.apReportForm.value.vendorPortalRefNoFrom;
  if(invoiceNoVP == "" || invoiceNoVP == undefined){
    this.apReportForm.controls.vendorPortalRefNoTo.setValue("");
    Swal.fire(`${this.language.swalInFromVp}`,"","warning")    
    return false;
  }
}
onChangeOfpoNumber(event: any){
  let etv: any;
  etv = event.target.value;
  let PoNumber :any;
  PoNumber = this.apReportForm.value.purchaseOrderNoFrom;
  if(PoNumber == "" || PoNumber == undefined){
    this.apReportForm.controls.purchaseOrderNoTo.setValue("");
    Swal.fire(`${this.language.swalInFromPoNo}`,"","warning")    
    return false;
  }
}
vendorCFrom: any;
vendorCTo: any;
vpNoFrom: any;
vpNoTo: any;
year:any;
vName: any ;
poNoFrom : any;
poNoTo : any;
invoiceFrom : any;
invoiceTo : any;
onClear(){
  
  
  
  this.requestData = {};
  //this.dataSource = new MatTableDataSource([]);
  this.companyLists = [];
  this.itemstatus = [];
  //this.companyCode = "";
  this.apReportForm.get('multiSelectCompany').setValue([]);
  this.apReportForm.get('InvoiceFor').setValue([]);
  this.apReportForm.get('typeOfInvoice').setValue([]);
  this.isNetDuedate = true;
  this.netDueDateRange.controls.netDueDateFrom.setValue("");
  this.netDueDateRange.controls.netDueDateTo.setValue("");
  this.netDueDateFrom = '';
  this.netDueDateTo = '';
  this.vendorCFrom = "";
  this.vendorCTo = "";
  this.InvoiceFor = '';
  this.typeOfInvoice = '';
  this.postingDateFrom = '';
  this.postingDateTo = '';
  this.invoiceDateFrom = '';
  this.invoiceDateTo = '';
  this.paymentDateFrom = '';
  this.paymentDateTo = '';
  this.year = '';
  this.dnRange.controls.postingDateFrom.setValue("");
  this.dnRange.controls.postingDateTo.setValue("");
  this.paymentRange.controls.paymentDateFrom.setValue("");
  this.paymentRange.controls.paymentDateTo.setValue("");
  this.invoiceDateRange.controls.invoiceDateFrom.setValue("");
  this.invoiceDateRange.controls.invoiceDateTo.setValue("");
  this.isStartDate = true;
  this.isPaymentStartDate = true;
  this.isInvoiceStartDate = true;
  // this.dnRange.value.postingDateFrom = "";
  // this.fromDate.nativeElement.value = "";
  // this.dnRange.value.postingDateTo = "";
  // this.endDate.nativeElement.value = "";
  this.statusId = "";
  this.apReportForm.get('multiSelectStatus').setValue([]);
  this.vpNoFrom = "";
  this.vpNoTo = "";
  this.vName = "";
  this.poNoFrom = "";
  this.poNoTo = "";
  this.invoiceFrom = "";
  this.invoiceTo = "";  
  this.table = false;    
}

isStartDate:boolean = true;
postingDateFrom: any;
postingDateTo:any;
getStartDate(event){
  this.postingDateFrom = this.datePipe.transform(event.target.value,'yyyy-MM-dd');
   if(new Date(this.postingDateTo) < new Date(this.postingDateFrom)){
       Swal.fire(`${this.language.swalPostingDate}`,'','warning');      
     event.target.value = new Date(this.postingDateTo);
     this.postingDateFrom = this.datePipe.transform(this.postingDateTo,'yyyy-MM-dd');
   }else{
     if(this.postingDateTo != "" || this.postingDateTo != undefined){
       //this.deliveryWbDetails = [];
    //    let selectedItem = []
    //    
    //    for (let i = 0; i <= this.selectedValuesWb.length - 1; i++) {
    //      selectedItem.push(this.selectedValuesWb[i].poNumber);
    //    }
    //    this.getWBDetails(selectedItem);
    }
     
   }
  //  
  this.isStartDate = false;     
 }
 isPaymentStartDate:boolean = true;
 paymentDateFrom:any;
 paymentDateTo:any;
 getPaymentStartDate(event){
  this.paymentDateFrom = this.datePipe.transform(event.target.value,'yyyy-MM-dd');
   if(new Date(this.paymentDateTo) < new Date(this.paymentDateFrom)){
       Swal.fire(`${this.language.swalPaymentDate}`,'','warning');      
     event.target.value = new Date(this.paymentDateTo);
     this.paymentDateFrom = this.datePipe.transform(this.paymentDateTo,'yyyy-MM-dd');
   }else{
     if(this.paymentDateTo != "" || this.paymentDateTo != undefined){
       //this.deliveryWbDetails = [];
    //    let selectedItem = []
    //    
    //    for (let i = 0; i <= this.selectedValuesWb.length - 1; i++) {
    //      selectedItem.push(this.selectedValuesWb[i].poNumber);
    //    }
    //    this.getWBDetails(selectedItem);
    }
     
   }
  //  
  this.isPaymentStartDate = false;     
 }
 isInvoiceStartDate:boolean = true;
 invoiceDateFrom:any;
 invoiceDateTo:any;
 getInvoiceStartDate(event){
  this.invoiceDateFrom = this.datePipe.transform(event.target.value,'yyyy-MM-dd');
   if(new Date(this.invoiceDateTo) < new Date(this.invoiceDateFrom)){
       Swal.fire(`${this.language.swalInvoiceDate}`,'','warning');      
     event.target.value = new Date(this.invoiceDateTo);
     this.invoiceDateFrom = this.datePipe.transform(this.invoiceDateTo,'yyyy-MM-dd');
   }else{
     if(this.invoiceDateTo != "" || this.invoiceDateTo != undefined){
       //this.deliveryWbDetails = [];
    //    let selectedItem = []
    //    
    //    for (let i = 0; i <= this.selectedValuesWb.length - 1; i++) {
    //      selectedItem.push(this.selectedValuesWb[i].poNumber);
    //    }
    //    this.getWBDetails(selectedItem);
    }
     
   }
  //  
  this.isInvoiceStartDate = false;     
 }

  getEndDate(event) {   
    this.postingDateTo = this.datePipe.transform(event.target.value,'yyyy-MM-dd');
    if(new Date(this.postingDateTo) < new Date(this.postingDateFrom)){
      Swal.fire(`${this.language.swalPostingDate}`,'','warning');
      event.target.value = new Date(this.postingDateFrom);
      this.postingDateTo = this.datePipe.transform(this.postingDateFrom,'yyyy-MM-dd');
    }
    if(this.postingDateFrom == null || this.postingDateFrom == undefined ){
        return;
    }
    if(this.postingDateTo == null || this.postingDateTo == undefined ){
      return;
  }
  }

  getInvoiceEndDate(event) {   
    this.invoiceDateTo = this.datePipe.transform(event.target.value,'yyyy-MM-dd');
    if(new Date(this.invoiceDateTo) < new Date(this.invoiceDateFrom)){
      Swal.fire(`${this.language.swalInvoiceDate}`,'','warning');
      event.target.value = new Date(this.invoiceDateFrom);
      this.invoiceDateTo = this.datePipe.transform(this.invoiceDateFrom,'yyyy-MM-dd');
    }
    if(this.invoiceDateFrom == null || this.invoiceDateFrom == undefined ){
        return;
    }
    if(this.invoiceDateTo == null || this.invoiceDateTo == undefined ){
      return;
  }
  }
  getPaymentEndDate(event) {   
    this.paymentDateTo = this.datePipe.transform(event.target.value,'yyyy-MM-dd');
    if(new Date(this.paymentDateTo) < new Date(this.paymentDateFrom)){
      Swal.fire(`${this.language.swalPaymentDate}`,'','warning');
      event.target.value = new Date(this.paymentDateFrom);
      this.paymentDateTo = this.datePipe.transform(this.paymentDateFrom,'yyyy-MM-dd');
    }
    if(this.paymentDateFrom == null || this.paymentDateFrom == undefined ){
        return;
    }
    if(this.paymentDateTo == null || this.paymentDateTo == undefined ){
      return;
  }
  }
  netDueDateFrom:any;
  netDueDateTo:any;
  isNetDuedate:boolean = true;
  getnetDateFrom(event){
    this.netDueDateFrom = this.datePipe.transform(event.target.value,'yyyy-MM-dd');
     if(new Date(this.netDueDateTo) < new Date(this.netDueDateFrom)){
         Swal.fire(`${this.language.swalnetDueDate}`,'','warning');      
       event.target.value = new Date(this.netDueDateTo);
       this.netDueDateFrom = this.datePipe.transform(this.netDueDateTo,'yyyy-MM-dd');
     }else{
       if(this.netDueDateTo != "" || this.netDueDateTo != undefined){;
      }
       
     }
    this.isNetDuedate = false;     
   }

   getnetDueDateTo(event) {   
    this.netDueDateTo = this.datePipe.transform(event.target.value,'yyyy-MM-dd');
    if(new Date(this.netDueDateTo) < new Date(this.netDueDateFrom)){
      Swal.fire(`${this.language.swalnetDueDate}`,'','warning');
      event.target.value = new Date(this.netDueDateFrom);
      this.netDueDateTo = this.datePipe.transform(this.netDueDateFrom,'yyyy-MM-dd');
    }
    if(this.netDueDateFrom == null || this.netDueDateFrom == undefined ){
        return;
    }
    if(this.netDueDateTo == null || this.netDueDateTo == undefined ){
      return;
  }
  }

  keyPressNumbers(event) {
    
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) && (charCode != 46)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  allowFour(event){
    if (
      event.target.value.length > 3
    ) {
      event.preventDefault();
      return;
    }
  }

  numberOnly(event) {
    var regex = new RegExp("^[0-9]");
        var key = String.fromCharCode(event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
  }

}
export interface PeriodicElement {
      STATUS:any,
      COMPANY: any,
      VENDORCODE: any,
      VENDORNAME: any,
      DOC: any,
      INVOICE: any,     
      INVOICEDATE: any,   
      APPOSTINGDATE: any,
      AMOUNT:any,
      TOTALAMOUNT: any,
      CURRENCY: any,      
      TAXTYPE: any,  
      PAYMENTTERMS: any,
      NETDUEDATE: any,
      PAYMENTMETHOD: any,
      WHTTYPE1:any,
      WHTCODE1: any,
      WHTBASEAMT1:any,
      WHTAMT1: any,
      WHTTYPE2:any,
      WHTCODE2: any,      
      WHTBASEAMT2:any,      
      WHTAMT2:any,
      VP:any,
      PO:any,
      SAPAPUSER:any,
      VENDORADDINVOICEDATE:any,
      APCHECKERUSER:any,
      APCHECKERACCEPTDATE:any,
      INVOICEFOR:any,
      TYPEOFINVOICE:any
}