import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { CommonServicesService } from 'src/app/services/common-services.service';
import { AdminServiceService } from 'src/app/services/admin-service.service';
// import { any } from '../purchase-order';

@Component({
  selector: 'app-po-info',
  templateUrl: './po-info.component.html',
  styleUrls: ['./po-info.component.css']
})
export class PoInfoComponent implements OnInit {
  expandedElement: any | null | undefined;
  mySpinner:boolean = false;
  constructor(private router: Router, private route: ActivatedRoute,private fb:FormBuilder,private cService: CommonServicesService,private aService: AdminServiceService) { }
  routeFrom: any;
  routeName: any;
  sub: any;
  userType: string | null | undefined;
  language:any;
  currentUser:any=[];
  subTotal:any=0;
  Source:any;
  dnPoid:any;
  languagePref: any;
  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      this.routeFrom = params["id"];
      this.routeName = params["pageName"];
      this.dnPoid = params["dnId"];
  })
  this.getPOInfoByPONumber(this.routeFrom);
  this.userType = localStorage.getItem("user");
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
  let lang = this.currentUser.langPreference;
  this.languagePref = this.currentUser.langPreference;
  if(lang == 'en'){
    this.language = {
      "poNumber":"PO Number",
      "poItem":"PO Item",
      "materialGroup":"Material Group",
      "material":"Material No",
      "shortText":"Short Text",
      "scheduledQuantity":"PO Qty",
      "grQuantity":"Received Qty",
      "outStandingQuantity":"Pending Qty",
      "netPrice":"Unit Price",
      "grossValue":"PO Amount",
      "warehouseReceivedQuantity":"Delivering Qty",
      "totalAmount":"Total Amount",
      "totalinwords":"Total Amount In Words",
      "SubTotal":"Sub Total",
      "LessDiscount":"Less Discount",
      "Currency":"Currency",
      "AdditionalConditions":"Additional Conditions",
      "PaymentTerm":"Payment Term",
      "DownPayment":"Down Payment",
      "button1":"Approve",
      "button2":"Reject",
      "VendorCode":"Vendor Code",
      "Head":" Delivery Note",
      "SoldTo":"Sold To",
      "Address":"Address",
      "Date":"Date",
      "Duedate":"Due date",
      "ShipmentNo":"Shipment No",
      "SupportDocuments":"Support Documents",
      "orderDetails":"Order Details",
      "PONo":"PO.No",
      "vendorName":"Vendor Name",
      "SalePerson":"Sale Person",
      "DeliveryTo":"Delivery To",
      "taxIdentificationNo":"Tax identification no",
      "headOfficeorBranchNo":"Head office or Branch no",
      "lineBeforeTable":" We, the Buyer, are pleased to place this purchase order for the following Commodity on the terms and conditions as stated below and on the reverse page hereof",
      "itemQuantity":"Item Quantity",
      "itemAmount":"Item Amount",
      "print":"Print"
      
    }; 

    }else if(lang == 'th'){
    this.language = {
      "poNumber":"เลขที่ใบสั่งซื้อ",
      "poItem":"รายการสั่งซื้อ",
      "materialGroup":"กลุ่มวัสดุ",
      "material":"หมายเลขวัสดุ",
      "shortText":"ข้อความสั้น",
      "scheduledQuantity":"จำนวน PO",
      "grQuantity":"รับจำนวน",
      "outStandingQuantity":"จำนวนที่รอดำเนินการ",
      "netPrice":"ราคาต่อหน่วย",
      "grossValue":"จำนวนใบสั่งซื้อ",
      "warehouseReceivedQuantity":"ส่งมอบจำนวน",
      "totalAmount":"จำนวนเงินทั้งหมด",
      "totalinwords":"จำนวนเงินทั้งหมดในคำ",
      "SubTotal":"ยอดรวม",
      "LessDiscount":"ลดน้อยลง",
      "Currency":"สกุลเงิน",
      "AdditionalConditions":"เงื่อนไขเพิ่มเติม",
      "PaymentTerm":"เงื่อนไขการชำระเงิน",
      "DownPayment":"เงินดาวน์",
      "button1":"อนุมัติ",
      "button2":"ปฏิเสธ",
      "VendorCode":"รหัสผู้ขาย",
      "Head":" บันทึกการส่งมอบ",
      "SoldTo":"ขายให้",
      "Address":"ที่อยู่",
      "Date":"วันที่",
      "Duedate":"วันครบกำหนด",
      "ShipmentNo":"เลขที่จัดส่ง",
      "SupportDocuments":"เอกสารสนับสนุน",
      "orderDetails":"รายละเอียดการสั่งซื้อ",
      "PONo":"ป.ไม่มี",
      "vendorName":"ชื่อผู้จำหน่าย",
      "SalePerson":"พนักงานขาย",
      "DeliveryTo":"ส่งไปที่",
      "taxIdentificationNo":"หมายเลขประจำตัวผู้เสียภาษี",
      "headOfficeorBranchNo":"สำนักงานใหญ่ หรือ สาขาที่",
      "lineBeforeTable":"เราซึ่งเป็นผู้ซื้อมีความยินดีที่จะส่งใบสั่งซื้อนี้สำหรับสินค้าโภคภัณฑ์ต่อไปนี้ตามข้อกำหนดและเงื่อนไขตามที่ระบุไว้ด้านล่างและในหน้าย้อนกลับของที่นี้",
      "itemQuantity":"จำนวนรายการ",
      "itemAmount":"จำนวนรายการ",
      "print":"พิมพ์"
    };
    }
  }
  detailsTableData = [
    { "poNumber":"10", "grOrSr":"GR", "materialG":"M0807", "shortText":"CUIRCUIT BREAKER;PKE12;EATON", "poQty":24, "receivedQty":2, "pendingQty":22, "unitPrice":2340, "poAmount":"4680", "delQty":30, "totalAmt":"9360"},
    { "poNumber":"20", "grOrSr":"SR", "materialG":"M0817", "shortText":"CUIRCUIT BREAKER;PKE12;EATON", "poQty":45, "receivedQty":25, "pendingQty":20, "unitPrice":100, "poAmount":"4500", "delQty":20, "totalAmt":"2500"}
    
  ]
  dataSource:any=[
    {
      'groupName':"DL12992", 'commissionType':"PO12992", 'commission':"30/11/2021", 'ticketQuantity':"100", 'ticketAmt':"1000",'status':"Pending"
    },
    {
      'groupName':"DL12992", 'commissionType':"PO12993", 'commission':"30/11/2021", 'ticketQuantity':"100", 'ticketAmt':"500",'status':"Pending"
    }
  ];
  //columnsToDisplay = ['groupName', 'commissionType', 'commission', 'ticketQuantity', 'ticketAmt','status','actions'];
  columnsToDisplay = ["poItem","materialGroup","shortText", "scheduledQuantity","netPrice","grossValue"];
  // columnsToDisplay = ["poItem","materialGroup","shortText", "scheduledQuantity","grQuantity","outStandingQuantity","netPrice","grossValue","invoicedAmt","totalAmount"];
  dataSource1:any=[
    {
      'dlNo':"DL12992",'groupName':"INV1232", 'commissionType':"PO12992", 'commission':"30/11/2021", 'ticketQuantity':"100", 'ticketAmt':"1000",'status':"Pending"
    },
    {
      'dlNo':"DL12992",'groupName':"INV1232", 'commissionType':"PO12993", 'commission':"30/11/2021", 'ticketQuantity':"100", 'ticketAmt':"500",'status':"Pending"
    }
  ];
  columnsToDisplay1 = ['groupName', 'commissionType','dlNo', 'commission', 'ticketQuantity', 'ticketAmt','status','actions'];

  columnsToDisplayDL = ['shipmentNo', 'dlNoteNo', 'dlNoteDate', 'qty', 'totalAmt','status','actions'];
  dataSourceDl:any = [
    {
      'shipmentNo':"DL12995",'poNumber':"PO1233", 'dlNoteNo':"PO12992", 'dlNoteDate':"04/12/2021", 'qty':"100", 'totalAmt':"1000",'status':"Approved"
    },
    {
      'shipmentNo':"DL12996",'poNumber':"PO1232", 'dlNoteNo':"PO12993", 'dlNoteDate':"04/12/2021", 'qty':"100", 'totalAmt':"500",'status':"Approved"
    }
  ];

  onInfo(element: any) {
    this.router.navigate(["/delivery-notes/delivery-notes-info", "po"]);
  }
  onInfo1(element: any) {
    this.router.navigate(["invoice-list/invoice-details", "po"])
  }
  goTo() {
    if(this.routeName =='p'){
      this.router.navigate(["delivery-notes"]);
      }else if(this.routeName =='h'){
        this.router.navigate(["delivery-notes-history"]);
      }else if(this.routeName =='d'){
        // this.router.navigate([`delivery-notes/delivery-notes-info/${this.routeFrom}/pending`]);
        this.router.navigate(["/delivery-notes/delivery-notes-info", this.dnPoid, "pending"]);
      }else if(this.routeName =='e'){
        // this.router.navigate([`delivery-notes/delivery-notes-info/${this.routeFrom}/pending`]);
        this.router.navigate(["/delivery-notes/edit-delivery-note", this.dnPoid]);
      }
    
  }

  addDelivery() {
    this.router.navigate(["purchase-order/add-delivery-notes"])
  }
  onPoInfo(element: any) {
    this.router.navigate(["delivery-notes/vendor-info", element, this.routeFrom, this.routeName, this.dnPoid])
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');  
  }

  purchaseOrder:any=[];
  purchaseTaxDetails:any=[];
  purchaseOrderInfo:any=[];
  totalQty:any=0;
  totalAmt:any=0;
  grossValue: any;
  scheduledQuantity: any;
  dpytAmount: any;
  newsub:any=0;
  currency: any;
  async getPOInfoByPONumber(id:any){
    this.mySpinner = true;
    (await this.cService.getPOInfoByPONumber(id)).pipe(first()).subscribe(res =>{
      let result=res;
      if(res.responseStatus.toUpperCase() == "SUCCESS"){
          // this.dataSource=res.response;
          this.purchaseOrder = res.response;
          this.getVendorInfo(this.purchaseOrder.vendorId);
          this.purchaseOrderInfo = res.response.purchaseOrderDetails;
          if(res.response.companyCode != null){
            this.getBranchMaster(this.purchaseOrder.vendorId, res.response.companyCode);
          }
            // if(this.purchaseOrder.length > 1){
            for(let i=0; i<this.purchaseOrderInfo.length; i++){
              this.grossValue = this.purchaseOrderInfo[i].grossValue;
              this.scheduledQuantity = this.purchaseOrderInfo[i].scheduledQuantity;
              this.dpytAmount = this.purchaseOrderInfo[i].dpytAmount;
              this.currency = this.purchaseOrderInfo[i].currency;
             }
           
            for(let i=0;i<this.purchaseOrder.purchaseOrderDetails.length;i++){              
              if(this.purchaseOrder.purchaseOrderDetails[i].outStandingQuantity){      
                this.totalQty = parseInt(this.totalQty) + parseInt(this.purchaseOrder.purchaseOrderDetails[i].outStandingQuantity);
                this.totalAmt = parseInt(this.totalAmt) + (parseInt(this.purchaseOrder.purchaseOrderDetails[i].outStandingQuantity) * parseInt(this.purchaseOrder.purchaseOrderDetails[i].netPrice ));
                this.subTotal = (parseFloat(this.subTotal) + (parseFloat(this.purchaseOrder.purchaseOrderDetails[i].grossValue))).toFixed(2);
                this.newsub = this.numberWithCommas(this.subTotal);
                // this.subTotal = (parseFloat(this.subTotal) + (parseInt(this.purchaseOrder.purchaseOrderDetails[i].outStandingQuantity) * parseInt(this.purchaseOrder.purchaseOrderDetails[i].netPrice))).toFixed(3);
              }           
            }
            this.mySpinner = false;
          //}
          
          // this.purchaseTaxDetails = res.response.purchaseOrderDetails[0];
      }else{
        // this.showOverlay=false;
       this.mySpinner = false;
      }
    },error =>{
      // this.showOverlay=false;
      this.mySpinner = false;
    })
  }
  branchMaster:any;
  branchC:boolean=false;
  async getBranchMaster(vendorId:any, companyCode:any){
    this.mySpinner = true;
    // this.branchMaster = [];
    (await this.cService.getBranchMaster(vendorId, companyCode)).pipe(first()).subscribe((data) => {
       
               
          if (data.responseStatus.toUpperCase() == "SUCCESS") {
            this.branchMaster= data.response[0];
            this.branchC = true;
            this.mySpinner = false;
          } else {
            this.branchC = false;
            this.mySpinner = false;
            // Swal.fire({ 
            //   icon:'error',
            //   text: data.responseMessage
            // });
          }
      },
      () => {
        this.mySpinner = false;
      }
    );
  }
  vendorDetails:any=[];
  vendorTelePhoneDetails:any=[];
  vendorOrg: any;
  // vendorAddress: any;
  address:boolean=false;
  taxId:any;
  vendorInfo1:any=[];
  isboolean:boolean = false;
  isstored:any;
  isAdded:boolean = false;
  vendorAdd:any = [];
  getVendorInfo(vendorId: any) {
    //this.mySpinner = true;
    this.aService.getVendorInfoById(vendorId).pipe(first()).subscribe(res =>{
      if (res.responseStatus.toUpperCase() == "SUCCESS") {
        
        //this.mySpinner = false;
        this.taxId = res.response.taxNumberThree;
        this.vendorAdd = res.response;
        let vendorDetails = res.response.vendorAddress;
        // this.vendorInfo = res.response.vendorDetails;
          // this.vendorInfo1 = res.response.vendorDetails.vendorAddress;
          // let vendorMasterDetails = res.response.vendorDetails.vendorAddress;
        //   for(let c of vendorDetails){
        //     if(c.addVersion == undefined){
        //       this.vendorInfo1 = c;
        //     }
        //   }
        for(let i = 0; i < vendorDetails.length; i++){
          if(vendorDetails[i].addVersion == undefined || vendorDetails[i].addVersion == ""){
            this.isboolean = true;
            this.vendorInfo1 = vendorDetails[i]
            break;
        }else if(vendorDetails[i].addVersion == "E" && this.isAdded == false){
          this.isAdded = true;
          this.isstored = i;
        }
      }
      if(this.isboolean == false){
        this.vendorInfo1 = vendorDetails[this.isstored];
      }
        
        if(res.response.vendorOrg.length > 0){
          this.vendorOrg = res.response.vendorOrg[0];
        }
        
        // this.vendorAddress = this.vendorDetails.address+','+this.vendorDetails.nameOne+','+this.vendorDetails.street+','+this.vendorDetails.city+','+this.vendorDetails.district+','+this.vendorDetails.postalCode+',P:'+this.vendorDetails.comTelephone;
        // if (this.vendorAddress == null || this.vendorAddress == undefined){
        //   this.vendorAddress = "-";
        // }else{
        //   this.vendorAddress = this.vendorDetails.address+','+this.vendorDetails.nameOne+','+this.vendorDetails.street+','+this.vendorDetails.city+','+this.vendorDetails.district+','+this.vendorDetails.postalCode+',P:'+this.vendorDetails.comTelephone;
        // }
      } else {
        //this.mySpinner = false;
        
      }
    });
  }

  printPage(cmpName){
      let printContents = document.getElementById(cmpName).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();      
      document.body.innerHTML = originalContents;
      location.reload() 
  }
}
