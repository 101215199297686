<mat-card>
    <mat-card-title>
        <div class="margin-bottotm" fxLayout="row" fxFlex="100">
            <div class="pull-left title" fxFlex="80">
                {{language.masterRecords}}
            </div>
        </div>
    </mat-card-title>
    <hr>

    <mat-card-content>
<div fxFlex="100"  fxLayoutWrap="column">
        
    <form [formGroup]="masterReportForm">
        <div fxLayout="row">
            <div class="leftBox"  fxFlex="50" fxLayout="column">
            <div fxFlex="100" fxLayout="row">
                    <div fxFlex="25">
                        <p class="fontS label">{{language.tablename}}</p>
                    </div>
                    <div fxFlex="75">
                        <ng-multiselect-dropdown  placeholder="{{language.selecttablename}}"
                            [settings]="dropdownSettings1"
                            formControlName="selecttablename"
                            [data]="tableList"
                            (onSelect)="onSelect2($event)" (onDeSelect)="onDeSelect2($event)"
                            >
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div fxFlex="100" fxLayout="row" style="margin-top:20px">
                    <div fxFlex="25">
                        <p class="fontS label">{{language.action}}</p>
                    </div>
                    <div fxFlex="75">
                    <mat-form-field class="example-full-width input"appearance="outline">
                            <mat-select appearance="outline" [(ngModel)]="selected" formControlName="action" (valueChange)="onactionTypeChange($event)">
                              <mat-option *ngFor="let item of actionTypes" [value]="item.key">{{item.value}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
            </div>
            <div fxFlex="100" fxLayout="row" fxLayout.xs="column" style="padding-bottom: 2px;" *ngIf = "selected == 'Upload'">
                <div class="padding-right" fxFlex="70">
                  {{language.attachments}}:
                  <div class="custom-file width-80">
                    <form method="post" enctype="multipart/form-data">
                      <label for="upfile" class="custom-file-input">
                          <input  #myInput id="upfile" type="file" (change)="upload($event)" accept=".xls,.xlsx" 
                        class="custom-file-input" aria-describedby="inputGroupFileAddon01"  />
                      </label>
                    </form>
                  </div>
                  <label style="font-size: 12px;"> {{language.note}}:<span>{{language.noteLine1}}</span><br/>{{language.noteLine2}} </label>
                </div>
                <div fxFlex.gt-sm="50" fxFlex="100" *ngIf = "fileName.length != 0" style="padding-top:28px">
                    <ul>
                      <li>
                        <span>
                          {{ fileName | slice:0:25 }}{{ fileName.length > 25 ? '....' : '' }}
                        </span>
                        <a style="cursor:pointer">
                          <mat-icon (click)="deleteItem(fileName)" style="font-size:17px">delete</mat-icon>
      
                        </a>
                      </li>
                    </ul>
                  </div>
              </div>
              <div>
                <div class="buttonGroup">
                    <button mat-flat-button (click)="onSubmit()">{{language.Submit}}</button>
                    <button mat-flat-button (click)="onClear()" >{{language.Clear}}</button>   
                </div>
            </div>
            </div>
            <div class="rightBox" fxFlex="50" fxLayout="column" *ngIf = "selected != 'Download'">
                <div fxFlex.gt-lg="70" fxFlex.gt-sm="70" fxFlex.gt-md="70" fxFlex.gt-xs="100" fxFlex="100" *ngIf = "showFile == 'Business Place Master'">
                    <div class="custom-file width-80" style="margin-top: 30px;min-width: 212px;">
                        <a href="../../assets/master-doc/Business_Place_Master_Template.xlsx" 
                            download="Business_Place_Master_Template.xlsx">
                            <mat-icon style="font-size: 16px;"  >download</mat-icon>                                                    
                            Business_Place_Master_Template
                        </a>
                    </div>
                </div>
                <div fxFlex.gt-lg="70" fxFlex.gt-sm="70" fxFlex.gt-md="70" fxFlex.gt-xs="100" fxFlex="100" *ngIf = "showFile == 'Storage Location Master'">
                    <div class="custom-file width-80" style="margin-top: 30px;min-width: 212px;">
                        <a href="../../assets/master-doc/Storage_Location_Template.xlsx" 
                            download="Storage_Location_Template.xlsx">
                            <mat-icon style="font-size: 16px;"  >download</mat-icon>                                                    
                            Storage_Location_Template
                        </a>
                    </div>
                </div>
                <div fxFlex.gt-lg="70" fxFlex.gt-sm="70" fxFlex.gt-md="70" fxFlex.gt-xs="100" fxFlex="100" *ngIf = "showFile == 'Withholding Tax Master'">
                    <div class="custom-file width-80" style="margin-top: 30px;min-width: 212px;">
                        <a href="../../assets/master-doc/WHT_Code_Master_Template.xlsx" 
                            download="WHT_Code_Master_Template.xlsx">
                            <mat-icon style="font-size: 16px;"  >download</mat-icon>                                                    
                            WHT_Code_Master_Template
                        </a>
                    </div>
                </div>
                <div fxFlex.gt-lg="70" fxFlex.gt-sm="70" fxFlex.gt-md="70" fxFlex.gt-xs="100" fxFlex="100" *ngIf = "showFile == 'Company Master'">
                    <div class="custom-file width-80" style="margin-top: 30px;min-width: 212px;">
                        <a href="../../assets/master-doc/Company_Master_Template.xlsx" 
                            download="Company_Master_Template.xlsx">
                            <mat-icon style="font-size: 16px;"  >download</mat-icon>                                                    
                            Company_Master_Template
                        </a>
                    </div>
                </div>
                <div fxFlex.gt-lg="70" fxFlex.gt-sm="70" fxFlex.gt-md="70" fxFlex.gt-xs="100" fxFlex="100" *ngIf = "showFile == 'Cost Center Master'">
                    <div class="custom-file width-80" style="margin-top: 30px;min-width: 212px;">
                        <a href="../../assets/master-doc/Cost_Center_Template.xlsx" 
                            download="Cost_Center_Template.xlsx">
                            <mat-icon style="font-size: 16px;"  >download</mat-icon>                                                    
                            Cost_Center_Template
                        </a>
                    </div>
                </div>
                <div fxFlex.gt-lg="70" fxFlex.gt-sm="70" fxFlex.gt-md="70" fxFlex.gt-xs="100" fxFlex="100" *ngIf = "showFile == 'GL Account Master'">
                    <div class="custom-file width-80" style="margin-top: 30px;min-width: 212px;">
                        <a href="../../assets/master-doc/GL_Account_Template.xlsx" 
                            download="GL_Account_Template.xlsx">
                            <mat-icon style="font-size: 16px;"  >download</mat-icon>                                                    
                            GL_Account_Template
                        </a>
                    </div>
                </div>
                <div fxFlex.gt-lg="70" fxFlex.gt-sm="70" fxFlex.gt-md="70" fxFlex.gt-xs="100" fxFlex="100" *ngIf = "showFile == 'Payment Terms Master'">
                    <div class="custom-file width-80" style="margin-top: 30px;min-width: 212px;">
                        <a href="../../assets/master-doc/Payment_Terms_Master_Template.xlsx" 
                            download="Payment_Terms_Master_Template.xlsx">
                            <mat-icon style="font-size: 16px;"  >download</mat-icon>                                                    
                            Payment_Terms_Master_Template
                        </a>
                    </div>
                </div>
                <div fxFlex.gt-lg="70" fxFlex.gt-sm="70" fxFlex.gt-md="70" fxFlex.gt-xs="100" fxFlex="100" *ngIf = "showFile == 'Plant Master'">
                    <div class="custom-file width-80" style="margin-top: 30px;min-width: 212px;">
                        <a href="../../assets/master-doc/Plant_Master_Template.xlsx" 
                            download="Plant_Master_Template.xlsx">
                            <mat-icon style="font-size: 16px;"  >download</mat-icon>                                                    
                            Plant_Master_Template
                        </a>
                    </div>
                </div>
                <div fxFlex.gt-lg="70" fxFlex.gt-sm="70" fxFlex.gt-md="70" fxFlex.gt-xs="100" fxFlex="100" *ngIf = "showFile == 'Tax Master'">
                    <div class="custom-file width-80" style="margin-top: 30px;min-width: 212px;">
                        <a href="../../assets/master-doc/Tax_Master_Template.xlsx" 
                            download="Tax_Master_Template.xlsx">
                            <mat-icon style="font-size: 16px;"  >download</mat-icon>                                                    
                            Tax_Master_Template
                        </a>
                    </div>
                </div>
                <div fxFlex.gt-lg="70" fxFlex.gt-sm="70" fxFlex.gt-md="70" fxFlex.gt-xs="100" fxFlex="100" *ngIf = "showFile == 'WHT Type Master'">
                    <div class="custom-file width-80" style="margin-top: 30px;min-width: 212px;">
                        <a href="../../assets/master-doc/With_Tax_Type_Master_Template.xlsx" 
                            download="With_Tax_Type_Master_Template.xlsx">
                            <mat-icon style="font-size: 16px;"  >download</mat-icon>                                                    
                            With_Tax_Type_Master_Template
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>        
    </mat-card-content>
</mat-card>
<div class="overlay" *ngIf="mySpinner">
    <div class="center">
        <mat-spinner></mat-spinner>
    </div>
</div>

