import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';

@Component({
  selector: 'app-error-login',
  templateUrl: './error-login.component.html',
  styleUrls: ['./error-login.component.css']
})
export class ErrorLoginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    swal.fire("Required access not available. Please contact your administrator." , "", "error").then(function(){;
      window.location.href = "signin";
      localStorage.clear();
    });
  }

}
