<mat-card>
    <mat-card-title>
        <div class="margin-bottotm" fxLayout="row" fxFlex="100">
            <div class="pull-left title" fxFlex="80">
                {{language.Head}}
            </div>
            <!-- <div class="pull-right" fxFlex="20">
                <mat-icon  class="close1">
                    close
                </mat-icon>
            </div> -->
        </div>
    </mat-card-title>
    <hr>

    <mat-card-content>
<div fxFlex="100"  fxLayoutWrap="column">
        
    <form [formGroup]="apReportForm">
        <div fxLayout="row">
            <div class="leftBox"  fxFlex="50" fxLayout="column">
            <div fxFlex="100" fxLayout="row">
            <div fxFlex="25">
                <p class="fontS label">{{language.company}}</p>
            </div>
            <div fxFlex="75">
                <ng-multiselect-dropdown  formControlName="multiSelectCompany" 
          placeholder="{{language.selectCompany}}"
          [settings]="dropdownSettings"
          [data]="companyMaster"
          (onSelect)="onSelect($event)"
          (onSelectAll)="onSelectAll($event)"
          (onDeSelect)="onDeSelect($event)"
          (onDeSelectAll)="onDeSelectAll($event)">
        </ng-multiselect-dropdown>
            </div>
            </div>
            <div fxFlex="100" fxLayout="row">
                    <div fxFlex="25">
                        <p class="fontS label">{{language.status}}</p>
                    </div>
                    <div fxFlex="75">
                        <ng-multiselect-dropdown formControlName="multiSelectStatus" placeholder="{{language.SelectStatus}}"
                            [settings]="dropdownSettings1" [data]="invoiceStatus" (onSelect)="onSelect1($event)"
                            (onDeSelect)="onDeSelect1($event)"
                            (onSelectAll)="onSelectAll1($event)"
                           (onDeSelectAll)="onDeSelectAll1($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div fxFlex="100" fxLayout="row">
                        <div fxFlex="25">
                            <p class="fontS label">{{language.VPNumber}}</p>
                        </div>
                        <div fxFlex="35">
                            <mat-form-field class="example-full-width input" appearance="outline">
                                <!-- <mat-label>VP Number</mat-label> -->
                                <input matInput placeholder="{{language.EnterVPNumberFrom}}" 
                                formControlName="vendorPortalRefNoFrom"
                                [(ngModel)]="vpNoFrom" maxlength="10" (keypress)="numberOnly($event)">
                              </mat-form-field>
                            </div>
                            <div fxFlex="5"><p class="fontS to">-</p></div>
                            <div fxFlex="35">
                                <mat-form-field  class="example-full-width input" appearance="outline">
                                    <!-- <mat-label>To</mat-label> -->
                                    <input  matInput placeholder="{{language.EnterVPNumberTo}}"
                                    formControlName="vendorPortalRefNoTo"
                                    [(ngModel)]="vpNoTo" maxlength="10" (keypress)="numberOnly($event)">
                                  </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="100" fxLayout="row">
                            <div fxFlex="25">
                                <p class="fontS label">{{language.year}}</p>
                            </div>
                            <div fxFlex="50">
                                <mat-form-field class="example-full-width input" appearance="outline">
                                    <input matInput placeholder="{{language.EnterYear}}"  
                                    formControlName="year"
                                    [(ngModel)]="year"
                                    (keypress)="allowFour($event)"
                                    (keypress)="keyPressNumbers($event)">
                                </mat-form-field>
                            </div>
                    </div>
                        <div fxFlex="100" fxLayout="row">
                                <div fxFlex="25">
                                    <p class="fontS label">{{language.poNumber}}</p>
                                </div>
                                <div fxFlex="35">
                                    <mat-form-field class="example-full-width input" appearance="outline">
                                        <!-- <mat-label>PO Number</mat-label> -->
                                        <input matInput placeholder="{{language.EnterPONumberFrom}}" 
                                        formControlName="purchaseOrderNoFrom"
                                        [(ngModel)]="poNoFrom">
                                      </mat-form-field>
                                </div>
                                <div fxFlex="5"><p class="fontS to">-</p></div>
                                <div fxFlex="35">
                                        <mat-form-field  class="example-full-width input" appearance="outline">
                                            <!-- <mat-label>To</mat-label> -->
                                            <input  matInput placeholder="{{language.EnterPONumberTo}}"
                                            formControlName="purchaseOrderNoTo"
                                            [(ngModel)]="poNoTo">
                                          </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex="100" fxLayout="row" [formGroup]="dnRange">
                                        <div fxFlex="25">
                                            <p class="fontS label">{{language.postingDate}}</p>
                                        </div>
                                        <div fxFlex="35" fxFlex.xs="100">
                                            <mat-form-field appearance="outline" class="validations">
                                                <input matInput formControlName="postingDateFrom" (dateChange)="getStartDate($event)"
                                                    [matDatepicker]="pickerStart" [max]="maxDate" readonly />
                                                <mat-datepicker-toggle matSuffix [for]="pickerStart">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #pickerStart></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                        <div fxFlex="5">
                                            <p class="fontS to">-</p>
                                        </div>
                                        <div fxFlex="35" fxFlex.xs="100">
                                            <mat-form-field appearance="outline" class="validations">
                                                <input matInput formControlName="postingDateTo" [disabled]="isStartDate" [max]="maxDate"
                                                    (dateChange)="getEndDate($event)" [matDatepicker]="pickerEnd" readonly />
                                                <mat-datepicker-toggle matSuffix [for]="pickerEnd">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #pickerEnd></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                        </div>
            </div>
        
            <div class="rightBox" fxFlex="50" fxLayout="column">
                    <div fxFlex="100" fxLayout="row">
                            <div fxFlex="25">
                                <p class="fontS label">{{language.invoiceFor}}</p>
                            </div>
                            <div fxFlex="50">
                            <mat-form-field class="example-full-width input"appearance="outline">
                                    <mat-select appearance="outline" [(ngModel)]="InvoiceFor" formControlName="InvoiceFor" (valueChange)="onInvoiceForChange($event)">
                                      <mat-option *ngFor="let item of potypes" [value]="item.key">{{item.value}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                            </div>
                    </div>
                    <div fxFlex="100" fxLayout="row">
                            <div fxFlex="25">
                                <p class="fontS label">{{language.typeOfInvoice}}</p>
                            </div>
                            <div fxFlex="50">
                            <mat-form-field class="example-full-width input"appearance="outline">
                                    <mat-select appearance="outline" [(ngModel)]="typeOfInvoice" formControlName="typeOfInvoice" (valueChange)="ontypeOfInvoiceChange($event)">
                                      <mat-option *ngFor="let item of invoicetypes" [value]="item.key">{{item.value}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                            </div>
                    </div>
            <div fxFlex="100" fxLayout="row">
                    <div fxFlex="25">
                        <p class="fontS label">{{language.VendorName}}</p>
                    </div>
                    <div fxFlex="75">
                        <mat-form-field class="example-full-width input" appearance="outline">
                            <!-- <mat-label>Vendor Name</mat-label> -->
                            <input matInput placeholder="{{language.EnterVendorName}}" 
                            formControlName="vendorName"
                            [(ngModel)]="vName">
                          </mat-form-field>
                    </div>
                </div>
                <div fxFlex="100" fxLayout="row">
                        <div fxFlex="25">
                            <p class="fontS label">{{language.VendorCode}}</p>
                        </div>
                        <div fxFlex="35">
                            <mat-form-field  class="example-full-width" appearance="outline">
                                <!-- <mat-label>From</mat-label> -->
                                <input class="input" matInput placeholder="{{language.CodeFrom}}"
                                  formControlName="vendorCodeFrom" 
                                  [(ngModel)]="vendorCFrom">
                              </mat-form-field>
                        </div>
                        <div fxFlex="5"><p class="fontS to">-</p></div>
                        <div fxFlex="35">
                            <mat-form-field  class="example-full-width input" appearance="outline">
                                <!-- <mat-label>To</mat-label> -->
                                <input  matInput placeholder="{{language.ToCode}}"
                                formControlName="vendorCodeTo"
                                [(ngModel)]="vendorCTo">
                              </mat-form-field>
                        </div>
                        </div>
                <div fxFlex="100" fxLayout="row" [formGroup]="invoiceDateRange">
                        <div fxFlex="25">
                            <p class="fontS label">{{language.AddinvoiceDate}}</p>
                        </div>
                        <div fxFlex="35" fxFlex.xs="100">
                                <mat-form-field appearance="outline" class="validations">
                                    <input matInput formControlName="invoiceDateFrom" (dateChange)="getInvoiceStartDate($event)"
                                        [matDatepicker]="pickerStart3" [max]="maxDate" readonly />
                                    <mat-datepicker-toggle matSuffix [for]="pickerStart3">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickerStart3></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div fxFlex="5">
                                <p class="fontS to">-</p>
                            </div>
                            <div fxFlex="35" fxFlex.xs="100">
                                <mat-form-field appearance="outline" class="validations">
                                    <input matInput formControlName="invoiceDateTo" [disabled]="isInvoiceStartDate" [max]="maxDate"
                                        (dateChange)="getInvoiceEndDate($event)" [matDatepicker]="pickerEnd4" readonly />
                                    <mat-datepicker-toggle matSuffix [for]="pickerEnd4">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #pickerEnd4></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex="100" fxLayout="row" [formGroup]="netDueDateRange">
                                <div fxFlex="25">
                                    <p class="fontS label">{{language.netDueDates}}</p>
                                </div>
                                <div fxFlex="35" fxFlex.xs="100">
                                    <mat-form-field appearance="outline" class="validations">
                                        <input matInput formControlName="netDueDateFrom" (dateChange)="getnetDateFrom($event)"
                                            [matDatepicker]="netpickerStart" [max]="maxDate" readonly />
                                        <mat-datepicker-toggle matSuffix [for]="netpickerStart">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #netpickerStart></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="5">
                                    <p class="fontS to">-</p>
                                </div>
                                <div fxFlex="35" fxFlex.xs="100">
                                    <mat-form-field appearance="outline" class="validations">
                                        <input matInput formControlName="netDueDateTo"  [max]="maxDate" (dateChange)="getnetDueDateTo($event)"
                                        [disabled]="isNetDuedate"  [matDatepicker]="netpickerEnd" readonly />
                                        <mat-datepicker-toggle matSuffix [for]="netpickerEnd">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #netpickerEnd></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                </div>
                <div fxFlex="100" fxLayout="row">
                    <div fxFlex="25">
                        <p class="fontS label">{{language.InvoiceNumber}}</p>
                    </div>
                    <div fxFlex="50">
                        <mat-form-field class="example-full-width input" appearance="outline">
                            <!-- <mat-label>From</mat-label> -->
                            <input matInput placeholder="{{language.EnterInvoiceNumber}}" 
                            formControlName="invoiceNoFrom"
                            [(ngModel)]="invoiceFrom">
                          </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </form>
   
    <div>
        <div class="buttonGroup">
            <button mat-flat-button (click)="onSubmit()">{{language.Search}}</button>
            <button mat-flat-button (click)="onClear()" >{{language.Clear}}</button>
            
        </div>
    </div>
</div>        
    </mat-card-content>
</mat-card>
<mat-card class="reportTable">
    <mat-card-content>
            <div fxLayout="row" fxLayout.xs="column">
                    <div>
                            <p class="fontS label" style="margin-left:15px">Found:&nbsp;{{listLength == undefined?0:listLength}}&nbsp;Records</p>
                        </div>
                <div fxFlex.gt-lg="80" fxFlex.gt-md="80" fxFlex.gt-xs="100" fxFlex="100"></div>       
                <!-- <div fxFlex="10" fxFlex.sm="10" fxFlex.xs="100">           
                    <div class="addUserParent">           
                            <a (click)="openPDF()" style="cursor:pointer"><img src="assets/images/icons8-export-pdf-100.png" height="50px" width="50px" ></a>         
                    </div>          
                </div>            -->
                <div fxFlex="7" fxFlex.sm="7" fxFlex.xs="100">       
                   <div class="addUserParent">          
                        <a (click)="exportexcel()" style="cursor:pointer"><img src="assets/images/icons8-export-excel-100.png" height="50px" width="50px" ></a>           
                    </div>           
                </div>          
            </div>
        <div class="overflow">
            <mat-card>
                <mat-table [dataSource]="dataSource"  matSort multiTemplateDataRows id="matTable" 
                    class=" full-width mat-elevation-z5 main-table reportTable apReportTable">

                    <!-- <span > -->
                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef class="status-w" mat-sort-header>
                                {{language.status}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="Status :" class="status-w">
                               {{(element.status == null || element.status == undefined)? '-' : element.status}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="company">
                            <mat-header-cell *matHeaderCellDef class="table-header shortText1-w"  mat-sort-header>
                                {{language.company}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="shortText1-w" data-label="Company :">
                             {{(element.company == null || element.company == undefined)? '-' : element.company}}
                            </mat-cell>
                        </ng-container>                                    
                    <ng-container matColumnDef="vendorCode">
                        <mat-header-cell *matHeaderCellDef class="vendorCode-w" mat-sort-header>
                            {{language.VendorCode}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="vendorCode-w" data-label="Vendor Code :">
                           {{(element.vendorCode == null || element.vendorCode == undefined)? '-' : element.vendorCode}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="vendorName">
                        <mat-header-cell *matHeaderCellDef class="vendorName-w" mat-sort-header>
                            {{language.VendorName}} 
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="vendorName-w" data-label="Vendor Name :">
                           {{(element.vendorName == null || element.vendorName == undefined)? '-' : element.vendorName}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="documentNumber">
                        <mat-header-cell *matHeaderCellDef class="companyName-w" mat-sort-header>
                            {{language.DOC}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="DOC.# :"  class="companyName-w">
                           {{(element.documentNumber == null || element.documentNumber == undefined)? '-' : element.documentNumber}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="invoiceNumber">
                        <mat-header-cell *matHeaderCellDef class="invoiceDate-w" mat-sort-header>
                            {{language.Invoice}} 
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="PO Number :" class="invoiceDate-w">
                           {{(element.invoiceNumber == null || element.invoiceNumber == undefined)? '-' : element.invoiceNumber}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="invoiceDate">
                        <mat-header-cell *matHeaderCellDef class="invoiceDate-w" mat-sort-header>
                            {{language.invoiceDate}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="PO Number :" class="invoiceDate-w">
                            {{(element.invoiceDate == null || element.invoiceDate == undefined)? '-' : element.invoiceDate | date:"dd/MM/yyyy"}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="postingDate">
                        <mat-header-cell *matHeaderCellDef class="invoiceDate-w" mat-sort-header>
                            {{language.postingDate}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="POSTING DATE :" class="invoiceDate-w">
                          {{(element.documentNumber == null || element.documentNumber == undefined)? '-' : element.postingDate | date:"dd/MM/yyyy"}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                            <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header>
                                {{language.amount}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="POSTING DATE :" class="totalAmt-w">
                              {{(element.amount == null || element.amount == undefined)? '-' : element.amount | number : '1.2-2'}}
                            </mat-cell>
                        </ng-container>
                    <ng-container matColumnDef="totalAmount">
                        <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header>
                            {{language.totalAmount}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="Total Amount :" class="totalAmt-w">
                            {{(element.totalAmount == null || element.totalAmount == undefined)? '-' : element.totalAmount | number : '1.2-2'}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="currency">
                        <mat-header-cell *matHeaderCellDef class="currency-w" mat-sort-header> 
                            {{language.Currency}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="Currency :" class="currency-w" >
                            {{(element.currency == null || element.currency == undefined)? '-' : element.currency}}
                        </mat-cell>
                    </ng-container>   
                    <ng-container matColumnDef="taxCode">
                        <mat-header-cell *matHeaderCellDef class="taxType-w" mat-sort-header> 
                            {{language.taxType}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="Currency :" class="taxType-w" >
                            {{(element.taxCode == null || element.taxCode == undefined)? '-' : element.taxCode}}
                        </mat-cell>
                    </ng-container>   
                    <ng-container matColumnDef="paymentTerms">
                        <mat-header-cell *matHeaderCellDef class="paymentTerms-w" mat-sort-header> 
                            {{language.PaymentTerm}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="paymentTerms-w" data-label="Payment Terms :">
                            {{(element.paymentTerms == null || element.paymentTerms == undefined)? '-' : element.paymentTerms}}
                        </mat-cell>
                    </ng-container>   
                    <ng-container matColumnDef="netDueDate">
                        <mat-header-cell *matHeaderCellDef class="invoiceDate-w" mat-sort-header>
                            {{language.netDueDate}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="PO Number :" class="invoiceDate-w">
                           {{(element.netDueDate == null || element.netDueDate == undefined)? '-' : element.netDueDate | date:"dd/MM/yyyy"}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="createdDate">
                        <mat-header-cell *matHeaderCellDef class="invoiceDate-w" mat-sort-header>
                            {{language.AddinvoiceDate}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="PO Number :" class="invoiceDate-w">
                            {{(element.createdDate == null || element.createdDate == undefined || element.createdDate == '-')? '-' : element.createdDate | date:"dd/MM/yyyy"}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="apCheckerUsername">
                            <mat-header-cell *matHeaderCellDef class="materialDocNum-w" mat-sort-header>
                                {{language.apCheckerUsername}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="materialDocNum-w" data-label="PAYMENT DOC. :">
                                {{(element.apCheckerUsername == null || element.apCheckerUsername == undefined)? '-' : element.apCheckerUsername}}
                            </mat-cell>
                        </ng-container>
                    <ng-container matColumnDef="apCheckerAcceptedDate">
                        <mat-header-cell *matHeaderCellDef class="materialDocNum-w" mat-sort-header>
                            {{language.ApcheckerDate}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="materialDocNum-w" data-label="PAYMENT DOC. :">
                            {{(element.apCheckerAcceptedDate == null || element.apCheckerAcceptedDate == undefined)? '-' : element.apCheckerAcceptedDate | date:"dd/MM/yyyy"}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="paymentMethods">
                        <mat-header-cell *matHeaderCellDef class="paymentMethod-w" mat-sort-header>
                            {{language.paymentMethod}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element"  data-label="PAYMENT METHODS :" class="paymentMethod-w">
                           {{(element.paymentMethods == null || element.paymentMethods == undefined)? '-' : element.paymentMethods}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="whtType1">
                        <mat-header-cell *matHeaderCellDef class="whtType-w" mat-sort-header>
                            {{language.WHTTYPE1}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="whtType-w" data-label="WHT TYPE 1 :">
                           {{(element.whtType1 == null || element.whtType1 == undefined)? '-' : element.whtType1}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="whtCode1">
                        <mat-header-cell *matHeaderCellDef class="whtCode-w" mat-sort-header>
                            {{language.WHTCODE1}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="whtCode-w" data-label="WHT CODE 1 :">
                           {{(element.whtCode1 == null || element.whtCode1 == undefined)? '-' : element.whtCode1}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="whtBaseAmount1">
                        <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header>
                            {{language.WHTBASEAmt1}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="totalAmt-w" data-label="WHT BASE AMOUNT 1 :">
                           {{(element.whtBaseAmount1 == null || element.whtBaseAmount1 == undefined)? '-' : element.whtBaseAmount1 | number : '1.2-2'}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="whtTaxAmount1">
                        <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header>
                            {{language.WHTAmt1}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="totalAmt-w" data-label="WHT Amt 1 :">
                           {{(element.whtTaxAmount1 == null || element.whtTaxAmount1 == undefined)? '-' : element.whtTaxAmount1 | number : '1.2-2'}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="whtType2">
                        <mat-header-cell *matHeaderCellDef class="whtType-w" mat-sort-header>
                           {{language.WHTTYPE2}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="whtType-w" data-label="WHT TYPE 2 :">
                           {{(element.whtType2 == null || element.whtType2 == undefined)? '-' : element.whtType2}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="whtCode2">
                        <mat-header-cell *matHeaderCellDef class="whtCode-w" mat-sort-header>
                           {{language.WHTCODE2}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="whtCode-w" data-label="WHT CODE 2 :">
                           {{(element.whtCode2 == null || element.whtCode2 == undefined)? '-' : element.whtCode2}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="whtBaseAmount2">
                        <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header>
                            {{language.WHTBASEAmt2}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="totalAmt-w" data-label="WHT BASE AMOUNT 2 :">
                           {{(element.whtBaseAmount2 == null || element.whtBaseAmount2 == undefined)? '-' : element.whtBaseAmount2 | number : '1.2-2'}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="whtTaxAmount2">
                        <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header>
                            {{language.WHTAmt2}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="totalAmt-w" data-label="WHT Amt 2 :">
                           {{(element.whtTaxAmount2 == null || element.whtTaxAmount2 == undefined)? '-' : element.whtTaxAmount2 | number : '1.2-2'}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="vpNumber">
                        <mat-header-cell *matHeaderCellDef class="invoiceNoVP-w" mat-sort-header>
                            {{language.vp}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label=" VP# :" class="invoiceNoVP-w">
                            {{(element.vpNumber == null || element.vpNumber == undefined)? '-' : element.vpNumber.substring(element.vpNumber.indexOf('_')+1)}}
                        </mat-cell>
                    </ng-container>                 
                    <ng-container matColumnDef="poNumber">
                        <mat-header-cell *matHeaderCellDef class="poNo-w" mat-sort-header>
                            {{language.po}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="PO#:" class="poNo-w">
                            {{(element.poNumber == null || element.poNumber == undefined)? '-' : element.poNumber}}
                        </mat-cell>
                    </ng-container>                 
                    <ng-container matColumnDef="approvedBy">
                        <mat-header-cell *matHeaderCellDef class="sapUser-w" mat-sort-header>
                            {{language.SAPAPUSER}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="SAP AP USER" class="sapUser-w">
                                {{ element.approvedBy ? element.approvedBy:'-' }}
                        </mat-cell>
                    </ng-container>                 
                    <ng-container matColumnDef="invoiceFor">
                        <mat-header-cell *matHeaderCellDef class="companyName-w" mat-sort-header>
                            {{language.invoiceFor}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="companyName-w" data-label="WHT TYPE 1 :">
                           {{(element.invoiceFor == null || element.invoiceFor == undefined)? '-' : element.invoiceFor}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="typeOfInvoice">
                        <mat-header-cell *matHeaderCellDef class="whtCode-w" mat-sort-header>
                            {{language.typeOfInvoice}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="whtCode-w" data-label="WHT CODE 1 :">
                           {{(element.typeOfInvoice == null || element.typeOfInvoice == undefined)? '-' : element.typeOfInvoice}}
                        </mat-cell>
                    </ng-container>
                               
                   <mat-header-row *matHeaderRowDef="columnsToDisplay">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: columnsToDisplay;">{{language.Pending}}</mat-row>

                </mat-table>

            </mat-card>
        </div>
        <mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" [pageSize]="pageSize"
        [length]="listLength"
        [pageSizeOptions]="[10, 25, 50, 100]" (page)="setTableData($event)"
        style="width:100%"></mat-paginator>
    </mat-card-content>
</mat-card>
<div class="overlay" *ngIf="mySpinner">
    <div class="center">
        <mat-spinner></mat-spinner>
    </div>
</div>
