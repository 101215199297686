<mat-card>
    <mat-card-title>
        <div class="margin-bottotm" fxLayout="row" fxFlex="100">
            <div class="pull-left title" fxFlex="80">
                {{language.Head}}
            </div>
            <!-- <div class="pull-right" fxFlex="20">
                <mat-icon  class="close1">
                    close
                </mat-icon>
            </div> -->
        </div>
    </mat-card-title>
    <hr>

    <mat-card-content>
<div fxFlex="100"  fxLayoutWrap="column">
        
    <form  [formGroup]="checkerReportForm">
        <div fxLayout="row">
            <div class="leftBox"  fxFlex="50" fxLayout="column">
            <div fxFlex="100" fxLayout="row">
            <div fxFlex="25">
                <p class="fontS label">{{language.company}}</p>
            </div>
            <div fxFlex="75">
                <ng-multiselect-dropdown formControlName="multiSelectCompany" placeholder="{{language.selectCompany}}"
                    [settings]="dropdownSettings" [data]="companyMaster" (onSelect)="onSelect($event)" (onSelectAll)="onSelectAll($event)"
                    (onDeSelect)="onDeSelect($event)" (onDeSelectAll)="onDeSelectAll($event)">
                </ng-multiselect-dropdown>
            </div>
            </div>
            <div fxFlex="100" fxLayout="row">
            <div fxFlex="25">
                <p class="fontS label">{{language.VendorCode}}</p>
            </div>
            <div fxFlex="35">
                <mat-form-field  class="example-full-width" appearance="outline">
                    <!-- <mat-label>From</mat-label> -->
                    <input class="input" matInput 
                    placeholder="{{language.CodeFrom}}"  
                    formControlName="vendorCodeFrom"
                    [(ngModel)]="vendorCFrom">
                  </mat-form-field>
            </div>
            <div fxFlex="5"><p class="fontS to">-</p></div>
            <div fxFlex="35">
                <mat-form-field  class="example-full-width input" appearance="outline">
                    <!-- <mat-label>To</mat-label> -->
                    <input  matInput 
                    placeholder="{{language.ToCode}}"
                    (keyup)="onChangeOfVendorCode($event)"
                    formControlName="vendorCodeTo"
                    [(ngModel)]="vendorCTo">
                  </mat-form-field>
            </div>
            </div>
            <div fxFlex="100" fxLayout="row" [formGroup]="dnRange" >
            <div fxFlex="25">
                <p class="fontS label">{{language.invoiceDate}}</p>
            </div>
            <!-- <div fxFlex="75">
                <mat-form-field class="example-full-width input" appearance="outline">                     -->
                    <!-- <input matInput placeholder="From Date" formControlName="invoiceDateFrom" > -->
                    <!-- <mat-label>{{language.dateRange}}</mat-label>
                    <mat-date-range-input [formGroup]="dnRange" [rangePicker]="picker" [max]="maxDate">
                      <input #invoiceFromD matStartDate formControlName="invoiceDateFrom"
                       placeholder="{{language.Startdate}}">
                      <input #invoiceToD matEndDate formControlName="invoiceDateTo" 
                      placeholder="{{language.Enddate}}">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  </mat-form-field>
            </div> -->
            <!-- <div fxFlex="5"><p class="fontS to">-</p></div>
            <div fxFlex="35">
                <mat-form-field  class="example-full-width input" appearance="outline">                
                    <input  matInput placeholder="To Date" formControlName="invoiceDateTo">
                  </mat-form-field>
            </div> -->
            <div fxFlex="35" fxFlex.xs="100">
                <mat-form-field appearance="outline" class="validations">
                    <input matInput formControlName="invoiceDateFrom" (dateChange)="getInvoiceStartDate($event)"
                        [matDatepicker]="inPickerStart" [max]="maxDate" readonly />
                    <mat-datepicker-toggle matSuffix [for]="inPickerStart">
                    </mat-datepicker-toggle>
                    <mat-datepicker #inPickerStart></mat-datepicker>
                </mat-form-field>
            </div>
            <div fxFlex="5">
                <p class="fontS to">-</p>
            </div>
            <div fxFlex="35" fxFlex.xs="100">
                <mat-form-field appearance="outline" class="validations">
                    <input matInput formControlName="invoiceDateTo" [disabled]="isInvoiceEndDate" [max]="maxDate"
                        (dateChange)="getInvoiceEndDate($event)" [matDatepicker]="inPickerEnd" readonly />
                    <mat-datepicker-toggle matSuffix [for]="inPickerEnd">
                    </mat-datepicker-toggle>
                    <mat-datepicker #inPickerEnd></mat-datepicker>
                </mat-form-field>
            </div>
            </div>
            <div fxFlex="100" fxLayout="row">
                <div fxFlex="25">
                    <p class="fontS label">{{language.status}}</p>
                </div>
                <div fxFlex="50">
                        <ng-multiselect-dropdown formControlName="multiSelectStatus" placeholder="{{language.SelectStatus}}"
                            [settings]="dropdownSettings1" [data]="invoiceStatus" (onSelect)="onSelect1($event)"
                            (onDeSelect)="onDeSelect1($event)">
                        </ng-multiselect-dropdown>
                </div>
            </div>
            <div fxFlex="100" fxLayout="row">
            <div fxFlex="25">
                <p class="fontS label">{{language.VPNumber}}</p>
            </div>
            <div fxFlex="50">
                <mat-form-field class="example-full-width input" appearance="outline">
                    <!-- <mat-label>VP Number</mat-label> -->
                    <input matInput 
                    placeholder="{{language.EnterVPNumber}}" 
                    formControlName="vendorPortalRefNo"
                    [(ngModel)]="vpNo">
                  </mat-form-field>
                </div>
            </div>
            </div>
        
            <div class="rightBox" fxFlex="50" fxLayout="column">
            <div fxFlex="100" fxLayout="row">
                    <div fxFlex="25">
                        <p class="fontS label">{{language.VendorName}}</p>
                    </div>
                    <div fxFlex="75">
                        <mat-form-field class="example-full-width input" appearance="outline">
                            <!-- <mat-label>Vendor Name</mat-label> -->
                            <input matInput 
                            placeholder="{{language.EnterVendorName}}" 
                            formControlName="vendorName"
                            [(ngModel)]="vName">
                          </mat-form-field>
                    </div>
                </div>
            <div fxFlex="100" fxLayout="row" [formGroup]="eDRange">
                <div fxFlex="25">
                    <p class="fontS label">{{language.EntryDate}}</p>
                </div>
                <!-- <div fxFlex="75">
                    <mat-form-field class="example-full-width input" appearance="outline">                        
                        <!-- <input matInput placeholder="From Date" formControlName="entryDateFrom"> -->
                       <!-- <mat-label>{{language.dateRange}}</mat-label>
                        <mat-date-range-input [formGroup]="eDRange" [rangePicker]="picker1" [max]="maxDate">
                          <input #entryFromD matStartDate formControlName="entryDateFrom" placeholder="{{language.Startdate}}">
                          <input #entryToD matEndDate formControlName="entryDateTo" placeholder="{{language.Enddate}}">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker1></mat-date-range-picker>
                      </mat-form-field>
                </div> -->
                <div fxFlex="35" fxFlex.xs="100">
                    <mat-form-field appearance="outline" class="validations">
                        <input matInput formControlName="entryDateFrom" (dateChange)="getEntryStartDate($event)"
                            [matDatepicker]="pickerStart" [max]="maxDate" readonly />
                        <mat-datepicker-toggle matSuffix [for]="pickerStart">
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerStart></mat-datepicker>
                    </mat-form-field>
                </div>
                <div fxFlex="5">
                    <p class="fontS to">-</p>
                </div>
                <div fxFlex="35" fxFlex.xs="100">
                    <mat-form-field appearance="outline" class="validations">
                        <input matInput formControlName="entryDateTo" [disabled]="isEntryEndDate" [max]="maxDate"
                            (dateChange)="getEntryEndDate($event)" [matDatepicker]="pickerEnd" readonly />
                        <mat-datepicker-toggle matSuffix [for]="pickerEnd">
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerEnd></mat-datepicker>
                    </mat-form-field>
                </div>
                <!-- <div fxFlex="5"><p class="fontS to">-</p></div>
                <div fxFlex="35">
                    <mat-form-field  class="example-full-width input" appearance="outline">
                         <input  matInput placeholder="To Date" formControlName="entryDateTo">
                      </mat-form-field>
                </div> -->
            </div>
            <div fxFlex="100" fxLayout="row">
                <div fxFlex="25">
                    <p class="fontS label">{{language.poNumber}}</p>
                </div>
                <div fxFlex="50">
                    <mat-form-field class="example-full-width input" appearance="outline">
                        <!-- <mat-label>PO Number</mat-label> -->
                        <input matInput 
                        placeholder="{{language.EnterPONumber}}" 
                        formControlName="purchaseOrderNo"
                        [(ngModel)]="poNo">
                      </mat-form-field>
                </div>
                </div>
                <div fxFlex="100" fxLayout="row">
                    <div fxFlex="25">
                        <p class="fontS label">{{language.InvoiceNumber}}</p>
                    </div>
                    <div fxFlex="35">
                        <mat-form-field class="example-full-width input" appearance="outline">
                            <!-- <mat-label>From</mat-label> -->
                            <input matInput 
                            placeholder="{{language.NumberFrom}}" 
                            formControlName="invoiceNoFrom"
                            [(ngModel)]="invoiceFrom">
                          </mat-form-field>
                    </div>
                    <div fxFlex="5"><p class="fontS to">-</p></div>
                    <div fxFlex="35">
                        <mat-form-field  class="example-full-width input" appearance="outline">
                            <!-- <mat-label>To</mat-label> -->
                            <input  matInput 
                            placeholder="{{language.ToNumber}}"
                            (keyup)="onChangeOfInvoiceNumber($event)"
                            formControlName="invoiceNoTo"
                            [(ngModel)]="invoiceTo">
                          </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </form>
   
    <div>
        <div class="buttonGroup">
            <button mat-flat-button (click)="onSubmit()">{{language.Search}}</button>
        <button mat-flat-button (click)="onClear()">{{language.Clear}}</button>
        </div>
    </div>
</div>        
    </mat-card-content>
</mat-card>
<mat-card  class="reportTable">
    <mat-card-content>
            <div fxLayout="row" fxLayout.xs="column">
                    <div fxFlex.gt-lg="80" fxFlex.gt-md="80" fxFlex.gt-xs="100" fxFlex="100"></div>       
                    <div fxFlex="10" fxFlex.sm="10" fxFlex.xs="100">           
                        <div class="addUserParent">           
                                <a (click)="openPDF()" style="cursor:pointer"><img src="assets/images/icons8-export-pdf-100.png" height="50px" width="50px" ></a>         
                        </div>          
                    </div>           
                    <div fxFlex="7" fxFlex.sm="7" fxFlex.xs="100">       
                       <div class="addUserParent">          
                            <a (click)="exportexcel()" style="cursor:pointer"><img src="assets/images/icons8-export-excel-100.png" height="50px" width="50px" ></a>           
                        </div>           
                    </div>          
                </div>
        <div class="overflow">
            <mat-card>
                <mat-table  [dataSource]="dataSource" id="matTable" matSort multiTemplateDataRows
                    class=" full-width mat-elevation-z5 main-table reportTable checkerReportTable">
                    <!-- <span > -->
                        <ng-container matColumnDef="status">
                            <mat-header-cell *matHeaderCellDef class="status-w" mat-sort-header>
                                {{language.status}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" data-label="Status :" class="status-w">
                              {{(element.status == null || element.status == undefined)? '-' : element.status}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="company">
                            <mat-header-cell *matHeaderCellDef class="companyName-w"  mat-sort-header>
                                {{language.company}}
                             </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="companyName-w" data-label="Company :">
                             {{(element.company == null || element.company == undefined)? '-' : element.company}}
                            </mat-cell>
                        </ng-container>                                    
                    <ng-container matColumnDef="vendorCode">
                        <mat-header-cell *matHeaderCellDef class="vendorCode-w" mat-sort-header>
                            {{language.VendorCode}} 
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="vendorCode-w" data-label="Vendor Code :">
                            {{(element.vendorCode == null || element.vendorCode == undefined)? '-' : element.vendorCode}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="vendorName">
                        <mat-header-cell *matHeaderCellDef class="vendorName-w" mat-sort-header>
                            {{language.VendorName}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="vendorName-w" data-label="Vendor Name :">
                           {{(element.vendorName == null || element.vendorName == undefined)? '-' : element.vendorName}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="typeOfInvoice">
                        <mat-header-cell *matHeaderCellDef class="invoiceType-w" mat-sort-header>
                            {{language.typeOfInvoice}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="TYPE OF INVOICE :"  class="invoiceType-w">
                           {{(element.typeOfInvoice == null || element.typeOfInvoice == undefined)? '-' : element.typeOfInvoice}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="entryDate">
                        <mat-header-cell *matHeaderCellDef class="invoiceDate-w" mat-sort-header>
                           {{language.EntryDate}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="ENTRY DATE :" class="invoiceDate-w">
                           {{(element.entryDate == null || element.entryDate == undefined)? '-' : element.entryDate}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="invoiceNo">
                        <mat-header-cell *matHeaderCellDef class="invoiceNo-w" mat-sort-header>
                           {{language.Invoice}} 
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="PO Number :" class="invoiceNo-w">
                           {{(element.invoiceNumber == null || element.invoiceNumber == undefined)? '-' : element.invoiceNumber}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="invoiceFor">
                        <mat-header-cell *matHeaderCellDef class="paymentTerms-w" mat-sort-header> 
                            Invoice For
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="paymentTerms-w" data-label="Payment Terms :">
                            {{(element.invoiceFor == null || element.invoiceFor == undefined)? '-' : element.invoiceFor}}
                        </mat-cell>
                    </ng-container> 
                    <ng-container matColumnDef="invoiceDate">
                        <mat-header-cell *matHeaderCellDef class="invoiceDate-w" mat-sort-header>
                           {{language.invoiceDate}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="PO Number :" class="invoiceDate-w">
                           {{(element.invoiceDate == null || element.invoiceDate == undefined)? '-' : element.invoiceDate}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="totalAmount">
                        <mat-header-cell *matHeaderCellDef class="totalAmt-w" mat-sort-header>
                            {{language.totalAmount}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="Total Amount :" class="totalAmt-w">
                            {{(element.totalAmount == null || element.totalAmount == undefined)? '-' : element.totalAmount}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="currency">
                        <mat-header-cell *matHeaderCellDef class="currency-w" mat-sort-header> 
                            {{language.Currency}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="Currency :" class="currency-w" >
                            {{(element.currency == null || element.currency == undefined)? '-' : element.currency}}
                        </mat-cell>
                    </ng-container>    
                    <ng-container matColumnDef="paymentTerms">
                        <mat-header-cell *matHeaderCellDef class="paymentTerms-w" mat-sort-header> 
                            {{language.PaymentTerm}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="paymentTerms-w" data-label="Payment Terms :">
                            {{(element.paymentTerms == null || element.paymentTerms == undefined)? '-' : element.paymentTerms}}
                        </mat-cell>
                    </ng-container>   
                    <ng-container matColumnDef="netDueDate">
                        <mat-header-cell *matHeaderCellDef class="invoiceDate-w" mat-sort-header>
                            {{language.netDueDate}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="PO Number :" class="invoiceDate-w">
                           {{(element.netDueDate == null || element.netDueDate == undefined)? '-' : element.netDueDate}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="paymentMethod">
                        <mat-header-cell *matHeaderCellDef class="paymentMethod-w" mat-sort-header>
                            {{language.paymentMethod}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="PAYMENT METHODS :" class="paymentMethod-w">
                           {{(element.paymentMethods == null || element.paymentMethods == undefined)? '-' : element.paymentMethods}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="vpNumber">
                        <mat-header-cell *matHeaderCellDef class="invoiceNoVP-w" mat-sort-header>
                            {{language.vp}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label=" VP# :" class="invoiceNoVP-w">
                            {{(element.vpNumber == null || element.vpNumber == undefined)? '-' : element.vpNumber.substring(element.vpNumber.indexOf('_')+1)}}
                        </mat-cell>
                    </ng-container>                 
                    <ng-container matColumnDef="poNumber">
                        <mat-header-cell *matHeaderCellDef class="po-w" mat-sort-header>
                            {{language.po}}
                         </mat-header-cell>
                        <mat-cell *matCellDef="let element" data-label="PO#:" class="po-w">
                            {{(element.poNumber == null || element.poNumber == undefined)? '-' : element.poNumber}}
                        </mat-cell>
                    </ng-container>                      
                   <mat-header-row *matHeaderRowDef="columnsToDisplay">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: columnsToDisplay;">{{language.Pending}}</mat-row>

                </mat-table>
            </mat-card>
        </div>
        <mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" [pageSize]="pageSize"
        [length]="listLength"
        [pageSizeOptions]="[10, 25, 50, 100]" (page)="setTableData($event)"
        style="width:100%"></mat-paginator>
    </mat-card-content>
</mat-card>
<div class="overlay" *ngIf="mySpinner">
    <div class="center">
        <mat-spinner></mat-spinner>
    </div>
</div>