import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DemoMaterialModule } from '../demo-material-module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PuchaseOrderRoutes } from './purchase-order.routing';
import { PurchaseOrderComponent } from './purchase-order.component';
import { PurchaseOrderInfoComponent } from './purchase-order-info/purchase-order-info.component';
import { PoInfoComponent } from './po-info/po-info.component';
import { AddDeliveryNotesComponent } from './add-delivery-notes/add-delivery-notes.component';
import { MatSortModule } from '@angular/material/sort';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PurchaseOrderHistoryComponent } from './purchase-order-history/purchase-order-history.component';
import { VendorInfoComponent } from './vendro-info/vendor-info.component';
import { CommonPipesModule } from '../common-pipes.module';


@NgModule({
  declarations: [
    PurchaseOrderComponent, 
    PurchaseOrderInfoComponent, 
    PoInfoComponent, 
    AddDeliveryNotesComponent, 
    PurchaseOrderHistoryComponent, 
    VendorInfoComponent
  ],
  imports: [
      CommonModule,
      FlexLayoutModule,
      FormsModule,
      ReactiveFormsModule,
      MatSortModule,
      RouterModule.forChild(PuchaseOrderRoutes),
      DemoMaterialModule,
      CommonPipesModule
  ]
  
  })
  export class PurchaseOrderModule {}
  