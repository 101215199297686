<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<!-- <a href="https://wrappixel.com/templates/materialpro-angular-dashboard/" class=" m-r-20 hidden-sm-up" mat-raised-button color="warn">Upgrade To Pro</a> -->
<!-- <button mat-icon-button>ไทย</button> -->
<h6 class="userName" style="margin-right: 5px;font-size: 15px;">{{welcome}} {{userName}}</h6>
<div style="width:40px; height: 30px;overflow-y: hidden;overflow-x: hidden;background-color: #f3f3f3;">
    <div class="pic">
        <div  style="height: 25px;" (click)="otherValue(selectedValue)">
            {{ selectedValue }}
        </div>
        <div >
            {{ langData }}
        </div>
    </div>
</div>
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5"> <img src="assets/images/users/user.jpg" alt="user" class="profile-pic"> </button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <!-- <button mat-menu-item>
        <mat-icon>settings</mat-icon> Settings </button> -->
    <button mat-menu-item (click)="openProfile()">
        <mat-icon>account_box</mat-icon> Profile </button>
    <!-- <button mat-menu-item>
        <mat-icon>notifications_off</mat-icon> Disable  notifications </button> -->
        <button *ngIf="currentUser.roleID == 2" mat-menu-item (click)="privacy()">
            <mat-icon>warning</mat-icon> Privacy Policy </button>
    <button mat-menu-item (click)="signOut()">
        <mat-icon>exit_to_app</mat-icon> Sign Out </button>

        
</mat-menu> 

