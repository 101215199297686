import { BreakpointObserver, MediaMatcher, Breakpoints } from '@angular/cdk/layout';
import {ChangeDetectorRef, Component,OnDestroy,AfterViewInit, OnInit} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs-compat/operator/map';
import { MenuItems } from '../../shared/menu-items/menu-items';


/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit ,OnDestroy, AfterViewInit {
  mobileQuery: MediaQueryList;
  public screenWidth: any;  
  public screenHeight: any;  

  private _mobileQueryListener: () => void;
  isWideScreen$: Observable<boolean>;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    public breakpoint: BreakpointObserver
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  mySpinner:boolean=false;
  break:any;
  userType:any;
  showMenu:boolean= false;
  isdisabled:boolean;
  currentUser :any=[];
  langPref: any;
  ngOnInit(): void {
    let currentUser =  JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
    if(lang == 'en'){
      this.langPref = "Vendor Management Portal"
    }else{
      this.langPref = "พอร์ทัลการจัดการผู้ขาย"
    }
    
    if(this.currentUser.firstTimeLogin == "0"){
        this.showMenu = false;
    }
    else{
      this.showMenu = true;
    }
    
    this.mySpinner=true;
    if (this.breakpoint.isMatched('(min-width: 900px)')){
      this.break = this.breakpoint.isMatched('(min-width: 900px)');
      
    }
    // this.breakpoint.observe('(min-width: 900px)').subscribe(console.info);
  setTimeout(() => {
    this.mySpinner=false; 
  }, 500);
  
  }
  ScreenSizes: boolean = false
  getScreenSize(){
    this.ScreenSizes = true;
    this.screenWidth = window.innerWidth;  
    this.screenHeight = window.innerHeight;
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() {}

  
}
