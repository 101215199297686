<mat-card>
        <mat-card-title>

            <div fxLayout="row" fxFlex.gt-xs="100" fxFlex="100">
                {{headerToDisplay.Configurations}}
            </div>
            <!-- <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
                <button mat-raised-button class="submitButton" (click)="openDialog('Add',{})">Add Config</button>
            </div> -->

        </mat-card-title>
        <hr>
        <mat-card-content>
            <div fxLayout="row" fxLayoutWrap="wrap">
                <!-- Card column -->
                <div fxFlex.gt-sm="100%" fxFlex="100">
                    <!-- <mat-tab-group> -->
                        <!-- <mat-tab label="App Configuration"> -->
                        <!-- <div class="row mx-2 mt-2 my-2 summaryTab mat-elevation-z8  mx-3 mt-3 full-width"> -->
                            <div  fxLayout="row"fxLayout.xs="column" >
                                <div class="search" fxFlex="30" fxFlex.sm="50" fxFlex.xs="100">
                                    <mat-form-field class="mx-3 max-width">
                                     <mat-label>{{headerToDisplay.search}}</mat-label>
                                      <input matInput placeholder="Search" (keyup)="applyFilter1()" #input [(ngModel)]="searchString">
                                   </mat-form-field>
                                   </div>
                                    
                                   <div fxFlex="20" fxFlex.sm="50" fxFlex.xs="100" style="margin-top:15px">
                                        <button (click)="applyFilter()" mat-raised-button class="submitButton btn-primary">{{headerToDisplay.search}}</button>
                                   </div>
                                 <!-- <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100" style="padding-left: 58%; padding-top: 2%;">
                                    <button mat-raised-button class="submitButton addConfig" (click)="openDialog('Add',{})">Add Config</button>
                                </div> -->
                            </div>

                    <div class="overflow">
                        <mat-card>
                                <mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="mat-elevation-z5 main-table ConfigurationTable">
                                    <ng-container matColumnDef="propKey">
                                        <mat-header-cell *matHeaderCellDef class="propKey" mat-sort-header>{{headerToDisplay.propKey}}</mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="propKey" data-label="Property Key"> {{element.propKey}} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="propValue">
                                        <mat-header-cell *matHeaderCellDef class="propValue" mat-sort-header>{{headerToDisplay.propValue}}</mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="propValue" data-label="Property Value"> {{element.propValue}} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="actions">
                                        <mat-header-cell *matHeaderCellDef class="actions-w"> {{headerToDisplay.actions}} </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="actions-w" data-label="Actions">
                                            <!-- <button (click)="openDialog('Update',element)" mat-flat-button class="submitButton"> -->
                                                <!-- <mat-icon aria-hidden="false" class="visibility" aria-label="visibility">visibility</mat-icon> -->
                                                <!-- Update
                                            </button> -->
                                            <!-- <button (click)="openDialog('Update',element)" mat-flat-button class="button-actions mx-2">
                                                <mat-icon aria-hidden="false" class="visibility" aria-label="visibility">visibility</mat-icon>
                                            </button> -->
                                            <mat-icon class="edit" aria-label="edit" (click)="openDialog('Update',element)">edit</mat-icon>
                                        </mat-cell>
                                    </ng-container>
                                    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                                    <mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"></mat-row>
                                </mat-table>                            
                        </mat-card>
                        <mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" [pageSize]="pageSize" [length]="listLength" [pageSizeOptions]="[5, 10, 20]" [pageSizeOptions]="[10, 25, 50, 100]" (page)="getConfig($event, '')"  style="width:100%"></mat-paginator> 
                    </div>
                        <!-- <mat-paginator [pageSizeOptions]="[10, 25, 50, 100, 500, 1000]" style="width:100%">
                        </mat-paginator> -->
                        <!-- </div> -->
                        <!-- </mat-tab>  -->

                        <!-- <mat-tab label="System Configuration">

                            <div  fxLayout="row"fxLayout.xs="column" >
                                <div class="search" fxFlex="30" fxFlex.sm="50" fxFlex.xs="100">
                                    <mat-form-field class="mx-3 max-width">
                                        <mat-label>Search</mat-label>
                                        <input matInput placeholder="Search" #input
                                            (keypress)="applyFilter($event)">
                                    </mat-form-field>
                                </div>
                            </div>

                        <div class="overflow">
                            <mat-card>
                                <mat-table [dataSource]="dataSource1" matSort multiTemplateDataRows class="mat-elevation-z5 main-table ConfigurationTable" style="width: 100%;">
                                    <ng-container matColumnDef="propKey">
                                        <mat-header-cell *matHeaderCellDef class="propKey" mat-sort-header>{{headerToDisplay.propKey}}</mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="propKey" data-label="Property Key"> {{element.propKey}} </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="propValue">
                                        <mat-header-cell *matHeaderCellDef class="propValue" mat-sort-header>{{headerToDisplay.propValue}}</mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="propValue" data-label="Property Value"> {{element.propValue}} </mat-cell>
                                    </ng-container>
                            
                                    <mat-header-row *matHeaderRowDef="columnsToDisplay1"></mat-header-row>
                                    <mat-row *matRowDef="let element; columns: columnsToDisplay1;" class="example-element-row"></mat-row>
                                </mat-table>
                            </mat-card>
                            <mat-paginator #paginator="matPaginator" [pageIndex]="pageIndex" [pageSize]="pageSize" [length]="listLength1" [pageSizeOptions]="[5, 10, 20]" [pageSizeOptions]="[10, 25, 50, 100, 500, 1000]" (page)="getConfig($event)"  style="width:100%"></mat-paginator> 
                        </div>  
                        </mat-tab>  -->
                    <!-- </mat-tab-group> -->
                </div>
            </div>
            <div class="overlay"  *ngIf="mySpinner">
                <div class="center">
                    <mat-spinner></mat-spinner> 
                </div>
                </div>
        </mat-card-content>
    </mat-card>