import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { CommonServicesService } from 'src/app/services/common-services.service';
import { first } from 'rxjs/operators';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { TableUtil } from 'src/app/reports/TableUtil';

@Component({
  selector: 'app-checker-report',
  templateUrl: './checker-report.component.html',
  styleUrls: ['./checker-report.component.css']
})
export class CheckerReportComponent implements OnInit {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  requestData:any;
  dnRange = new FormGroup({
    invoiceDateFrom: new FormControl(''),
    invoiceDateTo: new FormControl(''),
  });

  eDRange = new FormGroup({
    entryDateFrom: new FormControl(''),
    entryDateTo: new FormControl(''),
  });

  @ViewChild('invoiceFromD') invoiceFromD: ElementRef;
  @ViewChild('invoiceToD') invoiceToD: ElementRef;
  @ViewChild('entryFromD') entryFromD: ElementRef;
  @ViewChild('entryToD') entryToD: ElementRef;
  @ViewChild('matTable') matTable!: ElementRef;
  table: boolean = false;
  dropdownSettings = {};
  dropdownSettings1 = {};
  columnsToDisplay:any=[];
  dataSource:MatTableDataSource<any>;

  constructor(
    private fb: FormBuilder,  
    private datepipe: DatePipe,
    private cService: CommonServicesService
    )
     { }
     async openPDF() {
      this.mySpinner = true;
      (await this.cService.getexportPDFdata(this.requestData)).pipe(first()).subscribe(res => {
        if(res.response != null){
          if (res.responseStatus.toUpperCase() == "SUCCESS") {
            
            this.table = true;
            this.mySpinner = false;
          } else {
            this.mySpinner = false;
            Swal.fire(res.responseMessage,"","error");
          }
        }else{
          this.mySpinner = false;
          Swal.fire(res.responseMessage,"","error");
        }
      const doc = new jsPDF('l', 'pt', "a4");
      doc.setFontSize(5);
      var col = ["Status","Company","Vendor Code","Vendor Name","TypeOfInvoice","Entry Date","Invoice#","Invoice Date","Total Amount","Currency","Payment Method","Payment Terms","Net Due Date","vp#","po#","Invoice For"];
      var rows = [];
  
      
      res.response.forEach(x => {      
          var temp = [
              x.status,
              x.company,
              x.vendorCode,
              x.vendorName,
              x.typeOfInvoice,
              x.entryDate,
              x.invoiceNumber,
              x.invoiceDate,
              x.totalAmount,
              x.currency,
              x.paymentMethods,
              x.paymentTerms,
              x.netDueDate,
              x.vpNumber.substring(x.vpNumber.indexOf('_')+1),
              x.poNumber,
              x.invoiceFor,
          ];
          rows.push(temp);
  
      });        
  
      (doc as any).autoTable(col, rows,{
        startY: false,
        theme: 'grid',
        tableWidth: 'auto',
        cellWidth: 'wrap',
        showHead: 'everyPage',
        tableLineColor: 200,
        tableLineWidth: 0,
        columnStyles: {
            0: {
                cellWidth: 50
            },
            1: {
                cellWidth: 60
            },
            2: {
                cellWidth: 50
            },
            3: {
                cellWidth: 60
            },
            4: {
                cellWidth: 50
            },
            5: {
                cellWidth: 50
            },
            6: {
                cellWidth: 50
            },
            7: {
                cellWidth: 50
            },
            8: {
                cellWidth: 50
            },
            9: {
                cellWidth: 50
            },
            10: {
                cellWidth: 50
            },
            11: {
                cellWidth: 50
            },
            12: {
                cellWidth: 50
            },
            13: {
                cellWidth: 50
            },
            14: {
                cellWidth: 50
            },
            15: {
              cellWidth: 50
          }
        },
        headStyles: {
            theme: 'grid',
            fillColor : [237, 28, 36]
        },
        margins :{
          top: 10,
          bottom: 10,
          left: 10,
          width: 522
        },
        styles: {
            overflow: 'linebreak',
            cellWidth: 'wrap',
            // font: 'arial',
            fontSize: 7,
            // cellPadding: 2,
            overflowColumns: 'linebreak'
        },
    });
      doc.save('Ap-Checker-Report.pdf');
    });
    }

    async exportexcel() {
    this.mySpinner = true;
    (await this.cService.getexportPDFdata(this.requestData)).pipe(first()).subscribe(res => {
      if(res.response != null){
        if (res.responseStatus.toUpperCase() == "SUCCESS") {
          
          this.table = true;
          this.mySpinner = false;
        } else {
          this.mySpinner = false;
          Swal.fire(res.responseMessage,"","error");
        }
      }else{
        this.mySpinner = false;
        Swal.fire(res.responseMessage,"","error");
      }
  const onlyNameAndSymbolArr: Partial<PeriodicElement>[] = res.response.map(
    x => ({
      status:x.status,
      company:x.company,
      vendorCode:x.vendorCode,
      vendorName:x.vendorName,
      typeOfInvoice:x.typeOfInvoice,
      entryDate:x.entryDate,
      invoiceNumber:x.invoiceNumber,
      invoiceDate:x.invoiceDate,
      totalAmount:x.totalAmount,
      currency:x.currency,
      paymentMethods:x.paymentMethods,
      paymentTerms:x.paymentTerms,
      netDueDate:x.netDueDate,
      vpNumber:x.vpNumber.substring(x.vpNumber.indexOf('_')+1),
      poNumber:x.poNumber,
      invoiceFor:x.invoiceFor
    })
  )

  TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "Ap-Checker-Report");
})
}
  checkerReportForm: FormGroup = this.fb.group({
    multiSelectCompany:[],
    multiSelectStatus:[],
    companyCodes: [],
    vendorCodeFrom: [],
    vendorCodeTo: [],
  
    invoiceNoFrom: [],
    invoiceNoTo: [],
    purchaseOrderNo: [],
    vendorPortalRefNo: [],
    vendorName: []
  });

  // companyList =[
  //   {"companyCode": "1000","companyName": "1000-Siam City Cement"},
  //   {"companyCode": "1001","companyName": "1001-INSEE"},
  // ];
  // invoiceStatus =[
  //   {"statusId": "1","status": "Success"},
  //   {"statusId": "2","status": "AP Checker Accepted"},
  //   {"statusId": "3","status": "Pending"},
  //   {"statusId": "4","status": "AP Team Approved"},
  //   {"statusId": "13","status": "Cancelled"}
  // ];
  dropdownList = [];
  selectedItems = [];
  language:any;
  currentUser:any=[];
  ngOnInit() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
    if(lang == 'en'){
        this.language = {
          "poNumber":"PO Number",
          "totalAmount":"Total Amount",
          "Currency":"Currency",
          "PaymentTerm":"Payment Terms",
          "button1":"Approve",
          "button2":"Reject",
          "VendorCode":"Vendor Code",
          "VendorName":"Vendor Name",
          "postingDate":"Posting Date",
          "Head":"Report For Checker",
          "invoiceDate":"Invoice Date",
          "CodeFrom":"Code From",
          "ToCode":"To Code",
          "dateRange":"Enter a date range",
          "Startdate":"Start date",
          "Enddate":"End date",
          "VPNumber":"VP Number",
          "InvoiceNumber":"Invoice Number",
          "NumberFrom":"Number From",
          "ToNumber":"To Number",
          "Search":"Search",
          "DOC":"DOC#",
          "Invoice":"INVOICE#",
          "taxType":"TAX TYPE",
          "netDueDate":"NET DUE DATE",
          "paymentDate":"PAYMENT DATE",
          "paymentDoc":"PAYMENT DOC.",
          "paymentMethod":"PAYMENT METHOD",
          "WHTTYPE1":"WHT TYPE 1",
          "WHTCODE1":"WHT CODE 1",
          "WHTBASEAmt1":"WHT BASE Amt 1",
          "WHTAmt1":"WHT Amt 1",
          "WHTTYPE2":"WHT TYPE 2",
          "WHTCODE2":"WHT CODE 2",
          "WHTBASEAmt2":"WHT BASE Amt 2",
          "WHTAmt2":"WHT Amt 2",
          "vp" :"VP#",
          "po":"PO#",
          "SAPAPUSER":"SAP AP USER",
          "print":"Print",
          "status":"Status",
          "Comment":"Comment",
          "Pending":"Pending",
          "Clear":"Clear",
          "company":"Company",
          "selectCompany":"Select Company",
          "SelectStatus":"Select Status",
          "EnterVPNumber":"Enter VP Number",
          "EnterVendorName":"Enter Vendor Name",
          "EnterPONumber":"Enter PO Number",
          "EntryDate":"Entry Date",
          "typeOfInvoice":"Type Of Invoice",
          "swalVenFrom":"Vendor Code 'From' is required",
          "swalVenTo":"Vendor Code 'To' is required",
          "swalInFrom":"Invoice Number 'From' is required",
          "swalInTo":"Invoice Number 'To' is required",
          "swalVenGFT":"Vendor Code 'From' cannot be greater than Vendor Code 'To'",
          "swalInGFT":"Invoice Number 'From' cannot be greater than Invoice Number 'To'",
          "swalStatus":"Select status to get AP Checker Report",
          "swalEntryDate":"Entry Date 'From' cannot be greater than Entry Date 'To'",
          "swalInvoiceDate":"Invoice Date 'From' cannot be greater than Invoice Date 'To'"
    }}
    else if(lang == 'th'){
      this.language = {
        "poNumber":"ตัวเลข PO",
        "totalAmount":"จำนวนเงินทั้งหมด",
        "Currency":"สกุลเงิน",
        "PaymentTerm":"เงื่อนไขการชำระเงิน",
        "button1":"อนุมัติ",
        "button2":"ปฏิเสธ",
        "VendorCode":"รหัสผู้ขาย",
        "VendorName":"ชื่อผู้จำหน่าย",
        "postingDate":"วันที่โพสต์",
        "Head":"รายงานตัวตรวจสอบ",
        "invoiceDate":"วันที่ออกใบแจ้งหนี้",
        "CodeFrom":"รหัสจาก",
        "ToCode":"รหัส",
        "dateRange":"ป้อนช่วงวันที่",
        "Startdate":"วันที่เริ่มต้น",
        "Enddate":"วันที่สิ้นสุด",
        "VPNumber":"หมายเลข VP",
        "InvoiceNumber":"เลขใบสั่งของ",
        "NumberFrom":"หมายเลขจาก",
        "ToNumber":"ไปที่หมายเลข",
        "Search":"ค้นหา",
        "DOC":"เอกสาร#",
        "Invoice":"ใบแจ้งหนี้#",
        "taxType":"ประเภทภาษี",
        "netDueDate":"วันที่ครบกำหนดสุทธิ",
        "paymentDate":"วันจ่าย",
        "paymentDoc":"เอกสารการชำระเงิน.",
        "paymentMethod":"วิธีการชำระเงิน",
        "WHTTYPE1":"WHT พิมพ์ 1",
        "WHTCODE1":"WHT รหัส 1",
        "WHTBASEAmt1":"WHT ปริมาณพื้นฐาน 1",
        "WHTAmt1":"WHT จำนวน 1",
        "WHTTYPE2":"WHT พิมพ์ 2",
        "WHTCODE2":"WHT CODE 2",
        "WHTBASEAmt2":"WHT ปริมาณพื้นฐาน 2",
        "WHTAmt2":"WHT จำนวน 2",
        "vp":"VP#",
        "po":"PO#",
        "SAPAPUSER":"SAP AP USER",
        "print":"พิมพ์",
        "status":"สถานะ",
        "Comment":"ความคิดเห็น",
        "Pending":"รอดำเนินการ",
        "Clear":"ชัดเจน",
        "company":"บริษัท",
        "selectCompany":"เลือกบริษัท",
        "SelectStatus":"เลือกสถานะ",
        "EnterVPNumber":"ใส่หมายเลข VP",
        "EnterVendorName":"ใส่ชื่อผู้ขาย",
        "EnterPONumber":"ป้อนหมายเลข PO",
        "EntryDate":"วันที่เข้า",
        "typeOfInvoice":"ประเภทของใบแจ้งหนี้",
        "swalVenFrom":"ต้องระบุรหัสผู้จำหน่าย 'จาก'",
        "swalVenTo":"ต้องระบุรหัสผู้จำหน่าย 'ถึง'",
        "swalInFrom":"ต้องระบุหมายเลขใบแจ้งหนี้ 'จาก'",
        "swalInTo":"ต้องระบุหมายเลขใบแจ้งหนี้ 'ถึง'",
        "swalVenGFT":"รหัสผู้ขาย 'จาก' ต้องไม่มากกว่ารหัสผู้ขาย 'ถึง'",
        "swalInGFT":"หมายเลขใบแจ้งหนี้ 'จาก' ต้องไม่มากกว่าหมายเลขใบแจ้งหนี้ 'ถึง'",
        "swalStatus":"เลือกสถานะเพื่อรับรายงานตัวตรวจสอบ AP",
        "swalPostingDate":"วันที่เข้า 'จาก' ต้องไม่มากกว่าวันที่เข้า 'ถึง'",
        "swalEntryDate":"วันที่เข้า 'จาก' ต้องไม่มากกว่าวันที่เข้า 'ถึง'",
        "swalInvoiceDate":"วันที่ในใบแจ้งหนี้ 'จาก' ต้องไม่มากกว่าวันที่ในใบแจ้งหนี้ 'ถึง'"
  }
}

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'companyCode',
      textField: 'companyName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownSettings1 = {
      singleSelection: true,
      closeDropDownOnSelection: true,
      idField: 'id',
      textField: 'status',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    this.columnsToDisplay = ["status","company","vendorCode","vendorName","entryDate","invoiceNo","invoiceDate","totalAmount","currency","paymentMethod","paymentTerms","netDueDate","vpNumber","poNumber","invoiceFor","typeOfInvoice"];
    this.getCompanyMaster();
  }

  companyMaster:any = [];
  invoiceStatus:any = [];
  async getCompanyMaster(){
    (await this.cService.getMasterData()).pipe(first()).subscribe((data) => {
    // (await this.cService.getCompanyMaster(this.currentUser.usertype)).pipe(first()).subscribe((data) => {
      let companyData = [];
      // if (data != null) {
      if (data.responseStatus.toUpperCase() == "SUCCESS") {
        for (let i of data.response.companyLists) {
          let obj = {
            companyCode: i.companyCode,
            companyName: i.companyCode + "-" + i.name,
          };
          companyData.push(obj);
          this.invoiceStatus = data.response.invoiceStatus;
        }
        this.companyMaster = companyData;
        
        

        this.mySpinner = false;
      } else {
        this.mySpinner = false;
        Swal.fire({ 
          icon:'error',
          text: data.responseMessage
        });
      }
      // }
    },
      error => {
        this.mySpinner = false;
      }
    );
  }
  companyLists =[];
  companyCode:any;
  companyName:any;
  onSelect(item: any) {
    this.companyLists.push(item.companyCode);
    
  }

  onDeSelect(item: any) {
    
    const index = this.companyLists.indexOf(item.companyCode);
    
    this.companyLists.splice(index, 1);
    
  }

  onSelectAll(items: any) {
    this.companyLists = [];
    for (let i = 0; i < items.length; i++){
      this.companyLists.push(items[i].companyCode);
    }
    
  }
  
  onDeSelectAll(items: any) {
    this.companyLists = [];
    
  }

  statusId:any;
  onSelect1(item: any) {
    this.statusId = item.id
    
  }

  onDeSelect1(item: any) {
    
    item.status = "";
    item.statusId = "";
    this.statusId = "";
  }  

  search(){
    this.table = true;
  }

  onSubmit(){
    let vCfrom = Number(this.checkerReportForm.value.vendorCodeFrom);
    let vCto = Number(this.checkerReportForm.value.vendorCodeTo);
    let iNfrom = Number(this.checkerReportForm.value.invoiceNoFrom);
    let iNto = Number(this.checkerReportForm.value.invoiceNoTo);
   if((vCto != 0  && vCto != null) && (vCfrom == null || vCfrom === 0)) {  
       Swal.fire(`${this.language.swalVenFrom}`,"","error");
      return;
    }else if((vCfrom != 0 && vCfrom != null) && (vCto == null || vCto === 0)){
      Swal.fire(`${this.language.swalVenTo}`,"","error");
        return;
    }else if((iNto != 0 && iNto != null) && (iNfrom === 0|| iNfrom == null)) {
      Swal.fire(`${this.language.swalInFrom}`,"","error");
        return;
    }else if((iNfrom != 0 && iNfrom != null) && (iNto === 0 || iNto == null)){
      Swal.fire(`${this.language.swalInTo}`,"","error");
        return;
    }else if(vCfrom > vCto){
      Swal.fire(`${this.language.swalVenGFT}`,"","error");
      this.checkerReportForm.controls.vendorCodeTo.setValue("");
      return;  
    }else if(iNfrom > iNto){
      Swal.fire(`${this.language.swalInGFT}`,"","error");
      this.checkerReportForm.controls.invoiceNoTo.setValue("");
      return;
    }
    let status:any = [];
    if(this.statusId != null && this.statusId != undefined && this.statusId != ""){
      status.push(this.statusId);
    }
    
    let poNo =[];
    if(this.checkerReportForm.value.purchaseOrderNo != null && this.checkerReportForm.value.purchaseOrderNo != undefined && this.checkerReportForm.value.purchaseOrderNo != ""){
      poNo.push(this.checkerReportForm.value.purchaseOrderNo);
    }
    let vendorPortalrefNo =[];
    if(this.checkerReportForm.value.vendorPortalRefNo != null && this.checkerReportForm.value.vendorPortalRefNo != undefined && this.checkerReportForm.value.vendorPortalRefNo != ""){
      vendorPortalrefNo.push(this.checkerReportForm.value.vendorPortalRefNo);
    }

    let vendorname = [];
    if(this.checkerReportForm.value.vendorName != null && this.checkerReportForm.value.vendorName != undefined && this.checkerReportForm.value.vendorName != ""){
      vendorname.push(this.checkerReportForm.value.vendorName);
    }
  
    this.requestData = {
        "companyCodes": this.companyLists,
        //"status": this.statusId,
        "status": status,
        "vendorCodeFrom": this.checkerReportForm.value.vendorCodeFrom,
        "vendorCodeTo": this.checkerReportForm.value.vendorCodeTo,
        "invoiceDateFrom":this.invoiceDateFrom,
        "invoiceDateTo":this.invoiceDateTo,
        "entryDateFrom":this.entryDateFrom,
        "entryDateTo":this.entryDateTo,
        "invoiceNoFrom": this.checkerReportForm.value.invoiceNoFrom,
        "invoiceNoTo": this.checkerReportForm.value.invoiceNoTo,
        "purchaseOrderNo": poNo,
        "vendorPortalRefNo": vendorPortalrefNo,
        "vendorName": vendorname,
        // "invoiceDateFrom":this.datepipe.transform( this.dnRange.value.invoiceDateFrom, 'dd-MM-yyyy'),
        // "invoiceDateTo":this.datepipe.transform( this.dnRange.value.invoiceDateTo, 'dd-MM-yyyy'),
        // "entryDateFrom":this.datepipe.transform( this.eDRange.value.entryDateFrom, 'dd-MM-yyyy'),
        // "entryDateTo":this.datepipe.transform( this.eDRange.value.entryDateTo, 'dd-MM-yyyy'),        
        // "purchaseOrderNo": this.checkerReportForm.value.purchaseOrderNo,
        //"vendorPortalRefNo": this.checkerReportForm.value.vendorPortalRefNo,
        
    };
  
    
    this.setTableData(null);
  }
  mySpinner:boolean=false;
  pageIndex = 0;
  pageSize = 10;
  listLength: any;
    async setTableData(event: PageEvent){
  
      if (event == null) {
        this.pageIndex = 0;
        this.pageSize = 10;
      } else {
        // if(event.pageIndex == 0){
        //   this.pageIndex = 0;
        //   this.pageSize = event.pageSize;
        // }else{
          this.pageIndex = event.pageIndex;
          this.pageSize = event.pageSize;
        // }
      }
    let tableData: any [];
    let status : any;
    let comName: any;
        this.mySpinner = true;
        (await this.cService.getAPCheckerReport(this.requestData,this.pageIndex, this.pageSize)).pipe(first()).subscribe(res => {
          if(res.response != null){
            if (res.responseStatus.toUpperCase() == "SUCCESS") {
              
              
              this.table = true;
              this.dataSource = new MatTableDataSource(res.response);
              this.listLength = res.pagination.count;
              
              this.mySpinner = false;
            } else {
              this.mySpinner = false;
              Swal.fire(res.responseMessage,"","error");
            }
          }else{
            this.mySpinner = false;
            this.dataSource = new MatTableDataSource([]);
            Swal.fire(res.responseMessage,"","error");
          }
        })
  }
  // setTableData(){
  //   let tableData: any [];
  //   let status : any;
  //   let comName: any;
  //   if(this.companyCode == 1000){
  //     comName = "Siam City Cement"
  //   }else{
  //     comName = "INSEE"
  //   }
  
  //   if(this.statusId == 1){
  //    status = "SUCCESS";
  //    this.table = true;
  //   }else if(this.statusId == 2){
  //     status = "FAILED"
  //     this.table = true;
  //   }else if (this.statusId == 3){
  //     status = "PENDING"
  //     this.table = true;
  //   }else if (this.statusId == 4){
  //     status = "To Be Verified"
  //     this.table = true;
  //   }else{
  //     Swal.fire(`${this.language.swalStatus}`,"","error");
  //   }
  //   tableData=[
    
  //     {
  //       "status": status,
  //       "companyCode": "1000",
  //       "companyName": comName,
  //       "vendorCode": "123456",
  //       "vendorName":"Ashish Singh",
  //       "typeOfInvoice": "G",
  //       "entryDate": "06-06-2022",
  //       "invoiceNo":"87463",
  //       "invoiceDate":"987665",
  //       "totalAmount":"10000",
  //       "currency":"THB",
  //       "paymentMethod":"CNV",
  //       "paymentTerms":"Z0000",
  //       "paymentTermsDescription":"-",
  //       "netDueDate":"09-09-2022",
  //       "vpRefNo": "10000",
  //       "poNumber":"987654324356",
  //       },
  //       {
  //         "status": status,
  //         "companyCode": "1001",
  //         "companyName": comName,
  //         "vendorCode": "9988777",
  //         "vendorName":"Singh Ashish",
  //         "typeOfInvoice": "S",
  //         "entryDate": "05-06-2022",
  //         "invoiceNo":"1287463",
  //         "invoiceDate":"88987665",
  //         "totalAmount":"120000",
  //         "currency":"THB",
  //         "paymentMethod":"CN",
  //         "paymentTerms":"S0001",
  //         "paymentTermsDescription":"-",
  //         "netDueDate":"09-09-2022",
  //         "vpRefNo":"30000",
  //         "poNumber":"387658474636",
  //       }
  //       ]
  //       this.dataSource = new MatTableDataSource(tableData);
  // }
  
  maxDate = new Date();

  onChangeOfVendorCode(event: any){
    let etv: any;
    etv = event.target.value;
    let vendorCode :any;
    vendorCode = this.checkerReportForm.value.vendorCodeFrom;
    
    
    if(vendorCode == "" || vendorCode == undefined){
      this.checkerReportForm.controls.vendorCodeTo.setValue("");
      Swal.fire(`${this.language.swalVenFrom}`,"","warning")    
      return false;
    }
  }
  
  onChangeOfInvoiceNumber(event: any){
    let etv: any;
    etv = event.target.value;
    let invoiceNo :any;
    invoiceNo = this.checkerReportForm.value.invoiceNoFrom;
    if(invoiceNo == "" || invoiceNo == undefined){
      this.checkerReportForm.controls.invoiceNoTo.setValue("");
      Swal.fire(`${this.language.swalInFrom}`,"","warning")     
      return false;
    }
  }  

vendorCFrom: any;
vendorCTo: any;
vpNo: any;
vName: any ;
poNo : any;
invoiceFrom : any;
invoiceTo : any;
onClear(){
  
  this.requestData = {};
  this.dataSource = new MatTableDataSource([]);
  //this.companyCode = "";
  this.companyLists = [];
  this.checkerReportForm.get('multiSelectCompany').setValue([]);
  this.vendorCFrom = "";
  this.vendorCTo = "";
  this.entryDateFrom = '';
  this.entryDateTo = '';
  this.eDRange.controls.entryDateFrom.setValue("");
  this.eDRange.controls.entryDateTo.setValue("");
  this.isEntryEndDate = true;
  this.invoiceDateFrom = '';
  this.invoiceDateTo = '';
  this.dnRange.controls.invoiceDateFrom.setValue("");
  this.dnRange.controls.invoiceDateTo.setValue("");
  this.isInvoiceEndDate = true;
  // this.dnRange.value.invoiceDateFrom = "";
  // this.invoiceFromD.nativeElement.value = "";
  // this.dnRange.value.invoiceDateTo = "";
  // this.invoiceToD.nativeElement.value = "";
  // this.eDRange.value.entryDateFrom = "";
  // this.entryFromD.nativeElement.value = "";
  // this.eDRange.value.entryDateTo = "";
  // this.entryToD.nativeElement.value = "";
  this.statusId = "";
  this.checkerReportForm.get('multiSelectStatus').setValue([]);
  this.vpNo = "";
  this.vName = "";
  this.poNo = "";
  this.invoiceFrom = "";
  this.invoiceTo = "";  
  this.table = false;    
}

isEntryEndDate:boolean = true;
entryDateFrom: any;
entryDateTo:any;
getEntryStartDate(event){
  this.entryDateFrom = this.datepipe.transform(event.target.value,'yyyy-MM-dd');
   if(new Date(this.entryDateTo) < new Date(this.entryDateFrom)){
       Swal.fire(`${this.language.swalEntryDate}`,'','warning');      
     event.target.value = new Date(this.entryDateTo);
     this.entryDateFrom = this.datepipe.transform(this.entryDateTo,'yyyy-MM-dd');
   }else{
     if(this.entryDateTo != "" || this.entryDateTo != undefined){
       //this.deliveryWbDetails = [];
    //    let selectedItem = []
    //    
    //    for (let i = 0; i <= this.selectedValuesWb.length - 1; i++) {
    //      selectedItem.push(this.selectedValuesWb[i].poNumber);
    //    }
    //    this.getWBDetails(selectedItem);
    }
     
   }
  //  
  this.isEntryEndDate = false;     
 }

 getEntryEndDate(event) {   
    this.entryDateTo = this.datepipe.transform(event.target.value,'yyyy-MM-dd');
    if(new Date(this.entryDateTo) < new Date(this.entryDateFrom)){
      Swal.fire(`${this.language.swalEntryDate}`,'','warning');
      event.target.value = new Date(this.entryDateFrom);
      this.entryDateTo = this.datepipe.transform(this.entryDateFrom,'yyyy-MM-dd');
    }
    // let selectedItem = []
    // 
    // for (let i = 0; i <= this.selectedValuesWb.length - 1; i++) {
    //   selectedItem.push(this.selectedValuesWb[i].poNumber);
    // }

    // let POIDs= (this.selectedValues).toString();
    //
    // let request = {
    //   "poNumber": selectedItem,
    //  // "plantCode": this.plantId
    // }
    // this.dataSourceWb = [];
    // this.invoiceSvData = [];
    if(this.entryDateFrom == null || this.entryDateFrom == undefined ){
        return;
    }
    if(this.entryDateTo == null || this.entryDateTo == undefined ){
      return;
  }
  }

isInvoiceEndDate:boolean = true;
invoiceDateFrom: any;
invoiceDateTo:any;
getInvoiceStartDate(event){
  this.invoiceDateFrom = this.datepipe.transform(event.target.value,'yyyy-MM-dd');
   if(new Date(this.invoiceDateTo) < new Date(this.invoiceDateFrom)){
       Swal.fire(`${this.language.swalInvoiceDate}`,'','warning');      
     event.target.value = new Date(this.invoiceDateTo);
     this.invoiceDateFrom = this.datepipe.transform(this.invoiceDateTo,'yyyy-MM-dd');
   }else{
     if(this.invoiceDateTo != "" || this.invoiceDateTo != undefined){
       //this.deliveryWbDetails = [];
    //    let selectedItem = []
    //    
    //    for (let i = 0; i <= this.selectedValuesWb.length - 1; i++) {
    //      selectedItem.push(this.selectedValuesWb[i].poNumber);
    //    }
    //    this.getWBDetails(selectedItem);
    }
     
   }
  //  
  this.isInvoiceEndDate = false;     
 }

 getInvoiceEndDate(event) {   
    this.invoiceDateTo = this.datepipe.transform(event.target.value,'yyyy-MM-dd');
    if(new Date(this.invoiceDateTo) < new Date(this.invoiceDateFrom)){
      Swal.fire(`${this.language.swalInvoiceDate}`,'','warning');
      event.target.value = new Date(this.invoiceDateFrom);
      this.invoiceDateTo = this.datepipe.transform(this.invoiceDateFrom,'yyyy-MM-dd');
    }
    // let selectedItem = []
    // 
    // for (let i = 0; i <= this.selectedValuesWb.length - 1; i++) {
    //   selectedItem.push(this.selectedValuesWb[i].poNumber);
    // }

    // let POIDs= (this.selectedValues).toString();
    //
    // let request = {
    //   "poNumber": selectedItem,
    //  // "plantCode": this.plantId
    // }
    // this.dataSourceWb = [];
    // this.invoiceSvData = [];
    if(this.invoiceDateFrom == null || this.invoiceDateFrom == undefined ){
        return;
    }
    if(this.invoiceDateTo == null || this.invoiceDateTo == undefined ){
      return;
  }
  }
}

export interface PeriodicElement {
  status:any,
  company:any,
  vendorCode:any,
  vendorName:any,
  typeOfInvoice:any,
  entryDate:any,
  invoiceNumber:any,
  invoiceDate:any,
  totalAmount:any,
  currency:any,
  paymentMethods:any,
  paymentTerms:any,
  netDueDate:any,
  vpNumber:any,
  poNumber:any,
  invoiceFor:any
}