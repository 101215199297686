import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { IconsPipe } from './icons/icons.pipe';
import { AmountToWordPipe } from './amount-to-word.pipe';

@NgModule({
  declarations: [AmountToWordPipe],
  imports: [CommonModule],
  exports: [AmountToWordPipe],
})
export class CommonPipesModule {}