import { Injectable } from '@angular/core';

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  children: Menu[];
}

// const MENUITEMSVND = [
//   { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer',children: [] },
//   { state: 'purchase-order', name: 'Purchase Order', type: 'link', icon: 'inventory' },
//   { state: 'delivery-notes', name: 'Delivery Notes', type: 'link', icon: 'text_snippet' },
//   { state: 'weigh-bridge', name: 'Weigh Bridge', type: 'link', icon: 'text_snippet' },
//   { state: 'invoice-list', type: 'link', name: 'Invoices', icon: 'receipt' },
//   { state: 'debit-note', type: 'link', name: 'Debit Note', icon: 'receipt' },
//   { state: 'credit-note', type: 'link', name: 'Credit Note', icon: 'receipt' },
//   { state: 'notification', type: 'link', name: 'Notifications', icon: 'notifications_active' },
//   { state: 'vendor-management', type: 'link', name: 'Vendor info', icon: 'group' },
//   { state: 'profile', type: 'link', name: 'Profile', icon: 'manage_accounts' },
//   { state: 'change-pwd', type: 'link', name: 'Change PWD', icon: 'password' }
// ];

const MENUITEMSVND = [
  { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer', children:[] },
  { state: 'purchase-order', name: 'Purchase Order', type: 'link', icon: 'inventory', children:[] },
  { state: 'delivery-notes', name: 'Delivery Notes', type: 'link', icon: 'text_snippet', children:[] },
  { state: 'weigh-bridge', name: 'Weigh Bridge', type: 'link', icon: 'text_snippet', children:[] },
  { state: 'invoice-list', type: 'link', name: 'Invoices', icon: 'receipt', children:[] },
  { state: 'debit-note', type: 'link', name: 'Debit Note', icon: 'receipt', children:[] },
  { state: 'credit-note', type: 'link', name: 'Credit Note', icon: 'receipt', children:[] },
  { state: 'notification', type: 'link', name: 'Notifications', icon: 'notifications_active', children:[] },
  { state: 'vendor-management', type: 'link', name: 'Vendor info', icon: 'group', children:[] },
  { state: 'profile', type: 'link', name: 'Profile', icon: 'manage_accounts', children:[] },
  { state: 'change-pwd', type: 'link', name: 'Change PWD', icon: 'password', children:[] }
];

// const MENUITEMS = [
//   { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer' },
//   { state: 'purchase-order', name: 'Purchase Order', type: 'link', icon: 'inventory' },
//   { state: 'delivery-notes', name: 'Delivery Notes', type: 'link', icon: 'text_snippet' },  
//   { state: 'invoice-list', type: 'link', name: 'Invoices', icon: 'receipt' },
//   { state: 'user-management', type: 'link', name: 'User Management', icon: 'view_list' },
//   { state: 'role-management', type: 'link', name: 'Role Management', icon: 'view_headline' },
//   { state: 'notification', type: 'link', name: 'Notifications', icon: 'notifications_active' },
//   { state: 'profile', type: 'link', name: 'Profile', icon: 'manage_accounts' },
//   { state: 'change-pwd', type: 'link', name: 'Change PWD', icon: 'password' }
// ];


const MENUITEMSWAR = [
  { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer',children: [] },
  // { state: 'purchase-order', name: 'Purchase Order', type: 'link', icon: 'inventory',children: [] },
  { state: 'delivery-notes', name: 'Delivery Notes', type: 'link', icon: 'text_snippet',children: [] },
  // { state: 'invoice-list', type: 'link', name: 'Invoices', icon: 'receipt',children: [] },
  { state: 'notification', type: 'link', name: 'Notifications', icon: 'notifications_active',children: [] },
  { state: 'profile', type: 'link', name: 'Profile', icon: 'manage_accounts',children: [] },
  { state: 'change-pwd', type: 'link', name: 'Change PWD', icon: 'password' }
];

const MENUITEMSADM = [
  { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer',children: [] },
  { state: 'user-management', type: 'link', name: 'User Management', icon: 'view_list',children: [] },
  { state: 'role-management', type: 'link', name: 'Role Management', icon: 'view_headline',children: [] },
  { state: 'vendor-management', type: 'link', name: 'Vendor Management', icon: 'group',children: [] },
  { state: 'app-configurations', type: 'link', name: 'Configuration', icon: 'settings_suggest',children: [] },
  { state: 'response-messages', type: 'link', name: 'Response Messages', icon: 'notifications_active',children: [] },
  { state: 'profile', type: 'link', name: 'Profile', icon: 'manage_accounts',children: [] },
  { state: 'change-pwd', type: 'link', name: 'Change PWD', icon: 'password',children: [] },
];

const MENUITEMSAPV = [
  { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer',children: [] },
  { state: 'invoice-list', type: 'link', name: 'Invoices', icon: 'receipt',children: [] },
  { state: 'notification', type: 'link', name: 'Notifications', icon: 'notifications_active',children: [] },
  { state: 'profile', type: 'link', name: 'Profile', icon: 'manage_accounts',children: [] },
  { state: 'change-pwd', type: 'link', name: 'Change PWD', icon: 'password' }
];

const MENUITEMSAPU = [
  { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer',children: [] },
  { state: 'invoice-list', type: 'link', name: 'Invoices', icon: 'receipt',children: [] },
  { state: 'notification', type: 'link', name: 'Notifications', icon: 'notifications_active',children: [] },
  { state: 'profile', type: 'link', name: 'Profile', icon: 'manage_accounts',children: [] },
  { state: 'change-pwd', type: 'link', name: 'Change PWD', icon: 'password' }
];
const MENUITEMSPJO = [
  { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer',children: [] },
  { state: 'purchase-order', name: 'Purchase Order', type: 'link', icon: 'inventory',children: [] },
  { state: 'invoice-list', type: 'link', name: 'Invoices', icon: 'receipt',children: [] },
  { state: 'notification', type: 'link', name: 'Notifications', icon: 'notifications_active',children: [] },
  { state: 'profile', type: 'link', name: 'Profile', icon: 'manage_accounts',children: [] },
  { state: 'change-pwd', type: 'link', name: 'Change PWD', icon: 'password' }
];

@Injectable()
export class MenuItems {

  getMenuitem(): Menu[] {
    let data = localStorage.getItem("user");
    if(data != null){
      if (data.toUpperCase() == "VND123") {
        return MENUITEMSVND;
      } else if (data.toUpperCase() == "WAR123") {
        // return MENUITEMSWAR;
      } else if (data.toUpperCase() == "ADM123") {
        // return MENUITEMSADM;
      } else if (data.toUpperCase() == "APV123") {
        // return MENUITEMSAPV;
      } else if (data.toUpperCase() == "APU123") {
        // return MENUITEMSAPU;
      }else if (data.toUpperCase() == "PJO123") {
        // return MENUITEMSPJO;
      }
       else {
        // return MENUITEMSADM;
      }
    }else{
      // return MENUITEMSADM;
    }
    

  }
}
