import { Routes } from '@angular/router';
import { AddDeliveryNotesComponent } from './add-delivery-notes/add-delivery-notes.component';
import { DeliveryNotesHistoryComponent } from './delivery-notes-history/delivery-notes-history.component';
import { DeliveryNotesInfoComponent } from './delivery-notes-info/delivery-notes-info.component';
import { DeliveryNotesComponent } from './delivery-notes/delivery-notes.component';
import { DlApproveComponent } from './dl-approve/dl-approve.component';
import { EditDeliveryNoteComponent } from './edit-delivery-note/edit-delivery-note.component';
import { PoInfoComponent } from './po-info/po-info.component';
import { VendorInfoComponent } from './vendro-info/vendor-info.component';

export const DeliveryNotesRoutes: Routes = [
  {
  path: 'delivery-notes', 
  component: DeliveryNotesComponent
  },
  {
    path: 'delivery-notes-history', 
    component: DeliveryNotesHistoryComponent
    },
  {
    path: 'delivery-notes/delivery-notes-info/:id/:name', 
    component: DeliveryNotesInfoComponent
  },
  {
    path: 'delivery-notes/edit-delivery-note/:id', 
    component: EditDeliveryNoteComponent
  },
  {
    path: 'delivery-notes/po-info/:id/:pageName/:dnId', 
    component: PoInfoComponent
  },
  {
    path: 'delivery-notes/add-delivery-notes', 
    component: AddDeliveryNotesComponent
  },
  {
    path: 'delivery-notes/vendor-info/:id',
    component: VendorInfoComponent
  },
  {
        path: 'delivery-notes/vendor-info/:id/:poId/:pageName/:dnId',  
        component: VendorInfoComponent 
  }

];
