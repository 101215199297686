// my-modal.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router'
import { ResponseMessageComponent } from '../response-message.component';

@Component({
  selector: 'app-role-modal',
  templateUrl: './response-model.html',
  styleUrls: ['./response-model.scss']
})

export class ResponseModel {
    local_data:any;
    action:any;
    status:boolean=false;
    currentUser: any;
    language:any;
    constructor(private fb: FormBuilder, private router: Router,
        public dialogRef: MatDialogRef<ResponseModel>,
        @Inject(MAT_DIALOG_DATA) public data: ResponseMessageComponent) {
            this.local_data = { ...data };
            this.action = this.local_data.action;
            if (this.action == 'Add') {
            this.status = false;
            } else {
            this.status = true;
            }
        }

        roleForm:FormGroup= this.fb.group({
          id : ['',[Validators.required]],
          respCode : ['',[Validators.required]],
          message : ['',[Validators.required]],
            
        })

        ngOnInit(): void {
          this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
          let currentUsers = this.currentUser.response;
          let lang = currentUsers.langPreference;
          
          if(lang == 'en'){
            this.language={
              responseKey:"Response Key",
              responseValue:"Response Value"
             
            }
            }else if(lang == 'th'){
            this.language={
              responseKey:"คีย์ตอบกลับ",
              responseValue:"ค่าตอบกลับ"
            }
            }

            this.roleForm = this.fb.group({
              id : ['',[Validators.required]],
              respCode : ['',[Validators.required]],
              message : ['',[Validators.required]]
 
            })
        }

        onNoClick(): void {
            
            this.dialogRef.close({event:this.action, data:this.roleForm.value});
            // this.dialogRef.close({ event: this.action, data: this.local_data });
            // this.dialogRef.close({
            //   //food: this.food
            //   respCode : this.roleForm.value.respCode,
            //   message : this.roleForm.value.message
            // });
        
          }
          closeDialog() {
            this.dialogRef.close({ event: 'Cancel' });
          }
}