<mat-card>
      <mat-card-title>
          <div class="margin-bottotm" fxLayout="row">
              <div class="pull-left" fxFlex="50">
                  {{language.VendorInfo}}
              </div>
              <div class="pull-right" fxFlex="50">
                  <mat-icon (click)="goTo()" class="close1" >close</mat-icon>
              </div>
          </div>
      </mat-card-title>
        <div class="addUserParent mr-2">          
             <a (click)="exportexcel()" style="cursor:pointer"><img src="assets/images/icons8-export-excel-100.png" height="50px" width="50px" ></a>           
        </div>           
         <!-- <hr> -->
            <mat-card-content>
                <div fxLayout="row" fxLayoutWrap="wrap">
                    <!-- Card column -->
                    <div fxFlex.gt-sm="100%" fxFlex="100">
                <form  [formGroup]="vendorInfo" class="form-style">
                    <h5>{{language.BasicInfo}}</h5>
                <div fxLayout="row" fxLayout.xs ="column">

                    <div class="padding-right" fxFlex="33">
                        <mat-form-field appearance="outline" >
                            <mat-label>{{language.VendorCode}}</mat-label>
                            <input matInput formControlName="vendorCode" type="text" readonly>
                        </mat-form-field>
                    </div>
                    <!-- <div class="padding-left padding-right" fxFlex="33">
                        <mat-form-field appearance="outline">
                            <mat-label>{{language.CompanyCode}}</mat-label>
                            <input matInput formControlName="companyCode" type="text" readonly>
                        </mat-form-field>
                    </div>
                    <div class=" padding-right padding-left" fxFlex="33">
                        <mat-form-field appearance="outline">
                            <mat-label>{{language.Pmtmeths}}</mat-label>
                            <input matInput formControlName="pmtMeths" type="text" readonly>
                        </mat-form-field>
                    </div> -->
                    <div class="padding-left padding-right" fxFlex="33">
                            <mat-form-field appearance="outline">
                                <mat-label>{{language.nameOne}}</mat-label>
                                <input matInput formControlName="nameOne" type="text" readonly>
                            </mat-form-field>
                        </div>
                        <div class=" padding-right padding-left" fxFlex="33">
                            <mat-form-field appearance="outline">
                                <mat-label>{{language.nameTwo}}</mat-label>
                                <input matInput formControlName="nameTwo" type="text" readonly>
                            </mat-form-field>
                        </div>
                </div>
                <!-- <div fxLayout="row wrap" style="padding-right: 10%;" fxLayout.xs ="column"> -->

                    <!-- <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Recon Act</mat-label>
                            <input matInput formControlName="reconAcc" type="text" readonly>
                        </mat-form-field>
                    </div> -->
                    <!-- <div fxFlex.gt-sm="10" fxFlex="10"></div> -->
                    <!-- <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Pmt meths</mat-label>
                            <input matInput formControlName="pmtMeths" type="text" readonly>
                        </mat-form-field>
                    </div> -->
                <!-- </div> -->

                <!-- <div fxLayout="row wrap" style="padding-right: 10%;">

                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Search Terms</mat-label>
                            <input matInput formControlName="searchTerms" type="text" readonly>
                        </mat-form-field>
                    </div>
                    <div fxFlex.gt-sm="10" fxFlex="10"></div>
                    <div fxFlex.gt-sm="40" fxFlex="40"></div>
                </div> -->
                <hr>
                <h5>{{language.VendorAddress}}</h5>
                <div fxLayout="row" fxLayout.xs ="column">
                    <div class="padding-right" fxFlex="33">
                        <mat-form-field appearance="outline">
                            <mat-label>{{language.Street}} </mat-label>
                            <input matInput formControlName="street" type="text" readonly>
                        </mat-form-field>
                    </div>
                    <div class="padding-right padding-left" fxFlex="33">
                            <mat-form-field appearance="outline">
                                <mat-label>{{language.Street4}} </mat-label>
                                <input matInput formControlName="street4" type="text" readonly>
                            </mat-form-field>
                    </div>
                    <div class="padding-right padding-left" fxFlex="33">
                            <mat-form-field appearance="outline">
                                <mat-label>{{language.Street5}} </mat-label>
                                <input matInput formControlName="street5" type="text" readonly>
                            </mat-form-field>
                    </div>
                    </div>
                    <div fxLayout="row" fxLayout.xs ="column">
                            <div class="padding-right" fxFlex="33">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{language.City}}</mat-label>
                                    <input matInput formControlName="city" type="text" readonly>
                                </mat-form-field>
                            </div>
                            
                            <div class="padding-right padding-left" fxFlex="33">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{language.District}}</mat-label>
                                    <input matInput formControlName="district" type="text" readonly>
                                </mat-form-field>
                            </div>
                            <div  class="padding-right padding-left" fxFlex="33">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{language.PostalCode}}</mat-label>
                                        <input matInput formControlName="postalCode" type="text" readonly>
                                    </mat-form-field>
                                </div>
                    </div>
                <div fxLayout="row" fxLayout.xs ="column">             
                    <div class="padding-right" fxFlex="33">
                        <mat-form-field appearance="outline">
                            <mat-label>{{language.Country}}</mat-label>
                            <input matInput formControlName="country" type="text" readonly>
                        </mat-form-field>
                    </div>                    
                </div>
                <hr>


                <!-- Communications -->           
                <h5>{{language.Communication}}</h5>
                <div fxLayout="row" fxLayout.xs ="column">
                    <div class="padding-right" fxFlex="33">
                        <mat-form-field appearance="outline">
                            <mat-label>{{language.telephone}}</mat-label>
                            <input matInput formControlName="telphone" type="text" readonly>
                        </mat-form-field>
                    </div>
        
                    <div class="padding-right padding-left" fxFlex="33">
                        <mat-form-field appearance="outline">
                            <mat-label>{{language.Fax}}</mat-label>
                            <input matInput formControlName="fax" type="text" readonly>
                        </mat-form-field>
                    </div>

                    <div class="padding-right padding-left" fxFlex="33">
                        <mat-form-field appearance="outline">
                            <mat-label>{{language.Email}}</mat-label>
                            <input matInput formControlName="email" type="text" readonly>
                        </mat-form-field>
                    </div>                    
                </div>

                <div  fxLayout="row" fxLayout.xs ="column">                                      
                    <div class="padding-right" fxFlex="33">
                        <mat-form-field appearance="outline">
                            <mat-label>{{language.Language}}</mat-label>
                            <input matInput formControlName="language" type="text" readonly>
                        </mat-form-field>
                    </div>

                    <!-- <div class="padding-right padding-left" fxFlex="33">
                        <mat-form-field appearance="outline">
                            <mat-label>{{language.Salesperson}} </mat-label>
                            <input matInput formControlName="salesperson" type="text" readonly>
                        </mat-form-field>
                    </div>
                    
                    <div class="padding-right padding-left" fxFlex="33">
                        <mat-form-field appearance="outline">
                            <mat-label>{{language.TelePhone}}</mat-label>
                            <input matInput formControlName="purTelephone" type="text" readonly>
                        </mat-form-field>
                    </div> -->
                </div>

                <!-- <div fxLayout="row wrap" style="padding-right: 10%;">

                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Data Line</mat-label>
                            <input matInput formControlName="dataLine" type="text">
                        </mat-form-field>
                    </div>
                    <div fxFlex.gt-sm="10" fxFlex="10"></div>
                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Tele Box</mat-label>
                            <input matInput formControlName="teleBox" type="text">
                        </mat-form-field>
                    </div>
                </div> -->
                <!-- <hr>
                <h5>Branch Details</h5>
                <div fxLayout="row wrap" style="padding-right: 10%;">

                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Branch Code</mat-label>
                            <input matInput formControlName="branchCode" type="text">
                        </mat-form-field>
                    </div>
                    <div fxFlex.gt-sm="10" fxFlex="10"></div>
                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Branch Description</mat-label>
                            <input matInput formControlName="branchDesc" type="text">
                        </mat-form-field>
                    </div>
                </div> -->
                <!-- <hr>
                <h5>Tax Information</h5> -->
                <!-- <div fxLayout="row wrap" style="padding-right: 10%;">

                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Tax Number 1</mat-label>
                            <input matInput formControlName="taxNum1" type="text">
                        </mat-form-field>
                    </div>
                    <div fxFlex.gt-sm="10" fxFlex="10"></div>
                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Tax Number 2</mat-label>
                            <input matInput formControlName="taxNum2" type="text">
                        </mat-form-field>
                    </div>
                </div> -->
                <!-- <div fxLayout="row wrap" style="padding-right: 10%;">

                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Tax Number 3</mat-label>
                            <input matInput formControlName="taxNum3" type="text">
                        </mat-form-field>
                    </div>
                    <div fxFlex.gt-sm="10" fxFlex="10"></div>
                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Type of Industry</mat-label>
                            <input matInput formControlName="typeofIndusty" type="text">
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" style="padding-right: 10%;">

                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Reps Name</mat-label>
                            <input matInput formControlName="repsName" type="text">
                        </mat-form-field>
                    </div>
                    <div fxFlex.gt-sm="10" fxFlex="10"></div>
                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Type of Busines</mat-label>
                            <input matInput formControlName="typeofBusines" type="text">
                        </mat-form-field>
                    </div>
                </div> -->
                <!-- <div fxLayout="row wrap" style="padding-right: 10%;">

                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Type of Industry</mat-label>
                            <input matInput formControlName="typeofIndusty" type="text">
                        </mat-form-field>
                    </div>
                    <div fxFlex.gt-sm="10" fxFlex="10"></div>
                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Tax Number 5</mat-label>
                            <input matInput formControlName="taxNum5" type="text">
                        </mat-form-field>
                    </div>
                </div> -->
                <!-- <hr>
                <h5>Accounting Information</h5>
                <div fxLayout="row wrap" style="padding-right: 10%;">

                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Recon. account</mat-label>
                            <input matInput formControlName="reconAccount" type="text">
                        </mat-form-field>
                    </div>
                    <div fxFlex.gt-sm="10" fxFlex="10"></div>
                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Sort Key</mat-label>
                            <input matInput formControlName="sortKey" type="text">
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row wrap" style="padding-right: 10%;">

                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Authorization</mat-label>
                            <input matInput formControlName="authorization" type="text">
                        </mat-form-field>
                    </div>
                    <div fxFlex.gt-sm="10" fxFlex="10"></div>
                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Cash Management Group</mat-label>
                            <input matInput formControlName="cashManagementGroup" type="text">
                        </mat-form-field>
                    </div>
                </div>
                <hr>
                <h5>Reference Data</h5>
                <div fxLayout="row wrap" style="padding-right: 10%;">

                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Previous Account Number</mat-label>
                            <input matInput formControlName="previousAccountNumber" type="text">
                        </mat-form-field>
                    </div>
                    <div fxFlex.gt-sm="10" fxFlex="10"></div>
                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Personal Number</mat-label>
                            <input matInput formControlName="personalNumber" type="text">
                        </mat-form-field>
                    </div>
                </div>
                <hr>
                <h5>Payment Transactions</h5>
                <div fxLayout="row wrap" style="padding-right: 10%;">

                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Payment Terms</mat-label>
                            <input matInput formControlName="paymentTerms" type="text">
                        </mat-form-field>
                    </div>
                    <div fxFlex.gt-sm="10" fxFlex="10"></div>
                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Payment Methods</mat-label>
                            <input matInput formControlName="paymentMethod" type="text">
                        </mat-form-field>
                    </div>
                </div>
                
                <div fxLayout="row wrap" style="padding-right: 10%;">
                <div fxFlex.gt-sm="40" fxFlex="40">
                    <mat-form-field appearance="outline">
                        <mat-label>Payment Block </mat-label>
                        <input matInput formControlName="paymentBlock" type="text">
                    </mat-form-field>
                </div>
                <div fxFlex.gt-sm="10" fxFlex="10"></div>
                <div fxFlex.gt-sm="40" fxFlex="40">
                    <mat-form-field appearance="outline">
                        <mat-label>Alternative Payee</mat-label>
                        <input matInput formControlName="alterPayee" type="text">
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row wrap" style="padding-right: 10%;">
                <div fxFlex.gt-sm="40" fxFlex="40">
                    <mat-form-field appearance="outline">
                        <mat-label>House Bank </mat-label>
                        <input matInput formControlName="houseBank" type="text">
                    </mat-form-field>
                </div>
                <div fxFlex.gt-sm="10" fxFlex="10"></div>
                <div fxFlex.gt-sm="40" fxFlex="40">
                    <mat-form-field appearance="outline">
                        <mat-label>Grouping Key</mat-label>
                        <input matInput formControlName="groupingKey" type="text">
                    </mat-form-field>
                </div>
            </div> -->
                <!-- <hr> -->
                <!-- <h5>Purchasing Data</h5>
                <div fxLayout="row wrap" style="padding-right: 10%;">

                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Purchasing Org</mat-label>
                            <input matInput formControlName="purchasingOrg" type="text">
                        </mat-form-field>
                    </div>
                    <div fxFlex.gt-sm="10" fxFlex="10"></div>
                    <div fxFlex.gt-sm="40" fxFlex="40">
                        <mat-form-field appearance="outline">
                            <mat-label>Order Currency</mat-label>
                            <input matInput formControlName="orderCurrency" type="text">
                        </mat-form-field>
                    </div>
                </div>
                
                <div fxLayout="row wrap" style="padding-right: 10%;">
                <div fxFlex.gt-sm="40" fxFlex="40">
                    <mat-form-field appearance="outline">
                        <mat-label>Terms of Payment </mat-label>
                        <input matInput formControlName="termsOfPayment" type="text">
                    </mat-form-field>
                </div>
                <div fxFlex.gt-sm="10" fxFlex="10"></div>
                <div fxFlex.gt-sm="40" fxFlex="40">
                    <mat-form-field appearance="outline">
                        <mat-label>Incoterms</mat-label>
                        <input matInput formControlName="incoterms" type="text">
                    </mat-form-field>
                </div>
            </div> -->
            <!-- <div fxLayout="row wrap" style="padding-right: 10%;" fxLayout.xs ="column">
                <div fxFlex.gt-sm="40" fxFlex="40">
                    <mat-form-field appearance="outline">
                        <mat-label>Salesperson </mat-label>
                        <input matInput formControlName="salesperson" type="text">
                    </mat-form-field>
                </div>
                <div fxFlex.gt-sm="10" fxFlex="10"></div>
                <div fxFlex.gt-sm="40" fxFlex="40">
                    <mat-form-field appearance="outline">
                        <mat-label>TelePhone</mat-label>
                        <input matInput formControlName="purTelephone" type="text">
                    </mat-form-field>
                </div>
            </div> -->
            <!-- <div fxLayout="row" fxLayout.xs ="column">
                <div class="padding-right" fxFlex="33">
                    <mat-form-field appearance="outline">
                        <mat-label>{{language.SPEmail}}</mat-label>
                        <input matInput formControlName="spEmail" type="text" readonly>
                    </mat-form-field>
                </div>
            </div> -->
            </form>
     </div>
</div>
</mat-card-content>
</mat-card>
<div class="overlay"  *ngIf="mySpinner">
    <div class="center">
        <mat-spinner></mat-spinner> 
    </div>
    </div>