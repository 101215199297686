import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup,  Validators } from '@angular/forms';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { first } from 'rxjs/operators';
import { CommonServicesService } from '../services/common-services.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  mySpinner: boolean = false;
  language:any;
  constructor(
    private cService: CommonServicesService,
    private fb: FormBuilder,
  ) { }

  currentUser :any =[];
  emailPattern = /^([\w-]|(?<!\.)\.)+[a-zA-Z0-9]@[a-zA-Z0-9]([\w\-]+)((\.([a-zA-Z]){2,9})+)$/;
  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.currentUser = currentUser.response;
    let lang = this.currentUser.langPreference;
    if(lang == 'en'){
        this.language = {
        "Profile" :	"Profile",
        "FirstName":	"First Name",
        "LastName":	"Last Name",
        "FirstNameTh":	"Company Name TH",
        "LastNameEn"	:"Company Name EN",
        "Email":"Email",
        "MobileNumber":	"Mobile Number",
        "RoleName":	"Role Name",
        "Username":	"User Name",
        "grnEmail":"GRN Email",
        "srnEmail":"SRN Email",
        "invEmail":"Invoice Email",
        "submit":"Submit",
        "cancel":"Cancel",
        "swalValPrOEmail":"Please Enter Valid Email ID"
    }}
    else if(lang == 'th'){
      this.language = {
        "Profile" :	"ประวัติโดยย่อ",
        "FirstName":	"ชื่อจริง",
        "LastName"	:"นามสกุล",
        "FirstNameTh":	"ชื่อบริษัท TH",
        "LastNameEn"	:"ชื่อบริษัท EN",
        "Email":"อีเมล",
        "MobileNumber":	"เบอร์มือถือ",
        "RoleName":	"ชื่อบทบาท",
        "Username":	"ชื่อผู้ใช้",
        "grnEmail":"อีเมล GRN",
        "srnEmail":"อีเมล SRN",
        "invEmail":"อีเมลใบแจ้งหนี้",
        "submit":"ส่ง",
        "cancel":"ยกเลิก",
        "swalValPrOEmail":"กรุณากรอก ID อีเมลที่ถูกต้อง"

  }
}
    this.profileForm = this.fb.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      firstNameTh: ["", [Validators.required]],
      lastNameEn: ["", [Validators.required]],
      mobileNumber: ["", [Validators.required]],
      email: ["", [Validators.required]],
      vendorCode: ["", [Validators.required]],
      vendorAddress: ["", [Validators.required]],
      userName: ["", [Validators.required]],
      roleName: ["", [Validators.required]],
      grnEmail: ["", [Validators.required, Validators.email, Validators.pattern(this.emailPattern)]],
      srnEmail: ["", [Validators.required, Validators.email, Validators.pattern(this.emailPattern)]],
      invEmail: ["", [Validators.required, Validators.email, Validators.pattern(this.emailPattern)]]
    });
    this.getProfile();
  }
  profileForm : FormGroup = new FormGroup({
    firstName: new FormControl('test'),//
    lastName: new FormControl('test'),
    mobileNumber: new FormControl('8797777777'),
    email: new FormControl('shrinivasn@imss.co.in'),
    vendorCode: new FormControl('VND123234'),
    vendorAddress: new FormControl(''),
  });

  profileData:any;
  async getProfile(){
  this.mySpinner = true;
  (await this.cService.getProfile(this.currentUser.id)).pipe(first()).subscribe(data =>{
    if(data.responseStatus == "SUCCESS"){
      this.profileData = data.response;
      this.setProfile(this.profileData);
    } 
    this.mySpinner = false;
  })

}

setProfile(data: any) {
  this.profileForm = new FormGroup({
    firstName: new FormControl({ value: data.firstName, disabled: false }),
    lastName: new FormControl({ value: data.lastName, disabled: false}),
    firstNameTh: new FormControl({ value: (data.nameOneTh + (data.nameTwoTh?data.nameTwoTh:"")), disabled: false }),
    lastNameEn: new FormControl({ value: (data.nameOneEn + (data.nameTwoEn?data.nameTwoEn:"")), disabled: false}),
    mobileNumber: new FormControl({ value: data.contactNo, disabled: false}),
    email: new FormControl({ value: data.email, disabled: false}),
    userName: new FormControl({ value: data.userName, disabled: false}),
    roleName: new FormControl({ value: data.roleName, disabled: false}),
    grnEmail: new FormControl({ value: data.grnEmail, disabled: false}),
    srnEmail: new FormControl({ value: data.srnEmail, disabled: false}),
    invEmail: new FormControl({ value: data.invoiceEmail, disabled: false})
  });
  
}
async updateProfile(){
  let req = {
    "id": this.currentUser.id,
    "grnEmail":this.profileForm.value.grnEmail,
    "srnEmail": this.profileForm.value.srnEmail,
    "invoiceEmail":this.profileForm.value.invEmail
  };
  let nameregex = /^[a-zA-Z ]*$/;
  let emailregex = /^([\w-]|(?<!\.)\.)+[a-zA-Z0-9]@[a-zA-Z0-9]([\w\-]+)((\.([a-zA-Z]){2,9})+)$/;
  if (this.profileForm.value.grnEmail != "") {
    if (!emailregex.test(this.profileForm.value.grnEmail)) {
      Swal.fire({
        icon: 'warning',
        text: `${this.language.swalValPrOEmail}`
      });
      return;

    }
  }else if (this.profileForm.value.srnEmail != "") {
    if (!emailregex.test(this.profileForm.value.srnEmail)) {
      Swal.fire({
        icon: 'warning',
        text: `${this.language.swalValPrOEmail}`
      });
      return;

    }
  }else if (this.profileForm.value.invEmail != "") {
    if (!emailregex.test(this.profileForm.value.invEmail)) {
      Swal.fire({
        icon: 'warning',
        text: `${this.language.swalValPrOEmail}`
      });
      return;

    }
  }
  (await this.cService.updateProfile(req)).pipe(first()).subscribe(data =>{
    if(data.responseStatus == "SUCCESS"){
      this.getProfile();
    }else{
      Swal.fire(data.responseMessage,"", "error");
    }
  })
}
}
